import autobind from 'autobind-decorator'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import TimePicker from 'rc-time-picker'
import React from 'react'
import { getModelValue, setModelValue } from '../../common/utils'

@inject('store')
@observer
export default class FormTimePicker extends React.Component {
  static propTypes = {
    model: PropTypes.object,
    name: PropTypes.string,
    readOnly: PropTypes.bool,
    canClear: PropTypes.bool,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    showSecond: false,
    canClear: true,
    minuteStep: 5,
    clearText: 'Очистить',
  }

  get time() {
    let { model, name, value } = this.props
    let item = getModelValue(model, name, value)
    return item ? moment().startOf('day').seconds(item) : null
  }

  @autobind
  handleChange(value) {
    const time = value ? value.diff(moment().startOf('day'), 'seconds') : null
    const { model, name, onChange } = this.props
    setModelValue(model, name, time)
    onChange && onChange(time)
  }

  render() {
    const { model, name, readOnly, canClear, onChange, store, ...props } =
      this.props
    const disabled = readOnly || this.props.store.AppStore.isBusy
    return (
      <TimePicker
        {...props}
        value={this.time}
        onChange={this.handleChange}
        disabled={disabled}
        inputReadOnly={disabled}
        allowEmpty={canClear}
      />
    )
  }
}
