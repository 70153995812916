export const SEARCH = 'search'
export const GET = 'get'
export const PRODUCTION = 'production'
export const FORWARD = 'forward'
export const BACKWARD = 'backward'
export const DEEP_FORWARD = 'deep-forward'
export const DEEP_BACKWARD = 'deep-backward'
export const MULTIPLE_DEEP_FORWARD = 'multiple-deep-forward'
export const MULTIPLE_DEEP_BACKWARD = 'multiple-deep-backward'
export const IN_STOCK_AND_TARA = 'in-stock-and-tara'
