import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { TabContent, TabPane } from 'reactstrap'

@inject('store')
@observer
class RightSidebar extends Component {
  render() {
    const store = this.props.store.TaskStore
    return (
      <TabContent activeTab='1'>
        <TabPane tabId='1'>
          {store.incoming_unread.length > 0 ? (
            store.incoming_unread.map(this.mapTasks)
          ) : (
            <div className='empty-unread-messages'>Нет новых задач</div>
          )}
        </TabPane>
      </TabContent>
    )
  }

  mapTasks = task => (
    <NavLink to={'/tasks/' + task.id} key={task.id} className='message'>
      <div className='d-flex flex-row'>
        <small className='text-muted flex-grow-1 mr-1'>{task.fio}</small>
        <small className='text-muted text-right'>
          {moment(task.rec_date).calendar()}
        </small>
      </div>
      <div className='text-truncate font-weight-bold'>{task.name}</div>
      {!!task.comment && (
        <div className='text-muted font-italic'>{task.comment}</div>
      )}
    </NavLink>
  )
}

export default RightSidebar
