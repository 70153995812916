import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import ReportBase from './ReportBase'
import requester from '../../common/requester'

class ReportDataSorting {
  @observable column = 'contractor'
  @observable asc = true

  constructor() {
    makeObservable(this)
  }

  @action toggle(column) {
    if (this.column !== column) {
      this.column = column
      this.asc = true
    } else {
      this.asc = !this.asc
    }
  }
}

export class RoutesStore extends ReportBase {
  @observable days = observable.array()
  @observable sorting = new ReportDataSorting()

  @computed get years() {
    return Array(10)
      .fill(0)
      .map((_, i) => {
        const year = moment().year() - i
        return { value: year, text: `${year}` }
      })
  }

  @computed get months() {
    return Array(12)
      .fill(0)
      .map((_, month) => {
        return { value: month + 1, text: moment.months(month) }
      })
  }

  @computed get sorted_items() {
    return this.sorting.column === 'contractor'
      ? [...this.items].sort((a, b) => {
          const compare = a.contractor.localeCompare(b.contractor)
          return this.sorting.asc ? compare : -compare
        })
      : [...this.items].sort((a, b) => {
          const d0 = this.sorting.asc ? '24:00' : '00:00'
          const d1 = moment
            .duration(a.days?.[this.sorting.column - 1]?.time || d0)
            .asMinutes()
          const d2 = moment
            .duration(b.days?.[this.sorting.column - 1]?.time || d0)
            .asMinutes()
          const compare = d1 - d2
          return this.sorting.asc ? compare : -compare
        })
  }

  constructor() {
    super('/sale/report-routes')
    makeObservable(this)
  }

  getFiltersInitial() {
    return {
      year: moment().year(),
      month: moment().month() + 1,
      agent_id: null,
      route_id: null,
    }
  }

  get filter_params() {
    let params = {}
    params['filter[year]'] = this.filters.year || ''
    params['filter[month]'] = this.filters.month || ''
    params['filter[agent_id]'] = this.filters.agent_id || ''
    if (this.filters.route_id)
      params['filter[route_id]'] = this.filters.route_id
    return params
  }

  setData({ data }) {
    this.sorting = new ReportDataSorting()
    super.setData({ data })
    this.days.replace(data.days)
  }

  @action
  clearRouteFilter = () => {
    this.filters.route_id = null
  }

  clearData() {
    this.days.clear()
    super.clearData()
  }

  @action
  downloadData = () => {
    if (this.loading) return
    this.loading = true
    return requester.get(
      '/sale/report-routes/excel',
      this.filter_params,
      false,
      { responseType: 'arraybuffer' },
    )
  }
}

const store = new RoutesStore()
export default store
