import sum from 'lodash/sum'
import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import localDB from '../common/localDB'
import requester from '../common/requester'
import AppStore from './AppStore'
import { SellStore } from './SellStore'

export const STAGE_ROUTES = 1
export const STAGE_CONTRACTORS = 2
export const STAGE_PRODUCTS = 3
export const STAGE_CONFIRM = 4

const EARTH_RADIUS = 6371000
const RADIUS = 200

function calculateDistance(lat1, lon1, lat2, lon2) {
  const toRadians = degrees => degrees * (Math.PI / 180)

  const dLat = toRadians(lat2 - lat1)
  const dLon = toRadians(lon2 - lon1)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return EARTH_RADIUS * c
}

export class SaleOrdersStore {
  @observable is_loading = 0
  @observable is_ready = false
  @observable stage = STAGE_ROUTES
  @observable due_date = moment().add(1, 'day').format('YYYY-MM-DD')

  @observable routes = observable.array()
  @observable route = null

  @observable latitude = null
  @observable longitude = null

  @observable search_contractor = ''
  @observable contractors = observable.array()
  @observable contractor = null
  @observable done_contractors = observable.map({})
  @observable scrollPosition = 0

  @observable search_product = ''
  @observable products = observable.array()
  @observable prices_index = -1

  @observable is_confirm = false

  constructor() {
    makeObservable(this)
  }

  @action
  initState() {
    this.is_confirm = false
    this.prices_index = -1

    let stage = STAGE_ROUTES
    if (this.stage > STAGE_ROUTES && this.route && this.routes.length > 0) {
      stage = STAGE_CONTRACTORS
    } else {
      this.loadRoutes()
      this.route = null
    }
    if (stage === STAGE_CONTRACTORS) {
      if (
        this.stage > STAGE_CONTRACTORS &&
        this.contractor &&
        this.contractors.length > 0
      ) {
        stage = STAGE_PRODUCTS
      } else {
        this.loadContractors()
        this.contractor = null
      }
    }
    if (stage === STAGE_PRODUCTS) {
      if (
        this.stage > STAGE_PRODUCTS &&
        this.validateQuantity() &&
        this.order_products_grouped_with_amount.length > 0
      ) {
        stage = STAGE_CONFIRM
      }
    }
    this.stage = stage
    this.is_ready = true
  }

  @action
  resetReady() {
    this.is_ready = false
  }

  @action
  incrLoading = () => {
    this.is_loading++
  }

  @action
  decrLoading = () => {
    this.is_loading--
  }

  @computed get is_refreshing() {
    return this.is_loading > 0
  }

  @action
  prevStage = app => {
    if (this.is_loading) {
      app.showWarning('Идет загрузка, подождите.')
      return
    }
    this.stage--
    if (this.stage < STAGE_CONFIRM) {
      this.prices_index = -1
    }
    if (this.stage < STAGE_PRODUCTS) {
      this.contractor = null
      this.products.clear()
    }
    if (this.stage < STAGE_CONTRACTORS) {
      this.route = null
      this.contractors.clear()
    }
  }

  @action
  nextStage = app => {
    switch (this.stage + 1) {
      case STAGE_CONTRACTORS:
        if (!this.route) {
          app.showWarning('Выберите маршрут!')
          return
        }
        this.loadContractors()
        break
      case STAGE_PRODUCTS:
        if (!this.contractor) {
          app.showWarning('Выберите контрагента!')
          return
        }
        this.loadProducts().then(() => null)
        break
      case STAGE_CONFIRM:
        if (!this.validateQuantity())
          return app.showWarning('Проверьте количество!')
        if (this.products.filter(SaleOrdersStore.filterProducts).length === 0)
          return app.showWarning('Укажите количество!')
        this.is_confirm = false
        break
      default:
        return
    }
    this.stage++
  }

  loadContractorOrders = () => {
    this.incrLoading()
    this.getContractorOrders()
      .then(this.setContractorOrders)
      .finally(this.decrLoading)
  }

  async getContractorOrders() {
    const { data } = await requester.get(`/order/sale`, {
      due_date: this.due_date,
    })
    const order_contractors = {}
    for (const order of data.orders) {
      const contractor_id = `${order.contractor_id}`
      if (order.contractor_id && !order_contractors[contractor_id]) {
        order_contractors[contractor_id] = {
          id: order.id,
          products: order.data.products,
        }
      }
    }
    return order_contractors
  }

  @action setContractorOrders = order_contractors => {
    this.done_contractors.replace(order_contractors)
  }

  @computed get contractor_order_products() {
    const key = `${this.contractor.id}`
    return this.done_contractors.has(key)
      ? this.done_contractors.get(key).products
      : []
  }

  loadRoutes = () => {
    this.incrLoading()
    SellStore.getRoutes().then(this.setRoutes).finally(this.decrLoading)
  }

  @action
  setRoutes = routes => {
    this.routes.replace(routes)
  }

  @action
  onSelectRoute(route, app) {
    this.route = route
    this.scrollPosition = 0
    this.nextStage(app)
  }

  @action setCoordinates = (latitude, longitude) => {
    this.latitude = latitude
    this.longitude = longitude
  }

  loadContractors = () => {
    this.incrLoading()
    SaleOrdersStore.getContractors(this.route.data.contractors)
      .then(this.setContractors)
      .finally(this.decrLoading)
  }

  static async getContractors(contractor_ids) {
    let contractors = []
    for (let contractor_id of contractor_ids) {
      const contractor = await localDB.get('/contractors/' + contractor_id)
      if (contractor) contractors.push({ ...contractor.item })
    }
    return contractors
  }

  @action
  setContractors = contractors => {
    if (
      contractors.length <= this.scrollPosition > 0 &&
      this.scrollPosition > 0
    )
      this.scrollPosition = contractors.length > 0 ? contractors.length - 1 : 0
    this.contractors.replace(contractors)
  }

  @action
  onSelectContractor(contractor, app, index) {
    this.contractor = contractor
    this.nextStage(app)
    this.scrollPosition = index > -1 ? index : 0
  }

  @computed get filtered_contractors() {
    let result_items = this.contractors
    if (this.search_contractor) {
      result_items = result_items.filter(this.filterContractors)
    }

    if (this.latitude && this.longitude) {
      result_items = result_items
        .map(c => {
          let distance = 0
          if (c.data.latitude && c.data.longitude) {
            distance = calculateDistance(
              this.latitude,
              this.longitude,
              parseFloat(c.data.latitude),
              parseFloat(c.data.longitude),
            )
          }
          return { ...c, distance }
        })
        .sort((a, b) => a.distance - b.distance)
    }
    return result_items
  }

  filterContractors = c => {
    return c.name.toLowerCase().includes(this.search_contractor.toLowerCase())
  }

  static isInRadius(contractor) {
    return contractor.distance < RADIUS
  }

  async loadProducts() {
    this.incrLoading()
    let products = await this.getProducts()
    this.setProducts(products)
    this.decrLoading()
  }

  async getProducts() {
    const { list: nomenclatures } = await localDB.get(
      '/nomenclatures',
      {},
      null,
    )

    const list = []

    for (const n of nomenclatures) {
      if (!n || !n.data.templates || n.data.templates.length === 0) continue
      let prices = []
      let templates = {}
      for (const tid of n.data.templates) {
        if (typeof templates[tid] !== 'undefined') {
          prices.push(...templates[tid])
          continue
        }
        let t = await localDB.get('/templates/' + tid)
        t = t ? t.item : null
        if (t && t.temp_type === 'price' && !t.data.decider && t.data.prices) {
          templates[tid] = t.data.prices
          prices.push(...templates[tid])
        } else {
          templates[tid] = []
        }
      }
      if (prices.length === 0) continue
      const sale_prices = SellStore.reducePrices(prices)
      if (sale_prices.length === 0) continue
      const standard_price = sale_prices.find(
        p => p.name === 'standart' || p.name === 'standard',
      )

      const min_price = SellStore.minPrice(sale_prices)
      let price, price_type
      if (standard_price) {
        price = parseFloat(parseFloat(standard_price.price).toFixed(4))
        price_type = standard_price.name
      } else {
        price = parseFloat(parseFloat(sale_prices[0].price).toFixed(4))
        price_type = sale_prices[0].name
      }

      const order_product = this.contractor_order_products.find(
        f => f.nomenclature_id === n.id,
      )

      list.push({
        quantity: order_product?.quantity || null,
        price: order_product ? order_product.price : price,
        prices: sale_prices,
        min_price,
        price_type: order_product ? order_product.price_type : price_type,
        nomenclature_id: n.id,
        nomenclature: n.name ? n.name : '',
        unit: n.unit ? n.unit.short : '',
        unit_id: n.unit ? n.unit.id : null,
        category: n.category ? n.category.name : '',
        category_id: n.category ? n.category.id : null,
      })
    }

    return list.sort(SellStore.sortProducts)
  }

  @action setProducts = products => this.products.replace(products)

  @action
  togglePrices = index => {
    this.prices_index = this.prices_index === index ? -1 : index
  }

  @action
  setPrice(item) {
    const { prices, price_type } = item
    let price = prices.find(p => p.name === price_type)
    if (price) item.price = parseFloat(parseFloat(price.price).toFixed(4))
    if (isNaN(item.price)) item.price = null
  }

  static filterProducts(item) {
    return item.quantity && parseFloat(item.quantity) > 0
  }

  validateQuantity() {
    for (const p of this.products) {
      if (p.quantity && parseFloat(p.quantity) <= 0) return false
    }
    return true
  }

  showWarning = e => {
    e && !e.result && AppStore.showWarning(e.message)
  }

  filterSellSearchProduct = p =>
    p.nomenclature
      .toLowerCase()
      .trim()
      .includes(this.search_product.toLowerCase().trim())

  validNumeric(value) {
    value = value ? parseFloat(value) : 0
    return isFinite(value) ? value : 0
  }

  @computed get total_amount() {
    return sum(
      this.products
        .filter(SaleOrdersStore.filterProducts)
        .map(i => parseFloat(i.quantity) * parseFloat(i.price)),
    )
  }

  @computed
  get order_products_filtered() {
    let products = this.products
    if (this.search_product)
      products = products.filter(this.filterSellSearchProduct)
    return products
  }

  @computed
  get order_products_grouped() {
    return this.orderProductsCategorySort(
      this.order_products_filtered.reduce(
        this.orderProductsCategoryReducer,
        {},
      ),
    )
  }

  @computed
  get order_products_filtered_with_quantity() {
    return this.products.filter(SaleOrdersStore.filterProducts)
  }

  @computed get order_products_category_units_total_quantity_with_amount() {
    return this.order_products_filtered_with_quantity.reduce(
      this.orderProductsCategoryUnitsQuantityReducer,
      {},
    )
  }

  @computed get order_products_category_total_amount() {
    return this.order_products_filtered_with_quantity.reduce(
      (categories, product) => {
        const { category_id, quantity, price } = product
        if (!categories[category_id]) categories[category_id] = 0
        categories[category_id] +=
          parseFloat(quantity || '0') * parseFloat(price || '0')
        return categories
      },
      {},
    )
  }

  @computed
  get order_products_grouped_with_amount() {
    return this.orderProductsCategorySort(
      this.order_products_filtered_with_quantity.reduce(
        this.orderProductsCategoryReducer,
        {},
      ),
    )
  }

  orderProductsCategoryUnitsQuantityReducer = (categories, product) => {
    const unit = this.unitsReducer(categories, product)
    unit.quantity += this.validNumeric(product.quantity)
    return categories
  }

  unitsReducer = (categories, product) => {
    const { category_id, unit_id, unit } = product
    if (!categories[category_id]) categories[category_id] = []
    let unit_item = categories[category_id].find(
      unit => unit.unit_id === unit_id,
    )
    if (!unit_item) {
      unit_item = { unit_id, unit, quantity: 0, return: 0, defective: 0 }
      categories[category_id].push(unit_item)
    }
    return unit_item
  }

  orderProductsCategoryReducer = (categories, product) => {
    const { category_id, category } = product
    if (!categories[category_id]) {
      categories[category_id] = {
        key: category_id,
        category_id,
        category,
        data: [],
      }
    }
    categories[category_id].data.push(product)

    return categories
  }

  orderProductsCategorySort(categories) {
    return Object.values(categories)
      .sort(this.orderSortByCategory)
      .map((item, index) => ({
        ...item,
        index: (index + 1) * 10000,
      }))
  }

  orderSortByCategory(a, b) {
    return a.category.localeCompare(b.category)
  }

  @action
  clearConfirm = () => {
    this.is_confirm = false
  }

  @action
  openConfirm = () => {
    this.is_confirm = true
  }

  @action saveOrder = async () => {
    const contractor_id = this.contractor.id
    const order_id = this.done_contractors.get(`${contractor_id}`)?.id || null

    const { data } = await requester.post('/orders', {
      id: order_id,
      due_date: this.due_date,
      contractor_id,
      branch_id: null,
      data: {
        status: 'new',
        products: this.products
          .filter(SaleOrdersStore.filterProducts)
          .map(n => ({
            nomenclature_id: n.nomenclature_id,
            quantity: n.quantity,
            price: n.price,
            price_type: n.price_type,
          })),
        order_latitude: this.latitude || null,
        order_longitude: this.longitude || null,
        contractor_latitude: this.contractor.data.latitude || null,
        contractor_longitude: this.contractor.data.longitude || null,
      },
    })

    this.done_contractors.set(`${this.contractor.id}`, {
      id: data.id,
      products: data.data.products,
    })

    this.clearOrderSaveSuccess()
  }

  @action clearOrderSaveSuccess = () => {
    this.clearConfirm()
    this.stage = STAGE_CONTRACTORS
  }
}

const store = new SaleOrdersStore()
export default store
