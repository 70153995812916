import { computed, makeObservable, observable } from 'mobx'
import requester from '../../common/requester'
import { ReportCategoriesBase } from './ReportBase'
import xlsx from 'xlsx'
import { autoFitColumns } from '../../common/utils'

export class ByContractorsStore extends ReportCategoriesBase {
  @observable is_by_nomenclatures = false
  @observable data_filter = observable.map()

  constructor() {
    super('/sale/report-by-contractors')
    makeObservable(this)
  }

  get filter_params() {
    let filter_params = super.filter_params
    if (this.filters.contractor_id) {
      filter_params['filter[contractor_id]'] = this.filters.contractor_id
    }
    return filter_params
  }

  @computed
  get totalQuantity() {
    let total = 0
    if (this.items.length > 0) {
      total = this.items.reduce((sum, a) => sum + a.quantity, total)
    }
    return total
  }

  @computed
  get totalReturns() {
    let total = 0
    if (this.items.length > 0) {
      total = this.items.reduce((sum, a) => sum + a.return, total)
    }
    return total
  }

  @computed
  get totalDefective() {
    let total = 0
    if (this.items.length > 0) {
      total = this.items.reduce((sum, a) => sum + a.defective, total)
    }
    return total
  }

  getFilterStoreName() {
    return 'by-contractors-report'
  }

  getFiltersInitial() {
    let filters_initial = super.getFiltersInitial()
    filters_initial.contractor_id = null
    return filters_initial
  }

  loadData() {
    if (this.loading) return
    this.loading = true
    this.fetchData(this.url, this.filter_params)
      .then(this.setData)
      .finally(this.loadDataFinish)
  }

  fetchData = async (url, filter_params) => {
    const is_by_nomenclatures = !!filter_params['filter[contractor_id]']
    const resp = await requester.get(this.url, this.filter_params)
    return { data: { ...resp.data, is_by_nomenclatures } }
  }

  setData({ data }) {
    this.is_dirty = false
    this.is_by_nomenclatures = data.is_by_nomenclatures
    this.items.replace(data.list)
  }

  clearData() {
    this.is_by_nomenclatures = false
    super.clearData()
  }

  filterProduct = item => {
    let flag = true
    const contractor = this.data_filter.get('contractor') || ''
    const address = this.data_filter.get('address') || ''
    const quantity = this.data_filter.get('quantity') || null
    const return_quantity = this.data_filter.get('return') || null
    const defective = this.data_filter.get('defective') || null
    if (contractor) {
      flag =
        flag &&
        (item?.name || '').toLowerCase().includes(contractor.toLowerCase())
    }
    if (address) {
      flag =
        flag &&
        (item?.address || '').toLowerCase().includes(address.toLowerCase())
    }
    if (quantity) {
      flag = flag && (item?.quantity || 0) === quantity
    }

    if (return_quantity) {
      flag = flag && (item?.return || 0) === return_quantity
    }

    if (defective) {
      flag = flag && (item?.defective || 0) === defective
    }
    return flag
  }

  exportDataToExcel = () => {
    let items = [['ID', 'Наименование', 'Адрес', 'Количество', 'Замен', 'Брак']]
    for (const item of this.items) {
      items.push([
        item?.id,
        item?.name,
        item?.address,
        parseFloat(item?.quantity).toFixed(2).replace('.', ','),
        parseFloat(item?.return).toFixed(2).replace('.', ','),
        parseFloat(item?.defective).toFixed(2).replace('.', ','),
      ])
    }
    items.push([
      '',
      '',
      '',
      this.totalQuantity.toFixed(2).replace('.', ','),
      this.totalDefective.toFixed(2).replace('.', ','),
      this.totalReturns.toFixed(2).replace('.', ','),
    ])
    const worksheet = xlsx.utils.aoa_to_sheet(items)
    autoFitColumns(worksheet)
    const book = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(book, worksheet, 'list_1')
    xlsx.writeFile(book, `Отчет по ТТ.xlsx`, {
      bookType: 'xlsx',
    })
  }
}

const store = new ByContractorsStore()
export default store
