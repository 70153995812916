import { action, computed, makeObservable } from 'mobx'
import requester from '../common/requester'
import {
  CONTRACTOR_DETAIL,
  getModelValue,
  isEmail,
  isInstagram,
  isPhone,
  setModelValue,
} from '../common/utils'
import BaseStore from './BaseStore'

export class ContractorStore extends BaseStore {
  constructor() {
    super()
    makeObservable(this)
  }

  @computed get details() {
    const { data } = this.item
    return getModelValue(data, 'details', [])
  }

  @computed get contractor_tags() {
    const { data } = this.item
    const tags = getModelValue(data, 'contractor_tags', [])
    return tags.length === 0 ? [] : tags
  }

  @computed get price_rate() {
    const { data } = this.item
    const price_rate = getModelValue(data, 'price_rate', [])
    return price_rate.length === 0 ? [] : price_rate
  }

  @action.bound
  addTag() {
    setModelValue(this.item.data, 'contractor_tags', [
      ...this.contractor_tags,
      '',
    ])
  }

  @action.bound
  addPriceRate(category) {
    if (!category) return
    const newRate = {
      category,
      category_id: category.id,
      rate_auto: '',
      rate_avia: '',
      rate_auto_spot: '',
      rate_avia_spot: '',
      rate_auto_m3: '',
      rate_avia_m3: '',
    }
    setModelValue(this.item.data, 'price_rate', [...this.price_rate, newRate])
  }

  @action
  removeTag(index) {
    this.contractor_tags.splice(index, 1)
  }

  @action
  removePriceRate(index) {
    this.price_rate.splice(index, 1)
  }

  @action addCoordinates = (latitude, longitude) => {
    const { data } = this.item
    if (!data) return
    !data?.latitude && setModelValue(data, 'latitude', null)
    !data?.longitude && setModelValue(data, 'longitude', null)
    setModelValue(data, 'latitude', latitude)
    setModelValue(data, 'longitude', longitude)
  }

  @action addDetailsField = type => {
    const { data } = this.item
    data && !data.details && setModelValue(data, 'details', [])
    this.details.push({ type, value: '' })
  }

  @action removeDetailsField = detail => {
    this.details.remove(detail)
  }

  checkRequiredFields(item) {
    super.checkRequiredFields(item)
    const { data } = item
    const details = getModelValue(data, 'details', [])
    for (const detail of details) {
      this.contractorValidate(detail)
    }
  }

  contractorValidate(detail) {
    if (!detail.value) {
      throw new Error('Заполните поле ' + CONTRACTOR_DETAIL[detail.type])
    }
    switch (detail.type) {
      case 'email':
        if (!isEmail(detail.value)) {
          throw new Error('Неправильный формат E-mail')
        }
        break
      case 'phone':
        if (!isPhone(detail.value)) {
          throw new Error('Неправильный формат Телефона')
        }
        break
      case 'instagram':
        if (!isInstagram(detail.value)) {
          throw new Error('Неправильный формат имя пользоваеля Instagram')
        }
        break
    }
  }

  assignContractorCode = async () => {
    const { data } = await requester.get('/contractor/contractor-name-code', {
      country_id: this.item.data?.country_id,
      administrative_area_id: this.item.data?.administrative_area_id,
    })
    setModelValue(this.item, 'name', data.name)
  }
}

const store = new ContractorStore()
export default store
