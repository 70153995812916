import { action, computed, makeObservable, observable, values } from 'mobx'
import BaseStore from './BaseStore'

export class SelectionStore extends BaseStore {
  @observable selections_map = observable.map()

  constructor() {
    super()
    makeObservable(this)
    this.toggleSelection = this.toggleSelection.bind(this)
    this.clearSelectionsAll = this.clearSelectionsAll.bind(this)
  }

  @computed
  get selections() {
    const selections = []
    for (const page_selections of values(this.selections_map))
      selections.push(...values(page_selections))
    return selections
  }

  @computed get has_selections() {
    return this.selections.length > 0
  }

  @computed get has_single_selection() {
    return this.selections.length === 1
  }

  @computed get page_selections_map() {
    const page = `${this.page}`
    return this.selections_map.has(page)
      ? this.selections_map.get(page)
      : observable.map()
  }

  @computed
  get page_selections() {
    return values(this.page_selections_map)
  }

  selectionsIncludes(key) {
    return this.page_selections_map.has(`${key}`)
  }

  @action checkPageSelections() {
    const page = `${this.page}`
    if (!this.selections_map.has(page)) {
      this.selections_map.set(page, observable.map())
    }
  }

  @action
  toggleSelection(item, key, e) {
    e && e.stopPropagation()
    this.checkPageSelections()
    key = `${key}`
    if (this.selectionsIncludes(key)) {
      this.page_selections_map.delete(key)
    } else {
      this.page_selections_map.set(key, item)
    }
  }

  getSelectableItems() {
    return this.items
  }

  @action
  toggleSelectionsAll(reducer) {
    if (this.selectedAll) {
      this.page_selections_map.clear()
    } else {
      this.checkPageSelections()
      this.page_selections_map.replace(
        this.getSelectableItems().reduce(reducer, {}),
      )
    }
  }

  selectAllReducer(keyForItem) {
    return (all, item, idx) => {
      if (this.selectAllFilter(item, idx))
        all[`${keyForItem(item, idx)}`] = item
      return all
    }
  }

  selectAllFilter(item) {
    return true
  }

  @action
  clearSelectionsAll() {
    this.clearSelectionsMap()
  }

  clearSelectionsMap() {
    const page = `${this.page}`
    if (this.selections_map)
      this.selections_map.replace({ [page]: observable.map({}) })
    else this.selections_map = observable.map({ [page]: observable.map({}) })
  }

  @computed
  get selectedAll() {
    const selected_in_page = this.page_selections.length
    return (
      selected_in_page > 0 &&
      selected_in_page >=
        this.getSelectableItems().filter(this.selectAllFilter).length
    )
  }

  clearItems() {
    super.clearItems()
    this.clearSelectionsMap()
  }

  setPage(page) {
    const p = `${page}`
    !this.selections_map.has(p) &&
      this.selections_map.set(p, observable.map({}))
    super.setPage(page)
  }
}

const store = new SelectionStore()
export default store
