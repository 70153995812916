import classNames from 'classnames'
import isFunction from 'lodash/isFunction'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaClose, FaFolderOpenO, FaPlus } from 'react-icons/lib/fa'
import AsyncSelect from 'react-select/async'
import { Input, InputGroup, InputGroupAddon } from 'reactstrap'
import requester from '../../../common/requester'
import BaseStore from '../../../store/BaseStore'
import { AutoButton } from '../FormInput'

export default function AutocompletePopupView(props) {
  const { renderCmp: _, createBtn, selectModal, createModal, ...rest } = props
  return (
    <>
      <AutocompletePopupInput {...rest} createBtn={!!createBtn} />
      {selectModal}
      {createModal}
    </>
  )
}

function AutocompletePopupInput(props) {
  const {
    canCreate,
    clearSelect,
    createBtn,
    item,
    itemId,
    toggleCreate,
    toggleSelector,
    onSelect,
    value,
    canClear,
    readOnly,
    bsSize,
    children,
    placeholder,
    table,
    defaultFilter,
    displayProp,
  } = props
  const class_names = classNames([
    'popup-control',
    {
      'popup-disabled': readOnly,
      'popup-placeholder': !itemId && !!placeholder,
    },
  ])

  const { i18n } = useTranslation()

  const getOptionLabel = useCallback(
    option => {
      if (isFunction(displayProp)) return displayProp(option)
      const prop = displayProp || 'name'
      return option.data?.[`${prop}_${i18n.language}`] || option[prop]
    },
    [displayProp, i18n],
  )

  const getOptionValue = useCallback(option => option.id, [])

  const fetchOptions = useCallback(
    search => fetchTableItems(table, search, defaultFilter),
    [table, defaultFilter],
  )

  const input = (
    <AsyncSelect
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      value={item}
      onChange={onSelect}
      className={class_names}
      defaultInputValue={value || ''}
      defaultOptions={item ? [item] : true}
      loadOptions={fetchOptions}
      isClearable={false}
      classNamePrefix='react-select'
    />
  )
  const can_clear = !!(canClear && itemId)
  const can_create = !!(canCreate && createBtn)
  return !readOnly ? (
    <InputGroup size={bsSize}>
      {input}
      <InputGroupAddon addonType='append'>
        {children}
        {!!can_clear && (
          <AutoButton color='secondary' onClick={clearSelect}>
            <FaClose />
          </AutoButton>
        )}
        {can_create && (
          <AutoButton color='secondary' onClick={toggleCreate}>
            <FaPlus />
          </AutoButton>
        )}
        <AutoButton color='secondary' onClick={toggleSelector}>
          <FaFolderOpenO />
        </AutoButton>
      </InputGroupAddon>
    </InputGroup>
  ) : (
    <Input
      type='button'
      bsSize={bsSize}
      value={value || ''}
      className={class_names}
      readOnly
    />
  )
}

async function fetchTableItems(table, search, defaultFilter) {
  const params = {
    ...Object.fromEntries(
      Object.entries({ ...defaultFilter })
        .filter(BaseStore.validFilters)
        .map(([key, value]) => [`filter[${key}]`, value]),
    ),
    ...(search ? { search } : {}),
    page: 1,
  }
  const { data } = await requester.get(`/${table}`, params)
  return data.list
}
