import isFunction from 'lodash/isFunction'
import isObjectLike from 'lodash/isObjectLike'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import PropTypes from 'prop-types'
import React from 'react'
import bind from '../decorators/bind'
import localDB from '../localDB'
import {
  capitalize,
  checkAccess,
  getModelValue,
  isValidModelAndName,
  setModelValue,
} from '../utils'

class VisibilityState {
  @observable select = false
  @observable create = false

  constructor(popup) {
    makeObservable(this)
    this.popup = popup
  }

  @computed get is_select() {
    const { showSelect, readOnly } = this.popup.props
    const show = !isUndefined(showSelect) ? !!showSelect : this.select
    return show && !readOnly
  }

  @computed get is_create() {
    const { showCreate } = this.popup.props
    const show = !isUndefined(showCreate) ? !!showCreate : this.create
    return show && this.popup.can_create
  }

  @action toggleSelect() {
    this.select = !this.is_select
    const { onToggleSelect } = this.popup.props
    onToggleSelect && onToggleSelect(this.select)
  }

  @action toggleCreate() {
    this.create = !this.is_create
    const { onToggleCreate } = this.popup.props
    onToggleCreate && onToggleCreate(this.create)
  }
}

class StateValue {
  @observable value = null
  @action setValue = value => (this.value = value)
}

export default class PopupBase extends React.Component {
  static propTypes = {
    canClear: PropTypes.bool,
    displayProp: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    model: PropTypes.object,
    name: PropTypes.string,
    noCreate: PropTypes.bool,
    onSelect: PropTypes.func,
    readOnly: PropTypes.bool,
    refName: PropTypes.string,
    showCreate: PropTypes.bool,
    showSelect: PropTypes.bool,
    store: PropTypes.any,
    structure: PropTypes.shape({
      table: PropTypes.string,
      display: PropTypes.string,
    }),
    onToggleCreate: PropTypes.func,
    onToggleSelect: PropTypes.func,
    defaultFilter: PropTypes.object,
    finalFilter: PropTypes.array,
    defaultData: PropTypes.object,
  }

  static defaultProps = {
    canClear: false,
    noCreate: false,
    readOnly: false,
  }

  state = { value: new StateValue(), item: new StateValue() }

  get _value() {
    return this.state.value.value
  }

  set _value(value) {
    this.state.value.setValue(value)
  }

  get _item() {
    return this.state.item.value
  }

  set _item(value) {
    this.state.item.setValue(value)
  }

  _visibility = new VisibilityState(this)

  get is_valid_model_name() {
    const { model, name } = this.props
    return isValidModelAndName(model, name)
  }

  get value() {
    const { model, name } = this.props
    if (this.is_valid_model_name) return getModelValue(model, name, null)
    return this._value
  }

  set value(value) {
    const { model, name } = this.props
    setModelValue(model, name, value)
    this._value = value
  }

  get ref_name() {
    const { refName, name } = this.props
    let ref_name
    if (typeof refName === 'string') ref_name = refName
    else if (this.is_valid_model_name && isString(name))
      ref_name = name.substring(0, name.lastIndexOf('_'))
    return ref_name || null
  }

  get is_valid_model_ref_name() {
    const { model } = this.props
    return isValidModelAndName(model, this.ref_name)
  }

  get item() {
    const { model } = this.props
    let item
    if (this.is_valid_model_ref_name) item = getModelValue(model, this.ref_name)
    return isUndefined(item) ? this._item : item
  }

  set item(value) {
    const { model } = this.props
    setModelValue(model, this.ref_name, value)
    this._item = value
  }

  get display_value() {
    const { displayProp = this.props.structure.display || 'name', store } =
      this.props
    if (isFunction(displayProp)) return displayProp(this.item, this.value)
    if (!isObjectLike(this.item)) return null
    if (isString(displayProp) && displayProp.length > 0) {
      if (
        this.item.data &&
        this.item.data[`${displayProp}_${store.SettingsStore.language}`]
      )
        return this.item.data[`${displayProp}_${store.SettingsStore.language}`]
      return this.item[displayProp] || null
    }
    return (
      this.item.name ||
      this.item.data?.[`name_${store.SettingsStore.language}`] ||
      null
    )
  }

  get table() {
    let { structure } = this.props
    return (isObjectLike(structure) && structure.table) || null
  }

  canCreate() {
    let { store, readOnly, noCreate } = this.props
    if (!isObjectLike(store) || !isObjectLike(store.AppStore)) return false
    const ui = store.AppStore.user_info
    return (
      !noCreate &&
      !readOnly &&
      isObjectLike(ui) &&
      checkAccess(ui, 'create', this.table)
    )
  }

  get can_create() {
    return this.canCreate()
  }

  componentDidMount() {
    this.valueObserver = reaction(() => this.value, this.fetchItem, {
      fireImmediately: true,
    })
  }

  componentWillUnmount() {
    this.valueObserver?.()
  }

  get popup_view_props() {
    const {
      model,
      name,
      structure,
      onSelect,
      defaultFilter,
      finalFilter,
      defaultData,
      noCreate,
      store,
      displayProp,
      showCreate,
      showSelect,
      onToggleCreate,
      onToggleSelect,
      ...props
    } = this.props
    return props
  }

  get page_select() {
    return capitalize(this.table)
  }

  get match_select() {
    const table = this.table
    return { url: `/${table}`, path: `/${table}` }
  }

  get page_add() {
    return capitalize(this.table) + 'Edit'
  }

  get match_add() {
    const table = this.table
    return { url: `/${table}/add`, path: `/${table}/:id` }
  }

  get on_create_prop() {
    return this.can_create ? { onCreate: this._toggleCreate } : {}
  }

  loading = null

  isIdEqual(item, value) {
    return (
      isObjectLike(item) &&
      (value === item.id ||
        (!isNaN(value) && parseFloat(value) === parseFloat(item.id)))
    )
  }

  @bind
  async fetchItem() {
    const value = this.value
    const item = this.item
    if (!value) {
      if (isObjectLike(item)) this.item = null
    } else if (
      value !== this.loading &&
      !this.isIdEqual(item, value) &&
      this.table
    ) {
      this.loading = value
      try {
        const data = await localDB.get(`/${this.table}/${value}`)
        const new_item = isObjectLike(data) && data.item
        if (this.value && this.isIdEqual(new_item, this.value))
          this.item = new_item
      } finally {
        if (this.loading === value) this.loading = null
      }
    }
  }

  @bind _toggleSelector() {
    this._visibility.toggleSelect()
  }

  @bind _toggleCreate(e, created) {
    this._visibility.toggleCreate()
    isObjectLike(created) && created.id && this._onSelect(created)
  }

  @bind _clearSelect() {
    let { readOnly, canClear } = this.props
    !readOnly && canClear && this._onSelect(null)
  }

  @bind _onSelect(item) {
    this._visibility.is_select && this._toggleSelector()
    if (item) {
      this.value = item.id
      this.item = item
    } else {
      this.value = null
      this.item = null
    }
    const { onSelect } = this.props
    onSelect && setTimeout(onSelect, 0, item)
  }
}
