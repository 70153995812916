import validate from './validate'

const functionCache = new Map()

const RE = {
  MODEL_VAR: /^[a-z][a-z_\d]*(\.[a-z_]+[a-z_\d]*)*$/im,
  RETURN: /(;=>)/gim,
  LOCAL_VAR: /(^[$][a-z][a-z_\d]*$)/im,
}

const noop = () => null

export default function transpile(expression) {
  if (!validate(expression) || expression.length < 1) return noop
  if (functionCache.has(expression)) {
    return functionCache.get(expression)
  }
  const lexemeArray = lexer(expression)
  const { code, local_variables } = parser(lexemeArray)
  const callable = new Function(
    ['_', ...local_variables].join(','),
    `return (${code});`,
  )
  functionCache.set(expression, callable)
  return callable
}

function lexer(expression) {
  let str = expression.replace(/\s/g, '')
  return str.match(
    /(?:\$[a-z][a-z._?]*[a-z])|(?:[$][a-z][a-z_\d]*)|(?:[a-z][a-z\d_]*(?:\.[a-z+]+[a-z_\d]*)*)|(?:\d+)|(?:=>|{|}|,|&{1,2}|%|:|\.|<|>|\?|\^|-|\/|\*|\(|\)|=|\+|;|\\|\|{1,2})/gim,
  )
}

function parser(lexemeArray) {
  const local_variables = new Set()
  const newArr = lexemeArray?.map(item => {
    if (RE.LOCAL_VAR.test(item)) {
      local_variables.add(item)
      return item
    }
    if (RE.MODEL_VAR.test(item)) return `_("${item}")`
    return item
  })
  return {
    code: newArr && newArr.join('').replace(RE.RETURN, ';return '),
    local_variables,
  }
}
