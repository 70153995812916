import { computed, makeObservable } from 'mobx'
import xlsx from 'xlsx'
import { v2nQ, autoFitColumns } from '../common/utils'
import { SelectionStore } from './SelectionStore'

export class ReportStockStore extends SelectionStore {
  constructor() {
    super()
    makeObservable(this)
    this.add_filter_keys('category_id', 'nomenclature_id')
  }

  setResult(data) {
    data.item ? this.setSingle(data.item) : this.setSingle(data)
    return super.setResult(data)
  }

  @computed get total_start_balance() {
    return this.items.reduce((t, i) => {
      if (!i.start_balance) return t
      const u = t.find(j => j.unit_id === i.unit_id)
      if (!u)
        t.push({
          unit: i.unit,
          unit_id: i.unit_id,
          quantity: parseFloat(i.start_balance),
        })
      else u.quantity += parseFloat(i.start_balance)
      return t
    }, [])
  }

  @computed get total_income() {
    return this.items.reduce((t, i) => {
      if (!i.income) return t
      const u = t.find(j => j.unit_id === i.unit_id)
      if (!u)
        t.push({
          unit: i.unit,
          unit_id: i.unit_id,
          quantity: parseFloat(i.income),
        })
      else u.quantity += parseFloat(i.income)
      return t
    }, [])
  }

  @computed get total_outgoing() {
    return this.items.reduce((t, i) => {
      if (!i.outgoing) return t
      const u = t.find(j => j.unit_id === i.unit_id)
      if (!u)
        t.push({
          unit: i.unit,
          unit_id: i.unit_id,
          quantity: parseFloat(i.outgoing),
        })
      else u.quantity += parseFloat(i.outgoing)
      return t
    }, [])
  }

  @computed get total_end_balance() {
    return this.items.reduce((t, i) => {
      if (!i.end_balance) return t
      const u = t.find(j => j.unit_id === i.unit_id)
      if (!u)
        t.push({
          unit: i.unit,
          unit_id: i.unit_id,
          quantity: parseFloat(i.end_balance),
        })
      else u.quantity += parseFloat(i.end_balance)
      return t
    }, [])
  }

  get should_save_filter_keys() {
    return [...super.should_save_filter_keys, 'category_id', 'nomenclature_id']
  }

  exportExcel = () => {
    let filters_name = {
      id: 'Фильтры',
      branch: 'Дата от',
      category: `Дата до`,
      nomenclature: `Отдел`,
      start_balance: `Категория`,
      income: `Номенклатура`,
      outgoing: '',
      end_balance: '',
      unit: '',
    }
    let filters_value = {
      id: '',
      branch: this.filters.get('start_from'),
      category: this.filters.get('end_to'),
      nomenclature: this.filters.get('branch')?.name,
      start_balance: this.filters.get('category')?.name,
      income: this.filters.get('nomenclature')?.name,
      outgoing: '',
      end_balance: '',
      unit: '',
    }

    let header = {
      id: 'ID',
      branch: 'Отдел',
      category: 'Категория',
      nomenclature: 'Наименование',
      start_balance: 'Начало',
      income: 'Поступление',
      outgoing: 'Расход',
      end_balance: 'Конец',
      unit: 'Ед. измерение',
    }

    let items = this.items.map(item => {
      return {
        id: item.id,
        branch: item.branch,
        category: item.category,
        nomenclature: item.nomenclature,
        start_balance: item.start_balance ? v2nQ(item.start_balance) : null,
        income: item.income ? v2nQ(item.income) : null,
        outgoing: item.outgoing ? v2nQ(item.outgoing) : null,
        end_balance: item.end_balance ? v2nQ(item.end_balance) : null,
        unit: item.unit,
      }
    })

    items.unshift(filters_name, filters_value, { id: '' }, header)
    const worksheet = xlsx.utils.json_to_sheet(items, {
      skipHeader: true,
    })

    autoFitColumns(worksheet)

    const book = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(book, worksheet)

    xlsx.writeFile(book, `report-stock-${new Date().toISOString()}.xlsx`, {
      bookType: 'xlsx',
    })
  }
}

const store = new ReportStockStore()
export default store
