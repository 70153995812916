import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import EnumSelectBase, { EnumTextBase } from '../../common/components/EnumBase'
import { FormSelect } from './FormSelect'

@observer
export class EnumSelect extends EnumSelectBase {
  static propTypes = {
    ...EnumSelectBase.propTypes,
    ...FormSelect.propTypes,
    options: PropTypes.array,
  }

  render() {
    let { enumName, useConst, ...rest } = this.props
    return (
      <FormSelect
        labelKey='name'
        valueKey='value'
        options={this.options.slice()}
        {...rest}
      />
    )
  }
}

@inject('store')
@observer
export class EnumText extends EnumTextBase {
  static propTypes = {
    ...EnumTextBase.propTypes,
  }
}
