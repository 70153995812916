import { action, makeObservable, observable } from 'mobx'
import requester from '../common/requester'
import BaseStore from './BaseStore'

const UNKNOWN_INDEX = -1
const DEFAULT_INDEX = 0

class IotStore extends BaseStore {
  @observable branch_devices = observable.map()
  @observable branch_device_index = observable.map()
  queue = new Set()

  constructor() {
    super()
    makeObservable(this)
  }

  async fetchDevice(branch_id) {
    this.queue.add(branch_id)
    try {
      const {
        data: { devices },
      } = await requester.get('/iot/scales/' + branch_id)
      this.setDevices(branch_id, devices)
    } catch {
      this.setDevices(branch_id, [])
    } finally {
      this.queue.delete(branch_id)
    }
  }

  @action setDevices(branch_id, devices) {
    this.branch_devices.set(branch_id, devices || [])
  }

  getDevices(branch_id) {
    if (this.branch_devices.has(branch_id)) {
      return this.branch_devices.get(branch_id)
    }
    !this.queue.has(branch_id) && this.fetchDevice(branch_id)
    return []
  }

  getDevice(branch_id) {
    const devices = this.getDevices(branch_id)
    if (devices.length === 0) {
      return null
    }
    const index = this.branch_device_index.has(branch_id)
      ? this.branch_device_index.get(branch_id)
      : UNKNOWN_INDEX
    if (index !== UNKNOWN_INDEX && devices.length > index) {
      return devices[index]
    }
    return devices[DEFAULT_INDEX]
  }

  @action setDeviceIndex(branch_id, device_index) {
    this.branch_device_index.set(branch_id, device_index)
  }
}

const store = new IotStore()
export default store
