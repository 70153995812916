import {
  action,
  computed,
  keys,
  makeObservable,
  observable,
  runInAction,
} from 'mobx'
import {
  DOCUMENT_CANCELLED,
  DOCUMENT_COMMITTED,
  DOCUMENT_SAVED,
} from '../common/constants'
import requester from '../common/requester'
import { getDeepObjectValue, getModelValue } from '../common/utils'
import BaseStore from './BaseStore'

export class DocumentStore extends BaseStore {
  @observable document_type
  @observable related_documents = observable.array()

  constructor() {
    super()
    makeObservable(this)
  }

  clearItem() {
    this.related_documents?.clear()
    super.clearItem()
  }

  async localGet(...args) {
    if (this.document_type) {
      if (args.length < 2) args.length = 2
      args[1] = { ...args[1], template: this.document_type.template }
    }
    return super.localGet(...args)
  }

  setSingle({ document_type, document_type_id, ...item }) {
    document_type = { ...this.document_type, ...document_type }
    const data = Object.keys(document_type.data?.params?.data || {}).reduce(
      (data, name) => ({ ...data, [name]: null }),
      {},
    )
    this.document_type = document_type
    super.setSingle({
      document_type_id: document_type_id || document_type.id,
      document_type,
      ...item,
      data: { ...data, ...item.data },
    })
  }

  getParams = key => {
    const params = getDeepObjectValue(
      this.item,
      null,
      'document_type',
      'data',
      'params',
      key,
    )
    return params
      ? keys(params).map(name => ({
          ...getModelValue(params, name),
          name,
          form_param_viewer: true,
        }))
      : []
  }

  @computed get data_params() {
    return this.getParams('data')
  }

  @computed get cancelled() {
    return this.item.status_id === DOCUMENT_CANCELLED
  }

  @computed get saved() {
    return this.item.id && this.item.status_id === DOCUMENT_SAVED
  }

  @computed get committed() {
    return this.item.status_id === DOCUMENT_COMMITTED
  }

  @computed get structure_excluded() {
    return new Set(
      (this.document_type.data?.item_structure || [])
        .filter(s => s.exclude)
        .map(s => s.name),
    )
  }

  getStructure() {
    const structure = super
      .getStructure()
      .filter(s => !this.structure_excluded.has(s.name))
    return [...structure, ...this.data_params]
  }

  canSave() {
    return super.canSave() && !this.committed
  }

  canDelete() {
    return super.canDelete() && this.committed
  }

  async postData(pathname, item = this.item, commit = false) {
    this.item.document_type_id = this.document_type.id
    try {
      this.checkRequiredFields(item)
      await this.putFiles(pathname, item)
      if (item.data && typeof item.data === 'object')
        await this.putFiles(pathname, item.data)
      const { data } = await requester.post(
        `${pathname}?commit=${commit}`,
        item,
      )
      runInAction(() => (this.item = data.item))
      this.saveSuccessMessage()
      return data
    } catch (e) {
      this.catchPostData(e)
      throw e
    }
  }

  @action setRelatedDocuments(documents) {
    this.related_documents.replace(documents)
  }

  async fetchRelatedDocuments() {
    const { id } = this.item
    if (id) {
      const { data } = await requester.get('/documents', {
        'filter[parent_id]': id,
        'filter[status_id]': 2,
      })
      this.item.id === id && this.setRelatedDocuments(data.list)
    }
  }
}

const store = new DocumentStore()
export default store
