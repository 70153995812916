import * as mobx from 'mobx'
import {
  action,
  computed,
  isObservableArray,
  makeObservable,
  observable,
  values,
} from 'mobx'
import requester from '../common/requester'
import { fileKey, NativeFile, ROUTE_TYPES_MAP } from '../common/utils'
import AppStore from './AppStore'
import BaseStore from './BaseStore'
import BUSelectStore from './BUSelectStore'
import EditorStore from './EditorStore'
import { SelectionStore } from './SelectionStore'

export class TaskStore extends SelectionStore {
  @observable current_user_id = ''
  @observable task_type = 'issue'
  @observable route_type = 'negotiation'
  @observable category = 'incoming'
  @observable route_assignees = observable.array()
  @observable route_branches = observable.array()
  @observable route_description
  @observable route_due_date
  @observable route_due_time = '18:00'
  @observable incoming_unread_count = 0
  @observable incoming_unread = observable.array()
  @observable comment = ''
  @observable file_attach_to_comment = observable.array()
  @observable showSendForm = false
  @observable history_filter = 'all'
  @observable removeFilesMode = false
  @observable isImageView = false
  @observable currentImageIndex = 0
  @observable filterRouteType = null
  @observable on_replacement = false
  @observable route_filter = 'all'

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get is_closed() {
    return this.item.status === 'closed'
  }

  @computed get can_close() {
    return !this.is_closed && this.access.includes('close')
  }

  @computed get can_reopen() {
    return this.is_closed && this.access.includes('close')
  }

  @action
  setTaskCategory(category) {
    this.category = category
  }

  @action
  setCurrentUser(user_id) {
    if (user_id !== this.current_user_id)
      this.fetchMany().catch(err => console.log(err))
    this.current_user_id = user_id
  }

  @computed
  get routeTypeFilterText() {
    return (
      ['negotiation', 'approval', 'informative', 'execution']
        .reduce(this.mapRouteTypeFilterText, [])
        .join(', ') || 'Все'
    )
  }

  filterImage = item => {
    const name = typeof item === 'string' ? item : item.name
    return /\.(jpe?g|png|bmp|gif)$/i.test(name)
  }

  reduceCommentsImage = (images, item) => {
    const { message, record } = item
    if (message === 'comment_rec_date' && record && record.file_attach)
      record.file_attach.reduce(this.reduceRouteFileAttach, images)
    return images
  }

  reduceRouteFileAttach = (images, item) => {
    this.filterImage(item) && images.push(item)
    return images
  }

  @computed
  get history_images() {
    return this.item.history
      ? this.item.history.reduce(this.reduceCommentsImage, [])
      : []
  }

  @computed
  get attached_images() {
    const { data } = this.item
    return data && data.file_attach
      ? data.file_attach.filter(this.filterImage)
      : []
  }

  @computed
  get comment_images() {
    return this.file_attach_to_comment.filter(this.filterImage)
  }

  @computed
  get imageOnly() {
    const history_images = this.history_images
    const attached_images = this.attached_images
    const comment_images = this.comment_images
    return [...history_images, ...attached_images, ...comment_images]
  }

  mapImageSources = f => (typeof f === 'string' ? f : f.preview)

  @computed
  get images() {
    return this.imageOnly.map(this.mapImageSources)
  }

  mapRouteTypeFilterText = (all, t) => {
    if (this.filters.has('route_type_' + t)) {
      all.push(ROUTE_TYPES_MAP[t])
    }
    return all
  }

  @computed
  get routeTypeFilterValue() {
    return (
      ['negotiation', 'approval', 'informative', 'execution'].filter(
        this.filterRouteTypeFilterValue,
      )[0] || 'all'
    )
  }

  filterRouteTypeFilterValue = t => {
    return this.filters.has('route_type_' + t)
  }

  @action
  setRouteFilter(type) {
    if (!type || type === 'all') {
      ;['negotiation', 'approval', 'informative', 'execution'].map(
        this.removeRouteTypeFilter,
      )
    } else if (!this.filters.has('route_type_' + type)) {
      ;['negotiation', 'approval', 'informative', 'execution']
        .filter(t => t !== type)
        .map(this.removeRouteTypeFilter)
      this.filters.set('route_type_' + type, 1)
    }
  }

  @action
  removeRouteTypeFilter = t => {
    this.filters.has('route_type_' + t) &&
      this.filters.delete('route_type_' + t)
  }

  async sendCommand(type) {
    let data = await requester.post('/tasks/mark', {
      type,
      route: this.myRoute,
    })
    this.setResult(data.data)
    this.showSuccessMessage()
  }

  showSuccessMessage() {
    const lastRecord =
      this.item.history && this.item.history[this.item.history.length - 1]
    if (!lastRecord) return this.saveSuccessMessage()
    switch (lastRecord.message) {
      case 'route_fulfilment_date':
        switch (lastRecord.record.status) {
          case 'approved':
            AppStore.showSuccess('Подтвержденно!')
            break
          case 'disapproved':
            AppStore.showSuccess('Отклонено!')
            break
          case 'done':
            AppStore.showSuccess('Исполнено!')
            break
        }
        break
      case 'comment_rec_date':
        AppStore.showSuccess('Коментарий сохранен')
        break
      case 'route_rec_date':
        AppStore.showSuccess('Отправлено')
        break
      default:
        super.saveSuccessMessage()
    }
  }

  async reSend(route_type, route_assignees) {
    if (
      !mobx.isObservableArray(route_assignees) &&
      !Array.isArray(route_assignees)
    )
      route_assignees = [route_assignees]
    this.route_type = route_type
    this.route_assignees.replace(route_assignees)
    await this.sendTask()
    this.clearSendForm()
  }

  sendAndClear = async () => {
    await this.sendTask()
    this.closeSendForm()
    this.clearSendForm()
  }

  async sendTask() {
    const route = this.assignArgs()
    if (!this.item.id || !route) return
    let isDraft = false
    if (!this.item.routes || this.item.routes.length === 0) isDraft = true
    let data = await requester.post('/tasks/assign', route)
    this.successSend(data)
    if (isDraft) this.fetchDraft()
    else this.trackChanges(route)
  }

  isReadOnly() {
    return (
      this.item.id &&
      !this.access.includes('edit') &&
      !this.access.includes('close')
    )
  }

  @action
  forceGetTasks(match) {
    this.page = 1
    this.on_replacement = true
    return this.fetchData(match)
  }

  @action
  getTasksInit(match) {
    if (this.items.length === 0) {
      this.on_replacement = true
      return this.fetchData(match)
    }
  }

  @action
  goToNextPage(match) {
    if (this.total_items > this.page * 40) {
      this.page++
      this.on_replacement = true
      return this.fetchData(match)
    }
  }

  @action
  goToPreviousPage(match) {
    if (this.page > 1) {
      this.page--
      this.on_replacement = true
      return this.fetchData(match)
    }
  }

  @action
  loadNextPage(match) {
    if (this.total_items > this.page * 40) {
      this.on_replacement = false
      this.page++
      return this.fetchData(match)
    }
  }

  setData(data, count) {
    if (this.on_replacement || this.page === 1) {
      super.setData(data, count)
      this.on_replacement = false
    } else if (this.page > 1) {
      this.items.push(...data)
      this.total_items = count || this.items.length
    }
  }

  async change_status() {
    await requester
      .post('/tasks/change_status', {
        task_id: this.item.id,
        access: this.access,
      })
      .then(this.successStatusChange)
  }

  async archiveRoute(match) {
    await requester.post('/tasks/archive_route', {
      task_id: this.item.id,
    })
    await this.fetchItem(match.url)
    this.fetchIncoming()
    this.fetchArchive()
  }

  async unarchiveRoute(match) {
    await requester.post('/tasks/unarchive_route', {
      task_id: this.item.id,
    })
    await this.fetchItem(match.url)
    this.fetchIncoming()
    this.fetchArchive()
  }

  loadUnread() {
    return this.getUnread()
  }

  getUnread() {
    requester
      .get('/tasks/get_unread_tasks', {}, true)
      .then(data => {
        if (data?.data) {
          this.setUnread(data.data.tasks)
          this.setIncomingCount(data.data.count)
        }
      })
      .catch(() => undefined)
  }

  @action
  setUnread(incoming_unread) {
    this.incoming_unread = incoming_unread
  }

  @action
  setIncomingCount(incoming_unread_count) {
    this.incoming_unread_count = incoming_unread_count
  }

  setSingle(item) {
    super.setSingle(item)
    this.loadUnread()
  }

  async fetchMany() {
    if (!this.pathname) return
    let params = {}
    params.page = this.page
    params.category = this.category
    this.filtersEnabled &&
      mobx
        .entries(this.filters)
        .filter(BaseStore.validFilters)
        .map(([key, value]) => (params[`filter[${key}]`] = value))
    if (this.order) params.order = this.order
    const data = await this.localGet(
      this.pathname,
      params,
      this.order || 'rec_date desc',
    )
    this.setResult(data)
    return data
  }

  @computed
  get isAssignedToMe() {
    return (
      this.item &&
      this.item.routes &&
      this.item.routes.filter(
        r =>
          r.assignee &&
          r.assignee.id === AppStore.user_info.id &&
          ['pending', 'in_progress'].includes(r.status),
      ).length > 0
    )
  }

  @computed
  get can_archive() {
    const { routes } = this.item
    const user_id = AppStore.user_info?.id
    return (
      routes &&
      routes.some(route => {
        const { assignee, fulfilment_date, archive_date, data } = route
        if (!fulfilment_date || archive_date || assignee?.id !== user_id)
          return false
        const { bu_read_time, archived_for_users } = data
        return (
          bu_read_time &&
          bu_read_time.some(i => i.user_id === user_id) &&
          (!archived_for_users ||
            archived_for_users.every(i => i.user_id !== user_id))
        )
      })
    )
  }

  @computed
  get can_unarchive() {
    const { routes } = this.item
    const user_id = AppStore.user_info?.id
    return (
      routes &&
      routes.some(route => {
        const { archive_date, data } = route
        const { archived_for_users } = data
        return (
          !archive_date &&
          archived_for_users &&
          archived_for_users.some(i => i.user_id === user_id)
        )
      })
    )
  }

  @computed
  get isArchivable() {
    return (
      this.item &&
      this.item.routes &&
      (this.item.routes.findIndex(this.fulfilmentDate) !== -1 ||
        this.item.routes.findIndex(this.buReadTime) !== -1)
    )
  }

  fulfilmentDate = r =>
    r.assignee && r.assignee.id === AppStore.user_info.id && r.fulfilment_date
  buReadTime = r =>
    r.data &&
    r.data.bu_read_time &&
    r.data.bu_read_time.filter(this.recordBelongsToMe)

  @computed
  get areArchivable() {
    return (
      (this.selections_map &&
        this.selections_map.filter(
          item => item.route && item.route.fulfilment_date,
        ).length > 0) ||
      this.selections_map.filter.filter(
        item =>
          item.route.data &&
          item.route.data.bu_read_time &&
          item.route.data.bu_read_time.filter(
            record => record.user_id === AppStore.user_info.id,
          ) > 0,
      )
    )
  }

  @computed
  get isUnarchivable() {
    return (
      this.item &&
      this.item.routes &&
      ((this.item.routes.findIndex(this.archiveDate) !== -1 &&
        this.isArchivable) ||
        (this.item.routes.findIndex(this.archivedForUsers) !== -1 &&
          this.isArchivable))
    )
  }

  archiveDate = r =>
    r.assignee && r.assignee.id === AppStore.user_info.id && r.archive_date

  recordBelongsToMe = record => record.user_id === AppStore.user_info.id

  archivedForUsers = r =>
    r.data &&
    r.data.archived_for_users &&
    r.data.archived_for_users.findIndex(this.recordBelongsToMe) !== -1

  filterMyRoute = r => {
    const ui = AppStore.user_info
    return (
      ui &&
      r.assignee &&
      r.assignee_id === ui.id &&
      ['pending', 'in_progress'].includes(r.status)
    )
  }

  @computed
  get myRoute() {
    return this.isAssignedToMe
      ? this.item.routes.filter(this.filterMyRoute)[0]
      : null
  }

  sendComment = async () => {
    if (
      !this.item.id ||
      (!this.comment.trim() && this.file_attach_to_comment.length === 0)
    )
      return
    await this.putFiles(`/tasks/${this.item.id}`, this)
    const data = await requester.post('/tasks/comment', {
      task_id: this.item.id,
      comment: this.comment,
      file_attach: this.file_attach_to_comment,
    })
    this.successComment(data)
  }

  @action
  successComment(data) {
    this.item.data.comments.push(data.data.record)
    this.item.history.push(data.data)
    this.comment = ''
    this.file_attach_to_comment.replace([])
    this.showSuccessMessage()
  }

  @action.bound
  successSend(data) {
    this.item.close_date = null
    if (this.myRoute) this.myRoute.status = 'reassigned'
    data.data.list.map(this.fillTheHistory)
    this.showSuccessMessage()
  }

  @action
  fillTheHistory = el => {
    if (this.item) {
      if (!this.item.routes) this.item.routes = []
      if (!this.item.history) this.item.history = []
      this.item.routes.push(el.record)
      this.item.history.push(el)
    }
  }

  clearItem() {
    super.clearItem()
    this.comment = ''
  }

  @action
  removeAttachedFile = (event, file) => {
    event && event.preventDefault()
    this.item.data.file_attach.remove(file)
  }

  @action
  removeAttachedFileToComment = (event, file) => {
    event && event.preventDefault()
    this.file_attach_to_comment.remove(file)
  }

  @action
  removeRouteAssignee(event, user) {
    event && event.preventDefault()
    this.route_assignees.remove(user)
  }

  @action.bound
  successStatusChange(data) {
    this.item.status = data.data.task_status
    this.item.close_date = data.data.close_date
  }

  @action.bound
  successStatusArchive(data) {
    this.item.route.archive_date = data.data.archive_date
    this.item.close_date = data.data.close_date
  }

  @action
  onSelectFiles = files => {
    if (!this.item.data) return
    if (typeof this.item.data.file_attach === 'undefined')
      this.item.data = { ...this.item.data, file_attach: files }
    else if (!this.item.data.file_attach) this.item.data.file_attach = files
    else this.item.data.file_attach.push(...files)
  }

  @action
  onSelectFilesToComment = files => {
    if (!files) return
    this.file_attach_to_comment.push(...files)
  }

  async putFiles(pathname, item) {
    await Promise.all(
      Object.keys(item)
        .filter(k => k.startsWith('file_'))
        .map(async k => {
          if (Array.isArray(item[k]) || isObservableArray(item[k])) {
            for (let i = 0; i < item[k].length; i++) {
              await TaskStore.sendFile(pathname, item[k], i)
            }
          } else {
            await TaskStore.sendFile(pathname, item, k)
          }
        }),
    )
  }

  async postData(pathname, item = this.item) {
    let route = this.assignArgs()
    if (route && route.assignees) {
      item.with_route = route
    }
    const resp = await super.postData(pathname, item)
    this.trackChanges(route)
    if (resp && resp.routes && resp.routes[0].message) {
      this.closeSendForm()
      this.clearSendForm()
      this.fillTheHistory(resp.routes[0])
    }
    return resp
  }

  assignArgs() {
    const bu_state = BUSelectStore.states.has('tasks')
      ? BUSelectStore.states.get('tasks')
      : null
    const assigns =
      this.route_assignees.length +
        this.route_branches.length +
        (bu_state
          ? bu_state.user_selections.size + bu_state.branch_selections.size
          : 0) ===
      0
    if (!this.route_type || assigns) return
    let type = this.route_type
    let assignees = [...this.route_assignees]
    let branches = [...this.route_branches]
    if (bu_state) {
      assignees.push(...values(bu_state.user_selections))
      branches.push(...values(bu_state.branch_selections))
    }
    let comment = this.route_description
    let due_date = this.route_due_date
    let due_time = this.route_due_time
    if (due_date && due_time) due_date = due_date.slice(0, 10) + ' ' + due_time
    return {
      task_id: this.item.id,
      type,
      assignees,
      branches,
      comment,
      due_date,
    }
  }

  trackChanges(route) {
    if (route && route.assignees) this.fetchOutgoing()
    if (this.item.id && (!this.item.routes || this.item.routes.length === 0))
      this.fetchDraft()
  }

  fetchOutgoing() {
    let outgoingStore = EditorStore.editors.get('Tasks/#outgoing')
    if (outgoingStore && outgoingStore.pathname) {
      outgoingStore.forceGetTasks({ url: outgoingStore.pathname })
    }
  }

  fetchDraft() {
    let draftStore = EditorStore.editors.get('Tasks/#draft')
    if (draftStore && draftStore.pathname) {
      draftStore.forceGetTasks({ url: draftStore.pathname })
    }
  }

  fetchIncoming() {
    let IncomingStore = EditorStore.editors.get('Tasks/#incoming')
    if (IncomingStore && IncomingStore.pathname) {
      IncomingStore.forceGetTasks({ url: IncomingStore.pathname })
    }
  }

  fetchArchive() {
    let archiveStore = EditorStore.editors.get('Tasks/#archive')
    if (archiveStore && archiveStore.pathname) {
      archiveStore.forceGetTasks({ url: archiveStore.pathname })
    }
  }

  static async sendFile(pathname, item, k) {
    if (
      (typeof File !== 'undefined' && item[k] instanceof File) ||
      item[k] instanceof NativeFile
    ) {
      let form = new FormData()
      form.append('file', item[k])
      let { data } = await requester.put(`${pathname}`, form)
      item[k] = data.link
    }
  }

  @action
  toggleSendForm = () => {
    this.showSendForm = !this.showSendForm
    !this.showSendForm && BUSelectStore.clearSelections('tasks')
  }

  @action
  closeSendForm = () => {
    if (this.showSendForm) this.toggleSendForm()
    else {
      !this.showSendForm && BUSelectStore.clearSelections('tasks')
    }
  }

  @action
  showImageView = file => {
    this.isImageView = true
    const file_key = fileKey(file)
    const i = this.attached_images.findIndex(f => fileKey(f) === file_key)
    this.currentImageIndex = i !== -1 ? i : 0
  }

  @action
  hideImageView = () => (this.isImageView = false)

  @action
  switchRemoveMode = () => {
    this.removeFilesMode = !this.removeFilesMode
  }

  clearSendForm() {
    this.route_assignees.replace([])
    this.route_description = ''
    this.route_due_date = ''
    this.route_due_time = '18:00'
    this.comment = ''
  }
}

const store = new TaskStore()
export default store
