import isString from 'lodash/isString'

const validationCache = new Map()

// noinspection SpellCheckingInspection
const allowed_symbols = new Set([
  ...`ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz`.split(''),
  ...`0123456789`.split(''),
  ...` \n$%&()*+,-./:;<=>?\\^_{|}~`.split(''),
])

const isAllowed = s => allowed_symbols.has(s)

export default function validate(expression) {
  if (!isString(expression)) {
    return false
  }
  if (validationCache.has(expression)) {
    return validationCache.get(expression)
  }
  const is_valid = expression.split('').every(isAllowed)
  validationCache.set(expression, is_valid)
  return is_valid
}
