import { action, computed, makeObservable } from 'mobx'
import moment from 'moment/moment'
import { setModelValue } from '../common/utils'
import { SelectionStore } from './SelectionStore'

export class RoutelistStore extends SelectionStore {
  constructor() {
    super()
    makeObservable(this)
  }

  isReadOnly() {
    return !!this.item?.end_date || super.isReadOnly()
  }

  @computed get difference_value() {
    return (
      this.item.data?.end_value -
        this.item.data?.start_value +
        this.item.data?.individual_km || 0
    )
  }

  @computed get total_difference_value() {
    return this.items.reduce((sum, i) => {
      return (
        sum +
        (i.data.difference_value !== undefined ? i.data.difference_value : 0)
      )
    }, 0)
  }

  @action addStartCoordinates = (latitude, longitude) => {
    const { data } = this.item
    if (!data) return
    setModelValue(data, 'latitude_start', latitude)
    setModelValue(data, 'longitude_start', longitude)
  }

  @action addEndCoordinates = (latitude, longitude) => {
    const { data } = this.item
    if (!data) return
    setModelValue(data, 'latitude_end', latitude)
    setModelValue(data, 'longitude_end', longitude)
  }

  @action
  setEndDate = () => {
    if (this.item.data) {
      this.item.end_date = moment().format('YYYY-MM-DD HH:mm:ss')
      this.item.data.difference_value = this.difference_value
    }
  }
}

const store = new RoutelistStore()
export default store
