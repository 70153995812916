import { getDeepProductsMultiple } from './getDeepProducts'

export default function findDeepProducts(
  products_id,
  excluded,
  rel_map,
  isDeepFetched,
  getProduct,
) {
  if (!products_id.every(isDeepFetched)) return []
  return getDeepProductsMultiple(products_id, rel_map, excluded, getProduct)
}
