import { inject, observer } from 'mobx-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import DatePicker from 'react-datepicker'
import { MdChevronLeft, MdChevronRight } from 'react-icons/lib/md'
import { getModelValue, setModelValue } from '../../common/utils'

@inject('store')
@observer
export class FormDateTimePicker extends React.Component {
  static propTypes = {
    model: PropTypes.object,
    name: PropTypes.string,
    allowPast: PropTypes.bool,
    yearJump: PropTypes.bool,
    displayCalendars: PropTypes.number,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    canClear: PropTypes.bool,
    allowArrows: PropTypes.bool,
  }

  static defaultProps = {
    readOnly: false,
    canClear: true,
    allowPast: true,
    allowArrows: true,
  }

  state = { width: window.innerWidth }

  componentDidMount() {
    this.updateWindowDimensions()
    $(window).resize(this.updateWindowDimensions)
  }

  componentWillUnmount() {
    $(window).off('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => this.setState({ width: window.innerWidth })

  get value() {
    let { model, name, value } = this.props
    let item = getModelValue(model, name, value)
    return item ? moment(item, 'YYYY-MM-DD HH:mm') : null
  }

  get allow_past() {
    const { allowPast } = this.props
    return (
      allowPast ||
      (this.value ? moment().endOf('day').isBefore(this.value) : false)
    )
  }

  onChange = value => {
    const date = value ? value.format('YYYY-MM-DD HH:mm') : null
    const { model, name, onChange, canClear, readOnly } = this.props
    if (!readOnly && (date || canClear)) {
      setModelValue(model, name, date)
      onChange && onChange(date)
    }
  }

  changeDate = (inc = true) => {
    const { model, name, value } = this.props
    const v = getModelValue(model, name, value)
    let d = v ? moment(v) : moment()
    if (d.isValid()) {
      d = !v
        ? d
        : inc
        ? d.add(1, 'day')
        : this.allow_past
        ? d.subtract(1, 'day')
        : d
      this.onChange(d)
    }
  }

  nextDate = () => this.changeDate()

  prevDate = () => this.changeDate(false)

  render() {
    const { allowPast, placeholder, readOnly, canClear, allowArrows } =
      this.props
    const with_rows = allowArrows && !readOnly
    const myDatePicker = (
      <DatePicker
        selected={this.value}
        onChange={this.onChange}
        dateFormat='DD-MM-YYYY HH:mm'
        isClearable={canClear && !readOnly}
        readOnly={readOnly || this.props.store.AppStore.isBusy}
        placeholderText={placeholder || 'Выберите дату'}
        minDate={allowPast ? null : moment()}
        todayButton='Сегодня'
        className={with_rows ? null : 'dp-no-arrows'}
        withPortal={this.width < 768}
        showYearDropdown
        showMonthDropdown
        showTimeSelect
        timeFormat='HH:mm'
        dropdownMode='select'
      />
    )
    return (
      <div className='the-date-picker'>
        {with_rows ? (
          <div className='dp-arrows'>
            <button
              type='button'
              className='arrow arrow-left'
              onClick={this.prevDate}
              disabled={!this.allow_past}
            >
              <MdChevronLeft size={20} />
            </button>
            {myDatePicker}
            <button
              type='button'
              className='arrow arrow-right'
              onClick={this.nextDate}
            >
              <MdChevronRight size={20} />
            </button>
          </div>
        ) : (
          myDatePicker
        )}
      </div>
    )
  }
}

export default FormDateTimePicker
