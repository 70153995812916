function addHiddenProp(object, propName, value) {
  Object.defineProperty(object, propName, {
    enumerable: false,
    writable: true,
    configurable: true,
    value: value,
  })
}

function defineBound(target, propertyName, fn) {
  addHiddenProp(target, propertyName, fn.bind(target))
}

export default function bind(
  target,
  propertyName,
  descriptor,
  applyToInstance,
) {
  if (applyToInstance === true) {
    defineBound(target, propertyName, descriptor.value)
    return null
  }
  if (descriptor)
    return {
      configurable: true,
      enumerable: false,
      get() {
        defineBound(this, propertyName, descriptor.value)
        return this[propertyName]
      },
      set(v) {},
    }
  return {
    enumerable: false,
    configurable: true,
    set(v) {
      defineBound(this, propertyName, v)
    },
    get() {
      return undefined
    },
  }
}
