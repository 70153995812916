export async function fetchMultipleDeepProducts(
  products_id,
  store,
  isDeepProductsFetching,
  fetchProducts,
) {
  const deep_products = {}
  let i = 0
  const queue = products_id
  while (queue.length > i) {
    if (!isDeepProductsFetching()) throw new Error('Fetching canceled')
    if (!deep_products[queue[i]]) {
      deep_products[queue[i]] = await fetchProducts(queue[i], store)
      queue.push(...deep_products[queue[i]].map(p => p.id))
    }
    i++
  }
  return deep_products
}

export default async function fetchDeepProducts(
  product_id,
  store,
  isDeepProductsFetching,
  fetchProducts,
) {
  return await fetchMultipleDeepProducts(
    [product_id],
    store,
    () => isDeepProductsFetching(product_id),
    fetchProducts,
  )
}
