import { action, computed, observable, makeObservable } from 'mobx'
import requester from '../common/requester'
import { printPdfReport, reportFromTemplate } from '../common/utils'

export class PrintableStore {
  @observable templates = observable.array()
  @observable open_viewer_modal = false
  @observable template = {}
  report = null

  url = ''

  constructor() {
    makeObservable(this)
  }

  @computed get is_viewer_modal_open() {
    return (
      (this.open_viewer_modal && this.template.long_template?.length > 0) ||
      false
    )
  }

  fetchTemplates = async url => {
    const paths = url.split('/')
    if (paths.length === 2 || (paths.length === 3 && paths[2] !== 'add')) {
      this.url = url
      const { data } = await requester.get('/report-query' + url + '/templates')
      this.url === url && this.setTemplates(data.list)
    }
  }

  @action setTemplates = templates => this.templates.replace(templates)

  fetchTemplateParams = async template => {
    const { data } = await requester.get(
      '/report-query/template/' + template.code + '/params',
    )
    return data.item
  }

  selectTemplate = async template =>
    this.setTemplate(await this.fetchTemplateParams(template))

  @action setTemplate = template => {
    this.template = { ...template }
    this.report = null
    Object.keys(this.template).length > 0
      ? this.openViewerModal()
      : this.hideViewerModal()
  }

  @action clearTemplate = () => (this.template = {})

  @action openViewerModal = () => (this.open_viewer_modal = true)

  @action hideViewerModal = () => (this.open_viewer_modal = false)

  buildReport = async () => {
    const { long_template, data } = this.template
    this.report = reportFromTemplate(
      long_template,
      await this.fetchTemplateResults(data),
    )
  }

  printTemplate = async () => {
    await this.printTemplateToPfd(this.template)
    this.hideViewerModal()
  }

  fetchTemplateResults = async ({ queries }) => {
    const { data } = await requester.post(
      '/report-query/results',
      queries.reduce(this.reduceQueryProps, {}),
    )
    return data.results
  }

  printTemplateToPfd = async ({ long_template, data }) => {
    await printPdfReport(long_template, await this.fetchTemplateResults(data))
  }

  reduceQueryProps = (params, { param, results_param, query_id, props }) => ({
    ...params,
    [param]: { query_id, props, results_param },
  })

  clear = () => {
    this.url = ''
    this.open_viewer_modal = false
    this.template = {}
    this.report = null
    this.templates.clear()
  }
}
