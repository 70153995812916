import { isObservableArray } from 'mobx'
import { observer, PropTypes as MobxTypes } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import { getModelValue, setModelValue } from '../../../common/utils'
import ComputeExpressionSelect from '../../ui/ComputeExpressionSelect'
import FormCheckbox from '../../ui/FormCheckbox'
import FormDatePicker from '../../ui/FormDatePicker'
import { FormInput, FormLabel, NumInput } from '../../ui/FormInput'
import FormPopup from '../../ui/FormPopup'
import { FormSelect } from '../../ui/FormSelect'
import FormTimePicker from '../../ui/FormTimePicker'

@observer
export default class FormParamViewer extends React.Component {
  static propTypes = {
    model: MobxTypes.observableObject,
    name: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
  }

  components = {
    VARCHAR: FormInput,
    NUMERIC: NumInput,
    BOOLEAN: FormCheckbox,
    DATE: FormDatePicker,
    INTERVAL: FormTimePicker,
    ENUM: FormSelectParam,
    DICT: FormPopup,
    COMPUTED_ENUM: ComputeExpressionSelect,
  }

  static_props = {
    COMPUTED_ENUM: { canClear: true },
    ENUM: { canClear: true },
    DICT: { canClear: true },
  }

  render() {
    const { model, name, display_name, type, readOnly, ...props } = this.props
    const Component = this.components[type]
    return (
      !!Component && (
        <FormLabel label={display_name} readOnly={readOnly}>
          <Component
            {...props}
            {...this.static_props[type]}
            readOnly={readOnly}
            model={model}
            name={name}
          />
        </FormLabel>
      )
    )
  }
}

@observer
class FormSelectParam extends React.Component {
  static propTypes = { ...FormSelect.propTypes }
  componentDidMount() {
    const { model, name, options = [] } = this.props
    const value = getModelValue(model, name, null)
    if (
      !value &&
      (Array.isArray(options) || isObservableArray(options)) &&
      options.length > 0
    ) {
      setModelValue(model, name, options[0].value)
    }
  }

  render() {
    return <FormSelect {...this.props} />
  }
}
