export const DOC_TYPE_INCOMING = 1
export const DOC_TYPE_OUTGOING = 2
export const DOC_TYPE_PRODUCT_RECEIPT = 3
export const DOC_TYPE_PRODUCT_RELEASE = 4
export const DOC_TYPE_ADVANCE_RECEIPT = 5
export const DOC_TYPE_LOAN_ISSUE = 6
export const DOC_TYPE_ANY_TICKET = 7
export const DOC_TYPE_EXCHANGE = 9
export const DOC_TYPE_TRANSFER = 10
export const DOC_TYPE_PRODUCT_TRANSFER = 11
export const DOC_TYPE_PRODUCT_WRITE_OFF = 12
export const DOC_TYPE_RECURRING = 13
export const DOC_TYPE_PRODUCT_INVENTORY = 14
export const DOC_TYPE_SWAP_ROUTES = 15
export const DOC_TYPE_REQUEST_TRANSFER = 16
export const DOC_TYPE_CHICKEN_FARMING = 17
export const DOC_TYPE_CHICKEN_INVOICE = 18
export const DOC_TYPE_CONFIRM_PRODUCTION = 19
export const DOC_TYPE_COLLECTION_CASSETTE = 20
export const DOC_TYPE_MOVE_CONTRACTORS = 21

export const CASHIER = 1
export const EXPENSES = 2
export const INCOMES = 3
export const SALE = 4
export const DEBT = 5
export const NOT_PAID_BILLS = 6
export const SURPLUS = 7
export const DEBT_OWN = 8
export const DEBT_CONTRACTOR = 9
export const DEBT_BRANCH = 10
export const SURPLUS_OWN = 11
export const SURPLUS_BRANCH = 12
export const UNDISTRIBUTED = 13

export const MONEY = 14
export const MONEY_CASHIER = 15
export const MONEY_BANK = 16
export const MONEY_PAY24 = 28

export const MONEY_OPERATION = 17
export const MONEY_OPERATION_OTHER = 20
export const MONEY_OPERATION_CAPITAL = 21

export const MONEY_OPERATION_PAYABLE = 22
export const MONEY_OPERATION_RECEIVABLE = 23

export const ADVANCE_RECEIPT = 24
export const LOAN_ISSUE = 25

export const MONEY_OPERATION_PAID = 26
export const MONEY_OPERATION_RECEIVED = 27

export const EXPENSES_OTHER = 48

export const DOCUMENT_SAVED = 1
export const DOCUMENT_COMMITTED = 2
export const DOCUMENT_CANCELLED = 3

export const CONTRACTOR_DOC_DRAFT = 'draft'
export const CONTRACTOR_DOC_REVIEW = 'review'
export const CONTRACTOR_DOC_REJECTED = 'rejected'
export const CONTRACTOR_DOC_ACTIVE = 'active'
export const CONTRACTOR_DOC_ARCHIVE = 'archive'

export const CASSETTE_MODEL_MEI_SMALL = 'Кассета MEI маленькая'
export const CASSETTE_MODEL_MEI_BIG = 'Кассета MEI большая'
export const CASSETTE_BAG = 'Мешок'
export const CASSETTE_CASH = 'Кассета CASH'

export const GOOGLE_MAPS_API_KEY = 'AIzaSyA0pxTPn4Q01lVJgdhbqKej1tnPebLqvAc'
