import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import { QueryParamsParamViewer } from '../report-query/QueryParamsViewer'
import { AutoButton } from '../../ui/FormInput'
import StimulsoftViewer from './StimulsoftViewer'

@observer
export default class ReportTemplatePrintModal extends React.Component {
  static propTypes = { store: PropTypes.object.isRequired }

  render() {
    const { store } = this.props
    const { template } = store
    return (
      <Modal
        isOpen={store.is_viewer_modal_open}
        toggle={store.hideViewerModal}
        onClosed={store.clearTemplate}
        size='xl'
        autoFocus
        keyboard
      >
        <ModalHeader
          close={
            <Row>
              <Col xs='auto'>
                <AutoButton
                  color='warning'
                  onClick={store.hideViewerModal}
                  size='sm'
                >
                  Закрыть
                </AutoButton>
              </Col>
              <Col xs='auto'>
                <AutoButton
                  color='primary'
                  onClick={this.showReportOnViewer}
                  size='sm'
                >
                  Показать
                </AutoButton>
              </Col>
            </Row>
          }
        >
          {template.name}
        </ModalHeader>
        <ModalBody>
          {template.data?.queries?.map(this.renderQueryParams)}
          <hr />
          {!!template.long_template && (
            <StimulsoftViewer onViewer={this.setViewer} />
          )}
        </ModalBody>
      </Modal>
    )
  }

  showReportOnViewer = async () => {
    await this.props.store.buildReport()
    this.applyReportToViewer()
  }

  setViewer = viewer => {
    this.viewer = viewer
    this.applyReportToViewer()
  }

  applyReportToViewer = () => {
    if (this.viewer && this.props.store.report)
      this.viewer.report = this.props.store.report
  }

  renderQueryParams = q => (
    <Card key={q.uuid}>
      <CardHeader>{q.name}</CardHeader>
      <CardBody>
        <QueryParamsParamViewer model={q.props} params={q.params} />
      </CardBody>
    </Card>
  )
}
