import { computed, makeObservable, observable } from 'mobx'
import NomenclatureBase from './NomenclatureBase'
import roundQuantity from './utils/roundQuantity'
import sumActual from './utils/sumActual'
import trim from './utils/trim'

export default class Output extends NomenclatureBase {
  @computed get branches() {
    const { branches } = this.data
    return observable.array(branches || [])
  }

  @computed get group() {
    return trim(this.data.group, null)
  }

  @computed get main() {
    return !!this.data.main
  }

  @computed get only_tara() {
    return this.data.only_tara
  }

  @computed get actual_outputs() {
    // noinspection JSUnresolvedVariable
    const { materials } = this.production.data.process_output || {}
    if (!materials || materials.length < 1) return []
    return materials.filter(m => m.nomenclature_id === this.nomenclature_id)
  }

  @computed get actual() {
    return roundQuantity(this.actual_outputs.reduce(sumActual, 0))
  }

  @computed get compute() {
    return trim(this.data.compute, null)
  }

  @computed get round() {
    return !!this.data.round
  }

  @computed get tara() {
    return this.data.tara
  }

  @computed get tara_details_value_exclude() {
    return this.data.tara_details_value_exclude || false
  }

  @computed get branch_items() {
    return this.branches
      .map(b => {
        const reducer = (total, o) =>
          total +
          ((o.branch_id === b.id && o.actual && parseFloat(o.actual)) || 0)
        return {
          ...b,
          quantity: roundQuantity(this.actual_outputs.reduce(reducer, 0)),
        }
      })
      .filter(b => b.quantity !== 0)
  }

  @computed get tara_items() {
    return this.actual_outputs.reduce((items, o) => {
      if (o.buffer && o.buffer.length > 0) {
        o.buffer.forEach(b => {
          const branch =
            (this.branches.find(({ id }) => id === b.branch_id) || {}).name ||
            ''
          const quantity = (b.quantity && parseFloat(b.quantity)) || 0
          items.push({
            ...b,
            quantity,
            branch,
          })
        })
      }
      return items
    }, [])
  }

  constructor(data, production) {
    super(data, production)
    makeObservable(this)
  }

  toJSON() {
    return {
      ...super.toJSON(),
      branches: this.branches,
      group: this.group,
      main: this.main,
      actual: this.actual,
      compute: this.compute,
      round: this.round,
    }
  }
}
