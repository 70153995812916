import isFinite from 'lodash/isFinite'
import { action, computed, makeObservable, observable } from 'mobx'
import requester from '../common/requester'
import { getModelValue } from '../common/utils'
import AppStore from './AppStore'
import BaseStore from './BaseStore'
import { validateItem } from './editors/StockTara'
import { SelectionStore } from './SelectionStore'

class TaraStore {
  @observable show_scanner = false
  @observable search_mode = false
  @observable identity = ''
  @observable items = observable.array()
  @observable destination = {
    action: null,
    items: [],
    identity: '',
    branch_id: null,
    branch: null,
  }

  @observable identity_details = observable.map()
  queue = new Set()

  constructor() {
    makeObservable(this)
  }

  clearStore() {
    this.show_scanner = false
    this.identity = ''
    this.items = []
    this.destination = {
      action: null,
      items: [],
      identity: '',
      branch_id: null,
      branch: null,
    }
  }

  @action hideScanner = () => {
    this.show_scanner = false
  }

  @action showScanner = () => {
    this.show_scanner = true
  }

  @computed get can_view() {
    const { roles = [] } = AppStore.user_info || {}
    return roles.findIndex(this.findMenuTara) !== -1
  }

  findMenuTara = r => {
    const { tara = {} } = r.data || {}
    return tara.view || false
  }

  @computed get can_edit() {
    return AppStore.user_accesses.tara?.includes('edit') || false
  }

  findMenuTaraEdit = r => (r.data && r.data.tara && r.data.tara.edit) || false

  searchTara = async identity => {
    const { data } = await requester.get('/tara/search/' + identity)
    const items = data.list.map(({ tara_identity = null, ...item }) => ({
      is_selected: false,
      tara_identity,
      ...item,
    }))
    if (items.length === 0) {
      AppStore.showWarning('Данные не найдены')
    } else {
      this.setItems(items, identity)
    }
  }

  setItems = (items, identity) => {
    this.items.replace(items)
    this.identity = identity
  }

  @computed get has_selected() {
    return this.items.findIndex(i => i.is_selected) !== -1
  }

  @computed get selected_items() {
    return this.items.filter(i => i.is_selected)
  }

  canSelect(item) {
    if (!this.has_selected) return true
    if (!item.tara_identity && !item.is_selected) return false
    return item.is_selected || !!this.selected_items[0].tara_identity
  }

  @action onSelectItem = item => {
    if (this.canSelect(item)) {
      this.disableSearchMode()
      item.is_selected = !item.is_selected
    }
  }

  @action selectAll = () => {
    if (this.has_selected && !this.selected_items[0].tara_identity) {
      return
    }
    this.disableSearchMode()
    if (this.items.length === 1) {
      this.items[0].is_selected = true
    } else {
      this.items.forEach(i => {
        if (i.tara_identity) i.is_selected = true
      })
    }
  }

  @action clearSelections = () => {
    this.items.forEach(i => (i.is_selected = false))
  }

  @action disableSearchMode = () => {
    if (this.search_mode) this.search_mode = false
  }

  @action enableSearchMode = () => {
    if (!this.has_selected) this.search_mode = true
  }

  clearTara = async () => {
    let items = this.selected_items.slice()
    await requester.post('/tara/items/remove', { items })
    this.clearSelected(items)
  }

  @action clearSelected = items => {
    let new_items =
      items.length > 0
        ? this.items.filter(
            i =>
              items.findIndex(i2 => {
                return (
                  (i.tara_identity
                    ? i.tara_identity === i2.tara_identity
                    : !i2.tara_identity) &&
                  i.branch_id === i2.branch_id &&
                  i.nomenclature_id === i2.nomenclature_id &&
                  i.product_id === i2.product_id
                )
              }) === -1,
          )
        : this.items.slice()
    this.items.replace(new_items)
    if (this.destination.items.length > 0) {
      this.destination.items = []
    }
  }

  @computed get is_action() {
    const { action, items } = this.destination
    return items.length > 0 && ['replace', 'move'].includes(action)
  }

  @action clearAction = () => {
    this.destination.action = null
    this.destination.items = []
  }

  @action prepareDestination = action => {
    this.destination.action = action
    this.destination.items = this.selected_items.map(({ quantity, ...i }) => ({
      ...i,
      origin: quantity,
      quantity: i.tara_identity ? quantity : null,
    }))
  }

  @action setDestinationIdentity = identity =>
    (this.destination.identity = identity)

  doAction = async () => {
    const { action, identity, branch_id, items } = this.destination
    if (
      (action === 'replace' && identity) ||
      (action === 'move' && branch_id)
    ) {
      await requester.post('/tara/' + action, { items, identity, branch_id })
      this.clearSelected(items)
      identity && this.searchTara(identity)
      branch_id &&
        items[0] &&
        this.searchTara(items[0].tara_identity || items[0].identity)
    } else {
      this.clearSelected([])
    }
  }

  async fetchDetails(identity) {
    this.queue.add(identity)
    try {
      const { data } = await requester.get('/tara/details/' + identity)
      this.setDetails(identity, data)
    } finally {
      this.queue.delete(identity)
    }
  }

  @action setDetails(identity, data) {
    if (data) {
      this.identity_details.set(identity, data)
    } else if (this.identity_details.has(identity))
      this.identity_details.delete(identity)
  }

  getDetails(identity) {
    if (this.identity_details.has(identity)) {
      return this.identity_details.get(identity)
    }
    !this.queue.has(identity) && this.fetchDetails(identity)
    return null
  }
}

export default new TaraStore()

export class TaraEditStore extends BaseStore {
  @observable show_add_tara_product = false
  @observable add_tara_product = {
    identity: '',
    nomenclature_id: null,
    branch_id: null,
    quantity: null,
  }

  @observable show_edit_tara_product = false
  @observable edit_tara_product = {}
  @observable tara_history_page = 1
  @observable tara_history_count = -1
  @observable tara_history = observable.array()

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get total_item() {
    return reduceItems(getModelValue(this.item, 'materials', []))
  }

  @computed get can_add_tara_product() {
    const { identity, nomenclature_id, branch_id, quantity } =
      this.add_tara_product
    return (
      isFinite(parseFloat(quantity)) &&
      nomenclature_id !== null &&
      branch_id !== null &&
      identity.trim().length > 0
    )
  }

  @computed get can_edit_tara_product() {
    return isFinite(parseFloat(this.edit_tara_product.quantity))
  }

  @computed get taraHistoryPagesIsEnd() {
    let pages = this.tara_history_count
    if (pages > 40) return Math.ceil(pages / 40) > this.tara_history_page
    return false
  }

  clearItem() {
    this.show_add_tara_product = false
    this.add_tara_product = {
      identity: '',
      nomenclature_id: null,
      branch_id: null,
      quantity: null,
    }
    this.show_edit_tara_product = false
    this.edit_tara_product = {}
    this.tara_history_page = 1
    this.tara_history_count = -1
    this.tara_history = observable.array()
    super.clearItem()
  }

  @action showAddTaraProduct = () => (this.show_add_tara_product = true)
  @action hideAddTaraProduct = () => {
    this.show_add_tara_product = false
    this.add_tara_product = {
      identity: '',
      nomenclature_id: null,
      branch_id: null,
      quantity: null,
    }
  }
  @action toggleAddTaraProduct = () =>
    this.show_add_tara_product
      ? this.hideAddTaraProduct()
      : this.showAddTaraProduct()
  addTaraProduct = async () => {
    await requester.post('/tara/items/add', {
      items: [{ ...this.add_tara_product, tara_identity: this.item.identity }],
    })
    this.hideAddTaraProduct()
  }

  @action showEditTaraProduct = item => {
    this.show_edit_tara_product = true
    const { identity, product_id, nomenclature_id, branch_id, quantity } = item
    this.edit_tara_product = {
      identity,
      product_id,
      nomenclature_id,
      branch_id,
      quantity,
    }
  }
  @action hideEditTaraProduct = () => {
    this.show_edit_tara_product = false
    this.edit_tara_product = {}
  }

  @action setTaraHistory = (tara_history, count) => {
    this.tara_history = tara_history
    this.tara_history_count = count
  }

  @action addTaraHistory = tara_history => {
    this.tara_history.push.apply(this.tara_history, tara_history)
  }

  showTaraHistory = async () => {
    let { data } = await requester.get(
      `/tara/tara-history/` + this.item.identity + '?page=1',
    )
    this.setTaraHistory(data.list, data.count)
  }

  incTaraHistory = async () => {
    if (this.taraHistoryPagesIsEnd) {
      let { data } = await requester.get(
        `/tara/tara-history/` +
          this.item.identity +
          '?page=' +
          (this.tara_history_page + 1),
      )
      if (data) {
        this.tara_history_page++
      }
      this.addTaraHistory(data.list)
    }
  }

  editTaraProduct = async () => {
    const { product_id, nomenclature_id, branch_id, quantity } =
      this.edit_tara_product
    await requester.post('/tara/items/update', {
      items: [
        {
          product_id,
          nomenclature_id,
          branch_id,
          quantity,
          tara_identity: this.item.identity,
        },
      ],
    })
    this.hideEditTaraProduct()
  }

  removeTaraItem = async item =>
    await requester.post('/tara/items/remove', {
      items: [{ ...item, tara_identity: this.item.identity }],
    })

  setSingle(item) {
    super.setSingle(item.id ? item : validateItem(item))
  }

  async postData(pathname, item = this.item) {
    return await super.postData(pathname, item.id ? item : validateItem(item))
  }
}

const TARA_ITEM = {
  tara_identity: null,
  name: null,
  branch: null,
  quantity: 0,
  unit: null,
  identity: null,
}

const reduceItems = items =>
  items.reduce(
    (ret, item) => {
      ;['tara_identity', 'name', 'branch', 'unit', 'identity'].forEach(k => {
        if (ret[k] === null && item[k]) {
          ret[k] = item[k]
        } else if (ret[k] !== item[k] && item[k]) {
          ret[k] = ''
        }
      })

      if (item.quantity) {
        ret.quantity += parseFloat(item.quantity)
      }

      return ret
    },
    { ...TARA_ITEM },
  ) || TARA_ITEM

export const isTaraItem = item =>
  !!(
    item.tara_identity &&
    item.branch_id &&
    item.nomenclature_id &&
    item.product_id
  )

export class TaraListStore extends SelectionStore {
  @observable show_details_edit = false
  @observable details_edit = { identity: '', data: { tara_details: {} } }
  @observable show_remove_confirm = false
  @observable tara_identity = ''
  @observable items_to_replace = observable.array()
  @observable show_replace_tara_identity_edit = false
  @observable show_transfer_branch_edit = false
  @observable show_transfer_branch_select = false
  @observable branch_id = 0

  constructor() {
    super()
    makeObservable(this)
    this.add_filter_keys(
      'identity',
      'category_id',
      'nomenclature_id',
      'unit_id',
      'with_buffer',
      'with_buffer_less',
      'total_quantity',
      'total_quantity_less',
    )
  }

  @computed get tara_items() {
    return this.items.reduce((items, item) => {
      if (item.materials.length === 0) {
        items.push({
          id: item.id,
          key: item.id,
          tara_identity: item.identity,
        })
      } else {
        item.materials.forEach(m => {
          const branch_id = this.filters.has('branch_id')
            ? this.filters.get('branch_id')
            : null
          const nomenclature_id = this.filters.has('nomenclature_id')
            ? this.filters.get('nomenclature_id')
            : null
          ;(branch_id !== null ? branch_id === m.branch_id : true) &&
            (nomenclature_id !== null
              ? nomenclature_id === m.nomenclature_id
              : true) &&
            items.push({
              ...m,
              id: item.id,
              key: `${item.id}:${m.branch_id}:${m.nomenclature_id}:${m.product_id}`,
              tara_identity: item.identity,
            })
        })
      }
      return items
    }, [])
  }

  @computed get total_item() {
    return reduceItems(this.tara_items)
  }

  @computed get can_edit() {
    return this.access.includes('edit')
  }

  clearItem() {
    this.items_to_replace?.clear()
    this.tara_identity = ''
    this.show_replace_tara_identity_edit = false
    this.show_transfer_branch_edit = false
    this.show_remove_confirm = false
    this.s = false
    this.show_details_edit = false
    this.details_edit = { identity: '', data: { tara_details: {} } }
    super.clearItem()
  }

  @computed get can_submit_details() {
    return this.details_edit.identity.trim().length > 0
  }

  getSelectableItems() {
    return this.tara_items
  }

  toggleSelection(item, key, e) {
    isTaraItem(item) && super.toggleSelection(item, key, e)
  }

  selectAllFilter(item) {
    return isTaraItem(item)
  }

  @action toggleDetailsEdit = () =>
    this.show_details_edit ? this.hideDetailsEdit() : this.showDetailsEdit()
  @action showDetailsEdit = () => (this.show_details_edit = true)
  @action hideDetailsEdit = () => {
    this.show_details_edit = false
    this.details_edit = { identity: '', data: { tara_details: {} } }
  }
  postDetails = async () => {
    await requester.post(
      '/tara/details/' + this.details_edit.identity,
      this.details_edit.data.tara_details,
    )
    this.setFilter('identity', this.details_edit.identity)
    this.hideDetailsEdit()
  }

  @action showRemoveConfirm = () => (this.show_remove_confirm = true)

  @action hideRemoveConfirm = () => (this.show_remove_confirm = false)

  removeSelectedItems = async () => {
    await requester.post('/tara/items/remove', { items: this.selections })
    this.hideRemoveConfirm()
    this.clearSelectionsAll()
    await this.fetchMany()
  }

  @action showReplaceTaraIdentityEdit = () => {
    this.items_to_replace.replace(
      this.selections.map(i => ({ ...i, quantity: parseFloat(i.quantity) })),
    )
    this.show_replace_tara_identity_edit = this.items_to_replace.length > 0
  }

  @action hideReplaceTaraIdentityEdit = () => {
    this.show_replace_tara_identity_edit = false
    this.tara_identity = ''
    this.items_to_replace.clear()
  }

  replaceSelectedItems = async () => {
    if (this.tara_identity) {
      await requester.post('/tara/items/replace', {
        items: this.items_to_replace,
        identity: this.tara_identity,
      })
      this.hideReplaceTaraIdentityEdit()
      this.clearSelectionsAll()
      await this.fetchMany()
    }
  }

  @action showTransferBranchSelect = () => {
    this.show_transfer_branch_select = true
  }

  @action hideTransferBranchSelect = () => {
    this.show_transfer_branch_select = false
  }

  @action showTransferBranchEdit = branch => {
    this.branch_id = branch?.id || 0
    this.show_transfer_branch_edit = false
    if (this.branch_id) {
      this.items_to_replace.replace(
        this.selections.map(i => ({ ...i, quantity: parseFloat(i.quantity) })),
      )
      this.show_transfer_branch_edit = this.items_to_replace.length > 0
    }
  }

  @action hideTransferBranchEdit = () => {
    this.show_transfer_branch_edit = false
    this.show_transfer_branch_select = false
    this.branch_id = 0
    this.items_to_replace.clear()
  }

  transferSelectedItems = async () => {
    if (this.branch_id) {
      await requester.post('/tara/items/transfer', {
        items: this.items_to_replace,
        branch_id: this.branch_id,
      })
      this.hideTransferBranchEdit()
      this.clearSelectionsAll()
      await this.fetchMany()
    }
  }
}
