import requester from '../../common/requester'
import fetchProductionOf from './fetchProductionOf'

export default async function fetchDescendantProducts(product_id, store) {
  if (store.isDescendantProductsFetched(product_id))
    return store.getDescendantProducts(product_id)
  const { data } = await requester.get(`/trace/backward/${product_id}`)
  return data.products
}

export async function fetchDescendantProductProductions(product_id, store) {
  const products = await fetchDescendantProducts(product_id, store)
  const productions = {}
  for (const product of products) {
    if (!store.isDescendantProductsFetching(product_id))
      throw new Error('Fetching canceled')
    if (!productions[product.id]) {
      if (store.isProductionFetched(product.id)) {
        productions[product.id] = store.getProduction(product.id)
      } else {
        const data = await fetchProductionOf(product.id, store)
        productions[product.id] = data.production
        data.products.forEach(p => (productions[p.id] = data.production))
      }
    }
  }
  return { products, productions }
}
