import { action, computed, observable, makeObservable } from 'mobx'
import moment from 'moment'
import sum from 'lodash/sum'
import { isWeb } from '../common/settings'
import requester from '../common/requester'
import AppStore from './AppStore'
import { getStorage } from './Storage'

const make_date_options = () => {
  const today = moment().startOf('day')
  const options = [today, today.clone().add(1, 'day')]
  return options.map(option => ({
    value: option.format('YYYY-MM-DD'),
    text: option.format('Do MMM YYYY'),
  }))
}

const make_date = () => {
  const today = moment().startOf('day')
  const date = today.clone().add(19, 'hours').isBefore(moment())
    ? today.clone().add(1, 'day')
    : today
  return date.format('YYYY-MM-DD')
}

const EMPTY_EDIT_VALUE = isWeb() ? '0' : ''

export const STAGE = {
  AGENT: 'agent',
  NOMENCLATURE: 'nomenclature',
  EDIT: 'edit',
}

function trim(value) {
  return value ? value.trim().toLowerCase() : ''
}

export class ReadyAgentStore {
  queue_disposers = []

  @observable stage = STAGE.AGENT
  @observable _edit_value = EMPTY_EDIT_VALUE
  @observable agent_name = ''
  @observable nomenclature_name = ''
  @observable show_categories_modal = false
  @observable show_tara_edit = false
  @observable show_ready = false

  @observable for_date = make_date()
  @observable date_options = make_date_options()

  @observable branch_id = 0
  @observable branches = observable.array()

  @observable agent_id = 0
  @observable agents = observable.array()

  @observable category_id = 0
  @observable exclude_categories_id = observable.array()
  @observable nomenclature_id = 0
  @observable nomenclatures = observable.array()

  @observable ready_items = observable.array()
  @observable received_items = observable.array()

  @observable quantity_boxes = 0
  @observable quantity_gave_boxes = 0

  @observable boxes = observable.map({})
  @observable gave_boxes = observable.map({})
  @observable tara_quantities = observable.map({})

  constructor() {
    makeObservable(this)
  }

  @computed get is_loaded() {
    return this.branches.length > 0 && this.agents.length > 0
  }

  @computed get is_modal_open() {
    return this.show_tara_edit || this.show_ready || this.show_categories_modal
  }

  @computed get edit_value() {
    return this._edit_value
  }

  set edit_value(value) {
    if (
      !value ||
      /^[+-]$/.test(value) ||
      /^[+-]?\d+\.?\d*([+-]\d+\.?\d*)*[+-]?$/.test(value)
    )
      this._edit_value = value
  }

  @computed get branch() {
    return this.branches.find(branch => branch.id === this.branch_id) || null
  }

  @computed get filtered_agents() {
    const search = trim(this.agent_name)
    return search
      ? this.agents.filter(agent => trim(agent.name).startsWith(search))
      : this.agents.slice()
  }

  @computed get agent() {
    return this.agents.find(agent => agent.id === this.agent_id) || null
  }

  @computed get agent_branch_id() {
    return this.agent?.branch_id || 0
  }

  @action selectAgent = agent => {
    const next = this.agent_id === agent.id
    this.agent_id = agent.id
    this.stage !== STAGE.AGENT && this.setStageAgent()
    next && this.nextStage()
  }

  @computed get categories() {
    return Object.values(
      this.nomenclatures.reduce(
        (categories, n) => ({
          ...categories,
          [n.category_id]: {
            id: n.category_id,
            name: n.category,
          },
        }),
        {},
      ),
    ).sort((a, b) => a.name.localeCompare(b.name))
  }

  @computed get filtered_categories() {
    return this.categories.filter(
      category => this.exclude_categories_id.indexOf(category.id) === -1,
    )
  }

  @computed get category() {
    return this.categories.find(category => category.id === this.category_id)
  }

  @computed get is_categories_filtered() {
    return this.categories.length !== this.filtered_categories.length
  }

  @computed get show_category_filter() {
    return this.categories.length > 1
  }

  @computed get filtered_nomenclatures() {
    const search = trim(this.nomenclature_name)
    return search || this.show_category_filter
      ? this.nomenclatures.filter(n => {
          return (
            (!search || trim(n.name).startsWith(search)) &&
            this.exclude_categories_id.indexOf(n.category_id) === -1
          )
        })
      : this.nomenclatures.slice()
  }

  @computed get grouped_nomenclatures() {
    return this.filtered_nomenclatures.reduce((items, item) => {
      const prev = items[items.length - 1]
      ;(!prev || prev.category !== item.category) && items.push(item.category)
      items.push(item)
      return items
    }, [])
  }

  @computed get nomenclature() {
    return (
      this.nomenclatures.find(
        nomenclature => nomenclature.id === this.nomenclature_id,
      ) || null
    )
  }

  @computed get quantity() {
    const quantities = this.nomenclature?.quantities || {}
    return quantities[this.branch_id] || 0
  }

  @action selectCategory = category => (this.category_id = category.id)

  @action selectNomenclature = nomenclature => {
    const next = this.nomenclature_id === nomenclature.id
    this.nomenclature_id = nomenclature.id
    this.stage !== STAGE.NOMENCLATURE && this.setStageNomenclature()
    next && this.nextStage()
  }

  matchReadyItem = i => {
    return (
      i.branch_id === this.agent_branch_id &&
      i.nomenclature_id === this.nomenclature_id &&
      i.date === this.for_date
    )
  }

  @computed get ready_quantity() {
    return this.ready_items.find(this.matchReadyItem)?.quantity || 0
  }

  @computed get received_quantity() {
    return this.received_items.find(this.matchReadyItem)?.quantity || 0
  }

  @computed get ready_products() {
    const filter = item =>
      item.branch_id === this.agent_branch_id && item.date === this.for_date

    const items = [
      ...this.ready_items.filter(filter),
      ...this.received_items.filter(filter),
    ]

    const grouped = items.reduce((group, item) => {
      const key = item.category_id
      if (!group[key]) {
        group[key] = {
          key,
          category: item.category,
          data: [],
          units_quantity: [],
        }
      }
      let u_item_index = group[key].units_quantity.findIndex(
        f => f.unit_id === item.unit_id,
      )
      if (u_item_index === -1) {
        group[key].units_quantity.push({
          unit_id: item.unit_id,
          quantity: item.quantity,
          unit: item.unit,
        })
      } else {
        group[key].units_quantity[u_item_index].quantity += item.quantity
      }
      group[key].data.push(item)
      return group
    }, {})

    return Object.values(grouped)
  }

  @computed get box_quantity() {
    const id = `${this.agent_branch_id}`
    return this.boxes.has(id) ? this.boxes.get(id) : 0
  }

  @computed get box_gave_quantity() {
    const id = `${this.agent_branch_id}`
    return this.gave_boxes.has(id) ? this.gave_boxes.get(id) : 0
  }

  @computed get tara_quantity() {
    const id = `${this.branch_id}`
    return this.tara_quantities.has(id) ? this.tara_quantities.get(id) : 0
  }

  @action clearReadyAgentData() {
    this.queue_disposers.forEach(disposer => disposer())
    this.queue_disposers = []

    this.show_ready = false
    this.show_tara_edit = false
    this.show_categories_modal = false
    this.nomenclature_name = ''
    this.agent_name = ''
    this.edit_value = EMPTY_EDIT_VALUE
    this.stage = STAGE.AGENT

    this.for_date = make_date()
    this.date_options = make_date_options()

    this.branch_id = 0
    this.branches.clear()

    this.agent_id = 0
    this.agents.clear()

    this.exclude_categories_id.clear()
    this.nomenclature_id = 0
    this.nomenclatures.clear()

    this.ready_items.clear()
    this.received_items.clear()

    this.quantity_boxes = 0
    this.quantity_gave_boxes = 0

    this.boxes.clear()
    this.gave_boxes.clear()
    this.tara_quantities.clear()
  }

  withDispose = async cb => {
    let active = true
    const disposer = () => (active = false)
    this.queue_disposers.push(disposer)
    try {
      const data = await cb()
      if (!active) throw new Error('Canceled')
      return data
    } finally {
      const i = this.queue_disposers.indexOf(disposer)
      i !== -1 && this.queue_disposers.splice(i, 1)
    }
  }

  @action setReadyAgentData(data, categories_id) {
    const {
      branches,
      agents,
      nomenclatures,
      ready_items,
      received_items,
      boxes,
      gave_boxes,
      tara_quantities,
    } = data

    this.branches.replace(branches)
    this.branch_id = this.branches[0]?.id || 0

    this.agents.replace(agents)
    this.agent_id = this.agents[0]?.id || 0

    this.nomenclatures.replace(nomenclatures)
    this.exclude_categories_id.replace(categories_id)
    this.nomenclature_id =
      this.filtered_nomenclatures.length > 0
        ? this.filtered_nomenclatures[0].id
        : 0

    this.ready_items.replace(ready_items)
    this.received_items.replace(received_items)

    this.boxes.replace(boxes)
    this.gave_boxes.replace(gave_boxes)
    this.tara_quantities.replace(tara_quantities)
  }

  fetchReadyAgentData = async () => {
    this.clearReadyAgentData()
    const { data } = await this.withDispose(() =>
      requester.get('/ready-agents/all'),
    )
    const categories_id = []
    try {
      categories_id.push(
        ...(await getStorage().load({ key: 'ready-agent-exclude-categories' })),
      )
    } catch {}
    this.setReadyAgentData(data, categories_id)
  }

  @action selectNext = e => {
    if (this.show_tara_edit || this.show_ready) return
    e.preventDefault()

    const [items, item, selectItem] = this.show_categories_modal
      ? [this.categories, this.category, this.selectCategory]
      : this.stage === STAGE.AGENT
      ? [this.filtered_agents, this.agent, this.selectAgent]
      : [
          this.filtered_nomenclatures,
          this.nomenclature,
          this.selectNomenclature,
        ]

    if (item) {
      const i = items.indexOf(item)
      const j = items.length > i + 1 ? i + 1 : 0
      selectItem(items[j])
    } else if (items.length > 0) {
      selectItem(items[0])
    }
  }

  @action selectPrev = e => {
    if (this.show_tara_edit || this.show_ready) return
    e.preventDefault()

    const [items, item, selectItem] = this.show_categories_modal
      ? [this.categories, this.category, this.selectCategory]
      : this.stage === STAGE.AGENT
      ? [this.filtered_agents, this.agent, this.selectAgent]
      : [
          this.filtered_nomenclatures,
          this.nomenclature,
          this.selectNomenclature,
        ]

    if (item) {
      const i = items.indexOf(item)
      const j = i > 0 ? i - 1 : items.length - 1
      selectItem(items[j])
    } else if (items.length > 0) {
      selectItem(items[0])
    }
  }

  @action nextStage = e => {
    if (this.show_tara_edit || this.show_ready || this.show_categories_modal)
      return
    e && e.preventDefault()
    this.stage =
      this.stage === STAGE.AGENT
        ? STAGE.NOMENCLATURE
        : this.stage === STAGE.NOMENCLATURE
        ? STAGE.EDIT
        : STAGE.AGENT
  }

  @action prevStage = e => {
    if (this.show_tara_edit || this.show_ready || this.show_categories_modal)
      return
    e && e.preventDefault()
    this.stage =
      this.stage === STAGE.AGENT
        ? STAGE.EDIT
        : this.stage === STAGE.NOMENCLATURE
        ? STAGE.AGENT
        : STAGE.NOMENCLATURE
  }

  @action setStageAgent = () => (this.stage = STAGE.AGENT)

  @action setStageNomenclature = () => (this.stage = STAGE.NOMENCLATURE)

  @action setStageEdit = () => (this.stage = STAGE.EDIT)

  @action valueAppend = value => {
    value = value === ',' ? '.' : value
    if (value !== '+' && value !== '-') {
      if (/^[+-]?0$/.test(this.edit_value) && value !== '.') {
        this.edit_value = this.edit_value.replace('0', value)
      } else if (value !== '.' || /(^|[+-])\d+$/.test(this.edit_value)) {
        this.edit_value = this.edit_value.concat(value)
      }
    } else if (/^[+-]?0\.?0*$/.test(this.edit_value)) {
      const _sign = this.edit_value[0]
      if (value === '+' && _sign === '-') {
        this.edit_value = this.edit_value.substr(1)
      } else if (value === '-') {
        this.edit_value =
          (_sign === '-' || _sign === '+' ? '' : '-' + _sign) +
          this.edit_value.substr(1)
      }
    } else if (
      '.+-'.indexOf(this.edit_value[this.edit_value.length - 1]) !== -1
    ) {
      this.edit_value = this.edit_value
        .substr(0, this.edit_value.length - 1)
        .concat(value)
    } else {
      this.edit_value = this.edit_value.concat(value)
    }
    this.stage !== STAGE.EDIT && this.setStageEdit()
  }

  @action backspace = () => {
    if (this.edit_value === '-0') {
      this.edit_value = EMPTY_EDIT_VALUE
    } else {
      this.edit_value = this.edit_value.substr(0, this.edit_value.length - 1)
      if (this.edit_value === '-') this.edit_value = '-0'
      if (this.edit_value === '') this.edit_value = EMPTY_EDIT_VALUE
    }
    this.stage !== STAGE.EDIT && this.setStageEdit()
  }

  @action cleanEditValue = () => (this.edit_value = EMPTY_EDIT_VALUE)

  @computed get send_value() {
    return sum(
      this.edit_value.split(/([+-]\d+.?\d*)/).map(i => {
        return i && i !== '-' && i !== '+' ? parseFloat(i) : 0
      }),
    )
  }

  sendEditValue = async () => {
    const value = this.send_value
    const not_valid = !isFinite(value) || value === 0 || Math.abs(value) < 1e-3
    if (not_valid) return
    const { name, unit } = this.nomenclature
    const message = `${value} ${unit} ${name}`
    this.cleanEditValue()
    try {
      const { data } = await requester.post(
        `/ready-agents/add/${this.for_date}/${this.branch_id}`,
        {
          branch_id: this.agent_branch_id,
          nomenclature_id: this.nomenclature_id,
          quantity: value,
        },
      )
      const { ready_items, received_items } = data
      this.ready_items.replace(ready_items)
      this.received_items.replace(received_items)
      isWeb() ? AppStore.showInfo(message) : AppStore.showToast(message)
      !isWeb() && this.prevStage()
    } catch {
      if (this.edit_value === EMPTY_EDIT_VALUE) this.edit_value = `${value}`
    }
  }

  @action showTaraEdit = e => {
    !this.show_tara_edit && e && e.preventDefault()
    this.show_tara_edit = true
    this.quantity_boxes = this.box_quantity
    this.quantity_gave_boxes = this.box_gave_quantity
  }

  @action hideTaraEdit = e => {
    this.show_tara_edit && e && e.preventDefault()
    this.show_tara_edit = false
    this.quantity_boxes = 0
    this.quantity_gave_boxes = 0
  }

  saveTaraData = async () => {
    try {
      const { data } = await requester.post(
        `/ready-agents/tara/${this.branch_id}`,
        {
          branch_id: this.agent_branch_id,
          quantity: this.quantity_boxes,
          gave_quantity: this.quantity_gave_boxes,
        },
      )
      AppStore.showSuccess('Тара сохранена!')
      this.boxes.replace(data.boxes)
      this.gave_boxes.replace(data.gave_boxes)
      this.tara_quantities.replace(data.tara_quantities)
      this.showTaraEdit()
    } catch {}
  }

  @action showReady = e => {
    !this.show_ready && e && e.preventDefault()
    this.show_ready = true
  }

  @action hideReady = () => (this.show_ready = false)

  @action showCategoriesModal = e => {
    !this.show_categories_modal && e && e.preventDefault()
    if (
      !this.categories.find(category => category.id === this.category_id) &&
      this.categories.length > 0
    ) {
      this.category_id = this.categories[0].id
    }
    this.show_categories_modal = true
  }

  @action hideCategoriesModal = () => {
    this.show_categories_modal = false
    getStorage().save({
      key: 'ready-agent-exclude-categories',
      data: [...this.exclude_categories_id],
    })
  }

  @action toggleCategoryExclude = category => {
    const i = this.exclude_categories_id.indexOf(category.id)
    if (i >= 0) this.exclude_categories_id.splice(i, 1)
    else this.exclude_categories_id.push(category.id)
    this.filtered_nomenclatures.indexOf(this.nomenclature) === -1 &&
      this.filtered_nomenclatures.length > 0 &&
      this.selectNomenclature(this.filtered_nomenclatures[0])
  }

  @action exitModal = e => {
    e.preventDefault()
    this.show_tara_edit && this.hideTaraEdit()
    this.show_ready && this.hideReady()
    this.show_categories_modal && this.hideCategoriesModal()
  }
}

const store = new ReadyAgentStore()
export default store
