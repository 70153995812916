import { action, makeObservable } from 'mobx'
import requester from '../common/requester'
import { SelectionStore } from './SelectionStore'

export class ProductionTemplateStore extends SelectionStore {
  constructor() {
    super()
    makeObservable(this)
  }

  setData(data, count) {
    data.length > 0 && data.map(this.mapItems)
    super.setData(data, count)
  }

  async localGet(...args) {
    if (args.length < 2) args.length = 2
    args[1] = { ...args[1], is_edit: true }
    return super.localGet(...args)
  }

  mapItems = ({ materials, ...item }) => ({
    materials: materials ? materials.sort(this.sortName) : [],
    ...item,
  })

  sortName = ({ name }, b) => (name || '').localeCompare(b.name || '')

  @action addMaterials(selected) {
    let new_materials = selected.filter(
      s =>
        this.item.materials.findIndex(m => m.nomenclature_id === s.id) === -1,
    )
    this.item.materials.push(
      ...new_materials.map(m => ({
        nomenclature_id: m.id,
        nomenclature: m,
        quantity: null,
        branches: [],
      })),
    )
  }

  @action removeMaterial(m) {
    this.item.materials.remove(m)
  }

  postData(pathname, item = this.item, validateFunction) {
    if (validateFunction)
      try {
        validateFunction()
      } catch (e) {
        this.catchPostData(e)
        throw e
      }
    return super.postData(pathname, item)
  }

  ingredientPostValidate = () => {
    if (!this.item.name) throw new Error('Заполните наименование')

    if (!this.item.materials || this.item.materials.length === 0)
      throw new Error('Добавьте материал')

    this.item.materials &&
      this.item.materials.forEach(m => {
        if (!m.nomenclature_id) throw new Error('Укажите материал')
      })
  }

  copyIngredient = async ingredient => {
    await requester.post('/production/copy-ingredient/' + ingredient.id)
    return this.fetchMany()
  }

  multiplyIngredient = async (ingredient, number) => {
    const newIngredient = {
      branch: ingredient.branch,
      branch_id: ingredient.branch_id,
      name: `${ingredient.name} - ${number}`,
      materials: ingredient.materials.map(m => ({
        ...m,
        quantity: parseFloat((m.quantity * number).toFixed(2)),
      })),
    }
    await requester.post('/ingredients/add', newIngredient)
    return this.fetchMany()
  }
}

const store = new ProductionTemplateStore()
export default store
