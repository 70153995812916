/**
 * Created by jaynakus on 7/2/17.
 */
import axios from 'axios'
import { API_URL } from './settings'

const requester = {
  cancelPoll: true,
  cancelPayment: true,
  get: function (url, params = {}, silent = false, config = {}) {
    return axios.get(`${API_URL()}${url}`, {
      silent,
      ...config,
      params,
      headers: { silent },
    })
  },
  post: function (url, data, params = {}, config = {}) {
    return axios.post(`${API_URL()}${url}`, data, { ...config, params })
  },
  put: function (url, data) {
    return axios.put(`${API_URL()}${url}`, data)
  },
  delete: function (url, params = {}) {
    return axios.delete(`${API_URL()}${url}`, { params })
  },
  poll: function (id) {
    this.cancelPoll = false
    return new Promise(async function (resolve) {
      let data = null
      let sleep = ms => {
        return new Promise(r => setTimeout(r, ms))
      }
      do {
        await sleep(1000)
        let resp = await axios.get(`${API_URL()}/payment/poll/${id}`)
        data = resp.data
      } while (!requester.cancelPoll && data.status === 0)
      resolve({ data })
    })
  },
  payment: function (id) {
    this.cancelPayment = false
    return new Promise(async function (resolve) {
      let data = null
      let sleep = ms => {
        return new Promise(r => setTimeout(r, ms))
      }
      do {
        await sleep(1000)
        let resp = await axios.get(`${API_URL()}/payment/status/${id}`)
        data = resp.data
      } while (!requester.cancelPayment && data.status === 'pending')
      resolve({ data })
    })
  },
}

export default requester
