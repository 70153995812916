import { action, makeObservable, observable, observe, set } from 'mobx'
import moment from 'moment'
import requester from '../../common/requester'
import { getStorage } from '../Storage'

export default class ReportBase {
  @observable items = observable.array()
  @observable loading = false
  @observable is_dirty = true
  @observable filters = {}

  url = ''

  constructor(url) {
    makeObservable(this)
    this.url = url
    set(this, 'filters', this.getFiltersInitial())
    this.onChangeFilters = this.onChangeFilters.bind(this)
    this.loadData = this.loadData.bind(this)
    this.setData = this.setData.bind(this)
    this.loadDataFinish = this.loadDataFinish.bind(this)
  }

  getFiltersInitial() {
    return {}
  }

  @action
  onChangeFilters() {
    this.is_dirty = true
  }

  get filter_params() {
    return {}
  }

  @action
  loadData() {
    if (this.loading) return
    this.loading = true
    requester
      .get(this.url, this.filter_params)
      .then(this.setData)
      .finally(this.loadDataFinish)
  }

  @action
  setData({ data }) {
    this.is_dirty = false
    this.items.replace(data.list)
  }

  @action
  loadDataFinish() {
    this.loading = false
    if (!this.filters_disposer)
      this.filters_disposer = observe(this.filters, this.onChangeFilters)
  }

  @action
  clearData() {
    this.filters_disposer && this.filters_disposer()
    this.items.clear()
    set(this, 'filters', this.getFiltersInitial())
    this.is_dirty = true
  }
}

export class ReportCategoriesBase extends ReportBase {
  constructor(url) {
    super(url)
    this.onSelectCategories = this.onSelectCategories.bind(this)
  }

  getFiltersInitial() {
    return {
      start_from: moment().startOf('isoWeek').format('YYYY-MM-DD'),
      end_to: moment().format('YYYY-MM-DD'),
      branch_id: null,
      categories: [],
    }
  }

  get filter_params() {
    let params = {}
    params['filter[start_from]'] = this.filters.start_from || ''
    params['filter[end_to]'] = this.filters.end_to || ''
    if (this.filters.branch_id)
      params['filter[branch_id]'] = this.filters.branch_id
    params['filter[categories]'] = this.filters.categories
      .map(c => c.id)
      .join(',')
    return params
  }

  onSelectCategories(categories) {
    const selections = categories
      ? categories.map(({ id, name }) => ({ id, name }))
      : []
    this.replaceSelectedCategories(selections)
  }

  @action
  replaceSelectedCategories(categories) {
    this.filters.categories.replace(categories)
  }

  @action
  removeCategory(category) {
    this.filters.categories.remove(category)
  }

  getFilterStoreName() {
    return 'by-categories'
  }

  saveCategoriesFilter() {
    getStorage().save({
      key: 'filters',
      id: this.getFilterStoreName(),
      data: this.filters.categories,
    })
  }

  async restoreCategoriesFilter() {
    try {
      const categories = await getStorage().load({
        key: 'filters',
        id: this.getFilterStoreName(),
        data: this.filters.categories,
      })
      categories &&
        Array.isArray(categories) &&
        this.replaceSelectedCategories(categories)
    } catch (e) {}
  }
}
