import { action, computed, makeObservable, observable, values } from 'mobx'
import localDB from '../common/localDB'
import AppStore from './AppStore'
import BaseStore from './BaseStore'
import BUSelectStore from './BUSelectStore'

export class MoveStore extends BaseStore {
  @observable product = null
  @observable product_id = null
  @observable edit_product = -1
  @observable products = observable.array()

  @observable expands = observable.map({})

  constructor() {
    super()
    makeObservable(this)
  }

  setSingle(item) {
    let categories = []
    let products = {}
    for (let p of item.data.products) {
      if (!products[p.nomenclature.category_id]) {
        categories.push(p.nomenclature.category)
        products[p.nomenclature.category_id] = []
      }
      products[p.nomenclature.category_id].push(p)
    }

    this.products.clear()
    for (let c of categories.sort(MoveStore.compareNames)) {
      this.products.push({
        category: c,
        nomenclature: null,
        nomenclature_id: null,
        products: products[c.id].sort(MoveStore.comparePNames),
      })
    }
    if (item.branch2_id) {
      BUSelectStore.addBranchesQueue('move', item.branch2_id, {
        multiple: false,
        selectType: 'branch',
      })
    }
    super.setSingle(item)
  }

  static compareNames(a, b) {
    return a.name.localeCompare(b.name)
  }

  static comparePNames(a, b) {
    return a.nomenclature.name.localeCompare(b.nomenclature.name)
  }

  clearItem() {
    super.clearItem()
    this.products?.clear()
    this.edit_product = -1
  }

  filterDefaults() {
    return { ...super.filterDefaults(), move_status: '' }
  }

  addProducts = async stock => {
    let products = []
    for (const { nomenclature_id, count } of stock) {
      const data = await localDB.get(
        '/nomenclatures/' + nomenclature_id,
        {},
        null,
        true,
      )
      if (!data || !data.item) continue
      const nomenclature = data.item
      products.push({
        nomenclature_id,
        nomenclature,
        quantity: count ? parseFloat(count) : null,
      })
    }
    this.appendProducts(products)
  }

  @action
  appendProducts(products) {
    let cid = []
    for (const product of products) {
      !cid.includes(product.nomenclature.category_id) &&
        cid.push(product.nomenclature.category_id)
      let ci = this.products.findIndex(
        p => p.category.id === product.nomenclature.category_id,
      )
      if (ci === -1) {
        ci =
          this.products.push({
            category: product.nomenclature.category,
            products: [],
            nomenclature_id: null,
            nomenclature: null,
          }) - 1
      }
      const i = this.products[ci].products.findIndex(
        p => !p.id && p.nomenclature_id === product.nomenclature_id,
      )
      if (i === -1) {
        this.products[ci].products.push({
          ...product,
          nomenclature2_id: null,
          nomenclature2: null,
        })
      }
    }
    for (const id of cid)
      !this.expands.has(`${id}`) ||
        (!this.expands.get(`${id}`) && this.expands.set(`${id}`, true))
    this.product = this.product_id = null
  }

  @action.bound
  addQrProduct(identity, quantity, product) {
    if (!product.id || !product.nomenclature) return
    let ci = this.products.findIndex(
      p => p.category.id === product.nomenclature.category_id,
    )
    if (ci === -1) {
      ci =
        this.products.push({
          category: product.nomenclature.category,
          products: [],
          nomenclature_id: null,
          nomenclature: null,
        }) - 1
    }
    const i = this.products[ci].products.findIndex(p => p.id === product.id)
    if (i === -1) {
      this.products[ci].products.push({
        ...product,
        quantity,
        nomenclature2_id: null,
        nomenclature2: null,
      })
    } else {
      this.products[ci].products[i].quantity =
        parseFloat(this.products[ci].products[i].quantity) +
        parseFloat(quantity)
    }
  }

  @action
  removeProduct(product) {
    let ci = this.products.findIndex(
      p => p.category.id === product.nomenclature.category_id,
    )
    if (ci !== -1) {
      this.products[ci].products.remove(product)
      if (this.products[ci].products.length === 0) {
        this.products.remove(this.products[ci])
      }
    }
  }

  @action
  onSelectCategoryNomenclature() {
    const { products, nomenclature } = this
    if (!nomenclature || !products || products.length === 0) return
    for (let p of products) {
      if (p.nomenclature.unit_id === nomenclature.unit_id) {
        p.nomenclature2_id = nomenclature.id
        p.nomenclature2 = { ...nomenclature }
      }
    }
    this.nomenclature = this.nomenclature_id = null
  }

  @computed
  get editableProduct() {
    const { edit_product } = this
    if (edit_product > -1) {
      for (const c of this.products) {
        const product = c.products.find(p => p.id === edit_product)
        if (product) return product
      }
    }
    return null
  }

  @action.bound
  closeEditProduct() {
    this.edit_product = -1
  }

  @action
  editProduct(index) {
    this.edit_product = index
  }

  @action
  setMoveStatus(status) {
    this.prev_status = this.item.move_status
    this.item.move_status = status
  }

  @computed
  get canSend() {
    const { item, access } = this
    return (
      access.includes('send') &&
      item.branch2_id &&
      item.data.products.length > 0
    )
  }

  @computed
  get isSender() {
    return this.access.includes('send')
  }

  @computed
  get canReceive() {
    return (
      this.isReceiver &&
      ['sent', 'preinvalid', 'receive'].includes(this.item.move_status)
    )
  }

  @computed
  get canInvalid() {
    return (
      this.isReceiver &&
      ['sent', 'preinvalid', 'receive'].includes(this.item.move_status)
    )
  }

  @computed
  get isReceiver() {
    return this.access.includes('receive')
  }

  async postData(pathname, item = this.item) {
    let branch2_id = null
    if (BUSelectStore.states.has('move')) {
      const state = BUSelectStore.states.get('move')
      branch2_id =
        state.branch_selections.size > 0
          ? values(state.branch_selections)[0].id
          : null
    }
    item.branch2_id = branch2_id
    const data = await super.postData(pathname, {
      ...item,
      data: {
        ...item.data,
        products: this.products.reduce(MoveStore.reduceCategoryProducts, []),
      },
    })
    ;['send', 'preinvalid', 'receive'].includes(this.item.move_status) &&
      this.fetchItem(pathname)
    return data
  }

  static reduceCategoryProducts(o, c) {
    o.push(...c.products.slice())
    return o
  }

  @action
  catchPostData(e) {
    if (this.item.move_status === 'send') {
      this.setMoveStatus(this.prev_status || 'draft')
    } else if (this.item.move_status === 'receive') {
      this.setMoveStatus(this.prev_status || 'sent')
    } else if (this.item.move_status === 'preinvalid') {
      this.setMoveStatus(this.prev_status || 'sent')
    }
    super.catchPostData(e)
  }

  saveSuccessMessage() {
    const { move_status: ms } = this.item
    if (ms === 'send') {
      AppStore.showSuccess('Отправлен')
    } else if (ms === 'preinvalid') {
      AppStore.showSuccess('Отправлен на поправку')
    } else if (ms === 'receive') {
      AppStore.showSuccess('Принят')
    } else {
      super.saveSuccessMessage()
    }
  }

  @action
  expandCategory(c) {
    this.expands.set(`${c.id}`, !this.expands.get(`${c.id}`))
  }

  get should_save_filter_keys() {
    return [...super.should_save_filter_keys, 'branch2_id', 'move_status']
  }

  productKey = ({ id, identity, nomenclature_id }) =>
    identity ? identity : id ? 'id:' + id : 'nid:' + nomenclature_id
}

const store = new MoveStore()
export default store
