import classNames from 'classnames'
import React from 'react'
import { FaClose, FaPlus } from 'react-icons/lib/fa'
import { Input, InputGroup, InputGroupAddon } from 'reactstrap'
import { AutoButton } from '../FormInput'

const PopupView = ({
  canCreate,
  clearSelect,
  onSelect,
  children,
  createBtn,
  createModal,
  itemId,
  placeholder,
  renderCmp: RenderCmp,
  selectModal,
  toggleCreate,
  toggleSelector,
  value,
  ...props
}) => {
  const is_placeholder = !itemId && placeholder
  if (is_placeholder) value = placeholder
  if (RenderCmp)
    return (
      <React.Fragment>
        <RenderCmp
          {...props}
          onSelect={onSelect}
          clearSelect={clearSelect}
          canCreate={canCreate}
          item_id={itemId}
          toggleCreate={toggleCreate}
          toggleSelector={toggleSelector}
          value={value}
        />
        {selectModal}
        {createModal}
      </React.Fragment>
    )
  return (
    <>
      <PopupInput
        {...props}
        canCreate={canCreate}
        clearSelect={clearSelect}
        createBtn={!!createBtn}
        isPlaceholder={is_placeholder}
        itemId={itemId}
        toggleCreate={toggleCreate}
        toggleSelector={toggleSelector}
        value={value}
      >
        {children}
      </PopupInput>
      {selectModal}
      {createModal}
    </>
  )
}

export default PopupView

const PopupInput = ({
  canCreate,
  clearSelect,
  createBtn,
  isPlaceholder,
  itemId,
  toggleCreate,
  toggleSelector,
  value,
  ...props
}) => {
  let { canClear, readOnly, bsSize, children, ...rest } = props
  let class_names = classNames([
    'popup-control',
    { 'popup-disabled': readOnly, 'popup-placeholder': isPlaceholder },
  ])
  const input = (
    <Input
      {...rest}
      type='button'
      bsSize={bsSize}
      value={value || ''}
      onClick={e => !readOnly && toggleSelector(e)}
      className={class_names}
      readOnly
    />
  )
  const can_clear = !!(canClear && itemId)
  const can_create = !!(canCreate && createBtn)
  return !readOnly && (can_clear || children || can_create) ? (
    <InputGroup size={bsSize}>
      {input}
      <InputGroupAddon addonType='append'>
        {children}
        {!!can_clear && (
          <AutoButton color='secondary' onClick={clearSelect}>
            <FaClose />
          </AutoButton>
        )}
        {can_create && (
          <AutoButton color='secondary' onClick={toggleCreate}>
            <FaPlus />
          </AutoButton>
        )}
      </InputGroupAddon>
    </InputGroup>
  ) : (
    input
  )
}
