import { makeObservable, observable } from 'mobx'
import moment from 'moment'
import ReportBase from './ReportBase'
import xlsx from 'xlsx'
import { autoFitColumns } from '../../common/utils'

export class AgentContractorsDebtStore extends ReportBase {
  @observable total_cash = observable.array()
  @observable total_debts = observable.array()
  @observable total_own = observable.array()
  @observable show_agents = false

  constructor() {
    super('/sale/report-agent-contractors-debt')
    makeObservable(this)
  }

  getFiltersInitial() {
    return {
      date: moment().format('YYYY-MM-DD'),
      show_agents: false,
      agent_id: null,
      branch_id: null,
    }
  }

  get filter_params() {
    let params = {}
    params['filter[date]'] = this.filters.date || ''
    params['filter[show_agents]'] = this.filters.show_agents
    if (this.filters.agent_id)
      params['filter[agent_id]'] = this.filters.agent_id
    if (this.filters.branch_id)
      params['filter[branch_id]'] = this.filters.branch_id
    return params
  }

  setData({ data }) {
    this.items.clear()
    this.show_agents = data.show_agents
    super.setData({ data })
    this.total_cash.replace(data.total_cash)
    this.total_debts.replace(data.total_debts)
    this.total_own.replace(data.total_own)
  }

  clearData() {
    this.total_cash?.clear()
    this.total_debts?.clear()
    this.total_own?.clear()
    this.show_agents = false
    super.clearData()
  }

  exportDataToExcel = () => {
    let items = [
      ['Отдел', 'Контрагент', 'Долг', 'Адрес', 'Контактное лицо', 'Телефон'],
    ]
    for (const item of this.items) {
      items.push([
        item?.branch,
        item?.name,
        parseFloat(item?.debt).toFixed(2).replace('.', ','),
        item?.address,
        item?.contact_person,
        item?.phone,
      ])
    }
    const worksheet = xlsx.utils.aoa_to_sheet(items)
    autoFitColumns(worksheet)
    const book = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(book, worksheet, 'list_1')
    xlsx.writeFile(book, `Отчет по долгам.xlsx`, {
      bookType: 'xlsx',
    })
  }

  exportDataToExcelShowAgents = () => {
    let items = [['Агент', 'Касса', 'Долг', 'Свои долги']]
    for (const item of this.items) {
      items.push([
        item?.fio,
        parseFloat(item?.cash).toFixed(2).replace('.', ','),
        parseFloat(item?.debt).toFixed(2).replace('.', ','),
        parseFloat(item?.agent_debt).toFixed(2).replace('.', ','),
      ])
    }
    const worksheet = xlsx.utils.aoa_to_sheet(items)
    autoFitColumns(worksheet)
    const book = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(book, worksheet, 'list_1')
    xlsx.writeFile(book, `Отчет по долгам.xlsx`, {
      bookType: 'xlsx',
    })
  }
}

const store = new AgentContractorsDebtStore()
export default store
