import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import DynamicImport from '../../components/DynamicImport'
import DataForm from '../../pages/DataForm'
import { FormProvider } from '../FormContext'

const FORM_VALUE = { modal: true }

const CreateModal = ({
  isOpen,
  match,
  page,
  parentId,
  toggle,
  defaultData,
}) => (
  <Modal autoFocus={false} isOpen={isOpen} toggle={toggle} size='xl'>
    <ModalBody>
      <FormProvider value={FORM_VALUE}>
        <DynamicImport
          page={page}
          match={match}
          location={{ search: '?p=' + parentId }}
          onRequestClose={toggle}
          fallback={DataForm}
          defaultData={defaultData}
        />
      </FormProvider>
    </ModalBody>
  </Modal>
)

export default CreateModal
