export default function groupProductsIdByNomenclature(products_id, store) {
  return Array.from(products_id).reduce((nomenclatures, product_id) => {
    const product = store.getProduct(product_id)
    if (product) {
      const { id } = product.nomenclature
      if (!nomenclatures[id]) nomenclatures[id] = []
      nomenclatures[id].push(product_id)
    }
    return nomenclatures
  }, {})
}
