export default function isDeepProductsFetched(product_id, rel_map) {
  let i = 0
  const queue = [product_id]
  while (i < queue.length) {
    const key = `${queue[i]}`
    if (rel_map.has(key)) {
      queue.push(...rel_map.get(key))
      i++
    } else {
      return false
    }
  }
  return true
}
