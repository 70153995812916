import classNames from 'classnames'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { NavLink, withRouter } from 'react-router-dom'
import { Badge, Nav, NavItem, NavLink as RsNavLink } from 'reactstrap'

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  navConfig: PropTypes.any,
  navFunc: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isOpen: PropTypes.bool,
  staticContext: PropTypes.any,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
}

const defaultProps = {
  tag: 'nav',
  navConfig: {
    items: [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer',
        badge: { variant: 'info', text: 'NEW' },
      },
    ],
  },
  isOpen: false,
}

@withRouter
@inject('store')
@observer
class AppSidebarNav extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.activeRoute = this.activeRoute.bind(this)
    this.hideMobile = this.hideMobile.bind(this)
    this.toggleSideMenu = this.toggleSideMenu.bind(this)
    this.collapseSideMenu = this.collapseSideMenu.bind(this)
  }

  handleClick(e) {
    e.preventDefault()
    this.toggleSideMenu(e)
    e.target.parentElement.classList.toggle('open')
    this.props.store.AppStore.navigateSideMenu(null)
  }

  toggleSideMenu(e) {
    const menu = e.target?.parentElement?.parentElement?.getElementsByClassName(
      'nav-item nav-dropdown',
    )
    if (!menu) return
    for (let i = 0; i < menu.length; i++) {
      if (e.target.parentElement !== menu[i]) {
        menu[i].classList.remove('open')
      }
    }
  }

  collapseSideMenu() {
    const menu = document
      .getElementsByClassName('sidebar')?.[0]
      ?.getElementsByClassName('nav-item nav-dropdown')
    if (!menu) return
    for (let i = 0; i < menu.length; i++) {
      menu[i].classList.remove('open')
    }
  }

  activeRoute(items) {
    const active = items.some(
      t => this.props.location.pathname.split('/')[1] === t.url.split('/')[1],
    )
    return active ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown'
  }

  hideMobile(e) {
    this.props.store.AppStore.navigateSideMenu(null)
    if (document.body.classList.contains('sidebar-show')) {
      document.body.classList.toggle('sidebar-show')
    }
    if (e.metaKey || e.ctrlKey) return false
    if (window.swUpdate) {
      e.preventDefault()
      e.stopPropagation()
      return (window.location = e.target.href)
    }
  }

  // nav list
  navList(items) {
    return items.map((item, index) => this.navType(item, index))
  }

  // nav type
  navType(item, idx) {
    return item.title
      ? this.navTitle(item, idx)
      : item.divider
      ? this.navDivider(item, idx)
      : item.label
      ? this.navLabel(item, idx)
      : item.children
      ? this.navDropdown(item, idx)
      : this.navItem(item, idx)
  }

  // nav list section title
  navTitle(title, key) {
    const classes = classNames('nav-title', title.class)
    return (
      <li key={key} className={classes}>
        {this.navWrapper(title)}{' '}
      </li>
    )
  }

  // simple wrapper for nav-title item
  navWrapper(item) {
    return item.wrapper && item.wrapper.element
      ? React.createElement(
          item.wrapper.element,
          item.wrapper.attributes,
          item.data?.[`name_${this.props.store.SettingsStore.language}`] ||
            item.name,
        )
      : item.data?.[`name_${this.props.store.SettingsStore.language}`] ||
          item.name
  }

  // nav list divider
  navDivider(divider, key) {
    const classes = classNames('divider', divider.class)
    return <li key={key} className={classes} />
  }

  // nav label with nav link
  navLabel(item, key) {
    const classes = {
      item: classNames('hidden-cn', item.class),
      link: classNames('nav-label', item.class ? item.class : ''),
      icon: classNames(
        'nav-icon',
        !item.icon ? 'fa fa-circle' : item.icon,
        item.label.variant ? `text-${item.label.variant}` : '',
        item.label.class ? item.label.class : '',
      ),
    }
    return this.navLink(item, key, classes)
  }

  // nav dropdown
  navDropdown(item, key) {
    const classIcon = classNames('nav-icon', item.icon)
    const activeNav = this.props.store.AppStore.nav_side_menu
    let hasActiveNav = false
    item.children.forEach(i => {
      if (i.url === activeNav) hasActiveNav = true
    })
    if (hasActiveNav) this.collapseSideMenu()
    return (
      <li
        key={key}
        className={
          hasActiveNav
            ? 'nav-item nav-dropdown open'
            : this.activeRoute(item.children)
        }
      >
        <a
          className='nav-link nav-dropdown-toggle'
          href='#'
          onClick={this.handleClick}
        >
          <i className={classIcon} />
          {item.data?.[`name_${this.props.store.SettingsStore.language}`] ||
            item.name}
        </a>
        <ul className='nav-dropdown-items'>{this.navList(item.children)}</ul>
      </li>
    )
  }

  // nav item with nav link
  navItem(item, key) {
    const classes = {
      item: classNames(item.class),
      link: classNames(
        'nav-link',
        item.variant ? `nav-link-${item.variant}` : '',
      ),
      icon: classNames('nav-icon', item.icon),
    }
    return this.navLink(item, key, classes)
  }

  // nav link
  navLink(item, key, classes) {
    const url = item.url ? item.url : ''
    return (
      <NavItem key={key} className={classes.item}>
        {this.isExternal(url) ? (
          <RsNavLink href={url} className={classes.link} active>
            <i className={classes.icon} />
            {item.data?.[`name_${this.props.store.SettingsStore.language}`] ||
              item.name}
            {this.navBadge(item.badge)}
          </RsNavLink>
        ) : (
          <NavLink
            to={url}
            className={classes.link}
            activeClassName='active'
            onClick={this.hideMobile}
          >
            <i className={classes.icon} />
            {(item.data &&
              item.data['name_' + this.props.store.SettingsStore.language]) ||
              item.name}
            {this.navBadge(item.badge)}
          </NavLink>
        )}
      </NavItem>
    )
  }

  // badge addon to NavItem
  navBadge(badge) {
    if (badge) {
      const classes = classNames(badge.class)
      return (
        <Badge className={classes} color={badge.variant}>
          {badge.text}
        </Badge>
      )
    }
    return null
  }

  isExternal(url) {
    const link = url ? url.substring(0, 4) : ''
    return link === 'http'
  }

  render() {
    const { className, children, navConfig, ...attributes } = this.props

    delete attributes.isOpen
    delete attributes.staticContext
    delete attributes.Tag

    const navClasses = classNames(className, 'sidebar-nav')

    // sidebar-nav root
    return (
      <PerfectScrollbar
        className={navClasses}
        {...attributes}
        options={{ suppressScrollX: true }}
      >
        <Nav>{children || this.navList(navConfig.items)}</Nav>
      </PerfectScrollbar>
    )
  }
}

AppSidebarNav.propTypes = propTypes
AppSidebarNav.defaultProps = defaultProps

export default AppSidebarNav
