export function allMultipleProductNomenclatures(products_id, rel_map, store) {
  const nomenclatures = {}

  const queue = Array.from(
    products_id.reduce((queue, product_id) => {
      const key = `${product_id}`
      if (rel_map.has(key))
        rel_map.get(key).forEach(product_id => queue.add(product_id))
      return queue
    }, new Set()),
  )

  let i = 0
  while (i < queue.length) {
    const product = store.getProduct(queue[i])
    if (product) {
      const { nomenclature } = product
      if (!nomenclatures[nomenclature.id])
        nomenclatures[nomenclature.id] = { ...nomenclature, products: {} }
      const { products } = nomenclatures[nomenclature.id]
      if (!products[product.id]) {
        products[product.id] = product
      } else {
        i++
        continue
      }
    }
    const key = `${queue[i]}`
    if (rel_map.has(key)) queue.push(...rel_map.get(key))
    i++
  }

  return Object.values(nomenclatures)
    .map(nomenclature => ({
      ...nomenclature,
      products: Object.values(nomenclature.products),
    }))
    .sort((a, b) => a.name.localeCompare(b.name))
}

export default function allProductNomenclatures(product_id, rel_map, store) {
  return allMultipleProductNomenclatures([product_id], rel_map, store)
}
