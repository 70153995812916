import numeral from 'numeral'

!numeral.locales.kg &&
  numeral.register('locale', 'kg', {
    delimiters: {
      thousands: ' ',
      decimal: '.',
    },
    abbreviations: {
      thousand: 'тыс.',
      million: 'млн.',
      billion: 'млрд.',
      trillion: 'трлн.',
    },
    ordinal: function () {
      return '.'
    },
    currency: {
      symbol: 'c',
    },
  })
numeral.locale('kg')
numeral.defaultFormat('0,0.00')
