import { create } from 'mobx-persist'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import SpinnerScreen from './components/SpinnerScreen'
import storage from './storage'
import useStore from './useStore'

async function hydrate(store) {
  const stores = [
    { key: 'SettingsStore', store: store.SettingsStore },
    { key: 'ScreenStore', store: store.ScreenStore },
  ]
  await Promise.all(
    stores.map(async item => {
      try {
        await create({
          storage,
        })(item.key, item.store)
      } catch (error) {
        console.debug(error)
      }
    }),
  )
}

export default observer(function StoreProvider({ children }) {
  const [ready, setReady] = useState(false)

  const store = useStore()
  useEffect(() => {
    let active = true
    hydrate(store).finally(() => active && setReady(true))
    return () => {
      active = false
    }
  }, [store])

  return ready ? <>{children}</> : <SpinnerScreen />
})
