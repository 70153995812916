import fetchBaseProducts from './fetchBaseProducts'
import fetchDeepProducts from './fetchDeepProducts'

export default async function fetchDeepBaseProducts(product_id, store) {
  return await fetchDeepProducts(
    product_id,
    store,
    store.isDeepBaseProductsFetching,
    fetchBaseProducts,
  )
}
