import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Badge, Nav, NavItem, NavLink } from 'reactstrap'
import { FormRadio } from '../ui/FormInput'

@withRouter
@inject('store')
@observer
class Header extends React.Component {
  get user() {
    return this.props.store.AppStore.user_info?.username || ''
  }

  get branch() {
    return this.props.store.AppStore.user_info?.branch.name || ''
  }

  get title() {
    const { store, location } = this.props
    const document_type_id = location.state?.document_type?.id || 0
    const component = location.pathname.split('/')[1]
    if (component === 'documents' && document_type_id) {
      const menu = store.MenuStore.menu_names[`documents$${document_type_id}`]
      if (menu) {
        return menu.data['name_' + store.SettingsStore.language] || menu.name
      }
    }
    const menu = store.MenuStore.menu_names[component]
    if (!menu) return ' '
    return menu.data['name_' + store.SettingsStore.language] || menu.name
  }

  componentDidMount() {
    this.props.store.TaskStore.getUnread()
  }

  toggleASideMenu = e => {
    e.preventDefault()
    document.body.classList.toggle('aside-menu-show')
  }

  render() {
    const count = this.props.store.TaskStore.incoming_unread_count
    return (
      <React.Fragment>
        <AppSidebarToggler className='d-lg-none' display='md' mobile>
          <i className='icon-menu' />
        </AppSidebarToggler>
        <AppNavbarBrand />
        <AppSidebarToggler className='d-md-down-none' display='lg'>
          <i className='icon-menu' />
        </AppSidebarToggler>

        <Nav navbar>
          <NavItem className='d-md-down-none'>
            <NavLink href='#'>{this.branch}</NavLink>
          </NavItem>
        </Nav>

        <div className='app-header-title'>{this.title}</div>
        <div className='ml-auto text-white'>
          <FormRadio
            className='no-margin'
            model={this.props.store.SettingsStore}
            name={'language'}
            options={this.props.store.SettingsStore.language_options}
            canClear={false}
            inline
          />
        </div>

        <Nav navbar>
          <NavItem className='d-md-down-none'>
            <NavLink tag={Link} to='/me'>
              {this.user}
            </NavLink>
          </NavItem>
        </Nav>

        <Nav navbar>
          <NavItem className='d-md-down-none'>
            <NavLink href='#' onClick={this.toggleASideMenu}>
              <i className='icon-bell' />
              {count > 0 && (
                <Badge pill color='danger'>
                  {count}
                </Badge>
              )}
            </NavLink>
          </NavItem>
          <NavItem className='d-md-down-none'>
            <NavLink href='#'>
              <i className='icon-list' />
            </NavLink>
          </NavItem>
          <NavItem className='d-md-down-none'>
            <NavLink onClick={this.props.store.AppStore.logOut}>
              <i className='icon-logout' />
            </NavLink>
          </NavItem>
        </Nav>
      </React.Fragment>
    )
  }
}

export default Header
