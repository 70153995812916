import React, { useEffect } from 'react'
import client from '../components/bugsnag-client'

const BugsnagErrorBoundary = client
  .getPlugin('react')
  .createErrorBoundary(React)

export default function ErrorBoundary(props) {
  return (
    <BugsnagErrorBoundary FallbackComponent={ErrorView}>
      {props.children}
    </BugsnagErrorBoundary>
  )
}

function ErrorView({ error, info }) {
  console.log({ error, info })
  useEffect(() => {
    if (
      error.name === 'NotFoundError' &&
      error.message.includes("Failed to execute 'removeChild' on 'Node'")
    )
      window.location.reload()
  }, [error])
  return (
    <div className='container-fluid'>
      <h2>{error.name}</h2>
      <details style={{ whiteSpace: 'pre-wrap' }}>
        {error.message}
        <br />
        {info.componentStack && formatComponentStack(info.componentStack)}
      </details>
    </div>
  )
}

const formatComponentStack = str => {
  const lines = str.split(/\s*\n\s*/g)
  let ret = ''
  for (let line = 0, len = lines.length; line < len; line++) {
    if (lines[line].length) ret += `${ret.length ? '\n' : ''}${lines[line]}`
  }
  return ret
}
