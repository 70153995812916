import classnames from 'classnames'
import { isObservable, toJS } from 'mobx'
import { observer } from 'mobx-react'
import moment from 'moment'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { FaCheck, FaPlus, FaRefresh } from 'react-icons/lib/fa'
import { MdDashboard } from 'react-icons/lib/md'
import { Link } from 'react-router-dom'
import {
  Badge,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Table,
} from 'reactstrap'
import TableBase from '../../common/components/TableBase'
import { v2n } from '../../common/utils'
import Currency from '../ui/Currency'
import { EnumText } from '../ui/EnumSelect'
import Pager from '../ui/Pager'
import Quantity from '../ui/Quantity'
import PagePrintableDropDownButton from './PagePrintableDropDownButton'

const tableDictColumns = {
  users: 'fio',
}

@withTranslation('common')
@observer
class CellLabel extends React.Component {
  renderNum() {
    const { structure, item } = this.props
    const { type, name } = structure
    // noinspection JSUnresolvedFunction
    return type.endsWith('(24, 4)') ? (
      <Currency value={item[name]} symbol={item} />
    ) : type.endsWith('(24, 3)') ? (
      <Quantity value={item[name]} unit={item} />
    ) : item[structure.name] ? (
      v2n(item[structure.name]).value()
    ) : null
  }

  render() {
    const { structure, item, isNum, t, i18n } = this.props
    const { type, name, table } = structure
    if (type === 'BOOLEAN') return item[name] ? t('yes') : t('no')
    if (isNum && name !== 'id') return this.renderNum()
    if (!item[name]) return null
    if (['DATE', 'DATETIME'].includes(type))
      return moment(item[name]).format(
        type === 'DATE' ? 'DD-MM-YYYY' : 'DD-MM-YYYY HH:mm',
      )
    if (type === 'DICT' && item[name.split('_id')[0]]) {
      const d = item[name.split('_id')[0]]
      if (structure.translate && d.data)
        return (
          d.data[`${structure.display || name}_${i18n.language}`] ||
          d[tableDictColumns[table] || structure.display || 'name'] ||
          null
        )
      return d[tableDictColumns[table] || structure.display || 'name'] || null
    }
    if (type === 'ENUM')
      return <EnumText enumName={structure.enum_name} value={item[name]} />
    if (typeof item[name] === 'object') return JSON.stringify(item[name])
    if (structure.translate && item.data)
      return item.data[`${name}_${i18n.language}`] || item[name]
    return item[name]
  }
}

export default class DataTableBase extends TableBase {
  iconsMap = { refresh: <FaRefresh />, plus: <FaPlus />, main: <MdDashboard /> }

  constructor(props) {
    super(props)
    this.renderTheadTrTh = this.renderTheadTrTh.bind(this)
  }

  initButtons(home = true, add = true) {
    super.initButtons(home, add)
    const { mode, multiple } = this.props
    const { selections } = this.store
    const canSubmit = mode === 'select' && multiple && selections?.length > 0
    if (canSubmit) {
      this.tools.push({
        icon: <FaCheck />,
        action: this._submitSelections,
        title: 'button.accept',
      })
    }
  }

  mapTools(t, index) {
    if (React.isValidElement(t)) return t
    let icon = this.iconsMap[t.icon] || t.icon
    let title = this.tCommon(t.title)
    let text = icon ? (
      <span>
        {icon} {title}
      </span>
    ) : (
      <span>{title}</span>
    )
    if (t.link)
      return (
        <Link
          key={index}
          to={t.link}
          title={title}
          className='btn btn-outline-primary'
        >
          {text}
        </Link>
      )
    else if (t.action)
      return (
        <Button
          key={index}
          title={title}
          color={'primary'}
          outline
          onClick={t.action}
        >
          {text}
        </Button>
      )
    return null
  }

  historyPush(history, match, item, i, key) {
    history.push({
      pathname: match.url + '/' + key,
      state: isObservable(item) ? toJS(item) : item,
    })
  }

  selectionTools(tools = []) {
    const { mode, multiple } = this.props
    const { selections } = this.store
    const showSelectNum = mode === 'select' && multiple && selections.length > 0
    if (showSelectNum) {
      return (
        <Row>
          <Col xs={12} md={3}>
            <h5>
              Выбрано <Badge color='secondary'>{selections.length}</Badge>
            </h5>
          </Col>
        </Row>
      )
    }
    return (
      <Row className={'selection-tools align-items-center'}>
        {selections && selections.length ? (
          <Col xs={'auto'}>
            Выбрано:&nbsp;<Badge color='secondary'>{selections.length}</Badge>
          </Col>
        ) : null}
        <Col>
          <ButtonToolbar>{tools}</ButtonToolbar>
        </Col>
      </Row>
    )
  }

  toggleSelectionsAll = () =>
    this.store.toggleSelectionsAll(this.store.selectAllReducer(this.keyForItem))

  renderBody() {
    const { items, structure, selections } = this.store
    const { mode, multiple, store } = this.props
    const canSelect = mode === 'select' ? selections && multiple : !!selections
    return (
      (!store.AppStore.isBusy || structure.length > 0 || items.length > 0) && (
        <Table className='data-table' size='sm' hover bordered responsive>
          {this.thead(
            canSelect ? (
              <th width={20}>
                <input
                  type='checkbox'
                  onChange={this.toggleSelectionsAll}
                  checked={this.store.selectedAll}
                />
              </th>
            ) : null,
            structure
              ? structure.filter(this.filterColumns).map(this.renderTheadTrTh)
              : null,
          )}
          {this.tbody(items.map(this.mapItems))}
        </Table>
      )
    )
  }

  renderTable(
    tools,
    filters,
    header,
    selection_tools,
    body,
    footer,
    pagination,
  ) {
    return (
      <Card className={this.getCardClass()}>
        <CardHeader>
          <ButtonToolbar className='justify-content-between'>
            {tools}
            <ButtonGroup>{this.renderActionButtons()}</ButtonGroup>
          </ButtonToolbar>
        </CardHeader>
        <CardBody>
          {filters}
          {header}
          {selection_tools}
          {body}
          {footer}
        </CardBody>
        {pagination ? <CardFooter>{pagination}</CardFooter> : null}
      </Card>
    )
  }

  renderTools(tools, hasFilters = false) {
    return <ButtonGroup>{tools.map(this.mapTools)}</ButtonGroup>
  }

  renderActionButtons() {
    return this.store.printable ? (
      <PagePrintableDropDownButton {...this.props} pageStore={this.store} />
    ) : null
  }

  getCardClass() {
    return 'data-base-card'
  }

  thead(rendered_th_check, rendered_headers) {
    return (
      <thead>
        <tr>
          {rendered_th_check}
          {rendered_headers}
          {this.renderThead()}
        </tr>
      </thead>
    )
  }

  // noinspection JSMethodCanBeStatic
  renderTheadTrTh(structure) {
    return <th key={structure.name}>{this.t(structure.display_name)}</th>
  }

  tdItem(structure, item, index) {
    const { type } = structure
    const isNum = type === 'INTEGER' || type.startsWith('NUMERIC')
    return (
      <td
        key={structure.name}
        className={this.itemCellClasses(structure, item, index)}
      >
        <CellLabel structure={structure} item={item} isNum={isNum} />
      </td>
    )
  }

  // noinspection JSMethodCanBeStatic
  tbody(rendered_items) {
    return <tbody>{rendered_items}</tbody>
  }

  mapItems(item, i) {
    const { structure, selections } = this.store
    const { mode, multiple } = this.props
    const is_selection = mode === 'select'
    const canSelect = is_selection ? selections && multiple : !!selections
    const key = this.keyForItem(item, i)
    return (
      <tr
        key={key}
        onClick={e => this._onSelect(item, i, e)}
        className={this.itemClasses(item, i)}
      >
        {canSelect && (
          <td width={20}>
            <input
              type='checkbox'
              checked={this.store.selectionsIncludes(key)}
              onChange={() => undefined}
              onClick={e =>
                !is_selection && this.store.toggleSelection(item, key, e)
              }
            />
          </td>
        )}
        {structure &&
          structure
            .filter(this.filterColumns)
            .map(s => this.tdItem(s, item, i))}
        {this.renderTbody(item, i)}
      </tr>
    )
  }

  itemClasses(item, i) {
    return ''
  }

  itemCellClasses(structure, item, index) {
    const { type } = structure
    const isNum = type === 'INTEGER' || type.startsWith('NUMERIC')
    return classnames({ 'text-right': isNum })
  }

  renderPagination() {
    return this.store.pagesCount > 1 ? (
      <Pager
        size='large'
        page={this.store.page}
        pagesCount={this.store.pagesCount}
        onPageSelect={this.selectPage}
      />
    ) : null
  }

  renderThead() {
    return null
  }

  renderTbody(item) {
    return null
  }

  goBack() {
    const { history, match } = this.props
    if (history) {
      if (history.length > 1) history.goBack()
      else history.replace('/' + match.url.split('/')[1])
      return true
    }
    return false
  }
}
