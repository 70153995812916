import { computed, makeObservable } from 'mobx'
import NameBase from './NameBase'

export default class NomenclatureBase extends NameBase {
  get key() {
    return this.nomenclature_id
  }

  @computed get nomenclature_id() {
    return this.data.nomenclature_id
  }

  @computed get unit() {
    return this.data.unit || ''
  }

  @computed get unit_id() {
    return this.data.unit_id
  }

  constructor(data, production) {
    super(data, production)
    makeObservable(this)
  }

  toJSON() {
    return {
      ...super.toJSON(),
      nomenclature_id: this.nomenclature_id,
      unit: this.unit,
      unit_id: this.unit_id,
    }
  }
}
