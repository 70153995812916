import { action, computed, makeObservable, observable, toJS } from 'mobx'
import moment from 'moment'
import bind from '../common/decorators/bind'
import requester from '../common/requester'
import AppStore from './AppStore'
import BaseStore from './BaseStore'

export class ProductionsStore extends BaseStore {
  @observable new_production_item
  @observable show_scanner = false

  @observable tara_history_count = -1
  @observable tara_history = observable.array()

  @observable collapsed = observable.array()

  constructor() {
    super()
    makeObservable(this)
  }

  clearItems() {
    this.show_scanner = false
    this.new_production_item = null
    this.tara_history_count = -1
    this.tara_history = observable.array()
    super.clearItems()
  }

  @computed get grouped_items() {
    const items = Object.values(
      this.items.reduce((grouped, item) => {
        const {
          line_id,
          line_identity,
          line_status,
          status,
          rec_date,
          start_date,
          end_date,
        } = item
        const date = moment(
          status === 'finished'
            ? end_date
            : status === 'running'
            ? start_date
            : rec_date,
        ).format('YYYY-MM-DD')
        const key = line_id ? line_identity : `${status}_${date}`
        if (!grouped[key]) {
          grouped[key] = {
            key,
            line_identity,
            status: line_id ? line_status : status,
            date,
            data: [],
          }
        }
        if (!this.collapsed.includes(key)) {
          grouped[key].data.push(item)
        }
        return grouped
      }, {}),
    )
    return items.map(group => {
      if (!group.line_identity) return group
      group.data = group.data.sort((a, b) => {
        const [_, a_stage, a_process] = a.id.split('-')
        const [__, b_stage, b_process] = b.id.split('-')
        return a_stage === b_stage ? a_process - b_process : a_stage - b_stage
      })
      return group
    })
  }

  @computed get can_create() {
    return this.access.includes('create')
  }

  @computed get is_creating() {
    return this.can_create && !!this.new_production_item
  }

  @computed get can_edit() {
    return this.access.includes('edit')
  }

  canDelete() {
    return this.access.includes('delete')
  }

  @action.bound onSelectLine(selected) {
    let item = toJS(selected)
    if (!this.can_create) return
    item.stages.map(this.selectDefaultIngredientStages)
    this.new_production_item = {
      line_id: item.id,
      name: item.name,
      line_identity: '',
      stages: item.stages,
    }
  }

  @bind selectDefaultIngredientStages(s) {
    s.processes.map(this.selectDefaultIngredientProcess)
    return s
  }

  selectDefaultIngredientProcess(p) {
    const ingredients = p.process.ingredients || []
    p.ingredient_id =
      (ingredients.length > 0 && ingredients[0].ingredient_id) || null
    return p
  }

  @bind onSelectProcess(selected) {
    this.newProductionWithProcess(selected)
  }

  @action newProductionWithProcess(selected, identity) {
    let item = toJS(selected)
    if (!this.can_create) return
    const ui = AppStore.user_info || {}
    const branch_id = this.filters.get('branch_id') || ui.branch_id || null
    let process = { process_id: item.id, process: item, branch_id }
    this.selectDefaultIngredientProcess(process)
    this.new_production_item = {
      line_id: null,
      name: item.name,
      stages: [{ name: item.name, processes: [process] }],
      identity,
    }
  }

  @action onSelectIngredient(process, ingredient) {
    process.ingredient_id = ingredient.ingredient_id
  }

  async createNewProductionItem() {
    const { line_id, line_identity } = this.new_production_item
    let message = ''
    if (line_id && !line_identity) message = 'Введите индетификатор линии'
    else if (line_id && !/\d+/gim.test(line_identity))
      message = 'Неверный идентификатор линии'
    else
      try {
        this.new_production_item.stages.map(this.validateStages)
      } catch (e) {
        message = e.message
      }
    if (message) {
      AppStore.showWarning(message)
      throw new Error(message)
    }
    if (this.is_creating) {
      let { identity } = this.new_production_item
      let { data } = await requester.post(
        '/production/create',
        toJS(this.new_production_item),
      )
      this.closeNewProductionItem()
      this.fetchMany()
      return {
        id: `${data.id}-0-0`,
        identity,
      }
    }
  }

  @bind validateStages(s) {
    s.processes.map(this.validateProcesses)
  }

  validateProcesses(p) {
    if (!p.branch_id) throw new Error('Выберите отдел')
    const ingredients = p.process.ingredients || []
    if (ingredients.length > 0 && !p.ingredient_id)
      throw new Error('Выберите игредиент')
  }

  @action.bound closeNewProductionItem() {
    this.new_production_item = null
  }

  @action.bound showScanner() {
    if (!this.show_scanner) this.show_scanner = true
  }

  @action.bound hideScanner() {
    if (this.show_scanner) this.show_scanner = false
  }

  @action setTaraHistory = (tara_history, count) => {
    this.tara_history = tara_history
    this.tara_history_count = count
  }

  @action
  toggleSectionCollapse(section) {
    if (this.collapsed.includes(section.key)) {
      this.collapsed.remove(section.key)
    } else {
      this.collapsed.push(section.key)
    }
  }

  showTaraHistory = async () => {
    let { data } = await requester.get(
      `/tara/tara-history/productions/` + this.item.id,
    )
    this.setTaraHistory(data.list, data.count)
  }

  findProcess(identity) {
    return requester.get('/production/find/' + identity)
  }
}

const store = new ProductionsStore()
export default store
