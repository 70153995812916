import { observable, action, makeObservable } from 'mobx'
import requester from '../common/requester'

class EnumsStore {
  @observable enums = observable.map({})
  loads = []

  constructor() {
    makeObservable(this)
  }

  get_name(name, value) {
    if (!this.enums.has(name)) {
      this.enums.set(name, observable.map({}))
    }
    if (!value) return null
    let names = this.enums.get(name)
    if (!names.has(value)) {
      if (!this.loads.includes(name)) {
        this.load(name)
      }
      return value
    }
    return names.get(value)
  }

  async load(name) {
    this.loads.push(name)
    try {
      const data = await requester.get('/system/enum/' + name)
      this.setEnum(name, data.data.list.reduce(EnumsStore.reduceEnums, {}))
    } finally {
      const index = this.loads.indexOf(name)
      index > -1 && this.loads.splice(index, 1)
    }
  }

  static reduceEnums(o, e) {
    o[e.value] = e.name
    return o
  }

  @action
  setEnum(name, values) {
    this.enums.get(name).merge(values)
  }
}

export default new EnumsStore()
