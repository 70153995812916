import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FaSignOut } from 'react-icons/lib/fa'
import { MdBackspace } from 'react-icons/lib/md'
import { CardBody, Col, FormGroup, Input, Row } from 'reactstrap'
import meta from '../../common/manifest'

@inject('store')
@observer
export default class PinForm extends Component {
  static propTypes = {
    pinConfirm: PropTypes.bool,
    newPin: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.store = this.props.store.AppStore
    this.numPads = this.store.getNumPads()
  }

  render() {
    return (
      <CardBody>
        <h1>{meta.short_name}</h1>
        <p className='text-muted'>
          {this.props.pinConfirm
            ? 'Повторите'
            : this.props.newPin
            ? 'Создайте'
            : 'Введите'}{' '}
          ПИН
        </p>
        <FormGroup>
          <Input
            value={this.store.password}
            onChange={e => this.store.onPinChange(e)}
            type='password'
            placeholder='ПИН'
            className={'text-center'}
            autoFocus
            required
          />
        </FormGroup>
        <div className={'pin-num-pads'}>
          {this.numPads.map(this.mapNumRows)}
        </div>
      </CardBody>
    )
  }

  mapNumRows = (cols, i) => {
    return (
      <Row key={i} noGutters>
        {cols.map(this.mapNumCols)}
      </Row>
    )
  }

  mapNumCols = (col, i) => {
    return (
      <Col key={i}>
        {col !== null ? (
          <div className='num-btn'>
            <div
              role={'button'}
              className='num-text'
              onClick={() => this.store.onNumPress(col)}
            >
              {col}
            </div>
          </div>
        ) : i === 2 ? (
          <div className='num-btn'>
            <div
              role={'button'}
              className='num-text'
              onClick={() => this.store.onNumBack()}
            >
              <MdBackspace />
            </div>
          </div>
        ) : i === 0 && !this.props.newPin ? (
          <div className='num-btn-danger'>
            <div role={'button'} className='num-text' onClick={this.logOut}>
              <FaSignOut />
            </div>
          </div>
        ) : null}
      </Col>
    )
  }

  logOut = () => {
    if (window.confirm('Вам потребуется войти с помощью логина и пароля'))
      this.store.logOut()
  }
}
