import { action, makeObservable, observable } from 'mobx'
import requester from '../common/requester'

export const APPLIANCE_STATUSES = {
  0: 'Режмим ожидания',
  1: 'Ождание времени запуска программы',
  2: 'Выполнение программы',
  3: 'Программа выполнена и ожидает подтверждения',
  4: 'Программа приостановлена',
  5: 'Возникла ошибка в контроллере',
  128: 'Неизвестно. Возможно, камера выключена или не отвечает',
  /*  0: 'Standby mode.',
    1: 'Start with time, controller was programmed to start at time and is now waiting for this time.',
    2: 'Operation mode, program is running.',
    3: 'End of program, the program is at the end and the controller is waiting for confirmation.',
    4: 'Pause mode, the program is paused and waiting for resuming.',
    5: 'Alarm mode, a alarm condition appeared in the controller.',
    128: 'Unknown mode, running mode can\'t be specified, the controller is switched off or it doesn\'t respond.'*/
}

export const DATA_PARAMETERS = {
  A_20124: 'Камера  °C',
  A_20118: 'Керн °C',
  A_20122: 'Влажность %',
  A_20116: 'Fc-Value 70/10',
  // A_20121: 'Темп-а влаги °C',
  /*N_10033: 'MIC 2560, MIC 2510: Smoke interval on.',
  N_10034: 'MIC 2560, MIC 2510: Smoke interval off.',
  N_10035: 'MIC 2560: Third humidity nominal value.',
  N_10036: 'MIC 2560: Fresh air interval on.',
  N_10037: 'MIC 2560: Fresh air interval off.',
  N_10038: 'MIC 2595: Temperature 1.',
  N_10039: 'MIC 2595: Temperature 2.',
  N_10040: 'MIC 2595: Airstep 2.',
  N_10041: 'MIC 2595: Belt speed.',
  N_10129: 'pH-Value.',
  N_10130: 'Product temperature.',
  N_10131: 'Channel temperature.',
  N_10132: 'Cooling only if drum is on.',
  N_10133: 'Aeration before cooling.',
  N_10134: 'Aeration at the end of a step.',
  N_10135: 'Pressure max.',
  N_10136: 'Pressure min.',
  N_10137: 'Optional aggregate 5 Parameter 2 (from Code 5024).',
  N_10138: 'Optional aggregate 5 Parameter 1 (from Code 5024).',
  N_10139: 'Optional aggregate 4 Parameter 2 (from Code 5024).',
  N_10140: 'Optional aggregate 4 Parameter 1 (from Code 5024).',
  N_10141: 'Optional aggregate 3 Parameter 2 (from Code 5024).',
  N_10142: 'Optional aggregate 3 Parameter 1 (from Code 5024).',
  N_10143: 'Optional aggregate 2 Parameter 2 (from Code 5024).',
  N_10144: 'Optional aggregate 2 Parameter 1 (from Code 5024).',
  N_10145: 'Optional aggregate 1 Parameter 2 (from Code 5024).',
  N_10146: 'Optional aggregate 1 Parameter 1 (from Code 5024).',
  N_10147: 'One step control.',
  N_10148: 'Analog output 3.',
  N_10149: 'Analog output 2.',
  N_10150: 'Analog output 1.',
  N_10151: 'Tumbler: massage - vakuum-pause - drum stop in sec..',
  N_10152: 'Tumbler: massage - vakuum-time - drum stop in sec..',
  N_10153: 'Tumbler: massage - vakuum-pause - drum running in sec..',
  N_10154: 'Tumbler: massage - vakuum-time - drum running in sec..',
  N_10155: 'Airstep.',
  N_10156: 'Optional regulator 5.',
  N_10157: 'Optional regulator 4.',
  N_10158: 'Optional regulator 2.',
  N_10159: 'Optional regulator 1.',
  N_10160: 'Optional regulator 3.',
  N_10162: 'Fc-Value 121/10.',
  N_10163: 'Time: drum pause in sec..',
  N_10164: 'Fc-Value 70/10.',
  N_10165: 'Delta temperature.',
  N_10166: 'Core temperature.',
  N_10167: 'Time: drum left in sec..',
  N_10168: 'Time: drum right in sec..',
  N_10169: 'Humidity max.',
  N_10170: 'Humidity min.',
  N_10171: 'Chamber temperature max.',
  N_10172: 'Chamber temperature min.',
  N_10173: 'Vacuum.',
  N_10174: 'Step time: Minutes.',
  N_10175: 'Step time: Hours.',
  N_10199: 'Step of air circulation.',
  N_10200: 'Fast dehumidifying: on/off.',
  N_10201: 'Humidity nominal value => difference to humidity min (+).',
  N_10202: 'Humidity nominal value => difference to humidity min (-).',
  N_10203: 'Chamber nominal value => difference to chamber min (+).',
  N_10204: 'Chamber nominal value => difference to chamber min (-).',
  N_10205: 'Auto-Step: Start time (Time in minutes).',
  A_20026: 'Actual state of the digital inputs. Datatype: D_DIN *',
  A_20027: 'Digital inputs that are activated in actual step. Datatype: D_DIN *',
  A_20028: 'Actual state of the relays. Datatype: D_RELAY *',
  A_20029: 'Relays that are activated in actual step. Datatype: D_RELAY *',
  A_20030: 'Actual state of aggregate relays. Datatype: D_RELAY *',
  A_20031: 'Aggregate relays that are activated in actual step. Datatype: D_RELAY *',
  A_20033: 'Mic 2595: Temperature 2',
  A_20034: 'Mic 2595: Temperature 1',
  A_20068: 'Actual value of absolute humidity.',
  A_20069: 'Calculated nominal value of absolute humidity.',
  A_20093: 'Vacuum (in 1/10 %)',
  A_20094: 'Pressure',
  A_20095: 'Vacuum mode',
  A_20096: 'Vacuum time (in minutes)',
  A_20097: 'pH-Value',
  A_20098: 'Airstep',
  A_20099: 'Mode of the drum',
  A_20100: 'Analog output 3',
  A_20101: 'Analog output 2',
  A_20102: 'Analog output 1',
  A_20103: 'Product temperature 3',
  A_20104: 'Product temperature 2',
  A_20105: 'Product temperature 1',
  A_20106: 'Channel temperature 2',
  A_20108: 'Optional regulator 5',
  A_20109: 'Optional regulator 4',
  A_20110: 'Optional regulator 2',
  A_20111: 'Optional regulator 1',
  A_20112: 'Optional regulator 3',
  A_20113: 'Channel temperature 1',
  A_20114: 'Fc-Value 121/10',
  A_20115: 'Actual drum time (in minutes)',
  A_20116: 'Fc-Value 70/10',
  A_20117: 'Delta temperature',
  A_20118: 'Core temperature',
  A_20119: 'Outside humidity',
  A_20120: 'Outside humidity temperature',
  A_20121: 'Humidity temperature',
  A_20122: 'Humidity',
  A_20123: 'Outside temperature',
  A_20124: 'Chamber temperature',
  A_20126: 'Step time : Minutes',
  A_20127: 'Step time: Hours',
  A_20209: 'Actual air step',
  A_20233: 'Actual time of controller: Seconds',
  A_20234: 'Actual time of controller: Minutes',
  A_20235: 'Actual time of controller: Hour',
  A_20236: 'Actual date of controller: Weekday',
  A_20237: 'Actual date of controller: Day',
  A_20238: 'Actual date of controller: Month',
  A_20239: 'Actual date of controller: Year',*/
}

export class AditecStore {
  @observable appliances = observable.array()
  @observable appliance_id = 0
  @observable branch_id = 0
  @observable state = {}
  @observable data = {}
  @observable prev_states = observable.array()
  @observable prev_data = observable.array()

  @action clearData = () => {
    this.prev_data.clear()
    this.prev_states.clear()
    this.data = {}
    this.state = {}
    this.appliances.clear()
  }

  @action setApplianceId = appliance_id => {
    const refresh = this.appliance_id !== appliance_id
    this.appliance_id = appliance_id
    if (refresh) {
      this.clearData()
      this.appliance_id && this.refreshStateAndData()
    }
  }

  @action setBranchId = branch_id => {
    const refresh = this.branch_id !== branch_id
    this.branch_id = branch_id
    refresh && this.fetchAppliances()
  }

  @action setAppliances = appliances => {
    this.appliances.replace(appliances)
    const appliance = this.appliances.find(a => a.id === this.appliance_id)
    !appliance &&
      this.setApplianceId(
        this.appliances.length === 1 ? this.appliances[0].id : 0,
      )
  }

  @action setStateAndData = ({
    state,
    data,
    prev_states = [],
    prev_data = [],
  }) => {
    this.state = state
    this.data = data
    this.prev_states.replace(prev_states)
    this.prev_data.replace(prev_data)
  }

  fetchAppliances = async () => {
    const branch_id = this.branch_id
    try {
      const { data } = await requester.get('/aditec/appliances/' + branch_id)
      this.branch_id === branch_id && this.setAppliances(data.list)
    } catch {
      this.branch_id === branch_id && this.setAppliances([])
    }
  }

  refreshStateAndData = async () => {
    const appliance_id = this.appliance_id
    try {
      const { data } = await requester.get('/aditec/state-data/' + appliance_id)
      this.appliance_id === appliance_id && this.setStateAndData(data.item)
    } catch {
      this.appliance_id === appliance_id && this.clearData()
    }
  }

  constructor() {
    makeObservable(this)
  }
}

export default new AditecStore()
