import isFinite from 'lodash/isFinite'
import isNull from 'lodash/isNull'
import isNumber from 'lodash/isNumber'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import { getDeepObjectValue, getModelValue } from '../utils'
import transpile from './transpile'
import validate from './validate'

export { validate as isValid, transpile as build }

class ArgumentError extends Error {
  constructor(name, expected, value) {
    super()
    this.name = 'ArgumentError'
    this.message = `Argument ${name} should be type of ${expected}, ${typeof value} is given`
  }
}

const isDefined = v => !isUndefined(v)
const notNull = v => !isNull(v)

// noinspection JSUnusedGlobalSymbols
const globs = {
  coalesce: (...values) => values.filter(isDefined).filter(notNull)[0],
  asNumber: (value, defaultNumber = 0) => {
    value = parseFloat(value)
    return isNumber(value) && isFinite(value) ? value : defaultNumber
  },
  toFixed: (number, ...args) => number.toFixed(...args) - 0,
  parseFloat,
  parseInt,
  isNaN,
  isFinite,
  isNull,
  notNull,
  isUndefined,
  isDefined,
}

const key_regex = /^[a-z][a-z_\d]*$/i

export default function calc(
  expression,
  thisArg,
  isOptional = false,
  isNumeric = true,
) {
  if (!isString(expression)) {
    throw new ArgumentError('expression', 'string', expression)
  }
  if (
    !isNull(thisArg) &&
    !isPlainObject(thisArg) &&
    thisArg !== Object(thisArg)
  ) {
    throw new ArgumentError('thisArg', 'null or object', thisArg)
  }

  let result
  if (validate(expression)) {
    try {
      result = transpile(expression)(key => {
        const keys = key.split('.')
        if (keys.length > 1 && keys.every(k => key_regex.test(k))) {
          return getDeepObjectValue(thisArg, null, ...keys)
        }
        const value = key_regex.test(key)
          ? getModelValue(thisArg, key)
          : undefined
        return typeof value === 'undefined' ? Math[key] || globs[key] : value
      })
    } catch {}
  }
  if (!isNumeric) return result
  if (isNaN(result) || !isFinite(result)) result = null
  return result !== null ? result : isOptional ? null : 0
}
