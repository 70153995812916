import { action, makeObservable, observable } from 'mobx'
import requester from '../common/requester'

export class NomenclatureSaleHistoryStore {
  @observable loading = false
  @observable nomenclature = null
  @observable unit = null
  @observable nomenclature_id = null
  @observable history = observable.array()

  constructor() {
    makeObservable(this)
  }

  @action
  setNomenclatureId(nomenclature_id) {
    if (this.nomenclature_id === nomenclature_id) return
    this.nomenclature_id = nomenclature_id
    this.history.clear()
    this.loadHistory()
  }

  @action
  loadHistory = () => {
    if (this.loading) {
      this.queue = true
      return
    }
    this.queue = false
    if (this.nomenclature_id) {
      this.nomenclature = 'Загрузка...'
      requester
        .get('/agentreport/by-nomenclature/' + this.nomenclature_id)
        .then(this.setHistory)
        .catch(this.clearHistory)
        .finally(this.historyLoadEnd)
    } else if (this.history.length > 0) {
      this.nomenclature = null
      this.unit = null
      this.history.clear()
    }
  }

  @action
  setHistory = ({ data }) => {
    this.nomenclature = data.item
    this.unit = data.unit
    this.history.replace(data.list)
  }

  @action
  clearHistory = () => {
    this.nomenclature = null
    this.unit = null
    this.history.clear()
  }

  @action
  historyLoadEnd = () => {
    this.loading = false
    if (this.queue) {
      this.loadHistory()
    }
  }
}

const store = new NomenclatureSaleHistoryStore()
export default store
