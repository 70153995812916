/**
 * Note: don't import css files in dynamic import js files
 */

import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import 'react-placeholder/lib/reactPlaceholder.css'
import Loading from '../ui/Loading'

@inject('store')
@observer
export default class DynamicImport extends React.Component {
  static propTypes = {
    page: PropTypes.string.isRequired,
    fallback: PropTypes.any,
  }

  state = { loads: 0 }

  get is_loading() {
    return this.state.loads > 0
  }

  componentDidMount() {
    this.load()
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    let { page, fallback } = this.props
    if (page !== prevProps.page || fallback !== prevProps.fallback) return true
    return null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    snapshot === true && this.load()
  }

  render() {
    if (this.state.error) throw this.state.error
    const { page, fallback, store, ...rest } = this.props
    let id
    if (page) id = page.split('$')[1]
    const Component = this.component
    return this.is_loading ? (
      <Loading title='Loading...' />
    ) : Component ? (
      <Component {...rest} menu_id={id} />
    ) : (
      <div className='p-5 h1 text-muted text-center'>Page not found</div>
    )
  }

  load = async () => {
    const { page, fallback } = this.props
    this.setLoading(true)
    try {
      const [p] = page.split('$')
      this.component = (await import(`../pages/${p}`)).default
    } catch (error) {
      if (error.code !== 'MODULE_NOT_FOUND') {
        this.setState({ error })
      }
      this.component = fallback ? fallback : null
    } finally {
      this.setLoading(false)
    }
  }

  setLoading = is_start => {
    this.props.store.AppStore.setBusyState(!!is_start)
    this.setState(({ loads }) => ({ loads: loads + (is_start ? 1 : -1) }))
  }
}
