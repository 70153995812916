import { checkProductionFormula } from '../common/utils'
import { SelectionStore } from './SelectionStore'

export class ProcessStore extends SelectionStore {
  async postData(pathname, item = this.item) {
    try {
      this.processPostValidate()
    } catch (e) {
      this.catchPostData(e)
      throw e
    }
    return await super.postData(pathname, item)
  }

  async localGet(...args) {
    if (args.length < 2) args.length = 2
    args[1] = { ...args[1], is_edit: true }
    return super.localGet(...args)
  }

  processPostValidate = () => {
    if (!this.item.name) throw new Error('Заполните наименование')

    if (this.item.process_type === 'single_input') {
      if (this.item.inputs && this.item.inputs.length === 0)
        throw new Error('У процесса должен быть одно сырьё')
    }

    this.item.ingredients &&
      this.item.ingredients.forEach(m => {
        if (!m.ingredient_id) throw new Error('Недостаточно ингредиента')
      })
    this.item.inputs &&
      this.item.inputs.forEach(m => {
        if (!m.nomenclature_id) throw new Error('Недостаточно сырья')
        if (checkProductionFormula(m.compute) === false)
          throw new Error('Проверьте формулы')
        if (m.from_time && m.before_time && m.from_time >= m.before_time)
          throw new Error('Проверка даты от и до')
      })
    let has_main = false
    this.item.outputs &&
      this.item.outputs.forEach(m => {
        if (!m.nomenclature_id) throw new Error('Недостаточно продукции')
        if (m.main) {
          if (has_main)
            throw new Error(
              'У процесса должен быть только однин основной продукт',
            )
          has_main = true
        }
      })
    this.item.checks &&
      this.item.checks.forEach(m => {
        if (!m.name || !m.name.trim())
          throw new Error('Заполните наименование чеклиста')
      })

    if (this.item.process_type === 'single_input') {
      if (this.item.ingredients && this.item.ingredients.length > 0)
        throw new Error('У процесса не могут быть ингредиенты')
      if (!this.item.inputs || this.item.inputs.length !== 1)
        throw new Error('У процесса должно быть только одно сырьё')
    }
  }
}

const store = new ProcessStore()
export default store
