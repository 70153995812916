import { action, computed, makeObservable, observable, observe } from 'mobx'
import requester from '../common/requester'
import BaseStore from './BaseStore'

export class UserStore extends BaseStore {
  @observable roles = observable.array()
  @observable branch_id = null
  @observable branch = null

  constructor() {
    super()
    makeObservable(this)
  }

  fetchRoles = async () => {
    const { data } = await requester.get('/roles')
    this.setRoles(data.list)
  }

  @action setRoles = roles => this.roles.replace(roles)

  @action setEttnSendMethod = method => {
    this.item.data.ettn_send_method = method
  }

  setSingle(item) {
    super.setSingle(item)
    if (!this.branchDisposer)
      this.branchDisposer = observe(this.item, 'branch_id', this.onBranchSelect)
  }

  @computed get user_fio() {
    return (this.item.data || {}).fio || ''
  }

  set user_fio(value) {
    if (this.item.data) this.item.data.fio = value || null
  }

  @computed get car_number() {
    return (this.item.data || {}).car_number || ''
  }

  set car_number(value) {
    if (this.item.data) this.item.data.car_number = value || null
  }

  @action
  onBranchSelect = () => {
    if (!this.item.branch_id) return
    let i = this.user_branches.findIndex(this.findItemBranch)
    i !== -1 && this.user_branches.splice(i, 1)
  }

  findItemBranch = branch => branch.id === this.item.branch_id

  clearItem() {
    super.clearItem()
    this.roles?.clear()
    this.branch_id = this.branch = null
    this.branchDisposer && this.branchDisposer()
    this.branchDisposer = null
  }

  @computed get user_branches() {
    return this.item.branches || observable.array()
  }

  @action
  addBranch = () => {
    if (
      this.item.branch_id !== this.branch_id &&
      this.user_branches.findIndex(this.findBranch) === -1
    )
      this.user_branches.push(this.branch)
    this.branch_id = this.branch = null
  }

  findBranch = branch => branch.id === this.branch_id

  @action
  removeBranch = branch => this.user_branches.remove(branch)

  get should_save_filter_keys() {
    return [...super.should_save_filter_keys, 'fio', 'role_id', 'branch_id']
  }

  isSelectedRole = role => this.role_ids.includes(role.id)

  isNotSelectedRole = role => !this.role_ids.includes(role.id)

  @computed get user_roles() {
    return this.item.roles || []
  }

  @computed get role_ids() {
    return this.user_roles.map(r => r.id)
  }

  @computed get selected_roles() {
    return this.roles.filter(this.isSelectedRole)
  }

  @computed get not_selected_roles() {
    return this.roles.filter(this.isNotSelectedRole)
  }

  @action toggleRoleSelection = (role, checked) => {
    const i = this.role_ids.indexOf(role.id)
    if (checked && i === -1) {
      this.user_roles.push(role)
    } else if (!checked && i > -1) {
      this.user_roles.splice(i, 1)
    }
  }
}

const store = new UserStore()
export default store
