import * as base64 from 'base-64'
import CryptoJS from 'crypto-js'
import SHA1 from 'crypto-js/sha1'
import { computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import xlsx from 'xlsx'
import requester from '../common/requester'
import { API_URL } from '../common/settings'
import { autoFitColumns, reportFromTemplate } from '../common/utils'
import AppStore from './AppStore'
import { SelectionStore } from './SelectionStore'

class ViewReportStore extends SelectionStore {
  @observable params = {}
  @observable report_params = {}
  report = null
  @observable start_date = null
  @observable end_date = null

  constructor() {
    super()
    makeObservable(this)
  }

  setSingle(item) {
    this.report = null
    this.report_params = {}
    this.params = {}
    super.setSingle(item)
  }

  clearItem() {
    this.report = null
    this.report_params = {}
    this.params = {}
    super.clearItem()
  }

  @computed get is_download() {
    return this.item.code ? this.item.code.endsWith('_DOWNLOAD') : false
  }

  @computed get query_props() {
    const { queries } = { ...this.item.data }
    return queries ? queries.reduce(this.reduceQueryProps, {}) : {}
  }

  @computed get pdf_source() {
    const Authorization = 'Basic ' + base64.encode('web:' + AppStore.token)
    const body = JSON.stringify(this.query_props)
    const hash = SHA1(body).toString(CryptoJS.enc.Hex)
    return {
      uri: `${API_URL()}/report-templates/pdf/id/${this.item.id}/${hash}`,
      method: 'POST',
      headers: { Authorization, 'Content-Type': 'application/json' },
      body,
      cache: true,
      expiration: moment.duration(10, 'minutes').asSeconds(),
    }
  }

  reduceQueryProps = (params, { param, results_param, query_id, props }) => ({
    ...params,
    [param]: { query_id, props, results_param },
  })

  fetchTemplateResults = async () => {
    const { data } = await requester.post(
      '/report-query/results',
      this.query_props,
    )
    return data.results
  }

  buildReport = async () => {
    if (!this.item.long_template) return
    this.report = reportFromTemplate(
      this.item.long_template,
      await this.fetchTemplateResults(),
    )
  }

  reBuildExcelReport = async () => {
    await requester.post('/report-query/excel', {})
  }

  downloadExcelReport = async () => {
    requester
      .get('/report-query/excel_download', {}, {}, { responseType: 'blob' })
      .then(resp => {
        if (resp.data?.type === 'application/json') {
          resp.data.text().then(json => {
            let error = JSON.parse(json)
            AppStore.showError(error.message)
          })
        } else {
          const href = URL.createObjectURL(resp.data)
          const link = document.createElement('a')
          link.href = href
          link.setAttribute('download', 'Сводный отчет.xlsx')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          URL.revokeObjectURL(href)
        }
      })
  }

  downloadExcel = async () => {
    const name = this.item.name

    const results = await this.fetchTemplateResults()
    const book = xlsx.utils.book_new()

    for (const report in results) {
      for (const data in results[report]) {
        const keys = Array.from(
          new Set(
            results[report][data]
              .map(({ ix, ...row }) => Object.keys(row))
              .flat(),
          ),
        )
        const rows = results[report][data].map(({ ix, ...row }) =>
          keys.map(key => row[key] ?? null),
        )
        const worksheet = xlsx.utils.aoa_to_sheet(rows)
        autoFitColumns(worksheet)
        xlsx.utils.book_append_sheet(book, worksheet, `${report}_${data}`)
      }
    }
    xlsx.writeFile(
      book,
      `${name}-${moment().format('DD_MM_YYYY_HH:mm')}.xlsx`,
      { bookType: 'xlsx' },
    )
  }

  downloadExcelAgro = async () => {
    const params = {
      start_date: this.start_date,
      end_date: this.end_date,
    }
    await requester
      .get(
        `/report-query/spent-materials`,
        params,
        {},
        { responseType: 'blob' },
      )
      .then(resp => {
        if (resp.data?.type === 'application/json') {
          resp.data.text().then(json => {
            let error = JSON.parse(json)
            AppStore.showError(error.message)
          })
        } else {
          const href = URL.createObjectURL(resp.data)
          const link = document.createElement('a')
          link.href = href
          link.setAttribute('download', 'production-report.xlsx')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          URL.revokeObjectURL(href)
        }
      })
  }
}

const store = new ViewReportStore()
export default store
