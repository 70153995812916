import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import localDB from '../common/localDB'
import requester from '../common/requester'
import { SelectionStore } from './SelectionStore'

export class FieldSupportStore extends SelectionStore {
  @observable agents = observable.array()
  @observable contractors = observable.array()
  @observable routes = observable.array()
  @observable contractor_search = ''
  @observable route_search = ''

  constructor() {
    super()
    makeObservable(this)
  }

  @computed
  get edit_view() {
    return (
      !this.item.user_id ||
      !this.item.data?.route_id ||
      !this.item.contractor_id ||
      this.item.data.field_support_status !== 'done'
    )
  }

  @computed
  get draft_status() {
    return (
      this.item.data.field_support_status !== 'done' &&
      this.item.user_id &&
      this.item.contractor_id &&
      this.item.data.start_datetime
    )
  }

  @computed
  get contractors_filtered() {
    return this.contractor_search
      ? this.contractors.filter(this.filter_contractors)
      : this.contractors.slice()
  }

  filter_contractors = contractor => {
    return contractor.name
      .toLowerCase()
      .includes(this.contractor_search.toLowerCase())
  }

  @computed
  get routes_filtered() {
    return this.route_search
      ? this.routes.filter(this.filter_routes)
      : this.routes.slice()
  }

  filter_routes = route => {
    return route.name.toLowerCase().includes(this.route_search.toLowerCase())
  }

  isReadOnly() {
    return (
      !this.item.user_id ||
      !this.item.contractor_id ||
      !this.item.data?.route_id ||
      this.item.data.field_support_status === 'done' ||
      super.isReadOnly()
    )
  }

  setSingle(item) {
    if (item.data.field_support_status === 'done') {
      this.setAccess(['view'])
      this.setStructure(this.structure.map(this.mapStructureReadOnly))
    }
    super.setSingle(item)
    if (!this.item.user_id) {
      this.loadAgents()
    }
  }

  loadAgents() {
    return this.fetchAgents()
  }

  async fetchAgents() {
    if (localDB.hasConnection) {
      const data = await requester.get('/agent/list')
      this.setAgents(data.data.list)
    } else {
      let data = await localDB.get('/users', {}, 'fio', true)
      data && this.setAgents(data.list)
    }
  }

  @action
  setAgents(list) {
    this.agents.replace(list)
  }

  loadRoutes() {
    return this.item.user_id && this.item.user && this.fetchRoutes()
  }

  async fetchRoutes() {
    let data = await localDB.get(
      '/routes',
      { 'filter[branch_id]': this.item.user.branch_id },
      'name',
      true,
    )
    data && this.setRoutes(data.list)
  }

  @action
  setRoutes(list) {
    this.routes.replace(list)
  }

  loadContractors() {
    const { route, route_id } = this.item.data
    return route_id && route && this.fetchContractors()
  }

  async fetchContractors() {
    let contractors = []
    const { route = {} } = this.item.data
    for (let contractor_id of route?.data?.contractors || []) {
      const contractor = await localDB.get('/contractors/' + contractor_id)
      contractor && contractors.push({ ...contractor.item })
    }
    contractors.length > 0 && this.setContractors(contractors)
  }

  @action
  setContractors(list) {
    this.contractors.replace(list)
  }

  clearItem() {
    super.clearItem()
    this.agents?.clear()
    this.contractors?.clear()
    this.routes?.clear()
  }

  @action
  selectUser(user) {
    this.item.user = user
    this.item.user_id = user ? user.id : null
    this.loadRoutes()
  }

  @action
  selectRoute(route) {
    if (route) {
      this.item.data.route = {
        id: route.id,
        name: route.name,
        data: route.data,
        branch_id: route.branch_id,
      }
      this.item.data.route_id = route.id
    }
    this.loadContractors()
  }

  @action
  selectContractor(contractor) {
    this.item.contractor = contractor
    this.item.contractor_id = contractor ? contractor.id : null
  }

  @action
  startFieldSupport = () => {
    this.item.data.start_datetime = moment().format('YYYY-MM-DD HH:mm:ss')
  }

  @action
  setStatusDone() {
    if (this.item.data) {
      this.item.data.field_support_status = 'done'
      this.item.data.done_datetime = moment().format('YYYY-MM-DD HH:mm:ss')
    }
  }
}

const store = new FieldSupportStore()
export default store

export const dataFields = [
  { label: 'Вступление', name: 'begin', type: 'check' },
  { label: 'АНП', name: 'anp', type: 'check' },
  { label: 'ЧПВ', name: 'chpv', type: 'check' },
  { label: 'ПВ', name: 'pv', type: 'check' },
  { label: 'ЗС', name: 'zs', type: 'check' },
  { label: 'Мерчендайзинг', name: 'merchandising', type: 'check' },
  { label: 'Соответствует 2*С - 6*С', name: 'corresponds', type: 'check' },
  {
    label: 'Не соответствует 6*С и выше',
    name: 'not_corresponds',
    type: 'check',
  },
  { label: 'Комментарий (50 символов)', name: 'comment', type: 'textarea' },
]
