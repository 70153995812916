import { action, makeObservable } from 'mobx'
import moment from 'moment'
import requester from '../../common/requester'
import { ReportCategoriesBase } from './ReportBase'

export class ByNomenclaturesStore extends ReportCategoriesBase {
  constructor() {
    super('/sale/report-by-nomenclature')
    makeObservable(this)
  }

  getFiltersInitial() {
    return {
      start_from: moment().startOf('isoWeek').format('YYYY-MM-DD'),
      end_to: moment().format('YYYY-MM-DD'),
      agent_id: null,
      branch_id: null,
      categories: [],
    }
  }

  getFilterStoreName() {
    return 'report-by-nomenclature'
  }

  get filter_params() {
    let params = {}
    params['filter[start_from]'] = this.filters.start_from || ''
    params['filter[end_to]'] = this.filters.end_to || ''
    if (this.filters.agent_id)
      params['filter[agent_id]'] = this.filters.agent_id
    if (this.filters.branch_id)
      params['filter[branch_id]'] = this.filters.branch_id
    params['filter[categories]'] = this.filters.categories
      .map(c => c.id)
      .join(',')
    return params
  }

  get total_item() {
    const total_units = this.items.reduce((total, item) => {
      item.units.forEach(i => {
        if (!total[i.id]) {
          total[i.id] = {
            id: i.id,
            name: i.name,
            quantity: i.quantity,
            return: i.return,
          }
        } else {
          total[i.id].quantity += i.quantity
          total[i.id].return += i.return
        }
      })

      return total
    }, {})

    return {
      id: 'total',
      name: 'Итого',
      units: Object.values(total_units),
    }
  }

  @action
  downloadData = () => {
    if (this.loading) return
    this.loading = true
    return requester.get(
      '/sale/report-by-nomenclature/excel',
      this.filter_params,
      false,
      { responseType: 'arraybuffer' },
    )
  }
}

const store = new ByNomenclaturesStore()
export default store
