import { action, makeObservable, observable, toJS } from 'mobx'
import moment from 'moment'
import requester from '../common/requester'

export class AgentDebtEditStore {
  @observable loading = false
  @observable agent_id = null
  @observable item = {
    branch_id: null,
    amount: null,
    to_date: moment().format('YYYY-MM-DD'),
  }
  @observable contractors_start_debt = null
  @observable total_debt = { own: null, contractors: null }
  @observable total_after = { own: null, contractors: null }

  @action
  setAgentId = agent_id => {
    this.agent_id = agent_id
    this.contractors_start_debt = null
    this.total_debt.own = null
    this.total_debt.contracotrs = null
    this.total_after.own = null
    this.total_after.contracotrs = null
    return this.fetchData()
  }

  @action
  setLoading = status => (this.loading = status)

  fetchData = async (force = false) => {
    if (this.loading && !force) return
    this.setLoading(true)
    try {
      const { data } = await requester.get(
        '/agentreport/agent-debts/' + this.agent_id,
        { to_date: this.item.to_date },
      )
      this.setData(data)
    } finally {
      this.setLoading(false)
    }
  }

  @action
  setData = data => {
    if (data.agent_id !== this.agent_id) return
    this.item.to_date = data.to_date
    this.contractors_start_debt = data.contractors_start_debt
    this.total_debt.own = data.total_debt.own
    this.total_debt.contractors = data.total_debt.contractors
    this.total_after.own = data.total_after.own
    this.total_after.contractors = data.total_after.contractors
  }

  submitData = async () => {
    await requester.post(
      '/agentreport/agent-debts/' + this.agent_id,
      toJS(this.item),
    )
    return this.fetchData(true)
  }

  constructor() {
    makeObservable(this)
  }
}

const store = new AgentDebtEditStore()
export default store
