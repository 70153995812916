import { computed, makeObservable } from 'mobx'
import NomenclatureBase from './NomenclatureBase'
import trim from './utils/trim'

export default class Input extends NomenclatureBase {
  @computed get compute() {
    return trim(this.data.compute, null)
  }

  @computed get round() {
    return !!this.data.round
  }

  constructor(data, production) {
    super(data, production)
    makeObservable(this)
  }

  toJSON() {
    return {
      ...super.toJSON(),
      compute: this.compute,
      round: this.round,
    }
  }
}
