import * as mobx from 'mobx'
import { action, makeObservable, observable } from 'mobx'
import requester from '../common/requester'

export class BUSelectStore {
  @observable favorite_branches = observable.array()
  @observable all_branches = observable.array()
  @observable search_branches = observable.array()

  @observable loading_favorite_branches = false
  @observable loading_all_branches = false
  @observable loading_search_branches = false

  @observable branch_dependencies = observable.map({})

  @observable states = observable.map({})

  constructor() {
    makeObservable(this)
  }

  @action
  setState(state_key, { multiple, selectType }) {
    if (!this.states.has(state_key))
      this.states.set(state_key, new BUStateStore())
    const state = this.states.get(state_key)
    state.multiple = multiple
    state.selectType = selectType
    return state
  }

  @action
  removeState(state_key) {
    this.states.has(state_key) && this.states.delete(state_key)
  }

  getState(state_key) {
    return this.states.has(state_key) ? this.states.get(state_key) : null
  }

  all_branches_list(state_key, selectType) {
    if (!this.states.has(state_key)) return this.search_branches.slice()
    let state = this.states.get(state_key)
    if (selectType === 'branch')
      return this.all_branches.filter(i =>
        this.filterExpandedSelectType(
          state_key,
          selectType,
          i,
          state.branch_expands,
        ),
      )
    return this.all_branches.filter(i =>
      this.filterExpanded(state_key, i, state.branch_expands),
    )
  }

  filterExpandedSelectType = (state_key, selectType, item, branch_expands) =>
    item.type === selectType &&
    this.filterExpanded(state_key, item, branch_expands)

  filterExpanded = (state_key, item, branch_expands) => {
    return !item.branch_key || branch_expands.has(item.branch_key)
  }

  search_branches_list(state_key, selectType) {
    if (!this.states.has(state_key)) return this.search_branches.slice()
    let state = this.states.get(state_key)
    const search_name = state.search_name.toLowerCase()
    if (!search_name)
      this.search_branches.filter(i =>
        this.filterSearchSelectType(selectType, i),
      )
    return this.search_branches.filter(i =>
      this.filterSearch(state_key, selectType, i, search_name),
    )
  }

  filterSearchSelectType = (selectType, item) =>
    selectType === 'all' ? true : item.type === selectType

  filterSearch = (state_key, selectType, item, search_name) => {
    if (!this.filterSearchSelectType(selectType, item)) return false
    switch (item.type) {
      case 'branch':
        return item.branch.name.toLowerCase().includes(search_name)
      case 'user':
        return item.user.fio.toLowerCase().includes(search_name)
    }
    return false
  }

  favorite_branches_list(selectType) {
    if (selectType === 'all') return this.favorite_branches.slice()
    let list = this.favorite_branches.filter(i =>
      this.filterFavorite(selectType, i),
    )
    if (list.length > 2 && list[1].type === 'my-branches') list = list.slice(1)
    return list
  }

  filterFavorite = (selectType, item) => {
    return (
      item.type.startsWith(selectType) ||
      ['branch-favorite', 'branch,', 'favorite', 'my-branches'].includes(
        item.type,
      )
    )
  }

  forceLoadFavoriteBranches = () => {
    this.loadFavoriteBranches(true)
  }

  loadFavoriteBranches = (force = false) => {
    if (this.loading_favorite_branches) return
    if (this.favorite_branches.length > 0 && force !== true) return
    this.loading_favorite_branches = true
    BUSelectStore.requestFavoriteBranches()
      .then(this.setFavoriteBranches)
      .finally(this.loadFavoriteBranchesEnd)
  }

  static async requestFavoriteBranches() {
    let data = await requester.get('/user/dependencies')
    const { dependencies } = data.data
    data = await requester.get('/user/bu/favorite')
    const { list } = data.data
    return { list, dependencies }
  }

  @action
  setFavoriteBranches = ({ list, dependencies }) => {
    this.favorite_branches.replace(list)
    this.branch_dependencies.replace(dependencies)
    mobx.keys(this.states).map(state_key => {
      this.checkBranchDependencies(state_key)
      this.matchQueue(state_key)
    })
  }

  @action
  loadFavoriteBranchesEnd = () => {
    this.loading_favorite_branches = false
  }

  forceLoadAllBranches = () => {
    this.loadAllBranches(true)
  }

  loadAllBranches = (force = false) => {
    if (this.loading_all_branches) return
    if (this.all_branches.length > 0 && force !== true) return
    this.loading_all_branches = true
    BUSelectStore.requestAllBranches()
      .then(this.setAllBranches)
      .finally(this.loadAllBranchesEnd)
  }

  static async requestAllBranches() {
    let data = await requester.get('/user/dependencies')
    const { dependencies } = data.data
    data = await requester.get('/user/bu')
    const { list } = data.data
    return { list, dependencies }
  }

  @action
  setAllBranches = ({ list, dependencies }) => {
    this.all_branches.replace(list)
    this.branch_dependencies.replace(dependencies)
    mobx.keys(this.states).map(state_key => {
      this.checkBranchDependencies(state_key)
      this.matchQueue(state_key)
    })
  }

  @action
  loadAllBranchesEnd = () => {
    this.loading_all_branches = false
  }

  forceLoadSearchBranches = () => {
    this.loadSearchBranches(true)
  }

  loadSearchBranches = (force = false) => {
    if (this.loading_search_branches) return
    if (this.search_branches.length > 0 && force !== true) return
    this.loading_search_branches = true
    BUSelectStore.requestSearchBranches()
      .then(this.setSearchBranches)
      .finally(this.loadSearchBranchesEnd)
  }

  static async requestSearchBranches() {
    let data = await requester.get('/user/dependencies')
    const { dependencies } = data.data
    data = await requester.get('/user/bu/search')
    const { list } = data.data
    return { list, dependencies }
  }

  @action
  setSearchBranches = ({ list, dependencies }) => {
    this.search_branches.replace(list)
    this.branch_dependencies.replace(dependencies)
    mobx.keys(this.states).map(state_key => {
      this.checkBranchDependencies(state_key)
      this.matchQueue(state_key)
    })
  }

  @action
  loadSearchBranchesEnd = () => {
    this.loading_search_branches = false
  }

  @action
  openSelector = state_key => {
    if (!this.states.has(state_key)) return
    let state = this.states.get(state_key)
    state.selector_visible = true
  }

  @action
  closeSelector = state_key => {
    if (!this.states.has(state_key)) return
    let state = this.states.get(state_key)
    state.selector_visible = false
  }

  @action
  setActiveFavoriteBranches = state_key => {
    if (!this.states.has(state_key)) return
    let state = this.states.get(state_key)
    this.checkBranchDependencies(state_key)
    state.active_selections = 'favorite'
  }

  @action
  setActiveAllBranches = state_key => {
    if (!this.states.has(state_key)) return
    let state = this.states.get(state_key)
    this.checkBranchDependencies(state_key)
    state.active_selections = 'all'
  }

  @action
  setActiveSearch = state_key => {
    if (!this.states.has(state_key)) return
    let state = this.states.get(state_key)
    this.checkBranchDependencies(state_key)
    state.before_search = state.active_selections
    state.active_selections = 'search'
  }

  @action
  setInActiveSearch = state_key => {
    if (!this.states.has(state_key)) return
    let state = this.states.get(state_key)
    this.checkBranchDependencies(state_key)
    state.active_selections = state.before_search || 'favorite'
  }

  @action
  toggleBranch = (state_key, branch, { multiple, selectType }) => {
    if (!this.states.has(state_key) || !['all', 'branch'].includes(selectType))
      return
    let state = this.states.get(state_key)
    if (
      state.user_selections.size > 0 ||
      (multiple && state.branches_disabled.includes(branch.id))
    )
      return
    if (state.branch_selections.has(branch.id)) {
      state.branch_selections.delete(branch.id)
    } else {
      !multiple &&
        state.branch_selections.size > 0 &&
        state.branch_selections.clear()
      state.branch_selections.set(branch.id, branch)
    }
    this.checkBranchDependencies(state_key)
  }

  @action
  toggleBranchUsers = (state_key, users, { multiple, selectType }) => {
    if (!this.states.has(state_key) || !['all', 'user'].includes(selectType))
      return
    let state = this.states.get(state_key)
    if (state.branch_selections.size > 0) return
    if (!multiple) {
      state.user_selections.size > 0 && state.user_selections.clear()
      users.length > 0 && state.user_selections.set(users[0].id, users[0])
      return
    }
    let in_ = []
    let out_ = {}
    for (const user of users) {
      if (state.user_selections.has(user.id)) {
        in_.push(user.id)
      } else {
        out_[user.id] = user
      }
    }
    if (Object.keys(out_).length > 0) {
      state.user_selections.merge(out_)
    } else {
      for (const user_id of in_) {
        state.user_selections.delete(user_id)
      }
    }
  }

  @action
  toggleUser = (state_key, user, { multiple, selectType }) => {
    if (!this.states.has(state_key) || !['all', 'user'].includes(selectType))
      return
    let state = this.states.get(state_key)
    if (state.branch_selections.size > 0) return
    if (state.user_selections.has(user.id)) {
      state.user_selections.delete(user.id)
    } else {
      !multiple &&
        state.user_selections.size > 0 &&
        state.user_selections.clear()
      state.user_selections.set(user.id, user)
    }
  }

  @action
  toggleBranchExpand = (state_key, item) => {
    if (!this.states.has(state_key)) return
    let state = this.states.get(state_key)
    if (state.branch_expands.has(item.key))
      BUSelectStore.removeExpandKeys(state.branch_expands, [[item.key]], 0)
    else state.branch_expands.set(item.key, item.branch_key)
  }

  // noinspection InfiniteRecursionJS
  static removeExpandKeys(branch_expands, keys, depth) {
    if (depth > 1000) {
      throw new Error('max depth is exceeded')
    }
    const next_depth = depth + 1
    for (const [key] of keys) {
      if (branch_expands.has(key)) {
        const child_keys = mobx
          .entries(branch_expands)
          .filter(([_, branch_key]) => branch_key === key)
        child_keys.length > 0 &&
          BUSelectStore.removeExpandKeys(branch_expands, child_keys, next_depth)
        branch_expands.delete(key)
      }
    }
  }

  @action
  clearSelections(state_key) {
    if (!this.states.has(state_key)) return
    let state = this.states.get(state_key)
    state.user_selections.clear()
    state.branch_selections.clear()
    state.branches_disabled.clear()
    state.search_name = ''
  }

  @action
  checkBranchDependencies(state_key) {
    if (!this.states.has(state_key)) return
    let state = this.states.get(state_key)
    let disables = []
    if (this.branch_dependencies.size === 0) {
      state.branches_disabled.replace(disables)
      return
    }
    for (const bs of mobx.keys(state.branch_selections))
      if (this.branch_dependencies.has(bs))
        disables.push(...this.branch_dependencies.get(bs))
    disables = [...new Set(disables)]
    state.branches_disabled.replace(disables)
  }

  @action
  toggleFavorite(item) {
    const type = item.type.replace('-favorite', '')
    if (!['branch', 'user'].includes(type)) return
    let params = {
      [type + '_id']: item[type].id,
    }
    item[type].favorite = !item[type].favorite
    requester
      .post('/user/fav', params)
      .then(this.setFavorite)
      .catch(
        action(() => {
          item[type].favorite = !item[type].favorite
        }),
      )
  }

  @action
  setFavorite = ({ data }) => {
    if (!data.type || !['branch', 'user'].includes(data.type)) return
    this.all_branches.map(i => {
      if (i.type === data.type && i[data.type].id === data.id)
        i[data.type].favorite = data.favorite
    })
    this.search_branches.map(i => {
      if (i.type === data.type && i[data.type].id === data.id)
        i[data.type].favorite = data.favorite
    })
    this.favorite_branches.map(i => {
      if (i.type === data.type && i[data.type].id === data.id)
        i[data.type].favorite = data.favorite
    })
    this.forceLoadFavoriteBranches()
  }

  getStateEndItems(state_key, queue_prop) {
    if (!this.states.has(state_key)) return {}
    let state = this.states.get(state_key)
    if (state[queue_prop].length === 0) return {}
    let items = [
      ...this.favorite_branches,
      ...this.all_branches,
      ...this.search_branches,
    ]
    if (items.length === 0) this.loadAllBranches()
    return { state, items }
  }

  matchQueue(state_key) {
    this.matchBranchesQueue(state_key)
    this.matchUsersQueue(state_key)
  }

  addBranchesQueue = (state_key, id, { multiple, selectType }) => {
    let state = this.setState(state_key, { multiple, selectType })
    state.addBranchesQueue(id)
    this.matchBranchesQueue(state_key, { multiple, selectType })
  }

  @action
  matchBranchesQueue(state_key, options) {
    const { state, items } = this.getStateEndItems(state_key, 'branches_queue')
    if (!state || items.length === 0) return
    const { multiple, selectType } = options || state
    while (state.branches_queue.length > 0) {
      const id = state.branches_queue.shift()
      let item = items.find(
        i => i.type.startsWith('branch') && i.branch.id === id,
      )
      if (item && !state.branch_selections.has(item.branch.id))
        this.toggleBranch(state_key, item.branch, { multiple, selectType })
    }
  }

  addUsersQueue = (state_key, id, { multiple, selectType }) => {
    let state = this.setState(state_key, { multiple, selectType })
    state.addUsersQueue(id)
    this.matchUsersQueue(state_key, { multiple, selectType })
  }

  @action
  matchUsersQueue(state_key, options) {
    const { state, items } = this.getStateEndItems(state_key, 'users_queue')
    if (!state || items.length === 0) return
    const { multiple, selectType } = options || state
    while (state.users_queue.length > 0) {
      const id = state.users_queue.shift()
      let item = items.find(i => i.type.startsWith('user') && i.user.id === id)
      if (item && !state.user_selections.has(item.user.id))
        this.toggleUser(state_key, item.user, { multiple, selectType })
    }
  }
}

export class BUStateStore {
  @observable selector_visible = false
  @observable active_selections = 'favorite'

  @observable branch_selections = observable.map({})
  @observable user_selections = observable.map({})

  @observable branch_expands = observable.map({})

  @observable branches_disabled = observable.array()

  @observable search_name = ''
  before_search = null

  multiple = true
  selectType = 'all'

  branches_queue = []
  users_queue = []

  constructor() {
    makeObservable(this)
  }

  addBranchesQueue(id) {
    !this.branches_queue.includes(id) && this.branches_queue.push(id)
  }

  addUsersQueue(id) {
    !this.users_queue.includes(id) && this.users_queue.push(id)
  }
}

export default new BUSelectStore()
