import {
  action,
  computed,
  isObservableArray,
  makeObservable,
  observable,
} from 'mobx'
import moment from 'moment'
import localDB from '../common/localDB'
import { RouteStore } from './RouteStore'

export class OrderPriceStore {
  @observable is_loading = 0
  @observable is_ready = false
  @observable stage = 1
  @observable day_filter = null
  @observable search_contractor = ''
  @observable search_product = ''
  @observable search_category = ''
  @observable confirm_save = false
  @observable routes = observable.array()
  @observable route = null
  @observable contractors = observable.array()
  @observable contractor = null
  @observable categories = observable.array()
  @observable category = null
  @observable nomenclatures = observable.array()
  @observable done_contractors = observable.map({})
  @observable prices_index = -1
  @observable products = observable.array()
  @observable price = 0
  @observable due_date = moment().format('YYYY-MM-DD')

  scrollPosition = 0

  constructor() {
    makeObservable(this)
  }

  @action
  initState() {
    this.prices_index = -1
    this.confirm_save = false

    let stage = 1
    if (this.stage > 1 && this.route && this.routes.length > 0) {
      stage = 2
    } else {
      this.loadRoutes()
      this.route = null
    }
    if (stage === 2) {
      if (this.stage > 2 && this.contractor && this.contractors.length > 0) {
        stage = 3
      } else {
        this.loadContractors()
        this.contractor = null
      }
    }
    if (stage === 3) {
      if (this.stage > 3 && this.category && this.categories.length > 0) {
        stage = 4
      } else {
        this.loadCategories()
        this.category = null
      }
    }
    if (stage === 4) {
      if (
        this.stage > 4 &&
        this.validateQuantity() &&
        this.products_filtered.length > 0
      ) {
        stage = 4
      } else {
        this.loadNomenclatures()
      }
    }
    if (stage === 4) {
      stage = 5
    }
    this.stage = stage
    this.is_ready = true
  }

  @action
  clearStage() {
    this.is_ready = false
  }

  @action
  startLoading = () => {
    this.is_loading++
  }

  @action
  finishLoading = () => {
    this.is_loading--
  }

  @action
  prevStage = app => {
    if (this.is_loading) {
      app.showWarning('Идет загрузка, подождите.')
      return
    }
    this.stage--
    if (this.stage < 5) {
      this.prices_index = -1
    }
    if (this.stage < 4) {
      this.category = null
    }
    if (this.stage < 3) {
      this.contractor = null
      this.products.clear()
    }
    if (this.stage < 2) {
      this.route = null
      this.contractors.clear()
    }
  }

  @action
  nextStage = app => {
    switch (this.stage + 1) {
      case 2:
        if (!this.route) {
          app.showWarning('Выберите маршрут!')
          return
        }
        this.loadContractors()
        break
      case 3:
        if (!this.contractor) {
          app.showWarning('Выберите контрагента!')
          return
        }
        this.loadCategories()
        break
      case 4:
        if (!this.validateQuantity()) {
          return app.showWarning('Проверьте количество!')
        }
        this.loadNomenclatures()
        this.confirm_save = false
        break
      case 5:
        if (this.is_loading) return
        this.confirm_save = false
        break
      case 6:
        if (this.is_loading) return
        if (!this.confirm_save) {
          this.confirm_save = true
          return
        }
        return this.startPostData(app)
      default:
        return
    }
    this.stage++
  }

  isNaN = amount =>
    amount
      ? (typeof amount === 'string' && amount.includes(',')) || isNaN(amount)
      : false

  @action
  toggleDayFilter = () => {
    this.day_filter = this.day_filter ? null : moment().isoWeekday()
    this.loadRoutes()
  }

  @action
  clearConfirmSave = () => {
    this.confirm_save = false
  }

  loadRoutes = () => {
    this.startLoading()
    OrderPriceStore.getRoutes(this.day_filter)
      .then(this.setRoutes)
      .finally(this.finishLoading)
  }

  static async getRoutes(day_filter) {
    const data = await localDB.get('/routes', {}, 'name')
    let routes = []
    for (let r of data ? data.list : [])
      r.data.contractors.length > 0 &&
        (!day_filter || r.data.days.includes(day_filter)) &&
        routes.push({
          ...r,
          days_string: r.data.days.map(RouteStore.mapDays).join(', '),
        })
    return routes
  }

  @action
  setRoutes = routes => {
    this.routes.replace(routes)
  }

  @action
  onSelectRoute(route, app) {
    this.route = route
    this.scrollPosition = 0
    this.nextStage(app)
  }

  @action
  onSelectCategory(category, app) {
    this.category = category
    this.scrollPosition = 0
    this.nextStage(app)
  }

  loadContractors = () => {
    this.startLoading()
    OrderPriceStore.getContractors(this.route.data.contractors)
      .then(this.setContractors)
      .finally(this.finishLoading)
  }

  loadNomenclatures = () => {
    this.startLoading()
    OrderPriceStore.getNomenclatures(this.category.id)
      .then(this.setNomenclatures)
      .finally(this.finishLoading)
  }

  loadCategories = () => {
    this.startLoading()
    OrderPriceStore.getCategories()
      .then(this.setCategories)
      .finally(this.finishLoading)
  }

  static async getContractors(contractor_ids) {
    let contractors = []
    for (let contractor_id of contractor_ids) {
      const contractor = await localDB.get('/contractors/' + contractor_id)
      contractor && contractors.push({ ...contractor.item })
    }
    return contractors
  }

  static async getNomenclatures(category_id) {
    const { list: nomenclatures } = await localDB.get(
      '/nomenclatures',
      { 'filter[category_id]': category_id },
      null,
      true,
    )

    const templates = {}

    for (let i = 0; i < nomenclatures.length; i++) {
      if (
        nomenclatures[i].data.templates &&
        nomenclatures[i].data.templates.length > 0
      ) {
        let prices = []
        for (let tid of nomenclatures[i].data.templates) {
          if (typeof templates[tid] !== 'undefined') {
            prices.push(...templates[tid])
            continue
          }
          let t = await localDB.get('/templates/' + tid)
          t = t ? t.item : null
          if (t && t.temp_type === 'price' && t.data.prices) {
            templates[tid] = t.data.prices
            prices.push(...templates[tid])
          } else {
            templates[tid] = []
          }
        }

        if (prices.length === 0) continue
        const sale_prices = OrderPriceStore.reducePrices(prices)
        if (sale_prices.length === 0) continue
        const standard_price = sale_prices.find(
          p => p.name === 'standart' || p.name === 'standard',
        )
        let price, price_type
        if (standard_price) {
          price = parseFloat(parseFloat(standard_price.price).toFixed(4))
          price_type = standard_price.name
        } else {
          price = parseFloat(parseFloat(sale_prices[0].price).toFixed(4))
          price_type = sale_prices[0].name
        }
        nomenclatures[i] = {
          ...nomenclatures[i],
          price,
          price_type,
          prices: sale_prices,
        }
      }
    }

    return nomenclatures
      .filter(n => n.price && n.price > 0)
      .map(i => ({ ...i, quantity: null }))
  }

  static async getCategories() {
    let categoriesData = await localDB.get('/categorys')
    return categoriesData.list
  }

  @action
  setContractors = contractors => {
    if (
      contractors.length <= this.scrollPosition > 0 &&
      this.scrollPosition > 0
    ) {
      this.scrollPosition = contractors.length > 0 ? contractors.length - 1 : 0
    }
    this.contractors.replace(contractors)
  }

  @action
  setNomenclatures = nomenclatures => {
    if (
      nomenclatures.length <= this.scrollPosition > 0 &&
      this.scrollPosition > 0
    ) {
      this.scrollPosition =
        nomenclatures.length > 0 ? nomenclatures.length - 1 : 0
    }
    this.nomenclatures.replace(nomenclatures)
  }

  @action
  setCategories = categories => {
    if (
      categories.length <= this.scrollPosition > 0 &&
      this.scrollPosition > 0
    ) {
      this.scrollPosition = categories.length > 0 ? categories.length - 1 : 0
    }
    this.categories.replace(categories)
  }

  @action
  onSelectContractor(contractor, app, index) {
    this.contractor = contractor
    this.nextStage(app)
    this.scrollPosition = index > -1 ? index : 0
  }

  @action
  togglePrices = index => {
    this.prices_index = this.prices_index === index ? -1 : index
  }

  @action
  setPrice(item) {
    const { prices, price_type } = item
    let price = prices.find(p => p.name === price_type)
    if (price) item.price = parseFloat(parseFloat(price.price).toFixed(4))
    if (isNaN(item.price)) item.price = null
  }

  @computed
  get products_filtered() {
    return this.nomenclatures.filter(OrderPriceStore.filterNomenclatures)
  }

  static filterNomenclatures(item) {
    return item.quantity && parseFloat(item.quantity) !== 0
  }

  validateQuantity() {
    for (const p of this.products) {
      if (
        this.isNaN(p.quantity) ||
        this.isNaN(p.return) ||
        this.isNaN(p.defective)
      )
        return false
    }
    return true
  }

  static zIfNaN2Float(n) {
    return parseFloat(!n || isNaN(n) ? 0 : n)
  }

  static countItemAmount(p) {
    if (!p.quantity) return 0
    let quantity = OrderPriceStore.zIfNaN2Float(p.quantity)
    const amount = p.price * quantity
    return isNaN(amount) ? 0 : amount
  }

  static countTotal(products) {
    let total = 0
    for (const p of products) total += OrderPriceStore.countItemAmount(p)
    return parseFloat(total.toFixed(0))
  }

  startPostData(app) {
    this.startLoading()
    this.postData(app).then(this.postDataSuccess).finally(this.finishLoading)
  }

  async postData(app) {
    try {
      return await localDB.post(`/orders/add`, {
        contractor_id: this.contractor.id,
        due_date: this.due_date,
        branch_id: app.user_info ? app.user_info.branch_id : null,
        data: {
          products: this.products_filtered.map(OrderPriceStore.mapProductsPost),
        },
      })
    } catch (e) {
      if (e && !e.result) app.showWarning(e.message)
      throw e
    }
  }

  static mapProductsPost(item) {
    return {
      nomenclature_id: item.id,
      price: item.price || null,
      quantity: item.quantity || null,
      data: {
        price_type: item.price_type,
      },
    }
  }

  @action
  postDataSuccess = data => {
    this.stage = 2
    this.nomenclatures.map(p => {
      p.count -= p.quantity ? parseFloat(p.quantity) : 0
      p.quantity = null
    })
  }

  static reducePrices(prices) {
    const ret_prices = []
    if (Array.isArray(prices) || isObservableArray(prices)) {
      prices
        .reverse()
        .forEach(p =>
          (p.hasOwnProperty('prices') ? p.prices : [p]).forEach(
            price =>
              ret_prices.every(rp => rp.name !== price.name) &&
              ret_prices.push(price),
          ),
        )
    }
    return ret_prices.sort(OrderPriceStore.sortPrices)
  }

  static sortPrices(a, b) {
    return b.price - a.price
  }

  static minPrice(prices) {
    const sorted = prices.sort(OrderPriceStore.sortPrices)
    const min_price = sorted
      .reverse()
      .find(p => p.name && p.name.toLowerCase().indexOf('market') === -1)
    return min_price
      ? min_price.price
      : sorted.length > 0
      ? sorted[0].price
      : null
  }

  @computed
  get filteredContractors() {
    if (this.search_contractor) {
      return this.contractors.filter(this.filterContractors)
    }
    return this.contractors.slice()
  }

  filterContractors = c => {
    return c.name.toLowerCase().startsWith(this.search_contractor.toLowerCase())
  }

  @computed
  get filtered_products() {
    return this.search_product
      ? this.products.filter(this.filterProduct)
      : this.products
  }

  filterProduct = p =>
    p.nomenclature.toLowerCase().indexOf(this.search_product.toLowerCase()) !==
    -1

  @computed
  get filteredCategories() {
    if (this.search_category) {
      return this.categories.filter(this.filterCategories)
    }
    return this.categories.slice()
  }

  filterCategories = c => {
    return c.name.toLowerCase().startsWith(this.search_category.toLowerCase())
  }

  @computed
  get filteredNomenclatures() {
    return this.nomenclatures.filter(this.filterNomenclatures)
  }

  filterNomenclatures = n => {
    if (this.category) {
      return n.category_id === this.category.id
    }
  }
}

const store = new OrderPriceStore()
export default store
