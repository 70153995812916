import { useEffect, useState } from 'react'
import requester from '../requester'

/**
 * @param {string} [menu_id]
 * @returns {(string|undefined)}
 */
export default function useDocumentTable(menu_id) {
  const [state, setState] = useState({})

  useEffect(() => {
    let active = true
    if (menu_id) {
      setState({ loading: true })
      requester
        .get(`/documenttypes/${menu_id}`)
        .then(d => d.data.item.template)
        .catch(() => '')
        .then(editor => active && setState(s => ({ ...s, [menu_id]: editor })))
    }
    return () => {
      active = false
    }
  }, [menu_id])

  return menu_id ? state[menu_id] : ''
}
