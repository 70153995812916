import { action, computed, makeObservable, observable } from 'mobx'
import localDB from '../common/localDB'
import requester from '../common/requester'
import { getModelValue, isNotNot, setModelValue } from '../common/utils'
import AppStore from './AppStore'
import BaseStore from './BaseStore'

export class BranchStore extends BaseStore {
  @observable categories = observable.array()
  @observable expands = observable.map({})
  @observable action_branch = null

  constructor() {
    super()
    makeObservable(this)
    this.remove_filter_keys('branch_id')
    this.add_filter_keys('name')
  }

  @action
  setCategories(data) {
    data &&
      data.list &&
      this.categories.replace(data.list.map(BranchStore.mapCategories))
  }

  static mapCategories({ id, name }) {
    return { value: id, text: name }
  }

  async getCategories() {
    this.setCategories(await localDB.get('/categorys'))
  }

  async fetchItem(pathname, defaultData) {
    await this.getCategories()
    return await super.fetchItem(pathname, defaultData)
  }

  clearItem() {
    this.action_branch = null
    super.clearItem()
    this.categories?.clear()
  }

  dataDefaults = item => {
    const { data, ...item1 } = item
    const { defaults, ...data1 } = data
    let { currency_id, categories, categories_transfer, ...defaults1 } =
      defaults
    if (!currency_id) currency_id = null
    if (!categories) categories = []
    if (!categories_transfer) categories_transfer = []
    return {
      ...item1,
      data: {
        ...data1,
        defaults: {
          ...defaults1,
          currency_id,
          categories,
          categories_transfer,
        },
      },
    }
  }

  hasDefaultsCategory(category_id, name = 'categories') {
    const categories = getModelValue(this.item.data.defaults, name, [])
    return categories.includes(category_id)
  }

  @action toggleDefaultsCategory(value, name = 'categories') {
    const categories = this.item.data.defaults[name] || []
    const index = categories.indexOf(value)
    if (index === -1) categories.push(value)
    else categories.splice(index, 1)
    setModelValue(this.item.data.defaults, name, categories)
  }

  setSingle(item) {
    super.setSingle(this.skipParams(this.dataDefaults(item)))
  }

  setData(data, count) {
    super.setData(data.map(this.skipParams), count)
  }

  @computed get no_parents() {
    return this.items.filter(this.filterNoParent)
  }

  @computed get favorite_branch_ids() {
    if (!AppStore.user_info) return []
    const { fav_branches } = AppStore.user_info.data
    return fav_branches || []
  }

  @computed get favorites() {
    return this.favorite_branch_ids.map(this.findBranchesBy).filter(isNotNot)
  }

  @computed get my_branch_ids() {
    if (!AppStore.user_info) return []
    const { branch, branches } = AppStore.user_info
    return [branch.id, ...branches.map(b => b.id)]
  }

  @computed get my_branches() {
    return this.my_branch_ids.map(this.findBranchesBy).filter(isNotNot)
  }

  findBranchesBy = id => {
    return this.items.find(branch => id === branch.id)
  }

  filterNoParent = branch => {
    return (
      !branch.parent_id ||
      this.items.findIndex(item => item.id === branch.parent_id) === -1
    )
  }

  findChildrenOf = branch => {
    return this.items.filter(item => branch.id === item.parent_id)
  }

  // skip parent & children params
  skipParams = ({ parent, children, ...d }) => ({ ...d })

  @action
  setParent(item, id) {
    item.parent_id = parseInt(id)
  }

  async postData(pathname, item = this.item, location) {
    if (!item.parent_id && location) {
      const m = location.search.match(/[?&]p=([a-z\d-]+)(?:&|$)/)
      m && !isNaN(m[1]) && this.setParent(item, m[1])
    }
    return await super.postData(pathname, item)
  }

  @action
  toggleExpand(branch_id) {
    this.expands.set(
      branch_id,
      !this.expands.has(branch_id) || !this.expands.get(branch_id),
    )
  }

  @action selectActionBranch = branch => (this.action_branch = branch)

  @action clearActionBranch = () => (this.action_branch = null)

  @action toggleFavActionBranch = branch_id => {
    const i = this.favorite_branch_ids.findIndex(id => id === branch_id)
    if (i === -1) {
      this.favorite_branch_ids.push(branch_id)
    } else {
      this.favorite_branch_ids.splice(i, 1)
    }
  }

  favActionBranch = async branch_id => {
    if (!branch_id && this.action_branch) branch_id = this.action_branch.id
    if (!branch_id) return
    this.clearActionBranch()
    this.toggleFavActionBranch(branch_id)
    try {
      await requester.post('/user/fav', { branch_id })
    } catch (e) {
      this.toggleFavActionBranch(branch_id)
    }
  }

  @computed get is_action_branch_fav() {
    if (!this.action_branch) return false
    return this.favorite_branch_ids.includes(this.action_branch.id)
  }
}

const store = new BranchStore()
export default store
