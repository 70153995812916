import { action, makeObservable, observable, reaction } from 'mobx'
import requester from '../common/requester'

class AgentSelectStore {
  @observable loading = false
  @observable items = observable.array()
  @observable loaded = observable.map({})
  @observable is_search = false
  @observable search_name = ''

  queue = false

  constructor() {
    makeObservable(this)
    reaction(() => this.search_name, this.onSearchNameChange)
  }

  onSearchNameChange = () => {
    this.loadData()
  }

  @action
  loadData() {
    if (this.loading) {
      this.queue = true
      return
    }
    this.loading = true
    this.queue = false
    let params = {}
    if (this.search_name) {
      params['filter[fio]'] = this.search_name
    }
    requester
      .get('/agent/list', params)
      .then(this.setData)
      .finally(this.loadFinish)
  }

  @action
  setData = ({ data }) => {
    this.items.replace(data.list)
  }

  @action
  loadFinish = () => {
    this.loading = false
    if (this.queue) {
      this.loadData()
    }
  }

  async loadAgentInfo(agent_id) {
    const { data } = await requester.get('/agent/item/' + agent_id)
    return data.item
  }

  @action
  showSearch = () => {
    this.is_search = true
  }

  @action
  closeSearch = () => {
    this.is_search = false
    this.search_name = ''
  }
}

const store = new AgentSelectStore()
export default store
