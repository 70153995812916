import { SelectionStore } from './SelectionStore'

export class EttnStore extends SelectionStore {
  get is_selected() {
    return this.selections.length > 0
  }
}

const store = new EttnStore()

export default store
