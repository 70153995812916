import React from 'react'
import { Spinner } from 'reactstrap'

export default function SpinnerScreen() {
  return (
    <div>
      <div className='w-100 h-100 display-flex align-items-center justify-content-center'>
        <Spinner size='lg' color='primary' />
      </div>
    </div>
  )
}
