import { action, computed, makeObservable, observable } from 'mobx'
import requester from '../common/requester'
import BaseStore from './BaseStore'

class Node {
  id = 0
  parent_id = 0
  depth = 0

  @observable is_expanded = false

  constructor(props, store) {
    makeObservable(this)
    this._store = store
    Object.entries(props).forEach(this._apply)
  }

  _apply = ([key, value]) => (this[key] = value)

  @computed get children() {
    return this.is_expanded ? this._store.nodes.filter(this._find_children) : []
  }

  _find_children = n => n.parent_id === this.id && n.depth === this.depth + 1

  @computed get is_selected() {
    return this._store.selected ? this.isEqual(this._store.selected) : false
  }

  @action toggle = () => {
    this.is_expanded = !this.is_expanded
    this._store.selected = this
  }

  @action select = () => (this._store.selected = this)
  @action expand = () => (this.is_expanded = true)
  @action collapse = () => (this.is_expanded = false)

  isEqual(n) {
    return (
      n.id === this.id &&
      n.parent_id === this.parent_id &&
      n.depth === this.depth
    )
  }
}

class TraceStore extends BaseStore {
  @observable selected = null
  @observable nodes = observable.array()
  @observable show_scanner = false
  @observable products = observable.array()

  constructor() {
    super()
    this.filter_keys.push('id')
  }

  @computed get tree_data() {
    return this.nodes.filter(this._find_parents)
  }

  _find_parents = n => n.depth === 0 && !n.parent_id

  findOrCreateNode = item => {
    const node = this.nodes.find(Node.prototype.isEqual, item)
    return node ? node : new Node(item, this)
  }

  setData(data, count) {
    this.nodes.replace(data.map(this.findOrCreateNode))
    this.selected = null
    super.setData(data, count)
  }

  clearItems() {
    this.selected = null
    this.nodes?.clear()
    this.show_scanner = false
    this.products?.clear()
    super.clearItems()
  }

  @action hideScanner = () => (this.show_scanner = false)
  @action showScanner = () => (this.show_scanner = true)
  @action replaceProducts = products => this.products.replace(products)
  @action clearProducts = () => this.products.clear()

  isTara = ({ nomenclature }) =>
    !nomenclature
      ? false
      : nomenclature.editor
      ? nomenclature.editor === 'StockTara'
      : nomenclature?.category.editor === 'StockTara'

  fetchBufferProduct = async ({ product_id }) =>
    (await requester.get('/products/' + product_id)).data.item

  fetchBufferProducts = async product =>
    this.isTara(product) && product.data?.buffer
      ? [
          product,
          ...(
            await Promise.all(product.data.buffer.map(this.fetchBufferProduct))
          ).flat(),
        ]
      : [product]

  setIdentityFilter = identity => {
    identity && this.products.length > 0 && this.clearProducts()
    this.setFilter('id', identity || null)
    return !identity
  }

  onReadIdentity = async identity => {
    this.hideScanner()
    if (identity) {
      const { data } = await requester.get('/products', {
        'filter[identity_search]': identity,
      })
      const products = (
        await Promise.all(data.list.map(this.fetchBufferProducts))
      ).flat()
      products.length > 1 && this.replaceProducts(products)
      identity = products.length === 1 ? products[0].identity : null
    }
    return this.setIdentityFilter(identity)
  }
}

export default new TraceStore()
