import { computed, makeObservable, observable } from 'mobx'
import moment from 'moment'

import BaseStore from './BaseStore'

export class SalechartStore extends BaseStore {
  @observable stats = observable.array()
  @observable branches = observable.array()
  @observable upper_branches = observable.array()
  @observable contractors = observable.array()
  @observable nomenclatures = observable.array()
  @observable currencies = observable.array()
  @observable filter_stats = observable.map()
  @observable dynamic_sales = observable.array()
  @observable total_sales = null

  constructor() {
    super()
    makeObservable(this)
  }

  filterDefaults() {
    return {
      ...super.filterDefaults(),
      year: moment().year(),
      month: 'all',
    }
  }

  setResult(data, defaultData) {
    const ret = super.setResult(data, defaultData)
    if (data) {
      const { result, structure, access, item, list, count, ...rest } = data
      this.total_sales = rest
    }
    return ret
  }

  clearItems() {
    super.clearItems()
    this.stats?.clear()
    this.branches?.clear()
    this.upper_branches?.clear()
    this.nomenclatures?.clear()
    this.contractors?.clear()
    this.currencies?.clear()
    this.total_sales = null
  }

  @computed get filtered_nomenclatures() {
    const category_id = this.filter_stats.get('category_id') || 0
    const unit_id = this.filter_stats.get('unit_id') || 0
    return this.nomenclatures.filter(n => {
      return (
        (category_id ? n.category_id === category_id : true) &&
        (unit_id ? n.unit_id === unit_id : true)
      )
    })
  }

  _nomenclatures_id = new Set()

  @computed get filtered_nomenclatures_id() {
    const nomenclatures_id = this.filtered_nomenclatures.map(
      n => n.nomenclature_id,
    )
    const changed =
      nomenclatures_id.length !== this._nomenclatures_id.size ||
      nomenclatures_id.some(id => !this._nomenclatures_id.has(id))
    if (changed) this._nomenclatures_id = new Set(nomenclatures_id)
    return this._nomenclatures_id
  }

  @computed get filtered_branches() {
    const parent_id = this.filter_stats.get('parent_id') || 0
    if (!parent_id) return this.branches
    const under_branches_id = new Set()
    let branches_id = [parent_id]
    while (branches_id.length > 0) {
      const under_branches = this.upper_branches.filter(
        b =>
          branches_id.includes(b.parent_id) &&
          !under_branches_id.has(b.branch_id),
      )
      under_branches.forEach(b => under_branches_id.add(b.branch_id))
      branches_id = under_branches.map(b => b.branch_id)
    }
    return this.branches.filter(b => under_branches_id.has(b.branch_id))
  }

  _branches_id = new Set()

  @computed get filtered_branches_id() {
    const _branches_id = this.filtered_branches.map(b => b.branch_id)
    const changed =
      _branches_id.length !== this._branches_id.size ||
      _branches_id.some(id => !this._branches_id.has(id))
    if (changed) this._branches_id = new Set(_branches_id)
    return this._branches_id
  }

  @computed get filtered_stats() {
    const branch_id = this.filter_stats.get('branch_id') || 0
    const nomenclature_id = this.filter_stats.get('nomenclature_id') || 0
    const currency_id = this.filter_stats.get('currency_id') || 0
    const contractor_id = this.filter_stats.get('contractor_id') || 0
    const _nomenclatures_id = this.filtered_nomenclatures_id
    const _branches_id = this.filtered_branches_id
    return this.stats.filter(s => {
      return (
        (branch_id ? s.branch_id === branch_id : true) &&
        (nomenclature_id ? s.nomenclature_id === nomenclature_id : true) &&
        (currency_id ? s.currency_id === currency_id : true) &&
        (contractor_id ? s.contractor_id === contractor_id : true) &&
        _nomenclatures_id.has(s.nomenclature_id) &&
        _branches_id.has(s.branch_id)
      )
    })
  }

  @computed get nomenclature_quantities_stats() {
    const nomenclatures = this.filtered_stats.reduce((r, s) => {
      const quantity = parseFloat(s.quantity) + (r[s.nomenclature_id] || 0)
      return { ...r, [s.nomenclature_id]: quantity }
    }, {})
    return Object.entries(nomenclatures)
      .sort((a, b) => a[1] - b[1])
      .map(([nomenclature_id, quantity]) => {
        return { nomenclature_id: parseInt(nomenclature_id), quantity }
      })
  }

  @computed get chart_nomenclature_quantities() {
    return this.nomenclature_quantities_stats.map(n => ({
      name: this.nomenclatures.find(
        nn => nn.nomenclature_id === n.nomenclature_id,
      ).name,
      quantity: n.quantity,
    }))
  }

  @computed get nomenclature_amounts_stats() {
    const nomenclatures = this.filtered_stats.reduce((r, s) => {
      const amount = parseFloat(s.amount) + (r[s.nomenclature_id] || 0)
      return { ...r, [s.nomenclature_id]: amount }
    }, {})
    return Object.entries(nomenclatures)
      .sort((a, b) => a[1] - b[1])
      .map(([nomenclature_id, amount]) => {
        return { nomenclature_id: parseInt(nomenclature_id), amount }
      })
  }

  @computed get chart_nomenclature_amounts() {
    return this.nomenclature_amounts_stats.map(n => ({
      name: this.nomenclatures.find(
        nn => nn.nomenclature_id === n.nomenclature_id,
      ).name,
      quantity: n.amount,
    }))
  }

  @computed
  get sorted_categories() {
    return this.nomenclatures
      .map(n => ({
        nomenclature_id: n.nomenclature_id,
        category_id: n.category_id,
        category: n.category,
      }))
      .sort((a, b) => a.category_id - b.category_id)
  }

  @computed get _categories() {
    const categories_set = new Set(this.sorted_categories.map(c => c.category))
    return Array.from(categories_set)
  }

  @computed get category_quantities() {
    const quantities = this.filtered_stats.reduce((r, s) => {
      const category = this.sorted_categories.find(
        c => c.nomenclature_id === s.nomenclature_id,
      )
      if (category) {
        r[category.category_id] = (r[category.category_id] || 0) + s.quantity
      }
      return r
    }, {})
    return Object.values(quantities)
  }

  @computed get category_amounts() {
    const amounts = this.filtered_stats.reduce((r, s) => {
      const category = this.sorted_categories.find(
        c => c.nomenclature_id === s.nomenclature_id,
      )
      if (category) {
        r[category.category_id] = (r[category.category_id] || 0) + s.amount
      }
      return r
    }, {})
    return Object.values(amounts)
  }

  @computed get branch_quantities_stats() {
    const branches = this.stats.reduce((r, s) => {
      const quantity = parseFloat(s.quantity) + (r[s.branch_id] || 0)
      return { ...r, [s.branch_id]: quantity }
    }, {})
    return Object.entries(branches)
      .sort((a, b) => a[1] - b[1])
      .map(([branch_id, quantity]) => {
        return { branch_id: parseInt(branch_id), quantity }
      })
  }

  @computed get branch_amounts_stats() {
    const branches = this.stats.reduce((r, s) => {
      const amount = parseFloat(s.amount) + (r[s.branch_id] || 0)
      return { ...r, [s.branch_id]: amount }
    }, {})
    return Object.entries(branches)
      .sort((a, b) => a[1] - b[1])
      .map(([branch_id, amount]) => {
        return { branch_id: parseInt(branch_id), amount }
      })
  }

  @computed get top_chart_branches() {
    const branches = this.branch_quantities_stats.map(n => ({
      name: this.branches.find(bb => bb.branch_id === n.branch_id).name,
      quantity: n.quantity,
    }))
    const sorted_branches = branches.sort((a, b) => b.quantity - a.quantity)
    return sorted_branches.slice(0, 20)
  }

  @computed get bottom_chart_branches() {
    const branches = this.branch_quantities_stats.map(n => ({
      name: this.branches.find(bb => bb.branch_id === n.branch_id).name,
      quantity: n.quantity,
    }))
    const sorted_branches = branches.sort((a, b) => a.quantity - b.quantity)
    return sorted_branches.slice(0, 20)
  }

  @computed get top_chart_branches_amounts() {
    const branches = this.branch_amounts_stats.map(n => ({
      name: this.branches.find(bb => bb.branch_id === n.branch_id).name,
      quantity: n.amount,
    }))
    const sorted_branches = branches.sort((a, b) => b.quantity - a.quantity)
    return sorted_branches.slice(0, 20)
  }

  @computed get bottom_chart_branches_amounts() {
    const branches = this.branch_amounts_stats.map(n => ({
      name: this.branches.find(bb => bb.branch_id === n.branch_id).name,
      quantity: n.amount,
    }))
    const sorted_branches = branches.sort((a, b) => a.quantity - b.quantity)
    return sorted_branches.slice(0, 20)
  }

  @computed get dynamic_sales_quantity() {
    const is_year = this.filters.get('month') === 'all'
    return this.dynamic_sales.map(item => {
      return {
        name: is_year
          ? moment(item.date).format('MMMM')
          : moment(item.date).format('DD'),
        quantity: item.quantity,
      }
    })
  }

  @computed get dynamic_sales_amount() {
    const is_year = this.filters.get('month') === 'all'
    return this.dynamic_sales.map(item => {
      return {
        name: is_year
          ? moment(item.date).format('MMMM')
          : moment(item.date).format('DD'),
        quantity: item.amount,
      }
    })
  }

  @computed
  get _contractors() {
    return this.contractors.map(c => ({ value: c.contractor_id, text: c.name }))
  }

  @computed
  get _currencies() {
    return this.currencies.map(c => ({ value: c.currency_id, text: c.name }))
  }

  @computed
  get _branches() {
    return this.filtered_branches.map(b => ({
      value: b.branch_id,
      text: b.name,
    }))
  }

  @computed
  get _units() {
    const units_set = new Set()
    this.nomenclatures
      .map(n => ({ unit_id: n.unit_id, unit: n.unit }))
      .forEach(c => units_set.add(JSON.stringify(c)))
    const units = Array.from(units_set).map(b => JSON.parse(b))
    return units.map(b => ({ value: b.unit_id, text: b.unit }))
  }

  get should_save_filter_keys() {
    return [...super.should_save_filter_keys, 'year', 'month']
  }
}

const store = new SalechartStore()
export default store
