import React, { useState } from 'react'
import ReactImageLightbox from 'react-image-lightbox'
import { imageUrl } from '../utils'

/**
 * @param {string[]} sources
 * @returns {{setIndex:(index:number)=>void,lightbox:JSX.Element}}
 */
export default function useImageLightbox(sources) {
  const [index, setIndex] = useState(-1)

  if (index === -1 || sources.length === 0) return { setIndex, lightbox: <></> }

  const current_index = index % sources.length
  const prev_index = (current_index - 1 + sources.length) % sources.length
  const next_index = (current_index + 1 + sources.length) % sources.length

  const current_source = sources[current_index]
  const prev_source = sources[prev_index]
  const next_source = sources[next_index]

  const lightbox = (
    <ReactImageLightbox
      mainSrc={imageUrl(current_source, 0, 0)}
      prevSrc={imageUrl(prev_source, 0, 0)}
      nextSrc={imageUrl(next_source, 0, 0)}
      onCloseRequest={() => setIndex(-1)}
      onMovePrevRequest={() => setIndex(prev_index)}
      onMoveNextRequest={() => setIndex(next_index)}
    />
  )

  return { setIndex, lightbox }
}
