import { action, computed, makeObservable, observable } from 'mobx'
import requester from '../common/requester'
import BaseStore from './BaseStore'

export class TasksReportStore extends BaseStore {
  @observable detailedTasks = observable.array()
  @observable showModal = false
  @observable modalHeader = ''
  @observable active_selections = 'open'
  @observable branch_for_report = null

  constructor() {
    super()
    makeObservable(this)
  }

  @action
  setResult(data) {
    if (data && data.branch_for_report)
      this.setBranchForReport(data.branch_for_report)
    return super.setResult(data)
  }

  @action
  setBranchForReport(branch_for_report) {
    this.branch_for_report = branch_for_report
  }

  @computed
  get pickedUser() {
    return ''
  }

  @computed
  get userRoutes() {
    if (this.detailedTasks.length === 0) return []
    if (this.active_selections === 'open') return this.openTasks()
    else return this.closedTasks()
  }

  openTasks() {
    return this.detailedTasks.filter(task => {
      return task.route.status === 'pending'
    })
  }

  closedTasks() {
    return this.detailedTasks.filter(task => {
      return task.route.status === 'done'
    })
  }

  setModalHeader(userId) {
    const pickedItem = this.items.find(item => item.id === userId.user_id)
    if (!pickedItem) {
      this.modalHeader = ''
    } else {
      this.modalHeader = pickedItem.name
    }
  }

  async getTasksInDetail(path, key) {
    const data = await requester.get(path, key)
    this.setDetailedTasks(data)
    this.setModalHeader(key)
    this.onModalToggle()
  }

  setDetailedTasks(data) {
    this.detailedTasks.replace(data.data.list)
  }

  onModalToggle = () => {
    this.showModal = !this.showModal
  }

  @action
  setActiveOpenTasks = () => {
    this.active_selections = 'open'
  }

  @action
  setActiveDoneTasks = () => {
    this.active_selections = 'done'
  }
}

const store = new TasksReportStore()
export default store
