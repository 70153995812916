import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import localDB from '../common/localDB'
import requester from '../common/requester'
import { SelectionStore } from './SelectionStore'

export class StockStore extends SelectionStore {
  @observable count = 0
  @observable comment = ''
  @observable showWriteOffModal = false
  @observable lastWriteOffs = observable.array()
  @observable expands = observable.array()
  @observable is_in_own_branch = false
  @observable nomenclature_limits = {}

  constructor() {
    super()
    makeObservable(this)
    this.remove_filter_keys('start_from', 'end_to')
  }

  async fetchItem(pathname) {
    const { data } = await requester.get(pathname, this.jsonFilters)
    this.setResult(data)
    return data
  }

  setResult(data, defaultData) {
    if (!data) return
    this.nomenclature_limits = data?.nomenclature_limits || {}
    super.setResult(data, defaultData)
  }

  setData(data, count) {
    for (let item of data) {
      item.count_offline = 0
      item.count_diff = item.count
    }
    super.setData(data, count)
    return this.getOfflineStores().then(this.setOfflineStores)
  }

  async getOfflineStores() {
    let stores = {}
    try {
      let data = await localDB.get(
        '/transfers',
        { 'filter[transfer_status]': 'receive' },
        null,
        true,
        true,
      )
      data && StockStore.countStores(stores, data.list, -1, 'transfer_status')
      data &&
        StockStore.countStores(
          stores,
          data.list,
          1,
          'transfer_status',
          'branch2_id',
          'received',
        )
    } catch (e) {
      console.log(e)
    }
    try {
      let data = await localDB.get(
        '/invoices',
        { 'filter[invoice_status]': 'receive' },
        null,
        true,
        true,
      )
      data && StockStore.countStores(stores, data.list, 1, 'invoice_status')
    } catch (e) {
      console.log(e)
    }
    try {
      let data = await localDB.get(
        '/sales',
        { 'filter[sale_status]': 'new' },
        null,
        true,
        true,
      )
      data && StockStore.countSaleStores(stores, data.list)
    } catch (e) {
      console.log(e)
    }
    return stores
  }

  static countStores(
    stores,
    items,
    sign,
    status_col,
    branch_col = 'branch_id',
    quantity_col = 'quantity',
  ) {
    for (let item of items) {
      if (item[status_col] !== 'receive') continue
      for (let p of item.data.products) {
        if (!stores[p.nomenclature_id])
          stores[p.nomenclature_id] = { count: 0, ids: {} }
        if (!stores[p.nomenclature_id].ids[item[branch_col]])
          stores[p.nomenclature_id].ids[item[branch_col]] = 0
        let count = p[quantity_col] ? sign * p[quantity_col] : 0
        stores[p.nomenclature_id].count += count
        stores[p.nomenclature_id].ids[item[branch_col]] += count
      }
    }
  }

  static countSaleStores(stores, items) {
    for (let item of items) {
      if (item.sale_status !== 'new') continue
      const upd_date = item.upd_date ? moment(item.upd_date) : null
      for (let p of item.data.products) {
        if (!stores[p.nomenclature_id])
          stores[p.nomenclature_id] = { count: 0, ids: {} }
        if (!stores[p.nomenclature_id].ids[item.branch_id])
          stores[p.nomenclature_id].ids[item.branch_id] = 0
        for (let h of p.history) {
          if (upd_date && upd_date.isSameOrBefore(moment(h.rec_date))) continue
          let count = h.quantity ? -1 * h.quantity : 0
          stores[p.nomenclature_id].count += count
          stores[p.nomenclature_id].ids[item.branch_id] += count
        }
      }
    }
  }

  @action.bound
  setOfflineStores(stores) {
    if (Object.keys(stores).length === 0) return
    for (let item of this.items) {
      if (stores[item.nomenclature_id]) {
        if (!item.branch_id) {
          item.count_offline =
            1 * item.count_offline + stores[item.nomenclature_id].count
          item.count_diff = 1 * item.count + item.count_offline
          delete stores[item.nomenclature_id].count
        } else if (stores[item.nomenclature_id].ids[item.branch_id]) {
          item.count_offline =
            1 * item.count_offline +
            stores[item.nomenclature_id].ids[item.branch_id]
          item.count_diff = 1 * item.count + item.count_offline
          delete stores[item.nomenclature_id].ids[item.branch_id]
        } else if (stores[item.nomenclature_id].ids[item.branch_id] === 0) {
          delete stores[item.nomenclature_id].ids[item.branch_id]
        }
        if (
          !stores[item.nomenclature_id].count &&
          Object.keys(stores[item.nomenclature_id].ids).length === 0
        ) {
          delete stores[item.nomenclature_id]
        }
      }
    }
    const jsonFilters = this.jsonFilters
    let is_grouped = !jsonFilters.branch_id && !jsonFilters.nomenclature_id

    for (let nomenclature_id of Object.keys(stores)) {
      is_grouped &&
        this.items.push({
          id: `null:${nomenclature_id}`,
          branch_id: null,
          nomenclature_id,
          branch: null,
          name: null,
          unit: null,
          price: null,
          count: 0,
          count_offline: stores[nomenclature_id].count,
          count_diff: stores[nomenclature_id].count,
        })
      for (let branch_id of Object.keys(stores[nomenclature_id].ids)) {
        this.items.push({
          id: `${branch_id}:${nomenclature_id}`,
          branch_id,
          nomenclature_id,
          branch: null,
          name: null,
          unit: null,
          price: null,
          count: 0,
          count_offline: stores[nomenclature_id].ids[branch_id],
          count_diff: stores[nomenclature_id].ids[branch_id],
        })
      }
    }
  }

  @action
  selectWriteOffItem(item, e) {
    e && e.stopPropagation()
    this.showWriteOffModal = true
    this.count = item.count
    this.comment = ''
    return this.setSingle(item)
  }

  @action.bound
  deSelectWriteOffItem() {
    this.showWriteOffModal = false
    return this.setSingle({})
  }

  @action
  appendLastWriteOff(id) {
    let { name, branch, unit } = this.item
    this.lastWriteOffs.unshift({
      id,
      name,
      branch,
      unit,
      count: this.count,
      comment: this.comment,
      time: moment(),
    })
  }

  @action
  removeLastWriteOff(item) {
    this.lastWriteOffs.remove(item)
  }

  canWriteOff(item) {
    return item.branch_id && this.access.includes('edit')
  }

  writeOff = async () => {
    let { nomenclature_id, branch_id } = this.item
    const { data } = await requester.post('/store/write-off', {
      nomenclature_id,
      branch_id,
      quantity: this.count,
      comment: this.comment,
    })
    this.appendLastWriteOff(data.id)
    this.deSelectWriteOffItem()
    await this.fetchMany()
  }

  revertWriteOff = async item => {
    await requester.post('/store/write-off-revert', { id: item.id })
    this.removeLastWriteOff(item)
    await this.fetchMany()
  }

  @action
  toggleDetails({ nomenclature_id }, e) {
    e && e.stopPropagation()
    const { expands } = this
    if (expands.includes(nomenclature_id)) {
      expands.remove(nomenclature_id)
    } else {
      expands.push(nomenclature_id)
    }
  }

  @computed
  get noCollapse() {
    return this.filters.get('branch_id') || this.filters.get('nomenclature_id')
  }

  isExpanded({ nomenclature_id }) {
    return this.noCollapse || this.expands.includes(nomenclature_id)
  }

  @computed
  get total() {
    return this.items.reduce(StockStore.reducePrices, 0)
  }

  static reducePrices(total, item) {
    if (!item.branch_id) {
      return total
    }
    return total + parseFloat(item.price || 0)
  }

  selectAllFilter(item) {
    return item.branch_id && item.nomenclature_id
  }

  filterDefaults() {
    return {
      ...super.filterDefaults(),
      branch_id: this.is_in_own_branch || null,
    }
  }

  @action
  setInOwnBranch(ui, is_selection, is_single_branch) {
    let ii = ui && (is_selection || is_single_branch)
    this.is_in_own_branch = ii ? ui.branch_id : false
  }

  get should_save_filter_keys() {
    return [...super.should_save_filter_keys, 'category_id', 'nomenclature_id']
  }
}

const store = new StockStore()
export default store
