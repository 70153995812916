import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import PopupBase from '../../common/components/PopupBase'
import AutocompletePopupView from './popup/AutocompletePopupView'
import CreateModal from './popup/CreateModal'
import PopupView from './popup/PopupView'
import SelectModal from './popup/SelectModal'

@inject('store')
@observer
export default class FormPopup extends PopupBase {
  static propTypes = {
    ...PopupBase.propTypes,
    renderCmp: PropTypes.elementType,
    autocomplete: PropTypes.bool,
    structure: PropTypes.shape({
      table: PropTypes.string.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    ...PopupBase.defaultProps,
  }

  render() {
    const { children, autocomplete, ...props } = this.popup_view_props
    const View = autocomplete ? AutocompletePopupView : PopupView
    return (
      <View
        {...props}
        table={this.table}
        canCreate={this.can_create}
        clearSelect={this._clearSelect}
        onSelect={this._onSelect}
        createBtn
        createModal={this.can_create ? this.renderCreateModal() : null}
        item={this.item}
        itemId={this.value}
        selectModal={this.renderSelectModal()}
        toggleCreate={this._toggleCreate}
        toggleSelector={this._toggleSelector}
        value={this.display_value}
      >
        {children}
      </View>
    )
  }

  renderSelectModal = () => (
    <SelectModal
      defaultFilter={this.props.defaultFilter}
      finalFilter={this.props.finalFilter}
      defaultData={this.props.defaultData}
      isOpen={this._visibility.is_select}
      match={this.match_select}
      onCreateProp={this.on_create_prop}
      onSelect={this._onSelect}
      page={this.page_select}
      toggle={this._toggleSelector}
    />
  )

  renderCreateModal = () => (
    <CreateModal
      isOpen={this._visibility.is_create}
      match={this.match_add}
      page={this.page_add}
      toggle={this._toggleCreate}
      defaultData={this.props.defaultData}
    />
  )
}
