import { AppAside, AppFooter, AppHeader } from '@coreui/react'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/idea.css'
import 'easymde/dist/easymde.min.css'
import { observe } from 'mobx'
import { inject, observer } from 'mobx-react'
import 'rc-time-picker/assets/index.css'
import React, { Component } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import Helmet from 'react-helmet'
import { FaExternalLink } from 'react-icons/lib/fa'
import 'react-image-lightbox/style.css'
import LoadingBar from 'react-loading-bar'
import 'react-loading-bar/dist/index.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { NavLink } from 'react-router-dom'
import 'react-sortable-tree/style.css'
import { toast, ToastContainer } from 'react-toastify'
import 'simple-line-icons/css/simple-line-icons.css'
import meta from '../public/manifest.json'
import Footer from './components/Footer'
import Header from './components/Header'
import { ImageLightbox } from './components/ImageView'
import Main from './components/Main'
import RightSidebar from './components/RightSidebar'
import Sidebar from './components/Sidebar'
import Login from './Login'
import './scss/style.scss'

@inject('store')
@observer
export default class App extends Component {
  constructor(props) {
    super(props)
    this.store = this.props.store.AppStore
    this.taskStore = this.props.store.TaskStore
  }

  componentDidMount() {
    this.messageDisposer = observe(this.store, 'message', this.toastMessage)
    this.navDisposer = observe(this.store, 'hide_all_nav', this.onHideAllNav)
    if (process.env.NODE_ENV !== 'test') {
      this.store.refreshAuth()
    }
  }

  componentWillUnmount() {
    this.messageDisposer()
    this.navDisposer()
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>{meta.short_name}</title>
          <link
            type='image/x-icon'
            rel='icon'
            href={meta.icons[meta.icons.length - 1].src}
          />
        </Helmet>
        {!this.store.is_ready || !this.store.authenticated ? (
          <Login />
        ) : (
          <div className='app'>
            <AppHeader fixed>
              <LoadingBar show={this.store.isBusy} color='#f86c6b' />
              <Header />
            </AppHeader>
            <div className='app-body'>
              <Sidebar menu={this.props.store.MenuStore.menu_list_desktop} />
              <Main />
              <AppAside>
                <RightSidebar items={this.taskStore.items} />
              </AppAside>
            </div>
            <AppFooter fixed>
              <Footer />
            </AppFooter>
            <ImageLightbox />
          </div>
        )}
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          pauseOnHover
        />
      </div>
    )
  }

  toasts = {}

  toastMessage = () => {
    let { message, level, action, extraData } = this.store
    if (message && level) {
      let type = level
      if (type === 'danger') type = 'error'
      const ml = type + ':' + message
      if (this.toasts[ml] && toast.isActive(this.toasts[ml])) {
        toast.dismiss(this.toasts[ml])
        delete this.toasts[ml]
      }
      let options = {
        type,
        onClose: () => {
          delete this.toasts[ml]
          this.store.clearAlert()
        },
      }
      if (action && extraData && extraData.description) {
        options.autoClose = false
        this.toasts[ml] = toast(
          <div>
            {message}
            <div>
              <i>{extraData.description}</i>
            </div>
            <NavLink to={action} className={'link-white'}>
              <FaExternalLink color={'#fff'} />
              &nbsp;Посмотреть задачу
            </NavLink>
          </div>,
          options,
        )
        return
      } else if (action && this.store.level) {
        options.autoClose = false
        options.closeButton = (
          <NavLink to={action} className={'link-white'}>
            Посмотреть
          </NavLink>
        )
      }
      this.toasts[ml] = toast(message, options)
    }
  }

  onHideAllNav = () => {
    if (this.store.hide_all_nav) {
      $('body').addClass('hide-all-nav')
    } else {
      $('body').removeClass('hide-all-nav')
    }
  }
}
