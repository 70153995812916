import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import requester from '../common/requester'
import BaseStore from './BaseStore'

export class LibraryStore extends BaseStore {
  @observable edit = { path: null, node: { title: '', content: '' } }
  @observable view = { path: null, node: { title: '', content: '' } }
  @observable historyOfCatalog
  @observable dateInHistory = ''

  constructor() {
    super()
    makeObservable(this)
  }

  @computed
  get pickedBookVersion() {
    return this.historyOfCatalog.list.find(this.recordInHistoryOfCatalog)
  }

  @computed
  get previousView() {
    let picked = this.historyOfCatalog.list.indexOf(this.pickedBookVersion)
    let previousVersionOfCatalog =
      picked !== -1 && picked !== this.historyOfCatalog.list.length
        ? this.historyOfCatalog.list[picked + 1]
        : ''
    return previousVersionOfCatalog
      ? previousVersionOfCatalog.data.scripture
      : ''
  }

  @computed
  get pickedView() {
    let picked = this.historyOfCatalog.list.indexOf(this.pickedBookVersion)
    let pickedVersionOfCatalog = this.historyOfCatalog.list[picked]
    return pickedVersionOfCatalog ? pickedVersionOfCatalog.data.scripture : ''
  }

  recordInHistoryOfCatalog = record => {
    return record.created_on === this.dateInHistory
  }

  async convert(content, filename) {
    let { data } = await requester.post('/librarys/convert', {
      content,
      filename,
    })
    runInAction(() => (this.edit.node.content = data.content))
  }

  async publish() {
    let { data } = await requester.post('/librarys/publish', {
      id: this.item.id,
    })
    return data
  }

  @action addChapter(name = null) {
    let { data } = this.item
    let i = data.book.length
    let b = { title: name || `Глава №${i + 1}`, content: '', children: [] }
    data.book.push(b)
  }

  @action editChapter(node, path) {
    this.edit.path = path
    this.edit.node = node
  }

  @action viewChapter(node, path) {
    this.view.path = path
    this.view.node.title = node && node.title ? node.title : ''
    this.view.node.content = node && node.content ? node.content : ''
  }

  setSingle(item) {
    super.setSingle(item)
    return this.fetchHistory()
  }

  async fetchHistory() {
    if (this.item.id) {
      const catalog_id = this.item.id
      const { data } = await requester.get('/librarys/get_history', {
        catalog_id,
      })
      this.historyOfCatalog = data
      // this.dateInHistory = data.list && data.list[0] && data.list[0].created_on ? data.list[0].created_on : ''
    }
  }
}

const store = new LibraryStore()
export default store
