import { action, computed, makeObservable, observable } from 'mobx'
import * as XLSX from 'xlsx'
import localDB from '../common/localDB'
import { getModelValue, setModelValue } from '../common/utils'
import { SelectionStore } from './SelectionStore'

export class NomenclatureStore extends SelectionStore {
  @observable template_id = null
  @observable template = null
  @observable templates = observable.array()
  @observable sub_nomenclature = observable.array()
  @observable sub_branches = observable.array()
  @observable excel_import = null
  @observable is_multiple = false
  @observable edit_multiple = observable.array()

  constructor() {
    super()
    makeObservable(this)
    this.remove_filter_keys('branch_id')
    this.order = 'name'
  }

  setSingle(item) {
    item.identity = item.identity ? item.identity.split(',') : ['']
    super.setSingle(item)
    this.setTemplates(this.item.data.templates)
    this.setSub(this.item.data.sub_nomenclature || [])
    this.setSubBranches(this.item.data.sub_branches || [])
    if (this.is_multiple && this.edit_multiple.length > 0)
      this.getEditNomenclatures()
  }

  getEditNomenclatures() {
    for (const id of this.edit_multiple)
      localDB.get('/nomenclatures/' + id).then(this.setEditNomenclature)
  }

  @action
  addSub = (selected = []) => {
    if (!selected || selected.length === 0) return
    const new_sub = []

    for (const s of selected) {
      if (s.id === this.item?.id) continue
      if (s.unit?.id !== this.item?.unit?.id) continue
      if (
        this.sub_nomenclature.findIndex(f => f.nomenclature_id === s.id) === -1
      ) {
        new_sub.push({
          nomenclature_id: s.id,
          nomenclature: s.name,
        })
      }
    }

    this.sub_nomenclature = [...this.sub_nomenclature, ...new_sub]
  }

  @action
  removeSub = nid => {
    this.sub_nomenclature = this.sub_nomenclature.filter(
      f => f.nomenclature_id !== nid,
    )
  }

  @action
  setEditNomenclature = ({ item }) => {
    this.item.multiple &&
      this.item.multiple.push({
        id: item.id,
        identity: item.identity,
        name: item.name,
        name_short: item.name_short,
      })
    if (
      item.data &&
      item.data.templates &&
      item.data.templates.length > 0 &&
      this.templates.length === 0
    ) {
      this.setTemplates(item.data.templates)
    }
    if (!this.item.tnpa && item.tnpa) this.item.tnpa = item.tnpa
    if (!this.item.category_id && item.category_id)
      this.item.category_id = item.category_id
    if (!this.item.unit_id && item.unit_id) this.item.unit_id = item.unit_id
    if (!this.item.identify_each && item.identify_each)
      this.item.identify_each = item.identify_each
    if (!this.item.editor && item.editor) this.item.editor = item.editor
  }

  @action
  setTemplates(templates) {
    this.templates = templates
  }

  @action
  setSubBranches(branches) {
    this.sub_branches = branches
  }

  @action addBranch = selected => {
    if (!selected) return
    if (this.sub_branches.findIndex(b => b.id === selected.id) === -1)
      this.sub_branches.push({ id: selected.id, name: selected.name })
  }

  @action removeBranch = branch_id => {
    this.sub_branches = this.sub_branches.filter(f => f.id !== branch_id)
  }

  @action
  setSub(sub_nomenclature) {
    this.sub_nomenclature = sub_nomenclature
  }

  clearItem() {
    super.clearItem()
    this.template_id = this.template = null
    this.setTemplates([])
    this.setSub([])
    this.setSubBranches([])
    this.setIsMultiple(false)
    this.setEditMultiple([])
  }

  async postData(pathname, item = this.item) {
    let identity = item.identity
      .map(i => i.trim())
      .filter(i => !!i)
      .join(',')
    item.data.templates = [...this.templates]
    item.data.sub_branches = [...this.sub_branches]
    item.data.sub_nomenclature = [...this.sub_nomenclature]
    try {
      if (this.is_multiple) {
        this.setMultiple()
        if (item.multiple.length === 0) throw new Error('Добавьте наименования')
      }
      await super.postData(pathname, { ...item, identity })
    } catch (e) {
      this.catchPostData(e)
      throw e
    }
  }

  @action.bound
  addIdentity() {
    this.item.identity.push('')
  }

  @action
  removeIdentity(identity) {
    this.item.identity.remove(identity)
  }

  @action.bound
  addTemplate() {
    const { template_id, template } = this
    if (this.templates.indexOf(template_id) === -1) {
      this.templates.push(template_id)
      this.setDictTable(template, 'templates')
    }
    this.template_id = this.template = null
  }

  @action
  removeTemplate(t) {
    this.templates.remove(t)
  }

  @action.bound
  addNewMultiple() {
    this.item.multiple.push({
      identity: '',
      name: '',
      name_short: '',
    })
  }

  importMultiple = files => {
    if (!files || !files[0]) {
      return
    }
    let fileReader = new FileReader()
    fileReader.onload = this.excelLoad
    fileReader.readAsArrayBuffer(files[0])
  }

  @action.bound
  excelLoad(e) {
    // pre-process data
    let binary = ''
    let bytes = new Uint8Array(e.target.result)
    let length = bytes.byteLength
    for (let i = 0; i < length; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    // call 'xlsx' to read the file
    let oFile = XLSX.read(binary, {
      type: 'binary',
      cellDates: true,
      cellStyles: true,
    })
    for (const sh of oFile.SheetNames) {
      const items = XLSX.utils
        .sheet_to_json(oFile.Sheets[sh], { header: 1 })
        .map(NomenclatureStore.mapExcelRow)
      this.item.multiple && this.item.multiple.push(...items)
    }
    this.excel_import = null
  }

  static mapExcelRow(row) {
    return {
      identity: row[0] || '',
      name: row[1] || '',
      name_short: row[2] || '',
    }
  }

  @action
  removeMultipleItem(m) {
    this.item.multiple.remove(m)
  }

  setStructure(structure, item) {
    for (let s of structure) {
      if (s.name === 'name') {
        s.required = !this.is_multiple
        break
      }
    }
    super.setStructure(structure, item)
  }

  setMultiple() {
    this.item.multiple = this.item.multiple.filter(
      NomenclatureStore.filterMultiple,
    )
  }

  @action addBranchToNomenclatureLimits = select_branch => {
    if (this.item.data.nomenclature_limits[select_branch.id]) return
    this.item.data.nomenclature_limits[select_branch.id] = {
      branch_id: select_branch.id,
      branch_name: select_branch.name,
      min_quantity: null,
      critical_quantity: null,
    }
  }

  @action removeBranchToNomenclatureLimits = branch_id => {
    delete this.item.data.nomenclature_limits[branch_id]
  }

  @action
  setIsMultiple(type) {
    this.is_multiple = type
  }

  @action
  setEditMultiple(ids) {
    this.edit_multiple = ids
  }

  static filterMultiple(i) {
    return i.name && i.name.trim()
  }

  clearOrder() {
    this.order = 'name'
  }

  @computed
  get canEditMultiple() {
    if (this.selections.length > 1) {
      const items = this.selections
      let b_item = items[0]
      for (const item of items.slice(1)) {
        let all_same = true
        for (const col of [
          'tnpa',
          'category_id',
          'unit_id',
          'identify_each',
          'editor',
        ]) {
          all_same =
            (typeof item[col] === 'undefined' &&
              typeof b_item[col] === 'undefined') ||
            (item[col] === null && b_item[col] === null) ||
            item[col] === b_item[col]
          if (!all_same) break
          let t1 = item.data.templates || []
          let t2 = b_item.data.templates || []
          all_same = t1.join('') === t2.join('')
          if (!all_same) break
        }
        if (!all_same) return false
        b_item = item
      }
      return true
    }
    return false
  }

  mapEditMultipleIds() {
    return this.selections.map(s => 'ids[]=' + s.id).join('&')
  }

  @computed get tags() {
    const { data } = this.item
    const tags = getModelValue(data, 'tags', [])
    return tags.length === 0 ? [] : tags
  }

  @action.bound
  addTag() {
    const tag = {
      tag_tr: '',
      tag_ru: '',
    }
    setModelValue(this.item.data, 'tags', [...this.tags, tag])
  }

  @action
  removeTag(index) {
    this.tags.splice(index, 1)
  }

  get should_save_filter_keys() {
    return [
      ...super.should_save_filter_keys,
      'identity_search',
      'name',
      'category_id',
      'unit_id',
    ]
  }
}

const store = new NomenclatureStore()
export default store
