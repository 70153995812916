export default (viewer = true) => {
  const options = new Stimulsoft.Viewer.StiViewerOptions()

  if (viewer) {
    options.width = '100%'
    options.height = 'auto'
    options.reportDesignerMode = false
    options.appearance.fullScreenMode = false
    options.toolbar.showFullScreenButton = false
  }

  options.appearance.scrollbarsMode = false
  options.appearance.interfaceType = Stimulsoft.Viewer.StiInterfaceType.Mouse
  options.appearance.htmlRenderMode =
    Stimulsoft.Report.Export.StiHtmlExportMode.Table
  options.toolbar.showAboutButton = false
  options.toolbar.showDesignButton = false
  options.toolbar.showOpenButton = false
  options.toolbar.viewMode = Stimulsoft.Viewer.StiWebViewMode.Continuous

  return options
}
