import moment from 'moment'
import BaseStore from './BaseStore'

export class InvoiceReportStore extends BaseStore {
  filterDefaults() {
    return {
      ...super.filterDefaults(),
      start_from: moment().startOf('isoWeek').format('YYYY-MM-DD'),
    }
  }
}

export default new InvoiceReportStore()
