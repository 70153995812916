import PropTypes from 'prop-types'
import isNumber from 'lodash/isNumber'
import isObjectLike from 'lodash/isObjectLike'
import BaseInput from './BaseInput'
import { value2number } from '../../utils'

export default class NumInputBase extends BaseInput {
  static propTypes = {
    ...BaseInput.propTypes,
    onChange: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  getValue() {
    const value = super.getValue()
    return isNumber(value) ? value.toString() : value || ''
  }

  onChange(e) {
    const value =
      isObjectLike(e) && e.hasOwnProperty('target') ? e.target.value : e
    try {
      this.value = value2number(value)
    } catch (error) {
      console.log(error)
    }
    const { onChange } = this.props
    onChange && onChange(this.value)
  }
}
