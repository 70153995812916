import { computed, makeObservable, observable } from 'mobx'
import trim from './utils/trim'

export default class NameBase {
  @observable data

  constructor(data, production) {
    makeObservable(this)
    Object.defineProperty(this, 'production', {
      value: production,
      enumerable: false,
    })
    this.data = data
  }

  @computed get name() {
    return trim(this.data.name)
  }

  @computed get key() {
    return this.name
  }

  toJSON() {
    return {
      ...this.data,
      name: this.name,
    }
  }
}
