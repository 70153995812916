import React from 'react'
import PropTypes from 'prop-types'
import { autorun, isObservable } from 'mobx'
import { inject, observer } from 'mobx-react'
import calc from '../../common/calc-expression'
import { FormSelect } from './FormSelect'

@inject('store')
@observer
export default class ComputeExpressionSelect extends React.Component {
  static propTypes = {
    ...FormSelect.propTypes,
    expression: PropTypes.string,
  }

  state = { options: [] }

  noop = () => undefined
  watcher = this.noop

  runner = () => {
    const { model, expression } = this.props
    if (isObservable(model)) {
      const texts = calc(expression, model, true, false)
      this.setState({
        options: texts?.map(value => ({ value, text: value })) || [],
      })
    }
  }

  watch = () => {
    const { model, expression } = this.props
    this.watcher()
    this.watcher = expression && model ? autorun(this.runner) : this.noop
  }

  componentDidMount() {
    this.watch()
  }

  componentDidUpdate(prevProps) {
    const { model, expression } = this.props
    ;(prevProps.expression !== expression || prevProps.model !== model) &&
      this.watch()
  }

  componentWillUnmount() {
    this.watcher()
  }

  render() {
    const { item, store, expression, ...props } = this.props
    return <FormSelect options={this.state.options} {...props} />
  }
}
