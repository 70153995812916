import { action, observable } from 'mobx'
import xlsx from 'xlsx'
import requester from '../common/requester'
import { autoFitColumns } from '../common/utils'
import { SelectionStore } from './SelectionStore'

export class ReceiptOrderStatusStore extends SelectionStore {
  @observable branch_id = null
  get should_save_filter_keys() {
    return [
      ...super.should_save_filter_keys,
      'branch_id',
      'start_from',
      'end_to',
    ]
  }

  @action setBranch = (value = null) => (this.branch_id = value)

  exportExcel = async () => {
    const branch_id = this.branch_id
    const { data } = await requester.get(`/products/orders-stock/${branch_id}`)
    let header = {
      identity: 'Barkod',
      document_number: 'Plug No',
      number: 'Kap No',
      index_by_contractor: 'index',
      rec_date: 'Tarih',
      nomenclature: 'Urunler',
      contractor: 'Müşteri',
      administrative_area: 'Bölge',
      pieces: 'Adet',
      quantity: 'Kilo',
      official: 'Resmi',
      message: 'Not',
    }

    let groupedItems = {}
    data.list.forEach(item => {
      const contractorId = item.contractor
      if (!groupedItems[contractorId]) {
        groupedItems[contractorId] = []
      }
      groupedItems[contractorId].push(item)
    })

    let items = []
    let groupIndex = 1
    for (const contractorId in groupedItems) {
      const contractorItems = groupedItems[contractorId]
      contractorItems.forEach((item, index) => {
        let indexByContractor = `${index + 1}/${contractorItems.length}`
        items.push({
          identity: item.identity,
          document_number: item.document_number,
          number: item.number,
          index_by_contractor: indexByContractor,
          rec_date: item.rec_date,
          nomenclature: item?.nomenclature,
          contractor: item?.contractor,
          administrative_area: item?.administrative_area,
          pieces: item?.pieces,
          quantity: item.quantity,
          official: item.official ? 'Beyannameli' : '',
          message: item?.message,
        })
      })
      groupIndex++
    }

    items.unshift({ id: '' }, header)
    const worksheet = xlsx.utils.json_to_sheet(items, {
      skipHeader: true,
    })
    autoFitColumns(worksheet)

    const book = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(book, worksheet)

    xlsx.writeFile(book, `receıpt-order-${new Date().toISOString()}.xlsx`, {
      bookType: 'xlsx',
    })
    this.setBranch(null)
  }
}

const store = new ReceiptOrderStatusStore()
export default store
