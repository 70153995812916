import moment from 'moment'
import React, { Component } from 'react'
import { __DOMAIN__ } from '../../common/domain'
import meta from '../../public/manifest.json'

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <span>Copyright &copy; {moment().year()}</span>
        <span className='ml-auto'>
          {meta.short_name} Powered by{' '}
          <a href={`https://${__DOMAIN__}`} target='_blank'>
            {meta.name}
          </a>
        </span>
      </React.Fragment>
    )
  }
}

export default Footer
