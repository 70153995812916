import { action, makeObservable, observable } from 'mobx'
import requester from '../common/requester'
import BaseStore from './BaseStore'

class JobStore extends BaseStore {
  @observable name = null
  @observable bag = ''
  @observable jobs = observable.array()
  @observable current_jobs = observable.array()

  constructor() {
    super()
    makeObservable(this)
  }

  fetchJobs = async () => {
    const resp = await requester.get('/system/jobs')
    this.setJobs(resp.data.list)
  }

  @action
  setJobs = list => this.jobs.replace(list)

  runJob = () =>
    !!this.name &&
    (!requester.post('/system/jobs', { name: this.name, bag: this.bag }) ||
      true)

  setKey = item => (item.key = `key:${item.id}`)

  @action
  setCurrentJobs = data => {
    if (data.list) {
      data.list.map(this.setKey)
      this.current_jobs.replace(data.list)
    }
    if (data.item) {
      this.setKey(data.item)
      let item = this.current_jobs.find(i => i.id === data.item.id)
      this.current_jobs.remove(item)
      this.current_jobs.push(data.item)
    }
  }
}

const store = new JobStore()
export default store
