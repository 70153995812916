import { isObservableArray } from 'mobx'

export default function computeActualQuantity(
  product_id,
  base_product_id,
  store,
) {
  const production = store.getProduction(product_id)
  if (!production) return 0
  const { process_input } = production
  const { materials } = process_input
  return materials.reduce((total, material) => {
    let quantity = material.actual - 0
    if (Array.isArray(material.pid) || isObservableArray(material.pid)) {
      if (material.pid.indexOf(base_product_id) === -1) {
        return total
      } else if (material.pid.length > 1) {
        let i = 0
        while (material.pid[i] !== base_product_id) {
          const product = store.getProduct(material.pid[i])
          quantity -= product ? Math.min(product.quantity - 0, quantity) : 0
          i++
        }
        const product = store.getProduct(material.pid[i])
        quantity = product ? Math.min(product.quantity - 0, quantity) : 0
      }
    } else if (
      material.product_id !== base_product_id &&
      material.pid !== base_product_id
    ) {
      return total
    }
    return isFinite(quantity) ? total + quantity : total
  }, 0)
}
