import { action, observable, computed, makeObservable } from 'mobx'
import { imageUrl } from '../common/utils'

export class ImageViewStore {
  @observable groups = observable.map({})
  @observable current_group = null
  @observable current_index = -1

  constructor() {
    makeObservable(this)
  }

  @computed
  get current_images() {
    return (
      (this.current_group &&
        this.groups.has(this.current_group) &&
        this.groups.get(this.current_group)) ||
      []
    )
  }

  @computed
  get prev_index() {
    const index = this.current_index
    const length = this.current_images.length
    if (index === -1 || length < 2) return -1
    return (index + length - 1) % length
  }

  @computed
  get prev_image() {
    const index = this.prev_index
    const images = this.current_images
    return index !== -1 && images.length > index
      ? imageUrl(images[index])
      : null
  }

  @computed
  get current_image() {
    const index = this.current_index
    const images = this.current_images
    return index !== -1 && images.length > index
      ? imageUrl(images[index])
      : null
  }

  @computed
  get next_index() {
    const index = this.current_index
    const length = this.current_images.length
    if (index === -1 || length < 2) return -1
    return (index + length + 1) % length
  }

  @computed
  get next_image() {
    const index = this.next_index
    const images = this.current_images
    return index !== -1 && images.length > index
      ? imageUrl(images[index])
      : null
  }

  @action
  clearGroups() {
    this.groups.clear()
  }

  @action
  deleteGroup(group) {
    if (this.current_group === group) {
      this.current_group = null
      this.current_index = null
    }
    this.groups.has(group) && this.groups.delete(group)
  }

  @action
  setImages(group, images, active_index = -1) {
    if (images.length < 1) {
      this.deleteGroup(group)
      return
    }
    this.groups.set(group, images)
    active_index !== -1 && this.showImage(group, active_index)
  }

  @action
  showImage(group, active_index = 0) {
    this.current_group = group
    this.current_index = active_index
  }

  @action
  showPrevImage = () => {
    const index = this.prev_index
    const length = this.current_images.length
    if (index !== -1) this.current_index = index % length
  }

  @action
  showNextImage = () => {
    const index = this.next_index
    const length = this.current_images.length
    if (index !== -1) this.current_index = index % length
  }

  @action
  hideImage = () => {
    this.current_group = null
    this.current_index = -1
  }
}

const store = new ImageViewStore()
export default store
