import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import localDB from '../common/localDB'
import requester from '../common/requester'
import BaseStore from './BaseStore'

export class ReadyOrderStore extends BaseStore {
  @observable expands = observable.map({})
  @observable unchecked_nomenclatures = observable.map({})
  @observable readyOrder = null
  @observable readyOrderQty = null

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get date_str() {
    return this.item.date ? moment(this.item.date).format('Do MMM YYYY') : ''
  }

  @computed get is_today() {
    return this.item.date
      ? moment().isSame(moment(this.item.date), 'day')
      : false
  }

  @computed get is_outdated() {
    return this.item.date
      ? moment().isAfter(moment(this.item.date), 'day')
      : true
  }

  @computed get requested_quantity() {
    return (this.item.requests || [])
      .filter(request => request.nomenclature_id)
      .map(request => {
        const { nomenclature_id, quantity } = request
        let ready = 0
        this.item.ready_orders.forEach(ready_order => {
          const quantity = parseFloat(ready_order.quantity)
          if (
            ready_order.nomenclature_id === nomenclature_id &&
            isFinite(quantity)
          ) {
            ready += quantity
          }
        })
        this.item.received_ready_orders.forEach(received => {
          const quantity = parseFloat(received.quantity)
          if (
            received.nomenclature_id === nomenclature_id &&
            isFinite(quantity)
          ) {
            ready += quantity
          }
        })
        return { ...request, quantity: quantity - ready }
      })
      .filter(request => isFinite(request.quantity) && request.quantity > 0)
  }

  @computed get can_add_ready_orders() {
    return this.is_today && this.requested_quantity.length > 0
  }

  @computed get can_move_ready_orders() {
    return !this.is_today && (this.item.ready_orders?.length || 0) > 0
  }

  canSave() {
    return super.canSave() && !this.is_outdated
  }

  addReadyOrders = async stock => {
    const { item } = this
    if (!item.ready_orders) return []
    let ready_orders = item.ready_orders
    let items = []
    for (const { nomenclature_id } of stock) {
      const i = ready_orders.findIndex(
        ro => ro.nomenclature_id === nomenclature_id,
      )
      const data = await localDB.get(
        '/nomenclatures/' + nomenclature_id,
        {},
        null,
        true,
      )
      let nomenclature
      if (!data || !data.item) {
        continue
      } else {
        nomenclature = data.item
      }
      if (i !== -1) return
      let ro = {
        nomenclature_id: nomenclature_id,
        name: nomenclature.name,
        category_id: nomenclature.category_id,
        category: nomenclature.category ? nomenclature.category.name : '',
        quantity: null,
        unit: nomenclature.unit ? nomenclature.unit.short : '',
        received: false,
      }
      items.push(ro)
    }
    this.pushReadyOrders(items)
  }

  @action
  pushReadyOrders(orders) {
    this.item.ready_orders && this.item.ready_orders.push(...orders)
  }

  @action
  removeReadyOrder(ro) {
    const { item } = this
    if (item && item.ready_orders) {
      item.ready_orders.remove(ro)
    }
  }

  @action
  addRequestedProduct = async () => {
    const { branch_id } = this.item
    for (const { nomenclature_id, quantity } of this.requested_quantity) {
      await requester.post('/ready-agents/add', {
        branch_id,
        nomenclature_id,
        quantity,
      })
    }
  }

  @action
  setReadyOrder(ro) {
    if (ro) this.readyOrder = ro
  }

  @action.bound
  setReadyOrderQty(qty) {
    this.readyOrderQty = Math.abs(parseFloat(qty))
  }

  @action
  expandCategory = key => this.expands.set(key, !this.expands.get(key))

  @action
  toggleUncheckedNomenclature = (order, checked) => {
    const id = order.nomenclature_id.toString()
    this.unchecked_nomenclatures.set(id, !checked)
  }

  async postData(pathname, item = this.item) {
    const { unchecked_nomenclatures } = this
    return await super.postData(pathname, { ...item, unchecked_nomenclatures })
  }
}

const store = new ReadyOrderStore()
export default store
