import AgentConsumptionsStore from './AgentConsumptionsStore'
import AgentContractorsDebtStore from './AgentContractorsDebtStore'
import ByAgentsStore from './ByAgentsStore'
import ByContractorsStore from './ByContractorsStore'
import ByNomenclaturesStore from './ByNomenclaturesStore'
import InMonthSalePlanStore from './InMonthSalePlanStore'
import RoutesStore from './RoutesStore'

export default {
  AgentConsumptionsStore,
  AgentContractorsDebtStore,
  ByAgentsStore,
  ByContractorsStore,
  ByNomenclaturesStore,
  InMonthSalePlanStore,
  RoutesStore,
}
