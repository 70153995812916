import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import BranchPopupBase from '../../common/components/BranchPopupBase'
import CreateModal from './popup/CreateModal'
import PopupView from './popup/PopupView'
import SelectModal from './popup/SelectModal'

@inject('store')
@observer
export default class BranchPopup extends BranchPopupBase {
  static propTypes = {
    ...BranchPopupBase.propTypes,
    renderCmp: PropTypes.func,
  }

  static defaultProps = {
    ...BranchPopupBase.defaultProps,
  }

  render() {
    const {
      children,
      model,
      name,
      structure,
      onSelect,
      defaultFilter,
      finalFilter,
      noCreate,
      store,
      displayProp,
      headerBranch,
      underBranches,
      ...props
    } = this.props

    return (
      <PopupView
        {...props}
        canCreate={this.can_create}
        clearSelect={this._clearSelect}
        createModal={this.can_create ? this.renderCreateModal() : null}
        itemId={this.value}
        selectModal={this.renderSelectModal()}
        toggleSelector={this._toggleSelector}
        value={this.display_value}
      >
        {children}
      </PopupView>
    )
  }

  renderSelectModal = () => (
    <SelectModal
      defaultFilter={this.defaultFilter}
      finalFilter={this.finalFilter}
      isOpen={this._visibility.is_select}
      match={this.match_select}
      onCreateProp={this.on_create_prop}
      onSelect={this._onSelect}
      page={this.page_select}
      toggle={this._toggleSelector}
    />
  )

  renderCreateModal = () => (
    <CreateModal
      isOpen={this._visibility.is_create}
      match={this.match_add}
      page={this.page_add}
      parentId={this.parent_id}
      toggle={this._toggleCreate}
    />
  )
}
