import groupBy from 'lodash/groupBy'
import trim from './trim'

export default function groupByGroup(items) {
  const grouped = groupBy(items, ({ group }) => trim(group))
  return Object.keys(grouped)
    .sort()
    .reduce((groups, group) => {
      groups[group] = grouped[group]
      return groups
    }, {})
}
