import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Col, FormGroup, Label } from 'reactstrap'
import { useForm } from './FormContext'

const LABEL_SIZES = {
  default: { xs: 12, sm: 4, md: 3 },
  modal: { xs: 12, sm: 4 },
}

const COL_SIZES = {
  default: { xs: 12, sm: 8, md: 6 },
  modal: { xs: 12, sm: 8 },
}

function ActualFormLabel(props) {
  const { modal = false } = useForm()
  let { caption, readOnly, className, label, children } = props
  className = readOnly ? classNames('base-read-only', className) : className
  return (
    <FormGroup className={className} row>
      <Label {...((modal && LABEL_SIZES.modal) || LABEL_SIZES.default)}>
        {label}
      </Label>
      <Col {...((modal && COL_SIZES.modal) || COL_SIZES.default)}>
        {children}
        {!!caption && <div className='small text-muted mt-1'>{caption}</div>}
      </Col>
    </FormGroup>
  )
}

export class FormLabel extends React.Component {
  static propTypes = {
    className: PropTypes.any,
    label: PropTypes.any.isRequired,
    children: PropTypes.any,
    readOnly: PropTypes.bool,
    caption: PropTypes.string,
  }

  static defaultProps = { readOnly: false }

  render() {
    return <ActualFormLabel {...this.props} />
  }
}
