import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import { Row } from 'reactstrap'
import requester from '../../../common/requester'
import { getModelValue, setModelValue } from '../../../common/utils'
import QueryParamViewer from './QueryParamViewer'

@observer
export default class QueryParamsViewer extends React.Component {
  static propTypes = {
    model: PropTypes.object.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    code: PropTypes.string,
  }

  render() {
    const { model, code, id } = this.props
    return code ? (
      <QueryCodeParamsViewer model={model} code={code} />
    ) : id ? (
      <QueryIdParamsViewer model={model} id={id} />
    ) : null
  }
}

@observer
class QueryCodeParamsViewer extends React.Component {
  static propTypes = {
    model: PropTypes.object.isRequired,
    code: PropTypes.string.isRequired,
  }

  state = { params: {} }

  get model() {
    const { model, code } = this.props
    return model ? getModelValue(model, code, null) : null
  }

  fetchParams = async () => {
    const { code } = this.props
    this.setState({ params: {} })
    const {
      data: { params },
    } = await requester.get('/report-query/' + code + '/params')
    this.setState({ params })
  }

  componentDidMount() {
    const { model, code } = this.props
    !this.model && setModelValue(model, code, {})
    this.fetchParams()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { model, code } = this.props
    !this.model && setModelValue(model, code, {})
    prevProps.code !== code && this.fetchParams()
  }

  render() {
    return (
      <Row>
        {Object.entries(this.state.params)
          .sort(([, a], [, b]) => a.index - b.index)
          .map(this.renderQueryParamViewer)}
      </Row>
    )
  }

  renderQueryParamViewer = ([param, props]) => (
    <QueryParamViewer key={param} {...props} model={this.model} name={param} />
  )
}

@observer
class QueryIdParamsViewer extends React.Component {
  static propTypes = {
    model: PropTypes.object.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }

  state = { params: {} }

  fetchParams = async () => {
    const { id } = this.props
    this.setState({ params: {} })
    const {
      data: { params },
    } = await requester.get('/report-query/' + id + '/params')
    this.setState({ params })
  }

  componentDidMount() {
    this.fetchParams()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    prevProps.id !== this.props.id && this.fetchParams()
  }

  render() {
    return (
      <Row>
        {Object.entries(this.state.params)
          .sort(([, a], [, b]) => a.index - b.index)
          .map(this.renderQueryParamViewer)}
      </Row>
    )
  }

  renderQueryParamViewer = ([param, props]) => (
    <QueryParamViewer
      key={param}
      {...props}
      model={this.props.model}
      name={param}
    />
  )
}

@observer
export class QueryParamsParamViewer extends React.Component {
  static propTypes = {
    model: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  }

  render() {
    return (
      <Row>
        {Object.entries(this.props.params)
          .sort(([, a], [, b]) => a.index - b.index)
          .map(this.renderQueryParamViewer)}
      </Row>
    )
  }

  renderQueryParamViewer = ([param, props]) => (
    <QueryParamViewer
      key={param}
      {...props}
      model={this.props.model}
      name={param}
    />
  )
}
