import { action, makeObservable, observable, toJS } from 'mobx'
import * as XLSX from 'xlsx'
import localDB from '../common/localDB'
import requester from '../common/requester'
import { DAYS_DICT } from '../common/utils'
import AppStore from './AppStore'
import { SelectionStore } from './SelectionStore'

export class RouteStore extends SelectionStore {
  @observable expanded_id = -1
  @observable contractors = observable.array()
  @observable contractors_checked = observable.array()
  @observable show_import = false
  @observable file_excel = null
  @observable import_data = observable.array()
  @observable active_tab = '1'

  constructor() {
    super()
    makeObservable(this)
  }

  @action
  expandRoute(r, e) {
    e && e.stopPropagation()
    this.expanded_id = this.expanded_id === r.id ? -1 : r.id
  }

  async localGet(...args) {
    if (args.length < 6) args.length = 6
    args[2] = 'name'
    args[5] = this.filterDays
    return await super.localGet(...args)
  }

  filterDays = r => {
    if (!this.filters.has('day') || !this.filters.get('day')) return true
    return r.data.days.includes(this.filters.get('day'))
  }

  setData(data, count) {
    for (const item of data) {
      item.days_string = item.data.days.map(RouteStore.mapDays).join(', ')
    }
    super.setData(data, count)
  }

  setSingle(item) {
    super.setSingle(item)
    this.loadContractors().then(this.replaceContractors)
  }

  async loadContractors() {
    const { data, branch_id, id } = this.item
    if (!id || !branch_id) return
    const contractors_id = data.contractors || []
    let contractors = []
    const user = AppStore.user_info
    if (user && this.item.branch_id !== user.branch_id) {
      const { list } = await localDB.get(
        '/contractors',
        { 'filter[branch_id]': branch_id },
        'name',
        true,
      )
      contractors.push(...list)
      if (this.item.id !== id) return
      const not_found_contractors_id = contractors_id.filter(id =>
        contractors.every(contractor => {
          return contractor.id !== id && contractor.remote_id !== id
        }),
      )
      for (const contractor_id of not_found_contractors_id) {
        try {
          const { item } = await localDB.get(
            '/contractors/' + contractor_id,
            {},
            'name',
            true,
          )
          item && contractors.push(item)
          if (this.item.id !== id) return
        } catch {
          if (this.item.id !== id) return
        }
      }
    } else {
      const { list } = await localDB.get('/contractors', {}, 'name', true)
      contractors.push(...list)
    }

    const contractors_checked = contractors_id
      .map(id =>
        contractors.find(contractor => {
          return contractor.id === id || contractor.remote_id === id
        }),
      )
      .filter(Boolean)

    if (this.item.id !== id) return

    const mapContractor = contractor => {
      return {
        id: contractor.id,
        name: contractor.name,
        address: (contractor.data && contractor.data.address) || '',
      }
    }

    return {
      contractors: contractors
        .filter(contractor => contractors_checked.indexOf(contractor) === -1)
        .map(mapContractor),
      contractors_checked: contractors_checked.map(mapContractor),
    }
  }

  @action replaceContractors = data => {
    if (!data) return
    this.contractors.replace(data.contractors)
    this.contractors_checked.replace(data.contractors_checked)
  }

  @action
  contractorCheck(e, index, c) {
    if (
      e.target.checked &&
      (index === -1 || this.contractors[index].id === c.id)
    ) {
      index > -1 && this.contractors.splice(index, 1)
      this.contractors_checked.push(c)
    } else if (
      !e.target.checked &&
      (index === -1 || this.contractors_checked[index].id === c.id)
    ) {
      index > -1 && this.contractors_checked.splice(index, 1)
      this.contractors.unshift(c)
    }
  }

  @action
  contractorUp(e, index, c) {
    if (this.contractors_checked[index].id === c.id && index > 0) {
      const c1 = this.contractors_checked[index - 1]
      this.contractors_checked.splice(index - 1, 2, c, c1)
    }
  }

  @action
  contractorDown(e, index, c) {
    if (
      this.contractors_checked[index].id === c.id &&
      index + 1 < this.contractors_checked.length
    ) {
      const c1 = this.contractors_checked[index + 1]
      this.contractors_checked.splice(index, 2, c1, c)
    }
  }

  static mapDays(d) {
    return DAYS_DICT[d]
  }

  clearItem() {
    super.clearItem()
    this.contractors?.clear()
    this.contractors_checked?.clear()
  }

  filterDefaults() {
    return { ...super.filterDefaults(), day: '' }
  }

  async postData(pathname, item = this.item) {
    this.item.data.contractors = this.contractors_checked.map(c => c.id)
    return super.postData(pathname, item)
  }

  @action
  toggleShowImport = () => {
    this.show_import = !this.show_import
  }

  getExcelData = () => {
    if (!this.file_excel) {
      return
    }
    let fileReader = new FileReader()
    fileReader.onload = this.readExcelData
    fileReader.readAsArrayBuffer(this.file_excel)
  }

  @action
  readExcelData = e => {
    let binary = ''
    let bytes = new Uint8Array(e.target.result)
    let length = bytes.byteLength
    for (let i = 0; i < length; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    let oFile = XLSX.read(binary, {
      type: 'binary',
      cellDates: true,
      cellStyles: true,
    })
    let data = []
    for (const sh of oFile.SheetNames) {
      const items = XLSX.utils
        .sheet_to_json(oFile.Sheets[sh], { header: 1 })
        .map(RouteStore.mapExcelRow)
        .filter(RouteStore.filterExcelRow)
      data.push({
        route_id: null,
        contractors: items,
      })
    }
    this.import_data.replace(data)
    this.active_tab = '1'
    this.file_excel = null
  }

  static mapExcelRow(row) {
    return {
      name: `${row[0] || ''}`.trim(),
      inn: `${row[1] || ''}`.trim(),
      address: `${row[2] || ''}`.trim(),
      contact_person: `${row[3] || ''}`.trim(),
      phone: `${row[4] || ''}`.trim(),
      debt: !row[5] || !isFinite(row[5]) ? null : parseFloat(row[5]),
    }
  }

  static filterExcelRow(row) {
    return !!row.name
  }

  saveExcelData = () => {
    this.sendExcelData().then(this.onSentExcel).catch(this.catchPostData)
  }

  async sendExcelData() {
    for (const p of this.import_data) {
      if (!p.route_id) throw new Error('Укажите маршрут!')
    }
    await requester.post('/routes/import', { import: toJS(this.import_data) })
  }

  @action
  onSentExcel = () => {
    this.show_import = false
    this.import_data.replace([])
  }

  @action
  toggleActiveTab = tab => {
    this.active_tab = tab
  }

  get should_save_filter_keys() {
    return [...super.should_save_filter_keys, 'day', 'user_id']
  }
}

const store = new RouteStore()
export default store
