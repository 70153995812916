import React from 'react'
import Placeholder from 'react-placeholder'
import { Card, CardBody, CardHeader } from 'reactstrap'

export default class Loading extends React.Component {
  render() {
    return (
      <Card>
        {this.props.title && <CardHeader>{this.props.title}</CardHeader>}
        <CardBody>
          <Placeholder
            type='media'
            rows={7}
            showLoadingAnimation={true}
            delay={500}
            ready={false}
            children={' '}
          />
        </CardBody>
      </Card>
    )
  }
}
