import isNumber from 'lodash/isNumber'
import { action, computed, makeObservable, observable } from 'mobx'
import Input from './Input'
import roundQuantity from './utils/roundQuantity'
import sumActual from './utils/sumActual'

export default class Material extends Input {
  @observable value = null
  @observable compute_value = null

  constructor(data, production) {
    super(data, production)
    makeObservable(this)
  }

  @computed get primary() {
    return !!this.data.primary
  }

  @computed get quantity() {
    const { quantity = null } = this.data // Quantity maybe null or maybe greater or equal to zero
    const value =
      quantity === null
        ? null
        : isNumber(quantity)
        ? quantity
        : parseFloat(quantity)
    return value === null ? null : isFinite(value) ? value : null
  }

  @computed get actual_inputs() {
    const { process_input } = this.production.data
    return process_input?.materials?.length > 0
      ? process_input.materials.filter(
          m => m.nomenclature_id === this.nomenclature_id,
        )
      : []
  }

  @computed get actual() {
    return roundQuantity(this.actual_inputs.reduce(sumActual, 0))
  }

  @computed get remained_quantity() {
    if (this.quantity === null) {
      return null
    }
    const quantity =
      this.quantity -
      this.actual -
      this.production.states.materials.reduce((total, m) => {
        if (m.nomenclature_id !== this.nomenclature_id) {
          return total
        }
        const value = m instanceof Material ? m.value : m.quantity // Don't use 'value' property. It will reproduce recursive invoking.
        const v =
          value === null ? 0 : isNumber(value) ? value : parseFloat(value)
        return isFinite(v) ? total + v : total
      }, 0)
    return roundQuantity(quantity)
  }

  @computed get tara_identities() {
    const tara_identities = this.actual_inputs.reduce((identities, m) => {
      m.tara_identity && identities.add(m.tara_identity)
      return identities
    }, new Set())
    return Array.from(tara_identities).join(', ')
  }

  @action.bound toggleComputeValue(checked) {
    if (checked) {
      const { item, states } = this.production
      const output =
        item.outputs.find(o => o.main) ||
        (item.outputs.length === 1 ? item.outputs[0] : undefined)
      let compute_value = 0
      if (output) {
        compute_value += output.actual
        if (
          states.has_adding_output &&
          states.adding_output.nomenclature_id === output.nomenclature_id
        ) {
          compute_value += states.output_value
        }
      }
      try {
        let compute_formula = this.compute.replace(/out/g, `${compute_value}`)
        compute_value = window.eval(compute_formula) || 0
        if (this.round) compute_value = Math.round(compute_value)
      } catch (e) {
        compute_value = 0
      }
      this.compute_value = roundQuantity(compute_value)
    } else {
      this.compute_value = null
      this.value = null
    }
  }

  toJSON() {
    let value = (this.value && parseFloat(this.value)) || 0
    value += (this.compute_value && parseFloat(this.compute_value)) || 0
    return {
      ...super.toJSON(),
      value,
      quantity: this.quantity,
      actual: this.actual,
      remained_quantity: this.remained_quantity,
    }
  }
}
