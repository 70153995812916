import moment from 'moment'
import ReportBase from './ReportBase'
import xlsx from 'xlsx'
import { autoFitColumns } from '../../common/utils'

export class AgentConsumptionsStore extends ReportBase {
  constructor() {
    super('/sale/report-agent-consumptions')
  }

  getFiltersInitial() {
    return {
      start_from: moment().startOf('isoWeek').format('YYYY-MM-DD'),
      end_to: moment().format('YYYY-MM-DD'),
      branch_id: null,
    }
  }

  get filter_params() {
    let params = {}
    params['filter[start_from]'] = this.filters.start_from || ''
    params['filter[end_to]'] = this.filters.end_to || ''
    if (this.filters.branch_id)
      params['filter[branch_id]'] = this.filters.branch_id
    return params
  }

  exportDataToExcel = () => {
    let items = [['ФИО', 'Сумма', 'По курсу']]
    for (const item of this.items) {
      items.push([
        item?.name,
        `${parseFloat(item?.amount).toFixed(2).replace('.', ',')}${
          item?.currency
        }`,
        `${parseFloat(item?.amount_converted).toFixed(2).replace('.', ',')}${
          item?.currency_converted
        }`,
      ])
    }
    const worksheet = xlsx.utils.aoa_to_sheet(items)
    autoFitColumns(worksheet)
    const book = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(book, worksheet, 'list_1')
    xlsx.writeFile(book, `Отчет по расходам.xlsx`, {
      bookType: 'xlsx',
    })
  }
}

const store = new AgentConsumptionsStore()
export default store
