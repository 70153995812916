import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CurrencyBase from '../../common/components/CurrencyBase'
import { inject, observer } from 'mobx-react'

@inject('store')
@observer
export default class Currency extends CurrencyBase {
  static propTypes = {
    symbol: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.any,
    withCent: PropTypes.bool,
  }

  static defaultProps = {
    withCent: true,
  }

  render() {
    const render = super.render()
    return render ? (
      <span
        className={
          this.num_value < 0
            ? classNames('text-danger', this.props.className)
            : this.props.className
        }
      >
        {render}
      </span>
    ) : null
  }
}
