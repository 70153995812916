import Storage from 'react-native-storage'

export function createStorage(backend) {
  const storage = getStorage()
  if (storage) {
    return storage
  }
  const LocalStorage = new Storage({
    // maximum capacity, default 1000
    size: 1000,

    // Use AsyncStorage for RN, or window.localStorage for web.
    // If not set, data would be lost after reload.
    storageBackend: backend,

    // expire time, default 1 day(1000 * 3600 * 24 milliseconds).
    // can be null, which means never expire.
    defaultExpires: null,

    // cache data in the memory. default is true.
    enableCache: true,

    // if data was not found in storage or expired,
    // the corresponding sync method will be invoked and return
    // the latest data.
    sync: {},
  })
  if (global) {
    global.storage = LocalStorage
  } else {
    window.storage = LocalStorage
  }
  return LocalStorage
}

/**
 * @return {Storage}
 */
export function getStorage() {
  try {
    if (global && global.storage) {
      return global.storage
    }
    if (window !== undefined && window.storage) {
      return window.storage
    }
  } catch (e) {
    console.trace('storage error: ' + e)
  }
  return null
}
