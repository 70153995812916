import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import localDB from '../common/localDB'
import requester from '../common/requester'
import { SelectionStore } from './SelectionStore'

export class VehicleChecksStore extends SelectionStore {
  @observable agents = observable.array()

  constructor() {
    super()
    makeObservable(this)
  }

  @computed
  get edit_view() {
    return !this.item.user_id || this.item.data?.vehicle_check_status !== 'done'
  }

  @computed
  get draft_status() {
    return (
      this.item.data?.vehicle_check_status !== 'done' &&
      this.item.user_id &&
      this.item.data.start_datetime
    )
  }

  isReadOnly() {
    return (
      !this.item.user_id ||
      this.item.data?.vehicle_check_status === 'done' ||
      super.isReadOnly()
    )
  }

  setSingle(item) {
    if (item.data?.vehicle_check_status === 'done') {
      this.setAccess(['view'])
      this.setStructure(this.structure.map(this.mapStructureReadOnly))
    }
    super.setSingle(item)
    if (!this.item.user_id) {
      this.loadAgents()
    }
  }

  loadAgents() {
    return this.fetchAgents()
  }

  async fetchAgents() {
    if (localDB.hasConnection) {
      const data = await requester.get('/agent/list')
      this.setAgents(data.data.list)
    } else {
      let data = await localDB.get('/users', {}, 'fio', true)
      data && this.setAgents(data.list)
    }
  }

  @action
  setAgents(list) {
    this.agents.replace(list)
  }

  clearItem() {
    super.clearItem()
    this.agents?.clear()
  }

  @action
  selectUser(user) {
    this.item.user = user
    this.item.user_id = user ? user.id : null
  }

  @action
  startVehicleCheck = () => {
    this.item.data.start_datetime = moment().format('YYYY-MM-DD HH:mm:ss')
  }

  @action
  setStatusDone() {
    if (this.item.data) {
      this.item.data.vehicle_check_status = 'done'
      this.item.data.done_datetime = moment().format('YYYY-MM-DD HH:mm:ss')
    }
  }
}

const store = new VehicleChecksStore()
export default store
