import { inject, observer } from 'mobx-react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from 'reactstrap'
import meta from '../common/manifest'
import { AutoButton, FormInput, FormRadio } from './ui/FormInput'
import PinForm from './ui/PinForm'

@inject('store')
@withTranslation('login')
@observer
export default class Login extends React.Component {
  constructor(props) {
    super(props)
    this.store = this.props.store.AppStore
  }

  render() {
    const { t } = this.props
    const { is_ready, authenticated, has_pin, tmp_token, pin_confirm, usePin } =
      this.store
    return (
      <div className='app flex-row align-items-center'>
        <Container>
          <Row className='justify-content-center'>
            <Col md='8'>
              <Form onSubmit={e => this.store.login(e)}>
                <CardGroup>
                  <Card className='p-4'>
                    {!usePin || (!tmp_token && !authenticated) ? (
                      <LoginForm />
                    ) : tmp_token && !authenticated ? (
                      <PinForm pinConfirm={!!pin_confirm} newPin={!has_pin} />
                    ) : null}
                    {!is_ready ? <LoadingForm /> : null}
                  </Card>
                  <Card
                    className='text-white bg-primary py-5 d-md-down-none'
                    style={{ width: '44%' }}
                  >
                    <CardBody className='text-center'>
                      <div>
                        <h2>{t('welcome')}</h2>
                        <p>{t('automated_system')} </p>
                        <p>{meta.name}</p>
                      </div>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

@inject('store')
@withTranslation('login')
class LoginForm extends React.Component {
  render() {
    const { AppStore, SettingsStore } = this.props.store
    const { t } = this.props
    return (
      <CardBody>
        <h1>{meta.short_name}</h1>
        <p className='text-muted'>{t('enter_your_details')}</p>
        <InputGroup className='mb-3'>
          <div className='input-group-prepend'>
            <span className='input-group-text'>
              <i className='icon-user' />
            </span>
          </div>
          <FormInput
            type='text'
            placeholder={t('username')}
            model={AppStore}
            autoFocus
            name={'username'}
            autoComplete={'username'}
            required
          />
        </InputGroup>
        <InputGroup className='mb-4'>
          <div className='input-group-prepend'>
            <span className='input-group-text'>
              <i className='icon-lock' />
            </span>
          </div>
          <FormInput
            type='password'
            placeholder={t('password')}
            model={AppStore}
            name={'password'}
            autoComplete={'current-password'}
            required
          />
        </InputGroup>
        <Row className='justify-content-between mb-3'>
          <Col xs='auto'>
            <AutoButton type='submit' color='primary' className='px-4'>
              {t('login')}
            </AutoButton>
          </Col>
          <Col xs='auto'>
            <Button type='button' color='link' className='px-0'>
              {t('forgot_password')}
            </Button>
          </Col>
        </Row>
        <FormRadio
          className='m-0'
          model={SettingsStore}
          name={'language'}
          options={SettingsStore.language_options}
          canClear={false}
          inline
        />
      </CardBody>
    )
  }
}

function LoadingForm() {
  return (
    <CardBody className={'app-loading'}>
      <div className='simple-loader' />
    </CardBody>
  )
}
