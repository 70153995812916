import { action, computed, observable, makeObservable } from 'mobx'
import moment from 'moment'
import requester from '../common/requester'
import AppStore from './AppStore'

const char_map = {
  13: '\n',
  42: '*',
  43: '+',
  44: ',',
  45: '-',
  46: '.',
  47: '/',
  48: '0',
  49: '1',
  50: '2',
  51: '3',
  52: '4',
  53: '5',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
}

export class ReadyAgentStoreOld {
  @observable today = moment()
  @observable stage = 'agent'
  @observable is_weight_tara_input = false
  @observable is_weight_tara_input_first_focus = true
  @observable chars = observable.array()
  @observable chars_quantity = observable.array()
  @observable chars_weight_tara = observable.array()
  @observable history = observable.array()
  @observable agents = observable.array()
  @observable agent = null
  @observable nomenclatures = observable.array()
  @observable nomenclature = null
  @observable tara_quantity = 0
  @observable boxes = observable.map({})
  @observable gave_boxes = observable.map({})
  @observable is_tara_gave_input = false
  @observable chars_tara = observable.array()
  @observable chars_gave_tara = observable.array()

  goBackListener = null

  constructor() {
    makeObservable(this)
    this.setReadyAgentData = this.setReadyAgentData.bind(this)
    this.filterHistory = this.filterHistory.bind(this)
    this.checkMatches = this.checkMatches.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
    this.goToTara = this.goToTara.bind(this)
    this.goBack = this.goBack.bind(this)
    this.onAgentSelect = this.onAgentSelect.bind(this)
    this.switchToInputQuantity = this.switchToInputQuantity.bind(this)
    this.switchToInputTara = this.switchToInputTara.bind(this)
    this.switchToInputTaraReceive = this.switchToInputTaraReceive.bind(this)
    this.switchToInputTaraGave = this.switchToInputTaraGave.bind(this)
    this.onNomenclatureSelect = this.onNomenclatureSelect.bind(this)
    this.sendReadyProduct = this.sendReadyProduct.bind(this)
    this.onSentReadyProduct = this.onSentReadyProduct.bind(this)
    this.deleteReadyProduct = this.deleteReadyProduct.bind(this)
    this.onDeleteReadyProduct = this.onDeleteReadyProduct.bind(this)
    this.onSentTara = this.onSentTara.bind(this)
  }

  setGoBackListener(listener) {
    this.goBackListener = listener
  }

  loadReadyAgentData() {
    ReadyAgentStoreOld.fetchReadyAgentData().then(this.setReadyAgentData)
  }

  static async fetchReadyAgentData() {
    const { data } = await requester.get('/ready-agents/today')
    return data
  }

  @action
  setReadyAgentData({
    agents,
    nomenclatures,
    ready_orders,
    tara_quantity,
    boxes,
    gave_boxes,
  }) {
    this.agents.replace(agents)
    this.nomenclatures.replace(nomenclatures)
    this.history.replace(ready_orders)
    this.tara_quantity = tara_quantity
    this.boxes.replace(boxes)
    this.gave_boxes.replace(gave_boxes)
  }

  @computed
  get filtered_history() {
    return this.history.filter(this.filterHistory)
  }

  filterHistory(h) {
    return this.agent && h.branch_id === this.agent.branch_id
  }

  @action
  checkMatches() {
    if (this.checking_matches) {
      const char_str = this.chars.map(c => char_map[c]).join('')
      const chars = [...this.chars]
      this.chars.replace([])
      if (char_str === '*') {
        this.stage = 'tara'
        this.replaceTara2Chars()
      } else if (char_str === '00') {
        this.goBack()
      } else if (
        this.stage === 'agent' &&
        this.agents[parseInt(char_str) - 1]
      ) {
        this.agent = this.agents[parseInt(char_str) - 1]
        this.stage = 'nomenclature'
      } else if (
        this.stage === 'nomenclature' &&
        this.nomenclatures[parseInt(char_str) - 1]
      ) {
        this.nomenclature = this.nomenclatures[parseInt(char_str) - 1]
        this.stage = 'input'
      } else if (
        this.stage === 'input' &&
        chars.length > 0 &&
        chars.slice(-1)[0] === 13
      ) {
        if (this.is_weight_tara_input) {
          this.chars_weight_tara.replace([...chars.slice(0, -1)])
          this.chars.replace([...this.chars_weight_tara])
          this.sendReadyProduct()
        } else {
          this.chars_quantity.replace([...chars.slice(0, -1)])
          this.chars.replace([...this.chars_weight_tara])
          this.is_weight_tara_input = true
          this.is_weight_tara_input_first_focus =
            this.input_weight_tara === '0' || this.input_weight_tara === '-0'
        }
      } else if (
        this.stage === 'tara' &&
        chars.length > 0 &&
        chars.slice(-1)[0] === 13
      ) {
        this.chars.replace([...chars.slice(0, -1)])
        this.sendTara()
      }
      this.checking_matches = false
    }
    if (
      this.chars.length === 1 &&
      this.chars[0] === 42 &&
      this.stage === 'nomenclature'
    ) {
      this.checking_matches = true
      setTimeout(this.checkMatches, 0)
    } else if (
      this.chars.length === 2 &&
      ['agent', 'nomenclature'].includes(this.stage)
    ) {
      this.checking_matches = true
      setTimeout(this.checkMatches, 300)
    } else if (
      ['input', 'tara'].includes(this.stage) &&
      this.chars.length > 0 &&
      this.chars.slice(-1)[0] === 13
    ) {
      this.checking_matches = true
      setTimeout(this.checkMatches, 0)
    }
  }

  @action
  onKeyPress(e) {
    if (this.checking_matches) return
    if (e.charCode === 42) {
      if (this.stage !== 'nomenclature') return
      this.chars.replace([e.charCode])
    } else if (
      ['agent', 'nomenclature'].includes(this.stage) &&
      e.charCode !== 47
    ) {
      if (e.charCode < 48 || e.charCode > 57) return
      this.chars.push(e.charCode)
    } else if (!char_map[e.charCode]) {
      return
    } else if (e.charCode === 47) {
      if (this.stage !== 'input') {
        this.goBack()
      } else if (this.is_weight_tara_input) {
        this.chars_weight_tara.replace([...this.chars])
        this.chars.replace([...this.chars_quantity])
        this.is_weight_tara_input = false
      } else {
        this.chars_quantity.replace([])
        this.chars.replace([])
        this.goBack()
      }
    } else if ([44, 46].includes(e.charCode)) {
      if (
        this.chars.includes(44) ||
        this.chars.includes(46) ||
        this.stage === 'tara'
      )
        return
      if (this.chars.length === 0) this.chars.unshift(48)
      this.chars.push(46)
      if (this.is_weight_tara_input && !this.is_weight_tara_input_first_focus)
        this.is_weight_tara_input_first_focus = true
    } else if ([43, 45].includes(e.charCode)) {
      if (this.stage === 'tara' && e.charCode === 45) return
      if (this.chars.length === 0) this.chars.unshift(48)
      if (this.chars[0] >= 48) this.chars.unshift(e.charCode)
      else if (this.chars[0] !== e.charCode) this.chars[0] = e.charCode
      if (this.chars.length === 1) this.chars.push(48)
    } else if (this.chars.length === 1 && this.chars[0] === 48) {
      this.chars[0] = e.charCode
    } else if (
      this.chars.length === 2 &&
      [43, 45].includes(this.chars[0]) &&
      this.chars[1] === 48
    ) {
      this.chars[1] = e.charCode
    } else if (e.charCode === 13) {
      if (this.chars.length === 0) this.chars.push(48)
      this.chars.push(e.charCode)
    } else {
      if (this.is_weight_tara_input && !this.is_weight_tara_input_first_focus) {
        this.chars.replace([e.charCode])
        this.is_weight_tara_input_first_focus = true
      } else {
        this.chars.push(e.charCode)
      }
    }
    e.preventDefault && e.preventDefault()
    e.stopPropagation && e.stopPropagation()
    this.checkMatches()
  }

  @action
  onKeyUp(e) {
    if (e.ctrlKey || e.shiftKey || e.altKey) return

    if (this.chars.length > 0) {
      if (e.keyCode === 8) {
        e.preventDefault && e.preventDefault()
        e.stopPropagation && e.stopPropagation()
        this.chars.pop()
        if (this.stage !== 'input') return
        if (
          this.is_weight_tara_input &&
          !this.is_weight_tara_input_first_focus
        ) {
          this.chars.replace([])
          this.is_weight_tara_input_first_focus = true
        }
        if (this.chars.length === 0) this.chars.push(48)
        else if (this.chars.length === 1 && [43, 45].includes(this.chars[0]))
          this.chars[0] = 48
      } else if (e.keyCode === 27) {
        e.preventDefault && e.preventDefault()
        e.stopPropagation && e.stopPropagation()
        if (this.stage !== 'input') {
          this.chars.replace([])
        } else if (this.is_weight_tara_input) {
          this.chars_weight_tara.replace([...this.chars])
          this.chars.replace([...this.chars_quantity])
          this.is_weight_tara_input = false
        } else {
          this.chars_quantity.replace([])
          this.chars.replace([])
          this.goBack()
        }
      }
    } else if (this.stage === 'input' && e.keyCode === 27) {
      if (this.is_weight_tara_input) {
        this.chars_weight_tara.replace([...this.chars])
        this.chars.replace([...this.chars_quantity])
        this.is_weight_tara_input = false
      } else {
        this.chars_quantity.replace([])
        this.chars.replace([])
        this.goBack()
      }
    }
  }

  @action
  goToTara() {
    if (this.stage === 'nomenclature') {
      this.stage = 'tara'
      this.replaceTara2Chars()
    }
  }

  static str2chars(strings) {
    let str_chars = []
    for (const str of strings) {
      let tara_chars = []
      for (const n of str.split('')) {
        for (const k of Object.keys(char_map)) {
          if (char_map[k] === n) {
            tara_chars.push(parseInt(k))
            break
          }
        }
      }
      str_chars.push(tara_chars)
    }
    return str_chars
  }

  @action
  replaceTara2Chars() {
    let tara_str = '0'
    let tara_gave_str = '0'
    if (this.agent) {
      const { branch_id } = this.agent
      const id = `${branch_id}`
      tara_str = `${this.boxes.has(id) ? this.boxes.get(id) : 0}`
      tara_gave_str = `${this.gave_boxes.has(id) ? this.gave_boxes.get(id) : 0}`
    }
    const [tara_chars, tara_gave_chars] = ReadyAgentStoreOld.str2chars([
      tara_str,
      tara_gave_str,
    ])
    this.chars_tara.replace(tara_chars)
    this.chars_gave_tara.replace(tara_gave_chars)
    this.chars.replace(this.is_tara_gave_input ? tara_gave_chars : tara_chars)
  }

  @action
  goBack() {
    this.chars.replace([])
    if (this.stage === 'nomenclature') {
      this.stage = 'agent'
      this.agent = null
    } else if (this.stage === 'input') {
      this.stage = 'nomenclature'
      this.nomenclature = null
    } else if (this.stage === 'tara') {
      this.stage = 'nomenclature'
      this.nomenclature = null
    } else if (this.goBackListener) {
      return this.goBackListener()
    } else {
      return false
    }
    return true
  }

  @action
  onAgentSelect(index) {
    this.agent = this.agents[parseInt(index) - 1]
    this.stage = 'nomenclature'
  }

  @action
  onNomenclatureSelect(index) {
    this.nomenclature = this.nomenclatures[parseInt(index) - 1]
    this.stage = 'input'
  }

  @action
  switchToInputQuantity() {
    if (!this.is_weight_tara_input || this.stage !== 'input') return
    this.chars_weight_tara.replace([...this.chars])
    this.chars.replace([...this.chars_quantity])
    this.is_weight_tara_input = false
  }

  @action
  switchToInputTara() {
    if (this.is_weight_tara_input || this.stage !== 'input') return
    this.chars_quantity.replace([...this.chars])
    this.chars.replace([...this.chars_weight_tara])
    this.is_weight_tara_input = true
  }

  @action
  switchToInputTaraReceive() {
    if (!this.is_tara_gave_input || this.stage !== 'tara') return
    this.chars_gave_tara.replace([...this.chars])
    this.chars.replace([...this.chars_tara])
    this.is_tara_gave_input = false
  }

  @action
  switchToInputTaraGave() {
    if (this.is_tara_gave_input || this.stage !== 'tara') return
    this.chars_tara.replace([...this.chars])
    this.chars.replace([...this.chars_gave_tara])
    this.is_tara_gave_input = true
  }

  @computed
  get chars_str() {
    return [0, 1]
      .map(i => (this.chars.length > i ? char_map[this.chars[i]] : '_'))
      .join('')
  }

  input_num(chars) {
    const is_add = chars.length > 0 ? chars[0] === 43 : false
    return (
      chars
        .slice(is_add ? 1 : 0)
        .map(c => char_map[c])
        .join('') || '0'
    )
  }

  @computed
  get input_quantity() {
    return this.input_num(
      this.is_weight_tara_input ? this.chars_quantity : this.chars,
    )
  }

  @computed
  get input_weight_tara() {
    return this.input_num(
      this.is_weight_tara_input ? this.chars : this.chars_weight_tara,
    )
  }

  @computed
  get input_tara() {
    return this.input_num(
      this.is_tara_gave_input ? this.chars_tara : this.chars,
    )
  }

  @computed
  get input_gave_tara() {
    return this.input_num(
      this.is_tara_gave_input ? this.chars : this.chars_gave_tara,
    )
  }

  sending = []

  sendReadyProduct() {
    if (!this.agent || !this.nomenclature) return
    let quantity = isNaN(this.input_quantity)
      ? 0
      : parseFloat(this.input_quantity)
    quantity -= isNaN(this.input_weight_tara)
      ? 0
      : parseFloat(this.input_weight_tara)

    let nomenclature = this.nomenclatures.find(
      n => n.id === this.nomenclature.id,
    )
    if (nomenclature.quantity - quantity < 0) {
      AppStore.showError('В складе нет товара!')
      return
    }
    const key = `${this.agent.branch_id}:${this.nomenclature.id}:${quantity}`
    if (this.sending.indexOf(key) !== -1) return
    this.sending.push(key)
    requester
      .post('/ready-agents/add', {
        branch_id: this.agent.branch_id,
        nomenclature_id: this.nomenclature.id,
        quantity,
      })
      .then(this.onSentReadyProduct)
      .finally(() => {
        this.sending.splice(this.sending.indexOf(key), 1)
      })
  }

  @action
  onSentReadyProduct({ data }) {
    const { nomenclature_id, branch_id } = data.ready_order
    let quantity = 0
    let index = this.history.findIndex(
      h =>
        !h.received &&
        h.nomenclature_id === nomenclature_id &&
        h.branch_id === branch_id,
    )
    if (index !== -1) {
      let [h] = this.history.splice(index, 1)
      quantity = h.quantity
    }
    quantity -= data.ready_order.quantity
    let nomenclature = this.nomenclatures.find(n => n.id === nomenclature_id)
    if (nomenclature) nomenclature.quantity += quantity
    this.history.unshift(data.ready_order)
    this.chars_quantity.replace([])
    this.chars_weight_tara.replace([])
    this.chars.replace([])
    this.switchToInputQuantity()
  }

  deleting = []

  deleteReadyProduct(product) {
    if (this.deleting.indexOf(product.id) !== -1) return
    this.deleting.push(product.id)
    requester
      .delete('/ready-agents/' + product.id)
      .then(this.onDeleteReadyProduct)
      .finally(() => {
        this.deleting.splice(this.deleting.indexOf(product.id), 1)
      })
  }

  @action
  onDeleteReadyProduct({ data }) {
    const id = data.ready_order.id
    let index = this.history.findIndex(h => h.id === id)
    index !== -1 && this.history.splice(index, 1)
    const quantity = data.ready_order.quantity
    let nomenclature = this.nomenclatures.find(
      n => n.id === data.ready_order.nomenclature_id,
    )
    if (nomenclature) nomenclature.quantity += quantity
  }

  sendTara = () => {
    if (!this.agent) return
    const quantity = isNaN(this.input_tara) ? 0 : parseInt(this.input_tara)
    const gave_quantity = isNaN(this.input_gave_tara)
      ? 0
      : parseInt(this.input_gave_tara)

    if (this.tara_quantity - quantity < 0) {
      AppStore.showError('В складе нет тары!')
      return
    }

    requester
      .post('/ready-agents/tara', {
        branch_id: this.agent.branch_id,
        quantity,
        gave_quantity,
      })
      .then(this.onSentTara)
  }

  @action
  onSentTara({ data }) {
    AppStore.showSuccess('Тара сохранена!')
    this.is_tara_gave_input = !this.is_tara_gave_input
    const { quantity, gave_quantity, new_quantity, gave_new_quantity } = data
    this.tara_quantity -= quantity
    this.tara_quantity += gave_quantity
    const id = `${data.branch_id}`
    this.boxes.set(id, new_quantity)
    this.gave_boxes.set(id, gave_new_quantity)
    this.replaceTara2Chars()
  }

  @action
  clearStore() {
    this.today = moment()
    this.stage = 'agent'
    this.chars.replace([])
    this.agent = null
    this.nomenclature = null
  }
}

const store = new ReadyAgentStoreOld()
export default store
