import requester from '../../common/requester'

export default async function fetchProductionOf(product_id, store) {
  if (store.isProductionFetched(product_id))
    return {
      production: store.getProduction(product_id),
      products: store.getProductionProducts(product_id),
    }
  const {
    data: { production, products },
  } = await requester.get(`/trace/production/${product_id}`)
  return { production, products }
}
