import BaseStore from './BaseStore'
import MenuStore from './MenuStore'

class RoleStore extends BaseStore {
  setSingle(item) {
    MenuStore.menu.forEach(({ menus }) =>
      menus.forEach(m => {
        if (!item.data[m.controller]) {
          item.data[m.controller] = {}
        }
        ;['own', 'view', 'create', 'edit', 'delete'].forEach(a => {
          if (typeof item.data[m.controller][a] !== 'boolean') {
            item.data[m.controller][a] = false
          }
        })
      }),
    )
    super.setSingle(item)
  }
}

const store = new RoleStore()
export default store
