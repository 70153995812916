import { PropTypes as MobxTypes } from 'mobx-react'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { getModelValue, setModelValue } from '../../utils'

export default class BaseInput extends Component {
  static propTypes = {
    model: PropTypes.oneOfType([
      MobxTypes.objectOrObservableObject,
      MobxTypes.arrayOrObservableArray,
      MobxTypes.observableMap,
    ]),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  get value() {
    return this.getValue()
  }

  set value(value) {
    this.setValue(value)
  }

  getValue() {
    const { model, name } = this.props
    return getModelValue(model, name, null)
  }

  setValue(value) {
    const { model, name } = this.props
    setModelValue(model, name, value)
  }
}
