import { action, computed, makeObservable, observable } from 'mobx'
import requester from '../common/requester'
import BaseStore from './BaseStore'
import { SelectionStore } from './SelectionStore'

class CassetteHistoryStore {
  @observable page = 1
  @observable items = observable.array()
  @observable count = 0

  constructor() {
    makeObservable(this)
  }

  @computed get pages_count() {
    return Math.ceil(this.count / 10)
  }

  @action setPage = page => {
    this.page = page
  }

  fetchCassetteHistory = async identity => {
    const { data } = await requester.get(
      `/cassette/cassette-history/${identity}`,
      {
        page: this.page,
      },
    )
    this.setItems(data)
  }

  @action setItems = ({ list = [], count = 0 }) => {
    this.items.replace(list)
    this.count = count
    if (this.count > 0 && this.items.length === 0) this.setPage(1)
  }
}

class StorageHistoryStore {
  @observable page = 1
  @observable items = observable.array()
  @observable count = 0

  constructor() {
    makeObservable(this)
  }

  @computed get pages_count() {
    return Math.ceil(this.count / 10)
  }

  @action setPage = page => {
    this.page = page
  }

  fetchStorageHistory = async identity => {
    const { data } = await requester.get(
      `/cassette/storage-history/${identity}`,
      { page: this.page },
    )
    this.setItems(data)
  }

  @action setItems = ({ list = [], count = 0 }) => {
    this.items.replace(list)
    this.count = count
    if (this.count > 0 && this.items.length === 0) this.setPage(1)
  }
}

export class CassetteEditStore extends BaseStore {
  setSingle(item = {}) {
    super.setSingle({
      ...item,
      cassetteHistory: new CassetteHistoryStore(),
      storageHistory: new StorageHistoryStore(),
    })
  }
}

export class CassetteListStore extends SelectionStore {
  @observable search_identity = ''

  constructor() {
    super()
    this.add_filter_keys(
      'identity',
      'terminal_identity',
      'model',
      'terminal_number',
      'serial_number',
      'contractor_id',
    )
  }

  @computed get can_edit() {
    return this.access.includes('edit')
  }

  selectionsToBranch = async branch => {
    await requester.post('/cassette/to-branch', {
      branch_id: branch.id,
      items: this.selections,
    })
    this.clearSelectionsAll()
    await this.fetchMany()
  }

  selectionToTerminal = async terminal_identity => {
    if (this.has_single_selection) {
      await requester.post('/cassette/to-terminal', {
        item: this.selections[0],
        terminal_identity,
      })
    }
    this.clearSelectionsAll()
    await this.fetchMany()
  }

  selectionsClearTerminal = async () => {
    await requester.post('/cassette/clear-terminal', { items: this.selections })
    this.clearSelectionsAll()
    await this.fetchMany()
  }
}

class CassetteStore {}

export default new CassetteStore()
