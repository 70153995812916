import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const client = Bugsnag.start({
  apiKey: '9f4c203130e08966894e3672e7be1c92',
  autoDetectErrors: process.env.NODE_ENV !== 'development',
  plugins: [new BugsnagPluginReact()],
})

export default client
