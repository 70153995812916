import { initializeApp } from '@firebase/app'
import { getMessaging, getToken, onMessage } from '@firebase/messaging'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'
import { getStorage } from '../../store/Storage'
import DataForm from '../pages/DataForm'
import DataTable from '../pages/DataTable'
import Home from '../pages/Home'
import Me from '../pages/Me'
import DynamicImport from './DynamicImport'
import ErrorBoundary from './ErrorBoundary'

const firebaseConfig = {
  apiKey: 'AIzaSyBWU8JBnacGCKRzKxcNtxkYpxE5hh_EUn4',
  authDomain: 'apex-erp.firebaseapp.com',
  databaseURL: 'https://apex-erp.firebaseio.com',
  projectId: 'apex-erp',
  storageBucket: 'apex-erp.appspot.com',
  messagingSenderId: '93524991281',
  appId: '1:93524991281:web:baf3ee5532554250ef6410',
  measurementId: 'G-WDRS7DP1NW',
}

const vapidKey =
  'BAc8JXZZYa3ScoXe1jax18dIhP_om_eX8K9VVbT_Ai-y2n50Aoc-44FwfND0cPXe3ahzEAp7KMC8DWsWV4UfZQA'

@inject('store')
@observer
export default class Main extends React.Component {
  unsubscribePushMessaging

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test') {
      this.sendApnToken()
    }
  }

  componentWillUnmount() {
    if (this.unsubscribePushMessaging) this.unsubscribePushMessaging()
  }

  mapMenus = menu => ({
    ...menu,
    renderProps: {
      ...menu.renderProps,
      fallback: menu.path.endsWith('/:id') ? DataForm : DataTable,
    },
  })

  get menus() {
    return this.props.store.MenuStore.routes.map(this.mapMenus)
  }

  renderRoute = ({ renderProps, ...rest }) => (
    <Route
      {...rest}
      render={props => <DynamicImport {...props} {...renderProps} />}
    />
  )

  render() {
    return (
      <main className='main'>
        <div className='app-page'>
          <Container fluid>
            <ErrorBoundary>
              <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/logout' component={Logout} />
                <Route exact path='/me' component={Me} />
                {this.menus.map(this.renderRoute)}
                {this.props.store.MenuStore.ready && (
                  <Route render={() => <Redirect to='' />} />
                )}
              </Switch>
            </ErrorBoundary>
          </Container>
        </div>
      </main>
    )
  }

  sendApnToken = async () => {
    const app = this.props.store.AppStore
    if (!this.messaging) {
      const firebase_init_app = initializeApp(firebaseConfig)
      this.messaging = getMessaging(firebase_init_app)
      try {
        if ((await Notification.requestPermission()) === 'granted') {
          this.unsubscribePushMessaging = onMessage(this.messaging, payload => {
            const custom_notification = payload.data?.custom_notification || ''

            let notification = payload.notification
            if (custom_notification) {
              notification = JSON.parse(custom_notification)
            }
            const uri = new URL(notification.click_action)
            app.showInfo(notification.body, uri.pathname)
          })
        }
      } catch (e) {
        console.log('Unable to get permission to notify.', e)
      }
    }
    try {
      const token = await getToken(this.messaging, { vapidKey })
      if (token) {
        await getStorage().save({ key: 'apntoken', data: token })
        await app.saveApnToken(token, 'web')
      }
    } catch (err) {
      console.warn('An error occurred while retrieving token. ', err)
    }
  }
}

@withTranslation()
@inject('store')
class Logout extends React.Component {
  componentDidMount() {
    this.props.store.AppStore.logOut().then(() => {
      if (this.props.history.length > 1) {
        this.props.history.goBack()
      } else {
        this.props.history.replace('/')
      }
    })
  }

  render() {
    return <div>Logout...</div>
  }
}
