import { action, computed, makeObservable, observable } from 'mobx'
import { persist } from 'mobx-persist'

class Screen {
  @persist('map') @observable settings = observable.map({})
}

class ScreenStore {
  @observable width = 0
  @observable height = 0
  @persist('map', Screen) @observable screens = observable.map({})

  constructor() {
    makeObservable(this)
  }

  @action.bound setScreen(width, height) {
    this.width = width
    this.height = height
  }

  @action.bound setScreenDimensions({ width, height }) {
    this.width = width
    this.height = height
  }

  @action.bound setWidth(width) {
    this.width = width
  }

  @action.bound setHeight(height) {
    this.height = height
  }

  @computed get isPortrait() {
    return this.width <= this.height
  }

  @computed get isLandscape() {
    return this.width > this.height
  }

  @computed get is_wide() {
    const size = this.isPortrait ? this.width : this.height
    return size >= 800
  }

  @computed get max() {
    return this.height > this.width ? this.height : this.width
  }

  @computed get min() {
    return this.height > this.width ? this.width : this.height
  }

  @action.bound setProperty(screen, name, value) {
    if (!this.screens.has(screen)) {
      this.screens.set(screen, new Screen())
    }
    this.screens.get(screen).settings.set(name, value)
  }

  getProperty(screen, name) {
    if (this.screens.has(screen))
      return this.screens.get(screen).settings.get(name)
  }

  grid_breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  }

  @computed get is_xs() {
    return this.width >= this.grid_breakpoints.xs
  }

  @computed get is_sm() {
    return this.width >= this.grid_breakpoints.sm
  }

  @computed get is_md() {
    return this.width >= this.grid_breakpoints.md
  }

  @computed get is_lg() {
    return this.width >= this.grid_breakpoints.lg
  }

  @computed get is_xl() {
    return this.width >= this.grid_breakpoints.xl
  }
}

const store = new ScreenStore()
export default store
