import { makeObservable, observable } from 'mobx'
import BaseStore from './BaseStore'

export class TrackerStore extends BaseStore {
  @observable sales = observable.array()
  @observable routes = observable.array()

  constructor() {
    super()
    makeObservable(this)
  }

  setResult(data, defaultData) {
    if (!data) return
    if (data.structure)
      this.setStructure(data.structure, data.item, defaultData)
    if (data.access) this.setAccess(data.access)
    if (data.sales) {
      this.sales = data.sales
    }
    if (data.routes) {
      this.routes = data.routes
    }
  }
}

const store = new TrackerStore()
export default store
