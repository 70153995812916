export const TARA_TYPE_BOX = 'box'
export const TARA_TYPE_TRUCK = 'truck'

export const TARA_TYPE = {
  [TARA_TYPE_BOX]: 'Ящик',
  [TARA_TYPE_TRUCK]: 'Тележка',
}

export const TARA_TYPES = Object.keys(TARA_TYPE)

export const TARA_TYPE_OPTIONS = Object.entries(TARA_TYPE).map(
  ([value, text]) => ({ value, text }),
)

export function validateDetails({
  tara_type = TARA_TYPE_BOX,
  value = null,
  single_product = false,
  max_quantity = null,
  ...details
}) {
  if (tara_type === TARA_TYPE_TRUCK) {
    let { sticks_count = null, sticks_weight = null, ...rest } = details
    details = { ...rest, sticks_count, sticks_weight }
  }
  return {
    tara_type,
    value,
    single_product,
    max_quantity,
    ...details,
  }
}

export function validateItem({
  data: { buffer, tara_details, ...data },
  ...item
}) {
  return {
    ...item,
    data: {
      ...data,
      buffer: buffer || [],
      tara_details: validateDetails(tara_details || {}),
    },
  }
}

export default {
  validateItem,
  validateDetails,
}
