import { inject, observer } from 'mobx-react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Card, CardBody, CardFooter, CardHeader, Form } from 'reactstrap'
import { AutoButton, FormInput, FormLabel } from '../ui/FormInput'

@withTranslation('me')
@inject('store')
@observer
export default class Me extends React.Component {
  constructor(props) {
    super(props)
    this.store = this.props.store.AppStore
    this.store.extendUserInfo()
  }

  get branches() {
    const ui = this.store.user_info
    return ui ? [ui.branch, ...(ui.branches || [])] : []
  }

  get is_valid_pass() {
    const ui = this.store.user_info
    if (!ui) return undefined
    const { new_password, confirm_password } = ui
    return new_password &&
      new_password.length > 3 &&
      new_password === confirm_password
      ? true
      : undefined
  }

  render() {
    const ui = this.store.user_info
    if (!ui) return null
    return (
      <div className='me-page'>
        <Card>
          <CardHeader>
            <b>{this.props.t('profile')}</b>
          </CardHeader>
          <CardBody>
            <h1>{ui.username}</h1>
            <h3 className='text-muted'>
              {this.branches.map(Me.mapName).join(', ')}
            </h3>
            <hr />
            <p>
              <strong>{this.props.t('structure.roles')}:</strong>{' '}
              {ui.roles.map(Me.mapName).join(', ')}
            </p>
            <Form horizontal='true' onSubmit={e => e.preventDefault()}>
              <FormLabel label={this.props.t('structure.data.fio')}>
                <FormInput model={ui.data} name='fio' />
              </FormLabel>
              <hr />
              <FormLabel label={this.props.t('structure.username')}>
                <FormInput
                  model={ui}
                  name='username'
                  type='username'
                  autoComplete='username'
                  readOnly
                  disabled
                />
              </FormLabel>
              <FormLabel label={this.props.t('structure.password')}>
                <FormInput
                  model={ui}
                  name='password'
                  type='password'
                  autoComplete='current-password'
                />
              </FormLabel>
              <FormLabel label={this.props.t('structure.new_password')}>
                <FormInput
                  model={ui}
                  name='new_password'
                  type='password'
                  autoComplete='new-password'
                  valid={this.is_valid_pass}
                />
              </FormLabel>
              <FormLabel label={this.props.t('structure.confirm_password')}>
                <FormInput
                  model={ui}
                  name='confirm_password'
                  type='password'
                  autoComplete='new-password'
                  valid={this.is_valid_pass}
                />
              </FormLabel>
            </Form>
          </CardBody>
          <CardFooter>
            <AutoButton
              onClick={this.store.saveProfileChanges}
              color={'primary'}
            >
              {this.props.t('save')}
            </AutoButton>
          </CardFooter>
        </Card>
      </div>
    )
  }

  static mapName(item) {
    return item.name
  }
}
