import { action, computed, makeObservable } from 'mobx'
import xlsx from 'xlsx'
import { autoFitColumns } from '../common/utils'
import BaseStore from './BaseStore'

class KpiReportStore extends BaseStore {
  get should_save_filter_keys() {
    return [...super.should_save_filter_keys, 'branches_id']
  }

  constructor() {
    super()
    makeObservable(this)
  }

  @action addFilterBranch = branch => {
    const f_b = this.filters.get('branches_id') || ''
    let branches_id = []
    if (f_b) branches_id = f_b.split(',').map(i => parseInt(i))
    !branches_id.includes(branch.id) && branches_id.push(branch.id)
    this.filters.set('branches_id', branches_id.join(','))
  }

  @action filtersRemoveBranch = branch_index => {
    const branches_id = (this.filters.get('branches_id') || '').split(',')
    branches_id.splice(branch_index, 1)
    this.filters.set('branches_id', branches_id.join(','))
  }

  reduceUnit = (total, v) => {
    const i = total.findIndex(t => t.unit_id === v.unit_id)
    if (i === -1) return [...total, { ...v }]
    total[i].quantity = parseFloat(total[i].quantity) + parseFloat(v.quantity)
    return total
  }

  reduceUnits = prop => (total, item) =>
    item[prop] ? item[prop].reduce(this.reduceUnit, total) : total

  @computed get total_inputs() {
    return this.items.reduce(this.reduceUnits('inputs'), [])
  }

  @computed get total_outputs() {
    return this.items.reduce(this.reduceUnits('outputs'), [])
  }

  async fetchItem(pathname) {
    return await this.fetchData({ url: pathname })
  }

  @action reorderBranch = e => {
    const { source, destination } = e
    if (!destination) return this.filtersRemoveBranch(source.index)

    if (source.index === destination.index) return

    const branches_id = this.filters.get('branches_id').split(',')
    const [branch_id] = branches_id.splice(source.index, 1)
    branches_id.splice(destination.index, 0, branch_id)

    this.filters.set('branches_id', branches_id.join(','))
  }

  exportExcel = () => {
    const items = {}
    const headers = {
      branch: { id: 'Отдел', name: '' },
      name: { id: 0, name: 'Наименование' },
    }
    this.items.forEach(item => {
      const input_key = `quantity_input_${item.id}`
      headers.branch[input_key] = item.name
      headers.name[input_key] = `Сырье`

      item.inputs.forEach(i => {
        if (items[i.id]) {
          items[i.id][input_key] = i.quantity
        } else {
          items[i.id] = {
            id: i.id,
            name: i.name,
            [input_key]: i.quantity,
          }
        }
      })

      const output_key = `quantity_output_${item.id}`
      headers.branch[output_key] = ''
      headers.name[output_key] = `Продукция`

      item.outputs.forEach(o => {
        if (items[o.id]) {
          items[o.id][output_key] = o.quantity
        } else {
          items[o.id] = {
            id: o.id,
            name: o.name,
            [output_key]: o.quantity,
          }
        }
      })
    })

    const arr_json = Object.values(headers).concat(Object.values(items))
    const worksheet = xlsx.utils.json_to_sheet(arr_json, {
      skipHeader: true,
    })
    autoFitColumns(worksheet)
    const book = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(book, worksheet)

    xlsx.writeFile(book, `branch-kpi-${new Date().toISOString()}.xlsx`, {
      bookType: 'xlsx',
    })
  }
}

export default new KpiReportStore()
