import { action, computed, observable, makeObservable } from 'mobx'
import requester from '../../common/requester'
import fetchDeepBaseProducts from './fetchDeepBaseProducts'
import fetchDeepDescendantProducts from './fetchDeepDescendantProducts'
import fetchProductionOf from './fetchProductionOf'
import fetchBaseProducts from './fetchBaseProducts'
import fetchDescendantProducts, {
  fetchDescendantProductProductions,
} from './fetchDescendantProducts'
import {
  BACKWARD,
  DEEP_BACKWARD,
  DEEP_FORWARD,
  FORWARD,
  GET,
  MULTIPLE_DEEP_FORWARD,
  PRODUCTION,
  SEARCH,
  IN_STOCK_AND_TARA,
} from './path-constants'
import groupProductsIdByNomenclature from './groupProductsIdByNomenclature'
import findDeepProducts from './findDeepProducts'
import fetchMultipleRelations from './fetchMultipleRelations'
import fetchWithQueue from './fetchWithQueue'
import computeActualQuantity from './computeActualQuantity'
import isDeepProductsFetched from './isDeepProductsFetched'
import getDeepProducts from './getDeepProducts'
import allProductNomenclatures, {
  allMultipleProductNomenclatures,
} from './allProductNomenclatures'

const arrayAsObject = (items, key) =>
  items.reduce((object, item) => ({ ...object, [item[key]]: item }), {})

const flattenDeepProducts = deep_products => {
  return Object.values(deep_products).reduce(
    (o, products) =>
      products.reduce(
        (o2, product) => ({
          ...o2,
          [product.id]: product,
        }),
        o,
      ),
    {},
  )
}

const mapDeepProducts = deep_products =>
  Object.keys(deep_products).reduce(
    (o, product_id) => ({
      ...o,
      [product_id]: deep_products[product_id].map(product => product.id),
    }),
    {},
  )

async function fetch(path, param) {
  const { data } = await requester.get(`/trace/${path}/${param}`)
  return data
}

function noop() {}

class TraceProductionStore {
  @observable search = ''

  @observable history = observable.array()

  @observable _products = observable.map()
  @observable productions = observable.map()
  @observable base_products = observable.map()
  @observable descendant_products = observable.map()

  @observable fetch_queue = observable.map()
  @observable fetch_error_messages = observable.map()

  @observable excluded_base_products = observable.map()
  @observable selected_base_products = observable.map()

  @observable excluded_descendant_products = observable.map()
  @observable selected_descendant_products = observable.map()

  @observable multiple_relations = observable.array()

  @observable in_stock_and_tara = observable.map()

  @computed get products() {
    return [...this._products.values()].filter(Boolean)
  }

  @computed get selected_product() {
    return this.history.length > 0
      ? this.getProduct(this.history[this.history.length - 1])
      : null
  }

  @computed get selected_products() {
    return this.history.map(this.getProduct).filter(Boolean).reverse()
  }

  constructor() {
    makeObservable(this)
    ;[
      'isFetching',
      'getErrorMessage',
      '_fetchWithQueue',
      'disposeSearch',
      'getProduct',
      'fetchProducts',
      'isProductFetching',
      'getProductErrorMessage',
      'isProductFetched',
      'fetchProduct',
      'isProductionFetching',
      'getProductionErrorMessage',
      'isProductionFetched',
      'getProduction',
      'getProductionProducts',
      'fetchProduction',
      'computeActualQuantity',
      'isBaseProductsFetching',
      'getBaseProductsErrorMessage',
      'isBaseProductsFetched',
      'getBaseProducts',
      'fetchBaseProducts',
      'isDescendantProductsFetching',
      'getDescendantProductsErrorMessage',
      'isDescendantProductsFetched',
      'getDescendantProducts',
      'getDescendantProductProductions',
      'fetchDescendantProducts',
      'isDeepBaseProductsFetching',
      'getDeepBaseProductsErrorMessage',
      'isDeepBaseProductsFetched',
      'getDeepBaseProducts',
      'fetchDeepBaseProducts',
      'isDeepDescendantProductsFetching',
      'getDeepDescendantProductsErrorMessage',
      'isDeepDescendantProductsFetched',
      'getDeepDescendantProducts',
      'fetchDeepDescendantProducts',
      'hasSelectedBaseProducts',
      'getSelectedBaseProducts',
      'hasSelectedDescendantProducts',
      'getSelectedDescendantProducts',
      'allBaseProductNomenclatures',
      'hasExcludedBaseProducts',
      'getExcludedBaseProducts',
      'allDescendantProductNomenclatures',
      'hasExcludedDescendantProducts',
      'getExcludedDescendantProducts',
      'isMultipleRelationsFetching',
      'isMultipleRelationsFetched',
      'getMultipleRelationsErrorMessage',
      'getDeepRelations',
      'fetchMultipleRelations',
      'getSelectedMultipleRelations',
      'getExcludedMultipleRelations',
      'getMultipleRelationsAllProductNomenclatures',
      'isInStockAndTaraFetching',
      'isInStockAndTaraFetched',
      'getInStockAndTaraErrorMessage',
      'getInStockAndTara',
      'fetchInStockAndTara',
    ].forEach(method => (this[method] = this[method].bind(this)))
  }

  isFetching(path, param) {
    if (!this.fetch_queue.has(path)) return false
    const queue = this.fetch_queue.get(path)
    const key = `${param}`
    return queue.has(key) ? queue.get(key) >= 0 : false
  }

  @action.bound pushFetchQueue(path, param) {
    if (!this.fetch_queue.has(path))
      this.fetch_queue.set(path, observable.map())
    const queue = this.fetch_queue.get(path)
    const key = `${param}`
    queue.set(key, queue.has(key) ? queue.get(key) + 1 : 0)
    return queue.get(key)
  }

  @action.bound removeFetchQueue(path, param) {
    if (!this.isFetching(path, param)) return false
    const queue = this.fetch_queue.get(path)
    const key = `${param}`
    queue.set(key, queue.has(key) ? queue.get(key) - 1 : -1)
    return true
  }

  getErrorMessage(path, param) {
    if (!this.fetch_error_messages.has(path)) return ''
    const messages = this.fetch_error_messages.get(path)
    const key = `${param}`
    return messages.has(key) ? messages.get(key) : ''
  }

  @action.bound setFetchErrorMessage(path, param, message) {
    if (!this.fetch_error_messages.has(path))
      this.fetch_error_messages.set(path, observable.map())
    return this.fetch_error_messages.get(path).set(`${param}`, message)
  }

  @action.bound deleteFetchErrorMessage(path, param) {
    return this.getErrorMessage(path, param).length > 0
      ? this.fetch_error_messages.get(path).delete(`${param}`)
      : false
  }

  _fetchWithQueue(path, param, fetcher = fetch) {
    return fetchWithQueue(path, param, fetcher, this)
  }

  searchDisposer

  disposeSearch() {
    if (this.searchDisposer) {
      const ret = this.searchDisposer()
      this.searchDisposer = undefined
      return ret
    }
    return false
  }

  @action.bound clear() {
    this.disposeSearch()
    this.multiple_relations.clear()
    this.selected_descendant_products.clear()
    this.excluded_descendant_products.clear()
    this.selected_base_products.clear()
    this.excluded_base_products.clear()
    this.fetch_queue.clear()
    this.fetch_error_messages.clear()
    this.history.clear()
    this.descendant_products.clear()
    this.base_products.clear()
    this.productions.clear()
    this._products.clear()
  }

  getProduct(product_id) {
    const key = `${product_id}`
    return this._products.has(key) ? this._products.get(key) : null
  }

  @action.bound showProduct(product) {
    const i = this.history.indexOf(product.id)
    if (i === -1) {
      this.history.push(product.id)
    } else if (this.history.length - 1 > i) {
      this.history.replace([
        ...this.history.slice(0, i),
        ...this.history.slice(i + 1),
        product.id,
      ])
    }
  }

  @action.bound replaceProducts(products) {
    this.clear()
    this._products.replace(arrayAsObject(products, 'id'))
    this.products.length === 1 && this.showProduct(this.products[0])
  }

  fetchProducts() {
    if (!this.search) return this.disposeSearch
    this.disposeSearch()
    const [disposer, promise] = this._fetchWithQueue(SEARCH, this.search)
    this.searchDisposer = disposer
    promise.then(({ products }) => this.replaceProducts(products)).catch(noop)
    return this.disposeSearch
  }

  @action.bound setProduct(product_id, product) {
    this.clear()
    this._products.replace({ [`${product_id}`]: product })
    this.products.length === 1 && this.showProduct(this.products[0])
  }

  isProductFetching(product_id) {
    return this.isFetching(GET, product_id)
  }

  getProductErrorMessage(product_id) {
    return this.getErrorMessage(GET, product_id)
  }

  isProductFetched(product_id) {
    return this._products.has(`${product_id}`)
  }

  fetchProduct(product_id) {
    this.disposeSearch()
    const [disposer, promise] = this._fetchWithQueue(GET, product_id)
    this.searchDisposer = disposer
    promise
      .then(({ product }) => this.setProduct(product_id, product))
      .catch(noop)
    return this.disposeSearch
  }

  isProductionFetching(product_id) {
    return this.isFetching(PRODUCTION, product_id)
  }

  getProductionErrorMessage(product_id) {
    return this.getErrorMessage(PRODUCTION, product_id)
  }

  isProductionFetched(product_id) {
    return this.productions.has(`${product_id}`)
  }

  getProduction(product_id) {
    return this.isProductionFetched(product_id)
      ? this.productions.get(`${product_id}`)
      : null
  }

  getProductionProducts(product_id) {
    const production = this.getProduction(product_id)
    if (!production) return []
    const { process_output } = production
    const { materials } = process_output
    return Array.from(new Set(materials.map(material => material.pid)))
      .map(product_id => this.getProduct(product_id))
      .filter(Boolean)
  }

  @action.bound addProduction(products, production) {
    this._products.merge(arrayAsObject(products, 'id'))
    this.productions.merge(
      products.reduce(
        (map, product) => ({
          ...map,
          [product.id]: production,
        }),
        {},
      ),
    )
  }

  fetchProduction(product_id) {
    const [disposer, promise] = this._fetchWithQueue(
      PRODUCTION,
      product_id,
      () => fetchProductionOf(product_id, this),
    )
    promise
      .then(({ products, production }) => {
        if (production) {
          this.addProduction(products, production)
        } else {
          const product = this.getProduct(product_id)
          product && this.addProduction([product], production)
        }
      })
      .catch(noop)
    return disposer
  }

  computeActualQuantity(product_id, base_product_id) {
    return computeActualQuantity(product_id, base_product_id, store)
  }

  isBaseProductsFetching(product_id) {
    return this.isFetching(FORWARD, product_id)
  }

  getBaseProductsErrorMessage(product_id) {
    return this.getErrorMessage(FORWARD, product_id)
  }

  isBaseProductsFetched(product_id) {
    return this.base_products.has(`${product_id}`)
  }

  getBaseProducts(product_id) {
    return this.isBaseProductsFetched(product_id)
      ? this.base_products
          .get(`${product_id}`)
          .map(this.getProduct)
          .filter(Boolean)
      : []
  }

  @action.bound addBaseProducts(products, product_id) {
    this._products.merge(arrayAsObject(products, 'id'))
    this.base_products.set(
      `${product_id}`,
      products.map(product => product.id),
    )
  }

  fetchBaseProducts(product_id) {
    const [disposer, promise] = this._fetchWithQueue(FORWARD, product_id, () =>
      fetchBaseProducts(product_id, this),
    )
    promise
      .then(products => this.addBaseProducts(products, product_id))
      .catch(noop)
    return disposer
  }

  isDescendantProductsFetching(product_id) {
    return this.isFetching(BACKWARD, product_id)
  }

  getDescendantProductsErrorMessage(product_id) {
    return this.getErrorMessage(BACKWARD, product_id)
  }

  isDescendantProductsFetched(product_id) {
    const key = `${product_id}`
    return this.descendant_products.has(key)
      ? this.descendant_products.get(key).every(this.isProductionFetched)
      : false
  }

  getDescendantProducts(product_id) {
    return this.isDescendantProductsFetched(product_id)
      ? this.descendant_products
          .get(`${product_id}`)
          .map(this.getProduct)
          .filter(Boolean)
      : []
  }

  getDescendantProductProductions(product_id) {
    const products = this.getDescendantProducts(product_id)
    return Object.values(
      products.reduce((productions, product) => {
        const production = this.getProduction(product.id)
        if (production) {
          const { id, stage_index, process_index } = production
          const key = `${id}-${stage_index}-${process_index}`
          if (!productions[key]) productions[key] = { production, products: [] }
          productions[key].products.push(product)
        }
        return productions
      }, {}),
    )
  }

  @action.bound addDescendantProducts(products, product_id, productions) {
    this._products.merge(arrayAsObject(products, 'id'))
    this.productions.merge(productions)
    this.descendant_products.set(
      `${product_id}`,
      products.map(product => product.id),
    )
  }

  fetchDescendantProducts(product_id) {
    const [disposer, promise] = this._fetchWithQueue(BACKWARD, product_id, () =>
      fetchDescendantProductProductions(product_id, this),
    )
    promise
      .then(({ products, productions }) =>
        this.addDescendantProducts(products, product_id, productions),
      )
      .catch(noop)
    return disposer
  }

  isDeepBaseProductsFetching(product_id) {
    return this.isFetching(DEEP_FORWARD, product_id)
  }

  getDeepBaseProductsErrorMessage(product_id) {
    return this.getErrorMessage(DEEP_FORWARD, product_id)
  }

  isDeepBaseProductsFetched(product_id) {
    return isDeepProductsFetched(product_id, this.base_products)
  }

  getDeepBaseProducts(product_id) {
    if (!this.isDeepBaseProductsFetched(product_id)) return []
    const key = `${product_id}`
    const excluded = this.excluded_base_products.has(key)
      ? this.excluded_base_products.get(key)
      : null
    return getDeepProducts(
      product_id,
      this.base_products,
      excluded,
      this.getProduct,
    )
  }

  @action.bound addDeepBaseProducts(deep_base_products) {
    this._products.merge(flattenDeepProducts(deep_base_products))
    this.base_products.merge(mapDeepProducts(deep_base_products))
  }

  fetchDeepBaseProducts(product_id) {
    const [disposer, promise] = this._fetchWithQueue(
      DEEP_FORWARD,
      product_id,
      () => fetchDeepBaseProducts(product_id, this),
    )
    promise.then(this.addDeepBaseProducts).catch(noop)
    return disposer
  }

  isDeepDescendantProductsFetching(product_id) {
    return this.isFetching(DEEP_BACKWARD, product_id)
  }

  getDeepDescendantProductsErrorMessage(product_id) {
    return this.getErrorMessage(DEEP_BACKWARD, product_id)
  }

  isDeepDescendantProductsFetched(product_id) {
    return isDeepProductsFetched(product_id, this.descendant_products)
  }

  getDeepDescendantProducts(product_id) {
    if (!this.isDeepDescendantProductsFetched(product_id)) return []
    const key = `${product_id}`
    const excluded = this.excluded_descendant_products.has(key)
      ? this.excluded_descendant_products.get(key)
      : null
    return getDeepProducts(
      product_id,
      this.descendant_products,
      excluded,
      this.getProduct,
    )
  }

  @action.bound addDeepDescendantProducts(deep_descendant_products) {
    this._products.merge(flattenDeepProducts(deep_descendant_products))
    this.descendant_products.merge(mapDeepProducts(deep_descendant_products))
  }

  fetchDeepDescendantProducts(product_id) {
    const [disposer, promise] = this._fetchWithQueue(
      DEEP_BACKWARD,
      product_id,
      () => fetchDeepDescendantProducts(product_id, this),
    )
    promise.then(this.addDeepDescendantProducts).catch(noop)
    return disposer
  }

  hasSelectedBaseProducts(product_id) {
    return this.selected_base_products.has(`${product_id}`)
  }

  getSelectedBaseProducts(product_id) {
    return this.hasSelectedBaseProducts(product_id)
      ? groupProductsIdByNomenclature(
          this.selected_base_products.get(`${product_id}`),
          this,
        )
      : {}
  }

  @action.bound setSelectedBaseProducts(product_id, selections) {
    const key = `${product_id}`
    const values = Object.values(selections).flat()
    if (values.length > 0) this.selected_base_products.set(key, new Set(values))
    else if (this.selected_base_products.has(key))
      this.selected_base_products.delete(key)
  }

  hasSelectedDescendantProducts(product_id) {
    return this.selected_descendant_products.has(`${product_id}`)
  }

  getSelectedDescendantProducts(product_id) {
    return this.hasSelectedDescendantProducts(product_id)
      ? groupProductsIdByNomenclature(
          this.selected_descendant_products.get(`${product_id}`),
          this,
        )
      : {}
  }

  @action.bound setSelectedDescendantProducts(product_id, selections) {
    const key = `${product_id}`
    const values = Object.values(selections).flat()
    if (values.length > 0)
      this.selected_descendant_products.set(key, new Set(values))
    else if (this.selected_descendant_products.has(key))
      this.selected_descendant_products.delete(key)
  }

  allBaseProductNomenclatures(product_id) {
    return allProductNomenclatures(product_id, this.base_products, this)
  }

  hasExcludedBaseProducts(product_id) {
    return this.excluded_base_products.has(`${product_id}`)
  }

  getExcludedBaseProducts(product_id) {
    return this.hasExcludedBaseProducts(product_id)
      ? groupProductsIdByNomenclature(
          this.excluded_base_products.get(`${product_id}`),
          this,
        )
      : {}
  }

  @action.bound setExcludedBaseProducts(product_id, excluded) {
    this.setSelectedBaseProducts(product_id, [])
    const key = `${product_id}`
    const values = Object.values(excluded).flat()
    if (values.length > 0) this.excluded_base_products.set(key, new Set(values))
    else if (this.excluded_base_products.has(key))
      this.excluded_base_products.delete(key)
  }

  allDescendantProductNomenclatures(product_id) {
    return allProductNomenclatures(product_id, this.descendant_products, this)
  }

  hasExcludedDescendantProducts(product_id) {
    return this.excluded_descendant_products.has(`${product_id}`)
  }

  getExcludedDescendantProducts(product_id) {
    return this.hasExcludedDescendantProducts(product_id)
      ? groupProductsIdByNomenclature(
          this.excluded_descendant_products.get(`${product_id}`),
          this,
        )
      : {}
  }

  @action.bound setExcludedDescendantProducts(product_id, excluded) {
    this.setSelectedDescendantProducts(product_id, [])
    const key = `${product_id}`
    const values = Object.values(excluded).flat()
    if (values.length > 0)
      this.excluded_descendant_products.set(key, new Set(values))
    else if (this.excluded_descendant_products.has(key))
      this.excluded_descendant_products.delete(key)
  }

  isMultipleRelationsFetching(id, type) {
    return this.isFetching(type, id)
  }

  isMultipleRelationsFetched(id, type) {
    const { products_id } = this.multiple_relations.find(i => i.id === id)
    const isDeepFetched =
      type === MULTIPLE_DEEP_FORWARD
        ? this.isDeepBaseProductsFetched
        : this.isDeepDescendantProductsFetched
    return products_id.every(product_id => isDeepFetched(product_id))
  }

  getMultipleRelationsErrorMessage(id, type) {
    return this.getErrorMessage(type, id)
  }

  @action.bound addMultipleRelations(products_id, type) {
    const id = Math.min(0, ...this.multiple_relations.map(i => i.id)) - 1
    this.multiple_relations.push({
      id,
      type,
      products_id,
      excluded: observable.array(),
      selections: observable.array(),
    })
  }

  @action.bound removeMultipleRelations(id) {
    this.multiple_relations.replace(
      this.multiple_relations.filter(i => i.id !== id),
    )
  }

  getDeepRelations(products_id, excluded, type) {
    const isDeepFetched =
      type === MULTIPLE_DEEP_FORWARD
        ? this.isDeepBaseProductsFetched
        : this.isDeepDescendantProductsFetched
    const rel_map =
      type === MULTIPLE_DEEP_FORWARD
        ? this.base_products
        : this.descendant_products
    return findDeepProducts(
      products_id,
      excluded,
      rel_map,
      isDeepFetched,
      this.getProduct,
    )
  }

  fetchMultipleRelations(id, type, products_id) {
    const fetcher =
      type === MULTIPLE_DEEP_FORWARD
        ? () =>
            fetchMultipleRelations(
              id,
              type,
              products_id,
              fetchBaseProducts,
              this,
            )
        : () =>
            fetchMultipleRelations(
              id,
              type,
              products_id,
              fetchDescendantProducts,
              this,
            )
    const [disposer, promise] = this._fetchWithQueue(type, id, fetcher)
    promise
      .then(
        type === MULTIPLE_DEEP_FORWARD
          ? this.addDeepBaseProducts
          : this.addDeepDescendantProducts,
      )
      .catch(noop)
    return disposer
  }

  getSelectedMultipleRelations(selections) {
    return groupProductsIdByNomenclature(selections, this)
  }

  @action.bound setSelectedMultipleRelations(selections, new_selections) {
    selections.replace(Object.values(new_selections).flat())
  }

  getExcludedMultipleRelations(id) {
    const item = this.multiple_relations.find(i => i.id === id)
    if (!item || !item.excluded) return []
    return groupProductsIdByNomenclature(item.excluded, this)
  }

  @action.bound setExcludedMultipleRelations(id, excluded) {
    const item = this.multiple_relations.find(i => i.id === id)
    item.selections.clear()
    item.excluded.replace(Object.values(excluded).flat())
  }

  getMultipleRelationsAllProductNomenclatures(id) {
    const { type, products_id } = this.multiple_relations.find(i => i.id === id)
    const rel_map =
      type === MULTIPLE_DEEP_FORWARD
        ? this.base_products
        : this.descendant_products
    return allMultipleProductNomenclatures(products_id, rel_map, this)
  }

  isInStockAndTaraFetching(product_id) {
    return this.isFetching(IN_STOCK_AND_TARA, product_id)
  }

  isInStockAndTaraFetched(product_id) {
    return this.in_stock_and_tara.has(`${product_id}`)
  }

  getInStockAndTaraErrorMessage(product_id) {
    return this.getErrorMessage(IN_STOCK_AND_TARA, product_id)
  }

  @action.bound addInStockAndTara(products_id, data) {
    this.in_stock_and_tara.set(`${products_id}`, data)
  }

  getInStockAndTara(product_id) {
    return this.isInStockAndTaraFetched(product_id)
      ? this.in_stock_and_tara.get(`${product_id}`)
      : null
  }

  fetchInStockAndTara(product_id) {
    const [disposer, promise] = this._fetchWithQueue(
      IN_STOCK_AND_TARA,
      product_id,
    )
    promise.then(data => this.addInStockAndTara(product_id, data)).catch(noop)
    return disposer
  }
}

const store = new TraceProductionStore()
export default store
