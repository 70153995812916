import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Col, FormGroup, Label } from 'reactstrap'
import FormDateTimePicker from '../../ui/FormDateTimePicker'
import { FormInput, NumInput } from '../../ui/FormInput'
import FormPopup from '../../ui/FormPopup'
import { FormSelect } from '../../ui/FormSelect'
import FormTimePicker from '../../ui/FormTimePicker'
import FormDatePicker from '../../ui/FormDatePicker'

@observer
export default class QueryParamViewer extends React.Component {
  static propTypes = {
    model: PropTypes.object,
    name: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }

  elements = {
    VARCHAR: FormInput,
    NUMERIC: NumInput,
    DATE: FormDatePicker,
    INTERVAL: FormTimePicker,
    ENUM: FormSelect,
    DICT: FormPopup,
    DATETIME: FormDateTimePicker,
  }

  elementProps = {
    ENUM: { canClear: true },
    DICT: { canClear: true, noCreate: true },
  }

  render() {
    const { model, name, display_name, type, ...props } = this.props
    const Component = this.elements[type]
    return (
      !!Component && (
        <Col xs={12} sm={6} md={4}>
          <FormGroup>
            <Label>{display_name}</Label>
            <Component
              {...props}
              {...this.elementProps[type]}
              model={model}
              name={name}
            />
          </FormGroup>
        </Col>
      )
    )
  }
}
