export const ru = `<?xml version="1.0" encoding="utf-8" standalone="yes"?>
<Localization language="Russian" description="Русский" cultureName="ru">
  <A_WebViewer>
    <AbbreviatedDayFriday>Пт</AbbreviatedDayFriday>
    <AbbreviatedDayMonday>Пн</AbbreviatedDayMonday>
    <AbbreviatedDaySaturday>Сб</AbbreviatedDaySaturday>
    <AbbreviatedDaySunday>Вс</AbbreviatedDaySunday>
    <AbbreviatedDayThursday>Чт</AbbreviatedDayThursday>
    <AbbreviatedDayTuesday>Вт</AbbreviatedDayTuesday>
    <AbbreviatedDayWednesday>Ср</AbbreviatedDayWednesday>
    <Attachment>Вложение:</Attachment>
    <ButtonNext>Следующий</ButtonNext>
    <ButtonPrev>Предыдущий</ButtonPrev>
    <ButtonSend>Отправить</ButtonSend>
    <CategoryAlreadyExists>Категория с таким названием уже существует!</CategoryAlreadyExists>
    <DayFriday>Пятница</DayFriday>
    <DayMonday>Понедельник</DayMonday>
    <DaySaturday>Суббота</DaySaturday>
    <DaySunday>Воскресенье</DaySunday>
    <DayThursday>Четверг</DayThursday>
    <DayTuesday>Вторник</DayTuesday>
    <DayWednesday>Среда</DayWednesday>
    <Email>Email:</Email>
    <EmailOptions>Настройки отправки Email</EmailOptions>
    <FirstPage>Первая страница</FirstPage>
    <Hours>Часы</Hours>
    <LabelFrom>От:</LabelFrom>
    <LabelSelectExportFormat>Выберите формат, в котором Вы хотите сохранить отчет:</LabelSelectExportFormat>
    <LabelTo>Куда:</LabelTo>
    <LastPage>Последняя страница</LastPage>
    <Loading>Загрузка...</Loading>
    <Message>Сообщение:</Message>
    <Minutes>Минуты</Minutes>
    <MonthApril>Апрель</MonthApril>
    <MonthAugust>Август</MonthAugust>
    <MonthDecember>Декабрь</MonthDecember>
    <MonthFebruary>Февраль</MonthFebruary>
    <MonthJanuary>Январь</MonthJanuary>
    <MonthJuly>Июль</MonthJuly>
    <MonthJune>Июнь</MonthJune>
    <MonthMarch>Март</MonthMarch>
    <MonthMay>Май</MonthMay>
    <MonthNovember>Ноябрь</MonthNovember>
    <MonthOctober>Октябрь</MonthOctober>
    <MonthSeptember>Сентябрь</MonthSeptember>
    <NextPage>Следующая страница</NextPage>
    <OnePage>Одна страница</OnePage>
    <Page>Страница</Page>
    <PageOf>из</PageOf>
    <PreviousPage>Предыдущая страница</PreviousPage>
    <PrintContinue>Нажмите кнопку Печать для продолжения</PrintContinue>
    <PrintReport>Печатать</PrintReport>
    <PrintToPdf>Печатать в PDF</PrintToPdf>
    <PrintToXps>Печать в XPS</PrintToXps>
    <PrintWithoutPreview>Печатать без просмотра</PrintWithoutPreview>
    <PrintWithPreview>Печатать с просмотром</PrintWithPreview>
    <SaveReport>Сохранить</SaveReport>
    <Subject>Тема:</Subject>
    <TabItemContacts>Контакты</TabItemContacts>
    <TextComputer>Компьютер</TextComputer>
    <TextItemsRoot>Основной</TextItemsRoot>
    <TodayDate>Сегодня</TodayDate>
    <WholeReport>Весь отчет</WholeReport>
  </A_WebViewer>
  <Adapters>
    <AdapterBusinessObjects>Данные из Business Objects</AdapterBusinessObjects>
    <AdapterConnection>Данные из {0}</AdapterConnection>
    <AdapterCrossTabDataSource>Данные на основе Кросс-таблицы</AdapterCrossTabDataSource>
    <AdapterCsvFiles>Данные из CSV Files</AdapterCsvFiles>
    <AdapterDataTables>Данные из DataSet, DataTables</AdapterDataTables>
    <AdapterDataViews>Данные из DataViews</AdapterDataViews>
    <AdapterDB2Connection>Данные из IBM DB2 соединения</AdapterDB2Connection>
    <AdapterDBaseFiles>Данные из dBase файлов</AdapterDBaseFiles>
    <AdapterFirebirdConnection>Данные из Firebird соединения</AdapterFirebirdConnection>
    <AdapterInformixConnection>Данные из Informix соединения</AdapterInformixConnection>
    <AdapterMySQLConnection>Данные из MySQL соединения</AdapterMySQLConnection>
    <AdapterOdbcConnection>Данные из ODBC соединения</AdapterOdbcConnection>
    <AdapterOleDbConnection>Данные из OLE DB соединения</AdapterOleDbConnection>
    <AdapterOracleConnection>Данные из Oracle соединения</AdapterOracleConnection>
    <AdapterOracleODPConnection>Данные из Oracle ODP.NET соединения</AdapterOracleODPConnection>
    <AdapterPostgreSQLConnection>Данные из PostgreSQL соединения</AdapterPostgreSQLConnection>
    <AdapterSqlCeConnection>Данные из SQLCe соединения</AdapterSqlCeConnection>
    <AdapterSqlConnection>Данные из SQL соединения</AdapterSqlConnection>
    <AdapterSQLiteConnection>Данные из SQLite соединения</AdapterSQLiteConnection>
    <AdapterTeradataConnection>Данные из Teradata соединения</AdapterTeradataConnection>
    <AdapterUniDirectConnection>Данные из Uni Direct соединения</AdapterUniDirectConnection>
    <AdapterUserSources>Данные из User Sources</AdapterUserSources>
    <AdapterVirtualSource>Данные на основе других данных</AdapterVirtualSource>
    <AdapterVistaDBConnection>Данные из VistaDB соединения</AdapterVistaDBConnection>
  </Adapters>
  <Buttons>
    <Add>Добавить</Add>
    <AddAllColumns>Добавить все колонки</AddAllColumns>
    <Attach>Прикрепить</Attach>
    <Build>Построить...</Build>
    <Cancel>&amp;Отмена</Cancel>
    <Check>Проверить</Check>
    <Close>Закрыть</Close>
    <Delete>Удалить</Delete>
    <Design>Дизайн</Design>
    <Down>Вниз</Down>
    <Duplicate>Копия</Duplicate>
    <Export>Экспорт</Export>
    <Help>Помощь</Help>
    <LessOptions>Меньше параметров</LessOptions>
    <LoadDataSet>Загрузить DataSet (*.data)</LoadDataSet>
    <MoreOptions>Больше параметров</MoreOptions>
    <MoveLeft>Переместить влево</MoveLeft>
    <MoveRight>Переместить вправо</MoveRight>
    <No>&amp;Нет</No>
    <Ok>&amp;ОК</Ok>
    <Open>Открыть</Open>
    <Print>Печать</Print>
    <Publish>Опубликовать</Publish>
    <QuickPrint>Быстрая печать</QuickPrint>
    <Remove>Удалить</Remove>
    <RemoveAll>Удалить все</RemoveAll>
    <Rename>Переименовать</Rename>
    <RestoreDefaults>Восстановить по умолчанию</RestoreDefaults>
    <Reverse>Перевернуть</Reverse>
    <Save>Сохранить</Save>
    <SaveCopy>Сохранить копию</SaveCopy>
    <SetAll>Установить все</SetAll>
    <ShowLess>Показать меньше</ShowLess>
    <ShowMore>Показать больше</ShowMore>
    <Submit>Применить</Submit>
    <Test>Проверка</Test>
    <Up>Вверх</Up>
    <Upload>Загрузить</Upload>
    <Yes>&amp;Да</Yes>
  </Buttons>
  <Chart>
    <AddCondition>&amp;Добавить условие</AddCondition>
    <AddConstantLine>Добавить константную линию</AddConstantLine>
    <AddFilter>&amp;Добавить фильтр</AddFilter>
    <AddSeries>Добавить ряд</AddSeries>
    <AddStrip>Добавить полосу</AddStrip>
    <Area>Область</Area>
    <Axes>Оси</Axes>
    <AxisReverse>Перевернуть</AxisReverse>
    <AxisX>Ось X</AxisX>
    <AxisY>Ось Y</AxisY>
    <Bubble>Пузырьковая</Bubble>
    <Candlestick>Японские свечи</Candlestick>
    <ChartConditionsCollectionForm>Условия</ChartConditionsCollectionForm>
    <ChartEditorForm>Редактор диаграмм</ChartEditorForm>
    <ChartFiltersCollectionForm>Фильтры</ChartFiltersCollectionForm>
    <ChartType>Тип диаграммы</ChartType>
    <CheckBoxAutoRotation>Автоповорот</CheckBoxAutoRotation>
    <ClusteredBar>Линейчатая</ClusteredBar>
    <ClusteredColumn>Гистограмма</ClusteredColumn>
    <Common>Основные</Common>
    <ConstantLine>Константная линия</ConstantLine>
    <ConstantLinesEditorForm>Редактор константных линий</ConstantLinesEditorForm>
    <DataColumns>Колонки данных</DataColumns>
    <Doughnut>Кольцевая</Doughnut>
    <Financial>Финансовая</Financial>
    <FullStackedArea>Нормированная область</FullStackedArea>
    <FullStackedBar>Нормированная линейчатая</FullStackedBar>
    <FullStackedColumn>Нормированная гистограмма</FullStackedColumn>
    <FullStackedLine>Нормированная линия</FullStackedLine>
    <FullStackedSpline>Нормированная сглаженная линия</FullStackedSpline>
    <FullStackedSplineArea>Нормированная сглаженная область</FullStackedSplineArea>
    <Funnel>Воронка</Funnel>
    <FunnelWeightedSlices>Взвешенная воронка</FunnelWeightedSlices>
    <Gantt>Ганта</Gantt>
    <GridInterlaced>Чередование</GridInterlaced>
    <GridLines>Линии сетки</GridLines>
    <LabelAlignment>Выравнивание:</LabelAlignment>
    <LabelAlignmentHorizontal>Горизонтальное:</LabelAlignmentHorizontal>
    <LabelAlignmentVertical>Вертикальное:</LabelAlignmentVertical>
    <LabelAngle>Угол:</LabelAngle>
    <LabelArgumentDataColumn>Колонка аргумента:</LabelArgumentDataColumn>
    <LabelAutoRotation>Автоповорот:</LabelAutoRotation>
    <LabelCloseValueDataColumn>Колонка закрывающих значений:</LabelCloseValueDataColumn>
    <LabelEndValueDataColumn>Колонка конечных значений:</LabelEndValueDataColumn>
    <LabelHighValueDataColumn>Колонка максимумов:</LabelHighValueDataColumn>
    <LabelHorizontal>Горизонтальный:</LabelHorizontal>
    <LabelLowValueDataColumn>Колонка минимумов:</LabelLowValueDataColumn>
    <LabelMinorCount>Кол-во промеж. линий:</LabelMinorCount>
    <LabelOpenValueDataColumn>Колонка открывающих значений:</LabelOpenValueDataColumn>
    <Labels>Подписи</Labels>
    <LabelsCenter>По центру</LabelsCenter>
    <LabelSeriesName>Наименование ряда:</LabelSeriesName>
    <LabelsInside>Внутри</LabelsInside>
    <LabelsInsideBase>Внутри в начале</LabelsInsideBase>
    <LabelsInsideEnd>Внутри в конце</LabelsInsideEnd>
    <LabelsNone>Нет</LabelsNone>
    <LabelsOutside>Снаружи</LabelsOutside>
    <LabelsOutsideBase>Снаружи в начале</LabelsOutsideBase>
    <LabelsOutsideEnd>Снаружи в конце</LabelsOutsideEnd>
    <LabelsTwoColumns>Две колонки</LabelsTwoColumns>
    <LabelTextAfter>Текст после:</LabelTextAfter>
    <LabelTextBefore>Текст до:</LabelTextBefore>
    <LabelTitleAlignment>Выравнивание:</LabelTitleAlignment>
    <LabelValueDataColumn>Колонка величин:</LabelValueDataColumn>
    <LabelValueType>Тип значения:</LabelValueType>
    <LabelVertical>Вертикальный</LabelVertical>
    <LabelVisible>Показывать:</LabelVisible>
    <Legend>Легенда</Legend>
    <LegendSpacing>Расстояние</LegendSpacing>
    <Line>Линия</Line>
    <ListOfValues>Список значений</ListOfValues>
    <Marker>Пометка</Marker>
    <MoveConstantLineDown>Переместить константную линию вниз</MoveConstantLineDown>
    <MoveConstantLineUp>Переместить константную линию вверх</MoveConstantLineUp>
    <MoveSeriesDown>Переместить ряд вниз</MoveSeriesDown>
    <MoveSeriesUp>Переместить ряд вверх</MoveSeriesUp>
    <MoveStripDown>Переместить полосу вниз</MoveStripDown>
    <MoveStripUp>Переместить полосу вверх</MoveStripUp>
    <NoConditions>Нет условий</NoConditions>
    <NoFilters>Нет фильтров</NoFilters>
    <Pie>Круговая</Pie>
    <Radar>Лепестковая</Radar>
    <RadarArea>Лепестковая с областью</RadarArea>
    <RadarColumn>Лепестковая с колонками</RadarColumn>
    <RadarLine>Лепестковая с линией</RadarLine>
    <RadarPoint>Лепестковая с точками</RadarPoint>
    <Range>Диапазон</Range>
    <RangeBar>Столбчатый диапазон</RangeBar>
    <RemoveCondition>&amp;Удалить условие</RemoveCondition>
    <RemoveConstantLine>Удалить константную линию</RemoveConstantLine>
    <RemoveFilter>&amp;Удалить фильтр</RemoveFilter>
    <RemoveSeries>Удалить ряд</RemoveSeries>
    <RemoveStrip>Удалить полосу</RemoveStrip>
    <RunChartWizard>Запустить мастер диаграмм</RunChartWizard>
    <Scatter>Точечная</Scatter>
    <ScatterLine>Точечная с линиями</ScatterLine>
    <ScatterSpline>Точечная со сплайнами</ScatterSpline>
    <Series>Ряд</Series>
    <SeriesColorsCollectionForm>Цвета серий</SeriesColorsCollectionForm>
    <SeriesEditorForm>Редактор рядов</SeriesEditorForm>
    <Serieses>Ряды</Serieses>
    <Spline>Плавная линия</Spline>
    <SplineArea>Сглаженная область</SplineArea>
    <SplineRange>Сглаженный диапазон</SplineRange>
    <StackedArea>Область с накоплением</StackedArea>
    <StackedBar>Линейчатая с накоплением</StackedBar>
    <StackedColumn>Гистограмма с накоплением</StackedColumn>
    <StackedLine>Линия с накоплением</StackedLine>
    <StackedSpline>Сглаженная линия с накоплением</StackedSpline>
    <StackedSplineArea>Сглаженная область с накоплением</StackedSplineArea>
    <SteppedArea>Пошаговая Область</SteppedArea>
    <SteppedLine>Пошаговая Линия</SteppedLine>
    <SteppedRange>Пошаговый диапазон</SteppedRange>
    <Stock>Биржевая</Stock>
    <Strip>Полоса</Strip>
    <StripsEditorForm>Редактор полос</StripsEditorForm>
    <Style>Стиль</Style>
    <Treemap>Древовидная</Treemap>
  </Chart>
  <ChartRibbon>
    <Axes>Оси</Axes>
    <AxesArrowStyle>Стиль стрелки</AxesArrowStyle>
    <AxesArrowStyleLines>Линии</AxesArrowStyleLines>
    <AxesArrowStyleNone>Нет</AxesArrowStyleNone>
    <AxesArrowStyleTriangle>Треугольник</AxesArrowStyleTriangle>
    <AxesLabel>Размещение подписей</AxesLabel>
    <AxesLabelsNone>нет</AxesLabelsNone>
    <AxesLabelsOneLine>В одну линию</AxesLabelsOneLine>
    <AxesLabelsTwoLines>В две линии</AxesLabelsTwoLines>
    <AxesReverseHorizontal>Перевернуть по горизонтали</AxesReverseHorizontal>
    <AxesReverseVertical>Перевернуть по вертикали</AxesReverseVertical>
    <AxesTicks>Метки</AxesTicks>
    <AxesTicksMajor>Основные</AxesTicksMajor>
    <AxesTicksMinor>Вспомогательные</AxesTicksMinor>
    <AxesTicksNone>Нет</AxesTicksNone>
    <AxesVisible>Показывать</AxesVisible>
    <AxesXAxis>Ось X</AxesXAxis>
    <AxesXTopAxis>Ось X верхняя</AxesXTopAxis>
    <AxesYAxis>Ось Y</AxesYAxis>
    <AxesYRightAxis>Ось Y правая</AxesYRightAxis>
    <CenterLabels>По центру</CenterLabels>
    <ChangeType>Изменить тип</ChangeType>
    <GridLines>Линии сетки</GridLines>
    <GridLinesHorizontal>Горизонтальные линии сетки</GridLinesHorizontal>
    <GridLinesVertical>Вертикальные линии сетки</GridLinesVertical>
    <HorAlCenter>&lt;b&gt;Наложить легенду по центру&lt;/b&gt;&lt;br&gt;Накладывать легенду по центру диаграммы&lt;/br&gt;&lt;br&gt;без изменения размеров диаграммы&lt;/br&gt;</HorAlCenter>
    <HorAlLeft>&lt;b&gt;Наложить легенду слева&lt;/b&gt;&lt;br&gt;Накладывать легенду слева от диаграммы&lt;/br&gt;&lt;br&gt;без изменения размеров диаграммы&lt;/br&gt;</HorAlLeft>
    <HorAlLeftOutside>&lt;b&gt;Показывать легенду слева&lt;/b&gt;&lt;br&gt;Показывать легенду слева от диаграммы&lt;/br&gt;</HorAlLeftOutside>
    <HorAlRight>&lt;b&gt;Наложить легенду справа&lt;/b&gt;&lt;br&gt;Накладывать легенду справа от диаграммы&lt;/br&gt;&lt;br&gt;без изменения размеров диаграммы&lt;/br&gt;</HorAlRight>
    <HorAlRightOutside>&lt;b&gt;Показывать легенду справа&lt;/b&gt;&lt;br&gt;Показывать легенду справа от диаграммы&lt;/br&gt;</HorAlRightOutside>
    <HorizontalMajor>&lt;b&gt;Основные&lt;/b&gt;&lt;br&gt;Отображать горизонтальные линии основной сетки&lt;/br&gt;</HorizontalMajor>
    <HorizontalMajorMinor>&lt;b&gt;Основные и вспомогательные&lt;/b&gt;&lt;br&gt;Отображать горизонтальные линии основной и вспомогательной сеток&lt;/br&gt;</HorizontalMajorMinor>
    <HorizontalMinor>&lt;b&gt;Вспомогательные&lt;/b&gt;&lt;br&gt;Отображать горизонтальные линии вспомогательной сетки&lt;/br&gt;</HorizontalMinor>
    <HorizontalNone>&lt;b&gt;Не отображать&lt;/b&gt;&lt;br&gt;Не отображать горизонтальные линии&lt;/br&gt;</HorizontalNone>
    <InsideBaseLabels>Внутри у основания</InsideBaseLabels>
    <InsideEndLabels>Внутри в конце</InsideEndLabels>
    <Labels>Подписи рядов</Labels>
    <Legend>Легенда</Legend>
    <LegendHorizontalAlignment>Выравнивание по горизонтали</LegendHorizontalAlignment>
    <LegendMarker>Маркер</LegendMarker>
    <LegendMarkerAlignmentLeft>Слева</LegendMarkerAlignmentLeft>
    <LegendMarkerAlignmentRight>Справа</LegendMarkerAlignmentRight>
    <LegendMarkerVisible>Показывать</LegendMarkerVisible>
    <LegendVerticalAlignment>Выравнивание по вертикали</LegendVerticalAlignment>
    <LegendVisible>Показывать</LegendVisible>
    <NoneLabels>Нет</NoneLabels>
    <OutsideBaseLabels>Снаружи у основания</OutsideBaseLabels>
    <OutsideEndLabels>Снаружи в конце</OutsideEndLabels>
    <OutsideLabels>Снаружи</OutsideLabels>
    <ribbonBarAxis>Оси</ribbonBarAxis>
    <ribbonBarChartStyles>Стили диаграммы</ribbonBarChartStyles>
    <ribbonBarChartType>Изменить тип диаграммы</ribbonBarChartType>
    <ribbonBarLabels>Подписи</ribbonBarLabels>
    <ribbonBarLegend>Легенда</ribbonBarLegend>
    <Style>Изменить стиль</Style>
    <TwoColumnsPieLabels>Две колонки</TwoColumnsPieLabels>
    <VertAlBottom>&lt;b&gt;Наложить легенду внизу&lt;/b&gt;&lt;br&gt;Накладывать легенду внизу диаграммы&lt;/br&gt;&lt;br&gt;без изменения размеров диаграммы&lt;/br&gt;</VertAlBottom>
    <VertAlBottomOutside>&lt;b&gt;Показывать легенду внизу&lt;/b&gt;&lt;br&gt;Показывать легенду под диаграммой&lt;/br&gt;</VertAlBottomOutside>
    <VertAlCenter>&lt;b&gt;Наложить легенду по центру&lt;/b&gt;&lt;br&gt;Накладывать легенду по центру диаграммы&lt;/br&gt;&lt;br&gt;без изменения размеров диаграммы&lt;/br&gt;</VertAlCenter>
    <VertAlTop>&lt;b&gt;Наложить легенду сверху&lt;/b&gt;&lt;br&gt;Накладывать легенду сверху диаграммы&lt;/br&gt;&lt;br&gt;без изменения размеров диаграммы&lt;/br&gt;</VertAlTop>
    <VertAlTopOutside>&lt;b&gt;Показывать легенду сверху&lt;/b&gt;&lt;br&gt;Показывать легенду над диаграммой сверху&lt;/br&gt;</VertAlTopOutside>
    <VerticalMajor>&lt;b&gt;Основные&lt;/b&gt;&lt;br&gt;Отображать вертикальные линии основной сетки&lt;/br&gt;</VerticalMajor>
    <VerticalMajorMinor>&lt;b&gt;Основные и вспомогательные&lt;/b&gt;&lt;br&gt;Отображать вертикальные линии основной и вспомогательной сеток&lt;/br&gt;</VerticalMajorMinor>
    <VerticalMinor>&lt;b&gt;Вспомогательные&lt;/b&gt;&lt;br&gt;Отображать вертикальные линии вспомогательной сетки&lt;/br&gt;</VerticalMinor>
    <VerticalNone>&lt;b&gt;Не отображать&lt;/b&gt;&lt;br&gt;Не отображать вертикальные линии&lt;/br&gt;</VerticalNone>
  </ChartRibbon>
  <Cloud>
    <AccountSettings>Настройки аккаунта</AccountSettings>
    <AddAPlace>Добавление места</AddAPlace>
    <ButtonChangePassword>Изменить пароль</ButtonChangePassword>
    <ButtonDeleteAll>Удалить все</ButtonDeleteAll>
    <ButtonDesign>Редактировать отчет</ButtonDesign>
    <ButtonLogout>Выйти</ButtonLogout>
    <ButtonPublish>Опубликовать</ButtonPublish>
    <ButtonPurchase>Купить</ButtonPurchase>
    <ButtonRecover>Восстановить</ButtonRecover>
    <ButtonRenew>Обновление</ButtonRenew>
    <ButtonResendEmail>Отправить Email еще раз</ButtonResendEmail>
    <ButtonResetPassword>Сбросить</ButtonResetPassword>
    <ButtonRun>Запустить</ButtonRun>
    <ButtonShare>Доступ</ButtonShare>
    <ButtonSignUp>Регистрация</ButtonSignUp>
    <ButtonSkip>Пропустить</ButtonSkip>
    <ButtonView>Просмотр</ButtonView>
    <ButtonWhereUsed>Где используется?</ButtonWhereUsed>
    <Cancel>Отмена</Cancel>
    <CheckBoxMoveToRecycleBin>Переместить в Корзину</CheckBoxMoveToRecycleBin>
    <CheckBoxRememberMe>Запомнить меня</CheckBoxRememberMe>
    <Cloud>Облако</Cloud>
    <Collection>Коллекция</Collection>
    <Create>Создать</Create>
    <CreateError>Ошибка при создании отчета!</CreateError>
    <CreateNewCollection>Создать новую коллекцию</CreateNewCollection>
    <CreatingReport>Создание отчета...</CreatingReport>
    <DashboardWindowTitleNew>Новая панель индикаторов</DashboardWindowTitleNew>
    <DeleteFile>Удалить файл</DeleteFile>
    <ExecutionError>Ошибка запуска</ExecutionError>
    <ExpiredDate>Дата истечения срока</ExpiredDate>
    <FileStorageWindowTitleEdit>Редактирование хранилища файлов</FileStorageWindowTitleEdit>
    <FileStorageWindowTitleNew>Создание хранилища файлов</FileStorageWindowTitleNew>
    <FolderWindowTitleEdit>Изменение параметров папки</FolderWindowTitleEdit>
    <FolderWindowTitleNew>Новая папка</FolderWindowTitleNew>
    <ForExample>Например</ForExample>
    <GroupBoxAttachedItems>Прикрепленные элементы</GroupBoxAttachedItems>
    <HyperlinkAgreeToTerms>Регистрируясь, Вы соглашаетесь с условиями</HyperlinkAgreeToTerms>
    <HyperlinkAlreadyHaveAccount>Уже есть аккаунт</HyperlinkAlreadyHaveAccount>
    <HyperlinkForgotPassword>Забыл пароль</HyperlinkForgotPassword>
    <HyperlinkHavePassword>У меня есть пароль</HyperlinkHavePassword>
    <HyperlinkRegisterAccount>Создать аккаунт бесплатно</HyperlinkRegisterAccount>
    <labelCollectionName>Наименование коллекции:</labelCollectionName>
    <LabelCreated>Создан:</LabelCreated>
    <LabelCreateFolder>Папка для размещения в ней других элементов.</LabelCreateFolder>
    <LabelCreateReportTemplate>Отчет для вывода данных.</LabelCreateReportTemplate>
    <LabelCurrentPassword>Текущий пароль:</LabelCurrentPassword>
    <LabelDataFile>Файл данных:</LabelDataFile>
    <LabelDataUrl>URL данных:</LabelDataUrl>
    <labelFileName>Наименование файла:</labelFileName>
    <LabelForeground>Передний план:</LabelForeground>
    <LabelFromReport>Из отчета</LabelFromReport>
    <LabelFromReportCode>Из отчета (задано в коде)</LabelFromReportCode>
    <LabelLastLogin>Последний вход:</LabelLastLogin>
    <LabelLastTime>Последний раз:</LabelLastTime>
    <LabelModified>Изменен:</LabelModified>
    <LabelNewPassword>Новый пароль:</LabelNewPassword>
    <LabelNextTime>Следующий раз:</LabelNextTime>
    <labelPassword>Пароль:</labelPassword>
    <LabelPermission>Разрешение:</LabelPermission>
    <LabelPicture>Изображение:</LabelPicture>
    <LabelRenderedReport>Построенный отчет</LabelRenderedReport>
    <LabelResponseAsFile>Ответ в виде файла</LabelResponseAsFile>
    <LabelSeparateReport>Отдельный файл отчета(и скриптов)</LabelSeparateReport>
    <LabelShowReport>Отобразить отчет:</LabelShowReport>
    <labelUserName>Имя пользователя:</labelUserName>
    <License>Лицензия</License>
    <LicenseInformation>Информация о лицензии</LicenseInformation>
    <Login>Войти</Login>
    <NofM>{0}: {1} из {2}</NofM>
    <Open>Открыть</Open>
    <OpenFile>Открыть файл</OpenFile>
    <OperationCreate>Создать '{0}'</OperationCreate>
    <OperationDelete>Удалить '{0}' с сервера</OperationDelete>
    <OperationDownload>Скачать с сервера</OperationDownload>
    <OperationGetList>Получить список файлов с сервера</OperationGetList>
    <OperationLogin>Соединиться с сервером</OperationLogin>
    <OperationRename>Переименовать '{0}' в '{1}'</OperationRename>
    <OperationUpload>Отправить '{0}' на сервер</OperationUpload>
    <page>страница</page>
    <Platforms>Платформы</Platforms>
    <Products>Продукты</Products>
    <questionOpenThisFile>Вы действительно хотите открыть '{0}' файл?</questionOpenThisFile>
    <questionOverrideItem>Вы действительно хотите  перезаписать '{0}' файл?</questionOverrideItem>
    <questionRemoveItem>Вы действительно хотите удалить '{0}' файл?</questionRemoveItem>
    <RefreshList>Обновить список</RefreshList>
    <ReportDocumentFormatNotRecognized>Формат файла '{0}' не распознан как формат построенного отчета!</ReportDocumentFormatNotRecognized>
    <ReportTemplateFormatNotRecognized>Формат файла '{0}' не распознан как формат шаблона отчета!</ReportTemplateFormatNotRecognized>
    <RequestChangesWhenSavingToCloud>Показывать окно изменений при сохранении в облако</RequestChangesWhenSavingToCloud>
    <RibbonButtonAddRole>Добавить роль</RibbonButtonAddRole>
    <RibbonButtonAddUser>Добавить пользователя</RibbonButtonAddUser>
    <RibbonButtonAddWorkspace>Добавить рабочее пространство</RibbonButtonAddWorkspace>
    <RibbonButtonFolder>Папка</RibbonButtonFolder>
    <RibbonTabUsers>Пользователи</RibbonTabUsers>
    <Root>Основной</Root>
    <RootFolder>Основная папка</RootFolder>
    <Save>Сохранить</Save>
    <SaveAccountSettings>Запомнить настройки аккаунта</SaveAccountSettings>
    <SaveFile>Сохранить файл</SaveFile>
    <ShareWindowTitleNew>Доступ</ShareWindowTitleNew>
    <ShowAllFiles>Показать все файлы</ShowAllFiles>
    <Subscriptions>Подписки</Subscriptions>
    <TabItemEmbedCode>Код встраивания</TabItemEmbedCode>
    <TabItemQRCode>QR код</TabItemQRCode>
    <TabItemShare>Ссылка</TabItemShare>
    <TextActivated>Активирована</TextActivated>
    <TextActivationDate>Дата активации</TextActivationDate>
    <TextDelete>Удаление</TextDelete>
    <TextDeletingItems>Удаление элементов...</TextDeletingItems>
    <TextDescriptionChanges>Пожалуйста, опишите изменения</TextDescriptionChanges>
    <TextFirstName>Имя</TextFirstName>
    <TextFromTo>От {0} до {1} (Всего - {2})</TextFromTo>
    <TextItemsWorkspace>Рабочее пространство</TextItemsWorkspace>
    <TextLastName>Фамилия</TextLastName>
    <TextModify>Изменение</TextModify>
    <TextNoNotifications>Нет уведомлений</TextNoNotifications>
    <TextOwner>Владелец</TextOwner>
    <TextProfile>Профиль</TextProfile>
    <TextReports>Отчеты</TextReports>
    <TextRestoringItems>Восстановление элементов...</TextRestoringItems>
    <TextRole>Роль</TextRole>
    <TextRun>Запуск</TextRun>
    <TextUser>Пользователь</TextUser>
    <TextUserName>Адрес электронной почты</TextUserName>
    <TimeHoursAgoFive>Пять часов назад, {0:t}</TimeHoursAgoFive>
    <TimeHoursAgoFour>Четыре часа назад, {0:t}</TimeHoursAgoFour>
    <TimeHoursAgoOne>Один час назад, {0:t}</TimeHoursAgoOne>
    <TimeHoursAgoThree>Три часа назад, {0:t}</TimeHoursAgoThree>
    <TimeHoursAgoTwo>Два часа назад, {0:t}</TimeHoursAgoTwo>
    <TimeMinutesAgoFive>Пять минут назад, {0:t}</TimeMinutesAgoFive>
    <TimeMinutesAgoFour>Четыре минуты назад, {0:t}</TimeMinutesAgoFour>
    <TimeMinutesAgoLessOne>Менее минуты назад</TimeMinutesAgoLessOne>
    <TimeMinutesAgoN>{0} минут назад, {1:t}</TimeMinutesAgoN>
    <TimeMinutesAgoOne>Одну минуту назад, {0:t}</TimeMinutesAgoOne>
    <TimeMinutesAgoThree>Три минуты назад, {0:t}</TimeMinutesAgoThree>
    <TimeMinutesAgoTwo>Две минуты назад, {0:t}</TimeMinutesAgoTwo>
    <TimeToday>Сегодня, {0:t}</TimeToday>
    <TimeYesterday>Вчера, {0:t}</TimeYesterday>
    <ToolTipAddRole>Создание новой роли в рабочем пространстве.</ToolTipAddRole>
    <ToolTipAddUser>Создание нового пользователя в рабочем пространстве.</ToolTipAddUser>
    <ToolTipAspNet>Опубликовать выбранный отчет для ASP.NET приложения.</ToolTipAspNet>
    <ToolTipAspNetMvc>Опубликовать выбранный отчет для ASP.NET MVC приложения.</ToolTipAspNetMvc>
    <ToolTipAttach>Прикрепить элементы к выбранному отчету.</ToolTipAttach>
    <ToolTipCreate>Создать новый элемент.</ToolTipCreate>
    <ToolTipDelete>Удалить выбранные элементы.</ToolTipDelete>
    <ToolTipDeleted>Показать удаленные элементы</ToolTipDeleted>
    <ToolTipDownload>Загрузить выбранный элемент.</ToolTipDownload>
    <ToolTipEdit>Редактирование выделенного элемента.</ToolTipEdit>
    <ToolTipGridMode>В виде списка</ToolTipGridMode>
    <ToolTipInfo>Отобразить подробные сведения</ToolTipInfo>
    <ToolTipJs>Опубликовать выбранный отчет в виде HTML страницы</ToolTipJs>
    <ToolTipPublish>Подготовить выбранный отчет для использования в Вашем приложении.</ToolTipPublish>
    <ToolTipRecover>Восстановить выделенные элементы.</ToolTipRecover>
    <ToolTipRunWithoutPreview>Построить выделенный отчет без предварительного просмотра.</ToolTipRunWithoutPreview>
    <ToolTipShare>Разрешить совместный доступ к выделенному файлу.</ToolTipShare>
    <ToolTipSort>Настройки сортировки</ToolTipSort>
    <ToolTipThumbnailMode>В виде эскизов</ToolTipThumbnailMode>
    <ToolTipViewFile>Показать выделенный файл.</ToolTipViewFile>
    <ToolTipViewReport>Отобразить выделенный отчет во вьювере.</ToolTipViewReport>
    <WeDidntFindAnything>Ничего не найдено.</WeDidntFindAnything>
    <WindowDescriptionDelete>Вы уверены, что хотите удалить выделенные элементы?</WindowDescriptionDelete>
    <WindowDescriptionRecover>Элементы, которые были выделены будут восстановлены из корзины.</WindowDescriptionRecover>
    <WindowTitleDelete>Удалить элементы</WindowTitleDelete>
    <WindowTitleForgotPassword>Сброс</WindowTitleForgotPassword>
    <WindowTitleLogin>Войти</WindowTitleLogin>
    <WindowTitleRecover>Восстановить элементы</WindowTitleRecover>
    <WindowTitleRoleEdit>Изменить роль</WindowTitleRoleEdit>
    <WindowTitleRoleNew>Создать роль</WindowTitleRoleNew>
    <WindowTitleSignUp>Создать</WindowTitleSignUp>
    <WindowTitleUserEdit>Изменить пользователя</WindowTitleUserEdit>
    <WindowTitleUserNew>Новый пользователь</WindowTitleUserNew>
    <WindowTitleWorkspaceEdit>Изменить рабочее пространство</WindowTitleWorkspaceEdit>
    <WindowTitleWorkspaceNew>Новое рабочее пространство</WindowTitleWorkspaceNew>
    <WizardBlankReportDescription>Создать новый пустой отчет</WizardBlankReportDescription>
    <WizardExcelDescription>Создание отчета с Excel данными.</WizardExcelDescription>
    <WizardJsonDescription>Создание отчета с JSON данными.</WizardJsonDescription>
    <WizardPrivateShare>Нет доступа</WizardPrivateShare>
    <WizardPrivateShareDescription>Внешний доступ к элементу закрыт.</WizardPrivateShareDescription>
    <WizardPublicShare>Публичный доступ</WizardPublicShare>
    <WizardPublicShareDescription>Внешний доступ для любого не авторизированного пользователя.</WizardPublicShareDescription>
    <WizardRegisteredShare>Авторизированный доступ</WizardRegisteredShare>
    <WizardRegisteredShareDescription>Внешний доступ только для зарегистрированных пользователей из любого рабочего пространства.</WizardRegisteredShareDescription>
    <WizardXmlDescription>Создание отчета с XML данными.</WizardXmlDescription>
  </Cloud>
  <Components>
    <StiBarCode>Штрих-код</StiBarCode>
    <StiChart>Диаграмма</StiChart>
    <StiCheckBox>Флажок</StiCheckBox>
    <StiChildBand>Подчиненный</StiChildBand>
    <StiClone>Клон</StiClone>
    <StiColumnFooterBand>Итог колонки</StiColumnFooterBand>
    <StiColumnHeaderBand>Заголовок колонки</StiColumnHeaderBand>
    <StiComponent>Компонент</StiComponent>
    <StiContainer>Контейнер</StiContainer>
    <StiContourText>Контурный текст</StiContourText>
    <StiCrossColumn>Кросс-колонка</StiCrossColumn>
    <StiCrossColumnTotal>Кросс-итог по колонке</StiCrossColumnTotal>
    <StiCrossDataBand>Кросс-данные</StiCrossDataBand>
    <StiCrossFooterBand>Кросс-итог данных</StiCrossFooterBand>
    <StiCrossGroupFooterBand>Кросс-итог группы</StiCrossGroupFooterBand>
    <StiCrossGroupHeaderBand>Кросс-заголовок группы</StiCrossGroupHeaderBand>
    <StiCrossHeaderBand>Кросс-заголовок данных</StiCrossHeaderBand>
    <StiCrossRow>Кросс-строка</StiCrossRow>
    <StiCrossRowTotal>Кросс-итог по строке</StiCrossRowTotal>
    <StiCrossSummary>Кросс-сумма</StiCrossSummary>
    <StiCrossSummaryHeader>Кросс-заголовок суммы</StiCrossSummaryHeader>
    <StiCrossTab>Кросс-таблица</StiCrossTab>
    <StiCrossTitle>Кросс-заголовок</StiCrossTitle>
    <StiDashboard>Панель индикаторов</StiDashboard>
    <StiDataBand>Данные</StiDataBand>
    <StiEmptyBand>Пустые данные</StiEmptyBand>
    <StiFooterBand>Итог данных</StiFooterBand>
    <StiGauge>Индикатор</StiGauge>
    <StiGroupFooterBand>Итог группы</StiGroupFooterBand>
    <StiGroupHeaderBand>Заголовок группы</StiGroupHeaderBand>
    <StiHeaderBand>Заголовок данных</StiHeaderBand>
    <StiHierarchicalBand>Дерево</StiHierarchicalBand>
    <StiHorizontalLinePrimitive>Горизонтальная линия</StiHorizontalLinePrimitive>
    <StiImage>Картинка</StiImage>
    <StiMap>Карта</StiMap>
    <StiOverlayBand>Водяной знак</StiOverlayBand>
    <StiPage>Страница</StiPage>
    <StiPageFooterBand>Итог страницы</StiPageFooterBand>
    <StiPageHeaderBand>Заголовок страницы</StiPageHeaderBand>
    <StiPanel>Панель</StiPanel>
    <StiRectanglePrimitive>Прямоугольник</StiRectanglePrimitive>
    <StiReport>Отчет</StiReport>
    <StiReportSummaryBand>Итог отчета</StiReportSummaryBand>
    <StiReportTitleBand>Заголовок отчета</StiReportTitleBand>
    <StiRichText>Rich текст</StiRichText>
    <StiRoundedRectanglePrimitive>Закругленный прямоугольник</StiRoundedRectanglePrimitive>
    <StiShape>Геометрия</StiShape>
    <StiSubReport>Вложенный отчет</StiSubReport>
    <StiSystemText>Системный текст</StiSystemText>
    <StiTable>Таблица</StiTable>
    <StiText>Текст</StiText>
    <StiTextInCells>Текст в ячейках</StiTextInCells>
    <StiVerticalLinePrimitive>Вертикальная линия</StiVerticalLinePrimitive>
    <StiWinControl>Win компонент</StiWinControl>
    <StiZipCode>Почтовый индекс</StiZipCode>
  </Components>
  <Dashboard>
    <ChangeChartType>Изменить тип диаграммы</ChangeChartType>
    <ChangeMapType>Изменить тип карты</ChangeMapType>
    <ClearFilterFrom>Очистить фильтр от '{0}'</ClearFilterFrom>
    <DataNotDefined>Данные не указаны</DataNotDefined>
    <Dimension>Измерение</Dimension>
    <Dimensions>Измерения</Dimensions>
    <DragDropDataFromDictionary>Перетащите данные из Словаря данных</DragDropDataFromDictionary>
    <EmptyDashboardFooter>Перетащите компонент из Инструментария или данные из Словаря данных</EmptyDashboardFooter>
    <EmptyDashboardHeader>Пустая панель индикаторов</EmptyDashboardHeader>
    <ImageNotSpecified>Изображение не выбрано</ImageNotSpecified>
    <Measure>Показатель</Measure>
    <Measures>Показатели</Measures>
    <NumberFilters>Числовые фильтры</NumberFilters>
    <StringFilters>Строковые фильтры</StringFilters>
  </Dashboard>
  <Database>
    <Connection>Соединение</Connection>
    <Database>{0} соединение</Database>
    <DatabaseDB2>IBM DB2 соединение</DatabaseDB2>
    <DatabaseFirebird>Firebird соединение</DatabaseFirebird>
    <DatabaseInformix>Informix соединение</DatabaseInformix>
    <DatabaseJson>JSON данные</DatabaseJson>
    <DatabaseMySQL>MySQL соединение</DatabaseMySQL>
    <DatabaseOdbc>ODBC соединение</DatabaseOdbc>
    <DatabaseOleDb>OLE DB соединение</DatabaseOleDb>
    <DatabaseOracle>Oracle соединение</DatabaseOracle>
    <DatabaseOracleODP>Oracle ODP.NET соединение</DatabaseOracleODP>
    <DatabasePostgreSQL>PostgreSQL соединение</DatabasePostgreSQL>
    <DatabaseSql>SQL соединение</DatabaseSql>
    <DatabaseSqlCe>SQLServerCE соединение</DatabaseSqlCe>
    <DatabaseSQLite>SQLite соединение</DatabaseSQLite>
    <DatabaseTeradata>Teradata соединение</DatabaseTeradata>
    <DatabaseUniDirect>Uni Direct соединение</DatabaseUniDirect>
    <DatabaseVistaDB>VistaDB соединение</DatabaseVistaDB>
    <DatabaseXml>XML данные</DatabaseXml>
  </Database>
  <DatePickerRanges>
    <CurrentMonth>Текущий месяц</CurrentMonth>
    <CurrentQuarter>Текущий квартал</CurrentQuarter>
    <CurrentWeek>Текущая неделя</CurrentWeek>
    <CurrentYear>Текущий год</CurrentYear>
    <FirstQuarter>Первый квартал</FirstQuarter>
    <FourthQuarter>Четвертый квартал</FourthQuarter>
    <MonthToDate>С начала месяца</MonthToDate>
    <NextMonth>Следующий месяц</NextMonth>
    <NextQuarter>Следующий квартал</NextQuarter>
    <NextWeek>Следующая неделя</NextWeek>
    <NextYear>Следующий год</NextYear>
    <PreviousMonth>Предыдущий месяц</PreviousMonth>
    <PreviousQuarter>Предыдущий квартал</PreviousQuarter>
    <PreviousWeek>Предыдущая неделя</PreviousWeek>
    <PreviousYear>Предыдущий год</PreviousYear>
    <QuarterToDate>С начала квартала</QuarterToDate>
    <SecondQuarter>Второй квартал</SecondQuarter>
    <ThirdQuarter>Третий квартал</ThirdQuarter>
    <Today>Сегодня</Today>
    <Tomorrow>Завтра</Tomorrow>
    <WeekToDate>С начала недели</WeekToDate>
    <YearToDate>С начала года</YearToDate>
    <Yesterday>Вчера</Yesterday>
  </DatePickerRanges>
  <DesignerFx>
    <AlreadyExists>'{0}' уже существует.</AlreadyExists>
    <CanNotLoadThisReportTemplate>Невозможно загрузить шаблон отчета.</CanNotLoadThisReportTemplate>
    <CloseDataSourceEditor>Вы хотите закрыть редактор источника данных?</CloseDataSourceEditor>
    <CloseEditor>Вы действительно хотите закрыть редактор текста?</CloseEditor>
    <CompilingReport>Компиляция отчета</CompilingReport>
    <Connecting>Соединение с сервером</Connecting>
    <ConnectionError>Ошибка соединения</ConnectionError>
    <ConnectionSuccessfull>Соединение успешно установлено</ConnectionSuccessfull>
    <Continue>Продолжить</Continue>
    <DecryptionError>Ошибка: Неверно указан пароль или файл поврежден.</DecryptionError>
    <EmailSuccessfullySent>Email сообщение было успешно отправлено.</EmailSuccessfullySent>
    <ErrorAtSaving>Ошибка при сохранении</ErrorAtSaving>
    <ErrorCode>Ошибка при сохранении. Код ошибки: {0}</ErrorCode>
    <ErrorServer>Ошибка при сохранении. Сервер не отвечает.</ErrorServer>
    <ExportingReport>Экспорт отчета</ExportingReport>
    <LoadingCode>Загрузка кода</LoadingCode>
    <LoadingConfiguration>Загрузка конфигурации</LoadingConfiguration>
    <LoadingData>Загрузка данных</LoadingData>
    <LoadingDocument>Загрузка документа</LoadingDocument>
    <LoadingImages>Загрузка изображений</LoadingImages>
    <LoadingLanguage>Загрузка локализации</LoadingLanguage>
    <LoadingReport>Загрузка отчета</LoadingReport>
    <PreviewAs>Просмотр как {0}</PreviewAs>
    <RenderingReport>Построение отчета</RenderingReport>
    <ReportSuccessfullySaved>Отчет успешно сохранен.</ReportSuccessfullySaved>
    <RetrieveError>Ошибка получения колонок</RetrieveError>
    <RetrievingColumns>Получение колонок</RetrievingColumns>
    <SavingConfiguration>Сохранение конфигурации</SavingConfiguration>
    <SavingReport>Сохранение отчета</SavingReport>
    <TestConnection>Проверка соединения</TestConnection>
    <TextNotFound>Указанный текст не найден. Текст: {0}</TextNotFound>
  </DesignerFx>
  <Dialogs>
    <StiButtonControl>Кнопка</StiButtonControl>
    <StiCheckBoxControl>Флажок</StiCheckBoxControl>
    <StiCheckedListBoxControl>Список с флажками</StiCheckedListBoxControl>
    <StiComboBoxControl>Поле со списком</StiComboBoxControl>
    <StiDateTimePickerControl>Выбор даты</StiDateTimePickerControl>
    <StiForm>Форма</StiForm>
    <StiGridControl>Таблица</StiGridControl>
    <StiGroupBoxControl>Группа</StiGroupBoxControl>
    <StiLabelControl>Надпись</StiLabelControl>
    <StiListBoxControl>Список</StiListBoxControl>
    <StiListViewControl>Список значков</StiListViewControl>
    <StiLookUpBoxControl>Поле со списком с кодом</StiLookUpBoxControl>
    <StiNumericUpDownControl>Число вверх вниз</StiNumericUpDownControl>
    <StiPanelControl>Панель</StiPanelControl>
    <StiPictureBoxControl>Рисунок</StiPictureBoxControl>
    <StiRadioButtonControl>Переключатель</StiRadioButtonControl>
    <StiReportControl>Элемент управления</StiReportControl>
    <StiRichTextBoxControl>Rich текст</StiRichTextBoxControl>
    <StiTextBoxControl>Текстовое поле</StiTextBoxControl>
    <StiTreeViewControl>Дерево</StiTreeViewControl>
  </Dialogs>
  <Editor>
    <CantFind>Данные, удовлетворяющие условию поиска, не обнаружены.</CantFind>
    <CollapseToDefinitions>Свернуть в определения</CollapseToDefinitions>
    <Column>Колонка: {0}</Column>
    <EntireScope>&amp;С начала</EntireScope>
    <Find>На&amp;йти</Find>
    <FindNext>На&amp;йти далее</FindNext>
    <FindWhat>Найти:</FindWhat>
    <FromCursor>От по&amp;зиции курсора</FromCursor>
    <GotoLine>&amp;Перейти на линию</GotoLine>
    <Line>Линия: {0}</Line>
    <LineNumber>Номер линии:</LineNumber>
    <LineNumberIndex>Номер линии ({0} - {1})</LineNumberIndex>
    <MarkAll>По&amp;метить все</MarkAll>
    <MatchCase>&amp;Учитывать регистр</MatchCase>
    <MatchWholeWord>&amp;Только слово целиком</MatchWholeWord>
    <Outlining>Сво&amp;рачивание</Outlining>
    <PromptOnReplace>По&amp;дтверждение замены</PromptOnReplace>
    <Replace>За&amp;менить</Replace>
    <ReplaceAll>Заменить &amp;все</ReplaceAll>
    <ReplaceWith>Заменит&amp;ь на:</ReplaceWith>
    <Search>Искать</Search>
    <SearchHiddenText>С&amp;крытый текст</SearchHiddenText>
    <SearchUp>Искать в&amp;верх</SearchUp>
    <SelectionOnly>То&amp;лько выделение</SelectionOnly>
    <ShowAutoGeneratedCode>Показывать авто-генерируемый код</ShowAutoGeneratedCode>
    <ShowLineNumbers>Показывать номера линий</ShowLineNumbers>
    <StopOutlining>О&amp;становить сворачивание</StopOutlining>
    <titleFind>Найти</titleFind>
    <titleGotoLine>Перейти на линию</titleGotoLine>
    <titleReplace>Заменить</titleReplace>
    <ToggleAllOutlining>Переключить все сворачивание</ToggleAllOutlining>
    <ToggleOutliningExpansion>&amp;Переключить сворачивание</ToggleOutliningExpansion>
    <UseRegularExpressions>Регулярные Вы&amp;ражения</UseRegularExpressions>
  </Editor>
  <Errors>
    <ComponentIsNotRelease>Компонент не реализует '{0}'</ComponentIsNotRelease>
    <ContainerIsNotValidForComponent>Контейнер {0} не подходит для компонента {1}</ContainerIsNotValidForComponent>
    <DataNotFound>Данные не найдены</DataNotFound>
    <Error>Ошибка!</Error>
    <ErrorsList>Список ошибок</ErrorsList>
    <FieldRequire>Необходимо заполнить поле "{0}"</FieldRequire>
    <FileNotFound>Файл '{0}' не найден.</FileNotFound>
    <IdentifierIsNotValid>Недопустимый идентификатор '{0}'.</IdentifierIsNotValid>
    <ImpossibleFindDataSource>Не могу найти для компонента Источник данных</ImpossibleFindDataSource>
    <NameExists>Повторяющееся имя компонента '{0}'.  Имена компонентов должны быть уникальными.</NameExists>
    <NoServices>Сервисы не найдены в '{0}'</NoServices>
    <NotAssign>Источник данных не указан.</NotAssign>
    <NotCorrectFormat>Входная строка в неверном формате.</NotCorrectFormat>
    <RelationsNotFound>Связи не найдены.</RelationsNotFound>
    <ReportCannotBeSaveDueToErrors>Отчет с ошибками не может быть сохранен!</ReportCannotBeSaveDueToErrors>
    <ServiceNotFound>'{0}' сервис не найден</ServiceNotFound>
  </Errors>
  <Export>
    <AddPageBreaks>Добавить разделители страниц</AddPageBreaks>
    <AllBands>Все бэнды</AllBands>
    <AllowAddOrModifyTextAnnotations>Разрешить добавлять или изменять примечания</AllowAddOrModifyTextAnnotations>
    <AllowCopyTextAndGraphics>Разрешить копировать текст и графику</AllowCopyTextAndGraphics>
    <AllowEditable>Разрешить редактируемые:</AllowEditable>
    <AllowModifyContents>Разрешить редактирование документа</AllowModifyContents>
    <AllowPrintDocument>Разрешить печать документа</AllowPrintDocument>
    <Auto>Авто</Auto>
    <BandsFilter>Фильтр бэндов:</BandsFilter>
    <Color>Цвет</Color>
    <Compressed>Сжатый</Compressed>
    <CompressToArchive>Сжать в архив</CompressToArchive>
    <ContinuousPages>Непрерывные страницы</ContinuousPages>
    <DataAndHeadersFooters>Данные и Заголовки/Итоги</DataAndHeadersFooters>
    <DataOnly>Только данные</DataOnly>
    <DigitalSignature>Цифровая подпись</DigitalSignature>
    <DigitalSignatureCertificateNotSelected>Сертификат не выбран</DigitalSignatureCertificateNotSelected>
    <DigitalSignatureError>Ошибка цифровой подписи на шаге</DigitalSignatureError>
    <DocumentSecurity>Безопасность документа</DocumentSecurity>
    <DotMatrixMode>Матричный режим</DotMatrixMode>
    <EmbeddedFonts>Встроить шрифты</EmbeddedFonts>
    <EmbeddedImageData>Внедрить изображение</EmbeddedImageData>
    <Encoding>Кодировка:</Encoding>
    <EncryptionError>Ошибка шифрования на шаге</EncryptionError>
    <EscapeCodes>Управляющие коды</EscapeCodes>
    <Exactly>Точно</Exactly>
    <ExceptEditableFields>Исключить редактируемые</ExceptEditableFields>
    <ExportDataOnly>Только данные</ExportDataOnly>
    <ExportEachPageToSheet>Каждая страница отдельно</ExportEachPageToSheet>
    <ExportingCalculatingCoordinates>Расчет координат</ExportingCalculatingCoordinates>
    <ExportingCreatingDocument>Создание документа</ExportingCreatingDocument>
    <ExportingFormatingObjects>Форматирование объекта</ExportingFormatingObjects>
    <ExportingReport>Экспорт отчета</ExportingReport>
    <ExportMode>Режим экспорта:</ExportMode>
    <ExportModeFrame>Рамка</ExportModeFrame>
    <ExportModeTable>Таблица</ExportModeTable>
    <ExportObjectFormatting>Форматирование объектов</ExportObjectFormatting>
    <ExportPageBreaks>Экспортировать разрывы страниц</ExportPageBreaks>
    <ExportRtfTextAsImage>Экспортировать RTF текст как изображение</ExportRtfTextAsImage>
    <ExportTypeBmpFile>BMP рисунок...</ExportTypeBmpFile>
    <ExportTypeCalcFile>OpenDocument Calc файл...</ExportTypeCalcFile>
    <ExportTypeCsvFile>CSV файл...</ExportTypeCsvFile>
    <ExportTypeDataFile>Файл данных...</ExportTypeDataFile>
    <ExportTypeDbfFile>DBF файл...</ExportTypeDbfFile>
    <ExportTypeDifFile>Data Interchange формат (DIF) файл...</ExportTypeDifFile>
    <ExportTypeExcel2007File>Microsoft Excel 2007 файл...</ExportTypeExcel2007File>
    <ExportTypeExcelFile>Microsoft Excel файл...</ExportTypeExcelFile>
    <ExportTypeExcelXmlFile>Microsoft Excel XML файл...</ExportTypeExcelXmlFile>
    <ExportTypeGifFile>GIF рисунок...</ExportTypeGifFile>
    <ExportTypeHtml5File>HTML5 файл...</ExportTypeHtml5File>
    <ExportTypeHtmlFile>HTML файл...</ExportTypeHtmlFile>
    <ExportTypeImageFile>Файл изображения...</ExportTypeImageFile>
    <ExportTypeJpegFile>JPEG рисунок...</ExportTypeJpegFile>
    <ExportTypeMetafile>Windows Metafile...</ExportTypeMetafile>
    <ExportTypeMhtFile>MHT Web архив...</ExportTypeMhtFile>
    <ExportTypePcxFile>PCX рисунок...</ExportTypePcxFile>
    <ExportTypePdfFile>Adobe PDF файл...</ExportTypePdfFile>
    <ExportTypePngFile>PNG рисунок...</ExportTypePngFile>
    <ExportTypePpt2007File>Microsoft PowerPoint файл...</ExportTypePpt2007File>
    <ExportTypeRtfFile>Rich Text файл...</ExportTypeRtfFile>
    <ExportTypeSvgFile>Scalable Vector Graphics (SVG) файл...</ExportTypeSvgFile>
    <ExportTypeSvgzFile>Compressed SVG (SVGZ) файл...</ExportTypeSvgzFile>
    <ExportTypeSylkFile>Symbolic Link (SYLK) файл...</ExportTypeSylkFile>
    <ExportTypeTiffFile>TIFF рисунок...</ExportTypeTiffFile>
    <ExportTypeTxtFile>Text файл...</ExportTypeTxtFile>
    <ExportTypeWord2007File>Microsoft Word файл...</ExportTypeWord2007File>
    <ExportTypeWriterFile>OpenDocument Writer файл...</ExportTypeWriterFile>
    <ExportTypeXmlFile>XML файл...</ExportTypeXmlFile>
    <ExportTypeXpsFile>Microsoft XPS файл...</ExportTypeXpsFile>
    <GetCertificateFromCryptoUI>Брать сертификат из Crypto UI</GetCertificateFromCryptoUI>
    <ImageCompressionMethod>Метод сжатия изображения:</ImageCompressionMethod>
    <ImageCutEdges>Обрезать края</ImageCutEdges>
    <ImageFormat>Формат изображения:</ImageFormat>
    <ImageGrayscale>Серый</ImageGrayscale>
    <ImageMonochrome>Монохромный</ImageMonochrome>
    <ImageQuality>Качество изображения</ImageQuality>
    <ImageResolution>Разрешение изображения:</ImageResolution>
    <ImageResolutionMode>Режим разрешения:</ImageResolutionMode>
    <ImageType>Тип изображения</ImageType>
    <labelEncryptionKeyLength>Длина ключа шифрования:</labelEncryptionKeyLength>
    <labelOwnerPassword>Пароль владельца:</labelOwnerPassword>
    <labelSubjectNameString>Имя субъекта:</labelSubjectNameString>
    <labelUserPassword>Пароль пользователя:</labelUserPassword>
    <MonochromeDitheringType>Тип монохромного смешения:</MonochromeDitheringType>
    <MoreSettings>Дополнительные параметры</MoreSettings>
    <MultipleFiles>Несколько файлов</MultipleFiles>
    <NoMoreThan>Не больше чем</NoMoreThan>
    <OpenAfterExport>Открыть после экспорта</OpenAfterExport>
    <PdfACompliance>PDF/A соглашение</PdfACompliance>
    <RemoveEmptySpaceAtBottom>Убрать пустое место внизу страницы</RemoveEmptySpaceAtBottom>
    <RestrictEditing>Ограничение Редактирования:</RestrictEditing>
    <Scale>Масштаб:</Scale>
    <Separator>Разделитель:</Separator>
    <Settings>Настройки</Settings>
    <SkipColumnHeaders>Пропускать заголовки колонок</SkipColumnHeaders>
    <StandardPDFFonts>Стандартные PDF шрифты</StandardPDFFonts>
    <TiffCompressionScheme>Схема сжатия TIFF:</TiffCompressionScheme>
    <title>Параметры экспорта</title>
    <TxtBorderType>Тип границы</TxtBorderType>
    <TxtBorderTypeDouble>Unicode двойная</TxtBorderTypeDouble>
    <TxtBorderTypeSimple>Простая</TxtBorderTypeSimple>
    <TxtBorderTypeSingle>Unicode одинарная</TxtBorderTypeSingle>
    <TxtCutLongLines>Обрезать длинные линии</TxtCutLongLines>
    <TxtDrawBorder>Рисовать границу</TxtDrawBorder>
    <TxtKillSpaceGraphLines>Удалять пустые графические линии</TxtKillSpaceGraphLines>
    <TxtKillSpaceLines>Удалять пустые линии</TxtKillSpaceLines>
    <TxtPutFeedPageCode>Помечать конец страницы</TxtPutFeedPageCode>
    <Type>Тип:</Type>
    <UseDefaultSystemEncoding>Использовать кодировку по умолчанию</UseDefaultSystemEncoding>
    <UseDigitalSignature>Использовать цифровую подпись</UseDigitalSignature>
    <UseEscapeCodes>Использовать управляющие коды</UseEscapeCodes>
    <UseOnePageHeaderAndFooter>Один заголовок и итог страницы</UseOnePageHeaderAndFooter>
    <UsePageHeadersAndFooters>Использовать колонтитулы страницы</UsePageHeadersAndFooters>
    <UseUnicode>Использовать Unicode</UseUnicode>
    <X>X:</X>
    <Y>Y:</Y>
    <Zoom>Масштаб:</Zoom>
  </Export>
  <FileFilters>
    <AllFiles>Все файлы</AllFiles>
    <AllImageFiles>Все файлы изображений</AllImageFiles>
    <BitmapFiles>Двоичные изображения</BitmapFiles>
    <BmpFiles>BMP изображения (*.bmp)|*.bmp</BmpFiles>
    <BusinessIntelligence>Business Intelligence (*.sbi)|*.sbi</BusinessIntelligence>
    <CalcFiles>OpenDocument Calc файлы (*.ods)|*.ods</CalcFiles>
    <CsvFiles>CSV файлы (*.csv)|*.csv</CsvFiles>
    <DashboardTemplates>Шаблоны панелей индикаторов (*.sbi)|*.sbi</DashboardTemplates>
    <DataSetXmlData>DataSet XML данные (*.xml)|*.xml</DataSetXmlData>
    <DataSetXmlSchema>DataSet XML схема (*.xsd)|*.xsd</DataSetXmlSchema>
    <DbfFiles>DBF файл (*.dbf)|*.dbf</DbfFiles>
    <DictionaryFiles>Словари отчета (*.dct)|*.dct</DictionaryFiles>
    <DifFiles>DIF файлы (*.dif)|*.dif</DifFiles>
    <DllFiles>DLL файлы (*.dll)|*.dll</DllFiles>
    <DocumentFiles>Построенные отчеты (*.mdc)|*.mdc</DocumentFiles>
    <EmfFiles>Metafiles (*.emf)|*.emf</EmfFiles>
    <EncryptedDocumentFiles>Закодированные построенные отчеты (*.mdx)|*.mdx</EncryptedDocumentFiles>
    <EncryptedReportFiles>Закодированные шаблоны отчетов (*.mrx)|*.mrx</EncryptedReportFiles>
    <Excel2007Files>Microsoft Excel 2007/2013 файлы (*.xlsx)|*.xlsx</Excel2007Files>
    <ExcelAllFiles>Microsoft Excel файлы (*.xlsx;*.xls)|*.xlsx;*.xls</ExcelAllFiles>
    <ExcelFiles>Microsoft Excel файлы (*.xls)|*.xls</ExcelFiles>
    <ExcelXmlFiles>Microsoft Excel XML файлы (*.xls)|*.xls</ExcelXmlFiles>
    <ExeFiles>EXE файлы (*.exe)|*.exe</ExeFiles>
    <GifFiles>GIF изображения (*.gif)|*.gif</GifFiles>
    <HtmlFiles>HTML файлы (*.html)|*.html</HtmlFiles>
    <InheritedLanguageFiles>{0} классы для унаследованных отчетов (*.{1})|*.{2}</InheritedLanguageFiles>
    <JpegFiles>JPEG изображения (*.jpeg)|*.jpeg</JpegFiles>
    <JsonDocumentFiles>JSON построенные отчеты (*.mdc)|*.mdc</JsonDocumentFiles>
    <JsonFiles>JSON файлы (*.json)|*.json</JsonFiles>
    <JsonReportFiles>JSON шаблоны отчетов (*.mrt)|*.mrt</JsonReportFiles>
    <LanguageFiles>{0} классы (*.{1})|*.{2}</LanguageFiles>
    <LanguageForSilverlightFiles>{0} классы для Silverlight отчетов (*.{1})|*.{2}</LanguageForSilverlightFiles>
    <MetaFiles>Векторные изображения</MetaFiles>
    <MhtFiles>MHT Web архивы (*.mht)|*.mht</MhtFiles>
    <PackedDocumentFiles>Упакованные построенные отчеты (*.mdz)|*.mdz</PackedDocumentFiles>
    <PackedReportFiles>Упакованные шаблоны отчетов (*.mrz)|*.mrz</PackedReportFiles>
    <PageFiles>Страницы отчетов (*.pg)|*.pg</PageFiles>
    <PcxFiles>PCX изображения (*.pcx)|*.pcx</PcxFiles>
    <PdfFiles>Adobe PDF файлы (*.pdf)|*.pdf</PdfFiles>
    <PngFiles>PNG изображения (*.png)|*.png</PngFiles>
    <Ppt2007Files>Microsoft PowerPoint 2007/2013 файлы (*.pptx)|*.pptx</Ppt2007Files>
    <ReportFiles>Шаблоны отчетов (*.mrt)|*.mrt</ReportFiles>
    <RtfFiles>Текст в формате Rich Text (*.rtf)|*.rtf</RtfFiles>
    <StandaloneReportFiles>Автономные отчеты (*.exe)|*.exe</StandaloneReportFiles>
    <StylesFiles>Стили отчетов (*.sts)|*.sts</StylesFiles>
    <SvgFiles>SVG изображения (*.svg)|*.svg</SvgFiles>
    <SvgzFiles>Сжатые SVG изображения (*.svgz)|*.svgz</SvgzFiles>
    <SylkFiles>SYLK файлы (*.slk)|*.slk</SylkFiles>
    <TiffFiles>TIFF изображения (*.tiff)|*.tiff</TiffFiles>
    <TxtFiles>Обычный текст (*.txt)|*.txt</TxtFiles>
    <Word2007Files>Microsoft Word 2007/2013 файлы (*.docx)|*.docx</Word2007Files>
    <WriterFiles>OpenDocument Writer файлы (*.odt)|*.odt</WriterFiles>
    <XmlFiles>XML файлы(*.xml)|*.xml</XmlFiles>
    <XpsFiles>Microsoft XPS файлы (*.xps)|*.xps</XpsFiles>
    <ZipArchives>ZIP архивы (*.zip)|*.zip</ZipArchives>
  </FileFilters>
  <Formats>
    <custom01>d</custom01>
    <custom02>D</custom02>
    <custom03>f</custom03>
    <custom04>F</custom04>
    <custom05>dd/MM/yy</custom05>
    <custom06>dd/MM/yyyy</custom06>
    <custom07>G</custom07>
    <custom08>$0.00</custom08>
    <custom09>$0</custom09>
    <custom10>c</custom10>
    <custom11>c1</custom11>
    <custom12>c2</custom12>
    <custom13>#.00</custom13>
    <custom14>#,#</custom14>
    <custom15>n</custom15>
    <custom16>n1</custom16>
    <custom17>n2</custom17>
    <custom18>(###) ### - ####</custom18>
    <date01>*d</date01>
    <date02>*D</date02>
    <date03>dd.M</date03>
    <date04>dd.M.yy</date04>
    <date05>dd.MM.yy</date05>
    <date06>dd.MMM</date06>
    <date07>dd.MMM.yy</date07>
    <date08>yyyy, MMMM</date08>
    <date09>*f</date09>
    <date10>*F</date10>
    <date11>MM.dd.yyyy</date11>
    <date12>MM/dd/yyyy</date12>
    <time01>t</time01>
    <time02>T</time02>
    <time03>HH:mm</time03>
    <time04>H:mm</time04>
    <time06>HH:mm:ss</time06>
  </Formats>
  <FormBand>
    <AddFilter>&amp;Добавить фильтр</AddFilter>
    <AddGroup>&amp;Добавить группу</AddGroup>
    <AddResult>&amp;Добавить результат</AddResult>
    <AddSort>&amp;Добавить сортировку</AddSort>
    <And>и</And>
    <Ascending>По возрастанию</Ascending>
    <Descending>По убыванию</Descending>
    <NoFilters>Нет фильтров</NoFilters>
    <NoSort>Нет сортировки</NoSort>
    <RemoveFilter>&amp;Удалить фильтр</RemoveFilter>
    <RemoveGroup>&amp;Удалить группу</RemoveGroup>
    <RemoveResult>&amp;Удалить результат</RemoveResult>
    <RemoveSort>&amp;Удалить сортировку</RemoveSort>
    <SortBy>Сортировать по</SortBy>
    <ThenBy>Затем</ThenBy>
    <title>Настройка данных</title>
  </FormBand>
  <FormColorBoxPopup>
    <Color>Цвет</Color>
    <Custom>По выбору</Custom>
    <NoColor>Без цвета</NoColor>
    <Others>Другие</Others>
    <System>Система</System>
    <Web>Web</Web>
  </FormColorBoxPopup>
  <FormConditions>
    <AaBbCcYyZz>АаБбВвЯя</AaBbCcYyZz>
    <AddCondition>&amp;Добавить условие</AddCondition>
    <AddLevel>Добавить уровень</AddLevel>
    <AssignExpression>Присвоить выражение</AssignExpression>
    <BreakIfTrue>Прервать обработку</BreakIfTrue>
    <BreakIfTrueToolTip>Прекращать обработку условий если текущее выполняется</BreakIfTrueToolTip>
    <ChangeFont>Изменить шрифт</ChangeFont>
    <ComponentIsEnabled>Компонент включен</ComponentIsEnabled>
    <NoConditions>Нет условий</NoConditions>
    <RemoveCondition>&amp;Удалить условие</RemoveCondition>
    <SelectStyle>Выбрать стиль</SelectStyle>
    <title>Условия</title>
  </FormConditions>
  <FormCrossTabDesigner>
    <Columns>Колонки:</Columns>
    <DataSource>Источник Данных:</DataSource>
    <Properties>Свойства:</Properties>
    <Rows>Строки:</Rows>
    <Summary>Итоги:</Summary>
    <Swap>Обменять Колонки / Строки</Swap>
    <title>Редактор Кросс-Отчета</title>
  </FormCrossTabDesigner>
  <FormDatabaseEdit>
    <ConnectionString>Строка соединения:</ConnectionString>
    <DashboardConnections>Соединения панели индикаторов</DashboardConnections>
    <DB2Edit>Новое IBM DB2 соединение</DB2Edit>
    <DB2New>Изменение IBM DB2 соединения</DB2New>
    <EditConnection>Изменение {0} соединения</EditConnection>
    <FirebirdEdit>Изменение Firebird соединения</FirebirdEdit>
    <FirebirdNew>Новое Firebird соединение</FirebirdNew>
    <FirstRowIsHeader>Первая строка это заголовки</FirstRowIsHeader>
    <ImportData>Импорт данных</ImportData>
    <InformixEdit>Изменение Informix соединения</InformixEdit>
    <InformixNew>Новое Informix соединение</InformixNew>
    <InitialCatalog>Начальный каталог:</InitialCatalog>
    <JsonEdit>Изменение JSON данных</JsonEdit>
    <JsonNew>Новые JSON данные</JsonNew>
    <MySQLEdit>Изменение MySQL соединения</MySQLEdit>
    <MySQLNew>Новое MySQL соединение</MySQLNew>
    <NewConnection>Новое {0} соединение</NewConnection>
    <OdbcEdit>Изменение ODBC соединения</OdbcEdit>
    <OdbcNew>Новое ODBC соединение</OdbcNew>
    <OleDbEdit>Изменение OLE DB соединения</OleDbEdit>
    <OleDbNew>Новое OLE DB соединение</OleDbNew>
    <OracleEdit>Изменение Oracle соединения</OracleEdit>
    <OracleNew>Новое Oracle соединение</OracleNew>
    <OracleODPEdit>Изменение Oracle ODP.NET соединения</OracleODPEdit>
    <OracleODPNew>Новое Oracle ODP.NET соединение</OracleODPNew>
    <PathData>Путь к XML данным:</PathData>
    <PathJsonData>Путь к JSON данным:</PathJsonData>
    <PathSchema>Путь к XSD схеме:</PathSchema>
    <PathToData>Путь к данным:</PathToData>
    <Pin>Выбрать</Pin>
    <PostgreSQLEdit>Изменение PostgreSQL соединения</PostgreSQLEdit>
    <PostgreSQLNew>Новое PostgreSQL соединение</PostgreSQLNew>
    <PromptUserNameAndPassword>Запрос имени пользователя и пароля</PromptUserNameAndPassword>
    <RecentConnections>Последние</RecentConnections>
    <ReportConnections>Соединения отчета</ReportConnections>
    <SqlCeEdit>Редактировать SQLServerCE соединение</SqlCeEdit>
    <SqlCeNew>Новое SQLServerCE соединение</SqlCeNew>
    <SqlEdit>Изменение SQL соединения</SqlEdit>
    <SQLiteEdit>Изменение SQLite соединения</SQLiteEdit>
    <SQLiteNew>Новое SQLite соединение</SQLiteNew>
    <SqlNew>Новое SQL соединение</SqlNew>
    <TeradataEdit>Изменение Teradata соединения</TeradataEdit>
    <TeradataNew>Новое Teradata соединение</TeradataNew>
    <UniDirectEdit>Изменение Uni Direct соединения</UniDirectEdit>
    <UniDirectNew>Новое Uni Direct соединение</UniDirectNew>
    <Unpin>Убрать выбор</Unpin>
    <UseBearerAuthentication>Использовать Bearer аутентификацию</UseBearerAuthentication>
    <VistaDBEdit>Изменение VistaDB соединения</VistaDBEdit>
    <VistaDBNew>Новое VistaDB соединение</VistaDBNew>
    <XmlEdit>Изменение XML данных</XmlEdit>
    <XmlNew>Новые XML данные</XmlNew>
    <XmlType>Тип XML:</XmlType>
  </FormDatabaseEdit>
  <FormDesigner>
    <Code>Код</Code>
    <ColumnsOne>Одна</ColumnsOne>
    <ColumnsThree>Три</ColumnsThree>
    <ColumnsTwo>Две</ColumnsTwo>
    <CompilingReport>Компиляция отчета</CompilingReport>
    <DockingPanels>Панели</DockingPanels>
    <HtmlPreview>HTML просмотр</HtmlPreview>
    <JsPreview>JS просмотр</JsPreview>
    <labelPleaseSelectTypeOfInterface>Пожалуйста, выберите тип интерфейса</labelPleaseSelectTypeOfInterface>
    <LoadImage>Загрузить изображение...</LoadImage>
    <LocalizePropertyGrid>Переводить имена свойств</LocalizePropertyGrid>
    <MarginsNarrow>Узкие</MarginsNarrow>
    <MarginsNormal>Нормальные</MarginsNormal>
    <MarginsWide>Широкие</MarginsWide>
    <OrderToolbars>Упорядочить панели инструментов</OrderToolbars>
    <Others>Другие</Others>
    <Pages>Страницы</Pages>
    <Preview>Предварительный просмотр</Preview>
    <PropertyChange>Изменение свойства '{0}'</PropertyChange>
    <RTPreview>WinRT просмотр</RTPreview>
    <SetupToolbox>Настройка инструментария</SetupToolbox>
    <ShowDescription>Показывать описание</ShowDescription>
    <SLPreview>Silverlight просмотр</SLPreview>
    <title>Дизайнер</title>
    <WebPreview>Flash просмотр</WebPreview>
  </FormDesigner>
  <FormDictionaryDesigner>
    <Actions>Действия</Actions>
    <AutoSort>Автосортировка</AutoSort>
    <BusinessObjectEdit>Изменение бизнес-объекта</BusinessObjectEdit>
    <CalcColumnEdit>Изменение рассчитываемой колонки</CalcColumnEdit>
    <CalcColumnNew>Новая рассчитываемая колонка</CalcColumnNew>
    <CategoryEdit>Изменение категории</CategoryEdit>
    <CategoryNew>Новая категория</CategoryNew>
    <ChildOfBusinessObject>Подчиненный бизнес-объект</ChildOfBusinessObject>
    <ChildSource>Подчиненный источник данных:</ChildSource>
    <ClickHere>Нажмите здесь</ClickHere>
    <ColumnEdit>Изменение колонки</ColumnEdit>
    <ColumnNew>Новая колонка</ColumnNew>
    <CreateNewDataSource>чтобы создать новый источник данных</CreateNewDataSource>
    <CreateNewReport>для создания нового источника данных</CreateNewReport>
    <CsvSeparatorComma>Запятая</CsvSeparatorComma>
    <CsvSeparatorOther>Другой</CsvSeparatorOther>
    <CsvSeparatorSemicolon>Точка с запятой</CsvSeparatorSemicolon>
    <CsvSeparatorSpace>Пробел</CsvSeparatorSpace>
    <CsvSeparatorSystem>Системный разделитель</CsvSeparatorSystem>
    <CsvSeparatorTab>Табуляция</CsvSeparatorTab>
    <DatabaseEdit>Изменение базы данных</DatabaseEdit>
    <DatabaseNew>Новая база данных</DatabaseNew>
    <DataParameterEdit>Редактировать параметр</DataParameterEdit>
    <DataParameterNew>Новый параметр</DataParameterNew>
    <DataSetToBusinessObjects>DataSet в Business Objects</DataSetToBusinessObjects>
    <DataSourceEdit>Изменение источника данных</DataSourceEdit>
    <DataSourceNew>Новый источник данных</DataSourceNew>
    <DataSourcesNew>Новые источники данных</DataSourcesNew>
    <Delete>Удалить</Delete>
    <DesignTimeQueryText>Текст запроса для дизайнера</DesignTimeQueryText>
    <DictionaryMerge>Добавить словарь...</DictionaryMerge>
    <DictionaryNew>Новый словарь...</DictionaryNew>
    <DictionaryOpen>Открыть словарь...</DictionaryOpen>
    <DictionarySaveAs>Сохранить словарь как...</DictionarySaveAs>
    <DragNewDataSource>перетащите данные на эту панель</DragNewDataSource>
    <DragNewReport>перетащите отчет на эту панель</DragNewReport>
    <EditQuery>Изменить запрос</EditQuery>
    <ExecutedSQLStatementSuccessfully>Запрос SQL выполнен успешно</ExecutedSQLStatementSuccessfully>
    <ExpressionNew>Новое выражение</ExpressionNew>
    <GetColumnsFromAssembly>Взять колонки из сборки</GetColumnsFromAssembly>
    <ImportRelations>Импортировать связи</ImportRelations>
    <LabelSeparator>Разделитель:</LabelSeparator>
    <MarkUsedItems>Пометить используемые элементы</MarkUsedItems>
    <NewBusinessObject>Новый бизнес-объект</NewBusinessObject>
    <NewItem>Новый элемент</NewItem>
    <OpenAssembly>Открыть сборку</OpenAssembly>
    <ParentSource>Главный источник данных:</ParentSource>
    <Queries>Запросы</Queries>
    <QueryNew>Новый запрос</QueryNew>
    <QueryText>Текст запроса</QueryText>
    <QueryTimeout>Время ожидания запроса</QueryTimeout>
    <RelationEdit>Изменение связи</RelationEdit>
    <RelationNew>Новая связь</RelationNew>
    <ResourceEdit>Изменение ресурса</ResourceEdit>
    <ResourceNew>Новый ресурс</ResourceNew>
    <RetrieveColumns>Получить все колонки</RetrieveColumns>
    <RetrieveColumnsAllowRun>Разрешить запуск хранимой процедуры</RetrieveColumnsAllowRun>
    <RetrieveColumnsAndParameters>Получить колонки и параметры</RetrieveColumnsAndParameters>
    <RetrieveParameters>Получить параметры</RetrieveParameters>
    <RetrievingDatabaseInformation>Получение информации из базы данных...</RetrievingDatabaseInformation>
    <Run>Выполнить</Run>
    <SelectTypeOfBusinessObject>Выберите тип бизнес-объекта</SelectTypeOfBusinessObject>
    <SkipSchemaWizard>Пропустить мастер схемы</SkipSchemaWizard>
    <SortItems>Сортировать элементы</SortItems>
    <Synchronize>Синхронизировать</Synchronize>
    <SynchronizeHint>Синхронизирует содержимое Хранилища Данных и содержимое Словаря</SynchronizeHint>
    <TextDragDropFileHere>Перетяните сюда файл</TextDragDropFileHere>
    <TextDragDropImageHere>Перетяните сюда файл изображения</TextDragDropImageHere>
    <TextDropDataFileHere>Перетяните сюда файл данных</TextDropDataFileHere>
    <TextDropFileHere>Перетащите файл сюда</TextDropFileHere>
    <TextDropImageHere>Перетащите файл изображения сюда</TextDropImageHere>
    <title>Дизайнер словаря</title>
    <ValueNew>Новое значение</ValueNew>
    <VariableEdit>Изменение переменной</VariableEdit>
    <VariableNew>Новая переменная</VariableNew>
    <ViewData>Просмотр данных</ViewData>
    <ViewQuery>Просмотреть результаты запроса</ViewQuery>
  </FormDictionaryDesigner>
  <FormFormatEditor>
    <Boolean>Булевский</Boolean>
    <BooleanDisplay>Представление:</BooleanDisplay>
    <BooleanValue>Значение:</BooleanValue>
    <Currency>Денежный</Currency>
    <CurrencySymbol>Символ валюты:</CurrencySymbol>
    <Custom>По выбору</Custom>
    <Date>Дата</Date>
    <DateTimeFormat>Формат даты и времени</DateTimeFormat>
    <DecimalDigits>Знаков дробной части:</DecimalDigits>
    <DecimalSeparator>Разд. дробной части:</DecimalSeparator>
    <FormatMask>Маска</FormatMask>
    <Formats>Форматы</Formats>
    <General>Стандарт</General>
    <GroupSeparator>Разделитель группы:</GroupSeparator>
    <GroupSize>Размер группы:</GroupSize>
    <nameFalse>Ложь</nameFalse>
    <nameNo>Нет</nameNo>
    <nameOff>Выключено</nameOff>
    <nameOn>Включено</nameOn>
    <nameTrue>Истина</nameTrue>
    <nameYes>Да</nameYes>
    <NegativePattern>Отрицательный шаблон:</NegativePattern>
    <Number>Числовой</Number>
    <Percentage>Процентный</Percentage>
    <PercentageSymbol>Символ процента:</PercentageSymbol>
    <PositivePattern>Положительный шаблон:</PositivePattern>
    <Properties>Свойства</Properties>
    <Sample>Пример</Sample>
    <SampleText>текст</SampleText>
    <TextFormat>Формат текста</TextFormat>
    <Time>Время</Time>
    <title>Формат</title>
    <UseGroupSeparator>Разделитель групп разрядов</UseGroupSeparator>
    <UseLocalSetting>Региональные настройки</UseLocalSetting>
  </FormFormatEditor>
  <FormGlobalizationEditor>
    <AddCulture>&amp;Добавить культуру</AddCulture>
    <AutoLocalizeReportOnRun>Автоперевод отчета при старте</AutoLocalizeReportOnRun>
    <GetCulture>Взять настройки культуры из отчета</GetCulture>
    <qnGetCulture>Вы действительно хотите взять настройки культуры из отчета и стереть?</qnGetCulture>
    <qnSetCulture>Вы действительно хотите установить настройки культуры в компоненты отчета?</qnSetCulture>
    <RemoveCulture>&amp;Удалить культуру</RemoveCulture>
    <SetCulture>Установить настройки культуры в отчет</SetCulture>
    <title>Редактор культур</title>
  </FormGlobalizationEditor>
  <FormInteraction>
    <HyperlinkExternalDocuments>Гиперссылка к внешнему документу</HyperlinkExternalDocuments>
    <HyperlinkUsingInteractionBookmark>Гиперссылка с закладкой</HyperlinkUsingInteractionBookmark>
    <HyperlinkUsingInteractionTag>Гиперссылка с тэгом</HyperlinkUsingInteractionTag>
  </FormInteraction>
  <FormOptions>
    <AutoSave>Автосохранение</AutoSave>
    <AutoSaveReportToReportClass>Автоматически сохранять файл C# или VB.NET</AutoSaveReportToReportClass>
    <Default>По умолчанию</Default>
    <Drawing>Отображение</Drawing>
    <DrawMarkersWhenMoving>Рисовать маркеры компонентов при передвижении</DrawMarkersWhenMoving>
    <EditAfterInsert>Редактировать после создания</EditAfterInsert>
    <EnableAutoSaveMode>Включить режим автосохранения</EnableAutoSaveMode>
    <FillBands>Заполнять секции</FillBands>
    <FillComponents>Заполнять компоненты</FillComponents>
    <FillContainers>Заполнять контейнеры</FillContainers>
    <FillCrossBands>Заполнять кросс-секции</FillCrossBands>
    <GenerateLocalizedName>Использовать локализованные имена</GenerateLocalizedName>
    <Grid>Сетка</Grid>
    <GridDots>Точки</GridDots>
    <GridLines>Линии</GridLines>
    <GridMode>Режим  отображения сетки</GridMode>
    <GridSize>Шаг сетки</GridSize>
    <groupAutoSaveOptions>Параметры автосохранения</groupAutoSaveOptions>
    <groupColorScheme>Пожалуйста, выберите цветовую схему интерфейса</groupColorScheme>
    <groupGridDrawingOptions>Параметры отрисовки сетки</groupGridDrawingOptions>
    <groupGridOptions>Настройка сетки</groupGridOptions>
    <groupGridSize>Размер сетки</groupGridSize>
    <groupMainOptions>Главные параметры дизайнера отчетов</groupMainOptions>
    <groupMarkersStyle>Стиль маркеров</groupMarkersStyle>
    <groupOptionsOfQuickInfo>Свойства быстрой информации</groupOptionsOfQuickInfo>
    <groupPleaseSelectTypeOfGui>Пожалуйста, выберите тип GUI</groupPleaseSelectTypeOfGui>
    <groupReportDisplayOptions>Опции отображения отчета</groupReportDisplayOptions>
    <labelColorScheme>Цветовая схема:</labelColorScheme>
    <labelInfoAutoSave>Изменение параметров автосохранения отчета</labelInfoAutoSave>
    <labelInfoDrawing>Настройка параметров отрисовки</labelInfoDrawing>
    <labelInfoGrid>Как сетка используется и отрисовывается в отчете</labelInfoGrid>
    <labelInfoGui>Выберите тип GUI дизайнера отчетов</labelInfoGui>
    <labelInfoMain>Установка базовых параметров дизайнера отчетов</labelInfoMain>
    <labelInfoQuickInfo>Быстрая Информация на странице</labelInfoQuickInfo>
    <Main>Главные</Main>
    <MarkersStyle>Стиль маркеров компонентов</MarkersStyle>
    <MarkersStyleCorners>Углы</MarkersStyleCorners>
    <MarkersStyleDashedRectangle>Прямоугольник</MarkersStyleDashedRectangle>
    <MarkersStyleNone>Нет</MarkersStyleNone>
    <MessageLeftRightNotValid>Левое и правое значения неверны!</MessageLeftRightNotValid>
    <MessageTopBottomNotValid>Верхнее и нижнее значения неверны!</MessageTopBottomNotValid>
    <Minutes>{0} минут</Minutes>
    <SaveReportEvery>Сохранять отчет каждые</SaveReportEvery>
    <SelectUILanguage>Выберите язык интерфейса</SelectUILanguage>
    <ShowDimensionLines>Показывать размерные линии</ShowDimensionLines>
    <title>Параметры</title>
    <UseComponentColor>Использовать цвета компонента для заполнения</UseComponentColor>
    <UseLastFormat>Использовать последнее форматирование</UseLastFormat>
  </FormOptions>
  <FormPageSetup>
    <ApplyTo>Применять к</ApplyTo>
    <Bottom>Снизу:</Bottom>
    <Columns>Колонки</Columns>
    <groupColumns>Колонки страницы</groupColumns>
    <groupImage>Изображение водяного знака</groupImage>
    <groupMargins>Поля страницы</groupMargins>
    <groupOrientation>Ориентация страницы</groupOrientation>
    <groupPaper>Размер страницы</groupPaper>
    <groupPaperSource>Источник страницы</groupPaperSource>
    <groupText>Текст водяного знака</groupText>
    <Height>Высота:</Height>
    <labelAngle>Угол:</labelAngle>
    <labelColumnGaps>Промежуток между колонками:</labelColumnGaps>
    <labelColumnWidth>Ширина одной колонки:</labelColumnWidth>
    <labelImageAlignment>Выравнивание изображения:</labelImageAlignment>
    <labelImageTransparency>Прозрачность изображения:</labelImageTransparency>
    <labelInfoColumns>Настройка колонок страницы</labelInfoColumns>
    <labelInfoPaper>Изменение размеров и ориентации текущей страницы</labelInfoPaper>
    <labelInfoUnit>Поля страницы указываются в текущих единицах измерения отчета</labelInfoUnit>
    <labelInfoWatermark>Настройка параметров вывода водяного знака</labelInfoWatermark>
    <labelMultipleFactor>Масштаб:</labelMultipleFactor>
    <labelPaperSourceOfFirstPage>Источник бумаги для первой страницы:</labelPaperSourceOfFirstPage>
    <labelPaperSourceOfOtherPages>Источник бумаги для второй страницы:</labelPaperSourceOfOtherPages>
    <labelSelectBrush>Выбор кисти:</labelSelectBrush>
    <labelSelectColor>Выбор цвета:</labelSelectColor>
    <labelSelectFont>Выбор шрифта:</labelSelectFont>
    <labelSelectImage>Выбор изображения:</labelSelectImage>
    <labelText>Текст:</labelText>
    <Left>Слева:</Left>
    <Margins>Поля</Margins>
    <NumberOfColumns>Количество колонок:</NumberOfColumns>
    <Orientation>Ориентация</Orientation>
    <PageOrientationLandscape>Альбомная</PageOrientationLandscape>
    <PageOrientationPortrait>Портретная</PageOrientationPortrait>
    <Paper>Бумага:</Paper>
    <RebuildReport>Перестроить отчет</RebuildReport>
    <Right>Справа:</Right>
    <ScaleContent>Масштабировать содержимое</ScaleContent>
    <Size>Размер:</Size>
    <title>Свойства страницы</title>
    <Top>Сверху:</Top>
    <Width>Ширина:</Width>
  </FormPageSetup>
  <FormReportSetup>
    <groupDates>Дата создания и дата последнего изменения отчета</groupDates>
    <groupDescription>Описание отчета</groupDescription>
    <groupMainParameters>Параметры, влияющие на построение отчета</groupMainParameters>
    <groupNames>Наименование, псевдоним и автор отчета</groupNames>
    <groupScript>Скриптовый язык Вашего отчета</groupScript>
    <groupUnits>В указанных единицах будут измеряться все размеры и координаты в отчете</groupUnits>
    <labelInfoDescription>Информация об отчете</labelInfoDescription>
    <labelInfoMain>Изменение базовых параметров отчета</labelInfoMain>
    <labelNumberOfPass>Количество проходов в отчете:</labelNumberOfPass>
    <labelReportCacheMode>Режим кэширования отчета:</labelReportCacheMode>
    <ReportChanged>Дата изменения отчета:</ReportChanged>
    <ReportCreated>Дата создания отчета:</ReportCreated>
    <title>Свойства отчета</title>
  </FormReportSetup>
  <FormRichTextEditor>
    <Bullets>Маркеры</Bullets>
    <FontName>Шрифт</FontName>
    <FontSize>Размер шрифта</FontSize>
    <Insert>Вставить выражение</Insert>
    <title>Rich-текст редактор</title>
  </FormRichTextEditor>
  <FormStyleDesigner>
    <Add>Добавить новый стиль</Add>
    <ApplyStyleCollectionToReportComponents>Применить коллекцию стилей к компонентам отчета</ApplyStyleCollectionToReportComponents>
    <ApplyStyles>Применить стили</ApplyStyles>
    <ColorCollectionEditor>Редактор коллекции цветов</ColorCollectionEditor>
    <CreateStyleCollection>Создать коллекцию стилей</CreateStyleCollection>
    <CreateStylesCollection>Создать коллекцию стилей</CreateStylesCollection>
    <CreateStylesFromColor>Создать стиль из выбранного цвета</CreateStylesFromColor>
    <Duplicate>Копировать стиль</Duplicate>
    <GetStyle>Создать стиль на основе выделенных компонентов</GetStyle>
    <MoreStyles>Больше стилей</MoreStyles>
    <Open>Открыть стили</Open>
    <qnApplyStyleCollection>Вы действительно хотите применить коллекцию стилей к компонентам отчета?</qnApplyStyleCollection>
    <Remove>Удалить стиль</Remove>
    <RemoveExistingStyles>Удалить текущие стили</RemoveExistingStyles>
    <Save>Сохранить стили</Save>
    <Style>Стиль</Style>
    <StyleCollectionsNotFound>Коллекции стилей не найдены!</StyleCollectionsNotFound>
    <title>Редактор стилей</title>
  </FormStyleDesigner>
  <FormSystemTextEditor>
    <Condition>Условие</Condition>
    <LabelDataBand>Данные:</LabelDataBand>
    <LabelDataColumn>Колонка:</LabelDataColumn>
    <LabelShowInsteadNullValues>Показывать вместо нулевых значений:</LabelShowInsteadNullValues>
    <LabelSummaryFunction>Функция:</LabelSummaryFunction>
    <pageExpression>Выражение</pageExpression>
    <pageSummary>Итог</pageSummary>
    <pageSystemVariable>Системная переменная</pageSystemVariable>
    <RunningTotal>Нарастающий итог</RunningTotal>
    <SummaryRunning>Диапазон расчета</SummaryRunning>
    <SummaryRunningByColumn>Колонка</SummaryRunningByColumn>
    <SummaryRunningByPage>Страница</SummaryRunningByPage>
    <SummaryRunningByReport>Отчет</SummaryRunningByReport>
  </FormSystemTextEditor>
  <FormTitles>
    <ChartWizardForm>Мастер диаграмм</ChartWizardForm>
    <ConditionEditorForm>Условие</ConditionEditorForm>
    <ConnectionSelectForm>Выберите тип соединения</ConnectionSelectForm>
    <ContainerSelectForm>Выберите контейнер</ContainerSelectForm>
    <DataAdapterServiceSelectForm>Выберите тип данных</DataAdapterServiceSelectForm>
    <DataRelationSelectForm>Выберите связь</DataRelationSelectForm>
    <DataSetName>Введите наименование DataSet</DataSetName>
    <DataSourceSelectForm>Выберите источник данных</DataSourceSelectForm>
    <DataSourcesNewForm>Новые источники данных</DataSourcesNewForm>
    <DataStoreViewerForm>Хранилище данных</DataStoreViewerForm>
    <DesignerApplication>Дизайнер отчетов</DesignerApplication>
    <EventEditorForm>Редактор событий</EventEditorForm>
    <ExpressionEditorForm>Редактор выражений</ExpressionEditorForm>
    <GroupConditionForm>Группа</GroupConditionForm>
    <InteractionDrillDownPageSelectForm>Выбрать страницу детализации</InteractionDrillDownPageSelectForm>
    <MasterComponentSelectForm>Выберите мастер компонент</MasterComponentSelectForm>
    <PageAddForm>Добавление страницы</PageAddForm>
    <PageSizeForm>Размер страницы</PageSizeForm>
    <PagesManagerForm>Менеджер страниц</PagesManagerForm>
    <PromptForm>Введите информацию для подключения к базе данных</PromptForm>
    <ReportWizard>Мастер отчетов</ReportWizard>
    <ServiceSelectForm>Выберите сервисы</ServiceSelectForm>
    <SqlExpressionsForm>SQL выражения</SqlExpressionsForm>
    <SubReportPageSelectForm>Выберите страницу суб-отчета</SubReportPageSelectForm>
    <TextEditorForm>Редактор текста</TextEditorForm>
    <ViewDataForm>Просмотр данных</ViewDataForm>
    <ViewerApplication>Просмотр отчетов</ViewerApplication>
  </FormTitles>
  <FormViewer>
    <Bookmarks>Закладки</Bookmarks>
    <Close>Закрыть</Close>
    <CollapseAll>Свернуть все</CollapseAll>
    <CompressedDocumentFile>Упакованный файл документа</CompressedDocumentFile>
    <ContextMenu>Контекстное меню</ContextMenu>
    <DocumentFile>Файл документа...</DocumentFile>
    <Editor>Редактор</Editor>
    <EncryptedDocumentFile>Зашифрованный файл документа</EncryptedDocumentFile>
    <ExpandAll>Развернуть все</ExpandAll>
    <Export>Экспорт...</Export>
    <Find>Поиск...</Find>
    <FirstPage>Первая страница</FirstPage>
    <FullScreen>Во весь экран</FullScreen>
    <GoToPage>Перейти на страницу</GoToPage>
    <HorScrollBar>Горизонтальная полоса прокрутки</HorScrollBar>
    <LabelPageN>Страница:</LabelPageN>
    <LastPage>Последняя страница</LastPage>
    <NextPage>Следующая страница</NextPage>
    <Open>Открыть</Open>
    <PageControl>Управление страницей</PageControl>
    <PageDelete>Удалить страницу</PageDelete>
    <PageDesign>Редактировать страницу...</PageDesign>
    <PageNew>Новая страница</PageNew>
    <PageNofM>Страница {0} из {1}</PageNofM>
    <PageofM>из {0}</PageofM>
    <PageSize>Размер страницы...</PageSize>
    <PageViewModeContinuous>Непрерывный</PageViewModeContinuous>
    <PageViewModeMultiplePages>Несколько страниц</PageViewModeMultiplePages>
    <PageViewModeSinglePage>Одна страница</PageViewModeSinglePage>
    <Parameters>Параметры</Parameters>
    <PrevPage>Предыдущая страница</PrevPage>
    <Print>Печать</Print>
    <qnPageDelete>Удалить текущую страницу?</qnPageDelete>
    <Save>Сохранить</Save>
    <SendEMail>Отправить по Email...</SendEMail>
    <StatusBar>Статусная панель</StatusBar>
    <Thumbnails>Эскизы</Thumbnails>
    <title>Предварительный просмотр</title>
    <titlePageSettings>Настройка страницы</titlePageSettings>
    <Toolbar>Панель инструментов</Toolbar>
    <VerScrollBar>Вертикальная полоса прокрутки</VerScrollBar>
    <ViewMode>Режим отображения</ViewMode>
    <Zoom>Масштаб</Zoom>
    <ZoomMultiplePages>Несколько страниц</ZoomMultiplePages>
    <ZoomOnePage>Одна страница</ZoomOnePage>
    <ZoomPageWidth>По ширине страницы</ZoomPageWidth>
    <ZoomTwoPages>Две страницы</ZoomTwoPages>
    <ZoomXXPages>{0} X {1} страниц</ZoomXXPages>
    <ZoomXXPagesCancel>Отмена</ZoomXXPagesCancel>
  </FormViewer>
  <FormViewerFind>
    <Close>Закрыть</Close>
    <FindNext>Найти следующий</FindNext>
    <FindPrevious>Найти предыдущий</FindPrevious>
    <FindWhat>Найти:</FindWhat>
  </FormViewerFind>
  <Gauge>
    <AddNewItem>Добавить новый элемент</AddNewItem>
    <BarRangeList>Панель диапазонного ряда</BarRangeList>
    <GaugeEditorForm>Редактор измерительного прибора</GaugeEditorForm>
    <Kind>Вид</Kind>
    <LinearBar>Линейная панель</LinearBar>
    <LinearMarker>Линейный маркер</LinearMarker>
    <LinearRange>Линейный диапазон</LinearRange>
    <LinearRangeList>Линейный диапазонный ряд</LinearRangeList>
    <LinearScale>Линейная шкала</LinearScale>
    <LinearTickLabelCustom>Заголовки пользовательских линейных меток</LinearTickLabelCustom>
    <LinearTickLabelMajor>Заголовки основных линейных меток</LinearTickLabelMajor>
    <LinearTickLabelMinor>Заголовки промежуточных линейных меток</LinearTickLabelMinor>
    <LinearTickMarkCustom>Пользовательские линейные метки</LinearTickMarkCustom>
    <LinearTickMarkMajor>Основные линейные метки</LinearTickMarkMajor>
    <LinearTickMarkMinor>Промежуточные линейные метки</LinearTickMarkMinor>
    <Needle>Стрелка</Needle>
    <RadialBar>Радиальная панель</RadialBar>
    <RadialMarker>Радиальный маркер</RadialMarker>
    <RadialRange>Радиальный диапазон</RadialRange>
    <RadialRangeList>Радиальный диапазонный ряд</RadialRangeList>
    <RadialScale>Радиальная шкала</RadialScale>
    <RadialTickLabelCustom>Заголовки пользовательских радиальных меток</RadialTickLabelCustom>
    <RadialTickLabelMajor>Заголовки основных радиальных меток</RadialTickLabelMajor>
    <RadialTickLabelMinor>Заголовки промежуточных радиальных меток</RadialTickLabelMinor>
    <RadialTickMarkCustom>Пользовательские радиальные метки</RadialTickMarkCustom>
    <RadialTickMarkMajor>Основные радиальные метки</RadialTickMarkMajor>
    <RadialTickMarkMinor>Промежуточные радиальные метки</RadialTickMarkMinor>
    <StateIndicator>Индикатор состояния</StateIndicator>
    <StateIndicatorFilter>Фильтр состояния индикатораr</StateIndicatorFilter>
    <TickCustomValue>Метка значения</TickCustomValue>
  </Gauge>
  <Gui>
    <barname_cancel>Отмена</barname_cancel>
    <barname_caption>Новая панель инструментов</barname_caption>
    <barname_msginvalidname>Наименование панели инструментов не может быть пустым.</barname_msginvalidname>
    <barname_name>Наименование панели инструментов:</barname_name>
    <barname_ok>ОК</barname_ok>
    <barrename_caption>Переименовать панель инструментов</barrename_caption>
    <barsys_autohide_tooltip>Прятать автоматически</barsys_autohide_tooltip>
    <barsys_close_tooltip>Закрыть</barsys_close_tooltip>
    <barsys_customize_tooltip>Настройка</barsys_customize_tooltip>
    <colorpicker_morecolors>&amp;Другие цвета...</colorpicker_morecolors>
    <colorpicker_nofill>Нет заливки</colorpicker_nofill>
    <colorpicker_standardcolorslabel>Стандартные цвета</colorpicker_standardcolorslabel>
    <colorpicker_themecolorslabel>Цвета темы</colorpicker_themecolorslabel>
    <colorpickerdialog_bluelabel>&amp;Синий:</colorpickerdialog_bluelabel>
    <colorpickerdialog_cancelbutton>Отмена</colorpickerdialog_cancelbutton>
    <colorpickerdialog_caption>Цвета</colorpickerdialog_caption>
    <colorpickerdialog_colormodellabel>Цвет:</colorpickerdialog_colormodellabel>
    <colorpickerdialog_currentcolorlabel>Текущий</colorpickerdialog_currentcolorlabel>
    <colorpickerdialog_customcolorslabel>Цвета:</colorpickerdialog_customcolorslabel>
    <colorpickerdialog_greenlabel>&amp;Зеленый:</colorpickerdialog_greenlabel>
    <colorpickerdialog_newcolorlabel>Новый</colorpickerdialog_newcolorlabel>
    <colorpickerdialog_okbutton>ОК</colorpickerdialog_okbutton>
    <colorpickerdialog_redlabel>&amp;Красный:</colorpickerdialog_redlabel>
    <colorpickerdialog_rgblabel>RGB</colorpickerdialog_rgblabel>
    <colorpickerdialog_standardcolorslabel>Цвета:</colorpickerdialog_standardcolorslabel>
    <colorpickerdialog_tabcustom>По выбору</colorpickerdialog_tabcustom>
    <colorpickerdialog_tabstandard>Стандарт</colorpickerdialog_tabstandard>
    <cust_btn_close>Закрыть</cust_btn_close>
    <cust_btn_delete>Удалить</cust_btn_delete>
    <cust_btn_keyboard>К&amp;лавиатура...</cust_btn_keyboard>
    <cust_btn_new>Со&amp;здать...</cust_btn_new>
    <cust_btn_rename>Переименовать...</cust_btn_rename>
    <cust_btn_reset>С&amp;брос...</cust_btn_reset>
    <cust_btn_resetusage>С&amp;брос</cust_btn_resetusage>
    <cust_caption>Настройка</cust_caption>
    <cust_cbo_fade>Угасание</cust_cbo_fade>
    <cust_cbo_none>(Нет)</cust_cbo_none>
    <cust_cbo_random>Случайный выбор</cust_cbo_random>
    <cust_cbo_slide>Соскальзывание</cust_cbo_slide>
    <cust_cbo_system>По умолчанию</cust_cbo_system>
    <cust_cbo_unfold>Развертывание</cust_cbo_unfold>
    <cust_chk_delay>Показывать &amp;полные меню после короткой задержки</cust_chk_delay>
    <cust_chk_fullmenus>Всегда показывать полные &amp;меню</cust_chk_fullmenus>
    <cust_chk_showsk>Включить в &amp;подсказки сочетания клавиш</cust_chk_showsk>
    <cust_chk_showst>&amp;Отображать подсказки для кнопок</cust_chk_showst>
    <cust_lbl_cats>Кате&amp;гории:</cust_lbl_cats>
    <cust_lbl_cmds>Ко&amp;манды:</cust_lbl_cmds>
    <cust_lbl_cmdsins>Чтобы добавить команду на панель инструментов, выберите категорию и перетащите из этого окна на панель.</cust_lbl_cmdsins>
    <cust_lbl_menuan>Эффект при выводе меню:</cust_lbl_menuan>
    <cust_lbl_other>Другие:</cust_lbl_other>
    <cust_lbl_pmt>Настраиваемые меню и панели инструментов</cust_lbl_pmt>
    <cust_lbl_tlbs>Па&amp;нели инструментов:</cust_lbl_tlbs>
    <cust_mnu_addremove>Добавить или удалить кнопки</cust_mnu_addremove>
    <cust_mnu_cust>Настройка...</cust_mnu_cust>
    <cust_mnu_reset>С&amp;брос</cust_mnu_reset>
    <cust_mnu_tooltip>Свойства панели</cust_mnu_tooltip>
    <cust_msg_delete>Вы точно хотите удалить панель инструментов &lt;barname&gt;?</cust_msg_delete>
    <cust_pm_begingroup>Начало группы</cust_pm_begingroup>
    <cust_pm_delete>Удалить</cust_pm_delete>
    <cust_pm_name>Наименование:</cust_pm_name>
    <cust_pm_reset>Сброс</cust_pm_reset>
    <cust_pm_stydef>Стиль по умолчанию</cust_pm_stydef>
    <cust_pm_styimagetext>Изображения и текст (Всегда)</cust_pm_styimagetext>
    <cust_pm_stytextonly>Только текст (Всегда)</cust_pm_stytextonly>
    <cust_tab_commands>Команды</cust_tab_commands>
    <cust_tab_options>Свойства</cust_tab_options>
    <cust_tab_toolbars>Панели инструментов</cust_tab_toolbars>
    <mdisysmenu_close>Закрыть</mdisysmenu_close>
    <mdisysmenu_maximize>Максимизировать</mdisysmenu_maximize>
    <mdisysmenu_minimize>Минимизировать</mdisysmenu_minimize>
    <mdisysmenu_move>Переместить</mdisysmenu_move>
    <mdisysmenu_next>Следующий</mdisysmenu_next>
    <mdisysmenu_restore>Восстановить</mdisysmenu_restore>
    <mdisysmenu_size>Размер</mdisysmenu_size>
    <mdisystt_close>Закрыть</mdisystt_close>
    <mdisystt_minimize>Минимизировать</mdisystt_minimize>
    <mdisystt_restore>Восстановить</mdisystt_restore>
    <monthcalendar_clearbutton>Очистить</monthcalendar_clearbutton>
    <monthcalendar_todaybutton>Сегодня</monthcalendar_todaybutton>
    <navbar_navpaneoptions>Свойства...</navbar_navpaneoptions>
    <navbar_showfewerbuttons>Показывать меньше кнопок</navbar_showfewerbuttons>
    <navbar_showmorebuttons>Показывать больше кнопок</navbar_showmorebuttons>
    <navPaneCollapseTooltip>Свернуть</navPaneCollapseTooltip>
    <navPaneExpandTooltip>Развернуть</navPaneExpandTooltip>
    <sys_custombar>По выбору</sys_custombar>
    <sys_morebuttons>Больше кнопок</sys_morebuttons>
  </Gui>
  <HelpComponents>
    <StiBarCode>Компонент используется для отображения в отчете штрих-кодов. Данные для кодирования штрих-кода устанавливаются в виде строки. Строка может содержать любые символы, но только разрешенные символы для указанного типа штрих-кода будут отображаться.</StiBarCode>
    <StiChart>Этот компонент используется для отображения диаграмм в отчете. Доступно множество различных типов диаграмм.</StiChart>
    <StiCheckBox>Компонент для отображения флажков в отчете. Он может менять свой внешний вид в зависимости от условия, которое может принимать два значения: "включено" или "выключено".</StiCheckBox>
    <StiChildBand>Подчиненный бэнд используется для вывода более одного бэнда для одной строки данных.</StiChildBand>
    <StiClone>Этот компонент используется для клонирования части отчета в другое место отчета. Клонирование может быть применено только к компоненту панель.</StiClone>
    <StiColumnFooterBand>Этот бэнд используется для вывода итога колонки данных. Бэнд выводится под каждой колонкой.</StiColumnFooterBand>
    <StiColumnHeaderBand>Этот бэнд используется для вывода заголовка колонки данных. Бэнд выводится над каждой колонкой.</StiColumnHeaderBand>
    <StiContainer>Это прямоугольная область, где другие компоненты, в том числе и бэнды, могут быть размещены. При перемещении контейнера компоненты размещенные в нем будут также перемещены. Контейнер может размещаться как на бэнде так и на странице.</StiContainer>
    <StiCrossDataBand>Бэнд выводится столько раз, сколько строк в источнике данных, который связан с ним. Бэнд кросс-данные выводится слева направо.</StiCrossDataBand>
    <StiCrossFooterBand>Бэнд используется для вывода итога для бэнда кросс-данные. Итог выводится сразу же после всех строк бэнда кросс-данные. Бэнд кросс-итог выводится слева направо.</StiCrossFooterBand>
    <StiCrossGroupFooterBand>Бэнд используется для вывода итога группы. Он размещается после бэнда кросс-данные. Каждый бэнд кросс-итог группы принадлежит к определенному кросс-заголовку группы. Бэнд кросс-итог группы выводится слева направо.</StiCrossGroupFooterBand>
    <StiCrossGroupHeaderBand>Этот бэнд является основным для подготовки отчетов с группировкой, когда используются бэнд кросс-данные. Невозможно создать кросс-группировку без этого бэнда. Бэнд кросс-заголовок группы выводится слева направо.</StiCrossGroupHeaderBand>
    <StiCrossHeaderBand>Бэнд используется для вывода заголовка для бэнда кросс-данные. Заголовок выводится перед выводом строк бэнда кросс-данные. Бэнд кросс-заголовок выводится слева направо.</StiCrossHeaderBand>
    <StiCrossTab>Кросс компонент используется для представления структурированных данных в виде таблицы.</StiCrossTab>
    <StiDataBand>Бэнд выводится столько раз, сколько строк в источнике данных, который связан с ним.</StiDataBand>
    <StiEmptyBand>Бэнд используется для заполнения свободного пространства в нижней части страницы.</StiEmptyBand>
    <StiFooterBand>Бэнд используется для вывода итога для бэнда данные. Итог выводится сразу же после всех строк бэнда данные.</StiFooterBand>
    <StiGauge>Компонент отображает значение в пределах заранее определенного диапазона.</StiGauge>
    <StiGroupFooterBand>Бэнд используется для вывода итога группы. Он размещается после бэнда данные. Каждый бэнд итог группы принадлежит к определенному заголовку группы.</StiGroupFooterBand>
    <StiGroupHeaderBand>Это основной бэнд для создания отчетов с группировкой. Компоненты для отображения информации по группе размещаются на бэнде заголовке группы. Это может быть название группы, даты, условия группировки и т.д. Бэнд заголовок группы выводится один раз в начале каждой группы.</StiGroupHeaderBand>
    <StiHeaderBand>Бэнд используется для вывода заголовка для бэнда данные. Заголовок выводится перед выводом строк бэнда данные.</StiHeaderBand>
    <StiHierarchicalBand>Бэнд выводится столько раз, сколько строк в источнике данных, который связан с ним. Данные выводятся в виде дерева.</StiHierarchicalBand>
    <StiHorizontalLinePrimitive>Геометрический примитив - горизонтальная линия. Используется для визуального оформления отчетов.</StiHorizontalLinePrimitive>
    <StiImage>Этот компонент используется для вывода изображений. Он поддерживает следующие графические форматы: BMP, JPG, JPEG, GIF, TIFF, PNG, ICO, WMF, EMF.</StiImage>
    <StiMap>Этот компонент используется для отображения данных, привязанных к географическим локациям.</StiMap>
    <StiOverlayBand>Этот бэнд используется для вывода водяных знаков на странице.</StiOverlayBand>
    <StiPageFooterBand>Бэнд итог страницы используется для вывода информации в нижней части каждой страницы, и используется для вывода номеров страниц, дат и другой дополнительной информации.</StiPageFooterBand>
    <StiPageHeaderBand>Бэнд используется для вывода заголовка страницы. Он выводится в верхней части каждой страницы, и используется для вывода номеров страниц, дат и другой дополнительной информации.</StiPageHeaderBand>
    <StiPanel>Это прямоугольная область, где другие компоненты, в том числе и бэнды, могут быть размещены. При перемещении панели компоненты размещенные на ней будут также перемещены. Панель может размещаться как на бэнде так и на странице.</StiPanel>
    <StiRectanglePrimitive>Геометрический примитив - прямоугольник, используется для визуального оформления отчетов. Левый верхний угол прямоугольника может находится в области одного бэнда, а правый нижний угол - в области другого бэнда.</StiRectanglePrimitive>
    <StiReportSummaryBand>Этот бэнд используется для вывода итога по всему отчету. Бэнд выводится один раз в конце отчета.</StiReportSummaryBand>
    <StiReportTitleBand>Этот бэнд используется для вывода названия отчета. Он выводится в начале отчета перед другими бэндами.</StiReportTitleBand>
    <StiRichText>Компонент для вывода и редактирования текста RTF. Поддерживает загрузку и сохранение файлов в формате RTF.</StiRichText>
    <StiRoundedRectanglePrimitive>Геометрический примитив - закругленный прямоугольник. Этот примитив аналогичен примитиву прямоугольник за исключением того, что имеет закругленные углы. Примитив поддерживается не всеми видами экспорта.</StiRoundedRectanglePrimitive>
    <StiShape>Компонент предназначенный для отображения различных геометрических примитивов, таких как стрелки, линии, прямоугольники, треугольники.</StiShape>
    <StiSubReport>Этот компонент используется для вывода дополнительных данных в разных местах отчета.</StiSubReport>
    <StiTable>Этот компонент представляет собой набор элементов данных, организованных в виде таблицы.</StiTable>
    <StiText>Основной компонент для вывода текстовой информации в отчете.</StiText>
    <StiTextInCells>Компонент для вывода текста в виде ячеек. Обычно используется при печати бланков.</StiTextInCells>
    <StiVerticalLinePrimitive>Геометрический примитив - вертикальная линия, используется для визуального оформления отчетов. Вертикальная линия может начинаться в области одного бэнда, а заканчиваться в области другого бэнда.</StiVerticalLinePrimitive>
    <StiWinControl>Компонент предназначенный для отображения визуальных элементов управления .NET Framework.</StiWinControl>
    <StiZipCode>Компонент используется для вывода почтового индекса.</StiZipCode>
  </HelpComponents>
  <HelpDesigner>
    <Align>Изменение расположения выбранных компонентов.</Align>
    <AlignBottom>Расположить содержимое компонента по нижнему краю.</AlignBottom>
    <AlignCenter>Расположить содержимое компонента по центру по горизонтали.</AlignCenter>
    <AlignComponentBottom>Позволяет расположить выделенные компоненты горизонтально вдоль нижнего края выделенных компонентов.</AlignComponentBottom>
    <AlignComponentCenter>Позволяет расположить выделенные компоненты горизонтально вдоль центра края выделенных компонентов.</AlignComponentCenter>
    <AlignComponentLeft>Позволяет расположить выделенные компоненты вертикально по левому краю выделенных компонентов.</AlignComponentLeft>
    <AlignComponentMiddle>Позволяет расположить выделенные компоненты вертикально по центру края выделенных компонентов.</AlignComponentMiddle>
    <AlignComponentRight>Позволяет расположить выделенные компоненты вертикально вдоль правого края выделенных компонентов.</AlignComponentRight>
    <AlignComponentTop>Позволяет расположить выделенные компоненты горизонтально вдоль верхнего края выделенных компонентов.</AlignComponentTop>
    <AlignLeft>Расположить содержимое компонента по левому краю.</AlignLeft>
    <AlignMiddle>Расположить содержимое компонента по центру по вертикали.</AlignMiddle>
    <AlignRight>Расположить содержимое компонента по правому краю.</AlignRight>
    <AlignToGrid>Расположить выделенные компоненты по узлам сетки.</AlignToGrid>
    <AlignTop>Расположить содержимое компонента по верхнему краю.</AlignTop>
    <AlignWidth>Расположить содержимое компонента по ширине.</AlignWidth>
    <Angle>Повернуть содержимое компонента.</Angle>
    <AngleWatermark>Угол поворота текста водяного знака.</AngleWatermark>
    <Background>Изменить задний фон выделенных компонентов.</Background>
    <biConditions>Изменить условия выделенных компонентов.</biConditions>
    <BorderColor>Изменить цвет границы выделенных компонентов.</BorderColor>
    <BorderSidesAll>Установить границу у выделенных компонентов со всех сторон.</BorderSidesAll>
    <BorderSidesBottom>Установить границу у выделенных компонентов с нижней стороны.</BorderSidesBottom>
    <BorderSidesLeft>Установить границу у выделенных компонентов с левой стороны.</BorderSidesLeft>
    <BorderSidesNone>Сбросить границу у выделенных компонентов со всех сторон.</BorderSidesNone>
    <BorderSidesRight>Установить границу у выделенных компонентов с правой стороны.</BorderSidesRight>
    <BorderSidesTop>Установить границу у выделенных компонентов с верхней стороны.</BorderSidesTop>
    <BorderStyle>Изменить стиль границы у выделенных компонентов.</BorderStyle>
    <BringToFront>Переместить выделенные компоненты наверх.</BringToFront>
    <CenterHorizontally>Центрирует выделенные компоненты по горизонтали.</CenterHorizontally>
    <CenterVertically>Центрирует выделенные компоненты по вертикали.</CenterVertically>
    <Close>Закрыть Дизайнер Отчетов.</Close>
    <Columns>Разделите страницу на колонки.</Columns>
    <ComponentSize>Изменить размер выделенных компонентов.</ComponentSize>
    <CopyStyle>Копировать стиль</CopyStyle>
    <CopyToClipboard>Скопировать в буфер обмена</CopyToClipboard>
    <CurrencySymbol>Выбрать символ валюты.</CurrencySymbol>
    <DataStore>Показать данные, зарегистрированные для отчета.</DataStore>
    <DateTimeFormat>Выбрать формат даты и времени для выделенных компонентов.</DateTimeFormat>
    <DockingPanels>Настройки панели.</DockingPanels>
    <DockStyleBottom>Пристыковать выделенные компоненты к нижнему краю.</DockStyleBottom>
    <DockStyleFill>Пристыковать выделенные компоненты ко всем краям сразу.</DockStyleFill>
    <DockStyleLeft>Пристыковать выделенные компоненты к левому краю.</DockStyleLeft>
    <DockStyleNone>Не пристыковывать выделенные компоненты.</DockStyleNone>
    <DockStyleRight>Пристыковать выделенные компоненты к правому краю.</DockStyleRight>
    <DockStyleTop>Пристыковать выделенные компоненты к верхнему краю.</DockStyleTop>
    <FontGrow>Сделать размер шрифта текста больше.</FontGrow>
    <FontName>Шрифт текста.</FontName>
    <FontNameWatermark>Шрифт текста водяного знака.</FontNameWatermark>
    <FontShrink>Сделать размер шрифта текста меньше.</FontShrink>
    <FontSize>Размер шрифта.</FontSize>
    <FontSizeWatermark>Изменить размер шрифта.</FontSizeWatermark>
    <FontStyleBold>Сделать текст жирным.</FontStyleBold>
    <FontStyleBoldWatermark>Сделать текст водяного знака жирным.</FontStyleBoldWatermark>
    <FontStyleItalic>Сделать текст курсивом.</FontStyleItalic>
    <FontStyleItalicWatermark>Сделать текст водяного знака курсивом.</FontStyleItalicWatermark>
    <FontStyleUnderline>Сделать текст подчеркнутым.</FontStyleUnderline>
    <FontStyleUnderlineWatermark>Сделать текст водяного знака подчеркнутым.</FontStyleUnderlineWatermark>
    <FormatBoolean>Этот формат используется для форматирования значений булевского типа.</FormatBoolean>
    <FormatCurrency>Отображать данные как данные денежного типа.</FormatCurrency>
    <FormatCustom>Этот тип используется для отображения значений в соответствии с требованиями клиента. Этот тип позволяет задавать форматирование данных в формате маски.</FormatCustom>
    <FormatDate>Отображать данные в виде даты. Формат даты зависит от региональных настроек.</FormatDate>
    <FormatGeneral>Отображать данные без определенного формата.</FormatGeneral>
    <FormatNumber>Отображать данные как числа.</FormatNumber>
    <FormatPercentage>Отображать данные с процентами. Число умножается на 100, чтобы преобразовать их в проценты.</FormatPercentage>
    <FormatTime>Отображать данные, как время. Формат времени зависит от региональных настроек.</FormatTime>
    <FormNew>Создать новую диалоговую форму.</FormNew>
    <GridMode>Отображать сетку точками или линиями.</GridMode>
    <ImageAlignment>Разместить изображение в качестве </ImageAlignment>
    <ImageTransparency>Изменить прозрачность изображения.</ImageTransparency>
    <Link>Связать выделенные компоненты с контейнерами, в которых они находятся.</Link>
    <LoadImage>Загрузить изображение для водяного знака из файла.</LoadImage>
    <Lock>Блокировка выделенных компонентов. Компоненты не могут быть перемещены, и нельзя изменить их размеры.</Lock>
    <MainMenu>Нажмите здесь, чтобы увидеть список возможных операций с отчетом, в том числе операции открытия, закрытия и предварительного просмотра.</MainMenu>
    <MakeHorizontalSpacingEqual>Позволяет установить равные горизонтальные расстояния между отдельными компонентами.</MakeHorizontalSpacingEqual>
    <MakeVerticalSpacingEqual>Позволяет установить равные вертикальные расстояния между отдельными компонентами.</MakeVerticalSpacingEqual>
    <Margins>Выберите размер полей у текущей страницы.</Margins>
    <menuCheckIssues>Проверить отчет на наличие ошибок, замечаний и пожеланий от ядра генератора отчетов.</menuCheckIssues>
    <menuDesignerOptions>Произвести настройку параметров дизайнера отчетов.</menuDesignerOptions>
    <menuEditClearContents>Очистить содержимое выделенных текстовых компонентов.</menuEditClearContents>
    <menuEditCopy>Копировать выбранные компоненты и положить их в буфер обмена.</menuEditCopy>
    <menuEditCut>Вырезать выделенные компоненты из отчета, и положить их в буфер обмена.</menuEditCut>
    <menuEditDelete>Удалить выделенные компоненты из отчета.</menuEditDelete>
    <menuEditPaste>Вставить содержимое буфера обмена в отчет.</menuEditPaste>
    <menuFAQPage>Перейти на Web-страницу, на которой размещены Часто Задаваемые Вопросы.</menuFAQPage>
    <menuGlobalizationStrings>Вызвать редактор "Строк перевода" текущего отчета.</menuGlobalizationStrings>
    <menuHelpAboutProgramm>Получить информацию о версии генератора отчетов и версии .NET Framework.</menuHelpAboutProgramm>
    <menuHomePage>Перейти на домашнюю Web-страницу продукта.</menuHomePage>
    <menuPageOptions>Произвести настройку основных параметров текущей страницы. Все параметры страницы можно изменить при помощи панели Свойства.</menuPageOptions>
    <menuPagesManager>Запустить Менеджер Страниц. С его помощью можно перемещать, удалять страницы и создавать новые.</menuPagesManager>
    <menuPreviewSettings>Вызвать редактор внешнего вида окна предварительного просмотра текущего отчета. Настройки будут применяться только при просмотре текущего отчета.</menuPreviewSettings>
    <menuPrint>Выбрать принтер, количество копий и другие параметры перед печатью.</menuPrint>
    <menuPrintPreview>Выполнить предварительный просмотр отчета перед печатью.</menuPrintPreview>
    <menuPrintQuick>Распечатать отчет на принтере, который выбран по умолчанию. Диалог печати не отображается.</menuPrintQuick>
    <menuReportOptions>Произвести настройку основных параметров отчета. Все параметры отчета можно изменить при помощи панели Свойства.</menuReportOptions>
    <menuStyleDesigner>Вызвать редактор стилей текущего отчета.</menuStyleDesigner>
    <menuSupport>Перейти на Web-страницу, при помощи которой можно задать вопросы по работе продукта.</menuSupport>
    <menuViewAlignToGrid>Расположить по сетке.</menuViewAlignToGrid>
    <menuViewNormal>Просмотр страницы в нормальном режиме.</menuViewNormal>
    <menuViewPageBreakPreview>Просмотр страницы в режиме отображения границ сегментов страницы.</menuViewPageBreakPreview>
    <menuViewQuickInfo>Показывать информацию о компоненте: имя, псевдоним, содержание, события и т.д.</menuViewQuickInfo>
    <menuViewShowGrid>Включить линии сетки, по которым можно расположить компоненты в отчете.</menuViewShowGrid>
    <menuViewShowHeaders>Показывать заголовки бэндов.</menuViewShowHeaders>
    <menuViewShowOrder>Показывать порядок расположения компонентов на странице.</menuViewShowOrder>
    <menuViewShowRulers>Показывать размерные линейки.</menuViewShowRulers>
    <MoveBackward>Перемещение компонента на один уровень выше в порядке размещения компонентов на странице.</MoveBackward>
    <MoveForward>Перемещение компонента на один уровень ниже в порядке размещения компонентов на странице.</MoveForward>
    <Orientation>Переключение между книжной и альбомной ориентацией страницы.</Orientation>
    <PageDelete>Удалить текущую страницу из отчета.</PageDelete>
    <PageNew>Создать новую страницу в отчете.</PageNew>
    <PageSetup>Показать диалог настройки параметров страницы.</PageSetup>
    <PageSize>Выбрать размер страницы текущей страницы.</PageSize>
    <PagesManager>Запускает Менеджер Страниц.</PagesManager>
    <PressF1>Нажмите F1 для справки</PressF1>
    <Redo>Повторить ранее отменные изменения в дизайнере отчетов.</Redo>
    <ReportNew>Создать новый отчет.</ReportNew>
    <ReportOpen>Открыть отчет в дизайнере.</ReportOpen>
    <ReportPreview>Предварительный просмотр редактируемого отчета.</ReportPreview>
    <ReportSave>Сохранить редактируемый отчет.</ReportSave>
    <SelectAll>Выбрать все компоненты на странице.</SelectAll>
    <SelectUILanguage>Выбрать язык интерфейса.</SelectUILanguage>
    <SendToBack>Переместить выбранные компоненты назад по отношению к другим компонентам.</SendToBack>
    <ServicesConfigurator>Запускает Конфигуратор Сервисов.</ServicesConfigurator>
    <Shadow>Показывать тень компонента.</Shadow>
    <ShowBehind>Показывать текст водяного знака позади страницы.</ShowBehind>
    <ShowImageBehind>Показывать изображение водяного знака позади страницы.</ShowImageBehind>
    <ShowToolbox>Показать инструментарий.</ShowToolbox>
    <StimulsoftHelp>Помощь Stimulsoft</StimulsoftHelp>
    <StyleDesigner>Запустить Редактор Стилей отчета.</StyleDesigner>
    <TellMeMore>Подробнее</TellMeMore>
    <Text>Текст водяного знака.</Text>
    <TextBrush>Изменить цвет текста.</TextBrush>
    <TextBrushWatermark>Изменить цвет текста водяного знака.</TextBrushWatermark>
    <TextColor>Изменить цвет текста.</TextColor>
    <TextFormat>Выбрать формат отображения значений.</TextFormat>
    <ToolbarStyle>Применить выбранный стиль ко всем выделенным компонентам.</ToolbarStyle>
    <Undo>Отменить последнее изменение в отчете.</Undo>
    <WordWrap>Переносить текст.</WordWrap>
    <Zoom>Указать масштаб отображения отчета в дизайнере.</Zoom>
  </HelpDesigner>
  <HelpDialogs>
    <StiButtonControl>Представляет элемент управления "кнопка".</StiButtonControl>
    <StiCheckBoxControl>Представляет элемент управления — "флажок", который пользователь может устанавливать и снимать.</StiCheckBoxControl>
    <StiCheckedListBoxControl>Отображает объект "список", в котором флажок отображается слева от каждого элемента.</StiCheckedListBoxControl>
    <StiComboBoxControl>Представляет элемент управления, предназначенный для выбора значения, с раскрывающимся списком, который можно отображать и скрывать нажатием принадлежащей этому элементу управления кнопки со стрелкой.</StiComboBoxControl>
    <StiDateTimePickerControl>Представляет элемент управления, который позволяет выбрать дату и время и отобразить их в указанном формате.</StiDateTimePickerControl>
    <StiGridControl>Определяет гибкую область сетки, состоящую из столбцов и строк.</StiGridControl>
    <StiGroupBoxControl>Представляет элемент управления, создающий контейнер с границей и заголовком для содержимого пользовательский интерфейс.</StiGroupBoxControl>
    <StiLabelControl>Предоставляет текстовую метку для элемента управления и обеспечивает поддержку клавиш доступа.</StiLabelControl>
    <StiListBoxControl>Содержит список элементов для выбора.</StiListBoxControl>
    <StiListViewControl>Представляет элемент управления, отображающий список элементов данных.</StiListViewControl>
    <StiLookUpBoxControl>Содержит список элементов для выбора.</StiLookUpBoxControl>
    <StiNumericUpDownControl>Представляет регулятор, отображающий числовые значения.</StiNumericUpDownControl>
    <StiPanelControl>Элементы Panel используются для размещения и организации объектов.</StiPanelControl>
    <StiPictureBoxControl>Предоставляет элемент управления для отображения рисунка.</StiPictureBoxControl>
    <StiRadioButtonControl>Представляет переключатель, который пользователь может устанавливать, но не снимать флажок.</StiRadioButtonControl>
    <StiRichTextBoxControl>Представляет элемент управления с расширенным редактированием.</StiRichTextBoxControl>
    <StiTextBoxControl>Представляет элемент управления, который можно использовать для отображения или редактирования текста.</StiTextBoxControl>
    <StiTreeViewControl>Представляет элемент управления, отображающий иерархические данные в структуре дерева с элементами, которые могут разворачиваться и сворачиваться.</StiTreeViewControl>
  </HelpDialogs>
  <HelpViewer>
    <AddPageBreaks>Визуальный разделитель страниц отчета.</AddPageBreaks>
    <AllowAddOrModifyTextAnnotations>Ограниченный доступ к работе с примечаниями в документе.</AllowAddOrModifyTextAnnotations>
    <AllowCopyTextAndGraphics>Ограниченный доступ к копированию информации.</AllowCopyTextAndGraphics>
    <AllowEditable>Разрешить изменения только для компонентов с включенным свойством Редактируемый.</AllowEditable>
    <AllowModifyContents>Ограниченный доступ к редактированию текста.</AllowModifyContents>
    <AllowPrintDocument>Ограниченный доступ к операции печать.</AllowPrintDocument>
    <Bookmarks>Отображение панели закладок, которая служит для быстрой навигации по отчету при помощи закладок отчета.</Bookmarks>
    <BorderType>Тип границы компонентов: простая - отрисовка границы компонентов символами +,-, |. Unicode одинарная - отрисовка границ компонентов символами псевдографики одинарными, Unicode двойная - отрисовка границ компонентов двойными символами псевдографики.</BorderType>
    <Close>Свернуть закладку предварительного просмотра.</Close>
    <CloseDotMatrix>Свернуть закладку предварительного просмотра для матричных принтеров.</CloseDotMatrix>
    <Compressed>Сжатие конечного документа. Рекомендуется всегда включать сжатие файла.</Compressed>
    <CompressToArchive>Упаковать все файлы и папки в zip архив.</CompressToArchive>
    <ContinuousPages>Режим расположения страниц отчета в виде вертикальной ленты.</ContinuousPages>
    <CurrentPage>Обработка текущей страницы, т.е. если выбран данный параметр, то обрабатываться будет выделенная страница отчета.</CurrentPage>
    <CutEdges>Обрезать границы страниц отчета.</CutEdges>
    <CutLongLines>Обрезать длинные линии (строки текста) по границам компонента.</CutLongLines>
    <DigitalSignature>Цифровая подпись к файлу.</DigitalSignature>
    <DitheringType>Тип монохромного смешивания: None - без дизеринга, Ordered, FloydSt - с дизерингом.</DitheringType>
    <DotMatrixMode>Этот режим позволяет вам видеть, как отчет будет выглядеть, если напечатать его на матричный принтер.</DotMatrixMode>
    <DrawBorder>Отрисовка границ компонентов символами графики.</DrawBorder>
    <Edit>Редактировать элементы отчета.</Edit>
    <EmbeddedFonts>Встроить файлы шрифтов в файл PDF.</EmbeddedFonts>
    <EmbeddedImageData>Встроить изображения непосредственно в HTML-файл.</EmbeddedImageData>
    <Encoding>Кодировка текста отчета после экспорта.</Encoding>
    <EncodingData>Кодировка при создании файла.</EncodingData>
    <EncryptionKeyLength>Длина ключа шифрования к документу. Чем больше длина, тем сложнее расшифровать документ, и соответственно безопасность документа выше.</EncryptionKeyLength>
    <ExportDataOnly>Экспорт только бэндов Данные (компонент Таблица, бэнд Дерево).</ExportDataOnly>
    <ExportEachPageToSheet>Экспортировать каждую страницу отчета на отдельный лист в Excel.</ExportEachPageToSheet>
    <ExportMode>Применить условие фильтрации при экспорте. Только данные - экспортироваться будут только бэнды Данные (компонент Таблица, бэнд Дерево), Данные и Заголовки/Итоги - экспортироваться будут бэнды Данные (компонент Таблица, бэнд Дерево) и их заголовки/итоги, при наличии, Все бэнды - экспортироваться будут все бэнды отчета.</ExportMode>
    <ExportModeHtml>Cпособ разметки HTML страницы.</ExportModeHtml>
    <ExportModeRtf>Представление данных отчета после экспорта. Таблица - отчет будет представлять собой таблицу, где каждый компонент отчета - ячейка в таблице. Рамка - каждый компонент будет представлять собой отдельную рамку, но взамосвязи между ними не будет.</ExportModeRtf>
    <ExportObjectFormatting>Применять форматирование к экспорту с бэндов Данные (компонент Таблица, бэнд Дерево).</ExportObjectFormatting>
    <ExportPageBreaks>Отобразить границы страниц отчета на листе в Excel.</ExportPageBreaks>
    <ExportRtfTextAsImage>Конвертация RTF текста в картинку при экспорте. Если параметр выключен, то RichText при экспорте раскладывается на более простые примитивы, поддерживаемые форматом PDF. RichText со сложным форматированием (вложенные изображения, таблицы) не всегда может быть преобразован корректно. В этом случае рекомендуется включить данный параметр.</ExportRtfTextAsImage>
    <Find>Поиск текста в отчете.</Find>
    <FullScreen>Полноэкранный просмотр отчета.</FullScreen>
    <GetCertificateFromCryptoUI>Использование интерфейса системной библиотеки криптографии.</GetCertificateFromCryptoUI>
    <ImageCompressionMethod>Метод сжатия: JPEG - это сжатие с потерями, Flate - сжатие без потерь, Simple, Ordered, FloydSt. - изображения в монохромном режиме.</ImageCompressionMethod>
    <ImageFormat>Формат изображений в конечном файле.</ImageFormat>
    <ImageQuality>Позволяет выбрать соотношение качество изображения / размер файла. Чем выше качество, тем больше размер выходного файла.</ImageQuality>
    <ImageQualityPdf>Параметр доступен только при сжатии JPEG, и позволяет выбрать соотношение качество изображения / размер файла. Чем выше качество, тем больше размер выходного файла.</ImageQualityPdf>
    <ImageResolution>Количество пикселей на дюйм изображения. Чем больше данное количество пикселей, тем выше четкость изображения и легкость восприятия, но и размер выходного файла гораздо больше.</ImageResolution>
    <ImageType>Цветовая схема изображения: цвет - изображения после экспорта будет полностью соответствовать изображению во вьювере; серый - изображения после экспорта будут серого оттенка; монохромный - изображения будут сугубо черно-белые. При этом, следует учитывать, что монохромные изображения имеют три режима None, Ordered, FloydSt.</ImageType>
    <KillSpaceLines>Удалить пустые линии (строки) в документе.</KillSpaceLines>
    <MultipleFiles>Каждая страница отчета отдельным файлом.</MultipleFiles>
    <Open>Открыть ранее сохраненный отчет.</Open>
    <OpenAfterExport>Автоматическое открытие созданного документа (после завершения экспорта) программой, установленной по умолчанию для этих типов файлов.</OpenAfterExport>
    <OwnerPassword>Пароль доступа к операциям с файлом.</OwnerPassword>
    <PageAll>Обработка всех страниц отчета.</PageAll>
    <PageDelete>Удалить выбранную страницу отчета.</PageDelete>
    <PageDesign>Изменить выбранную страницу отчета в дизайнере отчетов.</PageDesign>
    <PageFirst>Перейти на первую страницу отчета.</PageFirst>
    <PageGoTo>Перейти на указанную страницу отчета.</PageGoTo>
    <PageLast>Перейти на последнюю страницу отчета.</PageLast>
    <PageNew>Добавить пустую страницу в конец отчета.</PageNew>
    <PageNext>Перейти на следующую страницу в отчете.</PageNext>
    <PagePrevious>Перейти на предыдущую страницу в отчете.</PagePrevious>
    <PageSize>Изменить параметры страницы в отчете.</PageSize>
    <Parameters>Отображение панели параметров отчета.</Parameters>
    <PdfACompliance>Поддержка стандарта долгосрочного архивного хранения электронных документов.</PdfACompliance>
    <Print>Напечатать отчет.</Print>
    <PutFeedPageCode>Разграничивать страницы в конечном документе при помощи специального символа.</PutFeedPageCode>
    <RangePages>Номера страниц, которые необходимо обработать. Указать можно одну страницу, список страниц (используя запятые как разделитель), а также задать диапазон, задав начальную страницу диапазона, разделитель "-", и конечную страницу диапазона. Для примера: 1,3,5-12.</RangePages>
    <RemoveEmptySpaceAtBottom>Минимизировать пустое место внизу страницы.</RemoveEmptySpaceAtBottom>
    <RestrictEditing>Ограничения на изменения в документе Word.</RestrictEditing>
    <Save>Сохранить отчет для последующего использования.</Save>
    <ScaleHtml>Размер (масштаб) страниц отчета и элементов отчета после экспорта.</ScaleHtml>
    <ScaleImage>Размер (масштаб) отчета после экспорта. Чем меньше масштаб, тем больше количество пикселей на дюйм и наоборот.</ScaleImage>
    <SendEMail>Послать отчет по Email.</SendEMail>
    <Separator>Символ-разделитель между данными в файле CSV.</Separator>
    <SkipColumnHeaders>Включить/выключить заголовки колонок.</SkipColumnHeaders>
    <StandardPdfFonts>14 стандартных шрифтов Adobe. Если данный параметр включен, то в PDF файле после экспорта, будут использованы только стандартные 14 шрифтов, и все шрифты отчета будут преобразованы в них.</StandardPdfFonts>
    <SubjectNameString>Идентификатор сертификата. Идентификатор - это имя владельца сертификата (полная строка) или часть имени (подстрока).</SubjectNameString>
    <Thumbnails>Отображение панели эскизов, которую можно использовать для быстрой навигации по отчету при помощи маленьких изображений каждой страницы.</Thumbnails>
    <TiffCompressionScheme>Схема сжатия для TIFF файлов.</TiffCompressionScheme>
    <ToolEditor>Этот инструмент позволяет отредактировать содержимое тестовых компонентов прямо в окне вьювера отчетов.</ToolEditor>
    <TypeExport>Тип файла, в который будет преобразован отчет.</TypeExport>
    <UseDefaultSystemEncoding>Использовать кодировку системы по умолчанию.</UseDefaultSystemEncoding>
    <UseOnePageHeaderAndFooter>Использовать только первый заголовок страницы и последний итог страницы, убрать остальные заголовки и итоги страницы.</UseOnePageHeaderAndFooter>
    <UsePageHeadersAndFooters>Экспортировать бэнды Заголовок страницы и Итог страницы как верхний и нижний колонтитул документа MS Word.</UsePageHeadersAndFooters>
    <UserPassword>Пароль, необходимый для открытия документа.</UserPassword>
    <UseUnicode>Расширенная поддержка кодирования символов. Влияет на внутреннее кодирование символов внутри PDF файла и улучшает копирование текста из PDF файла.</UseUnicode>
    <ViewModeContinuous>Отображать все страницы отчета в виде вертикальной ленты.</ViewModeContinuous>
    <ViewModeMultiplePages>Отображать столько страниц отчета, сколько помещается в окне просмотра.</ViewModeMultiplePages>
    <ViewModeSinglePage>Отображать только одну страницу в окне просмотра.</ViewModeSinglePage>
    <ZoomMultiplePages>Установить масштаб отображения отчета так, чтобы выбранное количество страниц поместились в окне просмотра.</ZoomMultiplePages>
    <ZoomOnePage>Установить такой масштаб отображения отчета, чтобы высота текущей страницы занимала всю доступную высоту окна просмотра.</ZoomOnePage>
    <ZoomPageWidth>Установить такой масштаб отображения отчета, чтобы ширина текущей страницы занимала всю доступную ширину окна просмотра.</ZoomPageWidth>
    <ZoomTwoPages>Установить такой масштаб отображения отчета, чтобы в окне просмотра отображались две страницы рядом.</ZoomTwoPages>
    <ZoomTxt>Размер (масштаб) отчета: X - изменить масштаб по горизонтали, Y - изменить масштаб по вертикали.</ZoomTxt>
  </HelpViewer>
  <Infographics>
    <Arguments>Аргументы</Arguments>
    <ChangeChartType>Изменить тип диаграммы</ChangeChartType>
    <ChangeMapType>Изменить тип карты</ChangeMapType>
    <DataNoDefined>Данные не заданы</DataNoDefined>
    <ImageNotSpecified>Изображение не задано</ImageNotSpecified>
    <Series>Серии</Series>
    <Values>Значения</Values>
  </Infographics>
  <Interface>
    <Mouse>Мышь</Mouse>
    <MouseDescription>Оптимизировано для использования мыши.</MouseDescription>
    <Touch>Сенсорное Управление</Touch>
    <TouchDescription>Оптимизировано для использования сенсорного экрана.</TouchDescription>
  </Interface>
  <MainMenu>
    <menuCheckIssues>Проверить отчет</menuCheckIssues>
    <menuContextClone>Клонировать...</menuContextClone>
    <menuContextDesign>Редактировать...</menuContextDesign>
    <menuContextTextFormat>Формат...</menuContextTextFormat>
    <menuConvertToCheckBox>Конвертировать во Флажок</menuConvertToCheckBox>
    <menuConvertToImage>Конвертировать в Картинку</menuConvertToImage>
    <MenuConvertToRichText>Конвертировать в Rich-текст</MenuConvertToRichText>
    <menuConvertToText>Конвертировать в Текст</menuConvertToText>
    <menuDeleteColumn>Удалить колонку</menuDeleteColumn>
    <menuDeleteRow>Удалить строку</menuDeleteRow>
    <menuEdit>&amp;Правка</menuEdit>
    <menuEditBusinessObjectFromDataSetNew>Новый бизнес-объект из набора данных...</menuEditBusinessObjectFromDataSetNew>
    <menuEditBusinessObjectNew>Новый бизнес-объект...</menuEditBusinessObjectNew>
    <menuEditCalcColumnNew>Новая рассчитываемая колонка...</menuEditCalcColumnNew>
    <menuEditCantRedo>Нельзя повторить</menuEditCantRedo>
    <menuEditCantUndo>Нельзя отменить</menuEditCantUndo>
    <menuEditCategoryNew>Новая категория...</menuEditCategoryNew>
    <menuEditClearContents>Очистить содержимое</menuEditClearContents>
    <menuEditColumnNew>Новая колонка...</menuEditColumnNew>
    <menuEditConnectionNew>Новое соединение...</menuEditConnectionNew>
    <menuEditCopy>&amp;Копировать</menuEditCopy>
    <menuEditCut>&amp;Вырезать</menuEditCut>
    <menuEditDataParameterNew>Новый параметр...</menuEditDataParameterNew>
    <menuEditDataSourceNew>Новый источник данных...</menuEditDataSourceNew>
    <menuEditDataSourcesNew>Новые источники данных...</menuEditDataSourcesNew>
    <menuEditDelete>&amp;Удалить</menuEditDelete>
    <menuEditEdit>Изменить</menuEditEdit>
    <menuEditImportRelations>Импортировать связи...</menuEditImportRelations>
    <menuEditPaste>Вст&amp;авить</menuEditPaste>
    <menuEditRedo>Пов&amp;торить</menuEditRedo>
    <menuEditRedoText>Пов&amp;торить {0}</menuEditRedoText>
    <menuEditRelationNew>Новая связь...</menuEditRelationNew>
    <menuEditRemoveUnused>Удалить неиспользуемые элементы</menuEditRemoveUnused>
    <menuEditResourceNew>Новый ресурс...</menuEditResourceNew>
    <menuEditSelectAll>В&amp;ыделить все</menuEditSelectAll>
    <menuEditSynchronize>Синхронизировать</menuEditSynchronize>
    <menuEditUndo>&amp;Отменить</menuEditUndo>
    <menuEditUndoText>&amp;Отменить {0}</menuEditUndoText>
    <menuEditVariableNew>Новая переменная...</menuEditVariableNew>
    <menuEditViewData>Просмотр данных...</menuEditViewData>
    <menuFile>&amp;Файл</menuFile>
    <menuFileClose>&amp;Закрыть</menuFileClose>
    <menuFileDashboardNew>Новая панель индикаторов</menuFileDashboardNew>
    <menuFileDashboardOpen>&amp;Открыть панель индикаторов...</menuFileDashboardOpen>
    <menuFileDashboardSaveAs>Со&amp;хранить панель индикаторов как...</menuFileDashboardSaveAs>
    <menuFileExit>В&amp;ыход</menuFileExit>
    <menuFileExportXMLSchema>Экспортировать XML схему...</menuFileExportXMLSchema>
    <menuFileFormNew>Создать форму</menuFileFormNew>
    <menuFileImportXMLSchema>Импортировать XML схему...</menuFileImportXMLSchema>
    <menuFileMerge>Добавить...</menuFileMerge>
    <menuFileMergeXMLSchema>Добавить XML схему...</menuFileMergeXMLSchema>
    <menuFileNew>Созд&amp;ать</menuFileNew>
    <menuFileOpen>&amp;Открыть...</menuFileOpen>
    <menuFilePageDelete>Удалить страницу</menuFilePageDelete>
    <menuFilePageNew>Создать страницу</menuFilePageNew>
    <menuFilePageOpen>Открыть страницу...</menuFilePageOpen>
    <menuFilePageSaveAs>Сохранить страницу как...</menuFilePageSaveAs>
    <menuFilePageSetup>Свойства страницы...</menuFilePageSetup>
    <menuFileRecentDocuments>Последние отчеты</menuFileRecentDocuments>
    <menuFileRecentLocations>Последние места</menuFileRecentLocations>
    <menuFileReportNew>Созд&amp;ать отчет...</menuFileReportNew>
    <menuFileReportOpen>&amp;Открыть отчет...</menuFileReportOpen>
    <menuFileReportOpenFromGoogleDocs>Открыть отчет из Google Docs...</menuFileReportOpenFromGoogleDocs>
    <menuFileReportPreview>Пред&amp;варительный просмотр...</menuFileReportPreview>
    <menuFileReportSave>&amp;Сохранить отчет</menuFileReportSave>
    <menuFileReportSaveAs>Со&amp;хранить отчет как...</menuFileReportSaveAs>
    <menuFileReportSaveAsToGoogleDocs>Сохранить отчет как в Google Docs...</menuFileReportSaveAsToGoogleDocs>
    <menuFileReportSetup>Свойства отчета...</menuFileReportSetup>
    <menuFileReportWizardNew>Создать отчет при помощи &amp;Мастера...</menuFileReportWizardNew>
    <menuFileSave>&amp;Сохранить</menuFileSave>
    <menuFileSaveAs>Сохранить как...</menuFileSaveAs>
    <menuHelp>Помощь</menuHelp>
    <menuHelpAboutProgramm>О программе...</menuHelpAboutProgramm>
    <menuHelpContents>Содержание...</menuHelpContents>
    <menuHelpFAQPage>Часто задаваемые вопросы</menuHelpFAQPage>
    <menuHelpHowToRegister>Регистрация</menuHelpHowToRegister>
    <menuHelpProductHomePage>Домашняя страница</menuHelpProductHomePage>
    <menuHelpSupport>Поддержка</menuHelpSupport>
    <menuInsertColumnToLeft>Вставить колонку слева</menuInsertColumnToLeft>
    <menuInsertColumnToRight>Вставить колонку справа</menuInsertColumnToRight>
    <menuInsertRowAbove>Вставить строку выше</menuInsertRowAbove>
    <menuInsertRowBelow>Вставить строку ниже</menuInsertRowBelow>
    <menuJoinCells>Объединить ячейки</menuJoinCells>
    <menuSelectColumn>Выбрать колонку</menuSelectColumn>
    <menuSelectRow>Выбрать строку</menuSelectRow>
    <menuTable>Таблица</menuTable>
    <menuTools>С&amp;ервис</menuTools>
    <menuToolsDataStore>Хранилище данных...</menuToolsDataStore>
    <menuToolsDictionary>Словарь...</menuToolsDictionary>
    <menuToolsOptions>Параметры...</menuToolsOptions>
    <menuToolsPagesManager>Менеджер страниц...</menuToolsPagesManager>
    <menuToolsServicesConfigurator>Конфигуратор сервисов...</menuToolsServicesConfigurator>
    <menuToolsStyleDesigner>Редактор стилей...</menuToolsStyleDesigner>
    <menuView>&amp;Вид</menuView>
    <menuViewAlignToGrid>Привязывать к сетке</menuViewAlignToGrid>
    <menuViewNormal>&amp;Обычный</menuViewNormal>
    <menuViewOptions>Параметры</menuViewOptions>
    <menuViewPageBreakPreview>&amp;Разметка страницы</menuViewPageBreakPreview>
    <menuViewQuickInfo>Быстрые сведения</menuViewQuickInfo>
    <menuViewQuickInfoNone>Не показывать</menuViewQuickInfoNone>
    <menuViewQuickInfoOverlay>Показывать поверх компонентов</menuViewQuickInfoOverlay>
    <menuViewQuickInfoShowAliases>Показывать Псевдонимы</menuViewQuickInfoShowAliases>
    <menuViewQuickInfoShowComponentsNames>Показывать имена компонентов</menuViewQuickInfoShowComponentsNames>
    <menuViewQuickInfoShowContent>Показывать содержимое</menuViewQuickInfoShowContent>
    <menuViewQuickInfoShowEvents>Показывать события</menuViewQuickInfoShowEvents>
    <menuViewQuickInfoShowFields>Показывать поля</menuViewQuickInfoShowFields>
    <menuViewQuickInfoShowFieldsOnly>Показывать только поля</menuViewQuickInfoShowFieldsOnly>
    <menuViewShowGrid>Сетка</menuViewShowGrid>
    <menuViewShowHeaders>Заголовки</menuViewShowHeaders>
    <menuViewShowInsertTab>Показать вкладку 'Вставить'</menuViewShowInsertTab>
    <menuViewShowOrder>Показывать порядок</menuViewShowOrder>
    <menuViewShowRulers>Линейка</menuViewShowRulers>
    <menuViewShowToolbox>Показать инструментарий</menuViewShowToolbox>
    <menuViewToolbars>Панели &amp;инструментов</menuViewToolbars>
  </MainMenu>
  <Map>
    <GroupAsia>Азия</GroupAsia>
    <GroupEurope>Европа</GroupEurope>
    <GroupNorthAmerica>Северная Америка</GroupNorthAmerica>
    <GroupOceania>Океания</GroupOceania>
    <GroupSouthAmerica>Южная Америка</GroupSouthAmerica>
    <GroupWorld>Мир</GroupWorld>
    <LinkDataForm>Данные</LinkDataForm>
    <MapEditorForm>Редактор карт</MapEditorForm>
  </Map>
  <Messages>
    <MessageTimeOutExpired>Истекло время ожидания исполнения команды!</MessageTimeOutExpired>
    <TextRegistrationSuccessfully>Регистрация прошла успешно!</TextRegistrationSuccessfully>
    <ThisFieldIsNotSpecified>Это поле не заполнено!</ThisFieldIsNotSpecified>
  </Messages>
  <Notices>
    <AccessDenied>Доступ запрещен!</AccessDenied>
    <AccountLocked>Ваш аккаунт заблокирован! Для решения проблемы, отправьте запрос на sales@stimulsoft.com!</AccountLocked>
    <ActivationExpiriedBeforeFirstRelease>Ваша Программа Подписки для Stimulsoft Reports.Ultimate истекла раньше даты первого релиза Stimulsoft Reports.Server!</ActivationExpiriedBeforeFirstRelease>
    <ActivationLicenseIsNotCorrect>Файл лицензии не корректен! Пожалуйста свяжитесь с нашим отделом продаж на sales@stimulsoft.com!</ActivationLicenseIsNotCorrect>
    <ActivationLockedAccount>Ваш аккаунт заблокирован. Пожалуйста, свяжитесь с нашим отделом продаж по sales@stimulsoft.com!</ActivationLockedAccount>
    <ActivationMaxActivationsReached>Вы достигли максимального количества активаций лицензии. Пожалуйста, свяжитесь с нашим отделом продаж по sales@stimulsoft.com!</ActivationMaxActivationsReached>
    <ActivationServerIsNotAvailableNow>Сервер активации сейчас недоступен! Пожалуйста, попробуйте активировать лицензию позднее.</ActivationServerIsNotAvailableNow>
    <ActivationServerVersionNotAllowed>Вы не можете активировать эту версию Stimulsoft Reports.Server, т.к. Ваша Программа Подписки истекла! Пожалуйста, войдите в Ваш аккаунт на нашем сайте и проверьте, какую версию Stimulsoft Reports.Server Вы можете установить и активировать.</ActivationServerVersionNotAllowed>
    <ActivationSomeTroublesOccurred>Во время активации лицензии произошла ошибка. Пожалуйста, попробуйте активировать лицензию позднее.</ActivationSomeTroublesOccurred>
    <ActivationUserNameOrPasswordIsWrong>Имя пользователя (Email) или пароль указаны неверно!</ActivationUserNameOrPasswordIsWrong>
    <ActivationWrongAccountType>Ваш тип аккаунта не позволяет активировать лицензию Stimulsoft Reports.Server!</ActivationWrongAccountType>
    <AuthAccountCantBeUsedNow>Аккаунт не может быть использован сейчас!</AuthAccountCantBeUsedNow>
    <AuthAccountIsNotActivated>Этот аккаунт не активирован! Пожалуйста, следуйте инструкциям, которые были отправлены на Ваш Email во время регистрации.</AuthAccountIsNotActivated>
    <AuthCantChangeRoleBecauseLastAdministratorUser>Роль данного пользователя не может быть изменена, т.к. это последний администратор в этом рабочем пространстве.</AuthCantChangeRoleBecauseLastAdministratorUser>
    <AuthCantChangeRoleBecauseLastSupervisorUser>Пользовательская роль не может быть изменена, т.к. это последняя роль супервизора на сервере!</AuthCantChangeRoleBecauseLastSupervisorUser>
    <AuthCantChangeRootUser>Основной пользователь не может быть удален!</AuthCantChangeRootUser>
    <AuthCantChangeSystemRole>Системная роль не может быть изменена!</AuthCantChangeSystemRole>
    <AuthCantDeleteHimselfUser>Пользователь не может удалить сам себя!</AuthCantDeleteHimselfUser>
    <AuthCantDeleteLastAdministratorUser>Данный пользователь не может быть удален, т.к. он является последним администратором в этом рабочем пространстве.</AuthCantDeleteLastAdministratorUser>
    <AuthCantDeleteLastSupervisorUser>Пользователь не может быть удален, т.к. это последний супервизор пользователь на сервере!</AuthCantDeleteLastSupervisorUser>
    <AuthCantDeleteSystemRole>Невозможно удалить системную роль!</AuthCantDeleteSystemRole>
    <AuthCantDisableUserBecauseLastAdministratorUser>Пользователь не может быть выключен потому, что это последний администратор в этом рабочем пространстве!</AuthCantDisableUserBecauseLastAdministratorUser>
    <AuthCantDisableUserBecauseLastSupervisorUser>Пользователь не может быть выключен, т.к. это последний супервизор пользователь на сервере!</AuthCantDisableUserBecauseLastSupervisorUser>
    <AuthFirstNameIsNotSpecified>Имя пользователя не указано!</AuthFirstNameIsNotSpecified>
    <AuthFirstNameNotSpecified>Имя не указано!</AuthFirstNameNotSpecified>
    <AuthLastNameIsNotSpecified>Фамилия пользователя не указана!</AuthLastNameIsNotSpecified>
    <AuthLastNameNotSpecified>Фамилия не указана!</AuthLastNameNotSpecified>
    <AuthOAuthIdNotSpecified>OAuth идентификатор не указан!</AuthOAuthIdNotSpecified>
    <AuthPasswordIsNotCorrect>Пароль неверный!</AuthPasswordIsNotCorrect>
    <AuthPasswordIsNotSpecified>Пароль не указан!</AuthPasswordIsNotSpecified>
    <AuthPasswordIsTooShort>Пароль слишком короткий (требуется минимум 6 символов)!</AuthPasswordIsTooShort>
    <AuthPasswordNotSpecified>Пароль не указан!</AuthPasswordNotSpecified>
    <AuthRoleCantBeDeletedBecauseUsedByUsers>Нельзя удалить эту роль, поскольку она используется пользователями!</AuthRoleCantBeDeletedBecauseUsedByUsers>
    <AuthRoleNameAlreadyExists>Роль с указанным именем "{0}" уже существует!</AuthRoleNameAlreadyExists>
    <AuthRoleNameIsSystemRole>Роль с указанным именем "{0}" это системная роль!</AuthRoleNameIsSystemRole>
    <AuthSendMessageWithInstructions>На адрес электронной почты "{0}" отправлено сообщение с дальнейшими инструкциями!</AuthSendMessageWithInstructions>
    <AuthUserHasLoggedOut>Вы вышли из системы!</AuthUserHasLoggedOut>
    <AuthUserNameAlreadyExist>Указанное имя пользователя уже используется!</AuthUserNameAlreadyExist>
    <AuthUserNameAlreadyExists>Имя пользователя (Email) уже используется!</AuthUserNameAlreadyExists>
    <AuthUserNameIsNotSpecified>Имя пользователя (Email) не указано!</AuthUserNameIsNotSpecified>
    <AuthUserNameNotSpecified>Имя пользователя не указано!</AuthUserNameNotSpecified>
    <AuthUserNameOrPasswordIsNotCorrect>Имя пользователя или пароль указаны неверно!</AuthUserNameOrPasswordIsNotCorrect>
    <AuthUserNameShouldLookLikeAnEmailAddress>Необходимо указать Email адрес для имени пользователя!</AuthUserNameShouldLookLikeAnEmailAddress>
    <AuthWorkspaceNameAlreadyInUse>Наименование рабочего пространства уже используется!</AuthWorkspaceNameAlreadyInUse>
    <CommandTimeOut>Время ожидания выполнения команды истекло!</CommandTimeOut>
    <Congratulations>Поздравления!</Congratulations>
    <EndDateShouldBeGreaterThanCurrentDate>Дата завершения должна быть больше текущей даты!</EndDateShouldBeGreaterThanCurrentDate>
    <EndDateShouldBeGreaterThanStartDate>Дата завершения должна быть больше даты начала!</EndDateShouldBeGreaterThanStartDate>
    <EnterCurrentPassword>Введите текущий пароль!</EnterCurrentPassword>
    <EnterNewPassword>Введите новый пароль!</EnterNewPassword>
    <ExecutionError>Ошибка запуска</ExecutionError>
    <IsIdentical>"{0}" и "{1}" идентичны."</IsIdentical>
    <IsNotAuthorized>Нет доступа к "{0}"!</IsNotAuthorized>
    <IsNotCorrect>"{0}" указан неверно!</IsNotCorrect>
    <IsNotDeleted>"{0}" не удален!</IsNotDeleted>
    <IsNotEqual>"{0}" не равны!</IsNotEqual>
    <IsNotFound>"{0}" не найден!</IsNotFound>
    <IsNotRecognized>"{0}" не распознан!</IsNotRecognized>
    <IsNotSpecified>"{0}" не указан!</IsNotSpecified>
    <IsRequiredFile>Необходимо добавить хотя бы один файл!</IsRequiredFile>
    <ItemCantBeAttachedToItself>Нельзя присоединить элемент к самому себе!</ItemCantBeAttachedToItself>
    <ItemCantBeDeletedBecauseItemIsAttachedToOtherItems>Элементы "{0}" не могут быть удалены, потому что используются в других элементах!</ItemCantBeDeletedBecauseItemIsAttachedToOtherItems>
    <ItemCantBeDeletedBecausePermissionsRole>Нельзя удалить следующие элементы: </ItemCantBeDeletedBecausePermissionsRole>
    <ItemCantBeMovedToSpecifiedPlace>Элемент не может быть перемещен в указанное место!</ItemCantBeMovedToSpecifiedPlace>
    <ItemDoesNotSupport>Элемент не поддерживает "{0}"!</ItemDoesNotSupport>
    <KeyAndToKeyAreEqual>Key и ToKey равны!</KeyAndToKeyAreEqual>
    <NewProduct>К Вашей подписке добавлен новый продукт!</NewProduct>
    <NotificationFailed>Неверный результат!</NotificationFailed>
    <NotificationFilesUploadingComplete>Файлы успешно загружены.</NotificationFilesUploadingComplete>
    <NotificationFileUploading>Загружается "{0}".</NotificationFileUploading>
    <NotificationItemDelete>Элементы удаляются.</NotificationItemDelete>
    <NotificationItemDeleteComplete>Удаление элементов завершено успешно.</NotificationItemDeleteComplete>
    <NotificationItemRestore>Элементы восстанавливаются.</NotificationItemRestore>
    <NotificationItemRestoreComplete>Восстановление элементов завершено успешно.</NotificationItemRestoreComplete>
    <NotificationItemTransfer>Элементы перемещаются.</NotificationItemTransfer>
    <NotificationItemTransferComplete>Элементы перемещены успешно.</NotificationItemTransferComplete>
    <NotificationItemWaitingProcessing>Элементы ожидают обработки.</NotificationItemWaitingProcessing>
    <NotificationMailing>Рассылка</NotificationMailing>
    <NotificationMailingComplete>Рассылка "{0}" завершена.</NotificationMailingComplete>
    <NotificationMailingWaitingProcessing>Рассылка "{0}" ожидает обработки.</NotificationMailingWaitingProcessing>
    <NotificationOperationAborted>Операция прервана!</NotificationOperationAborted>
    <NotificationRecycleBinCleaning>Корзина очищается.</NotificationRecycleBinCleaning>
    <NotificationRecycleBinCleaningComplete>Очистка корзхины завершена успешно.</NotificationRecycleBinCleaningComplete>
    <NotificationRecycleBinWaitingProcessing>Ожидается очистка корзины.</NotificationRecycleBinWaitingProcessing>
    <NotificationReportExporting>Отчет "{0}" экспортируется</NotificationReportExporting>
    <NotificationReportExportingComplete>Отчет "{0}" экспортирован успешно.</NotificationReportExportingComplete>
    <NotificationReportExportingTaskIsCreated>Задача экспорта отчета "{0}" создана.</NotificationReportExportingTaskIsCreated>
    <NotificationReportRendering>Отчет "{0}" строится</NotificationReportRendering>
    <NotificationReportRenderingComplete>Отчет "{0}" построен.</NotificationReportRenderingComplete>
    <NotificationReportRenderingTaskIsCreated>Задача построения отчета "{0}" создана.</NotificationReportRenderingTaskIsCreated>
    <NotificationReportWaitingProcessing>Отчет "{0}" ожидает обработки.</NotificationReportWaitingProcessing>
    <NotificationSchedulerRunning>Планировщик "{0}" работает.</NotificationSchedulerRunning>
    <NotificationSchedulerRunningComplete>Планировщик "{0}" завершил работу.</NotificationSchedulerRunningComplete>
    <NotificationSchedulerRunningTaskIsCreated>Задача запуска планировщика "{0}" создана.</NotificationSchedulerRunningTaskIsCreated>
    <NotificationSchedulerWaitingProcessing>Планировщик "{0}" ожидает обработки.</NotificationSchedulerWaitingProcessing>
    <NotificationSoftwareBackupProcessing>Выполняется резервное копирование "{0}".</NotificationSoftwareBackupProcessing>
    <NotificationSoftwareDownloading>Загрузка "{0}".</NotificationSoftwareDownloading>
    <NotificationSoftwareInstallationComplete>Установка "{0}" завершена успешно.</NotificationSoftwareInstallationComplete>
    <NotificationSoftwareInstallationWaitingProcessing>Ожидается установка "{0}".</NotificationSoftwareInstallationWaitingProcessing>
    <NotificationSoftwareInstalling>Установка "{0}".</NotificationSoftwareInstalling>
    <NotificationSoftwareNewVersionAvailable>Версия "{0}" доступна!</NotificationSoftwareNewVersionAvailable>
    <NotificationSoftwareNewVersionError>Произошла ошибка во время проверки наличия новой версии!</NotificationSoftwareNewVersionError>
    <NotificationTitleFilesUploading>Загрузка файлов</NotificationTitleFilesUploading>
    <NotificationTitleItemRefreshing>Обновление элемента "{0}"</NotificationTitleItemRefreshing>
    <NotificationTitleItemTransferring>Перемещение элемента "{0}"</NotificationTitleItemTransferring>
    <NotificationTitleMailing>Рассылка "{0}"</NotificationTitleMailing>
    <NotificationTitleReportExporting>Экспорт отчета "{0}"</NotificationTitleReportExporting>
    <NotificationTitleReportRendering>Построение отчета "{0}"</NotificationTitleReportRendering>
    <NotificationTitleSchedulerRunning>Запуск планировщика "{0}"</NotificationTitleSchedulerRunning>
    <NotificationTitleSoftwareUpdate>"{0}" обновление</NotificationTitleSoftwareUpdate>
    <NotificationTransferring>"{0}" перемещен в "{1}".</NotificationTransferring>
    <NotificationTransferringComplete>"{0}" успешно перемещен в "{1}".</NotificationTransferringComplete>
    <NotificationValueIsNotCorrect>Значение некорректно для этого типа {0}</NotificationValueIsNotCorrect>
    <OutOfRange>Выход за пределы диапазона ("{0}")!</OutOfRange>
    <ParsingCommandException>Исключение обработки команды в указанном xml: {0}</ParsingCommandException>
    <QuotaAvailableSpaceAmountExceeded>Доступное пространство исчерпано!</QuotaAvailableSpaceAmountExceeded>
    <QuotaMaximumBandwidthExceeded>Достигнут лимит по трафику.</QuotaMaximumBandwidthExceeded>
    <QuotaMaximumComputingCyclesCountExceeded>Лимит на циклы вычислений превышен.</QuotaMaximumComputingCyclesCountExceeded>
    <QuotaMaximumDashboardsCountExceeded>Достигнут лимит на количество панелей индикаторов!</QuotaMaximumDashboardsCountExceeded>
    <QuotaMaximumDataRowsCountExceeded>Достигнут лимит на количество строк данных в одном источнике данных!</QuotaMaximumDataRowsCountExceeded>
    <QuotaMaximumDataSourceItemCountExceeded>Максимальное количество источников данных превышено.</QuotaMaximumDataSourceItemCountExceeded>
    <QuotaMaximumDataSourcesCountExceeded>Достигнут лимит на количество источников данных!</QuotaMaximumDataSourcesCountExceeded>
    <QuotaMaximumEmailCountExceeded>Максимальное количество Email превышено.</QuotaMaximumEmailCountExceeded>
    <QuotaMaximumFileSizeExceeded>Максимальный размер файла превышен!</QuotaMaximumFileSizeExceeded>
    <QuotaMaximumItemCountExceeded>Максимальное количество элементов превышено.</QuotaMaximumItemCountExceeded>
    <QuotaMaximumItemsCountExceeded>Достигнут лимит на количество элементов!</QuotaMaximumItemsCountExceeded>
    <QuotaMaximumReportPagesCountExceeded>Достигнут лимит на количество страниц в отчете!</QuotaMaximumReportPagesCountExceeded>
    <QuotaMaximumReportsCountExceeded>Достигнут лимит на количество отчетов!</QuotaMaximumReportsCountExceeded>
    <QuotaMaximumResourcesCountExceeded>Достигнут лимит на максимальный размер ресурсов!</QuotaMaximumResourcesCountExceeded>
    <QuotaMaximumRoleCountExceeded>Максимальное количество пользовательских ролей превышено.</QuotaMaximumRoleCountExceeded>
    <QuotaMaximumRolesCountExceeded>Достигнут лимит на количество пользовательских ролей!</QuotaMaximumRolesCountExceeded>
    <QuotaMaximumSchedulersCountExceeded>Максимальное количество планировщиков превышено.</QuotaMaximumSchedulersCountExceeded>
    <QuotaMaximumUserCountExceeded>Максимальное количество пользователей превышено.</QuotaMaximumUserCountExceeded>
    <QuotaMaximumUsersCountExceeded>Достигнут лимит на количество пользователей!</QuotaMaximumUsersCountExceeded>
    <QuotaMaximumWorkspaceCountExceeded>Максимальное количество рабочих пространств превышено.</QuotaMaximumWorkspaceCountExceeded>
    <QuotaMaximumWorkspacesCountExceeded>Достигнут лимит на количество рабочих пространств!</QuotaMaximumWorkspacesCountExceeded>
    <QuotasSizeLimitIsExceeded>Превышен лимит доступного места, занято "{0}" Гб!</QuotasSizeLimitIsExceeded>
    <SchedulerCantRunItSelf>Планировщик не может запустить сам себя!</SchedulerCantRunItSelf>
    <SessionTimeOut>Время сессии истекло</SessionTimeOut>
    <SnapshotAlreadyProcessed>Снимок отчета уже обработан!</SnapshotAlreadyProcessed>
    <SpecifiedItemIsNot>Указанный элемент не "{0}"!</SpecifiedItemIsNot>
    <SubscriptionExpired>Срок действия Вашей подписки истек!</SubscriptionExpired>
    <SubscriptionsOut10>10 дней осталось, до завершения срока действия подписки!</SubscriptionsOut10>
    <SubscriptionsOut20>Срок действия подписки истекает через 20 дней!</SubscriptionsOut20>
    <SuccessfullyRenewed>Ваша подписка была успешно обновлена!</SuccessfullyRenewed>
    <TrialToLicense>Спасибо, что выбрали Stimulsoft!</TrialToLicense>
    <VersionCopyFromItem>Копия элемента "{0}".</VersionCopyFromItem>
    <VersionCreatedFromFile>Создано из файла "{0}".</VersionCreatedFromFile>
    <VersionCreatedFromItem>Создано из элемента "{0}".</VersionCreatedFromItem>
    <VersionLoadedFromFile>Загружено из файла "{0}".</VersionLoadedFromFile>
    <VersionNewItemCreation>Новый элемент создан.</VersionNewItemCreation>
    <WindowClosePreventWhileUploading>Некоторые файлы загружаются сейчас, если страница будет закрыта сейчас, эти файлы будут утеряны! Вы действительно хотите закрыть страницу?</WindowClosePreventWhileUploading>
    <WithSpecifiedKeyIsNotFound>"{0}" с указанным ключом не найден!</WithSpecifiedKeyIsNotFound>
    <YourTimeSessionHasExpired>Время сессии истекло!</YourTimeSessionHasExpired>
    <YouUsingTrialVersion>Вы используете тестовую версию!</YouUsingTrialVersion>
  </Notices>
  <NuGet>
    <AlreadyDownloaded>Уже скачано</AlreadyDownloaded>
    <AssemblyLoadedSuccessfully>Сборка загружена успешно.</AssemblyLoadedSuccessfully>
    <AssemblyNotFound>Невозможно найти {0} сборку для того, чтобы использовать {1} соединение!</AssemblyNotFound>
    <Author>Автор</Author>
    <Dependencies>Зависимости:</Dependencies>
    <Download>Скачать</Download>
    <DownloadAll>Скачать все</DownloadAll>
    <DownloadAndInstall>Скачать и Установить</DownloadAndInstall>
    <Downloads>Скачиваний:</Downloads>
    <IAccept>Я Согласен</IAccept>
    <IDecline>Я Отказываюсь</IDecline>
    <LicenceFormDesc>Данный пакет требует, чтобы вы приняли условия лицензии перед установкой.</LicenceFormDesc>
    <LicenceFormDesc1>Нажав "Я Согласен" вы соглашаетесь с условиями лицензии на пакет, которые перечисленны выше. Если вы не согласны с условиями лицензии, нажмите "Я Отказываюсь".</LicenceFormDesc1>
    <LicenceFormTitle>Лицензионное соглашение</LicenceFormTitle>
    <License>Лицензия:</License>
    <ProjectUrl>Ссылка проекта:</ProjectUrl>
    <ReportAbuse>Сообщить о нарушениях:</ReportAbuse>
    <RetrievingInformation>Получение информации ...</RetrievingInformation>
    <Tags>Ключевые слова:</Tags>
    <Title>Адаптер данных с NuGet</Title>
    <ViewLicense>Просмотреть лицензию</ViewLicense>
  </NuGet>
  <Panels>
    <Dictionary>Словарь</Dictionary>
    <Messages>Сообщения</Messages>
    <Properties>Свойства</Properties>
    <ReportTree>Дерево отчета</ReportTree>
  </Panels>
  <Password>
    <gbPassword>Шифрование файла</gbPassword>
    <lbPasswordLoad>Введите пароль для открытия этого файла</lbPasswordLoad>
    <lbPasswordSave>Пароль:</lbPasswordSave>
    <PasswordNotEntered>Пароль не введен</PasswordNotEntered>
    <StiLoadPasswordForm>Шифрование документа</StiLoadPasswordForm>
    <StiSavePasswordForm>Пароль</StiSavePasswordForm>
  </Password>
  <Permissions>
    <AdminAPI>API</AdminAPI>
    <AdminBackgroundTasks>Задачи в фоне</AdminBackgroundTasks>
    <AdminPermissions>Разрешения</AdminPermissions>
    <AdminRecycleBin>Корзина</AdminRecycleBin>
    <AdminShare>Доступ</AdminShare>
    <AdminTransfers>Перемещения</AdminTransfers>
    <ItemCalendars>Календари</ItemCalendars>
    <ItemCloudStorages>Облачные хранилища</ItemCloudStorages>
    <ItemContactLists>Списки контактов</ItemContactLists>
    <ItemDashboards>Панели индикаторов</ItemDashboards>
    <ItemDataSources>Источники данных</ItemDataSources>
    <ItemFiles>Файлы</ItemFiles>
    <ItemFolders>Папки</ItemFolders>
    <ItemReportSnapshots>Снимки отчетов</ItemReportSnapshots>
    <ItemReportTemplates>Шаблоны отчетов</ItemReportTemplates>
    <ItemSchedulers>Планировщики</ItemSchedulers>
    <LabelAdministration>Администрирование:</LabelAdministration>
    <LabelItems>Элементы:</LabelItems>
    <LabelUsers>Пользователи:</LabelUsers>
    <ReportDesignerBusinessObjects>Бизнес объекты</ReportDesignerBusinessObjects>
    <ReportDesignerDataColumns>Колонки данных</ReportDesignerDataColumns>
    <ReportDesignerDataConnections>Соединения</ReportDesignerDataConnections>
    <ReportDesignerDataRelations>Связи</ReportDesignerDataRelations>
    <ReportDesignerDataSources>Источники данных</ReportDesignerDataSources>
    <ReportDesignerDictionaryActions>Действия со словарем</ReportDesignerDictionaryActions>
    <ReportDesignerRestrictions>Ограничения</ReportDesignerRestrictions>
    <ReportDesignerVariables>Переменные</ReportDesignerVariables>
    <SystemBackupRestore>Резервное копирование</SystemBackupRestore>
    <SystemEmailTemplates>Шаблоны Email</SystemEmailTemplates>
    <SystemLicensing>Лицензирование</SystemLicensing>
    <SystemMonitoring>Мониторинг</SystemMonitoring>
    <SystemStatus>Статус</SystemStatus>
    <SystemUpdate>Обновление</SystemUpdate>
    <SystemWebsite>Веб-сайт</SystemWebsite>
    <SystemWebSite>Веб-сайт</SystemWebSite>
    <SystemWorkspaces>Рабочие пространства</SystemWorkspaces>
    <TextAdministration>Администрирование</TextAdministration>
    <TextCommon>Общие</TextCommon>
    <TextDesign>Дизайнер отчетов</TextDesign>
    <TextItems>Элементы</TextItems>
    <TextReportDesigner>Дизайнер отчетов</TextReportDesigner>
    <TextSystem>Система</TextSystem>
    <TextUsers>Пользователи</TextUsers>
    <UserHimself>Сам</UserHimself>
    <UserRoles>Роли</UserRoles>
    <Users>Пользователи</Users>
    <UserWorkspace>Рабочее пространство</UserWorkspace>
  </Permissions>
  <PlacementComponent>
    <MoveLeftFreeSpace>Перемещает компонент к левой части свободного пространства, увеличивая его высоту до высоты свободного пространства.</MoveLeftFreeSpace>
    <MoveRightFreeSpace>Перемещает компонент к правой части свободного пространства, увеличивая его высоту до высоты свободного пространства.</MoveRightFreeSpace>
  </PlacementComponent>
  <PropertyCategory>
    <AppearanceCategory>Оформление</AppearanceCategory>
    <AreaCategory>Область</AreaCategory>
    <ArgumentCategory>Аргумент</ArgumentCategory>
    <AxisCategory>Ось</AxisCategory>
    <BarCodeAdditionalCategory>Штрих-код, дополнительные</BarCodeAdditionalCategory>
    <BarCodeCategory>Штрих-код</BarCodeCategory>
    <BehaviorCategory>Поведение</BehaviorCategory>
    <CapNeedle>Основание стрелки</CapNeedle>
    <CellCategory>Ячейка</CellCategory>
    <ChartAdditionalCategory>Диаграмма, дополнительные</ChartAdditionalCategory>
    <ChartCategory>Диаграмма</ChartCategory>
    <ChartMap>Карта</ChartMap>
    <CheckCategory>Флажок</CheckCategory>
    <ColorsCategory>Цвета</ColorsCategory>
    <ColumnsCategory>Колонки</ColumnsCategory>
    <ControlCategory>Управление</ControlCategory>
    <ControlsEventsCategory>События элементов управления</ControlsEventsCategory>
    <CrossTabCategory>Кросс-таблица</CrossTabCategory>
    <DataCategory>Данные</DataCategory>
    <DescriptionCategory>Описание</DescriptionCategory>
    <DesignCategory>Редактирование</DesignCategory>
    <DisplayCategory>Отображение</DisplayCategory>
    <ExportCategory>Экспорт</ExportCategory>
    <ExportEventsCategory>События экспорта</ExportEventsCategory>
    <FooterTableCategory>Итог таблицы</FooterTableCategory>
    <GridLinesCategory>Линии сетки</GridLinesCategory>
    <HeaderTableCategory>Заголовок таблицы</HeaderTableCategory>
    <HierarchicalCategory>Дерево</HierarchicalCategory>
    <ImageAdditionalCategory>Картинка, дополнительные</ImageAdditionalCategory>
    <ImageCategory>Картинка</ImageCategory>
    <InterlacingCategory>Чередование</InterlacingCategory>
    <LegendCategory>Легенда</LegendCategory>
    <MainCategory>Основные</MainCategory>
    <MarkerCategory>Маркер</MarkerCategory>
    <MiscCategory>Прочие</MiscCategory>
    <MouseEventsCategory>Мышь</MouseEventsCategory>
    <NavigationCategory>Навигация</NavigationCategory>
    <NavigationEventsCategory>Навигация</NavigationEventsCategory>
    <Needle>Стрелка</Needle>
    <OptionsCategory>Параметры</OptionsCategory>
    <PageAdditionalCategory>Страница, дополнительные</PageAdditionalCategory>
    <PageCategory>Страница</PageCategory>
    <PageColumnBreakCategory>Разрыв страницы или колонки</PageColumnBreakCategory>
    <ParametersCategory>Параметры</ParametersCategory>
    <PositionCategory>Расположение</PositionCategory>
    <PrimitiveCategory>Примитив</PrimitiveCategory>
    <PrintEventsCategory>Печать</PrintEventsCategory>
    <RenderEventsCategory>Построение</RenderEventsCategory>
    <SeriesCategory>Серии</SeriesCategory>
    <SeriesLabelsCategory>Заголовки рядов</SeriesLabelsCategory>
    <ShapeCategory>Геометрия</ShapeCategory>
    <Size>Размер</Size>
    <SubReportCategory>Суб-отчеты</SubReportCategory>
    <TableCategory>Таблица</TableCategory>
    <TextAdditionalCategory>Текст, дополнительные</TextAdditionalCategory>
    <TextCategory>Текст</TextCategory>
    <TitleCategory>Заголовок</TitleCategory>
    <TrendLineCategory>Линия тренда</TrendLineCategory>
    <ValueCategory>Значение</ValueCategory>
    <ValueCloseCategory>Закрывающее значение</ValueCloseCategory>
    <ValueEndCategory>Конечное значение</ValueEndCategory>
    <ValueEventsCategory>Значения</ValueEventsCategory>
    <ValueHighCategory>Максимум</ValueHighCategory>
    <ValueLowCategory>Минимум</ValueLowCategory>
    <ValueOpenCategory>Открывающее значение</ValueOpenCategory>
    <WeightCategory>Вес</WeightCategory>
    <WinControlCategory>Win компонент</WinControlCategory>
    <ZipCodeCategory>Почтовый индекс</ZipCodeCategory>
  </PropertyCategory>
  <PropertyColor>
    <AliceBlue>Акварельно-синий</AliceBlue>
    <AntiqueWhite>Белый-антик</AntiqueWhite>
    <Aqua>Циан акварельный</Aqua>
    <Aquamarine>Аквамарин</Aquamarine>
    <Azure>Лазурный</Azure>
    <Beige>Бежевый</Beige>
    <Bisque>Светло-коричневый</Bisque>
    <Black>Черный</Black>
    <BlanchedAlmond>Бледно-миндальный</BlanchedAlmond>
    <Blue>Синий</Blue>
    <BlueViolet>Сине-фиолетовый</BlueViolet>
    <Brown>Коричневый</Brown>
    <BurlyWood>Древесный</BurlyWood>
    <CadetBlue>Серо-синий</CadetBlue>
    <Chartreuse>Зеленовато-желтый</Chartreuse>
    <Chocolate>Шоколадный</Chocolate>
    <Coral>Коралловый</Coral>
    <CornflowerBlue>Васильковый</CornflowerBlue>
    <Cornsilk>Шелковый оттенок</Cornsilk>
    <Crimson>Малиновый</Crimson>
    <Cyan>Циан</Cyan>
    <DarkBlue>Темно-синий</DarkBlue>
    <DarkCyan>Циан темный</DarkCyan>
    <DarkGoldenrod>Темно-золотистый</DarkGoldenrod>
    <DarkGray>Темно-серый</DarkGray>
    <DarkGreen>Темно-зеленый</DarkGreen>
    <DarkKhaki>Хаки темный</DarkKhaki>
    <DarkMagenta>Фуксин темный</DarkMagenta>
    <DarkOliveGreen>Темно-оливково-зеленый</DarkOliveGreen>
    <DarkOrange>Темно-оранжевый</DarkOrange>
    <DarkOrchid>Орхидея темный</DarkOrchid>
    <DarkRed>Темно-красный</DarkRed>
    <DarkSalmon>Лосось темный</DarkSalmon>
    <DarkSeaGreen>Морская волна темный</DarkSeaGreen>
    <DarkSlateBlue>Темно-грифельно-синий</DarkSlateBlue>
    <DarkSlateGray>Темно-грифельно-серый</DarkSlateGray>
    <DarkTurquoise>Темно-бирюзовый</DarkTurquoise>
    <DarkViolet>Темно-фиолетовый</DarkViolet>
    <DeepPink>Насыщенно-розовый</DeepPink>
    <DeepSkyBlue>Насыщенно-небесно-голубой</DeepSkyBlue>
    <DimGray>Тускло-серый</DimGray>
    <DodgerBlue>Серо-синий</DodgerBlue>
    <Firebrick>Кирпичный</Firebrick>
    <FloralWhite>Цветок белый</FloralWhite>
    <ForestGreen>Зеленый лес</ForestGreen>
    <Fuchsia>Фуксия</Fuchsia>
    <Gainsboro>Серебристо-серый</Gainsboro>
    <GhostWhite>Призрачно-белый</GhostWhite>
    <Gold>Золотой</Gold>
    <Goldenrod>Золотистый</Goldenrod>
    <Gray>Серый</Gray>
    <Green>Зеленый</Green>
    <GreenYellow>Зелено-желтый</GreenYellow>
    <Honeydew>Медовый</Honeydew>
    <HotPink>Тепло-розовый</HotPink>
    <IndianRed>Киноварь</IndianRed>
    <Indigo>Индиго</Indigo>
    <Ivory>Слоновая кость</Ivory>
    <Khaki>Хаки</Khaki>
    <Lavender>Бледно-лиловый</Lavender>
    <LavenderBlush>Голубой с красным отливом</LavenderBlush>
    <LawnGreen>Зеленая лужайка</LawnGreen>
    <LemonChiffon>Лимонный</LemonChiffon>
    <LightBlue>Голубой</LightBlue>
    <LightCoral>Светло-коралловый</LightCoral>
    <LightCyan>Циан светлый</LightCyan>
    <LightGoldenrodYellow>Светло-золотистый</LightGoldenrodYellow>
    <LightGray>Светло-серый</LightGray>
    <LightGreen>Светло-зеленый</LightGreen>
    <LightPink>Светло-розовый</LightPink>
    <LightSalmon>Лосось светлый</LightSalmon>
    <LightSeaGreen>Цвет морской волны светлый</LightSeaGreen>
    <LightSkyBlue>Светло-небесно-голубой</LightSkyBlue>
    <LightSlateGray>Светло-грифельно-синий</LightSlateGray>
    <LightSteelBlue>Голубой со стальным оттенком</LightSteelBlue>
    <LightYellow>Светло-желтый</LightYellow>
    <Lime>Зеленовато-лимонный</Lime>
    <LimeGreen>Лимонно-зеленый</LimeGreen>
    <Linen>Льняной</Linen>
    <Magenta>Фуксин</Magenta>
    <Maroon>Темно-бордовый</Maroon>
    <MediumAquamarine>Нейтрально-аквамариновый</MediumAquamarine>
    <MediumBlue>Нейтрально-синий</MediumBlue>
    <MediumOrchid>Орхидея нейтральный</MediumOrchid>
    <MediumPurple>Нейтрально-пурпурный</MediumPurple>
    <MediumSeaGreen>Цвет морской волны нейтральный</MediumSeaGreen>
    <MediumSlateBlue>Нейтрально-грифельно-синий</MediumSlateBlue>
    <MediumSpringGreen>Нейтрально-весенне-зеленый</MediumSpringGreen>
    <MediumTurquoise>Нейтрально-бирюзовый</MediumTurquoise>
    <MediumVioletRed>Нейтрально-фиолетово-красный</MediumVioletRed>
    <MidnightBlue>Полночно-синий</MidnightBlue>
    <MintCream>Мятный крем</MintCream>
    <MistyRose>Тускло-розовый</MistyRose>
    <Moccasin>Замша светлый</Moccasin>
    <NavajoWhite>Белый-навахо</NavajoWhite>
    <Navy>Ультрамарин</Navy>
    <OldLace>Старое кружево</OldLace>
    <Olive>Оливковый</Olive>
    <OliveDrab>Тускло-оливковый</OliveDrab>
    <Orange>Оранжевый</Orange>
    <OrangeRed>Оранжево-красный</OrangeRed>
    <Orchid>Орхидея</Orchid>
    <PaleGoldenrod>Бледно-золотистый</PaleGoldenrod>
    <PaleGreen>Бледно-зеленый</PaleGreen>
    <PaleTurquoise>Бледно-бирюзовый</PaleTurquoise>
    <PaleVioletRed>Бледно-красно-фиолетовый</PaleVioletRed>
    <PapayaWhip>Топленое молоко</PapayaWhip>
    <PeachPuff>Персиковый</PeachPuff>
    <Peru>Нейтрально-коричневый</Peru>
    <Pink>Розовый</Pink>
    <Plum>Сливовый</Plum>
    <PowderBlue>Синий с пороховым оттенком</PowderBlue>
    <Purple>Пурпурный</Purple>
    <Red>Красный</Red>
    <RosyBrown>Розово-коричневый</RosyBrown>
    <RoyalBlue>Королевский синий</RoyalBlue>
    <SaddleBrown>Кожано-коричневый</SaddleBrown>
    <Salmon>Лосось</Salmon>
    <SandyBrown>Песочно-коричневый</SandyBrown>
    <SeaGreen>Цвет морской волны</SeaGreen>
    <SeaShell>Перламутровый</SeaShell>
    <Sienna>Охра</Sienna>
    <Silver>Серебряный</Silver>
    <SkyBlue>Небесно-голубой</SkyBlue>
    <SlateBlue>Грифельно-синий</SlateBlue>
    <SlateGray>Грифельно-серый</SlateGray>
    <Snow>Белоснежный</Snow>
    <SpringGreen>Весенне-зеленый</SpringGreen>
    <SteelBlue>Синий со стальным оттенком</SteelBlue>
    <Tan>Рыжевато-коричневый</Tan>
    <Teal>Циан нейтральный</Teal>
    <Thistle>Бледно-сиреневый</Thistle>
    <Tomato>Томатный</Tomato>
    <Transparent>Прозрачный</Transparent>
    <Turquoise>Бирюзовый</Turquoise>
    <Violet>Фиолетовый</Violet>
    <Wheat>Пшеничный</Wheat>
    <White>Белый</White>
    <WhiteSmoke>Дымчато-белый</WhiteSmoke>
    <Yellow>Желтый</Yellow>
    <YellowGreen>Желто-зеленый</YellowGreen>
  </PropertyColor>
  <PropertyEnum>
    <boolFalse>Нет</boolFalse>
    <boolTrue>Да</boolTrue>
    <BorderStyleFixed3D>Трехмерная</BorderStyleFixed3D>
    <BorderStyleFixedSingle>Одинарная</BorderStyleFixedSingle>
    <BorderStyleNone>Нет</BorderStyleNone>
    <ChartAxesTicksAll>Все</ChartAxesTicksAll>
    <ChartAxesTicksMajor>Главные</ChartAxesTicksMajor>
    <ChartAxesTicksNone>Нет</ChartAxesTicksNone>
    <ChartGridLinesAll>Все</ChartGridLinesAll>
    <ChartGridLinesMajor>Главные</ChartGridLinesMajor>
    <ChartGridLinesNone>Нет</ChartGridLinesNone>
    <ComboBoxStyleDropDown>Выпадающий список</ComboBoxStyleDropDown>
    <ComboBoxStyleDropDownList>Только список</ComboBoxStyleDropDownList>
    <ComboBoxStyleSimple>Простой</ComboBoxStyleSimple>
    <ContentAlignmentBottomCenter>Снизу по центру</ContentAlignmentBottomCenter>
    <ContentAlignmentBottomLeft>Снизу с левого края</ContentAlignmentBottomLeft>
    <ContentAlignmentBottomRight>Снизу с правого края</ContentAlignmentBottomRight>
    <ContentAlignmentMiddleCenter>По середине по центру</ContentAlignmentMiddleCenter>
    <ContentAlignmentMiddleLeft>По середине с левого края</ContentAlignmentMiddleLeft>
    <ContentAlignmentMiddleRight>По середине с правого края</ContentAlignmentMiddleRight>
    <ContentAlignmentTopCenter>Сверху по центру</ContentAlignmentTopCenter>
    <ContentAlignmentTopLeft>Сверху по левому краю</ContentAlignmentTopLeft>
    <ContentAlignmentTopRight>Сверху по правому краю</ContentAlignmentTopRight>
    <DataGridLineStyleNone>Нет</DataGridLineStyleNone>
    <DataGridLineStyleSolid>Сплошная</DataGridLineStyleSolid>
    <DateTimePickerFormatCustom>По выбору</DateTimePickerFormatCustom>
    <DateTimePickerFormatLong>Длинный</DateTimePickerFormatLong>
    <DateTimePickerFormatShort>Короткий</DateTimePickerFormatShort>
    <DateTimePickerFormatTime>Время</DateTimePickerFormatTime>
    <DialogResultAbort>Прервать</DialogResultAbort>
    <DialogResultCancel>Отмена</DialogResultCancel>
    <DialogResultIgnore>Игнорировать</DialogResultIgnore>
    <DialogResultNo>Нет</DialogResultNo>
    <DialogResultNone>Не указано</DialogResultNone>
    <DialogResultOK>Ок</DialogResultOK>
    <DialogResultRetry>Повторить</DialogResultRetry>
    <DialogResultYes>Да</DialogResultYes>
    <DuplexDefault>По умолчанию</DuplexDefault>
    <DuplexHorizontal>Горизонтальный</DuplexHorizontal>
    <DuplexSimplex>Симплекс</DuplexSimplex>
    <DuplexVertical>Вертикальный</DuplexVertical>
    <FormStartPositionCenterParent>По центру главного окна</FormStartPositionCenterParent>
    <FormStartPositionCenterScreen>По центру экрана</FormStartPositionCenterScreen>
    <FormStartPositionManual>Ручной</FormStartPositionManual>
    <FormStartPositionWindowsDefaultBounds>Границы по умолчанию</FormStartPositionWindowsDefaultBounds>
    <FormStartPositionWindowsDefaultLocation>Положение по умолчанию</FormStartPositionWindowsDefaultLocation>
    <FormWindowStateMaximized>Разворачивать</FormWindowStateMaximized>
    <FormWindowStateMinimized>Сворачивать</FormWindowStateMinimized>
    <FormWindowStateNormal>Нормальное</FormWindowStateNormal>
    <HorizontalAlignmentCenter>По центру</HorizontalAlignmentCenter>
    <HorizontalAlignmentLeft>По левому краю</HorizontalAlignmentLeft>
    <HorizontalAlignmentRight>По правому краю</HorizontalAlignmentRight>
    <HotkeyPrefixHide>Не показывать</HotkeyPrefixHide>
    <HotkeyPrefixNone>Игнорировать</HotkeyPrefixNone>
    <HotkeyPrefixShow>Показывать</HotkeyPrefixShow>
    <LeftRightAlignmentLeft>С левой стороны</LeftRightAlignmentLeft>
    <LeftRightAlignmentRight>С правой стороны</LeftRightAlignmentRight>
    <PictureBoxSizeModeAutoSize>Авторазмер</PictureBoxSizeModeAutoSize>
    <PictureBoxSizeModeCenterImage>Центрировать изображение</PictureBoxSizeModeCenterImage>
    <PictureBoxSizeModeNormal>Нормальный</PictureBoxSizeModeNormal>
    <PictureBoxSizeModeStretchImage>Растягивать</PictureBoxSizeModeStretchImage>
    <RightToLeftInherit>Наследовать</RightToLeftInherit>
    <RightToLeftNo>Нет</RightToLeftNo>
    <RightToLeftYes>Да</RightToLeftYes>
    <SelectionModeMultiExtended>Мульти расширенный</SelectionModeMultiExtended>
    <SelectionModeMultiSimple>Мульти простой</SelectionModeMultiSimple>
    <SelectionModeNone>Нет</SelectionModeNone>
    <SelectionModeOne>Один</SelectionModeOne>
    <StiAnchorModeBottom>Снизу</StiAnchorModeBottom>
    <StiAnchorModeLeft>Слева</StiAnchorModeLeft>
    <StiAnchorModeRight>Справа</StiAnchorModeRight>
    <StiAnchorModeTop>Сверху</StiAnchorModeTop>
    <StiAngleAngle0>Угол наклона - 0 градусов</StiAngleAngle0>
    <StiAngleAngle180>Угол наклона - 180 градусов</StiAngleAngle180>
    <StiAngleAngle270>Угол наклона - 270 градусов</StiAngleAngle270>
    <StiAngleAngle45>Угол наклона - 45 градусов</StiAngleAngle45>
    <StiAngleAngle90>Угол наклона - 90 градусов</StiAngleAngle90>
    <StiArrowStyleArc>Дуга</StiArrowStyleArc>
    <StiArrowStyleArcAndCircle>Дуга и круг</StiArrowStyleArcAndCircle>
    <StiArrowStyleCircle>Круг</StiArrowStyleCircle>
    <StiArrowStyleLines>Линии</StiArrowStyleLines>
    <StiArrowStyleNone>Нет</StiArrowStyleNone>
    <StiArrowStyleTriangle>Треугольник</StiArrowStyleTriangle>
    <StiBorderSidesAll>Со всех сторон</StiBorderSidesAll>
    <StiBorderSidesBottom>Снизу</StiBorderSidesBottom>
    <StiBorderSidesLeft>Слева</StiBorderSidesLeft>
    <StiBorderSidesNone>Нет</StiBorderSidesNone>
    <StiBorderSidesRight>Справа</StiBorderSidesRight>
    <StiBorderSidesTop>Сверху</StiBorderSidesTop>
    <StiBorderStyleBump>Вдавленная</StiBorderStyleBump>
    <StiBorderStyleEtched>Выпуклая</StiBorderStyleEtched>
    <StiBorderStyleFlat>Плоской</StiBorderStyleFlat>
    <StiBorderStyleNone>Нет</StiBorderStyleNone>
    <StiBorderStyleRaised>Рельефный</StiBorderStyleRaised>
    <StiBorderStyleRaisedInner>Рельефный внутри</StiBorderStyleRaisedInner>
    <StiBorderStyleRaisedOuter>Рельефный снаружи</StiBorderStyleRaisedOuter>
    <StiBorderStyleSunken>Запавший</StiBorderStyleSunken>
    <StiBorderStyleSunkenInner>Запавший внутри</StiBorderStyleSunkenInner>
    <StiBorderStyleSunkenOuter>Запавший снаружи</StiBorderStyleSunkenOuter>
    <StiBrushTypeGlare>Кисть Блика</StiBrushTypeGlare>
    <StiBrushTypeGradient0>Градиентная кисть, Угол 0</StiBrushTypeGradient0>
    <StiBrushTypeGradient180>Градиентная кисть, Угол 180</StiBrushTypeGradient180>
    <StiBrushTypeGradient270>Градиентная кисть, Угол 270</StiBrushTypeGradient270>
    <StiBrushTypeGradient45>Градиентная кисть, Угол 45</StiBrushTypeGradient45>
    <StiBrushTypeGradient90>Градиентная кисть, Угол 90</StiBrushTypeGradient90>
    <StiBrushTypeSolid>Сплошная кисть</StiBrushTypeSolid>
    <StiCalculationModeCompilation>Компиляция</StiCalculationModeCompilation>
    <StiCalculationModeInterpretation>Интерпретация</StiCalculationModeInterpretation>
    <StiCapStyleArrow>Стрелка</StiCapStyleArrow>
    <StiCapStyleDiamond>Ромбовидная стрелка</StiCapStyleDiamond>
    <StiCapStyleNone>Нет стрелки</StiCapStyleNone>
    <StiCapStyleOpen>Открытая стрелка</StiCapStyleOpen>
    <StiCapStyleOval>Овальная стрелка</StiCapStyleOval>
    <StiCapStyleSquare>Квадратная стрелка</StiCapStyleSquare>
    <StiCapStyleStealth>Вогнутая стрелка</StiCapStyleStealth>
    <StiChartTitleDockBottom>Внизу</StiChartTitleDockBottom>
    <StiChartTitleDockLeft>Слева</StiChartTitleDockLeft>
    <StiChartTitleDockRight>Справа</StiChartTitleDockRight>
    <StiChartTitleDockTop>Сверху</StiChartTitleDockTop>
    <StiCheckStyleCheck>Флажок</StiCheckStyleCheck>
    <StiCheckStyleCheckRectangle>Прямоугольник с флажком</StiCheckStyleCheckRectangle>
    <StiCheckStyleCross>Крестик</StiCheckStyleCross>
    <StiCheckStyleCrossCircle>Перечеркнутый круг</StiCheckStyleCrossCircle>
    <StiCheckStyleCrossRectangle>Перечеркнутый прямоугольник</StiCheckStyleCrossRectangle>
    <StiCheckStyleDotCircle>Круг с точкой</StiCheckStyleDotCircle>
    <StiCheckStyleDotRectangle>Прямоугольник с точкой</StiCheckStyleDotRectangle>
    <StiCheckStyleNone>Нет</StiCheckStyleNone>
    <StiCheckStyleNoneCircle>Пустая окружность</StiCheckStyleNoneCircle>
    <StiCheckStyleNoneRectangle>Пустой прямоугольник</StiCheckStyleNoneRectangle>
    <StiCheckSumNo>Нет</StiCheckSumNo>
    <StiCheckSumYes>Да</StiCheckSumYes>
    <StiCode11CheckSumAuto>Авто</StiCode11CheckSumAuto>
    <StiCode11CheckSumNone>Нет</StiCode11CheckSumNone>
    <StiCode11CheckSumOneDigit>Одна цифра</StiCode11CheckSumOneDigit>
    <StiCode11CheckSumTwoDigits>Две цифры</StiCode11CheckSumTwoDigits>
    <StiColorScaleTypeColor2>Двухцветная шкала</StiColorScaleTypeColor2>
    <StiColorScaleTypeColor3>Трехцветная шкала</StiColorScaleTypeColor3>
    <StiColumnDirectionAcrossThenDown>Вправо затем вниз</StiColumnDirectionAcrossThenDown>
    <StiColumnDirectionDownThenAcross>Вниз затем вправо</StiColumnDirectionDownThenAcross>
    <StiCrossHorAlignmentCenter>По центру</StiCrossHorAlignmentCenter>
    <StiCrossHorAlignmentLeft>По левому краю</StiCrossHorAlignmentLeft>
    <StiCrossHorAlignmentNone>Нет</StiCrossHorAlignmentNone>
    <StiCrossHorAlignmentRight>По правому краю</StiCrossHorAlignmentRight>
    <StiDateTimeTypeDate>Дата</StiDateTimeTypeDate>
    <StiDateTimeTypeDateAndTime>Дата и время</StiDateTimeTypeDateAndTime>
    <StiDateTimeTypeTime>Время</StiDateTimeTypeTime>
    <StiDockStyleBottom>Внизу</StiDockStyleBottom>
    <StiDockStyleFill>Все</StiDockStyleFill>
    <StiDockStyleLeft>Слева</StiDockStyleLeft>
    <StiDockStyleNone>Нет</StiDockStyleNone>
    <StiDockStyleRight>Справа</StiDockStyleRight>
    <StiDockStyleTop>Сверху</StiDockStyleTop>
    <StiDrillDownModeMultiPage>Несколько страниц</StiDrillDownModeMultiPage>
    <StiDrillDownModeSinglePage>Одна страница</StiDrillDownModeSinglePage>
    <StiEanSupplementTypeFiveDigit>Пять цифр</StiEanSupplementTypeFiveDigit>
    <StiEanSupplementTypeNone>Нет</StiEanSupplementTypeNone>
    <StiEanSupplementTypeTwoDigit>Две цифры</StiEanSupplementTypeTwoDigit>
    <StiEmptySizeModeAlignFooterToBottom>Расположить итог по нижнему краю</StiEmptySizeModeAlignFooterToBottom>
    <StiEmptySizeModeAlignFooterToTop>Расположить итог по верхнему краю</StiEmptySizeModeAlignFooterToTop>
    <StiEmptySizeModeDecreaseLastRow>Уменьшить высоту последней строки</StiEmptySizeModeDecreaseLastRow>
    <StiEmptySizeModeIncreaseLastRow>Увеличить высоту последней строки</StiEmptySizeModeIncreaseLastRow>
    <StiEnumeratorTypeABC>Алфавит</StiEnumeratorTypeABC>
    <StiEnumeratorTypeArabic>Арабские цифры</StiEnumeratorTypeArabic>
    <StiEnumeratorTypeNone>Нет</StiEnumeratorTypeNone>
    <StiEnumeratorTypeRoman>Римские цифры</StiEnumeratorTypeRoman>
    <StiFilterConditionBeginningWith>начинается с</StiFilterConditionBeginningWith>
    <StiFilterConditionBetween>между</StiFilterConditionBetween>
    <StiFilterConditionContaining>содержит</StiFilterConditionContaining>
    <StiFilterConditionEndingWith>заканчивается на</StiFilterConditionEndingWith>
    <StiFilterConditionEqualTo>равно</StiFilterConditionEqualTo>
    <StiFilterConditionGreaterThan>больше чем</StiFilterConditionGreaterThan>
    <StiFilterConditionGreaterThanOrEqualTo>больше чем или равно</StiFilterConditionGreaterThanOrEqualTo>
    <StiFilterConditionIsNotNull>не является null</StiFilterConditionIsNotNull>
    <StiFilterConditionIsNull>является null</StiFilterConditionIsNull>
    <StiFilterConditionLessThan>меньше чем</StiFilterConditionLessThan>
    <StiFilterConditionLessThanOrEqualTo>меньше чем или равно</StiFilterConditionLessThanOrEqualTo>
    <StiFilterConditionNotBetween>не между</StiFilterConditionNotBetween>
    <StiFilterConditionNotContaining>не содержит</StiFilterConditionNotContaining>
    <StiFilterConditionNotEqualTo>не равно</StiFilterConditionNotEqualTo>
    <StiFilterDataTypeBoolean>Логический</StiFilterDataTypeBoolean>
    <StiFilterDataTypeDateTime>Дата</StiFilterDataTypeDateTime>
    <StiFilterDataTypeExpression>Выражение</StiFilterDataTypeExpression>
    <StiFilterDataTypeNumeric>Числовой</StiFilterDataTypeNumeric>
    <StiFilterDataTypeString>Строка</StiFilterDataTypeString>
    <StiFilterEngineReportEngine>Ядро генератора</StiFilterEngineReportEngine>
    <StiFilterEngineSQLQuery>Запрос</StiFilterEngineSQLQuery>
    <StiFilterItemArgument>Аргумент</StiFilterItemArgument>
    <StiFilterItemExpression>Выражение</StiFilterItemExpression>
    <StiFilterItemValue>Значение</StiFilterItemValue>
    <StiFilterItemValueClose>Закрывающее значение</StiFilterItemValueClose>
    <StiFilterItemValueEnd>Конечное значение</StiFilterItemValueEnd>
    <StiFilterItemValueHigh>Максимум</StiFilterItemValueHigh>
    <StiFilterItemValueLow>Минимум</StiFilterItemValueLow>
    <StiFilterItemValueOpen>Открывающее значение</StiFilterItemValueOpen>
    <StiFilterModeAnd>И</StiFilterModeAnd>
    <StiFilterModeOr>Или</StiFilterModeOr>
    <StiFormStartModeOnEnd>В конце</StiFormStartModeOnEnd>
    <StiFormStartModeOnPreview>В предварительном просмотре</StiFormStartModeOnPreview>
    <StiFormStartModeOnStart>В начале</StiFormStartModeOnStart>
    <StiGroupSortDirectionAscending>По возрастанию</StiGroupSortDirectionAscending>
    <StiGroupSortDirectionDescending>По убыванию</StiGroupSortDirectionDescending>
    <StiGroupSortDirectionNone>Нет</StiGroupSortDirectionNone>
    <StiHorAlignmentCenter>По центру</StiHorAlignmentCenter>
    <StiHorAlignmentLeft>По левому краю</StiHorAlignmentLeft>
    <StiHorAlignmentRight>По правому краю</StiHorAlignmentRight>
    <StiImageProcessingDuplicatesTypeGlobalHide>Спрятать глобально</StiImageProcessingDuplicatesTypeGlobalHide>
    <StiImageProcessingDuplicatesTypeGlobalMerge>Соединить глобально</StiImageProcessingDuplicatesTypeGlobalMerge>
    <StiImageProcessingDuplicatesTypeGlobalRemoveImage>Удалить изображение глобально</StiImageProcessingDuplicatesTypeGlobalRemoveImage>
    <StiImageProcessingDuplicatesTypeHide>Спрятать</StiImageProcessingDuplicatesTypeHide>
    <StiImageProcessingDuplicatesTypeMerge>Соединить</StiImageProcessingDuplicatesTypeMerge>
    <StiImageProcessingDuplicatesTypeNone>Нет</StiImageProcessingDuplicatesTypeNone>
    <StiImageProcessingDuplicatesTypeRemoveImage>Удалить изображение</StiImageProcessingDuplicatesTypeRemoveImage>
    <StiImageRotationFlipHorizontal>Перевернуть по горизонтали</StiImageRotationFlipHorizontal>
    <StiImageRotationFlipVertical>Перевернуть по вертикали</StiImageRotationFlipVertical>
    <StiImageRotationNone>Нет</StiImageRotationNone>
    <StiImageRotationRotate180>Повернуть на 180°</StiImageRotationRotate180>
    <StiImageRotationRotate90CCW>Повернуть на 90° против часовой стрелки</StiImageRotationRotate90CCW>
    <StiImageRotationRotate90CW>Повернуть на  90° по часовой стрелке</StiImageRotationRotate90CW>
    <StiKeepDetailsKeepDetailsTogether>Держать детали вместе</StiKeepDetailsKeepDetailsTogether>
    <StiKeepDetailsKeepFirstDetailTogether>Держать первую деталь</StiKeepDetailsKeepFirstDetailTogether>
    <StiKeepDetailsKeepFirstRowTogether>Держать первую строку</StiKeepDetailsKeepFirstRowTogether>
    <StiKeepDetailsNone>Нет</StiKeepDetailsNone>
    <StiLabelsPlacementNone>Нет</StiLabelsPlacementNone>
    <StiLabelsPlacementOneLine>В одну линию</StiLabelsPlacementOneLine>
    <StiLabelsPlacementTwoLines>В две линии</StiLabelsPlacementTwoLines>
    <StiLegendDirectionBottomToTop>Снизу вверх</StiLegendDirectionBottomToTop>
    <StiLegendDirectionLeftToRight>Слева направо</StiLegendDirectionLeftToRight>
    <StiLegendDirectionRightToLeft>Справа налево</StiLegendDirectionRightToLeft>
    <StiLegendDirectionTopToBottom>Сверху вниз</StiLegendDirectionTopToBottom>
    <StiLegendHorAlignmentCenter>По центру</StiLegendHorAlignmentCenter>
    <StiLegendHorAlignmentLeft>Слева</StiLegendHorAlignmentLeft>
    <StiLegendHorAlignmentLeftOutside>Слева за пределами</StiLegendHorAlignmentLeftOutside>
    <StiLegendHorAlignmentRight>Справа</StiLegendHorAlignmentRight>
    <StiLegendHorAlignmentRightOutside>Справа за пределами</StiLegendHorAlignmentRightOutside>
    <StiLegendVertAlignmentBottom>Снизу</StiLegendVertAlignmentBottom>
    <StiLegendVertAlignmentBottomOutside>Снизу за пределами</StiLegendVertAlignmentBottomOutside>
    <StiLegendVertAlignmentCenter>По центру</StiLegendVertAlignmentCenter>
    <StiLegendVertAlignmentTop>Сверху</StiLegendVertAlignmentTop>
    <StiLegendVertAlignmentTopOutside>Сверху за пределами</StiLegendVertAlignmentTopOutside>
    <StiMapIDAustralia>Австралия</StiMapIDAustralia>
    <StiMapIDAustria>Австрия</StiMapIDAustria>
    <StiMapIDBrazil>Бразилия</StiMapIDBrazil>
    <StiMapIDCanada>Канада</StiMapIDCanada>
    <StiMapIDChina>Китай</StiMapIDChina>
    <StiMapIDEU>ЕС</StiMapIDEU>
    <StiMapIDEurope>Европа</StiMapIDEurope>
    <StiMapIDFrance>Франция</StiMapIDFrance>
    <StiMapIDGermany>Германия</StiMapIDGermany>
    <StiMapIDItaly>Италия</StiMapIDItaly>
    <StiMapIDNetherlands>Нидерланды</StiMapIDNetherlands>
    <StiMapIDRussia>Россия</StiMapIDRussia>
    <StiMapIDUK>Великобритания</StiMapIDUK>
    <StiMapIDUSA>США</StiMapIDUSA>
    <StiMapIDWorld>Мир</StiMapIDWorld>
    <StiMapTypeGroup>Группа</StiMapTypeGroup>
    <StiMapTypeHeatmap>Тепловая карта</StiMapTypeHeatmap>
    <StiMapTypeHeatmapWithGroup>Тепловая карта с группировкой</StiMapTypeHeatmapWithGroup>
    <StiMapTypeNone>Нет</StiMapTypeNone>
    <StiMapTypePoints>Точки</StiMapTypePoints>
    <StiMarkerAlignmentLeft>Слева</StiMarkerAlignmentLeft>
    <StiMarkerAlignmentRight>Справа</StiMarkerAlignmentRight>
    <StiMarkerTypeCircle>Круг</StiMarkerTypeCircle>
    <StiMarkerTypeHexagon>Шестиугольник</StiMarkerTypeHexagon>
    <StiMarkerTypeRectangle>Прямоугольник</StiMarkerTypeRectangle>
    <StiMarkerTypeStar5>Звезда с 5 лучами</StiMarkerTypeStar5>
    <StiMarkerTypeStar6>Звезда с 6 лучами</StiMarkerTypeStar6>
    <StiMarkerTypeStar7>Звезда с 7 лучами</StiMarkerTypeStar7>
    <StiMarkerTypeStar8>Звезда с 8 лучами</StiMarkerTypeStar8>
    <StiMarkerTypeTriangle>Треугольник</StiMarkerTypeTriangle>
    <StiNestedFactorHigh>Высокий</StiNestedFactorHigh>
    <StiNestedFactorLow>Низкий</StiNestedFactorLow>
    <StiNestedFactorNormal>Нормальный</StiNestedFactorNormal>
    <StiNumberOfPassDoublePass>Два прохода</StiNumberOfPassDoublePass>
    <StiNumberOfPassSinglePass>Один проход</StiNumberOfPassSinglePass>
    <StiOrientationHorizontal>Горизонтальный</StiOrientationHorizontal>
    <StiOrientationHorizontalRight>Горизонтальный (справа)</StiOrientationHorizontalRight>
    <StiOrientationVertical>Вертикальный</StiOrientationVertical>
    <StiPageOrientationLandscape>Альбом</StiPageOrientationLandscape>
    <StiPageOrientationPortrait>Портрет</StiPageOrientationPortrait>
    <StiPenStyleDash>Штрихованная</StiPenStyleDash>
    <StiPenStyleDashDot>Штрих-пунктирная</StiPenStyleDashDot>
    <StiPenStyleDashDotDot>Штрих-пунктирная с двумя точками</StiPenStyleDashDotDot>
    <StiPenStyleDot>Пунктирная</StiPenStyleDot>
    <StiPenStyleDouble>Двойная</StiPenStyleDouble>
    <StiPenStyleNone>Нет</StiPenStyleNone>
    <StiPenStyleSolid>Сплошная</StiPenStyleSolid>
    <StiPlesseyCheckSumModulo10>Modulo10</StiPlesseyCheckSumModulo10>
    <StiPlesseyCheckSumModulo11>Modulo11</StiPlesseyCheckSumModulo11>
    <StiPlesseyCheckSumNone>Нет</StiPlesseyCheckSumNone>
    <StiPreviewModeDotMatrix>Матричный</StiPreviewModeDotMatrix>
    <StiPreviewModeStandard>Стандарт</StiPreviewModeStandard>
    <StiPreviewModeStandardAndDotMatrix>Стандарт и матричный</StiPreviewModeStandardAndDotMatrix>
    <StiPrintOnEvenOddPagesTypeIgnore>Игнорировать</StiPrintOnEvenOddPagesTypeIgnore>
    <StiPrintOnEvenOddPagesTypePrintOnEvenPages>Печатать на четных страницах</StiPrintOnEvenOddPagesTypePrintOnEvenPages>
    <StiPrintOnEvenOddPagesTypePrintOnOddPages>Печатать на нечетных страницах</StiPrintOnEvenOddPagesTypePrintOnOddPages>
    <StiPrintOnTypeAllPages>Все страницы</StiPrintOnTypeAllPages>
    <StiPrintOnTypeExceptFirstAndLastPage>Кроме первой и последней страницы</StiPrintOnTypeExceptFirstAndLastPage>
    <StiPrintOnTypeExceptFirstPage>Кроме первой страницы</StiPrintOnTypeExceptFirstPage>
    <StiPrintOnTypeExceptLastPage>Кроме последней страницы</StiPrintOnTypeExceptLastPage>
    <StiPrintOnTypeOnlyFirstAndLastPage>Только первая и последняя страница</StiPrintOnTypeOnlyFirstAndLastPage>
    <StiPrintOnTypeOnlyFirstPage>Только первая страница</StiPrintOnTypeOnlyFirstPage>
    <StiPrintOnTypeOnlyLastPage>Только последняя страница</StiPrintOnTypeOnlyLastPage>
    <StiProcessAtEndOfPage>В конце страницы</StiProcessAtEndOfPage>
    <StiProcessAtEndOfReport>В конце отчета</StiProcessAtEndOfReport>
    <StiProcessAtNone>Нет</StiProcessAtNone>
    <StiProcessingDuplicatesTypeBasedOnTagHide>Спрятать в зависимости от свойства Тег</StiProcessingDuplicatesTypeBasedOnTagHide>
    <StiProcessingDuplicatesTypeBasedOnTagMerge>Соединить в зависимости от свойства Тег</StiProcessingDuplicatesTypeBasedOnTagMerge>
    <StiProcessingDuplicatesTypeBasedOnTagRemoveText>Удалить текст в зависимости от свойства Тег</StiProcessingDuplicatesTypeBasedOnTagRemoveText>
    <StiProcessingDuplicatesTypeBasedOnValueAndTagHide>Спрятать в зависимости от Значения и свойства Тег</StiProcessingDuplicatesTypeBasedOnValueAndTagHide>
    <StiProcessingDuplicatesTypeBasedOnValueAndTagMerge>Соединить в зависимости от Значения и свойства Тег</StiProcessingDuplicatesTypeBasedOnValueAndTagMerge>
    <StiProcessingDuplicatesTypeBasedOnValueRemoveText>Удалить в зависимости от Значения</StiProcessingDuplicatesTypeBasedOnValueRemoveText>
    <StiProcessingDuplicatesTypeGlobalBasedOnValueAndTagHide>Спрятать глобально в зависимости от Значения и свойства Тег</StiProcessingDuplicatesTypeGlobalBasedOnValueAndTagHide>
    <StiProcessingDuplicatesTypeGlobalBasedOnValueAndTagMerge>Соединить глобально в зависимости от Значения и свойства Тег</StiProcessingDuplicatesTypeGlobalBasedOnValueAndTagMerge>
    <StiProcessingDuplicatesTypeGlobalBasedOnValueRemoveText>Удалить глобально в зависимости от Значения</StiProcessingDuplicatesTypeGlobalBasedOnValueRemoveText>
    <StiProcessingDuplicatesTypeGlobalHide>Спрятать глобально</StiProcessingDuplicatesTypeGlobalHide>
    <StiProcessingDuplicatesTypeGlobalMerge>Соединить глобально</StiProcessingDuplicatesTypeGlobalMerge>
    <StiProcessingDuplicatesTypeGlobalRemoveText>Удалить текст глобально</StiProcessingDuplicatesTypeGlobalRemoveText>
    <StiProcessingDuplicatesTypeHide>Спрятать</StiProcessingDuplicatesTypeHide>
    <StiProcessingDuplicatesTypeMerge>Соединить</StiProcessingDuplicatesTypeMerge>
    <StiProcessingDuplicatesTypeNone>Нет</StiProcessingDuplicatesTypeNone>
    <StiProcessingDuplicatesTypeRemoveText>Удалить текст</StiProcessingDuplicatesTypeRemoveText>
    <StiRadarStyleXFCircle>Окружность</StiRadarStyleXFCircle>
    <StiRadarStyleXFPolygon>Многоугольник</StiRadarStyleXFPolygon>
    <StiReportCacheModeAuto>Авто</StiReportCacheModeAuto>
    <StiReportCacheModeOff>Выключен</StiReportCacheModeOff>
    <StiReportCacheModeOn>Включен</StiReportCacheModeOn>
    <StiReportUnitTypeCentimeters>Сантиметры</StiReportUnitTypeCentimeters>
    <StiReportUnitTypeHundredthsOfInch>Сотые дюйма</StiReportUnitTypeHundredthsOfInch>
    <StiReportUnitTypeInches>Дюймы</StiReportUnitTypeInches>
    <StiReportUnitTypeMillimeters>Миллиметры</StiReportUnitTypeMillimeters>
    <StiReportUnitTypePixels>Пиксели</StiReportUnitTypePixels>
    <StiRestrictionsAll>Все</StiRestrictionsAll>
    <StiRestrictionsAllowChange>Изменение допускается</StiRestrictionsAllowChange>
    <StiRestrictionsAllowDelete>Удаление допускается</StiRestrictionsAllowDelete>
    <StiRestrictionsAllowMove>Перемещение допускается</StiRestrictionsAllowMove>
    <StiRestrictionsAllowResize>Изменение размера допускается</StiRestrictionsAllowResize>
    <StiRestrictionsAllowSelect>Выбор допускается</StiRestrictionsAllowSelect>
    <StiRestrictionsNone>Нет</StiRestrictionsNone>
    <StiSelectionModeFirst>Первый</StiSelectionModeFirst>
    <StiSelectionModeFromVariable>Из переменной</StiSelectionModeFromVariable>
    <StiSelectionModeNothing>Ничего</StiSelectionModeNothing>
    <StiSeriesLabelsValueTypeArgument>Аргумент</StiSeriesLabelsValueTypeArgument>
    <StiSeriesLabelsValueTypeArgumentValue>Аргумент - значение</StiSeriesLabelsValueTypeArgumentValue>
    <StiSeriesLabelsValueTypeSeriesTitle>Наименование ряда</StiSeriesLabelsValueTypeSeriesTitle>
    <StiSeriesLabelsValueTypeSeriesTitleArgument>Наименование ряда - Аргумент</StiSeriesLabelsValueTypeSeriesTitleArgument>
    <StiSeriesLabelsValueTypeSeriesTitleValue>Наименование ряда - Значение</StiSeriesLabelsValueTypeSeriesTitleValue>
    <StiSeriesLabelsValueTypeTag>Тег</StiSeriesLabelsValueTypeTag>
    <StiSeriesLabelsValueTypeValue>Значение</StiSeriesLabelsValueTypeValue>
    <StiSeriesLabelsValueTypeValueArgument>Значение - Аргумент</StiSeriesLabelsValueTypeValueArgument>
    <StiSeriesLabelsValueTypeWeight>Вес</StiSeriesLabelsValueTypeWeight>
    <StiSeriesSortDirectionAscending>По возрастанию</StiSeriesSortDirectionAscending>
    <StiSeriesSortDirectionDescending>По убыванию</StiSeriesSortDirectionDescending>
    <StiSeriesSortTypeArgument>Аргумент</StiSeriesSortTypeArgument>
    <StiSeriesSortTypeNone>Нет</StiSeriesSortTypeNone>
    <StiSeriesSortTypeValue>Значение</StiSeriesSortTypeValue>
    <StiSeriesYAxisLeftYAxis>Ось Y левая</StiSeriesYAxisLeftYAxis>
    <StiSeriesYAxisRightYAxis>Ось Y правая</StiSeriesYAxisRightYAxis>
    <StiShapeDirectionDown>Вниз</StiShapeDirectionDown>
    <StiShapeDirectionLeft>Влево</StiShapeDirectionLeft>
    <StiShapeDirectionRight>Вправо</StiShapeDirectionRight>
    <StiShapeDirectionUp>Вверх</StiShapeDirectionUp>
    <StiShiftModeDecreasingSize>При уменьшении размера</StiShiftModeDecreasingSize>
    <StiShiftModeIncreasingSize>При увеличении размера</StiShiftModeIncreasingSize>
    <StiShiftModeNone>Нет</StiShiftModeNone>
    <StiShiftModeOnlyInWidthOfComponent>Только в пределах ширины компонента</StiShiftModeOnlyInWidthOfComponent>
    <StiShowSeriesLabelsFromChart>Из диаграммы</StiShowSeriesLabelsFromChart>
    <StiShowSeriesLabelsFromSeries>Из ряда</StiShowSeriesLabelsFromSeries>
    <StiShowSeriesLabelsNone>Нет</StiShowSeriesLabelsNone>
    <StiShowXAxisBoth>Обе</StiShowXAxisBoth>
    <StiShowXAxisBottom>Нижняя</StiShowXAxisBottom>
    <StiShowXAxisCenter>По центру</StiShowXAxisCenter>
    <StiShowYAxisBoth>Обе</StiShowYAxisBoth>
    <StiShowYAxisCenter>По центру</StiShowYAxisCenter>
    <StiShowYAxisLeft>Левая</StiShowYAxisLeft>
    <StiSortDirectionAsc>По возрастанию</StiSortDirectionAsc>
    <StiSortDirectionDesc>По убыванию</StiSortDirectionDesc>
    <StiSortDirectionNone>Не сортировать</StiSortDirectionNone>
    <StiSortTypeByDisplayValue>по отображаемому значению</StiSortTypeByDisplayValue>
    <StiSortTypeByValue>по значению</StiSortTypeByValue>
    <StiSqlSourceTypeStoredProcedure>Хранимая процедура</StiSqlSourceTypeStoredProcedure>
    <StiSqlSourceTypeTable>Запрос</StiSqlSourceTypeTable>
    <StiStyleComponentTypeAny>Любой</StiStyleComponentTypeAny>
    <StiStyleComponentTypeChart>Диаграмма</StiStyleComponentTypeChart>
    <StiStyleComponentTypeCheckBox>Флажок</StiStyleComponentTypeCheckBox>
    <StiStyleComponentTypeCrossTab>Кросс-таблица</StiStyleComponentTypeCrossTab>
    <StiStyleComponentTypeImage>Картинка</StiStyleComponentTypeImage>
    <StiStyleComponentTypePrimitive>Примитив</StiStyleComponentTypePrimitive>
    <StiStyleComponentTypeText>Текст</StiStyleComponentTypeText>
    <StiStyleConditionTypeComponentName>Наименование компонента</StiStyleConditionTypeComponentName>
    <StiStyleConditionTypeComponentType>Тип компонента</StiStyleConditionTypeComponentType>
    <StiStyleConditionTypeLocation>Расположение</StiStyleConditionTypeLocation>
    <StiStyleConditionTypePlacement>Размещение</StiStyleConditionTypePlacement>
    <StiStyleTypeAuto>Автоматически</StiStyleTypeAuto>
    <StiStyleTypeManual>Вручную</StiStyleTypeManual>
    <StiSummaryValuesAllValues>Все значения</StiSummaryValuesAllValues>
    <StiSummaryValuesSkipNulls>Пропускать недействительные</StiSummaryValuesSkipNulls>
    <StiSummaryValuesSkipZerosAndNulls>Пропускать нули и недействительные</StiSummaryValuesSkipZerosAndNulls>
    <StiTablceCellTypeCheckBox>Флажок</StiTablceCellTypeCheckBox>
    <StiTablceCellTypeImage>Картинка</StiTablceCellTypeImage>
    <StiTablceCellTypeRichText>Rich-текст</StiTablceCellTypeRichText>
    <StiTablceCellTypeText>Текст</StiTablceCellTypeText>
    <StiTableAutoWidthNone>Нет</StiTableAutoWidthNone>
    <StiTableAutoWidthPage>Страница</StiTableAutoWidthPage>
    <StiTableAutoWidthTable>Таблица</StiTableAutoWidthTable>
    <StiTableAutoWidthTypeFullTable>Полная таблица</StiTableAutoWidthTypeFullTable>
    <StiTableAutoWidthTypeLastColumns>Последняя колонка</StiTableAutoWidthTypeLastColumns>
    <StiTableAutoWidthTypeNone>Нет</StiTableAutoWidthTypeNone>
    <StiTextHorAlignmentCenter>По центру</StiTextHorAlignmentCenter>
    <StiTextHorAlignmentLeft>По левому краю</StiTextHorAlignmentLeft>
    <StiTextHorAlignmentRight>По правому краю</StiTextHorAlignmentRight>
    <StiTextHorAlignmentWidth>По ширине</StiTextHorAlignmentWidth>
    <StiTextPositionCenterBottom>По центру внизу</StiTextPositionCenterBottom>
    <StiTextPositionCenterTop>По центру вверху</StiTextPositionCenterTop>
    <StiTextPositionLeftBottom>Слева внизу</StiTextPositionLeftBottom>
    <StiTextPositionLeftTop>Слева вверху</StiTextPositionLeftTop>
    <StiTextPositionRightBottom>Справа внизу</StiTextPositionRightBottom>
    <StiTextPositionRightTop>Справа вверху</StiTextPositionRightTop>
    <StiTextQualityStandard>Стандарт</StiTextQualityStandard>
    <StiTextQualityTypographic>Типографский</StiTextQualityTypographic>
    <StiTextQualityWysiwyg>Wysiwyg</StiTextQualityWysiwyg>
    <StiTitlePositionInside>Внутри</StiTitlePositionInside>
    <StiTitlePositionOutside>Снаружи</StiTitlePositionOutside>
    <StiTypeModeList>Список</StiTypeModeList>
    <StiTypeModeNullableValue>Значение с null</StiTypeModeNullableValue>
    <StiTypeModeRange>Диапазон</StiTypeModeRange>
    <StiTypeModeValue>Значение</StiTypeModeValue>
    <StiVertAlignmentBottom>По нижнему краю</StiVertAlignmentBottom>
    <StiVertAlignmentCenter>По центру</StiVertAlignmentCenter>
    <StiVertAlignmentTop>По верхнему краю</StiVertAlignmentTop>
    <StiViewModeNormal>Обычный</StiViewModeNormal>
    <StiViewModePageBreakPreview>Разметка страницы</StiViewModePageBreakPreview>
    <StiXmlTypeAdoNetXml>ADO.NET XML</StiXmlTypeAdoNetXml>
    <StiXmlTypeXml>XML</StiXmlTypeXml>
    <StringAlignmentCenter>По центру</StringAlignmentCenter>
    <StringAlignmentFar>Вдали</StringAlignmentFar>
    <StringAlignmentNear>Вблизи</StringAlignmentNear>
    <StringTrimmingCharacter>Символ</StringTrimmingCharacter>
    <StringTrimmingEllipsisCharacter>Символ с многоточием</StringTrimmingEllipsisCharacter>
    <StringTrimmingEllipsisPath>Путь с многоточием</StringTrimmingEllipsisPath>
    <StringTrimmingEllipsisWord>Слово с многоточием</StringTrimmingEllipsisWord>
    <StringTrimmingNone>Не подгонять</StringTrimmingNone>
    <StringTrimmingWord>Слово</StringTrimmingWord>
  </PropertyEnum>
  <PropertyEvents>
    <AfterPrintEvent>После печати</AfterPrintEvent>
    <AfterSelectEvent>После выбора</AfterSelectEvent>
    <BeforePrintEvent>Перед печатью</BeforePrintEvent>
    <BeginRenderEvent>Начало построения</BeginRenderEvent>
    <CheckedChangedEvent>Изменение отметки</CheckedChangedEvent>
    <ClickEvent>Нажатие</ClickEvent>
    <ClosedFormEvent>После закрытия</ClosedFormEvent>
    <ClosingFormEvent>Перед закрытием</ClosingFormEvent>
    <ColumnBeginRenderEvent>Начало построения колонки</ColumnBeginRenderEvent>
    <ColumnEndRenderEvent>Конец построения колонки</ColumnEndRenderEvent>
    <ConnectedEvent>После соединения</ConnectedEvent>
    <ConnectingEvent>До соединения</ConnectingEvent>
    <DisconnectedEvent>После разъединения</DisconnectedEvent>
    <DisconnectingEvent>До разъединения</DisconnectingEvent>
    <DoubleClickEvent>Двойное нажатие</DoubleClickEvent>
    <EndRenderEvent>Конец построения</EndRenderEvent>
    <EnterEvent>Получение выбора</EnterEvent>
    <ExportedEvent>После экспорта</ExportedEvent>
    <ExportingEvent>До экспорта</ExportingEvent>
    <GetArgumentEvent>Взять аргумент</GetArgumentEvent>
    <GetBookmarkEvent>Взять закладку</GetBookmarkEvent>
    <GetCollapsedEvent>Взять сворачивание</GetCollapsedEvent>
    <GetCrossValueEvent>Взять кросс-значение</GetCrossValueEvent>
    <GetCutPieListEvent>Взять список выдвинутых сегментов</GetCutPieListEvent>
    <GetCuttedPieListEvent>Взять список выдвинутых сегментов</GetCuttedPieListEvent>
    <GetDataUrlEvent>Взять гиперссылку на данные</GetDataUrlEvent>
    <GetDisplayCrossValueEvent>Взять отображаемое кросс-значение</GetDisplayCrossValueEvent>
    <GetDrillDownReportEvent>Взять отчет детализации</GetDrillDownReportEvent>
    <GetExcelSheetEvent>Взять лист Excel</GetExcelSheetEvent>
    <GetExcelValueEvent>Взять Excel значение</GetExcelValueEvent>
    <GetHyperlinkEvent>Взять гиперссылку</GetHyperlinkEvent>
    <GetImageDataEvent>Взять изображение</GetImageDataEvent>
    <GetImageURLEvent>Взять гиперссылку на картинку</GetImageURLEvent>
    <GetListOfArgumentsEvent>Взять список аргументов</GetListOfArgumentsEvent>
    <GetListOfHyperlinksEvent>Взять список гиперссылок</GetListOfHyperlinksEvent>
    <GetListOfTagsEvent>Взять список тегов</GetListOfTagsEvent>
    <GetListOfToolTipsEvent>Взять список подсказок</GetListOfToolTipsEvent>
    <GetListOfValuesEndEvent>Взять список конечных значений</GetListOfValuesEndEvent>
    <GetListOfValuesEvent>Взять список значений</GetListOfValuesEvent>
    <GetListOfWeights>Взять список весовых значений</GetListOfWeights>
    <GetListOfWeightsEvent>Взять список весовых значений</GetListOfWeightsEvent>
    <GetSummaryExpressionEvent>Взять выражение суммирования</GetSummaryExpressionEvent>
    <GetTagEvent>Взять тег</GetTagEvent>
    <GetTitleEvent>Взять заголовок</GetTitleEvent>
    <GetToolTipEvent>Взять подсказку</GetToolTipEvent>
    <GetValueEndEvent>Взять конечное значение</GetValueEndEvent>
    <GetValueEvent>Взять значение</GetValueEvent>
    <GetWeightEvent>Взять вес</GetWeightEvent>
    <LeaveEvent>Переход выбора</LeaveEvent>
    <LoadFormEvent>При открытии</LoadFormEvent>
    <MouseDownEvent>Нажатие мыши вниз</MouseDownEvent>
    <MouseEnterEvent>Мышь внутрь</MouseEnterEvent>
    <MouseLeaveEvent>Мышь наружу</MouseLeaveEvent>
    <MouseMoveEvent>Перемещение мыши</MouseMoveEvent>
    <MouseUpEvent>Нажатие мыши вверх</MouseUpEvent>
    <NewAutoSeriesEvent>Новый авторяд</NewAutoSeriesEvent>
    <PositionChangedEvent>Изменение позиции</PositionChangedEvent>
    <PrintedEvent>После печати</PrintedEvent>
    <PrintingEvent>До печати</PrintingEvent>
    <ProcessCellEvent>Обработка ячейки</ProcessCellEvent>
    <ProcessChartEvent>Обработка диаграммы</ProcessChartEvent>
    <RenderingEvent>Построение</RenderingEvent>
    <SelectedIndexChangedEvent>Изменение индекса</SelectedIndexChangedEvent>
    <StateRestoreEvent>Восстановление состояния</StateRestoreEvent>
    <StateSaveEvent>Сохранение состояния</StateSaveEvent>
    <ValueChangedEvent>Изменение значения</ValueChangedEvent>
  </PropertyEvents>
  <PropertyHatchStyle>
    <BackwardDiagonal>Задняя диагональ</BackwardDiagonal>
    <Cross>Пересечение</Cross>
    <DarkDownwardDiagonal>Темная нижняя диагональ</DarkDownwardDiagonal>
    <DarkHorizontal>Темная горизонталь</DarkHorizontal>
    <DarkUpwardDiagonal>Темная верхняя диагональ</DarkUpwardDiagonal>
    <DarkVertical>Темная вертикаль</DarkVertical>
    <DashedDownwardDiagonal>Пунктирная нижняя диагональ</DashedDownwardDiagonal>
    <DashedHorizontal>Пунктирная горизонталь</DashedHorizontal>
    <DashedUpwardDiagonal>Пунктирная верхняя диагональ</DashedUpwardDiagonal>
    <DashedVertical>Пунктирная вертикаль</DashedVertical>
    <DiagonalBrick>Диагональный кирпич</DiagonalBrick>
    <DiagonalCross>Диагональное пересечение</DiagonalCross>
    <Divot>Дерн</Divot>
    <DottedDiamond>Точечный алмаз</DottedDiamond>
    <DottedGrid>Точечная сетка</DottedGrid>
    <ForwardDiagonal>Передняя диагональ</ForwardDiagonal>
    <Horizontal>Горизонталь</Horizontal>
    <HorizontalBrick>Горизонтальный кирпич</HorizontalBrick>
    <LargeCheckerBoard>Большая шахматная доска</LargeCheckerBoard>
    <LargeConfetti>Большое конфетти</LargeConfetti>
    <LargeGrid>Большая сетка</LargeGrid>
    <LightDownwardDiagonal>Светлая нижняя диагональ</LightDownwardDiagonal>
    <LightHorizontal>Светлая горизонталь</LightHorizontal>
    <LightUpwardDiagonal>Светлая верхняя диагональ</LightUpwardDiagonal>
    <LightVertical>Светлая вертикаль</LightVertical>
    <NarrowHorizontal>Узкая горизонталь</NarrowHorizontal>
    <NarrowVertical>Узкая вертикаль</NarrowVertical>
    <OutlinedDiamond>Очерченный алмаз</OutlinedDiamond>
    <Percent05>Проценты 05</Percent05>
    <Percent10>Проценты 10</Percent10>
    <Percent20>Проценты 20</Percent20>
    <Percent25>Проценты 25</Percent25>
    <Percent30>Проценты 30</Percent30>
    <Percent40>Проценты 40</Percent40>
    <Percent50>Проценты 50</Percent50>
    <Percent60>Проценты 60</Percent60>
    <Percent70>Проценты 70</Percent70>
    <Percent75>Проценты 75</Percent75>
    <Percent80>Проценты 80</Percent80>
    <Percent90>Проценты 90</Percent90>
    <Plaid>Шотландка</Plaid>
    <Shingle>Дранка</Shingle>
    <SmallCheckerBoard>Маленькая шахматная доска</SmallCheckerBoard>
    <SmallConfetti>Маленькое конфетти</SmallConfetti>
    <SmallGrid>Маленькая сетка</SmallGrid>
    <SolidDiamond>Заполненный алмаз</SolidDiamond>
    <Sphere>Сфера</Sphere>
    <Trellis>Трельяж</Trellis>
    <Vertical>Вертикаль</Vertical>
    <Wave>Волна</Wave>
    <Weave>Переплетение</Weave>
    <WideDownwardDiagonal>Широкая нижняя диагональ</WideDownwardDiagonal>
    <WideUpwardDiagonal>Широкая верхняя диагональ</WideUpwardDiagonal>
    <ZigZag>Зиг-заг</ZigZag>
  </PropertyHatchStyle>
  <PropertyMain>
    <AcceptsReturn>Принимать Returns</AcceptsReturn>
    <AcceptsTab>Принимать Tabs</AcceptsTab>
    <AddClearZone>Добавлять поля</AddClearZone>
    <Advanced>Расширенный</Advanced>
    <AggregateFunction>Агрегатная функция</AggregateFunction>
    <AggregateFunctions>Агрегатные функции</AggregateFunctions>
    <Alias>Псевдоним</Alias>
    <Alignment>Выравнивание</Alignment>
    <AllowApplyBorderColor>Разр. исп. цвет границы</AllowApplyBorderColor>
    <AllowApplyBrush>Разр. исп. заполнение</AllowApplyBrush>
    <AllowApplyBrushNegative>Разрешить кисть негативных значений</AllowApplyBrushNegative>
    <AllowApplyColorNegative>Разрешить цвет негативных значений</AllowApplyColorNegative>
    <AllowApplyStyle>Разрешить применять стили</AllowApplyStyle>
    <AllowExpressions>Разрешить выражения</AllowExpressions>
    <AllowHtmlTags>Разрешить HTML теги</AllowHtmlTags>
    <AllowSeries>Разрешить ряды</AllowSeries>
    <AllowSeriesElements>Разрешить элементы рядов</AllowSeriesElements>
    <AllowSorting>Сортировка</AllowSorting>
    <AllowUseBackColor>Разрешить цвет заднего фона</AllowUseBackColor>
    <AllowUseBorder>Разрешить границы</AllowUseBorder>
    <AllowUseBorderFormatting>Разрешить форматирование границ</AllowUseBorderFormatting>
    <AllowUseBorderSides>Разрешить рамки границ</AllowUseBorderSides>
    <AllowUseBorderSidesFromLocation>Разрешить рамки границы в зависимости от расположения</AllowUseBorderSidesFromLocation>
    <AllowUseBrush>Разрешить кисть</AllowUseBrush>
    <AllowUseFont>Разрешить шрифт</AllowUseFont>
    <AllowUseForeColor>Разрешить цвет переднего фона</AllowUseForeColor>
    <AllowUseHorAlignment>Разрешить гор. выравнивание</AllowUseHorAlignment>
    <AllowUseImage>Разрешить изображения</AllowUseImage>
    <AllowUserValues>Разрешить пользовательские значения</AllowUserValues>
    <AllowUseTextBrush>Разрешить кисть текста</AllowUseTextBrush>
    <AllowUseTextOptions>Разрешить параметры текста</AllowUseTextOptions>
    <AllowUseVertAlignment>Разрешить верт. выравнивание</AllowUseVertAlignment>
    <AllowUsingAsSqlParameter>Разрешить использовать как SQL параметр</AllowUsingAsSqlParameter>
    <AlternatingBackColor>Альтернативный цвет фона</AlternatingBackColor>
    <Anchor>Привязка</Anchor>
    <Angle>Угол</Angle>
    <Antialiasing>Сглаживание</Antialiasing>
    <Area>Область</Area>
    <Argument>Аргумент</Argument>
    <ArgumentDataColumn>Колонка данных аргументов</ArgumentDataColumn>
    <Arguments>Аргументы</Arguments>
    <ArrowHeight>Высота пера</ArrowHeight>
    <ArrowStyle>Стиль стрелки</ArrowStyle>
    <ArrowWidth>Ширина пера</ArrowWidth>
    <AspectRatio>Соотношение сторон</AspectRatio>
    <Author>Автор</Author>
    <Auto>Авто</Auto>
    <AutoCalculateCenterPoint>Авторасчет центральной точки</AutoCalculateCenterPoint>
    <AutoDataColumns>Автоматические колонки данных</AutoDataColumns>
    <AutoDataRows>Автоматические строки данных</AutoDataRows>
    <AutoLocalizeReportOnRun>Автоматически переводить при запуске отчета</AutoLocalizeReportOnRun>
    <AutoRefresh>Авто обновление</AutoRefresh>
    <AutoRotate>Автовращение</AutoRotate>
    <AutoScale>Автомасштабирование</AutoScale>
    <AutoSeriesColorDataColumn>Авторяд Колонка Цвет</AutoSeriesColorDataColumn>
    <AutoSeriesKeyDataColumn>Авторяд Колонка Ключ</AutoSeriesKeyDataColumn>
    <AutoSeriesTitleDataColumn>Авторяд Колонка Наименование</AutoSeriesTitleDataColumn>
    <AutoWidth>Автоширина</AutoWidth>
    <AutoWidthType>Тип авто-ширины</AutoWidthType>
    <AxisLabelsColor>Цвет подписей</AxisLabelsColor>
    <AxisLineColor>Цвет линии</AxisLineColor>
    <AxisTitleColor>Цвет заголовка</AxisTitleColor>
    <AxisValue>Значение оси</AxisValue>
    <BackColor>Цвет фона</BackColor>
    <Background>Фон</Background>
    <BackgroundColor>Цвет заднего фона</BackgroundColor>
    <BarCodeType>Тип штрих-кода</BarCodeType>
    <BasicStyleColor>Основной цвет стиля</BasicStyleColor>
    <BindingColumn>Колонка привязки</BindingColumn>
    <Blend>Смешение</Blend>
    <Bold>Жирный</Bold>
    <Bookmark>Закладка</Bookmark>
    <Border>Граница</Border>
    <BorderBrush>Кисть границы</BorderBrush>
    <BorderColor>Цвет границы</BorderColor>
    <BorderColorNegative>Негативный цвет бордюра</BorderColorNegative>
    <Borders>Границы</Borders>
    <BorderSize>Размер кисти</BorderSize>
    <BorderStyle>Стиль границы</BorderStyle>
    <BorderWidth>Ширина границы</BorderWidth>
    <Bottom>Снизу</Bottom>
    <BottomSide>Нижняя сторона</BottomSide>
    <BreakIfLessThan>Новая, если больше чем</BreakIfLessThan>
    <Brush>Кисть</Brush>
    <BrushNegative>Кисть негативных значений</BrushNegative>
    <BrushType>Тип кисти</BrushType>
    <BusinessObject>Бизнес-объект</BusinessObject>
    <CacheAllData>Кэшировать все данные</CacheAllData>
    <CacheTotals>Кэшировать Totals-функции</CacheTotals>
    <CalcInvisible>Вычислять невидимые</CalcInvisible>
    <CalculatedDataColumn>Рассчитываемая колонка</CalculatedDataColumn>
    <CalculationMode>Режим вычислений</CalculationMode>
    <CanBreak>Может разрываться</CanBreak>
    <Cancel>Отмена</Cancel>
    <CanGrow>Может расти</CanGrow>
    <CanShrink>Может сжиматься</CanShrink>
    <Categories>Категории</Categories>
    <Category>Категория</Category>
    <CategoryConnections>Соединения</CategoryConnections>
    <CellDockStyle>Стиль стыковки</CellDockStyle>
    <CellHeight>Высота ячейки</CellHeight>
    <CellType>Тип ячейки</CellType>
    <CellWidth>Ширина ячейки</CellWidth>
    <Center>Центр</Center>
    <CenterPoint>Точка центра</CenterPoint>
    <ChartAreaBorderColor>Цвет границы</ChartAreaBorderColor>
    <ChartAreaBrush>Кисть области</ChartAreaBrush>
    <ChartAreaShowShadow>Показать тень</ChartAreaShowShadow>
    <ChartType>Тип диаграммы</ChartType>
    <Checked>Включено</Checked>
    <CheckOnClick>Включать при нажатии</CheckOnClick>
    <CheckStyle>Стиль флажка</CheckStyle>
    <CheckStyleForFalse>Стиль значка для False</CheckStyleForFalse>
    <CheckStyleForTrue>Стиль значка для True</CheckStyleForTrue>
    <Checksum>Контрольная сумма</Checksum>
    <CheckSum>Контрольная сумма</CheckSum>
    <CheckSum1>Контрольная сумма1</CheckSum1>
    <CheckSum2>Контрольная сумма2</CheckSum2>
    <Child>Дочерний</Child>
    <ChildColumns>Колонки подчиненного</ChildColumns>
    <ChildSource>Подчиненный источник данных</ChildSource>
    <ClearFormat>Очищать формат</ClearFormat>
    <CloneContainer>Клонировать контейнер</CloneContainer>
    <Code>Код</Code>
    <CodePage>Кодовая страница</CodePage>
    <Collapsed>Свернутый</Collapsed>
    <CollapseGroupFooter>Сворачивать Итог Группы</CollapseGroupFooter>
    <CollapsingEnabled>Сворачивание включено</CollapsingEnabled>
    <Collate>Сортировка страниц</Collate>
    <CollectionName>Наименование коллекции</CollectionName>
    <Color>Цвет</Color>
    <ColorEach>Цвет для каждого</ColorEach>
    <ColorScaleCondition>Цветовая шкала</ColorScaleCondition>
    <ColorScaleType>Тип цветовой шкалы</ColorScaleType>
    <Column>Колонка</Column>
    <ColumnCount>Количество колонок</ColumnCount>
    <ColumnDirection>Направление колонок</ColumnDirection>
    <ColumnGaps>Расстояние между колонками</ColumnGaps>
    <ColumnHeadersVisible>Видимость заголовков</ColumnHeadersVisible>
    <Columns>Колонки</Columns>
    <ColumnWidth>Ширина колонки</ColumnWidth>
    <CommandTimeout>Предельное время исполнения команды</CommandTimeout>
    <CompanyPrefix>Префикс Компании</CompanyPrefix>
    <ComponentStyle>Стиль компонента</ComponentStyle>
    <Condition>Условие</Condition>
    <ConditionOptions>Параметры условия</ConditionOptions>
    <Conditions>Условия</Conditions>
    <ConnectionString>Строка соединения</ConnectionString>
    <ConnectOnStart>Соединяться при запуске</ConnectOnStart>
    <ConstantLines>Константные линии</ConstantLines>
    <Container>Контейнер</Container>
    <ContinuousText>Непрерывный текст</ContinuousText>
    <ContourColor>Цвет контура</ContourColor>
    <Converting>Конвертация</Converting>
    <ConvertNulls>Преобразовывать Nulls</ConvertNulls>
    <Copies>Копии</Copies>
    <Count>Количество</Count>
    <CountData>Количество элементов</CountData>
    <Create>Создание</Create>
    <CreateFieldOnDoubleClick>Создавать поле при Двойном Щелчке</CreateFieldOnDoubleClick>
    <CreateLabel>Создавать подпись</CreateLabel>
    <Culture>Культура</Culture>
    <CustomFormat>Формат по выбору</CustomFormat>
    <CutPieList>Список выдвинутых сегментов</CutPieList>
    <CuttedPieList>Список выдвинутых сегментов</CuttedPieList>
    <Data>Данные</Data>
    <DataAdapter>Адаптер данных</DataAdapter>
    <DataAdapters>Адаптеры данных</DataAdapters>
    <DataBarCondition>Гистограмма</DataBarCondition>
    <DataBindings>Связывание данных</DataBindings>
    <DataColumn>Колонка данных</DataColumn>
    <DataColumns>Колонки данных</DataColumns>
    <DataField>Поле данных</DataField>
    <DataRelation>Связь</DataRelation>
    <DataRows>Строки данных</DataRows>
    <DataSource>Источник данных</DataSource>
    <DataSources>Источники данных</DataSources>
    <DataTextField>Поле данных</DataTextField>
    <DataType>Тип данных</DataType>
    <DataUrl>URL данных</DataUrl>
    <DateInfo>Информация о датах</DateInfo>
    <DateTimeStep>Шаг времени</DateTimeStep>
    <Default>По умолчанию</Default>
    <DefaultHeightCell>Высота ячейки по умолчанию</DefaultHeightCell>
    <DefaultNamespace>Пространство имен</DefaultNamespace>
    <DependentColumn>Зависимая колонка</DependentColumn>
    <DependentValue>Зависимое значение</DependentValue>
    <Description>Описание</Description>
    <DetectUrls>Определять гиперссылки</DetectUrls>
    <DialogResult>Результат диалога</DialogResult>
    <Diameter>Диаметр</Diameter>
    <Direction>Направление</Direction>
    <Disabled>Выключенный</Disabled>
    <DisplayValue>Отображаемое значение</DisplayValue>
    <Distance>Дистанция</Distance>
    <DistanceBetweenTabs>Дистанция между</DistanceBetweenTabs>
    <Dock>Докировка</Dock>
    <DockableTable>Стыковка таблицы</DockableTable>
    <DockStyle>Стиль стыковки</DockStyle>
    <DrawBorder>Рисовать границу</DrawBorder>
    <DrawHatch>Штриховка</DrawHatch>
    <DrawLine>рисовать линию</DrawLine>
    <DrillDown>Детализация</DrillDown>
    <DrillDownEnabled>Детализация включена</DrillDownEnabled>
    <DrillDownMode>Режим детализации</DrillDownMode>
    <DrillDownPage>Страница детализации</DrillDownPage>
    <DrillDownParameter1>Параметр детализации 1</DrillDownParameter1>
    <DrillDownParameter2>Параметр детализации 2</DrillDownParameter2>
    <DrillDownParameter3>Параметр детализации 3</DrillDownParameter3>
    <DrillDownParameter4>Параметр детализации 4</DrillDownParameter4>
    <DrillDownParameter5>Параметр детализации 5</DrillDownParameter5>
    <DrillDownParameters>Параметры</DrillDownParameters>
    <DrillDownReport>Отчет детализации</DrillDownReport>
    <DropDownAlign>Выравнивание списка</DropDownAlign>
    <DropDownStyle>Стиль списка</DropDownStyle>
    <DropDownWidth>Ширина списка</DropDownWidth>
    <DropShadow>Показывать тень</DropShadow>
    <Duplex>Дуплекс</Duplex>
    <Editable>Редактируемый</Editable>
    <EmptyBorderBrush>Кисть границы пустоты</EmptyBorderBrush>
    <EmptyBorderWidth>Ширина границы пустоты</EmptyBorderWidth>
    <EmptyBrush>Кисть пустоты</EmptyBrush>
    <EmptyValue>Пустое значение</EmptyValue>
    <Enabled>Доступность</Enabled>
    <EnableLog>Включен</EnableLog>
    <EncodingMode>Режим кодирования</EncodingMode>
    <EncodingType>Тип кодировки</EncodingType>
    <EndCap>Тип конца</EndCap>
    <EndColor>Конечный цвет</EndColor>
    <EndValue>Конечное значение</EndValue>
    <EndWidth>Конечная ширина</EndWidth>
    <EngineVersion>Версия ядра</EngineVersion>
    <EnumeratorSeparator>Разделитель счетчика</EnumeratorSeparator>
    <EnumeratorType>Тип счетчика</EnumeratorType>
    <ErrorCorrectionLevel>Уровень коррекции ошибок</ErrorCorrectionLevel>
    <ErrorsCorrectionLevel>Уровень коррекции ошибок</ErrorsCorrectionLevel>
    <EvenStyle>Стиль четных строк</EvenStyle>
    <ExcelSheet>Лист Excel</ExcelSheet>
    <ExcelValue>Excel значение</ExcelValue>
    <Exponential>Экспоненциальная</Exponential>
    <ExportAsImage>Экспортировать как изображение</ExportAsImage>
    <Expression>Выражение</Expression>
    <ExtensionDigit>Дополнительный Разряд</ExtensionDigit>
    <FaqPage>FAQ страница</FaqPage>
    <FieldIs>Поле</FieldIs>
    <File>Файл</File>
    <Fill>Заполнение</Fill>
    <FillColor>Цвет заполнения</FillColor>
    <Filter>Фильтр</Filter>
    <FilterEngine>Режим фильтрации</FilterEngine>
    <FilterMode>Режим фильтрации</FilterMode>
    <FilterOn>Фильтр включен</FilterOn>
    <Filters>Фильтры</Filters>
    <FirstTabOffset>Позиция первого символа</FirstTabOffset>
    <FixedWidth>Фиксированная ширина</FixedWidth>
    <Flat>Плоский</Flat>
    <FlatMode>Плоский режим</FlatMode>
    <Focus>Фокус</Focus>
    <Font>Шрифт</Font>
    <FontBold>Жирный</FontBold>
    <FontItalic>Наклонный</FontItalic>
    <FontName>Наименование</FontName>
    <FontSize>Размер</FontSize>
    <FontStrikeout>Зачеркнутый</FontStrikeout>
    <FontSubscript>Нижний текст</FontSubscript>
    <FontSuperscript>Верхний текст</FontSuperscript>
    <FontUnderline>Подчеркнутый</FontUnderline>
    <FontUnit>Единица измерения</FontUnit>
    <FooterCanBreak>Итог может разрываться</FooterCanBreak>
    <FooterCanGrow>Итог может расти</FooterCanGrow>
    <FooterCanShrink>Итог может сжиматься</FooterCanShrink>
    <FooterPrintAtBottom>Итог печатать внизу</FooterPrintAtBottom>
    <FooterPrintIfEmpty>Итог печатать, если нет данных</FooterPrintIfEmpty>
    <FooterPrintOn>Итог печатать на</FooterPrintOn>
    <FooterPrintOnAllPages>Итог печатать на всех страницах</FooterPrintOnAllPages>
    <FooterPrintOnEvenOddPages>Итог печатать на четных-нечетных страницах</FooterPrintOnEvenOddPages>
    <FooterRowsCount>Количество строк итога</FooterRowsCount>
    <Footers>Итоги</Footers>
    <ForeColor>Цвет текста</ForeColor>
    <Format>Формат</Format>
    <From>С</From>
    <FullConvertExpression>Полностью конвертировать выражение</FullConvertExpression>
    <Function>Функция</Function>
    <Functions>Функции</Functions>
    <GlobalizationStrings>Строки перевода</GlobalizationStrings>
    <GlobalizedName>Глобализация</GlobalizedName>
    <GridLineColor>Цвет линии сетки</GridLineColor>
    <GridLinesHor>Горизонтальные линии сетки</GridLinesHor>
    <GridLinesHorColor>Цвет горизонтальных линий сетки</GridLinesHorColor>
    <GridLinesHorRight>Горизонтальные линии сетки справа</GridLinesHorRight>
    <GridLineStyle>Стиль линии сетки</GridLineStyle>
    <GridLinesVert>Вертикальные линии сетки</GridLinesVert>
    <GridLinesVertColor>Цвет вертикальных линий сетки</GridLinesVertColor>
    <GridOutline>Контур таблицы</GridOutline>
    <GrowToHeight>Расти в высоту</GrowToHeight>
    <HeaderBackColor>Цвет заголовка</HeaderBackColor>
    <HeaderCanBreak>Заголовок может разрываться</HeaderCanBreak>
    <HeaderCanGrow>Заголовок может расти</HeaderCanGrow>
    <HeaderCanShrink>Заголовок может сжиматься</HeaderCanShrink>
    <HeaderFont>Шрифт заголовка</HeaderFont>
    <HeaderForeColor>Цвет текста заголовка</HeaderForeColor>
    <HeaderPrintAtBottom>Заголовок печатать внизу</HeaderPrintAtBottom>
    <HeaderPrintIfEmpty>Заголовок печатать если нет данных</HeaderPrintIfEmpty>
    <HeaderPrintOn>Заголовок печатать на</HeaderPrintOn>
    <HeaderPrintOnAllPages>Заголовок печатать на всех страницах</HeaderPrintOnAllPages>
    <HeaderPrintOnEvenOddPages>Заголовок печатать на четных-нечетных страницах</HeaderPrintOnEvenOddPages>
    <HeaderRowsCount>Количество строк заголовка</HeaderRowsCount>
    <Headers>Заголовки</Headers>
    <HeaderText>Текст заголовка</HeaderText>
    <Height>Высота</Height>
    <HideSeriesWithEmptyTitle>Прятать ряды с пустыми заголовками</HideSeriesWithEmptyTitle>
    <HideZeros>Скрывать нулевые значения</HideZeros>
    <High>Высокое</High>
    <HighlightCondition>Условное выделение</HighlightCondition>
    <HorAlignment>Горизонтальное выравнивание</HorAlignment>
    <HorSpacing>Расстояние по горизонтали</HorSpacing>
    <HotkeyPrefix>Префикс горячей клавиши</HotkeyPrefix>
    <HtmlTags>HTML-теги</HtmlTags>
    <Hyperlink>Гиперссылка</Hyperlink>
    <HyperlinkDataColumn>Колонка данных гиперссылок</HyperlinkDataColumn>
    <Icon>Значок</Icon>
    <IconSet>Набор значков</IconSet>
    <IconSetCondition>Набор значков</IconSetCondition>
    <Idents>Идентификаторы</Idents>
    <Image>Изображение</Image>
    <ImageAlign>Выравнивание изображения</ImageAlign>
    <ImageAlignment>Выравнивание изображения</ImageAlignment>
    <ImageData>Выражение изображения</ImageData>
    <ImageHorAlignment>Горизонтальное выравнивание изображения</ImageHorAlignment>
    <ImageMultipleFactor>Множитель изображения</ImageMultipleFactor>
    <ImageRotation>Поворот изображения</ImageRotation>
    <ImageStretch>Растягивание изображения</ImageStretch>
    <ImageTiling>Повтор изображения</ImageTiling>
    <ImageTransparency>Прозрачность изображения</ImageTransparency>
    <ImageURL>Гиперссылка на картинку</ImageURL>
    <ImageVertAlignment>Вертикальное выравнивание изображения</ImageVertAlignment>
    <ImportRelations>Импорт связей</ImportRelations>
    <Increment>Приращение</Increment>
    <Indent>Отступ</Indent>
    <InfographicsTitle>Заголовок</InfographicsTitle>
    <InitBy>Инициализировать</InitBy>
    <Insert>Вставить</Insert>
    <Interaction>Взаимодействие</Interaction>
    <InterlacedBrush>Кисть чередования</InterlacedBrush>
    <InterlacingHor>Горизонтальное чередование</InterlacingHor>
    <InterlacingHorBrush>Кисть горизонтального чередования</InterlacingHorBrush>
    <InterlacingVert>Вертикальное чередование</InterlacingVert>
    <InterlacingVertBrush>Кисть вертикального чередования</InterlacingVertBrush>
    <Interpolation>Интерполяция</Interpolation>
    <IsReversed>Зарезервировано</IsReversed>
    <Italic>Наклонный</Italic>
    <Item>Элемент</Item>
    <ItemHeight>Высота элемента</ItemHeight>
    <Items>Элементы</Items>
    <KeepChildTogether>Держать подчиненные вместе</KeepChildTogether>
    <KeepCrossTabTogether>Держать Кросс-таблицу вместе</KeepCrossTabTogether>
    <KeepDetails>Держать детали</KeepDetails>
    <KeepDetailsTogether>Держать детали вместе</KeepDetailsTogether>
    <KeepFooterTogether>Держать итог вместе</KeepFooterTogether>
    <KeepGroupFooterTogether>Держать итог группы вместе</KeepGroupFooterTogether>
    <KeepGroupHeaderTogether>Держать заголовок группы вместе</KeepGroupHeaderTogether>
    <KeepGroupTogether>Держать группу вместе</KeepGroupTogether>
    <KeepHeaderTogether>Держать заголовок вместе</KeepHeaderTogether>
    <KeepReportSummaryTogether>Держать итог отчета вместе</KeepReportSummaryTogether>
    <KeepSubReportTogether>Держать Вложенный отчет вместе</KeepSubReportTogether>
    <Key>Ключ</Key>
    <KeyDataColumn>Колонка данных с ключом</KeyDataColumn>
    <Keys>Ключи</Keys>
    <LabelColor>Цвет заголовка</LabelColor>
    <LabelRotationMode>Режим поворота подписей</LabelRotationMode>
    <Labels>Заголовки</Labels>
    <LabelsColor>Цвет заголовков</LabelsColor>
    <LabelsOffset>Смещение заголовка</LabelsOffset>
    <Language>Язык</Language>
    <LargeHeight>Большая высота</LargeHeight>
    <LargeHeightFactor>Коэффициент большой высоты</LargeHeightFactor>
    <Left>Слева</Left>
    <LeftSide>Левая сторона</LeftSide>
    <Legend>Легенда</Legend>
    <LegendBorderColor>Цвет границы легенды</LegendBorderColor>
    <LegendBrush>Кисть легенды</LegendBrush>
    <LegendLabelsColor>Цвет подписей легенды</LegendLabelsColor>
    <LegendTitleColor>Цвет заголовка</LegendTitleColor>
    <LegendValueType>Тип значения легенды</LegendValueType>
    <Length>Длина</Length>
    <LengthUnderLabels>Длина под подписью</LengthUnderLabels>
    <Lighting>Освещение</Lighting>
    <Linear>Линейная</Linear>
    <LineColor>Цвет линии</LineColor>
    <LineColorNegative>Цвет линии негативных значений</LineColorNegative>
    <LineLimit>Ограничение строки</LineLimit>
    <LineMarker>Маркер линии</LineMarker>
    <LinesOfUnderline>Линии подчеркивания</LinesOfUnderline>
    <LineStyle>Стиль линии</LineStyle>
    <LineWidth>Толщина линии</LineWidth>
    <Linked>Связь</Linked>
    <ListOfArguments>Список аргументов</ListOfArguments>
    <ListOfHyperlinks>Список гиперссылок</ListOfHyperlinks>
    <ListOfTags>Список тегов</ListOfTags>
    <ListOfToolTips>Список подсказок</ListOfToolTips>
    <ListOfValues>Список значений</ListOfValues>
    <ListOfValuesClose>Список значений закрытия</ListOfValuesClose>
    <ListOfValuesEnd>Список конечных значений</ListOfValuesEnd>
    <ListOfValuesHigh>Список максимумов</ListOfValuesHigh>
    <ListOfValuesLow>Список минимумов</ListOfValuesLow>
    <ListOfValuesOpen>Список значений открытия</ListOfValuesOpen>
    <ListOfWeights>Список весовых значений</ListOfWeights>
    <Localizable>Локализировать</Localizable>
    <Location>Позиция</Location>
    <Locked>Замок</Locked>
    <Logarithmic>Логарифмическая</Logarithmic>
    <LogarithmicScale>Логарифмическая шкала</LogarithmicScale>
    <Low>Низкое</Low>
    <MajorInterval>Основной интервал</MajorInterval>
    <MapID>Карта</MapID>
    <MapStyle>Стиль</MapStyle>
    <MapType>Тип карты</MapType>
    <Margins>Поля</Margins>
    <Marker>Маркер</Marker>
    <MarkerAlignment>Выравнивание маркера</MarkerAlignment>
    <MarkerBorder>Граница маркера</MarkerBorder>
    <MarkerColor>Цвет маркера</MarkerColor>
    <MarkerSize>Размер маркера</MarkerSize>
    <MarkerType>Тип маркера</MarkerType>
    <MarkerVisible>Показывать маркер</MarkerVisible>
    <MasterComponent>Мастер компонент</MasterComponent>
    <MasterKeyDataColumn>Колонка данных с мастер-ключом</MasterKeyDataColumn>
    <MatrixSize>Размер матрицы</MatrixSize>
    <MaxDate>Максимальная дата</MaxDate>
    <MaxDropDownItems>Максимальное количество элементов в списке</MaxDropDownItems>
    <MaxHeight>Макс. высота</MaxHeight>
    <Maximum>Максимальное значение</Maximum>
    <MaximumValue>Максимальное значение</MaximumValue>
    <MaxLength>Максимальная длина</MaxLength>
    <MaxNumberOfLines>Максимальное число строк</MaxNumberOfLines>
    <MaxSize>Максимальный размер</MaxSize>
    <MaxValue>Максимальное значение</MaxValue>
    <MaxWidth>Максимальная ширина</MaxWidth>
    <MergeDuplicates>Объединять дубликаты</MergeDuplicates>
    <MergeHeaders>Сливать заголовки</MergeHeaders>
    <Mid>Средний</Mid>
    <MinDate>Минимальная дата</MinDate>
    <MinHeight>Мин. высота</MinHeight>
    <Minimum>Минимальное значение</Minimum>
    <MinimumFontSize>Минимальный размер шрифта</MinimumFontSize>
    <MinimumValue>Минимальное значение</MinimumValue>
    <MinorColor>Цвет промежуточных линий</MinorColor>
    <MinorCount>Количество промежуточных линий</MinorCount>
    <MinorInterval>Промежуточный интервал</MinorInterval>
    <MinorLength>Длина промежуточных линий</MinorLength>
    <MinorStyle>Стиль промежуточных линий</MinorStyle>
    <MinorVisible>Показывать промежуточные линии</MinorVisible>
    <MinRowsInColumn>Минимальное количество строк в колонке</MinRowsInColumn>
    <MinSize>Минимальный размер</MinSize>
    <MinValue>Минимальное значение</MinValue>
    <MinWidth>Минимальная ширина</MinWidth>
    <MirrorMargins>Зеркальные поля</MirrorMargins>
    <Mode>Режим</Mode>
    <Module>Модуль</Module>
    <Move>Перемещение</Move>
    <Multiline>Многострочный</Multiline>
    <MultipleFactor>Кратный множитель</MultipleFactor>
    <Name>Наименование</Name>
    <NameInSource>Наименование в источнике</NameInSource>
    <NameParent>Наименование главного</NameParent>
    <Namespaces>Пространства имен</Namespaces>
    <Negative>Негативный</Negative>
    <NestedLevel>Уровень вложенности</NestedLevel>
    <NewColumnAfter>Новая колонка после</NewColumnAfter>
    <NewColumnBefore>Новая колонка до</NewColumnBefore>
    <NewPageAfter>Новая страница после</NewPageAfter>
    <NewPageBefore>Новая страница до</NewPageBefore>
    <NextPage>Следующая страница</NextPage>
    <NoIcon>Нет значка</NoIcon>
    <NullText>Нулевой текст</NullText>
    <NumberOfColumns>Количество колонок</NumberOfColumns>
    <NumberOfCopies>Число копий</NumberOfCopies>
    <NumberOfPass>Количество проходов</NumberOfPass>
    <NumberOfValues>Количество значений</NumberOfValues>
    <OddStyle>Стиль нечетных строк</OddStyle>
    <Offset>Смещение</Offset>
    <OffsetAngle>Угол смещения</OffsetAngle>
    <OnlyText>Только текст</OnlyText>
    <Operation>Операция</Operation>
    <Options>Параметры</Options>
    <Orientation>Ориентация</Orientation>
    <OthersText>Текст для остальных значений</OthersText>
    <PageHeight>Высота</PageHeight>
    <PageNumbers>Номера страниц</PageNumbers>
    <PageWidth>Ширина</PageWidth>
    <Paper>Бумага</Paper>
    <PaperSize>Размер страницы</PaperSize>
    <PaperSourceOfFirstPage>Источник бумаги для первой страницы</PaperSourceOfFirstPage>
    <PaperSourceOfOtherPages>Источник бумаги для остальных страниц</PaperSourceOfOtherPages>
    <Parameter>Параметр</Parameter>
    <Parameters>Параметры</Parameters>
    <ParametersOrientation>Расположение параметров</ParametersOrientation>
    <ParentColumns>Колонки главного</ParentColumns>
    <ParentSource>Главный источник данных</ParentSource>
    <ParentValue>Главное значение</ParentValue>
    <PasswordChar>Символ пароля</PasswordChar>
    <Path>Путь</Path>
    <PathData>Путь к XML данным</PathData>
    <PathSchema>Путь к XML схеме</PathSchema>
    <Placement>Размещение</Placement>
    <PlaceOnToolbox>Размещать в инструментарии</PlaceOnToolbox>
    <PointAtCenter>Точка в Центре</PointAtCenter>
    <Position>Позиция</Position>
    <Positive>Позитивный</Positive>
    <PreferredColumnWidth>Предпочтительная ширина колонки</PreferredColumnWidth>
    <PreferredRowHeight>Предпочтительная высота колонки</PreferredRowHeight>
    <PreventIntersection>Избегать пересечения</PreventIntersection>
    <PreviewMode>Режим предварительного просмотра</PreviewMode>
    <PreviewSettings>Настройка предварительного просмотра</PreviewSettings>
    <Printable>Печатать</Printable>
    <PrintAtBottom>Печатать внизу</PrintAtBottom>
    <PrinterName>Наименование принтера</PrinterName>
    <PrinterSettings>Настройка принтера</PrinterSettings>
    <PrintHeadersFootersFromPreviousPage>Использовать Заголовки и Итоги с предшествующей страницы</PrintHeadersFootersFromPreviousPage>
    <PrintIfDetailEmpty>Печатать если нет детальных данных</PrintIfDetailEmpty>
    <PrintIfEmpty>Печатать если нет данных</PrintIfEmpty>
    <PrintIfParentDisabled>Печатать если главный выключен</PrintIfParentDisabled>
    <PrintOn>Печатать на</PrintOn>
    <PrintOnAllPages>Печатать на всех страницах</PrintOnAllPages>
    <PrintOnEvenOddPages>Печатать на четных-нечетных страницах</PrintOnEvenOddPages>
    <PrintOnPreviousPage>Печатать на предыдущей странице</PrintOnPreviousPage>
    <PrintTitleOnAllPages>Печатать заголовок на всех страницах</PrintTitleOnAllPages>
    <PrintVerticalBars>Печатать вертикальные секции</PrintVerticalBars>
    <ProcessAt>Обработать в</ProcessAt>
    <ProcessAtEnd>Обработать в конце</ProcessAtEnd>
    <ProcessingDuplicates>Обработка дубликатов</ProcessingDuplicates>
    <ProcessTilde>Обрабатывать Tilde</ProcessTilde>
    <ProductHomePage>Домашняя страница</ProductHomePage>
    <RadarStyle>Стиль лепестков</RadarStyle>
    <Radius>Радиус</Radius>
    <RadiusMode>Режим радиуса</RadiusMode>
    <Range>Диапазон</Range>
    <RangeColorMode>Режим цвета диапозона</RangeColorMode>
    <RangeFrom>С</RangeFrom>
    <RangeScrollEnabled>Прокрутка диапазона</RangeScrollEnabled>
    <RangeTo>По</RangeTo>
    <Ratio>Коэффициент</Ratio>
    <RatioY>Коэффициент Y</RatioY>
    <ReadOnly>Только чтение</ReadOnly>
    <ReconnectOnEachRow>Обновлять на каждой записи</ReconnectOnEachRow>
    <ReferencedAssemblies>Ссылки на сборки</ReferencedAssemblies>
    <Refresh>Обновить</Refresh>
    <RefreshTime>Время обновления</RefreshTime>
    <Relation>Связь</Relation>
    <RelationName>Наименование связи</RelationName>
    <Relations>Связи</Relations>
    <RelativeHeight>Относительная высота</RelativeHeight>
    <RelativeWidth>Относительная ширина</RelativeWidth>
    <RemoveUnusedDataBeforeStart>Спрятать неиспользуемые данные перед построением</RemoveUnusedDataBeforeStart>
    <RenderTo>Выводить в</RenderTo>
    <ReportAlias>Псевдоним отчета</ReportAlias>
    <ReportAuthor>Автор отчета</ReportAuthor>
    <ReportCacheMode>Кэш отчета</ReportCacheMode>
    <ReportDescription>Описание отчета</ReportDescription>
    <ReportImage>Изображение отчета</ReportImage>
    <ReportName>Наименование отчета</ReportName>
    <ReportUnit>Единица измерения</ReportUnit>
    <RequestFromUser>Запросить у пользователя</RequestFromUser>
    <RequestParameters>Требовать параметры</RequestParameters>
    <ResetDataSource>Сброс данных</ResetDataSource>
    <ResetPageNumber>Сбросить номер страниц</ResetPageNumber>
    <Resize>Изменение размеров</Resize>
    <Resource>Ресурс</Resource>
    <Resources>Ресурсы</Resources>
    <Restrictions>Ограничения</Restrictions>
    <RetrieveOnlyUsedData>Запрашивать только необходимые данные</RetrieveOnlyUsedData>
    <ReturnValue>Возвращаемое значение</ReturnValue>
    <ReverseHor>Перевернуть по горизонтали</ReverseHor>
    <ReverseVert>Перевернуть по вертикали</ReverseVert>
    <Right>Справа</Right>
    <RightSide>Правая сторона</RightSide>
    <RightToLeft>Справа налево</RightToLeft>
    <Rotation>Поворот</Rotation>
    <RotationLabels>Поворот подписей</RotationLabels>
    <Round>Округлость</Round>
    <RowCount>Количество строк</RowCount>
    <RowHeadersVisible>Видимость заголовков</RowHeadersVisible>
    <RowHeaderWidth>Ширина заголовка строки</RowHeaderWidth>
    <Scale>Масштаб</Scale>
    <ScaleHor>Масштабирование по горизонтали</ScaleHor>
    <ScriptLanguage>Скриптовый язык</ScriptLanguage>
    <SegmentPerHeight>Сегментов по высоте</SegmentPerHeight>
    <SegmentPerWidth>Сегментов по ширине</SegmentPerWidth>
    <SelectedIndex>Выбранный индекс</SelectedIndex>
    <SelectedItem>Выбранный элемент</SelectedItem>
    <SelectedKey>Выбранный ключ</SelectedKey>
    <SelectedValue>Выбранное значение</SelectedValue>
    <Selection>Выбор</Selection>
    <SelectionBackColor>Цвет выбора</SelectionBackColor>
    <SelectionEnabled>Выбор включен</SelectionEnabled>
    <SelectionForeColor>Цвет текста выбора</SelectionForeColor>
    <SelectionMode>Способ выбора</SelectionMode>
    <SerialNumber>Серийный Номер</SerialNumber>
    <Series>Ряды</Series>
    <SeriesLabels>Заголовки рядов</SeriesLabels>
    <SeriesLabelsBorderColor>Цвет границы</SeriesLabelsBorderColor>
    <SeriesLabelsBrush>Кисть подписей</SeriesLabelsBrush>
    <SeriesLabelsColor>Цвет подписей</SeriesLabelsColor>
    <SeriesLighting>Освещение рядов</SeriesLighting>
    <SeriesShowShadow>Отображение тени рядов</SeriesShowShadow>
    <Shadow>Тень</Shadow>
    <ShadowBrush>Кисть тени</ShadowBrush>
    <ShadowColor>Цвет тени</ShadowColor>
    <ShadowSize>Размер тени</ShadowSize>
    <ShapeType>Тип геометрии</ShapeType>
    <Shift>Смещение</Shift>
    <ShiftMode>Режим смещения</ShiftMode>
    <ShowBehind>Показывать сзади</ShowBehind>
    <ShowDialog>Показывать диалог</ShowDialog>
    <ShowEdgeValues>Показывать крайние значения</ShowEdgeValues>
    <ShowImageBehind>Показывать изображения за</ShowImageBehind>
    <ShowInLegend>Показывать в легенде</ShowInLegend>
    <ShowInPercent>Показывать в процентах</ShowInPercent>
    <ShowLabels>Показывать подписи</ShowLabels>
    <ShowLabelText>Показывать текст</ShowLabelText>
    <ShowLegend>Показывать легенду</ShowLegend>
    <ShowMarker>Показывать маркер</ShowMarker>
    <ShowNulls>Показывать неопределенные</ShowNulls>
    <ShowOnZeroValues>Показывать при нулевых величинах</ShowOnZeroValues>
    <ShowOthers>Показывать остальные значения</ShowOthers>
    <ShowPercents>Показывать проценты</ShowPercents>
    <ShowQuietZoneIndicator>Показывать Quiet Zone индикатор</ShowQuietZoneIndicator>
    <ShowQuietZones>Показывать Quiet Zones</ShowQuietZones>
    <ShowScrollBar>Показывать полосу прокрутки</ShowScrollBar>
    <ShowSeriesLabels>Показывать подписи рядов</ShowSeriesLabels>
    <ShowShadow>Показывать тени</ShowShadow>
    <ShowTotal>Показывать итоги</ShowTotal>
    <ShowUpDown>Показывать вверх вниз</ShowUpDown>
    <ShowValue>Показывать значение</ShowValue>
    <ShowXAxis>Показывать X ось</ShowXAxis>
    <ShowYAxis>Показывать Y ось</ShowYAxis>
    <ShowZeros>Показывать нули</ShowZeros>
    <ShrinkFontToFit>Уменьшить шрифт чтобы вместить</ShrinkFontToFit>
    <ShrinkFontToFitMinimumSize>Уменьшить шрифт чтобы вместить минимальный размер</ShrinkFontToFitMinimumSize>
    <Side>Сторона</Side>
    <Sides>Стороны</Sides>
    <Simple>Простой</Simple>
    <Size>Размер</Size>
    <SizeMode>Способ изменения размера</SizeMode>
    <Skin>Скин</Skin>
    <SkipFirst>Пропустить первого</SkipFirst>
    <SkipIndices>Пропустить индексы</SkipIndices>
    <SkipIndicesObj>Пропустить индексы объектов</SkipIndicesObj>
    <SkipMajorValues>Пропустить основные значения</SkipMajorValues>
    <SkipValues>Пропустить значения</SkipValues>
    <SkipValuesObj>Пропустить значения объектов</SkipValuesObj>
    <Smoothing>Сглаживание</Smoothing>
    <Sort>Сортировка</Sort>
    <SortBy>Сортировать по</SortBy>
    <SortDirection>Направление сортировки</SortDirection>
    <Sorted>Сортировать</Sorted>
    <SortingColumn>Колонка для сортировки</SortingColumn>
    <SortingEnabled>Сортировка включена</SortingEnabled>
    <SortType>Тип сортировки</SortType>
    <Space>Пробел</Space>
    <Spacing>Расстояние</Spacing>
    <SqlCommand>SQL запрос</SqlCommand>
    <StartAngle>Начальный угол</StartAngle>
    <StartCap>Тип начала</StartCap>
    <StartColor>Начальный цвет</StartColor>
    <StartFromZero>Стартовать с нуля</StartFromZero>
    <StartMode>Режим запуска</StartMode>
    <StartNewPage>Новая страница</StartNewPage>
    <StartNewPageIfLessThan>Новая страница, если меньше чем</StartNewPageIfLessThan>
    <StartPosition>Начальная позиция</StartPosition>
    <StartValue>Начальное значение</StartValue>
    <StartWidth>Начальная ширина</StartWidth>
    <Step>Шаг</Step>
    <Stop>Остановить</Stop>
    <StopBeforePage>Остановиться после страницы</StopBeforePage>
    <StopBeforePrint>Остановиться после</StopBeforePrint>
    <StoreImagesInResources>Сохранять изображения в ресурсах</StoreImagesInResources>
    <Stretch>Растягивать</Stretch>
    <StretchToPrintArea>Растянуть на область печати</StretchToPrintArea>
    <StripBrush>Кисть полосы</StripBrush>
    <Strips>Полосы</Strips>
    <StructuredAppendPosition>Structured Append Position</StructuredAppendPosition>
    <StructuredAppendTotal>StructuredAppendTotal</StructuredAppendTotal>
    <Style>Стиль</Style>
    <StyleColors>Цвета стиля</StyleColors>
    <Styles>Стили</Styles>
    <SubReportPage>Страница суб-отчета</SubReportPage>
    <Summary>Итог</Summary>
    <SummaryExpression>Выражение суммирования</SummaryExpression>
    <SummarySortDirection>Направление сортировки суммирования</SummarySortDirection>
    <SummaryType>Тип суммирования</SummaryType>
    <SummaryValues>Значения итога</SummaryValues>
    <SupplementCode>Код дополнения</SupplementCode>
    <SupplementType>Тип дополнения</SupplementType>
    <SweepAngle>Угол стреловидности</SweepAngle>
    <SystemVariable>Системная переменная</SystemVariable>
    <SystemVariables>Системные переменные</SystemVariables>
    <Table>Таблица</Table>
    <Tag>Тег</Tag>
    <TagDataColumn>Колонка данных тегов</TagDataColumn>
    <TagValue>Значение Тега</TagValue>
    <Tension>Напряженность</Tension>
    <Text>Текст</Text>
    <TextAfter>Текст после</TextAfter>
    <TextAlign>Выравнивание текста</TextAlign>
    <TextAlignment>Выравнивание текста</TextAlignment>
    <TextBefore>Текст до</TextBefore>
    <TextBrush>Текстовая кисть</TextBrush>
    <TextColor>Цвет текста</TextColor>
    <TextFormat>Формат</TextFormat>
    <TextOnly>Только текст</TextOnly>
    <TextOptions>Параметры текста</TextOptions>
    <TextQuality>Качество текста</TextQuality>
    <Ticks>Метки</Ticks>
    <Title>Заголовок</Title>
    <TitleBeforeHeader>Титул до заголовка</TitleBeforeHeader>
    <TitleColor>Цвет заголовка</TitleColor>
    <TitleDirection>Направление заголовка</TitleDirection>
    <TitleFont>Шрифт заголовка</TitleFont>
    <TitleVisible>Доступность</TitleVisible>
    <To>По</To>
    <Today>Сегодня</Today>
    <ToolTip>Подсказка</ToolTip>
    <ToolTipDataColumn>Колонка данных подсказок</ToolTipDataColumn>
    <Top>Сверху</Top>
    <Topmost>Самый верхний</Topmost>
    <TopmostLine>Линия сверху</TopmostLine>
    <TopN>Лучшие значения</TopN>
    <TopSide>Верхняя сторона</TopSide>
    <Total>Итог</Total>
    <Totals>Итоги</Totals>
    <TransparentColor>Цвет прозрачности</TransparentColor>
    <TrendLine>Линия тренда</TrendLine>
    <TrendLineColor>Цвет</TrendLineColor>
    <TrendLineShowShadow>Показать тень</TrendLineShowShadow>
    <Trimming>Подгонка</Trimming>
    <Type>Тип</Type>
    <TypeName>Наименование типа</TypeName>
    <Types>Типы</Types>
    <Underline>Подчеркнутый</Underline>
    <UndoLimit>Количество шагов</UndoLimit>
    <Unit>Единица измерения</Unit>
    <UnlimitedBreakable>Разрывать неограниченный</UnlimitedBreakable>
    <UnlimitedHeight>Неограниченная высота</UnlimitedHeight>
    <UnlimitedWidth>Неограниченная ширина</UnlimitedWidth>
    <UseAliases>Использовать псевдонимы</UseAliases>
    <UseExternalReport>Использовать внешний отчет</UseExternalReport>
    <UseParentStyles>Исп. стили владельца</UseParentStyles>
    <UseRangeColor>Использовать цветовую гамму</UseRangeColor>
    <UseRectangularSymbols>Использовать прямоугольные символы</UseRectangularSymbols>
    <UseSeriesColor>Использовать цвета рядов</UseSeriesColor>
    <UseStyleOfSummaryInColumnTotal>Использовать стиль итога в итоге колонки</UseStyleOfSummaryInColumnTotal>
    <UseStyleOfSummaryInRowTotal>Использовать стиль итога в итоге строки</UseStyleOfSummaryInRowTotal>
    <UseValuesFromTheSpecifiedRange>Использовать значения из указанного диапозона</UseValuesFromTheSpecifiedRange>
    <Value>Значение</Value>
    <ValueClose>Закрывающее значение</ValueClose>
    <ValueDataColumn>Колонка данных значений</ValueDataColumn>
    <ValueDataColumnClose>Колонка данных значений закрытия</ValueDataColumnClose>
    <ValueDataColumnEnd>Колонка данных конечных значений</ValueDataColumnEnd>
    <ValueDataColumnHigh>Колонка данных максимумов</ValueDataColumnHigh>
    <ValueDataColumnLow>Колонка данных минимумов</ValueDataColumnLow>
    <ValueDataColumnOpen>Колонка данных значений открытия</ValueDataColumnOpen>
    <ValueEnd>Конечное значение</ValueEnd>
    <ValueHigh>Значение максимума</ValueHigh>
    <ValueLow>Значение минимума</ValueLow>
    <ValueOpen>Значение открытия</ValueOpen>
    <Values>Значения</Values>
    <ValueType>Тип значения</ValueType>
    <ValueTypeSeparator>Тип сепаратора значений</ValueTypeSeparator>
    <Variable>Переменная</Variable>
    <Variables>Переменные</Variables>
    <Version>Версия</Version>
    <VertAlignment>Вертикальное выравнивание</VertAlignment>
    <VertSpacing>Расстояние по вертикали</VertSpacing>
    <ViewMode>Вид страницы</ViewMode>
    <Visible>Видимость</Visible>
    <Watermark>Водяной знак</Watermark>
    <Weight>Вес</Weight>
    <WeightDataColumn>Колонка значений веса</WeightDataColumn>
    <Weights>Веса</Weights>
    <Width>Ширина</Width>
    <WindowState>Состояние окна</WindowState>
    <WordWrap>Перенос текста</WordWrap>
    <Wrap>Перенос</Wrap>
    <WrapGap>Промежуток переноса</WrapGap>
    <XAxis>Ось X</XAxis>
    <XTopAxis>Ось X Верхняя</XTopAxis>
    <YAxis>Ось Y</YAxis>
    <YRightAxis>Ось Y Правая</YRightAxis>
    <Zoom>Масштаб</Zoom>
  </PropertyMain>
  <PropertySystemColors>
    <ActiveBorder>Граница активного окна</ActiveBorder>
    <ActiveCaption>Заголовок активного окна</ActiveCaption>
    <ActiveCaptionText>Текст заголовка активного окна</ActiveCaptionText>
    <AppWorkspace>Рабочая область приложения</AppWorkspace>
    <Control>Кнопка</Control>
    <ControlDark>Кнопка темная</ControlDark>
    <ControlDarkDark>Кнопка самая темная</ControlDarkDark>
    <ControlLight>Кнопка светлая</ControlLight>
    <ControlLightLight>Кнопка самая светлая</ControlLightLight>
    <ControlText>Текст кнопки</ControlText>
    <Desktop>Рабочий стол</Desktop>
    <GrayText>Текст недоступный</GrayText>
    <Highlight>Выделенный фон</Highlight>
    <HighlightText>Выделенный текст</HighlightText>
    <HotTrack>Подсвеченный</HotTrack>
    <InactiveBorder>Граница неактивного окна</InactiveBorder>
    <InactiveCaption>Заголовок неактивного окна</InactiveCaption>
    <InactiveCaptionText>Текст заголовка неактивного окна</InactiveCaptionText>
    <Info>Подсказка</Info>
    <InfoText>Текст подсказки</InfoText>
    <Menu>Фон меню</Menu>
    <MenuText>Текст меню</MenuText>
    <ScrollBar>Полоса прокрутки</ScrollBar>
    <Window>Фон окна</Window>
    <WindowFrame>Рамка окна</WindowFrame>
    <WindowText>Текст окна</WindowText>
  </PropertySystemColors>
  <QueryBuilder>
    <AddObject>Добавить объект</AddObject>
    <AddSubQuery>Добавить производную таблицу</AddSubQuery>
    <AllObjects>(Все объекты)</AllObjects>
    <BadFromObjectExpression>Неверное указание объекта выборки (FROM)</BadFromObjectExpression>
    <BadObjectName>Неверное имя объекта!</BadObjectName>
    <BadSelectStatement>Неверное выражение SELECT!</BadSelectStatement>
    <Collections>Коллекции</Collections>
    <CreateLinksFromForeignKeys>Создавать связи из внешних ключей</CreateLinksFromForeignKeys>
    <CriteriaAlias>Псевдоним</CriteriaAlias>
    <CriteriaCriteria>Условие</CriteriaCriteria>
    <CriteriaExpression>Выражение</CriteriaExpression>
    <CriteriaGroupBy>Группировка</CriteriaGroupBy>
    <CriteriaOr>Или...</CriteriaOr>
    <CriteriaOutput>Вывод</CriteriaOutput>
    <CriteriaSortOrder>Порядок сортировки</CriteriaSortOrder>
    <CriteriaSortType>Тип сортировки</CriteriaSortType>
    <Database>База данных</Database>
    <DataSourceProperties>Свойства источника данных</DataSourceProperties>
    <DialectDontSupportDatabases>Сервер не поддерживает выборку из нескольких баз данных.</DialectDontSupportDatabases>
    <DialectDontSupportSchemas>Сервер не имеет поддержки схем.</DialectDontSupportSchemas>
    <DialectDontSupportUnions>Сервер не поддерживает объединения (UNION).</DialectDontSupportUnions>
    <DialectDontSupportUnionsBrackets>Сервер не поддерживает скобки при объединении (UNION).</DialectDontSupportUnionsBrackets>
    <DialectDontSupportUnionsBracketsInSubQuery>Сервер не поддерживает скобки при объединении (UNION) в подзапросах.</DialectDontSupportUnionsBracketsInSubQuery>
    <DialectDontSupportUnionsInSubQueries>Сервер не поддерживает объединения (UNION) в подзапросах.</DialectDontSupportUnionsInSubQueries>
    <Edit>Изменить</Edit>
    <EncloseWithBrackets>Заключить в скобки</EncloseWithBrackets>
    <Expressions>Выражения</Expressions>
    <InsertEmptyItem>Добавить пустой элемент</InsertEmptyItem>
    <JoinExpression>Добавить выражение</JoinExpression>
    <LabelAlias>Псевдоним:</LabelAlias>
    <LabelFilterObjectsBySchemaName>Фильтровать объекты:</LabelFilterObjectsBySchemaName>
    <LabelJoinExpression>Добавить выражение:</LabelJoinExpression>
    <LabelLeftColumn>Левая колонка:</LabelLeftColumn>
    <LabelLeftObject>Левый объект:</LabelLeftObject>
    <LabelObject>Объект:</LabelObject>
    <LabelRightColumn>Правая колонка:</LabelRightColumn>
    <LabelRightObject>Правый объект:</LabelRightObject>
    <LinkProperties>Свойства соединения</LinkProperties>
    <MetadataProviderCantExecSQL>Выбранный провайдер метаданных не поддерживает выполнение SQL запросов.</MetadataProviderCantExecSQL>
    <MetaProviderCantLoadMetadata>Выбранный провайдер метаданных не поддерживает автоматическую загрузку метаданных</MetaProviderCantLoadMetadata>
    <MetaProviderCantLoadMetadataForDatabase>Выбранный провайдер метаданных не поддерживает автоматическую загрузку метаданных для базы данных: {0}</MetaProviderCantLoadMetadataForDatabase>
    <MoveDown>Переместить вниз</MoveDown>
    <MoveUp>Переместить вверх</MoveUp>
    <NewUnionSubQuery>Новый подзапрос объединения</NewUnionSubQuery>
    <NoConnectionObject>Не указан объект connection (не задано свойство {0}).</NoConnectionObject>
    <NoTransactionObject>Не указан объект transaction (не задано свойство {0}).</NoTransactionObject>
    <Objects>Объекты</Objects>
    <ProcedureParameters>Параметры процедуры</ProcedureParameters>
    <Procedures>Процедуры</Procedures>
    <qnSaveChanges>Вы хотите сохранить изменения?</qnSaveChanges>
    <Query>Запрос</Query>
    <QueryBuilder>Построитель запросов</QueryBuilder>
    <QueryParameters>Параметры</QueryParameters>
    <QueryProperties>Свойства запроса</QueryProperties>
    <Remove>Удалить</Remove>
    <RemoveBrackets>Убрать скобки</RemoveBrackets>
    <RunQueryBuilder>Запустить построитель запросов</RunQueryBuilder>
    <SelectAllFromLeft>Выбрать все слева</SelectAllFromLeft>
    <SelectAllFromRight>Выбрать все справа</SelectAllFromRight>
    <SwitchToDerivedTable>Переключиться на производную таблицу</SwitchToDerivedTable>
    <Tables>Таблицы</Tables>
    <UnexpectedTokenAt>Непредвиденная лексема "{0}" в строке {1}, в позиции {2}!</UnexpectedTokenAt>
    <Unions>Объединения</Unions>
    <UnionSubMenu>Объединение</UnionSubMenu>
    <ViewQuery>Просмотреть результаты запроса</ViewQuery>
    <Views>Представления</Views>
  </QueryBuilder>
  <Questions>
    <qnConfiguration>Пожалуйста, выберите тип конфигурации для панели свойств. От типа выбранной конфигурации зависит количество отображаемых свойств и их сложности для разработчика отчетов. Вы всегда сможете изменить тип конфигурации из контекстного меню панели свойств.</qnConfiguration>
    <qnDictionaryNew>Создать новый словарь?</qnDictionaryNew>
    <qnLanguageNew>Вы изменили язык отчета, это приведет к генерации нового кода отчета. Вы уверены, что хотите сохранить новый язык?</qnLanguageNew>
    <qnPageDelete>Вы действительно хотите удалить страницу?</qnPageDelete>
    <qnRemove>Вы действительно хотите удалить?</qnRemove>
    <qnRemoveService>Удалить сервис?</qnRemoveService>
    <qnRemoveServiceCategory>Удалить категорию?</qnRemoveServiceCategory>
    <qnRemoveUnused>Удалить неиспользуемое?</qnRemoveUnused>
    <qnReplace>Вы действительно хотите заменить существующий элемент {0}?</qnReplace>
    <qnRestoreDefault>Восстановить значения по умолчанию?</qnRestoreDefault>
    <qnSaveChanges>Сохранить изменения в {0}?</qnSaveChanges>
    <qnSaveChangesToPreviewPage>Сохранить изменения текущей страницы?</qnSaveChangesToPreviewPage>
    <qnSynchronize>Синхронизировать содержимое Хранилища Данных и содержимое Словаря?</qnSynchronize>
    <qnSynchronizeServices>Синхронизировать сервисы?</qnSynchronizeServices>
  </Questions>
  <Report>
    <Address>Адрес</Address>
    <Alphabetical>По алфавиту</Alphabetical>
    <Bands>Секции</Bands>
    <Basic>Базовый</Basic>
    <BasicConfiguration>Минимальное количество свойств объектов, которые необходимы для построения основных типов отчетов.</BasicConfiguration>
    <BusinessObjects>Бизнес-объекты</BusinessObjects>
    <Categorized>По категориям</Categorized>
    <Charts>Диаграммы</Charts>
    <ClickForMoreDetails>Нажмите для просмотра</ClickForMoreDetails>
    <CollapseAll>Свернуть все</CollapseAll>
    <Collection>Коллекция</Collection>
    <CompilingReport>Компиляция отчета</CompilingReport>
    <Complete>Завершено</Complete>
    <Components>Компоненты</Components>
    <ConnectingToData>Соединение с данными</ConnectingToData>
    <CopyOf>Копия</CopyOf>
    <CreateNewReportPageForm>Создать новый отчет, добавить страницу или форму</CreateNewReportPageForm>
    <CreatingReport>Создание отчета</CreatingReport>
    <CrossBands>Кросс</CrossBands>
    <Dashboard>Панель индикаторов</Dashboard>
    <Dialogs>Элементы управления</Dialogs>
    <EditStyles>[Редактировать стили]</EditStyles>
    <Enhancements>Улучшения</Enhancements>
    <Errors>Ошибки</Errors>
    <EventsTab>События</EventsTab>
    <ExpandAll>Развернуть все</ExpandAll>
    <FilterAnd>И</FilterAnd>
    <FilterOr>Или</FilterOr>
    <FinishingReport>Завершение отчета</FinishingReport>
    <FirstPass>Первый проход</FirstPass>
    <FixedBugs>Исправленные ошибки</FixedBugs>
    <Gallery>Галерея</Gallery>
    <GenerateNewCode>Сгенерировать новый код</GenerateNewCode>
    <History>История</History>
    <Infographics>Инфографика</Infographics>
    <InfoMessage>{0} - {1} найдены.</InfoMessage>
    <InformationMessages>Информационные сообщения</InformationMessages>
    <LabelAlias>Псевдоним:</LabelAlias>
    <LabelAuthor>Автор:</LabelAuthor>
    <LabelBackground>Фон:</LabelBackground>
    <LabelCategory>Категория:</LabelCategory>
    <LabelCentimeters>Сантиметры:</LabelCentimeters>
    <LabelCollectionName>Наименование коллекции:</LabelCollectionName>
    <LabelColor>Цвет:</LabelColor>
    <LabelCountData>Количество данных:</LabelCountData>
    <LabelDataBand>Данные:</LabelDataBand>
    <LabelDataColumn>Колонка с данными:</LabelDataColumn>
    <LabelDefaultValue>По умолчанию:</LabelDefaultValue>
    <LabelExpression>Выражение:</LabelExpression>
    <LabelFactorLevel>Уровень:</LabelFactorLevel>
    <LabelFontName>Наименование шрифта:</LabelFontName>
    <LabelFunction>Функция:</LabelFunction>
    <LabelHundredthsOfInch>Сотые дюйма:</LabelHundredthsOfInch>
    <LabelInches>Дюймы:</LabelInches>
    <LabelMillimeters>Миллиметры:</LabelMillimeters>
    <LabelName>Наименование:</LabelName>
    <LabelNameInSource>Наименование в источнике:</LabelNameInSource>
    <LabelNestedLevel>Коэффициент вложенности:</LabelNestedLevel>
    <LabelPassword>Пароль:</LabelPassword>
    <LabelPixels>Пиксели:</LabelPixels>
    <LabelQueryTimeout>Время ожидания запроса:</LabelQueryTimeout>
    <LabelSystemVariable>Системные переменные:</LabelSystemVariable>
    <LabelTotals>Итоги:</LabelTotals>
    <LabelType>Тип:</LabelType>
    <LabelUserName>Имя пользователя:</LabelUserName>
    <LabelValue>Значение:</LabelValue>
    <LoadingReport>Загрузка отчета</LoadingReport>
    <nameAssembly>Сборка</nameAssembly>
    <NewFeatures>Новые возможности</NewFeatures>
    <No>Нет</No>
    <NoFixes>Исправления или обновления для этой версии отсутствуют!</NoFixes>
    <NoIssues>Нет проблем</NoIssues>
    <NoNewVersions>Новые версии не обнаружены!</NoNewVersions>
    <NotAssigned>Не назначено</NotAssigned>
    <Office2010Back>Назад</Office2010Back>
    <PageNofM>Страница {0} из {1}</PageNofM>
    <PreparingReport>Подготовка отчета</PreparingReport>
    <Professional>Расширенный</Professional>
    <ProfessionalConfiguration>Все свойства объектов без исключений.</ProfessionalConfiguration>
    <PropertiesTab>Свойства</PropertiesTab>
    <RangeAll>все</RangeAll>
    <RangeCurrentPage>текущая</RangeCurrentPage>
    <RangeInfo>Введите номера или диапазоны страниц, разделенные запятыми. Например: 1, 3, 5-12</RangeInfo>
    <RangePage>Страницы</RangePage>
    <RangePages>номера</RangePages>
    <ReportChecker>Проверка отчетов</ReportChecker>
    <ReportRenderingMessages>Сообщения построения отчета</ReportRenderingMessages>
    <RestartDesigner>Вам необходимо перезапустить дизайнер отчетов.</RestartDesigner>
    <SaveReportPagesOrFormsFromReport>Сохранить отчет, страницу или форму отчета</SaveReportPagesOrFormsFromReport>
    <SavingReport>Сохранение отчета</SavingReport>
    <SecondPass>Второй проход</SecondPass>
    <Shapes>Примитивы</Shapes>
    <Standard>Стандартный</Standard>
    <StandardConfiguration>Основные свойства объектов за исключением редко используемых свойств.</StandardConfiguration>
    <StiEmptyBrush>Нет</StiEmptyBrush>
    <StiGlareBrush>Блик</StiGlareBrush>
    <StiGlassBrush>Стекло</StiGlassBrush>
    <StiGradientBrush>Градиент</StiGradientBrush>
    <StiHatchBrush>Текстура</StiHatchBrush>
    <StiSolidBrush>Сплошная</StiSolidBrush>
    <StyleBad>Плохой</StyleBad>
    <StyleGood>Хороший</StyleGood>
    <StyleNeutral>Нейтральный</StyleNeutral>
    <StyleNormal>Нормальный</StyleNormal>
    <StyleNote>Примечание</StyleNote>
    <StyleWarning>Предупреждение</StyleWarning>
    <Warnings>Предупреждения</Warnings>
    <WhatsNewInVersion>Что нового в {0}</WhatsNewInVersion>
    <When>если {0} {1}</When>
    <WhenAnd>если {0} {1} и</WhenAnd>
    <WhenValueIs>если значение равно</WhenValueIs>
  </Report>
  <ReportInfo>
    <CheckIssuesAdditionalDescription>Проверить отчет на ошибки и получить рекомендации по отчету.</CheckIssuesAdditionalDescription>
    <EncryptWithPassword>Шифрование с паролем</EncryptWithPassword>
    <EncryptWithPasswordAdditionalDescription>Установить пароль для предотвращения несанкционированного доступа к отчету.</EncryptWithPasswordAdditionalDescription>
    <EncryptWithPasswordDescription>Защита отчета</EncryptWithPasswordDescription>
    <Info>Сведения</Info>
    <ReportOptions>Параметры отчета</ReportOptions>
    <ReportOptionsAdditionalDescription>Настройка основных параметров отчета.</ReportOptionsAdditionalDescription>
  </ReportInfo>
  <ReportOpen>
    <Browse>Обзор</Browse>
    <Import>Импорт</Import>
  </ReportOpen>
  <Services>
    <categoryContextTools>Контекстное меню дизайнера</categoryContextTools>
    <categoryDesigner>Дизайнер</categoryDesigner>
    <categoryDictionary>Словарь</categoryDictionary>
    <categoryExport>Экспорт</categoryExport>
    <categoryLanguages>Языки</categoryLanguages>
    <categoryPanels>Панели</categoryPanels>
    <categoryRender>Построение отчетов</categoryRender>
    <categoryShapes>Геометрия</categoryShapes>
    <categorySL>Сервисы сохранения / загрузки</categorySL>
    <categorySystem>Система</categorySystem>
    <categoryTextFormat>Формат</categoryTextFormat>
  </Services>
  <Shapes>
    <Arrow>Стрела</Arrow>
    <BasicShapes>Базовые примитивы</BasicShapes>
    <BentArrow>Изогнутая стрела</BentArrow>
    <BlockArrows>Стрелки</BlockArrows>
    <Chevron>Нашивка</Chevron>
    <ComplexArrow>Сложная стрела</ComplexArrow>
    <DiagonalDownLine>Диагональная линия вниз</DiagonalDownLine>
    <DiagonalUpLine>Диагональная линия вверх</DiagonalUpLine>
    <Division>Деление</Division>
    <Equal>Равенство</Equal>
    <EquationShapes>Примитивы операций</EquationShapes>
    <FlowchartCard>Блок-схема: карточка</FlowchartCard>
    <FlowchartCollate>Блок-схема: сопоставление</FlowchartCollate>
    <FlowchartDecision>Блок-схема: решение</FlowchartDecision>
    <FlowchartManualInput>Блок-схема: ручной ввод</FlowchartManualInput>
    <FlowchartOffPageConnector>Блок-схема: ссылка на другую страницу</FlowchartOffPageConnector>
    <FlowchartPreparation>Блок-схема: подготовка</FlowchartPreparation>
    <FlowchartSort>Блок-схема: сортировка</FlowchartSort>
    <Frame>Багетная рамка</Frame>
    <HorizontalLine>Горизонтальная линия</HorizontalLine>
    <InsertShapes>Вставить примитив</InsertShapes>
    <LeftAndRightLine>Линии слева и справа</LeftAndRightLine>
    <Lines>Линии</Lines>
    <Minus>Минус</Minus>
    <Multiply>Умножение</Multiply>
    <Octagon>Восьмиугольник</Octagon>
    <Oval>Овал</Oval>
    <Parallelogram>Параллелограмм</Parallelogram>
    <Plus>Плюс</Plus>
    <Rectangle>Прямоугольник</Rectangle>
    <Rectangles>Прямоугольники</Rectangles>
    <RegularPentagon>Правильный пятиугольник</RegularPentagon>
    <RoundedRectangle>Закругленный прямоугольник</RoundedRectangle>
    <ServiceCategory>Геометрические примитивы</ServiceCategory>
    <ShapeStyles>Стили фигур</ShapeStyles>
    <SnipDiagonalSideCornerRectangle>Прямоугольник со срезанными сверху углами</SnipDiagonalSideCornerRectangle>
    <SnipSameSideCornerRectangle>Прямоугольник со срезанными по диагонали углами</SnipSameSideCornerRectangle>
    <TopAndBottomLine>Линии сверху и снизу</TopAndBottomLine>
    <Trapezoid>Трапеция</Trapezoid>
    <Triangle>Треугольник</Triangle>
    <VerticalLine>Вертикальная линия</VerticalLine>
  </Shapes>
  <SystemVariables>
    <Column>Номер текущей колонки (Начинается с 1).</Column>
    <GroupLine>Номер текущей группы (Начинается с 1).</GroupLine>
    <IsFirstPage>Возвращает значение Истина, если в данный момент печатается первая страница отчета.</IsFirstPage>
    <IsFirstPageThrough>Возвращает значение Истина, если в данный момент печатается первая страница отчета. При расчете переменной все параметры сброса номера страницы игнорируются и расчет ведется с начала отчета.</IsFirstPageThrough>
    <IsLastPage>Возвращает значение Истина, если в данный момент печатается последняя страница отчета. Для корректного расчета данной переменной отчет должен быть двухпроходным.</IsLastPage>
    <IsLastPageThrough>Возвращает значение Истина, если в данный момент печатается последняя страница отчета. Для корректного расчета данной переменной отчет должен быть двухпроходным. При расчете переменной все параметры сброса номера страницы игнорируются и расчет ведется с начала отчета.</IsLastPageThrough>
    <Line>Номер текущей линии (Начинается с 1).</Line>
    <LineABC>Буквенный аналог номера текущей линии.</LineABC>
    <LineRoman>Номер текущей линии представленной римскими цифрами.</LineRoman>
    <LineThrough>Номер текущей линии (Начинается с 1). При расчете игнорируются все группы в отчете и исчисление ведется с начала отчета.</LineThrough>
    <PageCopyNumber>Номер копии страницы (Начиная с 1).</PageCopyNumber>
    <PageNofM>Локализованная строка, которая отображает строку "Страница N из M", где N это номер текущей страницы, а M - это общее количество страниц в отчете.</PageNofM>
    <PageNofMThrough>Локализованная строка, которая отображает строку "Страница N из M", где N это номер текущей страницы, а M - это общее количество страниц в отчете. При расчете переменной все параметры сброса номера страницы игнорируются и расчет ведется с начала отчета.</PageNofMThrough>
    <PageNumber>Текущий номер страницы (Начинается с 1).</PageNumber>
    <PageNumberThrough>Текущий номер страницы (Начинается с 1). При расчете переменной все параметры сброса номера страницы игнорируются и расчет ведется с начала отчета.</PageNumberThrough>
    <ReportAlias>Псевдоним отчета.</ReportAlias>
    <ReportAuthor>Автор отчета.</ReportAuthor>
    <ReportChanged>Дата, когда отчет был изменен в последний раз.</ReportChanged>
    <ReportCreated>Дата, когда отчет был создан.</ReportCreated>
    <ReportDescription>Описание отчета.</ReportDescription>
    <ReportName>Наименование отчета.</ReportName>
    <Time>Текущее время.</Time>
    <Today>Текущая дата.</Today>
    <TotalPageCount>Количество страниц в отчете.</TotalPageCount>
    <TotalPageCountThrough>Общее количество страниц в отчете. При расчете переменной все параметры сброса номера страницы игнорируются и расчет ведется с начала отчета.</TotalPageCountThrough>
  </SystemVariables>
  <TableRibbon>
    <BuiltIn>Встроенные</BuiltIn>
    <Delete>Удалить</Delete>
    <DeleteColumns>Удалить колонки</DeleteColumns>
    <DeleteRows>Удалить строки</DeleteRows>
    <DeleteTable>Удалить таблицу</DeleteTable>
    <DistributeColumns>Распределить колонки</DistributeColumns>
    <DistributeRows>Распределить строки</DistributeRows>
    <InsertAbove>Вставить выше</InsertAbove>
    <InsertBelow>Вставить ниже</InsertBelow>
    <InsertLeft>Вставить слева</InsertLeft>
    <InsertRight>Вставить справа</InsertRight>
    <PlainTables>Простые таблицы</PlainTables>
    <ribbonBarRowsColumns>Колонки и строки</ribbonBarRowsColumns>
    <ribbonBarTable>Таблица</ribbonBarTable>
    <ribbonBarTableStyles>Стили таблицы</ribbonBarTableStyles>
    <Select>Выбрать</Select>
    <SelectColumn>Выбрать колонку</SelectColumn>
    <SelectRow>Выбрать строку</SelectRow>
    <SelectTable>Выбрать таблицу</SelectTable>
  </TableRibbon>
  <Toolbars>
    <Align>Выравнивание</Align>
    <AlignBottom>Выровнять по нижнему краю</AlignBottom>
    <AlignCenter>Выровнять по горизонтали по центру </AlignCenter>
    <AlignLeft>Выровнять по левому краю</AlignLeft>
    <AlignMiddle>Выровнять по вертикали по центру</AlignMiddle>
    <AlignRight>Выровнять по правому краю</AlignRight>
    <AlignToGrid>Выровнять по узлам сетки</AlignToGrid>
    <AlignTop>Выровнять по верхнему краю</AlignTop>
    <AlignWidth>Выровнять по ширине</AlignWidth>
    <BringToFront>Поместить на передний план</BringToFront>
    <CenterHorizontally>Центрировать по горизонтали</CenterHorizontally>
    <CenterVertically>Центрировать по вертикали</CenterVertically>
    <Conditions>Условия</Conditions>
    <FontGrow>Увеличить размер шрифта</FontGrow>
    <FontName>Наименование шрифта</FontName>
    <FontShrink>Уменьшить размер шрифта</FontShrink>
    <FontSize>Размер шрифта</FontSize>
    <FontStyleBold>Жирный шрифт</FontStyleBold>
    <FontStyleItalic>Наклонный шрифт</FontStyleItalic>
    <FontStyleUnderline>Подчеркнутый шрифт</FontStyleUnderline>
    <Link>Связь</Link>
    <Lock>Замок</Lock>
    <MakeHorizontalSpacingEqual>Сделать по горизонтали эквивалентно расстоянию</MakeHorizontalSpacingEqual>
    <MakeSameHeight>Сделать ту же высоту, что и у {0}</MakeSameHeight>
    <MakeSameSize>Сделать тот же размер, что и у {0}</MakeSameSize>
    <MakeSameWidth>Сделать ту же ширину, что и у {0}</MakeSameWidth>
    <MakeVerticalSpacingEqual>Сделать по вертикали эквивалентно расстоянию</MakeVerticalSpacingEqual>
    <MoveBackward>Переместить назад</MoveBackward>
    <MoveForward>Переместить вперед</MoveForward>
    <Order>Порядок</Order>
    <SendToBack>Поместить на задний план</SendToBack>
    <Size>Размер</Size>
    <StyleDesigner>Редактор стилей</StyleDesigner>
    <Styles>Список стилей</Styles>
    <TabHome>Главная</TabHome>
    <TabLayout>Формат</TabLayout>
    <TabPage>Страница</TabPage>
    <TabView>Вид</TabView>
    <TextBrush>Кисть текста</TextBrush>
    <ToolbarAlignment>Выравнивание</ToolbarAlignment>
    <ToolbarArrange>Размещение</ToolbarArrange>
    <ToolbarBorders>Границы</ToolbarBorders>
    <ToolbarClipboard>Буфер обмена</ToolbarClipboard>
    <ToolbarDockStyle>Стиль докировки</ToolbarDockStyle>
    <ToolbarFont>Шрифт</ToolbarFont>
    <ToolbarFormatting>Форматирование</ToolbarFormatting>
    <ToolbarLayout>Дизайн</ToolbarLayout>
    <ToolbarPageSetup>Настройка страницы</ToolbarPageSetup>
    <ToolbarStandard>Стандарт</ToolbarStandard>
    <ToolbarStyle>Стили</ToolbarStyle>
    <ToolbarTextFormat>Формат текста</ToolbarTextFormat>
    <ToolbarTools>Сервис</ToolbarTools>
    <ToolbarViewOptions>Опции просмотра</ToolbarViewOptions>
    <ToolbarWatermarkImage>Изображение водяного знака</ToolbarWatermarkImage>
    <ToolbarWatermarkText>Текст водяного знака</ToolbarWatermarkText>
  </Toolbars>
  <Toolbox>
    <Create>Создание компонентов</Create>
    <Hand>Рука</Hand>
    <Select>Выбор</Select>
    <Style>Копирование стиля</Style>
    <TextEditor>Редактор текста</TextEditor>
    <title>Инструментарий</title>
  </Toolbox>
  <Wizards>
    <BlankDashboard>Пустая панель индикаторов</BlankDashboard>
    <BlankReport>Пустой отчет</BlankReport>
    <ButtonBack>&lt; &amp;Назад</ButtonBack>
    <ButtonCancel>Отмена</ButtonCancel>
    <ButtonFinish>&amp;Готово</ButtonFinish>
    <ButtonNext>&amp;Далее &gt;</ButtonNext>
    <ColumnsOrder>Порядок колонок</ColumnsOrder>
    <Custom>По выбору</Custom>
    <DataRelation>Связь</DataRelation>
    <DataSource>Источник данных</DataSource>
    <DataSources>Источники Данных</DataSources>
    <DefaultThemes>Встроенные темы</DefaultThemes>
    <Filters>Фильтры</Filters>
    <FromReportTemplate>Отчет из шаблона</FromReportTemplate>
    <groupCreateNewDashboard>Создать новую панель индикаторов</groupCreateNewDashboard>
    <groupCreateNewPageOrForm>Создать страницу или форму</groupCreateNewPageOrForm>
    <groupCreateNewReport>Создать новый отчет</groupCreateNewReport>
    <Groups>Группировки</Groups>
    <groupTemplates>Шаблоны</groupTemplates>
    <groupWizards>Мастера построения отчета</groupWizards>
    <infoColumnsOrder>Расположите колонки в необходимом порядке.</infoColumnsOrder>
    <infoDataSource>Выберите один источник данных.</infoDataSource>
    <infoDataSources>Выберите Источники Данных из доступных.</infoDataSources>
    <infoFilters>Фильтрация данных</infoFilters>
    <infoGroups>Выберите колонки, по которым необходимо сгруппировать данные.</infoGroups>
    <infoLabelSettings>Выберите параметры этикеток.</infoLabelSettings>
    <infoLayout>Определите формат отчета.</infoLayout>
    <infoRelation>Выберите одну Связь из доступных.</infoRelation>
    <infoSelectColumns>Выберите колонки для размещения в отчете.</infoSelectColumns>
    <infoSort>Настройте сортировку данных. Допускается сортировка сразу по нескольким полям.</infoSort>
    <infoThemes>Выберите тему оформления для Вашего отчета.</infoThemes>
    <infoTotals>Добавьте итоговую информацию в отчет.</infoTotals>
    <LabelDirection>Направление:</LabelDirection>
    <LabelHeight>Высота:</LabelHeight>
    <LabelHorizontalGap>Гор. промежуток:</LabelHorizontalGap>
    <LabelLabelType>Тип этикетки:</LabelLabelType>
    <LabelLeftMargin>Левое поле:</LabelLeftMargin>
    <LabelNumberOfColumns>Количество колонок:</LabelNumberOfColumns>
    <LabelNumberOfRows>Количество строк:</LabelNumberOfRows>
    <LabelPageHeight>Высота страницы:</LabelPageHeight>
    <LabelPageWidth>Ширина страницы:</LabelPageWidth>
    <LabelReport>Отчет с этикетками</LabelReport>
    <LabelSettings>Настройка этикеток</LabelSettings>
    <LabelSize>Размер:</LabelSize>
    <LabelTopMargin>Верхнее поле:</LabelTopMargin>
    <LabelVerticalGap>Верт. промежуток:</LabelVerticalGap>
    <LabelWidth>Ширина:</LabelWidth>
    <Layout>Формат</Layout>
    <MarkAll>Отметить &amp;Все</MarkAll>
    <MasterDetailReport>Master-Detail отчет</MasterDetailReport>
    <NoFunction>[Нет]</NoFunction>
    <OpenExistingReport>Открыть файл</OpenExistingReport>
    <Preview>Предварительный просмотр</Preview>
    <Reset>&amp;Сбросить</Reset>
    <Results>Результаты</Results>
    <SelectColumns>Выбор колонок</SelectColumns>
    <Sort>Сортировка</Sort>
    <StandardReport>Стандартный отчет</StandardReport>
    <Themes>Темы</Themes>
    <title>Новый отчет</title>
    <Totals>Итоги</Totals>
    <UsingReportWizard>Мастер отчета</UsingReportWizard>
  </Wizards>
  <Zoom>
    <EmptyValue>Пустое значение</EmptyValue>
    <MultiplePages>Несколько страниц</MultiplePages>
    <OnePage>Одна страница</OnePage>
    <PageHeight>По высоте страницы</PageHeight>
    <PageWidth>По ширине страницы</PageWidth>
    <TwoPages>Две страницы</TwoPages>
    <ZoomTo100>Масштаб в 100%</ZoomTo100>
  </Zoom>
</Localization>`
