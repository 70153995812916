import { makeObservable, observable } from 'mobx'
import BaseStore from './BaseStore'

export class EditorStore extends BaseStore {
  @observable editors = observable.map()

  constructor() {
    super()
    makeObservable(this)
  }
}

export default new EditorStore()
