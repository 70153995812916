import isUndefined from 'lodash/isUndefined'
import { isObservableArray } from 'mobx'
import { inject, observer, PropTypes as MobxTypes } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Select from 'react-select'
import { getModelValue, setModelValue } from '../../common/utils'

@inject('store')
@observer
export class FormSelect extends Component {
  static propTypes = {
    model: PropTypes.object,
    name: PropTypes.string,
    options: MobxTypes.arrayOrObservableArray.isRequired,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
    onChange: PropTypes.func,
    canClear: PropTypes.bool,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    labelKey: 'text',
    valueKey: 'value',
    disabled: false,
    readOnly: false,
    canClear: false,
    noResultsText: '',
  }

  onChange = item => {
    let { model, name, onChange, valueKey } = this.props
    setModelValue(model, name, item ? item[valueKey] : null)
    onChange && onChange(item)
  }

  getOptionValue = option => option[this.props.valueKey]

  getOptionLabel = option => option[this.props.labelKey]

  noOptionsMessage = () => this.props.noResultsText || 'No options'

  render() {
    const { model, name, options, canClear, readOnly, disabled, selected } =
      this.props
    const item = getModelValue(
      model,
      name,
      isUndefined(selected) ? '' : selected,
    )
    const select_options = isObservableArray(options)
      ? [...options]
      : Array.isArray(options)
      ? options
      : []
    const value = select_options.find(
      option => this.getOptionValue(option) === item,
    )
    return (
      <Select
        getOptionValue={this.getOptionValue}
        getOptionLabel={this.getOptionLabel}
        options={select_options}
        isClearable={canClear}
        isDisabled={disabled || readOnly || this.props.store.AppStore.isBusy}
        value={value || null}
        onChange={this.onChange}
        className='form-select'
        classNamePrefix='react-select'
        noOptionsMessage={this.noOptionsMessage}
      />
    )
  }
}
