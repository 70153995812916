import { action, computed, makeObservable, observable, observe } from 'mobx'
import moment from 'moment'
import localDB from '../common/localDB'
import requester from '../common/requester'
import editors from './editors'
import { SelectionStore } from './SelectionStore'

export class ProductStore extends SelectionStore {
  @observable prices = observable.array()
  @observable decider
  @observable show_scanner = false
  @observable totals_by_currencies = observable.array()
  @observable totals_by_units = observable.array()
  pricesDisposer
  nomenclatureDisposer
  editorObserver

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get editor() {
    const { nomenclature: n } = this.item
    let editor = !n || n.editor || !n.category || n.category.editor
    if (typeof editor === 'string') return editor.trim() || null
    return null
  }

  @computed
  get total_price() {
    const { price, quantity } = this.item
    if (price && quantity) {
      return parseFloat(
        (parseFloat(price).toFixed(4) * quantity).toFixed(4),
      ).toString()
    }
    return ''
  }

  @computed
  get canContract() {
    const items = this.selections
    if (items.length > 0) {
      const date = moment(items[0].rec_date)
      const contractor_id = items[0].contractor_id || -1
      const branch_id = items[0].branch_id || -1
      let wastage
      if (items[0].data.wastage) {
        wastage = parseFloat(items[0].data.wastage || 0)
      } else {
        wastage = parseFloat(items[0].data.slaughter?.products[0]?.wastage || 0)
      }
      for (const item of items) {
        const valid =
          moment(item.rec_date).isSame(date, 'date') &&
          item.price &&
          item.contractor_id === contractor_id &&
          item.branch_id === branch_id &&
          ((item.data.wastage &&
            parseFloat(item.data.wastage || 0) === wastage) ||
            (!item.data.wastage &&
              parseFloat(item.data.slaughter?.products[0]?.wastage || 0) ===
                wastage))
        if (!valid) {
          return false
        }
        const {
          nomenclature: { category, editor },
        } = item
        if (editor !== 'Animal' || (!editor && category.editor !== 'Animal')) {
          return false
        }
      }
      return true
    }
    return false
  }

  async contractData() {
    const items = this.selections
    if (items.length > 0) {
      const date = moment(items[0].rec_date)
      const contractor_id = items[0].contractor_id || -1
      const contractor = items[0].contractor
      const branch_id = items[0].branch_id || -1
      const branch = items[0].branch
      let wastage
      if (items[0].data.wastage) {
        wastage = parseFloat(items[0].data.wastage || 0)
      } else {
        wastage = parseFloat(items[0].data.slaughter?.products[0]?.wastage || 0)
      }

      let amount = 0
      let nomenclatures = {}
      for (const item of items) {
        amount += parseFloat(item.price)
        const {
          animal_weight,
          slaughter = {},
          price_type,
          price_type_slaughter = {},
        } = item.data
        const as_slaughter = !price_type && !animal_weight
        const { products = [], prices = {} } = slaughter
        if (!as_slaughter) {
          const price = item.price / animal_weight
          const key = `${item.nomenclature_id}_${price}`
          const name = `${item.nomenclature.name} (вживую)`
          if (!nomenclatures[key]) {
            nomenclatures[key] = { name, price, quantity: 0, weight: 0 }
          }
          nomenclatures[key].weight += parseFloat(animal_weight)
          nomenclatures[key].quantity += parseFloat(item.quantity)
        } else {
          for (const p of products.filter(s => s.price_decider)) {
            const { price = 0 } =
              prices[p.nomenclature_id]?.find(
                pr => pr.name === price_type_slaughter[p.nomenclature_id],
              ) || {}
            const { item: n } = await localDB.get(
              '/nomenclatures/' + p.nomenclature_id,
              {},
              null,
              true,
            )
            const key = `${item.nomenclature_id}-${p.nomenclature_id}_${price}`
            const name = `${item.nomenclature.name} (${n.name_short || n.name})`
            if (!nomenclatures[key]) {
              nomenclatures[key] = { name, price, quantity: 0, weight: 0 }
            }
            nomenclatures[key].weight +=
              parseFloat(p.quantity) - parseFloat(p.tara || '0')
            nomenclatures[key].quantity += parseFloat(item.quantity)
          }
        }
      }
      return {
        contract:
          branch_id + '.' + contractor_id + '.' + date.format('DD-MM-YYYY'),
        contractor: contractor.name,
        inn: contractor.inn,
        date: date.format('«DD» MMMM YYYY года'),
        amount:
          amount - Math.floor(amount) !== 0
            ? parseFloat(amount.toFixed(4))
            : amount,
        branch: branch.name,
        wastage: wastage,
        Data: [
          Object.keys(nomenclatures)
            .map(k => {
              nomenclatures[k].quantity = parseInt(nomenclatures[k].quantity)
              nomenclatures[k].weight = parseFloat(
                nomenclatures[k].weight.toFixed(3),
              )
              return nomenclatures[k]
            })
            .sort((a, b) => a.name.localeCompare(b.name)),
        ],
      }
    }
    return {}
  }

  fetchPrices = async ({ newValue, oldValue }) => {
    if (newValue && newValue !== oldValue) {
      let { data } = await requester.get('/nomenclature/prices', {
        nomenclature_id: newValue,
      })
      this.setPrices(data)
    }
  }

  setResult(data, defaultData) {
    const ret = super.setResult(data, defaultData)
    if (data.list) {
      this.totals_by_units = data?.totals_by_units || []
      this.totals_by_currencies = data?.totals_by_currencies || []
    }
    return ret
  }

  @action
  setPrices = ({ prices, decider }) => {
    this.prices.replace(prices)
    this.decider = decider
  }

  @action
  validateQuantity = () => {
    let { nomenclature } = this.item
    if (nomenclature && nomenclature.identify_each) {
      this.item.quantity = 1
    }
  }

  @action.bound
  setPrice() {
    if (!this.item) return
    if (!this.item.data) return
    if (this.prices.length > 0 && this.item.data.price_type) {
      let price = this.prices.find(p => p.name === this.item.data.price_type)
      if (price) {
        let multiplier = 1
        if (this.decider) {
          multiplier = parseFloat(this.item.data[this.decider] || '0')
        }
        this.item.price = parseFloat(
          (parseFloat(price.price) * multiplier).toFixed(4),
        )
      }
    }
    if (isNaN(this.item.price)) {
      this.item.price = null
    }
  }

  setSingle(item) {
    this.setSingleAsync(item)
  }

  bindItemObservers = (skip_clear = true) => {
    !skip_clear && this.clearObservers(true)
    // noinspection JSUnresolvedFunction
    this.pricesDisposer = observe(
      this.item,
      'nomenclature_id',
      this.fetchPrices,
      true,
    )
    // noinspection JSUnresolvedFunction
    this.nomenclatureDisposer = observe(
      this.item,
      'nomenclature',
      this.validateQuantity,
      true,
    )
  }

  async setSingleAsync(item) {
    if (item.nomenclature_id && !item.nomenclature) {
      const { item: nomenclature } = await localDB.get(
        '/nomenclatures/' + item.nomenclature_id,
      )
      item.nomenclature = nomenclature
    }
    const nomenclature = item.nomenclature
    if (nomenclature && nomenclature.category_id && !nomenclature.category) {
      const { item: category } = await localDB.get(
        '/categorys/' + nomenclature.category_id,
      )
      nomenclature.category = category
    }
    super.setSingle(item)
    this.clearObservers()
    this.bindItemObservers()
    // noinspection JSUnresolvedFunction
    this.editorObserver = observe(
      this,
      'editor',
      this.updateItem.bind(this),
      true,
    )
  }

  updateItem({ newValue, oldValue }) {
    if (
      !newValue ||
      newValue === oldValue ||
      !editors[newValue] ||
      !editors[newValue].validateItem
    )
      return
    const item = editors[newValue].validateItem({
      ...this.item,
      data: { ...(this.item.data || {}) },
    })
    super.setSingle(item)
    this.bindItemObservers(false)
  }

  clearObservers(skip_editor = false) {
    if (this.pricesDisposer) {
      this.pricesDisposer()
      this.pricesDisposer = null
    }
    if (this.nomenclatureDisposer) {
      this.nomenclatureDisposer()
      this.nomenclatureDisposer = null
    }
    if (!skip_editor && this.editorObserver) {
      this.editorObserver()
      this.editorObserver = null
    }
  }

  clearItem() {
    this.clearObservers()
    super.clearItem()
    this.prices?.clear()
    this.decider = null
    this.show_scanner = false
  }

  get should_save_filter_keys() {
    return [
      ...super.should_save_filter_keys,
      'category_id',
      'nomenclature_id',
      'identity_search',
      'contractor_search',
    ]
  }

  async postData(pathname, item = this.item) {
    const resp = await super.postData(pathname, item)
    if (this.item.id && this.item.id === parseInt(pathname.split('/')[2])) {
      await this.fetchItem(pathname)
    }
    return resp
  }

  @action.bound toggleScanner() {
    this.show_scanner = !this.show_scanner
  }

  @action.bound showScanner() {
    this.show_scanner = true
  }

  @action.bound hideScanner() {
    this.show_scanner = false
  }

  @action.bound onScanProductIdentity({ data }) {
    if (data) this.item.identity = data
    this.hideScanner()
  }
}

const store = new ProductStore()
export default store
