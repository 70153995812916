import { reaction } from 'mobx'
import { inject } from 'mobx-react'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import App from './App'
import client from './components/bugsnag-client'

@withRouter
@inject('store')
export default class Root extends Component {
  constructor(props) {
    super(props)
    this.setScreenSize()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  setScreenSize() {
    const { store } = this.props
    const { innerWidth: width, innerHeight: height } = window
    store.ScreenStore.setScreenDimensions({ width, height })
  }

  onUserChange = () => {
    const user = this.props.store.AppStore.user_info
    if (user) {
      client.setUser(user.id.toString(), null, user.username)
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.setScreenSize.bind(this))
    this.userDisposer = reaction(
      () => this.props.store.AppStore.user_info?.id || 0,
      this.onUserChange,
      { fireImmediately: true },
    )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreenSize.bind(this))
    this.userDisposer()
  }

  render() {
    return <App />
  }
}
