import classNames from 'classnames'
import isString from 'lodash/isString'
import PropTypes from 'prop-types'
import React from 'react'
import QuantityBase from '../../common/components/QuantityBase'

export default class Quantity extends QuantityBase {
  static propTypes = {
    unit: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.any,
  }

  get className() {
    const { className } = this.props
    return this.num_value < 0
      ? className
        ? classNames(['text-danger', className])
        : 'text-danger'
      : className
  }

  render() {
    const value = super.render()
    return isString(value) ? (
      <span className={this.className}>{value}</span>
    ) : null
  }
}
