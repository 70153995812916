import diff from 'deep-diff/index.es'
import moment from 'moment'

export function applyDiff(left, right) {
  diff.observableDiff(left, right, d => {
    if (d.path.join('.') !== 'version') {
      diff.applyChange(left, right, d)
    } else {
      diff.applyChange(left, right, d)
      if (right.is_dirty && d.lhs >= d.rhs) left.version = d.lhs + 1
    }
  })
}

export function applyChanges(table, item, realm) {
  let data = realm
    .objects('TableData')
    .filtered('table=$0 AND id=$1', table, item.id ? `${item.id}` : item.id)[0]
  if (!data || !data.is_dirty) return
  data = data.jsonData
  if (data.version > item.version) applyDiff(item, data)
  else applyDiff(data, item)
}

export function saveTableData(
  table,
  item,
  realm,
  only_create = false,
  dirty = false,
) {
  item.id && applyChanges(table, item, realm)
  let data = realm
    .objects('TableData')
    .filtered('table=$0 AND id=$1', table, item.id ? `${item.id}` : item.id)
  if (item.id) {
    data = data.filtered('id=$0', `${item.id}`)
  } else {
    data = data.filtered('local_id=$0', `${item.remote_id}`)
  }
  data = data[0]
  if (!data) {
    realm.create('TableData', {
      table,
      id: item.id ? `${item.id}` : item.id,
      local_id: item.remote_id ? `${item.remote_id}` : item.remote_id,
      rec_date: moment(item.rec_date || new Date()).toDate(),
      rec_user: item.rec_user,
      version: item.version || 0,
      is_dirty: dirty,
      data: JSON.stringify(item),
    })
  } else if (!only_create) {
    data.id = typeof item.id === 'string' ? item.id : `${item.id}`
    data.version = item.version || 0
    data.data = JSON.stringify(item)
  }
  console.log('saveTableData', table, item && item.id)
}
