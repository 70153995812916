import { isFinite, isNumber, sortBy, sum } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import { MONEY_PAY24 } from '../common/constants'
import requester from '../common/requester'
import { v2nC, v2nQ } from '../common/utils'
import { SelectionStore } from './SelectionStore'

function toFloat(value) {
  value = !isNumber(value) ? parseFloat(value) : value
  return isFinite(value) ? value : 0
}

function roundedSum(values, rounding) {
  return rounded(sum(values), rounding)
}

function rounded(amount, rounding) {
  const amount_rounding = parseFloat(rounding || '1')
  amount = Math.round(amount / amount_rounding) * amount_rounding
  return parseFloat(amount.toFixed(4))
}

function countTotalAmount(total_quantity, items, sort_reverse = false) {
  if (total_quantity <= 0 || !items) return [total_quantity, 0]
  let total_amount = 0
  for (const { quantity, price } of sortBy(items, 'price', sort_reverse)) {
    let q = quantity > total_quantity ? total_quantity : quantity
    total_amount += q * price
    total_quantity -= q
    if (total_quantity <= 0) {
      total_quantity = 0
      break
    }
  }
  return [total_quantity, total_amount]
}

function countMarketsTotalAmount(total_quantity, { markets }) {
  return countTotalAmount(total_quantity, markets, true)
}

function countReturnDefectiveTotalAmount(total_quantity, { returns }) {
  return countTotalAmount(total_quantity, returns)
}

function countProductTotalAmount(p, is_pre = false) {
  const QUANTITY_KEY = is_pre ? 'quantity' : 'quantity_edit'
  const RETURN_KEY = is_pre ? 'return' : 'return_edit'
  const DEFECTIVE_KEY = is_pre ? 'defective' : 'defective_edit'

  let quantity = toFloat(p.count) - toFloat(p[QUANTITY_KEY])
  const [quantity4, amount2] = countMarketsTotalAmount(quantity, p)

  let quantity2 = toFloat(p[RETURN_KEY]) + toFloat(p[DEFECTIVE_KEY])
  const [quantity3, amount] = countReturnDefectiveTotalAmount(quantity2, p)

  return (quantity4 - quantity3) * toFloat(p.min_price) + amount2 - amount
}

function countProductAmount(p) {
  return countProductTotalAmount(p)
}

function countProductPreAmount(p) {
  return countProductTotalAmount(p, true)
}

async function getSales(user_id) {
  const data = await requester.get('/agentreport/sales/' + user_id)
  return data.data
}

async function getAgentSales() {
  const data = await requester.get('/agentreport')
  return data.data.item
}

function quantitiesReduce(products_map, product) {
  products_map[product.id] = {
    quantity_edit: product.quantity_edit,
    return_edit: product.return_edit,
    defective_edit: product.defective_edit,
  }
  return products_map
}

function sortProductsByName(a, b) {
  if (a.name && !b.name) return -1
  if (!a.name && b.name) return 1
  return a.name.localeCompare(b.name)
}

function updateProps(obj, newObj, ...props) {
  props.forEach(prop => {
    const is_changed =
      typeof newObj[prop] !== 'undefined' && newObj[prop] !== null
        ? newObj[prop] !== obj[prop]
        : typeof obj[prop] === 'undefined' ||
          obj[prop] === null ||
          obj[prop] === ''
    if (is_changed) {
      obj[prop] = newObj[prop] || null
    }
  })
}

export class AgentReportStore extends SelectionStore {
  @observable products = observable.array()
  @observable input_column = 'quantity'
  @observable totals_by_currencies = observable.array()
  @observable preview = false
  @observable warnAboutAccount = false
  @observable warnAboutRemainder = false
  @observable hide_not_changed = false
  @observable errors = observable.array()

  constructor() {
    super()
    makeObservable(this)
    this.mapProductsPrint = this.mapProductsPrint.bind(this)
    this.sortProducts = this.sortProducts.bind(this)
  }

  filterDefaults() {
    return {
      ...super.filterDefaults(),
      start_from: moment()
        .startOf('isoWeek')
        .startOf('month')
        .format('YYYY-MM-DD'),
    }
  }

  @computed get filtered_products() {
    return this.hide_not_changed
      ? this.products.filter(
          p =>
            p.count !== p.quantity ||
            p.quantity !== p.quantity_edit ||
            p.return ||
            p.defective,
        )
      : this.products
  }

  @computed get can_fill_not_changed() {
    return (
      this.products.length > 0 &&
      this.products.some(
        p => p.count === p.quantity && !p.return && !p.defective,
      )
    )
  }

  @computed get disable_fill_not_changed() {
    return this.products.every(p =>
      p.count === p.quantity
        ? !!(p.quantity_edit || p.return || p.defective)
        : true,
    )
  }

  @computed get can_fill_all() {
    return (
      this.products.length > 0 &&
      this.products.some(
        p => !p.quantity_edit && !p.return_edit && !p.defective_edit,
      )
    )
  }

  @computed get consumptions_names() {
    const names = Array.from(
      new Set(
        this.items
          .map(item => item.data.consumptions?.map(c => c.name) || [])
          .flat(),
      ),
    )
    return names.length === 0 ? ['Расходы'] : names
  }

  @computed get accounts_names() {
    const names = Array.from(
      new Set(
        this.items
          .map(item => item.data.accounts?.map(c => c.name) || [])
          .flat(),
      ),
    )
    return names.length === 0 ? ['Касса'] : names
  }

  @computed get table_consumptions_data() {
    return this.items.map(item => {
      if (!item.data.consumptions) {
        return [
          { name: this.consumptions_names[0], amount: item.consumption },
          ...this.consumptions_names
            .slice(1)
            .map(name => ({ name, amount: null })),
        ]
      }
      return this.consumptions_names.map(
        name =>
          item.data.consumptions.find(c => c.name === name) || {
            name,
            amount: null,
          },
      )
    })
  }

  @computed get table_accounts_data() {
    return this.items.map(item => {
      if (!item.data.accounts) {
        return [
          { name: this.consumptions_names[0], amount: item.account },
          ...this.accounts_names.slice(1).map(name => ({ name, amount: null })),
        ]
      }
      return this.accounts_names.map(
        name =>
          item.data.accounts.find(c => c.name === name) || {
            name,
            amount: null,
          },
      )
    })
  }

  @computed get can_toggle_not_changed() {
    return this.products.some(
      p =>
        p.count === p.quantity &&
        p.quantity === p.quantity_edit &&
        !p.return &&
        !p.defective,
    )
  }

  @computed
  get total() {
    return roundedSum(
      this.products.map(countProductAmount),
      this.item.data?.sales_total_amount_rounding,
    )
  }

  @computed
  get total_pre() {
    return roundedSum(
      this.products.map(countProductPreAmount),
      this.item.data?.sales_total_amount_rounding,
    )
  }

  @computed
  get consumption() {
    const { consumptions = [] } = this.item.data || {}
    return consumptions.length > 0
      ? roundedSum(
          consumptions.map(c => toFloat(c.amount)),
          this.item.data?.sales_total_amount_rounding,
        )
      : this.item.consumption
  }

  @computed
  get amount() {
    const { debt, cash, data = {} } = this.item

    const pay_amount =
      this.total -
      toFloat(debt) +
      toFloat(cash) -
      toFloat(data.total_discount_amount || 0) +
      toFloat(data.total_tax_amount || 0) -
      toFloat(this.consumption)
    return rounded(pay_amount, this.item.data?.sales_total_amount_rounding)
  }

  @computed
  get visible_amount() {
    const pay24_cash =
      this.item.data?.accounts?.find(a => a.id === MONEY_PAY24)?.amount || 0
    return rounded(
      this.amount - toFloat(pay24_cash),
      this.item.data?.sales_total_amount_rounding,
    )
  }

  @computed
  get amount_pre() {
    const { debt, cash, data = {} } = this.item

    const pay_amount =
      this.total_pre -
      toFloat(debt) +
      toFloat(cash) -
      toFloat(data.total_discount_amount || 0) +
      toFloat(data.total_tax_amount || 0) -
      toFloat(this.consumption)
    return rounded(pay_amount, this.item.data?.sales_total_amount_rounding)
  }

  @computed
  get visible_amount_pre() {
    const pay24_cash =
      this.item.data?.accounts?.find(a => a.id === MONEY_PAY24)?.amount || 0
    return rounded(
      this.amount_pre - toFloat(pay24_cash),
      this.item.data?.sales_total_amount_rounding,
    )
  }

  @computed
  get canPrint() {
    return this.item.data && this.item.user && this.products.length > 0
  }

  get checkQuantity() {
    const { amount } = this.item
    let account = 0
    for (const a of this.item?.data?.accounts) {
      account += parseFloat(a.amount || '0')
    }
    const diff = amount && account ? Math.abs(amount - account) : 0
    if (amount && diff > 100 && diff > amount * 9) return false
    if (this.item.report_status !== 'done' || this.warnAboutAccount) {
      return true
    }
    return amount && diff < amount * 0.01
  }

  get checkQuantityRemainder() {
    let allChecksPassed = true

    for (const p of this.products) {
      if (p.quantity || p.quantity_edit) {
        const twenty_percentage = p.quantity * 0.2
        if (
          !(
            p.quantity + twenty_percentage >= p.quantity_edit &&
            p.quantity - twenty_percentage <= p.quantity_edit
          )
        ) {
          allChecksPassed = false
          this.errors.push({
            name: p.name,
            name_error: 'Остаток',
            num1: p.quantity,
            num2: p.quantity_edit,
          })
        }
      }
      if (p.return || p.return_edit) {
        const twenty_percentage = p.return * 0.2
        if (
          !(
            p.return + twenty_percentage >= p.return_edit &&
            p.return - twenty_percentage <= p.return_edit
          )
        ) {
          allChecksPassed = false
          this.errors.push({
            name: p.name,
            name_error: 'Возврат',
            num1: p.return,
            num2: p.return_edit,
          })
        }
      }
      if (p.defective || p.defective_edit) {
        const twenty_percentage = p.defective * 0.2
        if (
          !(
            p.defective + twenty_percentage >= p.defective_edit &&
            p.defective - twenty_percentage <= p.defective_edit
          )
        ) {
          allChecksPassed = false
          this.errors.push({
            name: p.name,
            name_error: 'Брак',
            num1: p.defective,
            num2: p.defective_edit,
          })
        }
      }
    }

    return allChecksPassed
  }

  get should_save_filter_keys() {
    return [...super.should_save_filter_keys, 'user_id']
  }

  @action toggleNotChanged = () =>
    (this.hide_not_changed = !this.hide_not_changed)

  @action
  switchInput = () => {
    this.input_column = {
      quantity: 'return',
      return: 'defective',
      defective: 'quantity',
    }[this.input_column]
    this.products.replace(this.products.sort(this.sortProducts))
  }

  sort = (a, b, order) => {
    const inputs = ['quantity', 'return', 'defective', 'count']

    for (let i = 0; i < inputs.length; i++) {
      let col = inputs[i],
        col_edit = `${col}_edit`
      order = (b[col_edit] || 0) - (a[col_edit] || 0)
      if (order === 0) {
        order = b[col] - a[col]
        if (order !== 0) break
      } else {
        break
      }
    }
    return order
  }

  sortProducts(a, b) {
    let order = 0
    switch (this.input_column) {
      case 'quantity':
        order = this.sort(a, b, order)
        break
      case 'return':
        order = b.return - a.return
        if (order === 0) {
          order = this.sort(a, b, order)
        }
        break
      case 'defective':
        order = b.defective - a.defective
        if (order === 0) {
          order = this.sort(a, b, order)
        }
        break
    }

    return order === 0 ? a.name.localeCompare(b.name) : order
  }

  setSingle(item) {
    if (item.report_status === 'done') {
      this.setAccess(
        this.access.filter(item => !['edit', 'create'].includes(item)),
      )
      this.setStructure(
        this.structure.map(s => ({ ...s, read_only: true })),
        item,
      )
    }
    super.setSingle(item)
    this.setSales(this.item)
  }

  loadSales() {
    this.item.user_id && getSales(this.item.user_id).then(this.setSales)
  }

  loadAgentSales() {
    getAgentSales().then(this.setSales)
  }

  @action updateReturnBranch = () => {
    if (!this.item.data || this.item.report_status === 'done') return
    const return_branch_id = this.item.branch?.data.return_branch_id || null
    this.item.data.branch2_id = return_branch_id || this.item.data.branch2_id
  }

  @action fillNotChanged = () => {
    this.products.forEach(p => {
      if (
        p.count === p.quantity &&
        p.quantity_edit === null &&
        !p.return &&
        !p.defective
      ) {
        p.quantity_edit = p.quantity
      }
    })
  }

  @action fillAll = () => {
    this.products.forEach(p => {
      if (!p.quantity_edit && !p.return_edit && !p.defective_edit) {
        p.quantity_edit = p.quantity
        p.return_edit = p.return
        p.defective_edit = p.defective
      }
    })
  }

  @action
  setSales = new_item => {
    const { total, debt, cash, data: new_data } = new_item
    const {
      received,
      start_date,
      end_date,
      sales,
      products,
      released,
      accounts,
      total_discount_amount,
      total_tax_amount,
      transfer_bank_amount,
      terminal_amount,
      bank_debt_amount,
      sales_total_amount_rounding,
    } = new_data

    let quantities = this.products.reduce(quantitiesReduce, {})
    for (let p of products) {
      if (quantities[p.id]) {
        p.quantity_edit = quantities[p.id].quantity_edit || null
        p.return_edit = quantities[p.id].return_edit || null
        p.defective_edit = quantities[p.id].defective_edit || null
      } else {
        if (!p.quantity_edit) p.quantity_edit = null
        if (!p.return_edit) p.return_edit = null
        if (!p.defective_edit) p.defective_edit = null
      }
    }

    let item = Object.keys(this.item).length === 0 ? {} : this.item
    item.total = total || null
    item.debt = debt || null
    item.cash = cash || null
    updateProps(item, new_item, 'consumption', 'amount', 'account')

    const no_data = !item.data || Object.keys(item.data).length === 0
    let data = no_data ? {} : item.data
    data.received = received || null
    data.start_date = start_date || null
    data.end_date = end_date || null
    data.sales = sales
    data.released = released
    data.total_discount_amount = total_discount_amount
    data.total_tax_amount = total_tax_amount
    data.transfer_bank_amount = transfer_bank_amount
    data.terminal_amount = terminal_amount
    data.bank_debt_amount = bank_debt_amount
    data.sales_total_amount_rounding = sales_total_amount_rounding

    data.accounts = accounts.map(a => ({
      ...a,
      amount: a.amount
        ? a.amount
        : !data.accounts
        ? null
        : data.accounts.find(a2 => a2.id === a.id)?.amount || null,
    }))
    if (!data.consumptions) {
      data.consumptions = []
    }
    updateProps(data, new_data, 'gave', 'transfer_stock', 'done')

    if (no_data) item.data = data // needed for render method in: /native/pages/Agentreport.js
    if (Object.keys(this.item).length === 0) this.item = item // needed for render method in: /native/pages/Agentreport.js

    this.products.replace(products.sort(this.sortProducts))
    this.hide_not_changed = false
  }

  async postData(pathname, item = this.item) {
    try {
      this.validate()
      item.data.products = [...this.products]
      const resp = await super.postData(pathname, item)
      item.report_status === 'done' && this.fetchItem(pathname)
      return resp
    } catch (e) {
      super.catchPostData(e)
      throw e
    }
  }

  validate() {
    if (!this.item.user_id) throw new Error('Укажите агента!')
    this.products.forEach(p => (p.amount = countProductAmount(p)))
    this.item.total = this.total
    this.item.amount = this.amount
    const accounts = this.item?.data?.accounts
    if (
      this.item.report_status === 'done' &&
      accounts.every(a => !a.amount && a.amount !== 0)
    ) {
      throw new Error('Укажите кассу!')
    }
    if (this.checkQuantity) {
      this.warnAboutAccount && this.setWarnAboutAccount(false)
    } else {
      this.setWarnAboutAccount(true)
      throw new Error('Проверьте итого и кассу')
    }
    if (this.checkQuantityRemainder) {
      this.warnAboutRemainder && this.setWarnAboutRemainder(false)
    } else if (this.warnAboutRemainder === false) {
      this.setWarnAboutRemainder(true)
      throw new Error('Проверьте остатки')
    } else {
      this.setWarnAboutRemainder(false)
      this.errors?.clear()
    }
  }

  clearItem() {
    super.clearItem()
    this.products?.clear()
    this.errors?.clear()
    this.input_column = 'quantity'
    this.preview = false
    this.warnAboutAccount = false
    this.warnAboutRemainder = false
    this.hide_not_changed = false
  }

  agentReportPrintableData() {
    if (!this.item.data) return
    const {
      data: { start_date, end_date, received, gave, done, released },
      user,
      debt,
      cash,
      account,
      report_status,
    } = this.item
    const read_only = this.isReadOnly()
    const total = read_only ? this.item.total : this.total
    const consumption = read_only ? this.item.consumption : this.consumption
    const amount = read_only ? this.item.amount : this.amount
    return {
      date: `${moment(start_date).format('DD.MM.YYYY')} - ${moment(
        end_date,
      ).format('DD.MM.YYYY')}`,
      agent: user.fio,
      products: this.products
        .sort(sortProductsByName)
        .map(this.mapProductsPrint),
      total: total ? v2nC(total) : null,
      debt: debt ? v2nC(debt) : null,
      cash: cash ? v2nC(cash) : null,
      consumption: consumption ? v2nC(consumption) : null,
      amount: amount ? v2nC(amount) : null,
      account: account ? v2nC(account) : null,
      received: received ? v2nQ(received) : null,
      gave: gave ? v2nQ(gave) : null,
      done: done || '',
      released: released || '',
      status: { new: 'Новый', done: 'Выполнено' }[report_status],
    }
  }

  mapProductsPrint(p) {
    const read_only = this.isReadOnly()
    let ret = []
    p.return_edit && ret.push(v2nQ(p.return_edit))
    p.defective_edit && ret.push(v2nQ(p.defective_edit) + ' бр.')
    const amount = read_only ? p.amount : countProductAmount(p)
    return {
      name: p.name_short || p.name,
      count: p.count ? v2nQ(p.count) : null,
      quantity: p.quantity_edit ? v2nQ(p.quantity_edit) : null,
      return: ret.join(' + '),
      amount: typeof amount === 'number' || amount ? v2nC(amount) : null,
    }
  }

  setResult(data, defaultData) {
    const ret = super.setResult(data, defaultData)
    if (data?.list) {
      this.totals_by_currencies = data?.totals_by_currencies || []
    }
    return ret
  }

  @action
  togglePreviewReport = () => {
    try {
      this.validate()
    } catch (e) {}
    this.preview = !this.preview
  }

  @action
  setWarnAboutAccount(status) {
    this.warnAboutAccount = status
  }
  @action
  setWarnAboutRemainder(status) {
    this.warnAboutRemainder = status
  }

  @action.bound
  closeWarnModal() {
    this.warnAboutAccount = false
  }
  @action.bound
  closeWarnModalRemainder() {
    this.warnAboutRemainder = false
    this.errors.clear()
  }

  @action
  catchPostData(e) {
    this.warnAboutAccount && this.setWarnAboutAccount(false)
    this.warnAboutRemainder && this.setWarnAboutRemainder(false)
    super.catchPostData(e)
  }

  canSave() {
    return super.canSave() && this.item.report_status !== 'rollback'
  }

  canDelete() {
    return super.canDelete() && this.item.report_status !== 'rollback'
  }
}

AgentReportStore.calcAmount = countProductAmount
AgentReportStore.calcAmountPre = countProductPreAmount

export default new AgentReportStore()
