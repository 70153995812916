import { inject, observer } from 'mobx-react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { NavLink } from 'reactstrap'

const colors = [
  '#008F95',
  '#4484CE',
  '#F19F4D',
  '#CD5360',
  '#985E6D',
  '#67AECA',
  '#675682',
]

@inject('store')
@withTranslation()
@observer
export default class Home extends React.Component {
  container
  containerRef = ref => (this.container = ref)

  componentDidMount() {
    const { height, top } = this.props.store.AppStore.home_scroll_position
    if (this.container && this.container.scrollHeight === height) {
      this.container.scrollTo(0, top)
    }
    this.props.store.AppStore.navigateSideMenu(null)
  }

  render() {
    return (
      <div className='home-container' ref={this.containerRef}>
        {this.props.store.MenuStore.menu_list_desktop.map(this.renderCategory)}
      </div>
    )
  }

  renderCategory = (s, index) => {
    const menus = [...s.children]
    if (menus.length % 3 > 0)
      menus.push(...Array.from(Array(3 - (menus.length % 3))))
    let name = s.data
      ? s.data[`name_${this.props.store.SettingsStore.language}`] || s.name
      : s.name
    return (
      <div
        key={s.name}
        className='home-page-category'
        style={{ backgroundColor: colors[index % colors.length] }}
      >
        <div className='home-page-category-heading text-center'>{name}</div>
        <div className='menus-row text-center'>{menus.map(this.mapMenus)}</div>
      </div>
    )
  }

  onMenuClick = (e, menu) => {
    if (e.metaKey || e.ctrlKey) return false
    e.preventDefault()
    if (window.swUpdate) return (window.location = menu.url)
    if (this.container) {
      this.props.store.AppStore.setScrollPosition(
        this.container.scrollTop,
        this.container.scrollHeight,
      )
    }
    this.props.store.AppStore.navigateSideMenu(menu.url)
    return this.props.history.push(menu.url)
  }

  mapMenus = (m, i) =>
    m?.url ? (
      <div key={m.url} className='menu-col'>
        <NavLink
          className='home-menu-item'
          href='#'
          onClick={e => this.onMenuClick(e, m)}
        >
          <div className='nav-icon-1x1'>
            <i className={`nav-icon-t ${m.icon}`} />
          </div>
          <div className='home-menu-item-title'>
            {m.data
              ? m.data[`name_${this.props.store.SettingsStore.language}`] ||
                m.name
              : m.name}
          </div>
        </NavLink>
      </div>
    ) : (
      <div key={i + '//'} className='menu-col' />
    )
}
