import {
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
} from '@coreui/react'
import { inject, observer } from 'mobx-react'
import React from 'react'
import AppSidebarNav from './SidebarNav'

@inject('store')
@observer
class Sidebar extends React.Component {
  render() {
    const { menu, store, ...props } = this.props
    const { user_info } = store.AppStore
    // noinspection SpellCheckingInspection
    const navigationRoutes = {
      items: [
        {
          name: user_info ? user_info.username : '',
          icon: 'icon-user',
          url: '/me',
          class: 'user-info',
        },
        {
          name: 'Главная',
          icon: 'icon-star',
          url: '/',
          class: 'side-main',
          data: { name_tr: 'Ana ekran' },
        },
        {
          title: true,
          name: 'Компоненты',
          wrapper: {
            element: '',
            attributes: {},
          },
          data: { name_tr: 'Bileşenler' },
        },
        ...menu,
        {
          name: 'Выход',
          icon: 'icon-logout',
          url: '/logout',
          class: 'side-logout',
          data: { name_tr: 'çıkış' },
        },
      ],
    }

    return (
      <AppSidebar fixed display='lg'>
        <AppSidebarHeader />
        <AppSidebarForm />
        <AppSidebarNav {...props} navConfig={navigationRoutes} />
        <AppSidebarFooter />
        <AppSidebarMinimizer />
      </AppSidebar>
    )
  }
}

export default Sidebar
