import {
  action,
  computed,
  entries as objectEntries,
  makeObservable,
  observable,
} from 'mobx'
import moment from 'moment'
import {
  CONTRACTOR_DOC_ACTIVE,
  CONTRACTOR_DOC_REJECTED,
} from '../common/constants'
import { getModelValue, guid } from '../common/utils'
import AppStore from './AppStore'
import BaseStore from './BaseStore'

export class ContractordocsStore extends BaseStore {
  @observable show_item_status_action = ''
  @observable reject_message = ''

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get current_item_inn() {
    const { data, contractor } = this.item
    return (
      getModelValue(data, 'inn_patent') ||
      getModelValue(data, 'inn_certificate') ||
      getModelValue(data, 'inn_contract') ||
      getModelValue(data, 'personal_number') ||
      getModelValue(contractor, 'inn') ||
      ''
    )
  }

  @computed get can_activate_message() {
    const { data } = this.item
    const {
      personal_number,
      date_of_expire,
      inn_patent,
      date_of_start_patent,
      date_of_expire_patent,
      inn_certificate,
      date_of_expire_contract,
    } = data ? Object.fromEntries(objectEntries(data)) : {}
    const messages = []
    if (personal_number && !date_of_expire) {
      messages.push('Укажите дату окончания паспорта.')
    } else if (
      personal_number &&
      moment().subtract(1, 'month').isAfter(date_of_expire)
    ) {
      messages.push('Дата окончания паспорта уже истекла.')
    }
    if (!inn_patent && !inn_certificate) {
      messages.push('Введите ИНН патента или свидетельства.')
    }
    if (inn_patent && !date_of_start_patent) {
      messages.push('Укажите дату начала патента.')
    } else if (
      inn_patent &&
      moment().endOf('week').isBefore(date_of_start_patent)
    ) {
      messages.push('Неверно введена дата начала патента.')
    }
    if (inn_patent && !date_of_expire_patent) {
      messages.push('Укажите дату окончания патента.')
    } else if (
      inn_patent &&
      moment().subtract(1, 'month').isAfter(date_of_expire_patent)
    ) {
      messages.push('Дата окончания патента уже истекла.')
    }
    if (
      date_of_expire_contract &&
      moment().subtract(1, 'month').isAfter(date_of_expire_contract)
    ) {
      messages.push('Дата окончания договора уже истекла.')
    }
    return messages.join('\n')
  }

  @computed get can_activate() {
    return this.can_activate_message.length === 0
  }

  isReadOnly() {
    return (
      this.item.contractor_doc_status === CONTRACTOR_DOC_ACTIVE ||
      super.isReadOnly()
    )
  }

  canDelete() {
    return (
      this.item.contractor_doc_status !== CONTRACTOR_DOC_ACTIVE &&
      super.canDelete()
    )
  }

  @action showItemStatusAction = action => {
    this.show_item_status_action = action || ''
  }

  @action showReject = () => {
    this.showItemStatusAction(CONTRACTOR_DOC_REJECTED)
    this.reject_message = ''
  }

  @action hideReject = () => {
    this.showItemStatusAction('')
    this.reject_message = ''
  }

  @action showActivate = () => {
    this.showItemStatusAction(CONTRACTOR_DOC_ACTIVE)
  }

  @action hideActivate = () => {
    this.showItemStatusAction('')
  }

  @action setContractorDocStatus(item, status) {
    item.contractor_doc_status = status
  }

  @action addMessage(item) {
    if (item.data.messages) item.data.messages = []
    const message = {
      status: item.contractor_doc_status,
      message: this.reject_message,
      uuid: guid(),
      rec_date: moment().format('YYYY-MM-DD HH:mm'),
      rec_user: AppStore.user_info?.username,
      rec_user_fio: AppStore.user_info?.fio,
    }
    message.message && item.data.messages.push(message)
    return message
  }

  @action removeMessage(item, message) {
    if (item.data.messages) item.data.messages = []
    item.data.messages = this.item.data.messages.filter(m => m !== message)
  }

  async postData(pathname, item = this.item) {
    const status = item.contractor_doc_status
    let message
    if (this.show_item_status_action === CONTRACTOR_DOC_REJECTED) {
      this.setContractorDocStatus(item, CONTRACTOR_DOC_REJECTED)
      message = this.addMessage(item)
    } else if (this.show_item_status_action === CONTRACTOR_DOC_ACTIVE) {
      this.setContractorDocStatus(item, CONTRACTOR_DOC_ACTIVE)
    }
    try {
      const resp = await super.postData(pathname, item)
      this.hideReject()
      this.hideActivate()
      return resp
    } catch (error) {
      this.removeMessage(item, message)
      this.setContractorDocStatus(item, status)
      throw error
    }
  }

  @action
  setDocumentStatus(status) {
    this.item.contractor_doc_status = status
  }
}

const store = new ContractordocsStore()
export default store
