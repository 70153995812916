import isNull from 'lodash/isNull'
import isNumber from 'lodash/isNumber'
import isObjectLike from 'lodash/isObjectLike'
import isUndefined from 'lodash/isUndefined'
import PropTypes from 'prop-types'
import React from 'react'
import { v2n, v2nQ } from '../utils'

export default class QuantityBase extends React.Component {
  static propTypes = {
    unit: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  get unit() {
    let { unit } = this.props
    if (isObjectLike(unit)) {
      unit = isObjectLike(unit.nomenclature) ? unit.nomenclature : unit
      unit = isObjectLike(unit.unit) ? unit.unit : unit
      unit = isObjectLike(unit) ? unit.short || unit.name : unit
    }
    return unit
  }

  get num_value() {
    let { value } = this.props
    if (!isNull(value) && !isUndefined(value) && value !== '')
      try {
        value = v2n(value).value()
        return isFinite(value) ? value : null
      } catch {
        return null
      }
  }

  get value() {
    const value = this.num_value
    return isNumber(value) ? v2nQ(value) : null
  }

  render() {
    const value = this.value
    const unit = this.unit
    return isNull(value) ? null : unit ? `${value}\xa0${unit}` : value
  }
}
