import { action, computed, makeObservable, observable } from 'mobx'
import requester from '../common/requester'
import { capitalize } from '../common/utils'
import { getStorage } from './Storage'

class MenuStore {
  @observable ready = false
  @observable menu = observable.array()
  @observable expanded_sections = observable.map()

  @action replaceMenu = menu => {
    this.expanded_sections.clear()
    this.menu.replace(menu)
    this.ready = true
  }

  fetchMenu = async () => {
    const menu_list = []
    try {
      const { data } = await requester.get('/system/menu')
      menu_list.push(...data.list)
    } catch {
      try {
        const menu = await getStorage().load({ key: 'menu' })
        menu && menu_list.push(...menu)
      } catch {}
    }
    this.replaceMenu(menu_list)
    if (menu_list.length > 0) {
      await getStorage().save({ key: 'menu', data: menu_list })
    } else {
      await getStorage().remove({ key: 'menu' })
    }
  }

  isSectionExpanded = section => {
    const id = section.id.toString()
    return this.expanded_sections.has(id) && this.expanded_sections.get(id)
  }

  @action toggleSection = section => {
    const id = section.id.toString()
    this.expanded_sections.set(id, !this.isSectionExpanded(section))
  }

  filterSections = section => section.menus.length > 0

  mapMenus = menu => ({
    ...menu,
    url: `/${menu.controller}`,
    icon: menu.icon || 'icon-puzzle',
  })
  mapMenuSections = section => ({
    ...section,
    icon: section.icon || 'icon-puzzle',
    children: section.menus.map(this.mapMenus),
  })

  isMobileMenu = menu => menu.mobile === true && menu.controller
  mapMobileMenuSections = section => ({
    ...section,
    menus: section.menus?.filter(this.isMobileMenu) || [],
  })

  constructor() {
    makeObservable(this)
  }

  @computed get menu_list_mobile() {
    return this.menu
      .map(this.mapMobileMenuSections)
      .filter(this.filterSections)
      .map(this.mapMenuSections)
  }

  isDesktopMenu = menu => menu.desktop === true && menu.controller
  mapDesktopMenuSections = section => ({
    ...section,
    menus: section.menus?.filter(this.isDesktopMenu) || [],
  })

  @computed get menu_list_desktop() {
    return this.menu
      .map(this.mapDesktopMenuSections)
      .filter(this.filterSections)
      .map(this.mapMenuSections)
  }

  @computed get menu_names() {
    const names = {}
    this.menu.forEach(section => {
      section.menus &&
        section.menus.forEach(menu => {
          const { controller, name, data = {} } = menu
          if (controller && name) {
            names[controller] = { name, data }
          }
        })
    })
    return names
  }

  @computed get routes() {
    return this.menu
      .map(section =>
        section.menus
          ? section.menus.map(menu => {
              const { controller } = menu
              const page = capitalize(controller)
              return controller
                ? [
                    {
                      exact: true,
                      key: `${controller}:edit`,
                      path: `/${controller}/:id`,
                      renderProps: { page: `${page}Edit` },
                    },
                    {
                      exact: true,
                      key: controller,
                      path: `/${controller}`,
                      renderProps: { page },
                    },
                  ]
                : []
            })
          : [],
      )
      .flat(2)
  }
}

export default new MenuStore()
