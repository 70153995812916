import { makeObservable, observable, runInAction } from 'mobx'
import requester from '../common/requester'
import BaseStore from './BaseStore'
import AppStore from './AppStore'

const ALREADY_REGISTERED_CODE = 409
export class CdsStore extends BaseStore {
  @observable roles = observable.array()
  @observable branch_id = null
  @observable code = ''
  @observable cds_auth = false
  @observable registered = false
  @observable is_verify = false

  constructor() {
    super()
    makeObservable(this)
  }

  async fetch() {
    const response = await requester.get('/cds')
    await this.setResult(response.data)
    this.setIsVerify(response.data.item.verified)
    this.setRegistered(response.data.item.registered)
    if (!this.is_verify && this.registered) {
      await this.getCode()
    }
    return response.data
  }

  catchPostData(e) {
    const message = JSON.parse(e.message)
    !e.extra && AppStore.showWarning(message.errorMessage)
  }

  setRegistered(registered) {
    this.registered = registered
  }

  setIsVerify(isVerify) {
    this.is_verify = isVerify
  }

  setCdsAuth(auth) {
    this.cds_auth = auth
  }

  async post() {
    try {
      let response = await requester.post(`/cds/register`, this.item)
      runInAction(() => (this.item.id = response.data.id))
      this.saveSuccessMessage()
      if (
        response.data.code &&
        response.data.code === ALREADY_REGISTERED_CODE
      ) {
        AppStore.showWarning(response.data.message)
        this.setRegistered(true)
        this.setIsVerify(false)
        this.setCdsAuth(true)
      }
      await this.fetch()
      return response.data
    } catch (e) {
      this.item.esiaAccepted = false
      this.catchPostData(e)
      throw e
    }
  }

  async verify() {
    try {
      let response = await requester.post(
        this.is_verify ? '/cds/verify' : '/cds/auth',
        this.item,
      )
      runInAction(() => (this.item.id = response.data.id))
      AppStore.showSuccess('Верификация прошла успешно')
      await this.fetch()
      return response.data
    } catch (e) {
      this.catchPostData(e)
      throw e
    }
  }

  async auth() {
    try {
      let response = await requester.post(`/cds/auth`, this.item)
      runInAction(() => (this.item.id = response.data.id))
      AppStore.showSuccess('Личность подтверждена успешно')
      await this.fetch()
      return response.data
    } catch (e) {
      this.catchPostData(e)
      throw e
    }
  }

  async getCode() {
    try {
      let response = await requester.post(`/cds/get_pin_code`, this.item)
      AppStore.showInfo('На почту отравлен код')
      this.setCdsAuth(true)
      return response.data
    } catch (e) {
      this.catchPostData(e)
      throw e
    }
  }
}

const store = new CdsStore()
export default store
