import { computed, observable, makeObservable } from 'mobx'
import States from './States'
import requester from '../../../common/requester'
import Item from './Item'

export default class Standard {
  @observable id
  @observable data

  constructor(data, store) {
    makeObservable(this)
    this.store = store
    this.setData(data)
    this.item = new Item(this)
    this.states = new States(this)
    this.store.with_identity &&
      this.states.withIdentity(this.store.with_identity)
  }

  setData(data) {
    this.id = data.id
    this.data = data
  }

  get process_type() {
    return 'standard'
  }

  @computed get line_status() {
    return this.item.line_status
  }

  async removeFromTara({
    branch_id,
    identity,
    nomenclature_id,
    product_id,
    quantity,
    tara_identity,
  }) {
    branch_id = branch_id || this.data.branch_id
    let data = {
      branch_id,
      material: {
        nomenclature_id,
        branch_id,
        quantity,
        product_id,
        identity,
      },
      tara_identity,
    }
    if (this.data.status !== 'finished') data.production_id = this.id
    await requester.post('/production/tara-write-off', data)
    await this.store.fetchProduction()
  }
}
