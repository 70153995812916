import { computed, makeObservable } from 'mobx'
import moment from 'moment'
import BaseStore from './BaseStore'

class KpiStore extends BaseStore {
  constructor() {
    super()
    makeObservable(this)
  }

  clearItem() {
    this.disposeFiltersDispose()
    super.clearItem()
  }

  filterDefaults() {
    return {
      ...super.filterDefaults(),
      start_from: moment().format('YYYY-MM-DD'),
      end_to: moment().format('YYYY-MM-DD'),
    }
  }

  async fetchItem(pathname) {
    return await this.fetchData({ url: pathname })
  }

  @computed get total_created() {
    return this.items.reduce(
      (total, { created }) => total + parseFloat(created || '0'),
      0,
    )
  }

  @computed get total_started() {
    return this.items.reduce(
      (total, { started }) => total + parseFloat(started || '0'),
      0,
    )
  }

  @computed get total_finished() {
    return this.items.reduce(
      (total, { finished }) => total + parseFloat(finished || '0'),
      0,
    )
  }

  @computed get total_running_start() {
    return this.items.reduce(
      (total, { running_start }) => total + parseFloat(running_start || '0'),
      0,
    )
  }

  @computed get total_running_end() {
    return this.items.reduce(
      (total, { running_end }) => total + parseFloat(running_end || '0'),
      0,
    )
  }

  @computed get total_invalid() {
    return this.items.reduce(
      (total, { invalid }) => total + parseFloat(invalid || '0'),
      0,
    )
  }

  reduceUnit = (total, v) => {
    const i = total.findIndex(t => t.unit_id === v.unit_id)
    if (i === -1) return [...total, { ...v }]
    total[i].quantity = parseFloat(total[i].quantity) + parseFloat(v.quantity)
    return total
  }

  reduceUnits = prop => (total, item) =>
    item[prop] ? item[prop].reduce(this.reduceUnit, total) : total

  @computed get total_inputs() {
    return this.items.reduce(this.reduceUnits('inputs'), [])
  }

  @computed get total_outputs() {
    return this.items.reduce(this.reduceUnits('outputs'), [])
  }
}

const store = new KpiStore()
export default store
