import 'file-loader?name=firebase-messaging-sw.js!./libs/firebase-messaging-sw.js'
import { createBrowserHistory } from 'history'
import { Provider } from 'mobx-react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Router } from 'react-router-dom'
import { LanguageProvider, StoreProvider } from '../common'
import { isProduction } from '../common/settings'
import { excelLoader } from '../common/utils'
import stores from '../store/stores'
import ErrorBoundary from './components/ErrorBoundary'
import Root from './Root'

if (isProduction()) {
  import('@lcdp/offline-plugin/runtime').then(({ install, applyUpdate }) =>
    install({
      onUpdateReady: () => applyUpdate(),
      onUpdated: () => (window.swUpdate = true),
    }),
  )
}

excelLoader.use(url => window.open(url, '_blank'))
const browserHistory = createBrowserHistory()

function WebApp() {
  return (
    <ErrorBoundary>
      <Provider store={stores}>
        <StoreProvider>
          <LanguageProvider>
            <Router history={browserHistory}>
              <Root />
            </Router>
          </LanguageProvider>
        </StoreProvider>
      </Provider>
    </ErrorBoundary>
  )
}

// noinspection JSCheckFunctionSignatures
const root = createRoot(document.getElementById('root'))

function render() {
  root.render(<WebApp />)
}

render()
module.hot?.accept(() => render())
