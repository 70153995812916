import { inject, observer, PropTypes as MobxTypes } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import ReactImageLightbox from 'react-image-lightbox'
import { imageUrl } from '../../common/utils'

@inject('store')
@observer
export class ImageLightbox extends React.Component {
  get store() {
    return this.props.store.ImageViewStore
  }

  render() {
    const current_image = this.store.current_image
    return (
      !!current_image && (
        <Lightbox
          mainSrc={current_image}
          prevSrc={this.store.prev_image}
          nextSrc={this.store.next_image}
          onCloseRequest={this.store.hideImage}
          onMovePrevRequest={this.store.showPrevImage}
          onMoveNextRequest={this.store.showNextImage}
        />
      )
    )
  }
}

@inject('store')
@observer
export class ImageList extends React.Component {
  static propTypes = {
    group: PropTypes.string.isRequired,
    images: MobxTypes.arrayOrObservableArrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        MobxTypes.objectOrObservableObject,
      ]),
    ),
    visible: PropTypes.bool,
    visible_index: PropTypes.number,
    mapper: PropTypes.func,
    renderImages: PropTypes.func,
    renderImage: PropTypes.func,
  }

  static defaultProps = {
    visible_index: 0,
  }

  get store() {
    return this.props.store.ImageViewStore
  }

  get images() {
    return this.imagesCompute(this.props)
  }

  imagesCompute = ({ images, mapper }) => {
    if (!images) return []
    return !mapper
      ? images
      : images.reduce((reduced, image) => {
          const mapped = mapper(image)
          if (mapped && typeof mapped === 'string') reduced.push(mapped)
          return reduced
        }, [])
  }

  componentDidMount() {
    this.updateImages()
  }

  componentDidUpdate(pp, prevState, snapshot) {
    const { group, visible, visible_index } = this.props
    if (group !== pp.group) this.store.deleteGroup(pp.group)
    const images = this.imagesCompute(this.props)
    const prev_images = this.imagesCompute(pp)
    const update_images =
      group !== pp.group ||
      images.length !== prev_images.length ||
      images.some((image, index) => prev_images[index] !== image)
    if (update_images) this.updateImages()
    else if (visible !== pp.visible || visible_index !== pp.visible_index) {
      let index = visible ? visible_index % images.length : -1
      if (index === -1 && this.store.current_group === group)
        this.store.hideImage()
      else this.store.showImage(group, index)
    }
  }

  updateImages() {
    const { group, visible, visible_index } = this.props
    const images = this.imagesCompute(this.props)
    this.store.setImages(
      group,
      images,
      visible ? visible_index % images.length : -1,
    )
  }

  componentWillUnmount() {
    this.store.deleteGroup(this.props.group)
  }

  render() {
    const { renderImages, group } = this.props
    const images = this.images
    if (renderImages) return renderImages({ images, group })
    return (
      images.length > 0 && (
        <div className='apx-image-list'>{images.map(this.renderImageItem)}</div>
      )
    )
  }

  renderImageItem = (image, index) => (
    <ImageItem
      key={`${image}.${index}`}
      group={this.props.group}
      images={this.images}
      index={index}
      image={image}
      renderImage={this.props.renderImage}
    />
  )
}

@inject('store')
@observer
export class ImageItem extends React.Component {
  static propTypes = {
    group: PropTypes.string.isRequired,
    images: MobxTypes.arrayOrObservableArrayOf(PropTypes.string),
    index: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    renderImage: PropTypes.func,
  }

  get store() {
    return this.props.store.ImageViewStore
  }

  _onClick = e => {
    e.preventDefault()
    this.store.showImage(this.props.group, this.props.index)
    return false
  }

  get image_url() {
    return imageUrl(this.props.image, 200, 200)
  }

  get image_alt() {
    const { group, image } = this.props
    return group + ':' + image.substring(image.lastIndexOf('/') + 1)
  }

  render() {
    const { renderImage, group, images, index, image } = this.props
    if (renderImage)
      return renderImage({
        image,
        index,
        group,
        images,
        onClick: this._onClick,
      })
    return (
      <a className='apx-image-item' href='#' onClick={this._onClick}>
        <img className='apx-image' src={this.image_url} alt={this.image_alt} />
      </a>
    )
  }
}

class Lightbox extends React.Component {
  static propTypes = {
    mainSrc: PropTypes.string.isRequired,
    prevSrc: PropTypes.string,
    nextSrc: PropTypes.string,
    mainSrcThumbnail: PropTypes.string,
    prevSrcThumbnail: PropTypes.string,
    nextSrcThumbnail: PropTypes.string,
    onCloseRequest: PropTypes.func.isRequired,
    onMovePrevRequest: PropTypes.func,
    onMoveNextRequest: PropTypes.func,
    onImageLoadError: PropTypes.func,
    onImageLoad: PropTypes.func,
    onAfterOpen: PropTypes.func,
    discourageDownloads: PropTypes.bool,
    animationDisabled: PropTypes.bool,
    animationOnKeyInput: PropTypes.bool,
    animationDuration: PropTypes.number,
    keyRepeatLimit: PropTypes.number,
    keyRepeatKeyupBonus: PropTypes.number,
    imageTitle: PropTypes.node,
    imageCaption: PropTypes.node,
    imageCrossOrigin: PropTypes.string,
    reactModalStyle: PropTypes.shape({}),
    imagePadding: PropTypes.number,
    wrapperClassName: PropTypes.string,
    toolbarButtons: PropTypes.arrayOf(PropTypes.node),
    clickOutsideToClose: PropTypes.bool,
    enableZoom: PropTypes.bool,
    reactModalProps: PropTypes.shape({}),
    nextLabel: PropTypes.string,
    prevLabel: PropTypes.string,
    zoomInLabel: PropTypes.string,
    zoomOutLabel: PropTypes.string,
    closeLabel: PropTypes.string,
    imageLoadErrorMessage: PropTypes.node,
  }

  static defaultProps = {
    discourageDownloads: true,
    closeLabel: 'Закрыть',
    nextLabel: 'След.',
    prevLabel: 'Пред.',
    zoomInLabel: 'Увеличить',
    zoomOutLabel: 'Уменьшить',
    imageLoadErrorMessage: 'Не удалось загрузить изображение',
  }

  render() {
    return <ReactImageLightbox {...this.props} />
  }
}
