import { action, computed, makeObservable, observable } from 'mobx'
import requester from '../common/requester'
import AppStore from './AppStore'
import production from './production'

export class ProductionStore {
  @observable access = observable.array()
  @observable initial_loading = true
  @observable production_id
  @observable production_item = {}
  @observable tara_history_count = -1
  @observable tara_history = observable.array()

  with_identity

  constructor() {
    makeObservable(this)
    this.clearStore()
    this.fetchProduction = this.fetchProduction.bind(this)
    this.deleteProduction = this.deleteProduction.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  clearStore() {
    this.tara_history = []
    this.tara_history_count = -1
    this.with_identity = null
    this.production_item = {}
    this.production_id = null
    this.initial_loading = true
    this.access.clear()
  }

  @action
  setProductionId(id, with_identity) {
    this.production_id = id || null
    this.with_identity = with_identity || null
    this.production_id && this.fetchProduction()
  }

  @action setTaraHistory = (tara_history, count) => {
    this.tara_history = tara_history
    this.tara_history_count = count
  }

  showTaraHistory = async () => {
    let { data } = await requester.get(
      `/tara/tara-history/productions/` + this.production_id,
    )
    this.setTaraHistory(data.list, data.count)
  }

  async fetchProduction() {
    AppStore.setBusyState(true)
    try {
      const data = await requester.get('/productions/' + this.production_id)
      if (data.data.item.id !== this.production_id) {
        return
      }
      this.setAccess(data.data.access)
      this.setProductionItem(data.data.item)
    } finally {
      AppStore.setBusyState(false)
      this.initial_loading !== false && this.setInitialLoading(false)
    }
  }

  @action setAccess(access) {
    this.access.replace(access)
  }

  @computed get can_create() {
    return this.access.includes('create')
  }

  @computed get can_edit() {
    return this.access.includes('edit')
  }

  @computed get can_delete() {
    return (
      this.access.includes('delete') &&
      this.production_item.item?.status === 'pending'
    )
  }

  async deleteProduction() {
    await requester.delete('/productions/' + this.production_id)
  }

  @action setProductionItem(production_item) {
    const { id, process_type } = production_item
    if (
      this.production_item.id === id &&
      this.production_item.process_type === process_type
    ) {
      this.production_item.setData(production_item)
    } else if (production[process_type]) {
      this.production_item = new production[process_type](production_item, this)
    }
  }

  @action setInitialLoading(value) {
    this.initial_loading = value
  }

  goBack() {
    const { goBack } = this.production_item.states || {}
    return goBack ? goBack() : false
  }
}

const store = new ProductionStore()
export default store
