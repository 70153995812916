import isObjectLike from 'lodash/isObjectLike'
import PropTypes from 'prop-types'
import React from 'react'
import { v2n, v2nC } from '../utils'

export default class CurrencyBase extends React.Component {
  static propTypes = {
    symbol: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    withCent: PropTypes.bool,
  }

  static defaultProps = {
    withCent: true,
  }

  constructor(props) {
    super(props)
    this.store = this.props.store.AppStore
  }

  get symbol() {
    let { symbol } = this.props
    if (isObjectLike(symbol)) {
      symbol = symbol.currency ? symbol.currency : symbol
      symbol = isObjectLike(symbol) ? symbol.symbol || symbol.code : symbol
    }
    const currency = this.store.user_info?.branch.currency
    return symbol || currency.symbol || currency.code || 'c'
  }

  get num_value() {
    const { value } = this.props
    if (!value && typeof value !== 'number') return null
    return v2n(value).value()
  }

  get value() {
    const value = this.num_value
    if (value === null) return null
    return v2nC(value, this.props.withCent)
  }

  render() {
    const value = this.value
    return value ? `${value} ${this.symbol}` : null
  }
}
