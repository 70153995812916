import PropTypes from 'prop-types'
import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

function makePages(page = 1, pagesCount = 0) {
  if (pagesCount < 10) {
    return new Array(pagesCount).fill(0).map((_, i) => {
      const page = i + 1
      return { page, label: `${page}` }
    })
  }
  if (page < 6) {
    return [
      ...new Array(8).fill(0).map((_, i) => {
        const page = i + 1
        return { page, label: `${page}` }
      }),
      { page: pagesCount, label: `${pagesCount}` },
    ]
  } else if (page > pagesCount - 6) {
    return [
      { page: 1, label: '1' },
      ...new Array(8).fill(0).map((_, i) => {
        const page = pagesCount - 7 + i
        return { page, label: `${page}` }
      }),
    ]
  }
  return [
    { page: 1, label: '1' },
    ...new Array(7).fill(0).map((_, i) => {
      const _page = page - 3 + i
      return { page: _page, label: `${_page}` }
    }),
    { page: pagesCount, label: `${pagesCount}` },
  ]
}

export default class Pager extends React.Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
    pagesCount: PropTypes.number.isRequired,
    onPageSelect: PropTypes.func.isRequired,
  }

  state = { pages: makePages(1, 0), page: 1, pagesCount: 0 }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { page, pagesCount } = nextProps
    const { page: p, pagesCount: pc } = { ...prevState }
    return p === page && pc === pagesCount
      ? prevState
      : {
          pages: makePages(page, pagesCount),
          page,
          pagesCount,
        }
  }

  _onPageSelect(page) {
    this.props.onPageSelect(page)
  }

  renderPage(i) {
    const { page } = this.state
    return (
      <PaginationItem
        key={i.page}
        active={i.page === page}
        onClick={() => this._onPageSelect(i.page)}
      >
        <PaginationLink>{i.label}</PaginationLink>
      </PaginationItem>
    )
  }

  render() {
    const { page, pagesCount, onPageSelect, ...rest } = this.props
    return (
      <Pagination listClassName='mb-0' {...rest}>
        {this.state.pages.map(this.renderPage.bind(this))}
      </Pagination>
    )
  }
}
