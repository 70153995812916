import { action, makeObservable, observable } from 'mobx'
import PropTypes from 'prop-types'
import { Component } from 'react'
import enumTypes from '../enum-types'
import requester from '../requester'

class EnumStateModel {
  @observable options = observable.array()

  constructor() {
    makeObservable(this)
  }

  @action setOptions = options => {
    this.options = options
  }
}

export default class EnumSelectBase extends Component {
  static propTypes = {
    enumName: PropTypes.string.isRequired,
    useConst: PropTypes.bool,
  }

  static defaultProps = {
    useConst: false,
  }

  state = { enum: new EnumStateModel() }

  get options() {
    return this.state.enum.options
  }

  set options(options) {
    this.state.enum.setOptions(options)
  }

  componentDidMount() {
    this.fetchOptions()
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { enumName, useConst } = this.props
    return prevProps.enumName !== enumName || prevProps.useConst !== useConst
      ? true
      : null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    snapshot && this.fetchOptions()
  }

  async fetchOptions() {
    const { enumName, useConst } = this.props
    if (!useConst)
      try {
        let { data } = await requester.get(`/system/enum/${enumName}`)
        this.setOptions(data.list)
      } catch (e) {
        this.fromConst(enumName)
      }
    else {
      this.fromConst(enumName)
    }
  }

  fromConst = enumName => {
    const enumValue = enumTypes[enumName.toUpperCase()]
    const options = enumValue
      ? Object.entries(enumValue).map(([value, name]) => ({ name, value }))
      : null
    options && this.setOptions(options)
  }

  setOptions = options => this.state.enum.setOptions(options)

  render() {
    throw new Error('method not implemented')
  }
}

export class EnumTextBase extends Component {
  static propTypes = {
    enumName: PropTypes.string.isRequired,
    value: PropTypes.any,
  }

  constructor(props) {
    super(props)
    this.store = this.props.store.EnumsStore
  }

  render() {
    return this.store.get_name(this.props.enumName, this.props.value)
  }
}
