import { APPLIANCE_STATUSES } from '../store/AditecStore'
import {
  CASSETTE_BAG,
  CASSETTE_CASH,
  CASSETTE_MODEL_MEI_BIG,
  CASSETTE_MODEL_MEI_SMALL,
  CONTRACTOR_DOC_ACTIVE,
  CONTRACTOR_DOC_ARCHIVE,
  CONTRACTOR_DOC_DRAFT,
  CONTRACTOR_DOC_REJECTED,
  CONTRACTOR_DOC_REVIEW,
} from './constants'
import {
  CONTRACTOR_TYPES,
  INVOICE_STATUSES,
  MOVE_STATUSES,
  SALE_STATUSES,
  TRANSFER_STATUSES,
} from './utils'

export const WITH_BUFFER_OPTIONS = {
  0: '== 0',
  1: '>= 1',
  2: '>= 2',
  3: '>= 3',
  5: '>= 5',
  10: '>= 10',
}
export const WITH_BUFFER_LESS_OPTIONS = {
  1: '< 1',
  2: '< 2',
  3: '< 3',
  5: '< 5',
  10: '< 10',
}

export const CONTRACTOR_DOC_STATUSES = {
  [CONTRACTOR_DOC_DRAFT]: 'Черновик',
  [CONTRACTOR_DOC_REVIEW]: 'Проверка',
  [CONTRACTOR_DOC_REJECTED]: 'Отклонен',
  [CONTRACTOR_DOC_ACTIVE]: 'Активно',
  [CONTRACTOR_DOC_ARCHIVE]: 'Архив',
}

export const CASSETTE_MODELS = {
  [CASSETTE_MODEL_MEI_SMALL]: CASSETTE_MODEL_MEI_SMALL,
  [CASSETTE_MODEL_MEI_BIG]: CASSETTE_MODEL_MEI_BIG,
  [CASSETTE_BAG]: CASSETTE_BAG,
  [CASSETTE_CASH]: CASSETTE_CASH,
}

export default {
  CONTRACTOR_TYPES,
  INVOICE_STATUSES,
  MOVE_STATUSES,
  SALE_STATUSES,
  TRANSFER_STATUSES,
  WITH_BUFFER_OPTIONS,
  WITH_BUFFER_LESS_OPTIONS,
  APPLIANCE_STATUSES,
  CONTRACTOR_DOC_STATUSES,
  CASSETTE_MODELS,
}
