import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import DynamicImport from '../../components/DynamicImport'
import DataTable from '../../pages/DataTable'

const SelectModal = ({
  defaultFilter,
  finalFilter,
  defaultData,
  isOpen,
  match,
  onCreateProp,
  onSelect,
  page,
  toggle,
}) => (
  <Modal autoFocus={false} isOpen={isOpen} toggle={toggle} size='xl'>
    <ModalBody>
      <DynamicImport
        page={page}
        mode='select'
        {...onCreateProp}
        match={match}
        onSelect={onSelect}
        fallback={DataTable}
        defaultFilter={defaultFilter}
        finalFilter={finalFilter}
        defaultData={defaultData}
      />
    </ModalBody>
  </Modal>
)

export default SelectModal
