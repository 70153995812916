import { fetchMultipleDeepProducts } from './fetchDeepProducts'

export default async function fetchMultipleRelations(
  id,
  type,
  products_id,
  fetcher,
  store,
) {
  return await fetchMultipleDeepProducts(
    products_id,
    store,
    () => store.isMultipleRelationsFetching(id, type),
    fetcher,
  )
}
