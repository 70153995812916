import isUndefined from 'lodash/isUndefined'
import { reaction } from 'mobx'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { SelectionStore } from '../../store/SelectionStore'

function ToolsReaction({ expression, effect, children }) {
  useEffect(() => reaction(expression, effect), [expression, effect])
  return <>{children}</>
}

export default class TableBase extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    mode: PropTypes.string,
    onSelect: PropTypes.func,
    onCreate: PropTypes.func,
    multiple: PropTypes.bool,
    finalFilter: PropTypes.arrayOf(PropTypes.string),
    defaultFilter: PropTypes.object,
    tryCloseRef: PropTypes.func,
    onRequestClose: PropTypes.func,
  }

  static defaultProps = {
    multiple: false,
  }
  tools = []

  constructor(props) {
    super(props)
    this.initStore()
    this.columnFilter = [
      'rec_date',
      'rec_user',
      'is_deleted',
      'id',
      'data',
      'version',
      'upd_date',
      'upd_user',
      'remote_id',
    ]

    this.mapTools = this.mapTools.bind(this)
    this.refreshList = this.refreshList.bind(this)
    this.selectPage = this.selectPage.bind(this)
    this._onSelect = this._onSelect.bind(this)
    this._submitSelections = this._submitSelections.bind(this)
    this.keyForItem = this.keyForItem.bind(this)
    this.mapItems = this.mapItems.bind(this)
    this.filterColumns = this.filterColumns.bind(this)
    this.filterColumnExcept = this.filterColumnExcept.bind(this)

    if (this.columnExcept)
      this.columnFilter = this.columnFilter.filter(this.filterColumnExcept)

    this.props.tryCloseRef && this.props.tryCloseRef(this.onTryClose)
    this.initButtons()
  }

  get columnExcept() {
    return null
  }

  get match() {
    const { url, ...rest } = this.props.match
    const [, page, ...paths] = url.split('/')
    const [component] = page.split('$')
    return { ...rest, url: `/${[component, ...paths].join('/')}` }
  }

  filterColumnExcept(column) {
    return !this.columnExcept.includes(column)
  }

  t = key => {
    if (this.props.t) return this.props.t(key)
    return key
  }
  tCommon = key => {
    if (this.props.t) {
      return this.props.t(key, { ns: 'common' })
    }
    return key
  }

  get store_class() {
    return SelectionStore
  }

  /**
   * Store instance from global stores
   * @returns {BaseStore|null}
   */
  get store_instance() {
    return null
  }

  get editor_path() {
    const { match, mode } = this.props
    const { url = '' } = match
    return mode ? url.substring(1) + '#' + mode : url.substring(1)
  }

  get editors() {
    const { store } = this.props
    return store.EditorStore.editors
  }

  initStore(StoreClass = this.store_class, component = this.editor_path) {
    let store = this.store_instance
    if (!store) {
      const store_key = component || this.editor_path
      store = this.editors.get(store_key) || new StoreClass()
      this.editors.set(store_key, store)
    }
    this.store = store
    this.storePostInit()
  }

  storePostInit() {}

  get title() {
    return this.getTitle()
  }

  getTitle() {
    const { name, store, match } = this.props
    const component = match.url.split('/')[1]
    if (name) return name
    const menu = store.MenuStore.menu_names[component]
    return this.getT(menu, 'name') || ' '
  }

  getT(item, key = 'name') {
    if (!item) return null
    const data = item.data || {}
    return (
      data[key + '_' + this.props.store.SettingsStore.language] || item[key]
    )
  }

  onTryClose = () => {
    if (this.store.selections.length > 0) {
      this.store.clearSelectionsAll()
    } else if (this.props.onRequestClose) {
      this.props.onRequestClose()
    }
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test') {
      this.onDidMount().then(this.refreshList)
    }
    this.props.tryCloseRef && this.props.tryCloseRef(this.onTryClose)
  }

  get page_url_code() {
    const { match, mode } = this.props
    return mode ? match.url + '#' + mode : match.url
  }

  filterDefaults() {
    const { defaultFilter } = this.props
    return defaultFilter
  }

  get filter_defaults() {
    return this.filterDefaults()
  }

  async onDidMount() {
    const url_code = this.page_url_code
    await this.store.restoreFilters(url_code, this.filter_defaults)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  initButtons(home = true, add = true) {
    const { mode, onCreate } = this.props
    this.tools = [
      { icon: 'refresh', action: this.refreshList, title: 'button.refresh' },
    ]
    if (home && mode !== 'select') {
      switch (typeof home) {
        case 'string':
          this.tools.unshift({ icon: home, link: '/', title: 'button.to_main' })
          break
        case 'object':
          this.tools.unshift(home)
          break
        default:
          this.tools.unshift({
            icon: 'main',
            link: '/',
            title: 'button.to_main',
          })
      }
    }
    add &&
      !!(mode === 'select' ? onCreate : this.match.url) &&
      this.store.access.includes('create') &&
      this.tools.push(this.initAddTool())
  }

  initAddTool() {
    const { onCreate } = this.props
    const options = onCreate
      ? { action: onCreate }
      : { link: `${this.match.url}/add` }
    return { icon: 'plus', title: 'button.add', ...options }
  }

  mapTools(t, index) {
    return JSON.stringify(t)
  }

  componentWillUnmount() {
    this.onWillUnmount()
  }

  onWillUnmount() {
    this.store.clearItems()
    if (this.store.clearOrder) this.store.clearOrder()
    this.store.saveFilters(this.page_url_code)
    if (this.store.clearFilter) this.store.clearFilter()
  }

  _toolsReactionExpression() {
    const { items, access, structure, selections } = this.store
    return (
      items.length +
      access.length +
      structure.length +
      (selections?.length || 0)
    )
  }

  _toolsReactionEffect() {
    this.initButtons()
  }

  _render_index = 0
  render() {
    const { items, access, structure, selections } = this.store
    this._render_index =
      items.length +
      access.length +
      structure.length +
      (selections?.length || 0)
    const filters = this.renderFilters()
    const tools = this.renderTools(this.tools, !!filters)
    const header = this.renderHeader()
    const selection_tools = this.selectionTools()
    const body = this.renderBody()
    const footer = this.renderTableEnd()
    const pagination = this.renderPagination()
    const table = this.renderTable(
      tools,
      filters,
      header,
      selection_tools,
      body,
      footer,
      pagination,
    )
    return (
      <ToolsReaction
        expression={this._toolsReactionExpression.bind(this)}
        effect={this._toolsReactionEffect.bind(this)}
      >
        {table}
      </ToolsReaction>
    )
  }

  renderTools(tools, hasFilters = false) {
    return tools
  }

  renderFilters() {
    return null
  }

  _onSelect(item, i, e) {
    if (e && e.defaultPrevented) return false
    const {
      store: { selections },
      props,
    } = this
    const { multiple, onSelect, mode, history } = props
    const key = this.keyForItem(item, i)
    if (mode === 'select') {
      const funcSelect =
        multiple && selections ? this.store.toggleSelection : onSelect
      funcSelect && funcSelect(multiple && !selections ? [item] : item, key)
    } else if (this.store.has_selections) {
      this.store.toggleSelection(item, key)
    } else if (history && this.match && key) {
      this.historyPush(history, this.match, item, i, key)
    }
  }

  historyPush(history, match, item, i, key) {
    history.push(match.url + '/' + key)
  }

  _submitSelections() {
    const { onSelect } = this.props
    onSelect && onSelect(this.store.selections)
    this.store.clearSelectionsAll()
  }

  selectionTools() {
    return []
  }

  renderHeader() {
    return null
  }

  renderBody() {
    return null
  }

  renderTableEnd() {
    return null
  }

  mapItems(c, i) {
    return null
  }

  keyForItem(item, i) {
    return !isUndefined(item.id) ? item.id : i
  }

  renderPagination() {
    return null
  }

  async selectPage(p) {
    this.store.setPage(p)
    await this.refreshList()
  }

  async refreshList(silent = false) {
    return await this.store.fetchData(this.match, silent === true)
  }

  filterColumns(s) {
    return !(this.columnFilter && this.columnFilter.includes(s.name))
  }

  renderTable(
    tools,
    filters,
    header,
    selection_tools,
    body,
    footer,
    pagination,
  ) {
    return [tools, filters, header, selection_tools, body, footer, pagination]
  }

  getSetting(name) {
    return this.props.store.ScreenStore.getProperty(this.constructor.name, name)
  }

  setSetting(name, value) {
    return this.props.store.ScreenStore.setProperty(
      this.constructor.name,
      name,
      value,
    )
  }
}
