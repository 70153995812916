import { ReportCategoriesBase } from './ReportBase'
import xlsx from 'xlsx'
import { autoFitColumns } from '../../common/utils'

export class ByAgentsStore extends ReportCategoriesBase {
  constructor() {
    super('/sale/report-by-agents')
  }

  exportDataToExcel = () => {
    let items = [['ФИО', 'Чистые продажи', 'Замен']]
    for (const item of this.items) {
      items.push([
        item?.name,
        parseFloat(item?.quantity).toFixed(2).replace('.', ','),
        parseFloat(item?.return).toFixed(2).replace('.', ','),
      ])
    }
    const worksheet = xlsx.utils.aoa_to_sheet(items)
    autoFitColumns(worksheet)
    const book = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(book, worksheet, 'list_1')
    xlsx.writeFile(book, `Отчет по ТП.xlsx`, {
      bookType: 'xlsx',
    })
  }

  getFilterStoreName() {
    return 'by-agents-report'
  }
}

const store = new ByAgentsStore()
export default store
