export default function fetchWithQueue(path, param, fetcher, store) {
  let active = true
  const fetchQueueDisposer = () => {
    if (active) {
      store.removeFetchQueue(path, param)
      active = false
    }
  }
  const promise =
    store.pushFetchQueue(path, param) === 0
      ? (async () => {
          try {
            const data = await fetcher(path, param)
            if (store.isFetching(path, param)) {
              store.deleteFetchErrorMessage(path, param)
              return data
            }
          } catch (e) {
            if (store.isFetching(path, param))
              store.setFetchErrorMessage(
                path,
                param,
                e.message || 'Неизвестная ошибка',
              )
            throw e
          }
          throw new Error(`/${path}/${param} removed from queue.`)
        })()
      : new Promise((resolve, reject) =>
          reject(new Error(`/${path}/${param} is already in queue.`)),
        )
  promise.finally(fetchQueueDisposer)
  return [fetchQueueDisposer, promise]
}
