import PropTypes from 'prop-types'
import bind from '../decorators/bind'
import PopupBase from './PopupBase'

export default class BranchPopupBase extends PopupBase {
  static propTypes = {
    ...PopupBase.propTypes,
    finalFilter: PropTypes.array,
    defaultFilter: PropTypes.object,
    headerBranch: PropTypes.shape({
      id: PropTypes.any,
      name: PropTypes.string,
    }),
    underBranches: PropTypes.bool,
  }

  static defaultProps = {
    ...PopupBase.defaultProps,
    name: 'branch_id',
    structure: { table: 'branchs' },
    underBranches: false,
  }

  parent_id = null

  get table() {
    return 'branchs'
  }

  canCreate() {
    return super.canCreate() && this.parent_id !== null
  }

  get finalFilter() {
    if (!this.props.headerBranch) {
      return this.props.finalFilter
    }
    if (this.props.finalFilter) {
      return [...this.props.finalFilter, 'branch_id']
    }
    return ['branch_id']
  }

  get defaultFilter() {
    let filters = {}
    if (this.props.defaultFilter)
      Object.assign(filters, this.props.defaultFilter)
    if (this.props.headerBranch && this.props.headerBranch.id) {
      filters.branch = this.props.headerBranch
      filters.branch_id = this.props.headerBranch.id
    }
    if (this.props.underBranches) filters.under_branches = true
    return filters
  }

  render() {
    throw Error('not implemented')
  }

  @bind _toggleCreate(parent_id, created) {
    this.parent_id = parent_id || null
    super._toggleCreate(null, created)
  }
}
