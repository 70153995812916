import { action, makeObservable, observable } from 'mobx'
import requester from '../common/requester'

export class UsersBranchesStore {
  @observable users = observable.array()
  @observable branches = observable.array()
  @observable branch = null
  @observable ancestor_branches = observable.array()
  @observable my_branch = observable.array()
  @observable my_branches = observable.array()
  @observable all_branches = observable.array()
  @observable branch_mode = 'my_branches'
  @observable loading_my_branches = false

  constructor() {
    makeObservable(this)
    this.setBranchesAndUsers = this.setBranchesAndUsers.bind(this)
    this.checkAndSetMyBranch = this.checkAndSetMyBranch.bind(this)
    this.checkAndSetMyBranches = this.checkAndSetMyBranches.bind(this)
    this.checkAndSetAllBranches = this.checkAndSetAllBranches.bind(this)
    this.setMyBranch = this.setMyBranch.bind(this)
    this.setMyBranches = this.setMyBranches.bind(this)
    this.setAllBranches = this.setAllBranches.bind(this)
  }

  loadBranchesAndUsers() {
    this.fetchBranchesAndUsers().then(this.setBranchesAndUsers)
  }

  loadMyBranches(force = false) {
    this.branch_mode = 'my_branches'
    if (this.loading_my_branches || (!force && this.my_branches.length > 0))
      return
    this.loading_my_branches = true
    this.fetchMyBranches().then(this.checkAndSetMyBranches)
  }

  loadAllBranches() {
    this.branch_mode = 'all_branches'
    this.fetchAllBranches().then(this.checkAndSetAllBranches)
  }

  async fetchBranchesAndUsers() {
    const data = await requester.get(
      '/user/user_branch' + (this.branch ? '/' + this.branch.id : ''),
    )
    return data.data
  }

  async fetchMyBranches() {
    const data = await requester.get('/user/my_branches_users')
    return data.data.list
  }

  async fetchAllBranches() {
    const data = await requester.get('/user/all_branches_users')
    return data.data.list
  }

  @action
  setBranchesAndUsers(data) {
    const { branches, branch, users, ancestor_branches } = data
    this.branches.replace(branches)
    this.branch = branch
    this.users.replace(users)
    this.ancestor_branches.replace(ancestor_branches)
  }

  @action
  checkAndSetMyBranch(list) {
    this.branch_mode = 'my_branch'
    this.setMyBranch(list)
  }

  @action
  checkAndSetMyBranches(list) {
    this.loading_my_branches = false
    this.setMyBranches(list)
  }

  @action
  checkAndSetAllBranches(list) {
    if (this.all_branches.length === 0) this.setAllBranches(list)
  }

  @action
  setMyBranch(list) {
    this.my_branch.replace(list)
  }

  @action
  setMyBranches(list) {
    this.my_branches.replace(list)
  }

  @action
  setAllBranches(list) {
    this.all_branches.replace(list)
  }

  @action
  selectBranch(branch, index, is_ancestor = true) {
    is_ancestor &&
      this.ancestor_branches.splice(
        index,
        this.ancestor_branches.length - index,
      )
    !is_ancestor && this.ancestor_branches.push(branch)
    this.branch = branch
    this.branch_mode = 'old_branch'
    return this.loadBranchesAndUsers()
  }

  @action
  selectBranchMode(mode) {
    switch (mode) {
      case 'my_branches':
        return this.loadMyBranches()
      case 'all_branches':
        return this.loadAllBranches()
    }
    return this.loadAllBranches()
  }

  @action
  clearItem() {
    this.users.clear()
    this.branches.clear()
    this.ancestor_branches.clear()
    this.branch = null
  }
}

const store = new UsersBranchesStore()
export default store
