import {
  action,
  computed,
  isObservableArray,
  isObservableObject,
  makeObservable,
  observable,
  observe,
  set,
} from 'mobx'
import BaseStore from './BaseStore'

const priceItem = {
  name: '',
  display_name: '',
  price: null,
}

const slaughterItem = {
  nomenclature_id: null,
  quantity: 0,
  min: null,
  max: null,
  price_decider: false,
  decider_expanded: false,
}

export class TemplateStore extends BaseStore {
  constructor() {
    super()
    makeObservable(this)
  }

  setSingle(item) {
    if (item.temp_type === 'slaughter') {
      if (!item.data.prices)
        item.data.prices = item.data.byproducts.reduce(
          TemplateStore.reduceKeysArray,
          {},
        )
      for (let bp of item.data.byproducts) {
        if (typeof bp.price_decider === 'undefined') bp.price_decider = false
        bp.decider_expanded = false
      }
    }
    super.setSingle(item)
    if (this.typeDisposer) {
      this.typeDisposer()
      this.typeDisposer = null
    }
    this.typeDisposer = observe(this.item, 'temp_type', this.typeObserver)
  }

  clearItem() {
    if (this.typeDisposer) {
      this.typeDisposer()
      this.typeDisposer = null
    }
    super.clearItem()
  }

  @computed
  get prices() {
    const { item } = this
    if (item.temp_type === 'price')
      return isObservableArray(item.data.prices) ? item.data.prices : []
    if (item.temp_type === 'slaughter')
      return isObservableObject(item.data.prices) ? item.data.prices : {}
  }

  @action
  typeObserver = ({ oldValue, newValue }) => {
    let { data } = this.item
    if (oldValue !== newValue) {
      if (newValue === 'slaughter') {
        if (!isObservableArray(data.byproducts)) set(data, 'byproducts', [])
        if (!isObservableObject(data.prices))
          set(
            data,
            'prices',
            data.byproducts.reduce(TemplateStore.reduceKeysArray, {}),
          )
      } else if (newValue === 'price') {
        if (!isObservableArray(data.prices))
          set(data, 'prices', [
            { branch: { name: 'Все' }, branch_id: 0, prices: [] },
          ])
        if (typeof data.decider === 'undefined') set(data, 'decider', null)
      }
    }
  }

  static reduceKeysArray(o, bp) {
    if (bp.nomenclature_id) o[bp.nomenclature_id] = []
    return o
  }

  @action.bound
  addSlaughter() {
    const {
      item: {
        temp_type,
        data: { byproducts },
      },
    } = this
    temp_type === 'slaughter' && byproducts.push(slaughterItem)
  }

  @action
  onSelectNomenclature(p) {
    if (
      p.nomenclature_id &&
      p.price_decider &&
      !this.item.data.prices[p.nomenclature_id]
    )
      set(this.item.data.prices, p.nomenclature_id, observable.array())
  }

  @action
  toggleDeciderPrices(p) {
    p.decider_expanded = !p.decider_expanded
  }

  @action
  removeSlaughter(p) {
    let { byproducts } = this.item.data
    byproducts.remove(p)
  }

  @action
  addPrice(branch_group) {
    if (isObservableArray(branch_group.prices))
      branch_group.prices.push(priceItem)
  }

  @action
  addPriceBranch(branch) {
    let { prices } = this.item.data
    let found = prices.find(e => e.branch_id === branch.id)
    if (!found && isObservableArray(this.item.data.prices))
      this.item.data.prices.push({ branch_id: branch.id, branch, prices: [] })
  }

  @action.bound
  removePriceBranch(branch_id) {
    let { prices } = this.item.data
    prices.replace(prices.filter(e => e.branch_id !== branch_id))
  }

  @action
  removePrice(branch_group, price) {
    if (isObservableArray(branch_group.prices))
      branch_group.prices.remove(price)
  }

  @action
  addPriceSlaughter(nid) {
    const { prices } = this.item.data
    if (isObservableObject(prices)) {
      if (!prices[nid]) prices[nid] = []
      prices[nid].push(priceItem)
    }
  }

  @action
  removePriceSlaughter(price, nid) {
    const { prices } = this.item.data
    if (isObservableObject(prices) && isObservableArray(prices[nid])) {
      prices[nid].remove(price)
    }
  }

  @action onSelectNomenclatureService = nomenclature => {
    if (!this.item.data.prices[nomenclature.id]) {
      const service = {
        nomenclature_id: nomenclature.id,
        nomenclature: {
          id: nomenclature.id,
          name: nomenclature.name,
          unit: {
            id: nomenclature.unit.id,
            short: nomenclature.unit.short,
          },
        },
        quantity: 0,
        price_decider: false,
        decider_expanded: false,
      }
      this.addService(service)
    }
  }

  @action addService = service => {
    const { services } = this.item.data
    services && services.push(service)
  }

  @action
  removeService(p) {
    let { services } = this.item.data
    services.remove(p)
  }

  @action addPriceService(nid) {
    const { prices } = this.item.data
    if (isObservableObject(prices)) {
      if (!prices[nid]) prices[nid] = []
      prices[nid].push({ ...priceItem, is_service: true })
    }
  }

  @action
  removePriceService(price, nid) {
    const { prices } = this.item.data
    if (isObservableObject(prices) && isObservableArray(prices[nid])) {
      prices[nid].remove(price)
    }
  }
}

const store = new TemplateStore()
export default store
