import CryptoJS from 'crypto-js'
import AES from 'crypto-js/aes'
import SHA1 from 'crypto-js/sha1'
import { action, computed, observable, set, makeObservable } from 'mobx'
import requester from '../common/requester'
import { isNativeProduction, isProduction, isWeb } from '../common/settings'
import MenuStore from './MenuStore'
import { getStorage } from './Storage'

export const ROLES = { AGENT: 7 }

async function isSynced() {
  if (isWeb() || !isProduction()) return true
  try {
    const synced = await getStorage().load({ key: 'synced' })
    if (synced) return true
  } catch {}
  const { default: localDB } = await import('../common/localDB')
  // noinspection RedundantConditionalExpressionJS
  return localDB.hasConnection ? false : true
}

// noinspection JSUnresolvedVariable,JSCheckFunctionSignatures,JSUnresolvedFunction
export class AppStore {
  @observable is_ready = false

  @observable busyCounter = 0

  @observable username = ''
  @observable password = ''
  @observable user_info
  @observable token = null
  @observable tmp_token = null

  @observable pin_confirm = ''

  @observable authenticated = false
  @observable usePin = isNativeProduction()
  @observable has_pin = null

  @observable show_syncing = false

  @observable level = ''
  @observable message = ''
  @observable action
  @observable messages = observable.array()

  @observable app_name = null
  @observable device_info = null

  @observable hide_all_nav = false
  @observable nav_side_menu = null
  home_scroll_position = { top: 0, height: 0 }

  inputId = 0

  constructor() {
    makeObservable(this)
  }

  @action
  setDeviceInfo(app_name, device, model) {
    this.app_name = app_name
    this.device_info = { device, model }
  }

  @computed
  get isBusy() {
    return this.busyCounter > 0
  }

  @action
  setBusyState(state) {
    const nextState = this.busyCounter + (state ? 1 : -1)
    this.busyCounter = nextState > 0 ? nextState : 0
  }

  makeBusy = async () => {
    this.setBusyState(true)
    await new Promise(resolve => setTimeout(resolve, 100))
    this.setBusyState(false)
  }

  /* ----- Auth part ----- */

  @computed get is_new_pin() {
    return !this.has_pin
  }

  @computed get is_confirm_pin() {
    return this.pin_confirm.length > 0
  }

  @action
  setShowSyncing(status) {
    this.show_syncing = status
    !status && getStorage().save({ key: 'synced', data: true })
  }

  refreshAuth() {
    let storage = getStorage()
    storage.load({ key: 'token' }).then(this.onTokenLoad).catch(this.logOut)
  }

  @action
  setHasPin(status) {
    this.has_pin = status
  }

  onTokenLoad = async (token, encrypted = true) => {
    if (!token) throw new Error('Auth token required!')
    this.tmp_token = token
    if (this.usePin) {
      try {
        const pinHash = await getStorage().load({ key: 'pinHash' })
        this.setHasPin(!!(pinHash && encrypted !== false))
      } catch {}
      this.setReady()
    }
    return await this.checkPin()
  }

  @action
  setUserInfo(user) {
    this.user_info = user
  }

  @action
  setAuthenticated(status = true) {
    this.authenticated = status
  }

  @action
  setReady(status = true) {
    this.is_ready = status
  }

  async onAuthResponse(user) {
    await getStorage().save({ key: 'userInfo', data: user })
    this.setUserInfo(user)
    this.setAuthenticated()
    this.setReady()
    await MenuStore.fetchMenu()
  }

  async onAuthResponseFail(e) {
    if (e && (e.result === 1 || e.result === 5)) {
      await this.logOut()
    } else if (e && !e.result) {
      try {
        const user = await getStorage().load({ key: 'userInfo' })
        this.setUserInfo(user || undefined)
      } catch {
      } finally {
        this.setAuthenticated(!!this.user_info)
        this.setReady()
        this.authenticated && (await MenuStore.fetchMenu())
      }
    } else {
      this.setReady()
    }
  }

  @action
  clearAuth() {
    this.resetAuthCredentials(false)
    this.user_info = null
    this.token = null
    this.tmp_token = null

    this.authenticated = false
    this.has_pin = false

    this.show_syncing = false

    this.clearAlert()

    this.is_ready = true
  }

  @action
  resetAuthCredentials(clear = true) {
    this.username = clear || isProduction() ? '' : 'admin'
    this.password = clear || isProduction() ? '' : '123'
  }

  login = async e => {
    e && e.preventDefault()
    const { data } = await requester.post('/user/login', {
      username: this.username,
      password: this.password,
      info: this.device_info,
    })
    this.resetAuthCredentials()
    this.clearAlert()
    return await this.onTokenLoad(data.token, false)
  }

  logOut = async () => {
    this.clearAuth()
    await getStorage().remove({ key: 'token' })
    await getStorage().remove({ key: 'userInfo' })
    await getStorage().remove({ key: 'synced' })
    await getStorage().remove({ key: 'menu' })
    await getStorage().clearMapForKey('filters')
  }

  getNumPads = () => [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    [null, 0, null],
  ]

  @action
  onPinChange({ target: { value } }) {
    let pin = value && !isNaN(value) ? `${parseInt(value)}` : ''
    if (pin === value) {
      if (pin.length === 5) {
        for (let i in pin.split('')) {
          if (!this.password[i] || this.password[i] !== pin[i]) {
            pin = pin[i]
            break
          }
        }
      }
      if (pin.length < 5) this.password = pin
    }
    return this.checkPin()
  }

  onNumPress = async num => {
    if (this.lock) return
    this.appendPass(num)
    if (this.password.length === 4) {
      try {
        this.lock = true
        await new Promise(resolve => setTimeout(resolve, 100))
      } finally {
        this.lock = false
      }
    }
    return await this.checkPin()
  }

  @action
  appendPass(num) {
    if (!isNaN(num)) {
      if (this.password.length === 4) this.password = ''
      if (this.password.length < 4) this.password += `${num}`
    }
  }

  @action
  onNumBack() {
    this.password = this.password.slice(0, -1)
    return this.checkPin()
  }

  @action setPinConfirm(value = '') {
    this.pin_confirm = value
  }

  async checkPin() {
    if (this.usePin && (!this.is_ready || this.password.length !== 4)) return
    this.setReady(false)
    if (!this.usePin) {
      await getStorage().save({ key: 'token', data: this.tmp_token })
      this.setToken(this.tmp_token)
    } else if (!this.has_pin) {
      if (!this.pin_confirm) {
        this.setPinConfirm(this.password)
        this.clearPassword()
        this.showInfo('Повторите ПИН')
        this.setReady()
        return
      } else if (this.pin_confirm === this.password) {
        this.setPinConfirm()
        await getStorage().save({
          key: 'pinHash',
          data: pinHash(this.password),
        })
        await getStorage().save({
          key: 'token',
          data: AES.encrypt(this.tmp_token, this.password).toString(),
        })
        this.setHasPin(true)
        this.setToken(this.tmp_token)
      } else {
        this.setPinConfirm()
        this.clearPassword()
        this.showWarning('ПИН отличаются')
        this.setReady()
        return
      }
    } else {
      let pin_hash = await getStorage().load({ key: 'pinHash' })
      if (pin_hash === pinHash(this.password)) {
        let t = await getStorage().load({ key: 'token' })
        this.setToken(AES.decrypt(t, this.password).toString(CryptoJS.enc.Utf8))
      } else {
        this.showWarning('Неверный ПИН')
        this.setReady()
        return
      }
    }
    this.setShowSyncing(!(await isSynced()))
    try {
      const { data } = await requester.get('/user/auth')
      await this.onAuthResponse(data.user)
    } catch (e) {
      await this.onAuthResponseFail(e)
    }
  }

  @action
  setToken(token) {
    this.token = token
  }

  @action
  clearPassword() {
    this.password = ''
  }

  saveApnToken = async (token, device) => {
    await requester.post('/user/push_token', { token, device })
  }

  /* ----- End of auth part ----- */

  @action
  clearAlert() {
    this.level = ''
    this.message = null
    this.action = null
    this.extraData = null
  }

  @action
  showSuccess(message) {
    this.level = 'success'
    this.message = message
    this.pushMessage(message, this.level)
  }

  @action
  showError(message) {
    this.level = 'danger'
    this.message = message
    this.pushMessage(message, this.level)
  }

  @action
  showInfo(message, action, extraData) {
    this.action = action
    this.extraData = extraData
    this.level = 'info'
    this.message = message
    this.pushMessage(message, this.level)
  }

  @action
  showWarning(message) {
    this.level = 'warning'
    this.message = message
    this.pushMessage(message, this.level)
  }

  @action
  showToast(message) {
    this.pushMessage(message, 'toast')
  }

  @action
  showNotification(message, data) {
    this.pushMessage(message, 'notification', data)
  }

  messages_id = 0

  @action
  pushMessage(message, level, data = {}) {
    const index = this.messages.findIndex(
      m => m.message === message && m.level === level,
    )
    index >= 0 && this.spliceMessage(index)

    const id = ++this.messages_id
    const timer = setTimeout(
      messages_id => {
        const index = this.messages.findIndex(
          message => message.id === messages_id,
        )
        index >= 0 && this.spliceMessage(index)
      },
      level === 'notification' ? 6e4 : 5e3,
      id,
    )
    this.messages.push({ ...data, message, level, timer, id })
  }

  @action
  spliceMessage(index) {
    if (this.messages.length > index) {
      clearTimeout(this.messages[index].timer)
      this.messages.splice(index, 1)
    }
  }

  extendUserInfo() {
    const { user_info: ui } = this
    if (ui) {
      typeof ui.new_password === 'undefined' && set(ui, 'new_password', '')
      typeof ui.confirm_password === 'undefined' &&
        set(ui, 'confirm_password', '')
      typeof ui.data.fio === 'undefined' && set(ui.data, 'fio', '')
    }
  }

  saveProfileChanges = async () => {
    const { user_info: ui } = this
    if (!ui) return null
    const data = await requester.post('/user/me', ui)
    this.changesSaved(data)
  }

  @action
  changesSaved() {
    this.showSuccess('Изменения сохранены')
    const { user_info: ui } = this

    if (!ui) return null
    ui.password = ui.new_password = ui.confirm_password = ''
  }

  @action
  hideAllNav(status = true) {
    this.hide_all_nav = status
  }

  @action
  setScrollPosition(top = 0, height = 0) {
    this.home_scroll_position = { top, height }
  }

  @computed
  get is_admin() {
    const admin = this.user_info?.admin || false
    return admin || this.user_info?.roles.some(role => role.admin) || false
  }

  @computed
  get is_agent() {
    return this.user_info?.roles.some(role => role.id === ROLES.AGENT) || false
  }

  @computed
  get user_accesses() {
    const roles = this.user_info?.roles || []
    /**
     * @type {Record<string,string[]>}
     */
    const user_accesses = {}
    roles.forEach(role =>
      Object.keys(role.data).forEach(controller =>
        Object.keys(role.data[controller]).forEach(access => {
          if (!role.data[controller][access]) return
          if (!user_accesses[controller]) user_accesses[controller] = []
          if (!user_accesses[controller].includes(access))
            user_accesses[controller].push(access)
        }),
      ),
    )
    return user_accesses
  }

  @computed
  get is_single_branch() {
    const { user_info } = this
    return (
      !user_info || !user_info.branch_ids || user_info.branch_ids.length === 1
    )
  }

  @computed
  get own_branch_ids() {
    if (!this.user_info) return []
    const { branch_id, branches } = this.user_info
    const own_branch_ids = [branch_id]
    branches &&
      branches.forEach(branch => {
        own_branch_ids.push(branch.id)
      })
    return own_branch_ids
  }

  @computed
  get upper_branch_ids() {
    if (!this.user_info) return []
    const { upper_branch_ids } = this.user_info
    return upper_branch_ids || []
  }

  hasAccess = (access = '', controller = '') =>
    this.user_accesses[controller]?.includes(access) || false

  @action
  navigateSideMenu(url) {
    this.nav_side_menu = url
  }

  getNextInputId() {
    const id = ++this.inputId
    if (id > 1e6) this.inputId = 0
    return id
  }
}

const pinHash = pin => {
  // noinspection JSUnresolvedVariable
  return SHA1(SHA1(pin).toString(CryptoJS.enc.Hex)).toString(CryptoJS.enc.Hex)
}

const app = new AppStore()
export default app
