export function getDeepProductsMultiple(
  products_id,
  rel_map,
  excluded,
  getProduct,
) {
  excluded = new Set(excluded)

  const deep_products_id = new Set()

  const queue = Array.from(
    products_id.reduce((queue, product_id) => {
      const key = `${product_id}`
      if (!excluded.has(product_id) && rel_map.has(key))
        rel_map
          .get(key)
          .forEach(
            product_id => !excluded.has(product_id) && queue.add(product_id),
          )
      return queue
    }, new Set()),
  )

  let i = 0
  while (i < queue.length) {
    const product_id = queue[i]
    const key = `${product_id}`
    if (rel_map.has(key)) {
      const products_id = rel_map.get(key).filter(id => !excluded.has(id))
      if (products_id.length === 0)
        !deep_products_id.has(product_id) && deep_products_id.add(product_id)
      else queue.push(...products_id)
    }
    i++
  }

  return Object.values(
    Array.from(deep_products_id).reduce((items, product_id) => {
      const product = getProduct(product_id)
      if (!product) return items
      const { nomenclature_id, nomenclature } = product
      if (!items[nomenclature_id])
        items[nomenclature_id] = {
          ...nomenclature,
          nomenclature_id,
          products: [],
        }
      items[nomenclature_id].products.push(product)
      return items
    }, {}),
  ).sort((a, b) => a.name.localeCompare(b.name))
}

export default function getDeepProducts(
  product_id,
  rel_map,
  excluded,
  getProduct,
) {
  return getDeepProductsMultiple([product_id], rel_map, excluded, getProduct)
}
