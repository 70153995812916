import BaseStore from './BaseStore'

export class AccountingStore extends BaseStore {
  async fetchData(match, silent = false) {
    return super.fetchData({ url: '/accounting/documents' }, silent)
  }
}

const store = new AccountingStore()
export default store
