import BaseStore from './BaseStore'

export class ContractorinvoiceStore extends BaseStore {
  get should_save_filter_keys() {
    return [...super.should_save_filter_keys, 'contractor']
  }
}

const store = new ContractorinvoiceStore()
export default store
