import ScanMaterial from './ScanMaterial'
import roundQuantity from './utils/roundQuantity'
import sumValue from './utils/sumValue'

export default class ScanTaraMaterial extends ScanMaterial {
  computeValue() {
    return this.edited || this.material.remained_quantity !== null
      ? super.computeValue()
      : roundQuantity(this.materials.reduce(sumValue, 0))
  }
}
