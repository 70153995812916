import { makeObservable, observable } from 'mobx'
import moment from 'moment'
import { ReportCategoriesBase } from './ReportBase'
import xlsx from 'xlsx'
import { autoFitColumns } from '../../common/utils'

export class InMonthSalePlanStore extends ReportCategoriesBase {
  @observable plan = null
  @observable fact = null

  constructor() {
    super('/sale/report-in-month-sale-plan')
    makeObservable(this)
  }

  getFiltersInitial() {
    return {
      agent_id: null,
      branch_id: null,
      year: moment().year(),
      month: moment().month() + 1,
      plan: null,
      categories: [],
    }
  }

  get filter_params() {
    let params = {}
    if (this.filters.agent_id)
      params['filter[agent_id]'] = this.filters.agent_id
    if (this.filters.branch_id)
      params['filter[branch_id]'] = this.filters.branch_id
    params['filter[year]'] = this.filters.year || ''
    params['filter[month]'] = this.filters.month || ''
    params['filter[plan]'] = this.filters.plan || 0
    params['filter[categories]'] = this.filters.categories
      .map(c => c.id)
      .join(',')
    return params
  }

  setData({ data }) {
    super.setData({ data })
    this.plan = data.plan
    this.fact = data.fact
  }

  getFilterStoreName() {
    return 'in-month-sale-plan-report'
  }

  clearData() {
    this.plan = null
    this.fact = null
    super.clearData()
  }

  exportDataToExcel = () => {
    let items = [['День', 'По плану', 'Чистыми']]
    for (const item of this.items) {
      items.push([
        item?.day,
        item?.plan,
        parseFloat(item?.quantity).toFixed(2).replace('.', ','),
      ])
    }
    const worksheet = xlsx.utils.aoa_to_sheet(items)
    autoFitColumns(worksheet)
    const book = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(book, worksheet, 'list_1')
    xlsx.writeFile(book, `Отчет по месяцу.xlsx`, {
      bookType: 'xlsx',
    })
  }
}

const store = new InMonthSalePlanStore()
export default store
