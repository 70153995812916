import fetchDescendantProducts from './fetchDescendantProducts'
import fetchDeepProducts from './fetchDeepProducts'

export default async function fetchDeepDescendantProducts(product_id, store) {
  return await fetchDeepProducts(
    product_id,
    store,
    store.isDeepDescendantProductsFetching,
    fetchDescendantProducts,
  )
}
