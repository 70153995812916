import React from 'react'
import PropTypes from 'prop-types'
import { inject } from 'mobx-react'
import { guid } from '../../../common/utils'
import '../../libs/stimulsoft.license'
import { ru as Localization } from '../../libs/ru'
import ViewerOptions from './ViewerOptions'

@inject('store')
export default class StimulsoftViewer extends React.Component {
  static propTypes = {
    onViewer: PropTypes.func.isRequired,
  }

  viewer = null

  constructor(props) {
    super(props)
    const uuid = guid().replace(/-/g, '')
    this.sti_viewer_id = 'StiViewer' + uuid
    this.viewer_content_id = 'viewerContent' + uuid
  }

  componentDidMount() {
    this.initViewer().catch(e => {
      console.warn(e)
      this.props.store.AppStore.showWarning('Не удалось построить просмотр')
    })
  }

  componentWillUnmount() {
    this.viewerTimeout && clearTimeout(this.viewerTimeout)
    this.viewer && this.props.onViewer(null)
  }

  async initViewer() {
    Stimulsoft.Base.Localization.StiLocalization.setLocalization(
      Localization,
      false,
    )
    this.viewer = new Stimulsoft.Viewer.StiViewer(
      ViewerOptions(),
      this.sti_viewer_id,
      false,
    )
    this.viewer.renderHtml(this.viewer_content_id)
    this.viewerTimeout = setTimeout(this.viewerReady, 100)
  }

  viewerReady = () => {
    this.viewerTimeout = 0
    this.props.onViewer(this.viewer)
  }

  render() {
    return (
      <div
        id={this.viewer_content_id}
        className='report-template-viewer bg-white position-relative w-100'
      />
    )
  }
}
