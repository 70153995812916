import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { FaPrint } from 'react-icons/lib/fa'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap'
import ReportTemplatePrintModal from './report-template/ReportTemplatePrintModal'

@inject('store')
@withTranslation('common')
@observer
export default class PagePrintableDropDownButton extends React.Component {
  static propTypes = {
    match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    pageStore: PropTypes.any.isRequired,
  }

  componentDidMount() {
    this.fetchTemplates()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    prevProps.match.url !== this.props.match.url && this.fetchTemplates()
  }

  fetchTemplates = () =>
    this.props.pageStore.printable.fetchTemplates(this.props.match.url)

  render() {
    const { printable } = this.props.pageStore
    const { templates } = printable
    return (
      templates.length > 0 && (
        <React.Fragment>
          <UncontrolledButtonDropdown>
            <DropdownToggle color='primary' caret outline>
              <FaPrint /> {this.props.t('button.print')}
            </DropdownToggle>
            <DropdownMenu>{templates.map(this.renderItem)}</DropdownMenu>
          </UncontrolledButtonDropdown>
          <ReportTemplatePrintModal store={printable} />
        </React.Fragment>
      )
    )
  }

  renderItem = item => {
    const { match, pageStore } = this.props
    return (
      <PagePrintableDropDownItem
        key={item.id}
        item={item}
        match={match}
        pageStore={pageStore}
      />
    )
  }
}

@observer
class PagePrintableDropDownItem extends React.Component {
  static propTypes = {
    match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    pageStore: PropTypes.any.isRequired,
    item: PropTypes.any.isRequired,
  }

  render() {
    const { item } = this.props
    return <DropdownItem onClick={this.selectOrPrint}>{item.name}</DropdownItem>
  }

  selectOrPrint = async () => {
    const { match, item, pageStore } = this.props
    const { printable } = pageStore
    if (
      /^\/documents\/\d+$/.test(match.url) &&
      /^document_.+/i.test(item.code)
    ) {
      const template = await printable.fetchTemplateParams(item)
      if (template.data.queries.every(this.isSingleParam)) {
        template.data.queries.forEach(this.setIdProp)
        await printable.printTemplateToPfd(template)
      } else {
        await printable.setTemplate(template)
      }
    } else if (/^\/[a-z\d-]+\/[^/?]+$/i.test(match.url)) {
      const template = await printable.fetchTemplateParams(item)
      if (template.data.queries.every(this.isSingleParam)) {
        template.data.queries.forEach(this.setIdProp)
        await printable.printTemplateToPfd(template)
      } else {
        await printable.setTemplate(template)
      }
    } else {
      await printable.selectTemplate(item)
    }
  }

  isSingleParam = ({ params }) =>
    Object.keys(params).length === 1 && Object.keys(params)[0] === 'id'

  setIdProp = ({ props }) => {
    let id = this.props.match.url.split('/')[2]
    if (/^\d+$/.test(id)) id = parseInt(id)
    props.id = id
  }
}
