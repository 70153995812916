import { computed, observable, makeObservable } from 'mobx'
import groupByGroup from './utils/groupByGroup'
import Material from './Material'
import Output from './Output'
import sortByGroup from './utils/sortByGroup'
import trim from './utils/trim'

function filterCompute(i) {
  return i.compute
}

function filterNotCompute(i) {
  return !i.compute
}

function filterActual(i) {
  return i.actual !== 0
}

export default class Item {
  constructor(production) {
    makeObservable(this)
    this.production = production
  }

  @computed get data() {
    return this.production.data
  }

  @computed get branch() {
    return this.data.branch
  }

  @computed get branch_id() {
    return this.data.branch_id
  }

  @computed get identity() {
    return trim(this.data.identity)
  }

  @computed get process_type() {
    return this.production.process_type
  }

  @computed get rec_user() {
    return this.data.rec_user
  }

  @computed get ingredient() {
    return this.data.ingredient
  }

  @computed get line_id() {
    return this.data.line_id
  }

  @computed get line_name() {
    return this.data.line_name
  }

  @computed get line_status() {
    return this.data.line_status
  }

  @computed get name() {
    return this.data.name
  }

  @computed get stage_name() {
    return this.data.stage_name
  }

  @computed get stage_status() {
    return this.data.stage_status
  }

  @computed get status() {
    return this.data.status
  }

  @computed get rec_date() {
    return this.data.rec_date
  }

  @computed get start_date() {
    return this.data.start_date
  }

  @computed get end_date() {
    return this.data.end_date
  }

  @computed get materials() {
    return observable.array(
      (this.data.materials || []).map(m => new Material(m, this.production)),
    )
  }

  @computed get input_units() {
    const { process_input } = this.production.data
    return (process_input && process_input.units) || []
  }

  @computed get output_units() {
    const { process_output } = this.production.data
    return (process_output && process_output.units) || []
  }

  @computed get materials_compute() {
    return this.materials.filter(filterCompute)
  }

  @computed get materials_not_compute() {
    return this.materials.filter(filterNotCompute)
  }

  @computed get materials_filtered() {
    return this.materials.filter(filterActual)
  }

  @computed get outputs() {
    return observable.array(
      sortByGroup(
        (this.data.outputs || []).map(m => new Output(m, this.production)),
      ),
    )
  }

  @computed get outputs_filtered() {
    return this.outputs.filter(filterActual)
  }

  @computed get groups() {
    return Object.keys(this.grouped_outputs)
  }

  @computed get grouped_outputs() {
    return groupByGroup(this.outputs)
  }

  @computed get groups_filtered() {
    return Object.keys(this.grouped_outputs_filtered)
  }

  @computed get grouped_outputs_filtered() {
    return groupByGroup(this.outputs_filtered)
  }
}
