import isNumber from 'lodash/isNumber'
import { computed, observable, makeObservable } from 'mobx'
import { guid } from '../../../common/utils'
import roundQuantity from './utils/roundQuantity'
import sumValue from './utils/sumValue'

export default class ScanMaterial {
  @observable edited = false
  @observable quantity = null
  @observable materials = observable.array()

  key = guid()

  constructor(materials, material) {
    makeObservable(this)
    Object.defineProperty(this, 'material', {
      value: material,
      enumerable: false,
    })
    this.materials.replace(materials)
  }

  @computed get branch() {
    return (this.materials.length > 0 && this.materials[0].branch) || ''
  }

  @computed get branch_id() {
    return (this.materials.length > 0 && this.materials[0].branch_id) || null
  }

  @computed get product_id() {
    return (this.materials.length > 0 && this.materials[0].product_id) || null
  }

  @computed get nomenclature_id() {
    return this.material.nomenclature_id
  }

  @computed get name() {
    return this.material.name
  }

  @computed get unit() {
    return this.material.unit
  }

  @computed get origin() {
    return roundQuantity(this.materials.reduce(sumOrigin, 0))
  }

  computeValue() {
    if (this.edited) {
      return this.quantity
    }
    let value = this.material.remained_quantity
    if (value === null || value <= 0) return null
    const scan_materials = this.material.production.states.scan_materials
    const i = scan_materials.findIndex(m => m === this)
    i > 0 &&
      scan_materials.slice(0, i).forEach(m => {
        if (
          m.nomenclature_id === this.nomenclature_id &&
          m.value !== null &&
          m.value > 0
        ) {
          value -= m.value
        }
      })
    return value > 0
      ? roundQuantity(Math.min(value, this.materials.reduce(sumValue, 0)))
      : null
  }

  @computed get value() {
    return this.computeValue()
  }

  @computed get tara_identity() {
    return (
      (this.materials.length > 0 && this.materials[0].tara_identity) || null
    )
  }

  set value(v) {
    if (!this.edited) {
      this.edited = true
    }
    this.quantity = v
  }

  makeEdited() {
    if (!this.edited) {
      this.quantity = this.value || 0
      this.edited = true
    }
  }

  toJSON() {
    let total = this.value
    const materials = this.materials.map(m => {
      const value = m.origin > total ? total : m.origin
      if (total > value) {
        total -= value
      } else {
        total = 0
      }
      return {
        ...this.material.toJSON(),
        ...m,
        value: value,
      }
    })
    if (total > 0.0001) {
      const mul = total / materials.reduce(sumValue, 0)
      materials.forEach(m => (m.value = roundQuantity(m.value + m.value * mul)))
    }
    return materials
  }
}

function sumOrigin(total = 0, { origin = 0 }) {
  const value = isNumber(origin) ? origin : parseFloat(origin)
  return isFinite(value) ? total + value : total
}
