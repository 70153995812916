import { useEffect } from 'react'
import requester from '../requester'

export default function useDocumentEditor(props) {
  const { history, location, match } = props
  const url = match.url
  const { search = '', state } = location
  const page = search.match(/(^|&|\?)page=([^&]*)/)
  const editor = page?.[2] || state?.document_type?.template || null

  useEffect(() => {
    let active = true
    if (!editor) {
      requester
        .get(url)
        .then(response => {
          const { document_type } = response.data.item
          active &&
            history.replace({
              pathname: url,
              search: `page=${document_type.template}`,
              state: { document_type },
            })
        })
        .catch(console.debug)
    }
    return () => {
      active = false
    }
  }, [url, editor, history])

  return editor
}
