import SingleInput from './single-input'
import Standard from './standard'

export const TYPE = {
  SINGLE_INPUT: 'single_input',
  STANDARD: 'standard',
}

export const TYPE_CLASS_MAP = {
  [TYPE.SINGLE_INPUT]: SingleInput,
  [TYPE.STANDARD]: Standard,
}

export default TYPE_CLASS_MAP
