import classNames from 'classnames'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import { getModelValue, setModelValue } from '../../common/utils'

@inject('store')
@observer
export default class FormCheckbox extends Component {
  static propTypes = {
    model: PropTypes.any,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    checked: PropTypes.bool,
    title: PropTypes.string,
    onChange: PropTypes.func,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.any,
    labelStyle: PropTypes.any,
    alignCenter: PropTypes.bool,
  }

  static defaultProps = {
    checked: false,
    inline: false,
    disabled: false,
    alignCenter: false,
  }

  _onChange = event => {
    let { model, name, onChange } = this.props
    setModelValue(model, name, event.target.checked)
    onChange && onChange(event.target.checked)
  }

  get is_checked() {
    const { model, name, checked } = this.props
    return getModelValue(model, name, checked)
  }

  render() {
    const {
      title,
      inline,
      disabled,
      className,
      labelStyle,
      alignCenter,
      store,
    } = this.props
    const is_disabled = store.AppStore.isBusy || disabled
    let class_names = classNames([
      {
        'cursor-pointer': !disabled,
        'h-100 d-flex flex-column align-items-stretch justify-content-center':
          alignCenter,
        'text-muted': disabled,
      },
      className,
    ])
    return (
      <FormGroup check inline={inline} className={class_names}>
        <Label check style={labelStyle}>
          <Input
            type='checkbox'
            disabled={is_disabled}
            onChange={this._onChange}
            checked={this.is_checked}
          />
          {title || '\xa0'}
        </Label>
      </FormGroup>
    )
  }
}
